/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateUserParsedResumeByUserId = /* GraphQL */ `
  subscription OnCreateUserParsedResumeByUserId($UserId: ID!) {
    onCreateUserParsedResumeByUserId(UserId: $UserId) {
      UserId
      ParsedString
      createdAt
      id
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateHIDI = /* GraphQL */ `
  subscription OnCreateHIDI($filter: ModelSubscriptionHIDIFilterInput) {
    onCreateHIDI(filter: $filter) {
      createdAt
      Email
      id
      profile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            Talents {
              nextToken
              __typename
            }
            signals {
              nextToken
              __typename
            }
            MobileNumber
            name
            UserId
            PSStitle
            PSSdescription
            DMStitle
            DMSdescription
            CTtitle
            CTdescription
            Atitle
            Adescription
            Verified
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Talents {
          items {
            createdAt
            hidiId
            UserId
            Spotter
            description
            id
            Talent
            Level
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            hidi {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        signals {
          items {
            createdAt
            hidiId
            UserId
            Spotter
            description
            id
            signalname
            score
            signalcategory
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            hidi {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        resume
        education
        experience
        expertise
        graduation
        iframescorelink
        iframehidilink
        updatedAt
        owner
        __typename
      }
      Talents {
        items {
          createdAt
          hidiId
          UserId
          Spotter
          description
          id
          Talent
          Level
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            Talents {
              nextToken
              __typename
            }
            signals {
              nextToken
              __typename
            }
            resume
            education
            experience
            expertise
            graduation
            iframescorelink
            iframehidilink
            updatedAt
            owner
            __typename
          }
          hidi {
            createdAt
            Email
            id
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            Talents {
              nextToken
              __typename
            }
            signals {
              nextToken
              __typename
            }
            MobileNumber
            name
            UserId
            PSStitle
            PSSdescription
            DMStitle
            DMSdescription
            CTtitle
            CTdescription
            Atitle
            Adescription
            Verified
            updatedAt
            owner
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      signals {
        items {
          createdAt
          hidiId
          UserId
          Spotter
          description
          id
          signalname
          score
          signalcategory
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            Talents {
              nextToken
              __typename
            }
            signals {
              nextToken
              __typename
            }
            resume
            education
            experience
            expertise
            graduation
            iframescorelink
            iframehidilink
            updatedAt
            owner
            __typename
          }
          hidi {
            createdAt
            Email
            id
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            Talents {
              nextToken
              __typename
            }
            signals {
              nextToken
              __typename
            }
            MobileNumber
            name
            UserId
            PSStitle
            PSSdescription
            DMStitle
            DMSdescription
            CTtitle
            CTdescription
            Atitle
            Adescription
            Verified
            updatedAt
            owner
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      MobileNumber
      name
      UserId
      PSStitle
      PSSdescription
      DMStitle
      DMSdescription
      CTtitle
      CTdescription
      Atitle
      Adescription
      Verified
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateHIDI = /* GraphQL */ `
  subscription OnUpdateHIDI($filter: ModelSubscriptionHIDIFilterInput) {
    onUpdateHIDI(filter: $filter) {
      createdAt
      Email
      id
      profile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            Talents {
              nextToken
              __typename
            }
            signals {
              nextToken
              __typename
            }
            MobileNumber
            name
            UserId
            PSStitle
            PSSdescription
            DMStitle
            DMSdescription
            CTtitle
            CTdescription
            Atitle
            Adescription
            Verified
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Talents {
          items {
            createdAt
            hidiId
            UserId
            Spotter
            description
            id
            Talent
            Level
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            hidi {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        signals {
          items {
            createdAt
            hidiId
            UserId
            Spotter
            description
            id
            signalname
            score
            signalcategory
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            hidi {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        resume
        education
        experience
        expertise
        graduation
        iframescorelink
        iframehidilink
        updatedAt
        owner
        __typename
      }
      Talents {
        items {
          createdAt
          hidiId
          UserId
          Spotter
          description
          id
          Talent
          Level
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            Talents {
              nextToken
              __typename
            }
            signals {
              nextToken
              __typename
            }
            resume
            education
            experience
            expertise
            graduation
            iframescorelink
            iframehidilink
            updatedAt
            owner
            __typename
          }
          hidi {
            createdAt
            Email
            id
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            Talents {
              nextToken
              __typename
            }
            signals {
              nextToken
              __typename
            }
            MobileNumber
            name
            UserId
            PSStitle
            PSSdescription
            DMStitle
            DMSdescription
            CTtitle
            CTdescription
            Atitle
            Adescription
            Verified
            updatedAt
            owner
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      signals {
        items {
          createdAt
          hidiId
          UserId
          Spotter
          description
          id
          signalname
          score
          signalcategory
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            Talents {
              nextToken
              __typename
            }
            signals {
              nextToken
              __typename
            }
            resume
            education
            experience
            expertise
            graduation
            iframescorelink
            iframehidilink
            updatedAt
            owner
            __typename
          }
          hidi {
            createdAt
            Email
            id
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            Talents {
              nextToken
              __typename
            }
            signals {
              nextToken
              __typename
            }
            MobileNumber
            name
            UserId
            PSStitle
            PSSdescription
            DMStitle
            DMSdescription
            CTtitle
            CTdescription
            Atitle
            Adescription
            Verified
            updatedAt
            owner
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      MobileNumber
      name
      UserId
      PSStitle
      PSSdescription
      DMStitle
      DMSdescription
      CTtitle
      CTdescription
      Atitle
      Adescription
      Verified
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteHIDI = /* GraphQL */ `
  subscription OnDeleteHIDI($filter: ModelSubscriptionHIDIFilterInput) {
    onDeleteHIDI(filter: $filter) {
      createdAt
      Email
      id
      profile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            Talents {
              nextToken
              __typename
            }
            signals {
              nextToken
              __typename
            }
            MobileNumber
            name
            UserId
            PSStitle
            PSSdescription
            DMStitle
            DMSdescription
            CTtitle
            CTdescription
            Atitle
            Adescription
            Verified
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Talents {
          items {
            createdAt
            hidiId
            UserId
            Spotter
            description
            id
            Talent
            Level
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            hidi {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        signals {
          items {
            createdAt
            hidiId
            UserId
            Spotter
            description
            id
            signalname
            score
            signalcategory
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            hidi {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        resume
        education
        experience
        expertise
        graduation
        iframescorelink
        iframehidilink
        updatedAt
        owner
        __typename
      }
      Talents {
        items {
          createdAt
          hidiId
          UserId
          Spotter
          description
          id
          Talent
          Level
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            Talents {
              nextToken
              __typename
            }
            signals {
              nextToken
              __typename
            }
            resume
            education
            experience
            expertise
            graduation
            iframescorelink
            iframehidilink
            updatedAt
            owner
            __typename
          }
          hidi {
            createdAt
            Email
            id
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            Talents {
              nextToken
              __typename
            }
            signals {
              nextToken
              __typename
            }
            MobileNumber
            name
            UserId
            PSStitle
            PSSdescription
            DMStitle
            DMSdescription
            CTtitle
            CTdescription
            Atitle
            Adescription
            Verified
            updatedAt
            owner
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      signals {
        items {
          createdAt
          hidiId
          UserId
          Spotter
          description
          id
          signalname
          score
          signalcategory
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            Talents {
              nextToken
              __typename
            }
            signals {
              nextToken
              __typename
            }
            resume
            education
            experience
            expertise
            graduation
            iframescorelink
            iframehidilink
            updatedAt
            owner
            __typename
          }
          hidi {
            createdAt
            Email
            id
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            Talents {
              nextToken
              __typename
            }
            signals {
              nextToken
              __typename
            }
            MobileNumber
            name
            UserId
            PSStitle
            PSSdescription
            DMStitle
            DMSdescription
            CTtitle
            CTdescription
            Atitle
            Adescription
            Verified
            updatedAt
            owner
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      MobileNumber
      name
      UserId
      PSStitle
      PSSdescription
      DMStitle
      DMSdescription
      CTtitle
      CTdescription
      Atitle
      Adescription
      Verified
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateProfile = /* GraphQL */ `
  subscription OnCreateProfile($filter: ModelSubscriptionProfileFilterInput) {
    onCreateProfile(filter: $filter) {
      createdAt
      name
      UserId
      Email
      id
      phone
      about
      hidis {
        items {
          createdAt
          Email
          id
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            Talents {
              nextToken
              __typename
            }
            signals {
              nextToken
              __typename
            }
            resume
            education
            experience
            expertise
            graduation
            iframescorelink
            iframehidilink
            updatedAt
            owner
            __typename
          }
          Talents {
            items {
              createdAt
              hidiId
              UserId
              Spotter
              description
              id
              Talent
              Level
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          signals {
            items {
              createdAt
              hidiId
              UserId
              Spotter
              description
              id
              signalname
              score
              signalcategory
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          MobileNumber
          name
          UserId
          PSStitle
          PSSdescription
          DMStitle
          DMSdescription
          CTtitle
          CTdescription
          Atitle
          Adescription
          Verified
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      Skills {
        items {
          createdAt
          profileId
          UserId
          description
          YearsOfExperience
          id
          Skill
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          JoinedCapabilities {
            items {
              id
              UserId
              SkillId
              CapabilityId
              GlobalCapabilityConfigId
              GlobalCapabilityConfigName
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      Talents {
        items {
          createdAt
          hidiId
          UserId
          Spotter
          description
          id
          Talent
          Level
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            Talents {
              nextToken
              __typename
            }
            signals {
              nextToken
              __typename
            }
            resume
            education
            experience
            expertise
            graduation
            iframescorelink
            iframehidilink
            updatedAt
            owner
            __typename
          }
          hidi {
            createdAt
            Email
            id
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            Talents {
              nextToken
              __typename
            }
            signals {
              nextToken
              __typename
            }
            MobileNumber
            name
            UserId
            PSStitle
            PSSdescription
            DMStitle
            DMSdescription
            CTtitle
            CTdescription
            Atitle
            Adescription
            Verified
            updatedAt
            owner
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      signals {
        items {
          createdAt
          hidiId
          UserId
          Spotter
          description
          id
          signalname
          score
          signalcategory
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            Talents {
              nextToken
              __typename
            }
            signals {
              nextToken
              __typename
            }
            resume
            education
            experience
            expertise
            graduation
            iframescorelink
            iframehidilink
            updatedAt
            owner
            __typename
          }
          hidi {
            createdAt
            Email
            id
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            Talents {
              nextToken
              __typename
            }
            signals {
              nextToken
              __typename
            }
            MobileNumber
            name
            UserId
            PSStitle
            PSSdescription
            DMStitle
            DMSdescription
            CTtitle
            CTdescription
            Atitle
            Adescription
            Verified
            updatedAt
            owner
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      resume
      education
      experience
      expertise
      graduation
      iframescorelink
      iframehidilink
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateProfile = /* GraphQL */ `
  subscription OnUpdateProfile($filter: ModelSubscriptionProfileFilterInput) {
    onUpdateProfile(filter: $filter) {
      createdAt
      name
      UserId
      Email
      id
      phone
      about
      hidis {
        items {
          createdAt
          Email
          id
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            Talents {
              nextToken
              __typename
            }
            signals {
              nextToken
              __typename
            }
            resume
            education
            experience
            expertise
            graduation
            iframescorelink
            iframehidilink
            updatedAt
            owner
            __typename
          }
          Talents {
            items {
              createdAt
              hidiId
              UserId
              Spotter
              description
              id
              Talent
              Level
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          signals {
            items {
              createdAt
              hidiId
              UserId
              Spotter
              description
              id
              signalname
              score
              signalcategory
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          MobileNumber
          name
          UserId
          PSStitle
          PSSdescription
          DMStitle
          DMSdescription
          CTtitle
          CTdescription
          Atitle
          Adescription
          Verified
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      Skills {
        items {
          createdAt
          profileId
          UserId
          description
          YearsOfExperience
          id
          Skill
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          JoinedCapabilities {
            items {
              id
              UserId
              SkillId
              CapabilityId
              GlobalCapabilityConfigId
              GlobalCapabilityConfigName
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      Talents {
        items {
          createdAt
          hidiId
          UserId
          Spotter
          description
          id
          Talent
          Level
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            Talents {
              nextToken
              __typename
            }
            signals {
              nextToken
              __typename
            }
            resume
            education
            experience
            expertise
            graduation
            iframescorelink
            iframehidilink
            updatedAt
            owner
            __typename
          }
          hidi {
            createdAt
            Email
            id
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            Talents {
              nextToken
              __typename
            }
            signals {
              nextToken
              __typename
            }
            MobileNumber
            name
            UserId
            PSStitle
            PSSdescription
            DMStitle
            DMSdescription
            CTtitle
            CTdescription
            Atitle
            Adescription
            Verified
            updatedAt
            owner
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      signals {
        items {
          createdAt
          hidiId
          UserId
          Spotter
          description
          id
          signalname
          score
          signalcategory
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            Talents {
              nextToken
              __typename
            }
            signals {
              nextToken
              __typename
            }
            resume
            education
            experience
            expertise
            graduation
            iframescorelink
            iframehidilink
            updatedAt
            owner
            __typename
          }
          hidi {
            createdAt
            Email
            id
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            Talents {
              nextToken
              __typename
            }
            signals {
              nextToken
              __typename
            }
            MobileNumber
            name
            UserId
            PSStitle
            PSSdescription
            DMStitle
            DMSdescription
            CTtitle
            CTdescription
            Atitle
            Adescription
            Verified
            updatedAt
            owner
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      resume
      education
      experience
      expertise
      graduation
      iframescorelink
      iframehidilink
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteProfile = /* GraphQL */ `
  subscription OnDeleteProfile($filter: ModelSubscriptionProfileFilterInput) {
    onDeleteProfile(filter: $filter) {
      createdAt
      name
      UserId
      Email
      id
      phone
      about
      hidis {
        items {
          createdAt
          Email
          id
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            Talents {
              nextToken
              __typename
            }
            signals {
              nextToken
              __typename
            }
            resume
            education
            experience
            expertise
            graduation
            iframescorelink
            iframehidilink
            updatedAt
            owner
            __typename
          }
          Talents {
            items {
              createdAt
              hidiId
              UserId
              Spotter
              description
              id
              Talent
              Level
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          signals {
            items {
              createdAt
              hidiId
              UserId
              Spotter
              description
              id
              signalname
              score
              signalcategory
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          MobileNumber
          name
          UserId
          PSStitle
          PSSdescription
          DMStitle
          DMSdescription
          CTtitle
          CTdescription
          Atitle
          Adescription
          Verified
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      Skills {
        items {
          createdAt
          profileId
          UserId
          description
          YearsOfExperience
          id
          Skill
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          JoinedCapabilities {
            items {
              id
              UserId
              SkillId
              CapabilityId
              GlobalCapabilityConfigId
              GlobalCapabilityConfigName
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      Talents {
        items {
          createdAt
          hidiId
          UserId
          Spotter
          description
          id
          Talent
          Level
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            Talents {
              nextToken
              __typename
            }
            signals {
              nextToken
              __typename
            }
            resume
            education
            experience
            expertise
            graduation
            iframescorelink
            iframehidilink
            updatedAt
            owner
            __typename
          }
          hidi {
            createdAt
            Email
            id
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            Talents {
              nextToken
              __typename
            }
            signals {
              nextToken
              __typename
            }
            MobileNumber
            name
            UserId
            PSStitle
            PSSdescription
            DMStitle
            DMSdescription
            CTtitle
            CTdescription
            Atitle
            Adescription
            Verified
            updatedAt
            owner
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      signals {
        items {
          createdAt
          hidiId
          UserId
          Spotter
          description
          id
          signalname
          score
          signalcategory
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            Talents {
              nextToken
              __typename
            }
            signals {
              nextToken
              __typename
            }
            resume
            education
            experience
            expertise
            graduation
            iframescorelink
            iframehidilink
            updatedAt
            owner
            __typename
          }
          hidi {
            createdAt
            Email
            id
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            Talents {
              nextToken
              __typename
            }
            signals {
              nextToken
              __typename
            }
            MobileNumber
            name
            UserId
            PSStitle
            PSSdescription
            DMStitle
            DMSdescription
            CTtitle
            CTdescription
            Atitle
            Adescription
            Verified
            updatedAt
            owner
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      resume
      education
      experience
      expertise
      graduation
      iframescorelink
      iframehidilink
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateNewprofile = /* GraphQL */ `
  subscription OnCreateNewprofile(
    $filter: ModelSubscriptionNewprofileFilterInput
  ) {
    onCreateNewprofile(filter: $filter) {
      createdAt
      name
      UserId
      Email
      id
      phone
      about
      hidis {
        items {
          createdAt
          Email
          id
          profileId
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          name
          UserId
          challenges {
            items {
              createdAt
              id
              challenge
              HidiId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          outcomes {
            items {
              createdAt
              id
              Outcome
              HidiId
              Verified
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConnectedCapability {
            items {
              CapabilityId
              HidiId
              createdAt
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          connectedFeeds {
            items {
              id
              UserSegmentId
              HidiId
              CapabilityConfigId
              jobPostId
              tags
              domain
              Description
              Payload
              FeedType
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          summary
          description
          title
          Verified
          likes
          views
          steps
          jobs
          outcomelink
          impact
          impactOverall
          imagelink
          outcome
          pinnedFlag
          tag1
          tag2
          htmlheading
          htmlsummary
          htmloutput
          peerEmails
          helped
          problem
          Hack
          experience
          projectLink
          whereitwasdone
          whereitwasdonecompany
          SkillUsed
          ExperinceInSkill
          outcomejson
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          AiGenerated
          ProbabilityFake
          ProbabilityReal
          hidiTopic
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      CandidateEducation {
        items {
          createdAt
          UserId
          id
          degree
          institute
          duration
          startDate
          EndDate
          Present
          percentage
          description
          location
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      deployedforqrews {
        items {
          createdAt
          updatedAt
          companyQrewId
          businessProfileId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          finalCTCQrew
          candidateprofileId
          qrewPosition
          deployedUserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          preferencesId
          JobPostPreferences {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          deployedQrewHistory {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              NewContractCTC
              ContractEndedBy
              ContractPausedBy
              JobApplicationsId
              deployedQrewId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          recommededId
          joiningDate
          endingDate
          NDAkey
          Contractkey
          KYCdocumentkey
          WorkingOnit
          EndContract
          ContractEndingDate
          ExtensionDurationInMonths
          ExtensionDate
          ExtendingContract
          CandidateAcceptanceStatus
          CandidateContractRejectionReason
          CandidateContractNegotiatingReason
          NewContractCTC
          ContractEndedBy
          ContractExtendedBy
          ContractPausedBy
          JobApplicationsId
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          editObjectiveHistory {
            items {
              id
              createdAt
              updatedAt
              objectiveId
              prevTitle
              editedbyCompanyUserProfileId
              editedbyDeployedQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          id
          owner
          __typename
        }
        nextToken
        __typename
      }
      CandidateExperience {
        items {
          createdAt
          UserId
          id
          startDate
          EndDate
          Present
          company
          designation
          description
          duration
          location
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      comapanyshortlists {
        items {
          createdAt
          businessProfileId
          businessUserId
          UserId
          about
          candidateprofileId
          shortlistedbyId
          JobPostId
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          shortlistedByUser {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          recommededId
          preferencesId
          prferenceType {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          id
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          businessProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      comapanyrejected {
        items {
          createdAt
          businessProfileId
          businessUserId
          shortlistedbyId
          shortlistedByUser {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          UserId
          about
          candidateprofileId
          Reasonofrejection
          recommededId
          preferencesId
          prferenceType {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          id
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          businessProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      inpreference {
        items {
          createdAt
          businessProfileId
          businessUserId
          UserId
          candidateprofileId
          acceptanceStatus
          ReasonofRecommendation
          ReasonofShortlist
          Reasonofrejection
          acceptanceStatus_by_CompanyUser_ID
          acceptanceStatus_by_CompanyUser_name
          recommendedbyId
          prferenceType {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          preferencesId
          companyQrewId
          JobApplicationsId
          JobApplication {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          JobPostId
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          businessProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          new
          id
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      Skills {
        items {
          createdAt
          profileId
          UserId
          description
          YearsOfExperience
          id
          Skill
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          JoinedCapabilities {
            items {
              id
              UserId
              SkillId
              CapabilityId
              GlobalCapabilityConfigId
              GlobalCapabilityConfigName
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      profileFeedback {
        items {
          createdAt
          id
          profileId
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          name
          designation
          description
          UserId
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      certifications {
        items {
          createdAt
          id
          Certificatename
          description
          Source
          certificateId
          validity
          UserId
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      Capabilities {
        items {
          createdAt
          ProfileId
          id
          PointsEarned {
            items {
              id
              Project
              Hack
              ProjectLink
              Video
              Photo
              Presentation
              experience
              Claim
              Outcomes
              PeersVouch
              GlobalCapabilityConfigId
              Helped
              Problem
              Challenges
              CapabilityPoints
              UserId
              capabilityPointsCapabilityClaimedId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          userProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ConnectedHidis {
            items {
              CapabilityId
              HidiId
              createdAt
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          capabilitiesCapabilityConfigId
          CapabilityConfig {
            id
            name
            tag
            domain
            createdAt
            weeklyActiveOpportunity
            capabilitiesClaimedCount
            CapabilityPoints
            capabilitiesClaimed {
              nextToken
              __typename
            }
            feeds {
              nextToken
              __typename
            }
            connectedJobPosts {
              nextToken
              __typename
            }
            ActivityStatus
            updatedAt
            owner
            __typename
          }
          JoinedSkills {
            items {
              id
              UserId
              SkillId
              CapabilityId
              GlobalCapabilityConfigId
              GlobalCapabilityConfigName
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      CandidatePreferences {
        items {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      JobApplications {
        items {
          id
          JobPostId
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          CompanyProfileId
          ProfileId
          CompanyPreferenceId
          companyQrewId
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ApplyingStatus
          Level
          CandidateProfileAnalysis
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      ConvoOnHidi {
        items {
          id
          Question
          Answer
          profileId
          UserId
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          PreferenceId
          companyProfileId
          CompanyProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          companyUserProfileId
          HidiId
          NewHidi {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          companyUser {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      ActivatedCandisatePreferencesID
      activeCandidatePreferences {
        createdAt
        id
        rolename
        jobType
        workType
        rolelevel
        benefits
        priority
        expectation
        Salaryexpectation
        ContractCompensation
        currentSalary
        companyType
        workStyle
        minimumContractPeriod
        servingNotice
        noticePeriod
        workSchedule
        Activelylooking
        contractType
        compensationStructure
        AvailableDate
        userDomain
        roletype
        location1
        location2
        location3
        Country
        CurrencyCountry
        ActivatePreference
        prevContractCount
        profileId
        UserProfile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        ProfilebyCandidatePreferencesId {
          items {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      ConnectedUserSegments {
        items {
          UserSegmentId
          UserId
          createdAt
          updatedAt
          id
          owner
          __typename
        }
        nextToken
        __typename
      }
      salarycurrent
      salaryexpectation
      certification
      noticeperiod
      currentoffer
      education
      experience
      expertise
      graduation
      image
      resume
      location
      imageicon1
      link1
      imageicon2
      link2
      imageicon3
      link3
      profileviews
      relevant
      connected
      imagekey
      typeofwork
      resumekey
      ConsentToShareInfo
      IntroVideo
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateNewprofile = /* GraphQL */ `
  subscription OnUpdateNewprofile(
    $filter: ModelSubscriptionNewprofileFilterInput
  ) {
    onUpdateNewprofile(filter: $filter) {
      createdAt
      name
      UserId
      Email
      id
      phone
      about
      hidis {
        items {
          createdAt
          Email
          id
          profileId
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          name
          UserId
          challenges {
            items {
              createdAt
              id
              challenge
              HidiId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          outcomes {
            items {
              createdAt
              id
              Outcome
              HidiId
              Verified
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConnectedCapability {
            items {
              CapabilityId
              HidiId
              createdAt
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          connectedFeeds {
            items {
              id
              UserSegmentId
              HidiId
              CapabilityConfigId
              jobPostId
              tags
              domain
              Description
              Payload
              FeedType
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          summary
          description
          title
          Verified
          likes
          views
          steps
          jobs
          outcomelink
          impact
          impactOverall
          imagelink
          outcome
          pinnedFlag
          tag1
          tag2
          htmlheading
          htmlsummary
          htmloutput
          peerEmails
          helped
          problem
          Hack
          experience
          projectLink
          whereitwasdone
          whereitwasdonecompany
          SkillUsed
          ExperinceInSkill
          outcomejson
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          AiGenerated
          ProbabilityFake
          ProbabilityReal
          hidiTopic
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      CandidateEducation {
        items {
          createdAt
          UserId
          id
          degree
          institute
          duration
          startDate
          EndDate
          Present
          percentage
          description
          location
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      deployedforqrews {
        items {
          createdAt
          updatedAt
          companyQrewId
          businessProfileId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          finalCTCQrew
          candidateprofileId
          qrewPosition
          deployedUserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          preferencesId
          JobPostPreferences {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          deployedQrewHistory {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              NewContractCTC
              ContractEndedBy
              ContractPausedBy
              JobApplicationsId
              deployedQrewId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          recommededId
          joiningDate
          endingDate
          NDAkey
          Contractkey
          KYCdocumentkey
          WorkingOnit
          EndContract
          ContractEndingDate
          ExtensionDurationInMonths
          ExtensionDate
          ExtendingContract
          CandidateAcceptanceStatus
          CandidateContractRejectionReason
          CandidateContractNegotiatingReason
          NewContractCTC
          ContractEndedBy
          ContractExtendedBy
          ContractPausedBy
          JobApplicationsId
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          editObjectiveHistory {
            items {
              id
              createdAt
              updatedAt
              objectiveId
              prevTitle
              editedbyCompanyUserProfileId
              editedbyDeployedQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          id
          owner
          __typename
        }
        nextToken
        __typename
      }
      CandidateExperience {
        items {
          createdAt
          UserId
          id
          startDate
          EndDate
          Present
          company
          designation
          description
          duration
          location
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      comapanyshortlists {
        items {
          createdAt
          businessProfileId
          businessUserId
          UserId
          about
          candidateprofileId
          shortlistedbyId
          JobPostId
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          shortlistedByUser {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          recommededId
          preferencesId
          prferenceType {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          id
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          businessProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      comapanyrejected {
        items {
          createdAt
          businessProfileId
          businessUserId
          shortlistedbyId
          shortlistedByUser {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          UserId
          about
          candidateprofileId
          Reasonofrejection
          recommededId
          preferencesId
          prferenceType {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          id
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          businessProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      inpreference {
        items {
          createdAt
          businessProfileId
          businessUserId
          UserId
          candidateprofileId
          acceptanceStatus
          ReasonofRecommendation
          ReasonofShortlist
          Reasonofrejection
          acceptanceStatus_by_CompanyUser_ID
          acceptanceStatus_by_CompanyUser_name
          recommendedbyId
          prferenceType {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          preferencesId
          companyQrewId
          JobApplicationsId
          JobApplication {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          JobPostId
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          businessProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          new
          id
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      Skills {
        items {
          createdAt
          profileId
          UserId
          description
          YearsOfExperience
          id
          Skill
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          JoinedCapabilities {
            items {
              id
              UserId
              SkillId
              CapabilityId
              GlobalCapabilityConfigId
              GlobalCapabilityConfigName
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      profileFeedback {
        items {
          createdAt
          id
          profileId
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          name
          designation
          description
          UserId
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      certifications {
        items {
          createdAt
          id
          Certificatename
          description
          Source
          certificateId
          validity
          UserId
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      Capabilities {
        items {
          createdAt
          ProfileId
          id
          PointsEarned {
            items {
              id
              Project
              Hack
              ProjectLink
              Video
              Photo
              Presentation
              experience
              Claim
              Outcomes
              PeersVouch
              GlobalCapabilityConfigId
              Helped
              Problem
              Challenges
              CapabilityPoints
              UserId
              capabilityPointsCapabilityClaimedId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          userProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ConnectedHidis {
            items {
              CapabilityId
              HidiId
              createdAt
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          capabilitiesCapabilityConfigId
          CapabilityConfig {
            id
            name
            tag
            domain
            createdAt
            weeklyActiveOpportunity
            capabilitiesClaimedCount
            CapabilityPoints
            capabilitiesClaimed {
              nextToken
              __typename
            }
            feeds {
              nextToken
              __typename
            }
            connectedJobPosts {
              nextToken
              __typename
            }
            ActivityStatus
            updatedAt
            owner
            __typename
          }
          JoinedSkills {
            items {
              id
              UserId
              SkillId
              CapabilityId
              GlobalCapabilityConfigId
              GlobalCapabilityConfigName
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      CandidatePreferences {
        items {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      JobApplications {
        items {
          id
          JobPostId
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          CompanyProfileId
          ProfileId
          CompanyPreferenceId
          companyQrewId
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ApplyingStatus
          Level
          CandidateProfileAnalysis
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      ConvoOnHidi {
        items {
          id
          Question
          Answer
          profileId
          UserId
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          PreferenceId
          companyProfileId
          CompanyProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          companyUserProfileId
          HidiId
          NewHidi {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          companyUser {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      ActivatedCandisatePreferencesID
      activeCandidatePreferences {
        createdAt
        id
        rolename
        jobType
        workType
        rolelevel
        benefits
        priority
        expectation
        Salaryexpectation
        ContractCompensation
        currentSalary
        companyType
        workStyle
        minimumContractPeriod
        servingNotice
        noticePeriod
        workSchedule
        Activelylooking
        contractType
        compensationStructure
        AvailableDate
        userDomain
        roletype
        location1
        location2
        location3
        Country
        CurrencyCountry
        ActivatePreference
        prevContractCount
        profileId
        UserProfile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        ProfilebyCandidatePreferencesId {
          items {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      ConnectedUserSegments {
        items {
          UserSegmentId
          UserId
          createdAt
          updatedAt
          id
          owner
          __typename
        }
        nextToken
        __typename
      }
      salarycurrent
      salaryexpectation
      certification
      noticeperiod
      currentoffer
      education
      experience
      expertise
      graduation
      image
      resume
      location
      imageicon1
      link1
      imageicon2
      link2
      imageicon3
      link3
      profileviews
      relevant
      connected
      imagekey
      typeofwork
      resumekey
      ConsentToShareInfo
      IntroVideo
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteNewprofile = /* GraphQL */ `
  subscription OnDeleteNewprofile(
    $filter: ModelSubscriptionNewprofileFilterInput
  ) {
    onDeleteNewprofile(filter: $filter) {
      createdAt
      name
      UserId
      Email
      id
      phone
      about
      hidis {
        items {
          createdAt
          Email
          id
          profileId
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          name
          UserId
          challenges {
            items {
              createdAt
              id
              challenge
              HidiId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          outcomes {
            items {
              createdAt
              id
              Outcome
              HidiId
              Verified
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConnectedCapability {
            items {
              CapabilityId
              HidiId
              createdAt
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          connectedFeeds {
            items {
              id
              UserSegmentId
              HidiId
              CapabilityConfigId
              jobPostId
              tags
              domain
              Description
              Payload
              FeedType
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          summary
          description
          title
          Verified
          likes
          views
          steps
          jobs
          outcomelink
          impact
          impactOverall
          imagelink
          outcome
          pinnedFlag
          tag1
          tag2
          htmlheading
          htmlsummary
          htmloutput
          peerEmails
          helped
          problem
          Hack
          experience
          projectLink
          whereitwasdone
          whereitwasdonecompany
          SkillUsed
          ExperinceInSkill
          outcomejson
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          AiGenerated
          ProbabilityFake
          ProbabilityReal
          hidiTopic
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      CandidateEducation {
        items {
          createdAt
          UserId
          id
          degree
          institute
          duration
          startDate
          EndDate
          Present
          percentage
          description
          location
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      deployedforqrews {
        items {
          createdAt
          updatedAt
          companyQrewId
          businessProfileId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          finalCTCQrew
          candidateprofileId
          qrewPosition
          deployedUserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          preferencesId
          JobPostPreferences {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          deployedQrewHistory {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              NewContractCTC
              ContractEndedBy
              ContractPausedBy
              JobApplicationsId
              deployedQrewId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          recommededId
          joiningDate
          endingDate
          NDAkey
          Contractkey
          KYCdocumentkey
          WorkingOnit
          EndContract
          ContractEndingDate
          ExtensionDurationInMonths
          ExtensionDate
          ExtendingContract
          CandidateAcceptanceStatus
          CandidateContractRejectionReason
          CandidateContractNegotiatingReason
          NewContractCTC
          ContractEndedBy
          ContractExtendedBy
          ContractPausedBy
          JobApplicationsId
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          editObjectiveHistory {
            items {
              id
              createdAt
              updatedAt
              objectiveId
              prevTitle
              editedbyCompanyUserProfileId
              editedbyDeployedQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          id
          owner
          __typename
        }
        nextToken
        __typename
      }
      CandidateExperience {
        items {
          createdAt
          UserId
          id
          startDate
          EndDate
          Present
          company
          designation
          description
          duration
          location
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      comapanyshortlists {
        items {
          createdAt
          businessProfileId
          businessUserId
          UserId
          about
          candidateprofileId
          shortlistedbyId
          JobPostId
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          shortlistedByUser {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          recommededId
          preferencesId
          prferenceType {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          id
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          businessProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      comapanyrejected {
        items {
          createdAt
          businessProfileId
          businessUserId
          shortlistedbyId
          shortlistedByUser {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          UserId
          about
          candidateprofileId
          Reasonofrejection
          recommededId
          preferencesId
          prferenceType {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          id
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          businessProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      inpreference {
        items {
          createdAt
          businessProfileId
          businessUserId
          UserId
          candidateprofileId
          acceptanceStatus
          ReasonofRecommendation
          ReasonofShortlist
          Reasonofrejection
          acceptanceStatus_by_CompanyUser_ID
          acceptanceStatus_by_CompanyUser_name
          recommendedbyId
          prferenceType {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          preferencesId
          companyQrewId
          JobApplicationsId
          JobApplication {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          JobPostId
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          businessProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          new
          id
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      Skills {
        items {
          createdAt
          profileId
          UserId
          description
          YearsOfExperience
          id
          Skill
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          JoinedCapabilities {
            items {
              id
              UserId
              SkillId
              CapabilityId
              GlobalCapabilityConfigId
              GlobalCapabilityConfigName
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      profileFeedback {
        items {
          createdAt
          id
          profileId
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          name
          designation
          description
          UserId
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      certifications {
        items {
          createdAt
          id
          Certificatename
          description
          Source
          certificateId
          validity
          UserId
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      Capabilities {
        items {
          createdAt
          ProfileId
          id
          PointsEarned {
            items {
              id
              Project
              Hack
              ProjectLink
              Video
              Photo
              Presentation
              experience
              Claim
              Outcomes
              PeersVouch
              GlobalCapabilityConfigId
              Helped
              Problem
              Challenges
              CapabilityPoints
              UserId
              capabilityPointsCapabilityClaimedId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          userProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ConnectedHidis {
            items {
              CapabilityId
              HidiId
              createdAt
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          capabilitiesCapabilityConfigId
          CapabilityConfig {
            id
            name
            tag
            domain
            createdAt
            weeklyActiveOpportunity
            capabilitiesClaimedCount
            CapabilityPoints
            capabilitiesClaimed {
              nextToken
              __typename
            }
            feeds {
              nextToken
              __typename
            }
            connectedJobPosts {
              nextToken
              __typename
            }
            ActivityStatus
            updatedAt
            owner
            __typename
          }
          JoinedSkills {
            items {
              id
              UserId
              SkillId
              CapabilityId
              GlobalCapabilityConfigId
              GlobalCapabilityConfigName
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      CandidatePreferences {
        items {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      JobApplications {
        items {
          id
          JobPostId
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          CompanyProfileId
          ProfileId
          CompanyPreferenceId
          companyQrewId
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ApplyingStatus
          Level
          CandidateProfileAnalysis
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      ConvoOnHidi {
        items {
          id
          Question
          Answer
          profileId
          UserId
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          PreferenceId
          companyProfileId
          CompanyProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          companyUserProfileId
          HidiId
          NewHidi {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          companyUser {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      ActivatedCandisatePreferencesID
      activeCandidatePreferences {
        createdAt
        id
        rolename
        jobType
        workType
        rolelevel
        benefits
        priority
        expectation
        Salaryexpectation
        ContractCompensation
        currentSalary
        companyType
        workStyle
        minimumContractPeriod
        servingNotice
        noticePeriod
        workSchedule
        Activelylooking
        contractType
        compensationStructure
        AvailableDate
        userDomain
        roletype
        location1
        location2
        location3
        Country
        CurrencyCountry
        ActivatePreference
        prevContractCount
        profileId
        UserProfile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        ProfilebyCandidatePreferencesId {
          items {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      ConnectedUserSegments {
        items {
          UserSegmentId
          UserId
          createdAt
          updatedAt
          id
          owner
          __typename
        }
        nextToken
        __typename
      }
      salarycurrent
      salaryexpectation
      certification
      noticeperiod
      currentoffer
      education
      experience
      expertise
      graduation
      image
      resume
      location
      imageicon1
      link1
      imageicon2
      link2
      imageicon3
      link3
      profileviews
      relevant
      connected
      imagekey
      typeofwork
      resumekey
      ConsentToShareInfo
      IntroVideo
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateSavedJobs = /* GraphQL */ `
  subscription OnCreateSavedJobs(
    $filter: ModelSubscriptionSavedJobsFilterInput
  ) {
    onCreateSavedJobs(filter: $filter) {
      id
      JobPostId
      ProfileId
      UserId
      jobPost {
        id
        CompanyPreferenceId
        companyQrewId
        CompanyPreference {
          createdAt
          id
          rolename
          businessUserId
          businessProfileId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          recommendedprofiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejectedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobPosts {
            items {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobTraits {
            items {
              id
              TraitName
              createdAt
              updatedAt
              preferencesID
              owner
              __typename
            }
            nextToken
            __typename
          }
          package
          certifications
          noticeperiod
          maxExperienceRequired
          minExperienceRequired
          modeofwork
          JobDomain
          other
          previousrole
          combinedrole
          currencyCountry
          JobType
          Location
          JobPublished
          numberOfCandidateRequired
          updatedAt
          owner
          __typename
        }
        companyName
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        shortlistedCandidates {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        jobPostQuestions {
          items {
            id
            JobPostId
            preferencesID
            Question
            TypeOfQuesiton
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        jobPostLink
        logoUrl
        htmldescription
        CompanyDescription
        AvailablityStatus
        experienceRequired
        jobType
        Location
        skills
        domain
        description
        ShortDesc
        RolesandResponsibility
        title
        CapabilitiesRequired {
          items {
            id
            jobPostID
            GlobalCapabilityConfigID
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        FeedsConnected {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CapabilitiesRequiredJSON
        createdAt
        updatedAt
        SavedJobPosts {
          items {
            id
            JobPostId
            ProfileId
            UserId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateSavedJobs = /* GraphQL */ `
  subscription OnUpdateSavedJobs(
    $filter: ModelSubscriptionSavedJobsFilterInput
  ) {
    onUpdateSavedJobs(filter: $filter) {
      id
      JobPostId
      ProfileId
      UserId
      jobPost {
        id
        CompanyPreferenceId
        companyQrewId
        CompanyPreference {
          createdAt
          id
          rolename
          businessUserId
          businessProfileId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          recommendedprofiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejectedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobPosts {
            items {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobTraits {
            items {
              id
              TraitName
              createdAt
              updatedAt
              preferencesID
              owner
              __typename
            }
            nextToken
            __typename
          }
          package
          certifications
          noticeperiod
          maxExperienceRequired
          minExperienceRequired
          modeofwork
          JobDomain
          other
          previousrole
          combinedrole
          currencyCountry
          JobType
          Location
          JobPublished
          numberOfCandidateRequired
          updatedAt
          owner
          __typename
        }
        companyName
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        shortlistedCandidates {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        jobPostQuestions {
          items {
            id
            JobPostId
            preferencesID
            Question
            TypeOfQuesiton
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        jobPostLink
        logoUrl
        htmldescription
        CompanyDescription
        AvailablityStatus
        experienceRequired
        jobType
        Location
        skills
        domain
        description
        ShortDesc
        RolesandResponsibility
        title
        CapabilitiesRequired {
          items {
            id
            jobPostID
            GlobalCapabilityConfigID
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        FeedsConnected {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CapabilitiesRequiredJSON
        createdAt
        updatedAt
        SavedJobPosts {
          items {
            id
            JobPostId
            ProfileId
            UserId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteSavedJobs = /* GraphQL */ `
  subscription OnDeleteSavedJobs(
    $filter: ModelSubscriptionSavedJobsFilterInput
  ) {
    onDeleteSavedJobs(filter: $filter) {
      id
      JobPostId
      ProfileId
      UserId
      jobPost {
        id
        CompanyPreferenceId
        companyQrewId
        CompanyPreference {
          createdAt
          id
          rolename
          businessUserId
          businessProfileId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          recommendedprofiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejectedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobPosts {
            items {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobTraits {
            items {
              id
              TraitName
              createdAt
              updatedAt
              preferencesID
              owner
              __typename
            }
            nextToken
            __typename
          }
          package
          certifications
          noticeperiod
          maxExperienceRequired
          minExperienceRequired
          modeofwork
          JobDomain
          other
          previousrole
          combinedrole
          currencyCountry
          JobType
          Location
          JobPublished
          numberOfCandidateRequired
          updatedAt
          owner
          __typename
        }
        companyName
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        shortlistedCandidates {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        jobPostQuestions {
          items {
            id
            JobPostId
            preferencesID
            Question
            TypeOfQuesiton
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        jobPostLink
        logoUrl
        htmldescription
        CompanyDescription
        AvailablityStatus
        experienceRequired
        jobType
        Location
        skills
        domain
        description
        ShortDesc
        RolesandResponsibility
        title
        CapabilitiesRequired {
          items {
            id
            jobPostID
            GlobalCapabilityConfigID
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        FeedsConnected {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CapabilitiesRequiredJSON
        createdAt
        updatedAt
        SavedJobPosts {
          items {
            id
            JobPostId
            ProfileId
            UserId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateCandidatePreferences = /* GraphQL */ `
  subscription OnCreateCandidatePreferences(
    $filter: ModelSubscriptionCandidatePreferencesFilterInput
  ) {
    onCreateCandidatePreferences(filter: $filter) {
      createdAt
      id
      rolename
      jobType
      workType
      rolelevel
      benefits
      priority
      expectation
      Salaryexpectation
      ContractCompensation
      currentSalary
      companyType
      workStyle
      minimumContractPeriod
      servingNotice
      noticePeriod
      workSchedule
      Activelylooking
      contractType
      compensationStructure
      AvailableDate
      userDomain
      roletype
      location1
      location2
      location3
      Country
      CurrencyCountry
      ActivatePreference
      prevContractCount
      profileId
      UserProfile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      ProfilebyCandidatePreferencesId {
        items {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateCandidatePreferences = /* GraphQL */ `
  subscription OnUpdateCandidatePreferences(
    $filter: ModelSubscriptionCandidatePreferencesFilterInput
  ) {
    onUpdateCandidatePreferences(filter: $filter) {
      createdAt
      id
      rolename
      jobType
      workType
      rolelevel
      benefits
      priority
      expectation
      Salaryexpectation
      ContractCompensation
      currentSalary
      companyType
      workStyle
      minimumContractPeriod
      servingNotice
      noticePeriod
      workSchedule
      Activelylooking
      contractType
      compensationStructure
      AvailableDate
      userDomain
      roletype
      location1
      location2
      location3
      Country
      CurrencyCountry
      ActivatePreference
      prevContractCount
      profileId
      UserProfile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      ProfilebyCandidatePreferencesId {
        items {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteCandidatePreferences = /* GraphQL */ `
  subscription OnDeleteCandidatePreferences(
    $filter: ModelSubscriptionCandidatePreferencesFilterInput
  ) {
    onDeleteCandidatePreferences(filter: $filter) {
      createdAt
      id
      rolename
      jobType
      workType
      rolelevel
      benefits
      priority
      expectation
      Salaryexpectation
      ContractCompensation
      currentSalary
      companyType
      workStyle
      minimumContractPeriod
      servingNotice
      noticePeriod
      workSchedule
      Activelylooking
      contractType
      compensationStructure
      AvailableDate
      userDomain
      roletype
      location1
      location2
      location3
      Country
      CurrencyCountry
      ActivatePreference
      prevContractCount
      profileId
      UserProfile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      ProfilebyCandidatePreferencesId {
        items {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateNewProfileFeedback = /* GraphQL */ `
  subscription OnCreateNewProfileFeedback(
    $filter: ModelSubscriptionNewProfileFeedbackFilterInput
  ) {
    onCreateNewProfileFeedback(filter: $filter) {
      createdAt
      id
      profileId
      profile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      name
      designation
      description
      UserId
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateNewProfileFeedback = /* GraphQL */ `
  subscription OnUpdateNewProfileFeedback(
    $filter: ModelSubscriptionNewProfileFeedbackFilterInput
  ) {
    onUpdateNewProfileFeedback(filter: $filter) {
      createdAt
      id
      profileId
      profile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      name
      designation
      description
      UserId
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteNewProfileFeedback = /* GraphQL */ `
  subscription OnDeleteNewProfileFeedback(
    $filter: ModelSubscriptionNewProfileFeedbackFilterInput
  ) {
    onDeleteNewProfileFeedback(filter: $filter) {
      createdAt
      id
      profileId
      profile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      name
      designation
      description
      UserId
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateCertifications = /* GraphQL */ `
  subscription OnCreateCertifications(
    $filter: ModelSubscriptionCertificationsFilterInput
  ) {
    onCreateCertifications(filter: $filter) {
      createdAt
      id
      Certificatename
      description
      Source
      certificateId
      validity
      UserId
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateCertifications = /* GraphQL */ `
  subscription OnUpdateCertifications(
    $filter: ModelSubscriptionCertificationsFilterInput
  ) {
    onUpdateCertifications(filter: $filter) {
      createdAt
      id
      Certificatename
      description
      Source
      certificateId
      validity
      UserId
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteCertifications = /* GraphQL */ `
  subscription OnDeleteCertifications(
    $filter: ModelSubscriptionCertificationsFilterInput
  ) {
    onDeleteCertifications(filter: $filter) {
      createdAt
      id
      Certificatename
      description
      Source
      certificateId
      validity
      UserId
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateNewhidi = /* GraphQL */ `
  subscription OnCreateNewhidi($filter: ModelSubscriptionNewhidiFilterInput) {
    onCreateNewhidi(filter: $filter) {
      createdAt
      Email
      id
      profileId
      profile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      name
      UserId
      challenges {
        items {
          createdAt
          id
          Hidi {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          challenge
          HidiId
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      outcomes {
        items {
          createdAt
          id
          Hidi {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          Outcome
          HidiId
          Verified
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      ConnectedCapability {
        items {
          CapabilityId
          HidiId
          Capability {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Hidi {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          createdAt
          id
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      connectedFeeds {
        items {
          id
          UserSegmentId
          HidiId
          Hidi {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          CapabilityConfigId
          jobPostId
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          tags
          domain
          CapabilityConfig {
            id
            name
            tag
            domain
            createdAt
            weeklyActiveOpportunity
            capabilitiesClaimedCount
            CapabilityPoints
            capabilitiesClaimed {
              nextToken
              __typename
            }
            feeds {
              nextToken
              __typename
            }
            connectedJobPosts {
              nextToken
              __typename
            }
            ActivityStatus
            updatedAt
            owner
            __typename
          }
          Description
          Payload
          FeedType
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      summary
      description
      title
      Verified
      likes
      views
      steps
      jobs
      outcomelink
      impact
      impactOverall
      imagelink
      outcome
      pinnedFlag
      tag1
      tag2
      htmlheading
      htmlsummary
      htmloutput
      peerEmails
      helped
      problem
      Hack
      experience
      projectLink
      whereitwasdone
      whereitwasdonecompany
      SkillUsed
      ExperinceInSkill
      outcomejson
      ConvoOnHidi {
        items {
          id
          Question
          Answer
          profileId
          UserId
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          PreferenceId
          companyProfileId
          CompanyProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          companyUserProfileId
          HidiId
          NewHidi {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          companyUser {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      AiGenerated
      ProbabilityFake
      ProbabilityReal
      hidiTopic
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateNewhidi = /* GraphQL */ `
  subscription OnUpdateNewhidi($filter: ModelSubscriptionNewhidiFilterInput) {
    onUpdateNewhidi(filter: $filter) {
      createdAt
      Email
      id
      profileId
      profile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      name
      UserId
      challenges {
        items {
          createdAt
          id
          Hidi {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          challenge
          HidiId
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      outcomes {
        items {
          createdAt
          id
          Hidi {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          Outcome
          HidiId
          Verified
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      ConnectedCapability {
        items {
          CapabilityId
          HidiId
          Capability {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Hidi {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          createdAt
          id
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      connectedFeeds {
        items {
          id
          UserSegmentId
          HidiId
          Hidi {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          CapabilityConfigId
          jobPostId
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          tags
          domain
          CapabilityConfig {
            id
            name
            tag
            domain
            createdAt
            weeklyActiveOpportunity
            capabilitiesClaimedCount
            CapabilityPoints
            capabilitiesClaimed {
              nextToken
              __typename
            }
            feeds {
              nextToken
              __typename
            }
            connectedJobPosts {
              nextToken
              __typename
            }
            ActivityStatus
            updatedAt
            owner
            __typename
          }
          Description
          Payload
          FeedType
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      summary
      description
      title
      Verified
      likes
      views
      steps
      jobs
      outcomelink
      impact
      impactOverall
      imagelink
      outcome
      pinnedFlag
      tag1
      tag2
      htmlheading
      htmlsummary
      htmloutput
      peerEmails
      helped
      problem
      Hack
      experience
      projectLink
      whereitwasdone
      whereitwasdonecompany
      SkillUsed
      ExperinceInSkill
      outcomejson
      ConvoOnHidi {
        items {
          id
          Question
          Answer
          profileId
          UserId
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          PreferenceId
          companyProfileId
          CompanyProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          companyUserProfileId
          HidiId
          NewHidi {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          companyUser {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      AiGenerated
      ProbabilityFake
      ProbabilityReal
      hidiTopic
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteNewhidi = /* GraphQL */ `
  subscription OnDeleteNewhidi($filter: ModelSubscriptionNewhidiFilterInput) {
    onDeleteNewhidi(filter: $filter) {
      createdAt
      Email
      id
      profileId
      profile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      name
      UserId
      challenges {
        items {
          createdAt
          id
          Hidi {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          challenge
          HidiId
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      outcomes {
        items {
          createdAt
          id
          Hidi {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          Outcome
          HidiId
          Verified
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      ConnectedCapability {
        items {
          CapabilityId
          HidiId
          Capability {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Hidi {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          createdAt
          id
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      connectedFeeds {
        items {
          id
          UserSegmentId
          HidiId
          Hidi {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          CapabilityConfigId
          jobPostId
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          tags
          domain
          CapabilityConfig {
            id
            name
            tag
            domain
            createdAt
            weeklyActiveOpportunity
            capabilitiesClaimedCount
            CapabilityPoints
            capabilitiesClaimed {
              nextToken
              __typename
            }
            feeds {
              nextToken
              __typename
            }
            connectedJobPosts {
              nextToken
              __typename
            }
            ActivityStatus
            updatedAt
            owner
            __typename
          }
          Description
          Payload
          FeedType
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      summary
      description
      title
      Verified
      likes
      views
      steps
      jobs
      outcomelink
      impact
      impactOverall
      imagelink
      outcome
      pinnedFlag
      tag1
      tag2
      htmlheading
      htmlsummary
      htmloutput
      peerEmails
      helped
      problem
      Hack
      experience
      projectLink
      whereitwasdone
      whereitwasdonecompany
      SkillUsed
      ExperinceInSkill
      outcomejson
      ConvoOnHidi {
        items {
          id
          Question
          Answer
          profileId
          UserId
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          PreferenceId
          companyProfileId
          CompanyProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          companyUserProfileId
          HidiId
          NewHidi {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          companyUser {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      AiGenerated
      ProbabilityFake
      ProbabilityReal
      hidiTopic
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateConvoOnHidi = /* GraphQL */ `
  subscription OnCreateConvoOnHidi(
    $filter: ModelSubscriptionConvoOnHidiFilterInput
  ) {
    onCreateConvoOnHidi(filter: $filter) {
      id
      Question
      Answer
      profileId
      UserId
      profile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      PreferenceId
      companyProfileId
      CompanyProfile {
        createdAt
        businessUserId
        name
        companyMailId
        id
        preferences
        about
        websiteUrl
        LogoImageKey
        IndustryType
        Shortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CompanyQrew {
          items {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        prefferedprofile {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        preferenceslist {
          items {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        companyusers {
          items {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      companyUserProfileId
      HidiId
      NewHidi {
        createdAt
        Email
        id
        profileId
        profile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        name
        UserId
        challenges {
          items {
            createdAt
            id
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            challenge
            HidiId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        outcomes {
          items {
            createdAt
            id
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            Outcome
            HidiId
            Verified
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConnectedCapability {
          items {
            CapabilityId
            HidiId
            Capability {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            createdAt
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        connectedFeeds {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        summary
        description
        title
        Verified
        likes
        views
        steps
        jobs
        outcomelink
        impact
        impactOverall
        imagelink
        outcome
        pinnedFlag
        tag1
        tag2
        htmlheading
        htmlsummary
        htmloutput
        peerEmails
        helped
        problem
        Hack
        experience
        projectLink
        whereitwasdone
        whereitwasdonecompany
        SkillUsed
        ExperinceInSkill
        outcomejson
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        AiGenerated
        ProbabilityFake
        ProbabilityReal
        hidiTopic
        updatedAt
        owner
        __typename
      }
      companyUser {
        createdAt
        username
        designation
        ProfilePictureKey
        businessProfileId
        id
        cognitoId
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        Shortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        UserRole
        editObjectiveHistory {
          items {
            id
            createdAt
            updatedAt
            objectiveId
            Objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            prevTitle
            editedbyCompanyUserProfileId
            editbyCompanyUserProfile {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            editedbyDeployedQrewId
            editedDeployedQrewProfile {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        email
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateConvoOnHidi = /* GraphQL */ `
  subscription OnUpdateConvoOnHidi(
    $filter: ModelSubscriptionConvoOnHidiFilterInput
  ) {
    onUpdateConvoOnHidi(filter: $filter) {
      id
      Question
      Answer
      profileId
      UserId
      profile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      PreferenceId
      companyProfileId
      CompanyProfile {
        createdAt
        businessUserId
        name
        companyMailId
        id
        preferences
        about
        websiteUrl
        LogoImageKey
        IndustryType
        Shortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CompanyQrew {
          items {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        prefferedprofile {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        preferenceslist {
          items {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        companyusers {
          items {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      companyUserProfileId
      HidiId
      NewHidi {
        createdAt
        Email
        id
        profileId
        profile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        name
        UserId
        challenges {
          items {
            createdAt
            id
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            challenge
            HidiId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        outcomes {
          items {
            createdAt
            id
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            Outcome
            HidiId
            Verified
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConnectedCapability {
          items {
            CapabilityId
            HidiId
            Capability {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            createdAt
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        connectedFeeds {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        summary
        description
        title
        Verified
        likes
        views
        steps
        jobs
        outcomelink
        impact
        impactOverall
        imagelink
        outcome
        pinnedFlag
        tag1
        tag2
        htmlheading
        htmlsummary
        htmloutput
        peerEmails
        helped
        problem
        Hack
        experience
        projectLink
        whereitwasdone
        whereitwasdonecompany
        SkillUsed
        ExperinceInSkill
        outcomejson
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        AiGenerated
        ProbabilityFake
        ProbabilityReal
        hidiTopic
        updatedAt
        owner
        __typename
      }
      companyUser {
        createdAt
        username
        designation
        ProfilePictureKey
        businessProfileId
        id
        cognitoId
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        Shortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        UserRole
        editObjectiveHistory {
          items {
            id
            createdAt
            updatedAt
            objectiveId
            Objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            prevTitle
            editedbyCompanyUserProfileId
            editbyCompanyUserProfile {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            editedbyDeployedQrewId
            editedDeployedQrewProfile {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        email
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteConvoOnHidi = /* GraphQL */ `
  subscription OnDeleteConvoOnHidi(
    $filter: ModelSubscriptionConvoOnHidiFilterInput
  ) {
    onDeleteConvoOnHidi(filter: $filter) {
      id
      Question
      Answer
      profileId
      UserId
      profile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      PreferenceId
      companyProfileId
      CompanyProfile {
        createdAt
        businessUserId
        name
        companyMailId
        id
        preferences
        about
        websiteUrl
        LogoImageKey
        IndustryType
        Shortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CompanyQrew {
          items {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        prefferedprofile {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        preferenceslist {
          items {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        companyusers {
          items {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      companyUserProfileId
      HidiId
      NewHidi {
        createdAt
        Email
        id
        profileId
        profile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        name
        UserId
        challenges {
          items {
            createdAt
            id
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            challenge
            HidiId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        outcomes {
          items {
            createdAt
            id
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            Outcome
            HidiId
            Verified
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConnectedCapability {
          items {
            CapabilityId
            HidiId
            Capability {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            createdAt
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        connectedFeeds {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        summary
        description
        title
        Verified
        likes
        views
        steps
        jobs
        outcomelink
        impact
        impactOverall
        imagelink
        outcome
        pinnedFlag
        tag1
        tag2
        htmlheading
        htmlsummary
        htmloutput
        peerEmails
        helped
        problem
        Hack
        experience
        projectLink
        whereitwasdone
        whereitwasdonecompany
        SkillUsed
        ExperinceInSkill
        outcomejson
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        AiGenerated
        ProbabilityFake
        ProbabilityReal
        hidiTopic
        updatedAt
        owner
        __typename
      }
      companyUser {
        createdAt
        username
        designation
        ProfilePictureKey
        businessProfileId
        id
        cognitoId
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        Shortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        UserRole
        editObjectiveHistory {
          items {
            id
            createdAt
            updatedAt
            objectiveId
            Objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            prevTitle
            editedbyCompanyUserProfileId
            editbyCompanyUserProfile {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            editedbyDeployedQrewId
            editedDeployedQrewProfile {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        email
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateOutcome = /* GraphQL */ `
  subscription OnCreateOutcome($filter: ModelSubscriptionOutcomeFilterInput) {
    onCreateOutcome(filter: $filter) {
      createdAt
      id
      Hidi {
        createdAt
        Email
        id
        profileId
        profile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        name
        UserId
        challenges {
          items {
            createdAt
            id
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            challenge
            HidiId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        outcomes {
          items {
            createdAt
            id
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            Outcome
            HidiId
            Verified
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConnectedCapability {
          items {
            CapabilityId
            HidiId
            Capability {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            createdAt
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        connectedFeeds {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        summary
        description
        title
        Verified
        likes
        views
        steps
        jobs
        outcomelink
        impact
        impactOverall
        imagelink
        outcome
        pinnedFlag
        tag1
        tag2
        htmlheading
        htmlsummary
        htmloutput
        peerEmails
        helped
        problem
        Hack
        experience
        projectLink
        whereitwasdone
        whereitwasdonecompany
        SkillUsed
        ExperinceInSkill
        outcomejson
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        AiGenerated
        ProbabilityFake
        ProbabilityReal
        hidiTopic
        updatedAt
        owner
        __typename
      }
      Outcome
      HidiId
      Verified
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateOutcome = /* GraphQL */ `
  subscription OnUpdateOutcome($filter: ModelSubscriptionOutcomeFilterInput) {
    onUpdateOutcome(filter: $filter) {
      createdAt
      id
      Hidi {
        createdAt
        Email
        id
        profileId
        profile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        name
        UserId
        challenges {
          items {
            createdAt
            id
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            challenge
            HidiId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        outcomes {
          items {
            createdAt
            id
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            Outcome
            HidiId
            Verified
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConnectedCapability {
          items {
            CapabilityId
            HidiId
            Capability {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            createdAt
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        connectedFeeds {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        summary
        description
        title
        Verified
        likes
        views
        steps
        jobs
        outcomelink
        impact
        impactOverall
        imagelink
        outcome
        pinnedFlag
        tag1
        tag2
        htmlheading
        htmlsummary
        htmloutput
        peerEmails
        helped
        problem
        Hack
        experience
        projectLink
        whereitwasdone
        whereitwasdonecompany
        SkillUsed
        ExperinceInSkill
        outcomejson
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        AiGenerated
        ProbabilityFake
        ProbabilityReal
        hidiTopic
        updatedAt
        owner
        __typename
      }
      Outcome
      HidiId
      Verified
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteOutcome = /* GraphQL */ `
  subscription OnDeleteOutcome($filter: ModelSubscriptionOutcomeFilterInput) {
    onDeleteOutcome(filter: $filter) {
      createdAt
      id
      Hidi {
        createdAt
        Email
        id
        profileId
        profile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        name
        UserId
        challenges {
          items {
            createdAt
            id
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            challenge
            HidiId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        outcomes {
          items {
            createdAt
            id
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            Outcome
            HidiId
            Verified
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConnectedCapability {
          items {
            CapabilityId
            HidiId
            Capability {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            createdAt
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        connectedFeeds {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        summary
        description
        title
        Verified
        likes
        views
        steps
        jobs
        outcomelink
        impact
        impactOverall
        imagelink
        outcome
        pinnedFlag
        tag1
        tag2
        htmlheading
        htmlsummary
        htmloutput
        peerEmails
        helped
        problem
        Hack
        experience
        projectLink
        whereitwasdone
        whereitwasdonecompany
        SkillUsed
        ExperinceInSkill
        outcomejson
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        AiGenerated
        ProbabilityFake
        ProbabilityReal
        hidiTopic
        updatedAt
        owner
        __typename
      }
      Outcome
      HidiId
      Verified
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateChallenges = /* GraphQL */ `
  subscription OnCreateChallenges(
    $filter: ModelSubscriptionChallengesFilterInput
  ) {
    onCreateChallenges(filter: $filter) {
      createdAt
      id
      Hidi {
        createdAt
        Email
        id
        profileId
        profile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        name
        UserId
        challenges {
          items {
            createdAt
            id
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            challenge
            HidiId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        outcomes {
          items {
            createdAt
            id
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            Outcome
            HidiId
            Verified
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConnectedCapability {
          items {
            CapabilityId
            HidiId
            Capability {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            createdAt
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        connectedFeeds {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        summary
        description
        title
        Verified
        likes
        views
        steps
        jobs
        outcomelink
        impact
        impactOverall
        imagelink
        outcome
        pinnedFlag
        tag1
        tag2
        htmlheading
        htmlsummary
        htmloutput
        peerEmails
        helped
        problem
        Hack
        experience
        projectLink
        whereitwasdone
        whereitwasdonecompany
        SkillUsed
        ExperinceInSkill
        outcomejson
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        AiGenerated
        ProbabilityFake
        ProbabilityReal
        hidiTopic
        updatedAt
        owner
        __typename
      }
      challenge
      HidiId
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateChallenges = /* GraphQL */ `
  subscription OnUpdateChallenges(
    $filter: ModelSubscriptionChallengesFilterInput
  ) {
    onUpdateChallenges(filter: $filter) {
      createdAt
      id
      Hidi {
        createdAt
        Email
        id
        profileId
        profile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        name
        UserId
        challenges {
          items {
            createdAt
            id
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            challenge
            HidiId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        outcomes {
          items {
            createdAt
            id
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            Outcome
            HidiId
            Verified
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConnectedCapability {
          items {
            CapabilityId
            HidiId
            Capability {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            createdAt
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        connectedFeeds {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        summary
        description
        title
        Verified
        likes
        views
        steps
        jobs
        outcomelink
        impact
        impactOverall
        imagelink
        outcome
        pinnedFlag
        tag1
        tag2
        htmlheading
        htmlsummary
        htmloutput
        peerEmails
        helped
        problem
        Hack
        experience
        projectLink
        whereitwasdone
        whereitwasdonecompany
        SkillUsed
        ExperinceInSkill
        outcomejson
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        AiGenerated
        ProbabilityFake
        ProbabilityReal
        hidiTopic
        updatedAt
        owner
        __typename
      }
      challenge
      HidiId
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteChallenges = /* GraphQL */ `
  subscription OnDeleteChallenges(
    $filter: ModelSubscriptionChallengesFilterInput
  ) {
    onDeleteChallenges(filter: $filter) {
      createdAt
      id
      Hidi {
        createdAt
        Email
        id
        profileId
        profile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        name
        UserId
        challenges {
          items {
            createdAt
            id
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            challenge
            HidiId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        outcomes {
          items {
            createdAt
            id
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            Outcome
            HidiId
            Verified
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConnectedCapability {
          items {
            CapabilityId
            HidiId
            Capability {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            createdAt
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        connectedFeeds {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        summary
        description
        title
        Verified
        likes
        views
        steps
        jobs
        outcomelink
        impact
        impactOverall
        imagelink
        outcome
        pinnedFlag
        tag1
        tag2
        htmlheading
        htmlsummary
        htmloutput
        peerEmails
        helped
        problem
        Hack
        experience
        projectLink
        whereitwasdone
        whereitwasdonecompany
        SkillUsed
        ExperinceInSkill
        outcomejson
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        AiGenerated
        ProbabilityFake
        ProbabilityReal
        hidiTopic
        updatedAt
        owner
        __typename
      }
      challenge
      HidiId
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateTimeline = /* GraphQL */ `
  subscription OnCreateTimeline($filter: ModelSubscriptionTimelineFilterInput) {
    onCreateTimeline(filter: $filter) {
      createdAt
      UserId
      profileId
      description
      id
      Timelinestring
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateTimeline = /* GraphQL */ `
  subscription OnUpdateTimeline($filter: ModelSubscriptionTimelineFilterInput) {
    onUpdateTimeline(filter: $filter) {
      createdAt
      UserId
      profileId
      description
      id
      Timelinestring
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteTimeline = /* GraphQL */ `
  subscription OnDeleteTimeline($filter: ModelSubscriptionTimelineFilterInput) {
    onDeleteTimeline(filter: $filter) {
      createdAt
      UserId
      profileId
      description
      id
      Timelinestring
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateCandidateExperience = /* GraphQL */ `
  subscription OnCreateCandidateExperience(
    $filter: ModelSubscriptionCandidateExperienceFilterInput
  ) {
    onCreateCandidateExperience(filter: $filter) {
      createdAt
      UserId
      id
      startDate
      EndDate
      Present
      company
      designation
      description
      duration
      location
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateCandidateExperience = /* GraphQL */ `
  subscription OnUpdateCandidateExperience(
    $filter: ModelSubscriptionCandidateExperienceFilterInput
  ) {
    onUpdateCandidateExperience(filter: $filter) {
      createdAt
      UserId
      id
      startDate
      EndDate
      Present
      company
      designation
      description
      duration
      location
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteCandidateExperience = /* GraphQL */ `
  subscription OnDeleteCandidateExperience(
    $filter: ModelSubscriptionCandidateExperienceFilterInput
  ) {
    onDeleteCandidateExperience(filter: $filter) {
      createdAt
      UserId
      id
      startDate
      EndDate
      Present
      company
      designation
      description
      duration
      location
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateCandidateEducation = /* GraphQL */ `
  subscription OnCreateCandidateEducation(
    $filter: ModelSubscriptionCandidateEducationFilterInput
  ) {
    onCreateCandidateEducation(filter: $filter) {
      createdAt
      UserId
      id
      degree
      institute
      duration
      startDate
      EndDate
      Present
      percentage
      description
      location
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateCandidateEducation = /* GraphQL */ `
  subscription OnUpdateCandidateEducation(
    $filter: ModelSubscriptionCandidateEducationFilterInput
  ) {
    onUpdateCandidateEducation(filter: $filter) {
      createdAt
      UserId
      id
      degree
      institute
      duration
      startDate
      EndDate
      Present
      percentage
      description
      location
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteCandidateEducation = /* GraphQL */ `
  subscription OnDeleteCandidateEducation(
    $filter: ModelSubscriptionCandidateEducationFilterInput
  ) {
    onDeleteCandidateEducation(filter: $filter) {
      createdAt
      UserId
      id
      degree
      institute
      duration
      startDate
      EndDate
      Present
      percentage
      description
      location
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateCompanyprofile = /* GraphQL */ `
  subscription OnCreateCompanyprofile(
    $filter: ModelSubscriptionCompanyprofileFilterInput
  ) {
    onCreateCompanyprofile(filter: $filter) {
      createdAt
      businessUserId
      name
      companyMailId
      id
      preferences
      about
      websiteUrl
      LogoImageKey
      IndustryType
      Shortlists {
        items {
          createdAt
          businessProfileId
          businessUserId
          UserId
          about
          candidateprofileId
          shortlistedbyId
          JobPostId
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          shortlistedByUser {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          recommededId
          preferencesId
          prferenceType {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          id
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          businessProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      CompanyQrew {
        items {
          id
          qrewname
          aboutqrew
          businessProfileId
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Invoices {
            items {
              id
              CtcInvoiceNumber
              PlatformInvoiceNumber
              CompanyQrewId
              businessProfileId
              companyName
              CtcInvoiceS3Key
              PlarformInvoiceS3Key
              CtcTxnNumber
              PlatformTxnNumber
              CtcTxnNumberApproved
              PlatformTxnNumberApproved
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          qrewroles {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          objectives {
            items {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          durationInmonths
          qrewLive
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      prefferedprofile {
        items {
          createdAt
          businessProfileId
          businessUserId
          UserId
          candidateprofileId
          acceptanceStatus
          ReasonofRecommendation
          ReasonofShortlist
          Reasonofrejection
          acceptanceStatus_by_CompanyUser_ID
          acceptanceStatus_by_CompanyUser_name
          recommendedbyId
          prferenceType {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          preferencesId
          companyQrewId
          JobApplicationsId
          JobApplication {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          JobPostId
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          businessProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          new
          id
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      preferenceslist {
        items {
          createdAt
          id
          rolename
          businessUserId
          businessProfileId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          recommendedprofiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejectedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobPosts {
            items {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobTraits {
            items {
              id
              TraitName
              createdAt
              updatedAt
              preferencesID
              owner
              __typename
            }
            nextToken
            __typename
          }
          package
          certifications
          noticeperiod
          maxExperienceRequired
          minExperienceRequired
          modeofwork
          JobDomain
          other
          previousrole
          combinedrole
          currencyCountry
          JobType
          Location
          JobPublished
          numberOfCandidateRequired
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      rejected {
        items {
          createdAt
          businessProfileId
          businessUserId
          shortlistedbyId
          shortlistedByUser {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          UserId
          about
          candidateprofileId
          Reasonofrejection
          recommededId
          preferencesId
          prferenceType {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          id
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          businessProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      companyusers {
        items {
          createdAt
          username
          designation
          ProfilePictureKey
          businessProfileId
          id
          cognitoId
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          UserRole
          editObjectiveHistory {
            items {
              id
              createdAt
              updatedAt
              objectiveId
              prevTitle
              editedbyCompanyUserProfileId
              editedbyDeployedQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          email
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      ConvoOnHidi {
        items {
          id
          Question
          Answer
          profileId
          UserId
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          PreferenceId
          companyProfileId
          CompanyProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          companyUserProfileId
          HidiId
          NewHidi {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          companyUser {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateCompanyprofile = /* GraphQL */ `
  subscription OnUpdateCompanyprofile(
    $filter: ModelSubscriptionCompanyprofileFilterInput
  ) {
    onUpdateCompanyprofile(filter: $filter) {
      createdAt
      businessUserId
      name
      companyMailId
      id
      preferences
      about
      websiteUrl
      LogoImageKey
      IndustryType
      Shortlists {
        items {
          createdAt
          businessProfileId
          businessUserId
          UserId
          about
          candidateprofileId
          shortlistedbyId
          JobPostId
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          shortlistedByUser {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          recommededId
          preferencesId
          prferenceType {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          id
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          businessProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      CompanyQrew {
        items {
          id
          qrewname
          aboutqrew
          businessProfileId
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Invoices {
            items {
              id
              CtcInvoiceNumber
              PlatformInvoiceNumber
              CompanyQrewId
              businessProfileId
              companyName
              CtcInvoiceS3Key
              PlarformInvoiceS3Key
              CtcTxnNumber
              PlatformTxnNumber
              CtcTxnNumberApproved
              PlatformTxnNumberApproved
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          qrewroles {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          objectives {
            items {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          durationInmonths
          qrewLive
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      prefferedprofile {
        items {
          createdAt
          businessProfileId
          businessUserId
          UserId
          candidateprofileId
          acceptanceStatus
          ReasonofRecommendation
          ReasonofShortlist
          Reasonofrejection
          acceptanceStatus_by_CompanyUser_ID
          acceptanceStatus_by_CompanyUser_name
          recommendedbyId
          prferenceType {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          preferencesId
          companyQrewId
          JobApplicationsId
          JobApplication {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          JobPostId
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          businessProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          new
          id
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      preferenceslist {
        items {
          createdAt
          id
          rolename
          businessUserId
          businessProfileId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          recommendedprofiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejectedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobPosts {
            items {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobTraits {
            items {
              id
              TraitName
              createdAt
              updatedAt
              preferencesID
              owner
              __typename
            }
            nextToken
            __typename
          }
          package
          certifications
          noticeperiod
          maxExperienceRequired
          minExperienceRequired
          modeofwork
          JobDomain
          other
          previousrole
          combinedrole
          currencyCountry
          JobType
          Location
          JobPublished
          numberOfCandidateRequired
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      rejected {
        items {
          createdAt
          businessProfileId
          businessUserId
          shortlistedbyId
          shortlistedByUser {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          UserId
          about
          candidateprofileId
          Reasonofrejection
          recommededId
          preferencesId
          prferenceType {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          id
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          businessProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      companyusers {
        items {
          createdAt
          username
          designation
          ProfilePictureKey
          businessProfileId
          id
          cognitoId
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          UserRole
          editObjectiveHistory {
            items {
              id
              createdAt
              updatedAt
              objectiveId
              prevTitle
              editedbyCompanyUserProfileId
              editedbyDeployedQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          email
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      ConvoOnHidi {
        items {
          id
          Question
          Answer
          profileId
          UserId
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          PreferenceId
          companyProfileId
          CompanyProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          companyUserProfileId
          HidiId
          NewHidi {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          companyUser {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteCompanyprofile = /* GraphQL */ `
  subscription OnDeleteCompanyprofile(
    $filter: ModelSubscriptionCompanyprofileFilterInput
  ) {
    onDeleteCompanyprofile(filter: $filter) {
      createdAt
      businessUserId
      name
      companyMailId
      id
      preferences
      about
      websiteUrl
      LogoImageKey
      IndustryType
      Shortlists {
        items {
          createdAt
          businessProfileId
          businessUserId
          UserId
          about
          candidateprofileId
          shortlistedbyId
          JobPostId
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          shortlistedByUser {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          recommededId
          preferencesId
          prferenceType {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          id
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          businessProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      CompanyQrew {
        items {
          id
          qrewname
          aboutqrew
          businessProfileId
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Invoices {
            items {
              id
              CtcInvoiceNumber
              PlatformInvoiceNumber
              CompanyQrewId
              businessProfileId
              companyName
              CtcInvoiceS3Key
              PlarformInvoiceS3Key
              CtcTxnNumber
              PlatformTxnNumber
              CtcTxnNumberApproved
              PlatformTxnNumberApproved
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          qrewroles {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          objectives {
            items {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          durationInmonths
          qrewLive
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      prefferedprofile {
        items {
          createdAt
          businessProfileId
          businessUserId
          UserId
          candidateprofileId
          acceptanceStatus
          ReasonofRecommendation
          ReasonofShortlist
          Reasonofrejection
          acceptanceStatus_by_CompanyUser_ID
          acceptanceStatus_by_CompanyUser_name
          recommendedbyId
          prferenceType {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          preferencesId
          companyQrewId
          JobApplicationsId
          JobApplication {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          JobPostId
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          businessProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          new
          id
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      preferenceslist {
        items {
          createdAt
          id
          rolename
          businessUserId
          businessProfileId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          recommendedprofiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejectedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobPosts {
            items {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobTraits {
            items {
              id
              TraitName
              createdAt
              updatedAt
              preferencesID
              owner
              __typename
            }
            nextToken
            __typename
          }
          package
          certifications
          noticeperiod
          maxExperienceRequired
          minExperienceRequired
          modeofwork
          JobDomain
          other
          previousrole
          combinedrole
          currencyCountry
          JobType
          Location
          JobPublished
          numberOfCandidateRequired
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      rejected {
        items {
          createdAt
          businessProfileId
          businessUserId
          shortlistedbyId
          shortlistedByUser {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          UserId
          about
          candidateprofileId
          Reasonofrejection
          recommededId
          preferencesId
          prferenceType {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          id
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          businessProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      companyusers {
        items {
          createdAt
          username
          designation
          ProfilePictureKey
          businessProfileId
          id
          cognitoId
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          UserRole
          editObjectiveHistory {
            items {
              id
              createdAt
              updatedAt
              objectiveId
              prevTitle
              editedbyCompanyUserProfileId
              editedbyDeployedQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          email
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      ConvoOnHidi {
        items {
          id
          Question
          Answer
          profileId
          UserId
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          PreferenceId
          companyProfileId
          CompanyProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          companyUserProfileId
          HidiId
          NewHidi {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          companyUser {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateCompanyusers = /* GraphQL */ `
  subscription OnCreateCompanyusers(
    $filter: ModelSubscriptionCompanyusersFilterInput
  ) {
    onCreateCompanyusers(filter: $filter) {
      createdAt
      username
      designation
      ProfilePictureKey
      businessProfileId
      id
      cognitoId
      ConvoOnHidi {
        items {
          id
          Question
          Answer
          profileId
          UserId
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          PreferenceId
          companyProfileId
          CompanyProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          companyUserProfileId
          HidiId
          NewHidi {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          companyUser {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      companyprofile {
        createdAt
        businessUserId
        name
        companyMailId
        id
        preferences
        about
        websiteUrl
        LogoImageKey
        IndustryType
        Shortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CompanyQrew {
          items {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        prefferedprofile {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        preferenceslist {
          items {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        companyusers {
          items {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      Shortlists {
        items {
          createdAt
          businessProfileId
          businessUserId
          UserId
          about
          candidateprofileId
          shortlistedbyId
          JobPostId
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          shortlistedByUser {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          recommededId
          preferencesId
          prferenceType {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          id
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          businessProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      rejected {
        items {
          createdAt
          businessProfileId
          businessUserId
          shortlistedbyId
          shortlistedByUser {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          UserId
          about
          candidateprofileId
          Reasonofrejection
          recommededId
          preferencesId
          prferenceType {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          id
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          businessProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      UserRole
      editObjectiveHistory {
        items {
          id
          createdAt
          updatedAt
          objectiveId
          Objective {
            id
            createdAt
            updatedAt
            title
            creator
            companyProfileId
            companyUserId
            ObjectivekeyResults {
              nextToken
              __typename
            }
            deployedQrewId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            editHistory {
              nextToken
              __typename
            }
            owner
            __typename
          }
          prevTitle
          editedbyCompanyUserProfileId
          editbyCompanyUserProfile {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          editedbyDeployedQrewId
          editedDeployedQrewProfile {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          owner
          __typename
        }
        nextToken
        __typename
      }
      email
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateCompanyusers = /* GraphQL */ `
  subscription OnUpdateCompanyusers(
    $filter: ModelSubscriptionCompanyusersFilterInput
  ) {
    onUpdateCompanyusers(filter: $filter) {
      createdAt
      username
      designation
      ProfilePictureKey
      businessProfileId
      id
      cognitoId
      ConvoOnHidi {
        items {
          id
          Question
          Answer
          profileId
          UserId
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          PreferenceId
          companyProfileId
          CompanyProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          companyUserProfileId
          HidiId
          NewHidi {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          companyUser {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      companyprofile {
        createdAt
        businessUserId
        name
        companyMailId
        id
        preferences
        about
        websiteUrl
        LogoImageKey
        IndustryType
        Shortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CompanyQrew {
          items {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        prefferedprofile {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        preferenceslist {
          items {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        companyusers {
          items {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      Shortlists {
        items {
          createdAt
          businessProfileId
          businessUserId
          UserId
          about
          candidateprofileId
          shortlistedbyId
          JobPostId
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          shortlistedByUser {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          recommededId
          preferencesId
          prferenceType {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          id
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          businessProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      rejected {
        items {
          createdAt
          businessProfileId
          businessUserId
          shortlistedbyId
          shortlistedByUser {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          UserId
          about
          candidateprofileId
          Reasonofrejection
          recommededId
          preferencesId
          prferenceType {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          id
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          businessProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      UserRole
      editObjectiveHistory {
        items {
          id
          createdAt
          updatedAt
          objectiveId
          Objective {
            id
            createdAt
            updatedAt
            title
            creator
            companyProfileId
            companyUserId
            ObjectivekeyResults {
              nextToken
              __typename
            }
            deployedQrewId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            editHistory {
              nextToken
              __typename
            }
            owner
            __typename
          }
          prevTitle
          editedbyCompanyUserProfileId
          editbyCompanyUserProfile {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          editedbyDeployedQrewId
          editedDeployedQrewProfile {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          owner
          __typename
        }
        nextToken
        __typename
      }
      email
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteCompanyusers = /* GraphQL */ `
  subscription OnDeleteCompanyusers(
    $filter: ModelSubscriptionCompanyusersFilterInput
  ) {
    onDeleteCompanyusers(filter: $filter) {
      createdAt
      username
      designation
      ProfilePictureKey
      businessProfileId
      id
      cognitoId
      ConvoOnHidi {
        items {
          id
          Question
          Answer
          profileId
          UserId
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          PreferenceId
          companyProfileId
          CompanyProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          companyUserProfileId
          HidiId
          NewHidi {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          companyUser {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      companyprofile {
        createdAt
        businessUserId
        name
        companyMailId
        id
        preferences
        about
        websiteUrl
        LogoImageKey
        IndustryType
        Shortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CompanyQrew {
          items {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        prefferedprofile {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        preferenceslist {
          items {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        companyusers {
          items {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      Shortlists {
        items {
          createdAt
          businessProfileId
          businessUserId
          UserId
          about
          candidateprofileId
          shortlistedbyId
          JobPostId
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          shortlistedByUser {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          recommededId
          preferencesId
          prferenceType {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          id
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          businessProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      rejected {
        items {
          createdAt
          businessProfileId
          businessUserId
          shortlistedbyId
          shortlistedByUser {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          UserId
          about
          candidateprofileId
          Reasonofrejection
          recommededId
          preferencesId
          prferenceType {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          id
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          businessProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      UserRole
      editObjectiveHistory {
        items {
          id
          createdAt
          updatedAt
          objectiveId
          Objective {
            id
            createdAt
            updatedAt
            title
            creator
            companyProfileId
            companyUserId
            ObjectivekeyResults {
              nextToken
              __typename
            }
            deployedQrewId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            editHistory {
              nextToken
              __typename
            }
            owner
            __typename
          }
          prevTitle
          editedbyCompanyUserProfileId
          editbyCompanyUserProfile {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          editedbyDeployedQrewId
          editedDeployedQrewProfile {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          owner
          __typename
        }
        nextToken
        __typename
      }
      email
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateCompanyQrew = /* GraphQL */ `
  subscription OnCreateCompanyQrew(
    $filter: ModelSubscriptionCompanyQrewFilterInput
  ) {
    onCreateCompanyQrew(filter: $filter) {
      id
      qrewname
      aboutqrew
      businessProfileId
      companyprofile {
        createdAt
        businessUserId
        name
        companyMailId
        id
        preferences
        about
        websiteUrl
        LogoImageKey
        IndustryType
        Shortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CompanyQrew {
          items {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        prefferedprofile {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        preferenceslist {
          items {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        companyusers {
          items {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      Invoices {
        items {
          id
          CtcInvoiceNumber
          PlatformInvoiceNumber
          CompanyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          businessProfileId
          companyName
          CtcInvoiceS3Key
          PlarformInvoiceS3Key
          CtcTxnNumber
          PlatformTxnNumber
          CtcTxnNumberApproved
          PlatformTxnNumberApproved
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      qrewroles {
        items {
          createdAt
          id
          rolename
          businessUserId
          businessProfileId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          recommendedprofiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejectedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobPosts {
            items {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobTraits {
            items {
              id
              TraitName
              createdAt
              updatedAt
              preferencesID
              owner
              __typename
            }
            nextToken
            __typename
          }
          package
          certifications
          noticeperiod
          maxExperienceRequired
          minExperienceRequired
          modeofwork
          JobDomain
          other
          previousrole
          combinedrole
          currencyCountry
          JobType
          Location
          JobPublished
          numberOfCandidateRequired
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      deployedQrew {
        items {
          createdAt
          updatedAt
          companyQrewId
          businessProfileId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          finalCTCQrew
          candidateprofileId
          qrewPosition
          deployedUserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          preferencesId
          JobPostPreferences {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          deployedQrewHistory {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              NewContractCTC
              ContractEndedBy
              ContractPausedBy
              JobApplicationsId
              deployedQrewId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          recommededId
          joiningDate
          endingDate
          NDAkey
          Contractkey
          KYCdocumentkey
          WorkingOnit
          EndContract
          ContractEndingDate
          ExtensionDurationInMonths
          ExtensionDate
          ExtendingContract
          CandidateAcceptanceStatus
          CandidateContractRejectionReason
          CandidateContractNegotiatingReason
          NewContractCTC
          ContractEndedBy
          ContractExtendedBy
          ContractPausedBy
          JobApplicationsId
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          editObjectiveHistory {
            items {
              id
              createdAt
              updatedAt
              objectiveId
              prevTitle
              editedbyCompanyUserProfileId
              editedbyDeployedQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          id
          owner
          __typename
        }
        nextToken
        __typename
      }
      objectives {
        items {
          id
          createdAt
          updatedAt
          title
          creator
          companyProfileId
          companyUserId
          ObjectivekeyResults {
            items {
              id
              createdAt
              updatedAt
              keyResult
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveId
              progressStatus
              progresssPercentage
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrewId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          editHistory {
            items {
              id
              createdAt
              updatedAt
              objectiveId
              prevTitle
              editedbyCompanyUserProfileId
              editedbyDeployedQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          owner
          __typename
        }
        nextToken
        __typename
      }
      initiatives {
        items {
          id
          createdAt
          updatedAt
          initiative
          initiativeDocumentation
          keyResultsAcheived
          deployedQrewId
          companyProfileId
          candidateProfileId
          companyQrewId
          objectiveKeyResultId
          objectiveId
          approvalStatus
          objective {
            id
            createdAt
            updatedAt
            title
            creator
            companyProfileId
            companyUserId
            ObjectivekeyResults {
              nextToken
              __typename
            }
            deployedQrewId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            editHistory {
              nextToken
              __typename
            }
            owner
            __typename
          }
          ObjectiveKeyResult {
            id
            createdAt
            updatedAt
            keyResult
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveId
            initiatives {
              nextToken
              __typename
            }
            progressStatus
            progresssPercentage
            owner
            __typename
          }
          InitiativeCreatedBydeployedQrew {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          PercentageContributionOnkeyResult
          TimeRequiredToComplete
          owner
          __typename
        }
        nextToken
        __typename
      }
      durationInmonths
      qrewLive
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateCompanyQrew = /* GraphQL */ `
  subscription OnUpdateCompanyQrew(
    $filter: ModelSubscriptionCompanyQrewFilterInput
  ) {
    onUpdateCompanyQrew(filter: $filter) {
      id
      qrewname
      aboutqrew
      businessProfileId
      companyprofile {
        createdAt
        businessUserId
        name
        companyMailId
        id
        preferences
        about
        websiteUrl
        LogoImageKey
        IndustryType
        Shortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CompanyQrew {
          items {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        prefferedprofile {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        preferenceslist {
          items {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        companyusers {
          items {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      Invoices {
        items {
          id
          CtcInvoiceNumber
          PlatformInvoiceNumber
          CompanyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          businessProfileId
          companyName
          CtcInvoiceS3Key
          PlarformInvoiceS3Key
          CtcTxnNumber
          PlatformTxnNumber
          CtcTxnNumberApproved
          PlatformTxnNumberApproved
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      qrewroles {
        items {
          createdAt
          id
          rolename
          businessUserId
          businessProfileId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          recommendedprofiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejectedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobPosts {
            items {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobTraits {
            items {
              id
              TraitName
              createdAt
              updatedAt
              preferencesID
              owner
              __typename
            }
            nextToken
            __typename
          }
          package
          certifications
          noticeperiod
          maxExperienceRequired
          minExperienceRequired
          modeofwork
          JobDomain
          other
          previousrole
          combinedrole
          currencyCountry
          JobType
          Location
          JobPublished
          numberOfCandidateRequired
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      deployedQrew {
        items {
          createdAt
          updatedAt
          companyQrewId
          businessProfileId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          finalCTCQrew
          candidateprofileId
          qrewPosition
          deployedUserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          preferencesId
          JobPostPreferences {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          deployedQrewHistory {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              NewContractCTC
              ContractEndedBy
              ContractPausedBy
              JobApplicationsId
              deployedQrewId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          recommededId
          joiningDate
          endingDate
          NDAkey
          Contractkey
          KYCdocumentkey
          WorkingOnit
          EndContract
          ContractEndingDate
          ExtensionDurationInMonths
          ExtensionDate
          ExtendingContract
          CandidateAcceptanceStatus
          CandidateContractRejectionReason
          CandidateContractNegotiatingReason
          NewContractCTC
          ContractEndedBy
          ContractExtendedBy
          ContractPausedBy
          JobApplicationsId
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          editObjectiveHistory {
            items {
              id
              createdAt
              updatedAt
              objectiveId
              prevTitle
              editedbyCompanyUserProfileId
              editedbyDeployedQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          id
          owner
          __typename
        }
        nextToken
        __typename
      }
      objectives {
        items {
          id
          createdAt
          updatedAt
          title
          creator
          companyProfileId
          companyUserId
          ObjectivekeyResults {
            items {
              id
              createdAt
              updatedAt
              keyResult
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveId
              progressStatus
              progresssPercentage
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrewId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          editHistory {
            items {
              id
              createdAt
              updatedAt
              objectiveId
              prevTitle
              editedbyCompanyUserProfileId
              editedbyDeployedQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          owner
          __typename
        }
        nextToken
        __typename
      }
      initiatives {
        items {
          id
          createdAt
          updatedAt
          initiative
          initiativeDocumentation
          keyResultsAcheived
          deployedQrewId
          companyProfileId
          candidateProfileId
          companyQrewId
          objectiveKeyResultId
          objectiveId
          approvalStatus
          objective {
            id
            createdAt
            updatedAt
            title
            creator
            companyProfileId
            companyUserId
            ObjectivekeyResults {
              nextToken
              __typename
            }
            deployedQrewId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            editHistory {
              nextToken
              __typename
            }
            owner
            __typename
          }
          ObjectiveKeyResult {
            id
            createdAt
            updatedAt
            keyResult
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveId
            initiatives {
              nextToken
              __typename
            }
            progressStatus
            progresssPercentage
            owner
            __typename
          }
          InitiativeCreatedBydeployedQrew {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          PercentageContributionOnkeyResult
          TimeRequiredToComplete
          owner
          __typename
        }
        nextToken
        __typename
      }
      durationInmonths
      qrewLive
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteCompanyQrew = /* GraphQL */ `
  subscription OnDeleteCompanyQrew(
    $filter: ModelSubscriptionCompanyQrewFilterInput
  ) {
    onDeleteCompanyQrew(filter: $filter) {
      id
      qrewname
      aboutqrew
      businessProfileId
      companyprofile {
        createdAt
        businessUserId
        name
        companyMailId
        id
        preferences
        about
        websiteUrl
        LogoImageKey
        IndustryType
        Shortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CompanyQrew {
          items {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        prefferedprofile {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        preferenceslist {
          items {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        companyusers {
          items {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      Invoices {
        items {
          id
          CtcInvoiceNumber
          PlatformInvoiceNumber
          CompanyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          businessProfileId
          companyName
          CtcInvoiceS3Key
          PlarformInvoiceS3Key
          CtcTxnNumber
          PlatformTxnNumber
          CtcTxnNumberApproved
          PlatformTxnNumberApproved
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      qrewroles {
        items {
          createdAt
          id
          rolename
          businessUserId
          businessProfileId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          recommendedprofiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejectedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobPosts {
            items {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobTraits {
            items {
              id
              TraitName
              createdAt
              updatedAt
              preferencesID
              owner
              __typename
            }
            nextToken
            __typename
          }
          package
          certifications
          noticeperiod
          maxExperienceRequired
          minExperienceRequired
          modeofwork
          JobDomain
          other
          previousrole
          combinedrole
          currencyCountry
          JobType
          Location
          JobPublished
          numberOfCandidateRequired
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      deployedQrew {
        items {
          createdAt
          updatedAt
          companyQrewId
          businessProfileId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          finalCTCQrew
          candidateprofileId
          qrewPosition
          deployedUserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          preferencesId
          JobPostPreferences {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          deployedQrewHistory {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              NewContractCTC
              ContractEndedBy
              ContractPausedBy
              JobApplicationsId
              deployedQrewId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          recommededId
          joiningDate
          endingDate
          NDAkey
          Contractkey
          KYCdocumentkey
          WorkingOnit
          EndContract
          ContractEndingDate
          ExtensionDurationInMonths
          ExtensionDate
          ExtendingContract
          CandidateAcceptanceStatus
          CandidateContractRejectionReason
          CandidateContractNegotiatingReason
          NewContractCTC
          ContractEndedBy
          ContractExtendedBy
          ContractPausedBy
          JobApplicationsId
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          editObjectiveHistory {
            items {
              id
              createdAt
              updatedAt
              objectiveId
              prevTitle
              editedbyCompanyUserProfileId
              editedbyDeployedQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          id
          owner
          __typename
        }
        nextToken
        __typename
      }
      objectives {
        items {
          id
          createdAt
          updatedAt
          title
          creator
          companyProfileId
          companyUserId
          ObjectivekeyResults {
            items {
              id
              createdAt
              updatedAt
              keyResult
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveId
              progressStatus
              progresssPercentage
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrewId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          editHistory {
            items {
              id
              createdAt
              updatedAt
              objectiveId
              prevTitle
              editedbyCompanyUserProfileId
              editedbyDeployedQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          owner
          __typename
        }
        nextToken
        __typename
      }
      initiatives {
        items {
          id
          createdAt
          updatedAt
          initiative
          initiativeDocumentation
          keyResultsAcheived
          deployedQrewId
          companyProfileId
          candidateProfileId
          companyQrewId
          objectiveKeyResultId
          objectiveId
          approvalStatus
          objective {
            id
            createdAt
            updatedAt
            title
            creator
            companyProfileId
            companyUserId
            ObjectivekeyResults {
              nextToken
              __typename
            }
            deployedQrewId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            editHistory {
              nextToken
              __typename
            }
            owner
            __typename
          }
          ObjectiveKeyResult {
            id
            createdAt
            updatedAt
            keyResult
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveId
            initiatives {
              nextToken
              __typename
            }
            progressStatus
            progresssPercentage
            owner
            __typename
          }
          InitiativeCreatedBydeployedQrew {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          PercentageContributionOnkeyResult
          TimeRequiredToComplete
          owner
          __typename
        }
        nextToken
        __typename
      }
      durationInmonths
      qrewLive
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateCompanyQrewInvoices = /* GraphQL */ `
  subscription OnCreateCompanyQrewInvoices(
    $filter: ModelSubscriptionCompanyQrewInvoicesFilterInput
  ) {
    onCreateCompanyQrewInvoices(filter: $filter) {
      id
      CtcInvoiceNumber
      PlatformInvoiceNumber
      CompanyQrewId
      companyQrew {
        id
        qrewname
        aboutqrew
        businessProfileId
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        Invoices {
          items {
            id
            CtcInvoiceNumber
            PlatformInvoiceNumber
            CompanyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            businessProfileId
            companyName
            CtcInvoiceS3Key
            PlarformInvoiceS3Key
            CtcTxnNumber
            PlatformTxnNumber
            CtcTxnNumberApproved
            PlatformTxnNumberApproved
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        qrewroles {
          items {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedQrew {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        objectives {
          items {
            id
            createdAt
            updatedAt
            title
            creator
            companyProfileId
            companyUserId
            ObjectivekeyResults {
              nextToken
              __typename
            }
            deployedQrewId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            editHistory {
              nextToken
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        initiatives {
          items {
            id
            createdAt
            updatedAt
            initiative
            initiativeDocumentation
            keyResultsAcheived
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveKeyResultId
            objectiveId
            approvalStatus
            objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            ObjectiveKeyResult {
              id
              createdAt
              updatedAt
              keyResult
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveId
              progressStatus
              progresssPercentage
              owner
              __typename
            }
            InitiativeCreatedBydeployedQrew {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            PercentageContributionOnkeyResult
            TimeRequiredToComplete
            owner
            __typename
          }
          nextToken
          __typename
        }
        durationInmonths
        qrewLive
        createdAt
        updatedAt
        owner
        __typename
      }
      businessProfileId
      companyName
      CtcInvoiceS3Key
      PlarformInvoiceS3Key
      CtcTxnNumber
      PlatformTxnNumber
      CtcTxnNumberApproved
      PlatformTxnNumberApproved
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateCompanyQrewInvoices = /* GraphQL */ `
  subscription OnUpdateCompanyQrewInvoices(
    $filter: ModelSubscriptionCompanyQrewInvoicesFilterInput
  ) {
    onUpdateCompanyQrewInvoices(filter: $filter) {
      id
      CtcInvoiceNumber
      PlatformInvoiceNumber
      CompanyQrewId
      companyQrew {
        id
        qrewname
        aboutqrew
        businessProfileId
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        Invoices {
          items {
            id
            CtcInvoiceNumber
            PlatformInvoiceNumber
            CompanyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            businessProfileId
            companyName
            CtcInvoiceS3Key
            PlarformInvoiceS3Key
            CtcTxnNumber
            PlatformTxnNumber
            CtcTxnNumberApproved
            PlatformTxnNumberApproved
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        qrewroles {
          items {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedQrew {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        objectives {
          items {
            id
            createdAt
            updatedAt
            title
            creator
            companyProfileId
            companyUserId
            ObjectivekeyResults {
              nextToken
              __typename
            }
            deployedQrewId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            editHistory {
              nextToken
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        initiatives {
          items {
            id
            createdAt
            updatedAt
            initiative
            initiativeDocumentation
            keyResultsAcheived
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveKeyResultId
            objectiveId
            approvalStatus
            objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            ObjectiveKeyResult {
              id
              createdAt
              updatedAt
              keyResult
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveId
              progressStatus
              progresssPercentage
              owner
              __typename
            }
            InitiativeCreatedBydeployedQrew {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            PercentageContributionOnkeyResult
            TimeRequiredToComplete
            owner
            __typename
          }
          nextToken
          __typename
        }
        durationInmonths
        qrewLive
        createdAt
        updatedAt
        owner
        __typename
      }
      businessProfileId
      companyName
      CtcInvoiceS3Key
      PlarformInvoiceS3Key
      CtcTxnNumber
      PlatformTxnNumber
      CtcTxnNumberApproved
      PlatformTxnNumberApproved
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteCompanyQrewInvoices = /* GraphQL */ `
  subscription OnDeleteCompanyQrewInvoices(
    $filter: ModelSubscriptionCompanyQrewInvoicesFilterInput
  ) {
    onDeleteCompanyQrewInvoices(filter: $filter) {
      id
      CtcInvoiceNumber
      PlatformInvoiceNumber
      CompanyQrewId
      companyQrew {
        id
        qrewname
        aboutqrew
        businessProfileId
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        Invoices {
          items {
            id
            CtcInvoiceNumber
            PlatformInvoiceNumber
            CompanyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            businessProfileId
            companyName
            CtcInvoiceS3Key
            PlarformInvoiceS3Key
            CtcTxnNumber
            PlatformTxnNumber
            CtcTxnNumberApproved
            PlatformTxnNumberApproved
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        qrewroles {
          items {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedQrew {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        objectives {
          items {
            id
            createdAt
            updatedAt
            title
            creator
            companyProfileId
            companyUserId
            ObjectivekeyResults {
              nextToken
              __typename
            }
            deployedQrewId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            editHistory {
              nextToken
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        initiatives {
          items {
            id
            createdAt
            updatedAt
            initiative
            initiativeDocumentation
            keyResultsAcheived
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveKeyResultId
            objectiveId
            approvalStatus
            objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            ObjectiveKeyResult {
              id
              createdAt
              updatedAt
              keyResult
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveId
              progressStatus
              progresssPercentage
              owner
              __typename
            }
            InitiativeCreatedBydeployedQrew {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            PercentageContributionOnkeyResult
            TimeRequiredToComplete
            owner
            __typename
          }
          nextToken
          __typename
        }
        durationInmonths
        qrewLive
        createdAt
        updatedAt
        owner
        __typename
      }
      businessProfileId
      companyName
      CtcInvoiceS3Key
      PlarformInvoiceS3Key
      CtcTxnNumber
      PlatformTxnNumber
      CtcTxnNumberApproved
      PlatformTxnNumberApproved
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreatePreferences = /* GraphQL */ `
  subscription OnCreatePreferences(
    $filter: ModelSubscriptionPreferencesFilterInput
  ) {
    onCreatePreferences(filter: $filter) {
      createdAt
      id
      rolename
      businessUserId
      businessProfileId
      companyQrewId
      companyQrew {
        id
        qrewname
        aboutqrew
        businessProfileId
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        Invoices {
          items {
            id
            CtcInvoiceNumber
            PlatformInvoiceNumber
            CompanyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            businessProfileId
            companyName
            CtcInvoiceS3Key
            PlarformInvoiceS3Key
            CtcTxnNumber
            PlatformTxnNumber
            CtcTxnNumberApproved
            PlatformTxnNumberApproved
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        qrewroles {
          items {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedQrew {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        objectives {
          items {
            id
            createdAt
            updatedAt
            title
            creator
            companyProfileId
            companyUserId
            ObjectivekeyResults {
              nextToken
              __typename
            }
            deployedQrewId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            editHistory {
              nextToken
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        initiatives {
          items {
            id
            createdAt
            updatedAt
            initiative
            initiativeDocumentation
            keyResultsAcheived
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveKeyResultId
            objectiveId
            approvalStatus
            objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            ObjectiveKeyResult {
              id
              createdAt
              updatedAt
              keyResult
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveId
              progressStatus
              progresssPercentage
              owner
              __typename
            }
            InitiativeCreatedBydeployedQrew {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            PercentageContributionOnkeyResult
            TimeRequiredToComplete
            owner
            __typename
          }
          nextToken
          __typename
        }
        durationInmonths
        qrewLive
        createdAt
        updatedAt
        owner
        __typename
      }
      companyprofile {
        createdAt
        businessUserId
        name
        companyMailId
        id
        preferences
        about
        websiteUrl
        LogoImageKey
        IndustryType
        Shortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CompanyQrew {
          items {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        prefferedprofile {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        preferenceslist {
          items {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        companyusers {
          items {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      recommendedprofiles {
        items {
          createdAt
          businessProfileId
          businessUserId
          UserId
          candidateprofileId
          acceptanceStatus
          ReasonofRecommendation
          ReasonofShortlist
          Reasonofrejection
          acceptanceStatus_by_CompanyUser_ID
          acceptanceStatus_by_CompanyUser_name
          recommendedbyId
          prferenceType {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          preferencesId
          companyQrewId
          JobApplicationsId
          JobApplication {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          JobPostId
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          businessProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          new
          id
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      shortlistedProfiles {
        items {
          createdAt
          businessProfileId
          businessUserId
          UserId
          about
          candidateprofileId
          shortlistedbyId
          JobPostId
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          shortlistedByUser {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          recommededId
          preferencesId
          prferenceType {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          id
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          businessProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      rejectedProfiles {
        items {
          createdAt
          businessProfileId
          businessUserId
          shortlistedbyId
          shortlistedByUser {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          UserId
          about
          candidateprofileId
          Reasonofrejection
          recommededId
          preferencesId
          prferenceType {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          id
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          businessProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      JobApplications {
        items {
          id
          JobPostId
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          CompanyProfileId
          ProfileId
          CompanyPreferenceId
          companyQrewId
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ApplyingStatus
          Level
          CandidateProfileAnalysis
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      JobPosts {
        items {
          id
          CompanyPreferenceId
          companyQrewId
          CompanyPreference {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          companyName
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedCandidates {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          jobPostQuestions {
            items {
              id
              JobPostId
              preferencesID
              Question
              TypeOfQuesiton
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          jobPostLink
          logoUrl
          htmldescription
          CompanyDescription
          AvailablityStatus
          experienceRequired
          jobType
          Location
          skills
          domain
          description
          ShortDesc
          RolesandResponsibility
          title
          CapabilitiesRequired {
            items {
              id
              jobPostID
              GlobalCapabilityConfigID
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          FeedsConnected {
            items {
              id
              UserSegmentId
              HidiId
              CapabilityConfigId
              jobPostId
              tags
              domain
              Description
              Payload
              FeedType
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CapabilitiesRequiredJSON
          createdAt
          updatedAt
          SavedJobPosts {
            items {
              id
              JobPostId
              ProfileId
              UserId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          owner
          __typename
        }
        nextToken
        __typename
      }
      deployedQrew {
        items {
          createdAt
          updatedAt
          companyQrewId
          businessProfileId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          finalCTCQrew
          candidateprofileId
          qrewPosition
          deployedUserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          preferencesId
          JobPostPreferences {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          deployedQrewHistory {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              NewContractCTC
              ContractEndedBy
              ContractPausedBy
              JobApplicationsId
              deployedQrewId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          recommededId
          joiningDate
          endingDate
          NDAkey
          Contractkey
          KYCdocumentkey
          WorkingOnit
          EndContract
          ContractEndingDate
          ExtensionDurationInMonths
          ExtensionDate
          ExtendingContract
          CandidateAcceptanceStatus
          CandidateContractRejectionReason
          CandidateContractNegotiatingReason
          NewContractCTC
          ContractEndedBy
          ContractExtendedBy
          ContractPausedBy
          JobApplicationsId
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          editObjectiveHistory {
            items {
              id
              createdAt
              updatedAt
              objectiveId
              prevTitle
              editedbyCompanyUserProfileId
              editedbyDeployedQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          id
          owner
          __typename
        }
        nextToken
        __typename
      }
      JobTraits {
        items {
          id
          TraitName
          createdAt
          updatedAt
          preferencesID
          owner
          __typename
        }
        nextToken
        __typename
      }
      package
      certifications
      noticeperiod
      maxExperienceRequired
      minExperienceRequired
      modeofwork
      JobDomain
      other
      previousrole
      combinedrole
      currencyCountry
      JobType
      Location
      JobPublished
      numberOfCandidateRequired
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdatePreferences = /* GraphQL */ `
  subscription OnUpdatePreferences(
    $filter: ModelSubscriptionPreferencesFilterInput
  ) {
    onUpdatePreferences(filter: $filter) {
      createdAt
      id
      rolename
      businessUserId
      businessProfileId
      companyQrewId
      companyQrew {
        id
        qrewname
        aboutqrew
        businessProfileId
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        Invoices {
          items {
            id
            CtcInvoiceNumber
            PlatformInvoiceNumber
            CompanyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            businessProfileId
            companyName
            CtcInvoiceS3Key
            PlarformInvoiceS3Key
            CtcTxnNumber
            PlatformTxnNumber
            CtcTxnNumberApproved
            PlatformTxnNumberApproved
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        qrewroles {
          items {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedQrew {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        objectives {
          items {
            id
            createdAt
            updatedAt
            title
            creator
            companyProfileId
            companyUserId
            ObjectivekeyResults {
              nextToken
              __typename
            }
            deployedQrewId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            editHistory {
              nextToken
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        initiatives {
          items {
            id
            createdAt
            updatedAt
            initiative
            initiativeDocumentation
            keyResultsAcheived
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveKeyResultId
            objectiveId
            approvalStatus
            objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            ObjectiveKeyResult {
              id
              createdAt
              updatedAt
              keyResult
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveId
              progressStatus
              progresssPercentage
              owner
              __typename
            }
            InitiativeCreatedBydeployedQrew {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            PercentageContributionOnkeyResult
            TimeRequiredToComplete
            owner
            __typename
          }
          nextToken
          __typename
        }
        durationInmonths
        qrewLive
        createdAt
        updatedAt
        owner
        __typename
      }
      companyprofile {
        createdAt
        businessUserId
        name
        companyMailId
        id
        preferences
        about
        websiteUrl
        LogoImageKey
        IndustryType
        Shortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CompanyQrew {
          items {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        prefferedprofile {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        preferenceslist {
          items {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        companyusers {
          items {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      recommendedprofiles {
        items {
          createdAt
          businessProfileId
          businessUserId
          UserId
          candidateprofileId
          acceptanceStatus
          ReasonofRecommendation
          ReasonofShortlist
          Reasonofrejection
          acceptanceStatus_by_CompanyUser_ID
          acceptanceStatus_by_CompanyUser_name
          recommendedbyId
          prferenceType {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          preferencesId
          companyQrewId
          JobApplicationsId
          JobApplication {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          JobPostId
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          businessProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          new
          id
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      shortlistedProfiles {
        items {
          createdAt
          businessProfileId
          businessUserId
          UserId
          about
          candidateprofileId
          shortlistedbyId
          JobPostId
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          shortlistedByUser {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          recommededId
          preferencesId
          prferenceType {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          id
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          businessProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      rejectedProfiles {
        items {
          createdAt
          businessProfileId
          businessUserId
          shortlistedbyId
          shortlistedByUser {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          UserId
          about
          candidateprofileId
          Reasonofrejection
          recommededId
          preferencesId
          prferenceType {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          id
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          businessProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      JobApplications {
        items {
          id
          JobPostId
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          CompanyProfileId
          ProfileId
          CompanyPreferenceId
          companyQrewId
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ApplyingStatus
          Level
          CandidateProfileAnalysis
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      JobPosts {
        items {
          id
          CompanyPreferenceId
          companyQrewId
          CompanyPreference {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          companyName
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedCandidates {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          jobPostQuestions {
            items {
              id
              JobPostId
              preferencesID
              Question
              TypeOfQuesiton
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          jobPostLink
          logoUrl
          htmldescription
          CompanyDescription
          AvailablityStatus
          experienceRequired
          jobType
          Location
          skills
          domain
          description
          ShortDesc
          RolesandResponsibility
          title
          CapabilitiesRequired {
            items {
              id
              jobPostID
              GlobalCapabilityConfigID
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          FeedsConnected {
            items {
              id
              UserSegmentId
              HidiId
              CapabilityConfigId
              jobPostId
              tags
              domain
              Description
              Payload
              FeedType
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CapabilitiesRequiredJSON
          createdAt
          updatedAt
          SavedJobPosts {
            items {
              id
              JobPostId
              ProfileId
              UserId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          owner
          __typename
        }
        nextToken
        __typename
      }
      deployedQrew {
        items {
          createdAt
          updatedAt
          companyQrewId
          businessProfileId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          finalCTCQrew
          candidateprofileId
          qrewPosition
          deployedUserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          preferencesId
          JobPostPreferences {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          deployedQrewHistory {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              NewContractCTC
              ContractEndedBy
              ContractPausedBy
              JobApplicationsId
              deployedQrewId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          recommededId
          joiningDate
          endingDate
          NDAkey
          Contractkey
          KYCdocumentkey
          WorkingOnit
          EndContract
          ContractEndingDate
          ExtensionDurationInMonths
          ExtensionDate
          ExtendingContract
          CandidateAcceptanceStatus
          CandidateContractRejectionReason
          CandidateContractNegotiatingReason
          NewContractCTC
          ContractEndedBy
          ContractExtendedBy
          ContractPausedBy
          JobApplicationsId
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          editObjectiveHistory {
            items {
              id
              createdAt
              updatedAt
              objectiveId
              prevTitle
              editedbyCompanyUserProfileId
              editedbyDeployedQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          id
          owner
          __typename
        }
        nextToken
        __typename
      }
      JobTraits {
        items {
          id
          TraitName
          createdAt
          updatedAt
          preferencesID
          owner
          __typename
        }
        nextToken
        __typename
      }
      package
      certifications
      noticeperiod
      maxExperienceRequired
      minExperienceRequired
      modeofwork
      JobDomain
      other
      previousrole
      combinedrole
      currencyCountry
      JobType
      Location
      JobPublished
      numberOfCandidateRequired
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeletePreferences = /* GraphQL */ `
  subscription OnDeletePreferences(
    $filter: ModelSubscriptionPreferencesFilterInput
  ) {
    onDeletePreferences(filter: $filter) {
      createdAt
      id
      rolename
      businessUserId
      businessProfileId
      companyQrewId
      companyQrew {
        id
        qrewname
        aboutqrew
        businessProfileId
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        Invoices {
          items {
            id
            CtcInvoiceNumber
            PlatformInvoiceNumber
            CompanyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            businessProfileId
            companyName
            CtcInvoiceS3Key
            PlarformInvoiceS3Key
            CtcTxnNumber
            PlatformTxnNumber
            CtcTxnNumberApproved
            PlatformTxnNumberApproved
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        qrewroles {
          items {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedQrew {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        objectives {
          items {
            id
            createdAt
            updatedAt
            title
            creator
            companyProfileId
            companyUserId
            ObjectivekeyResults {
              nextToken
              __typename
            }
            deployedQrewId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            editHistory {
              nextToken
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        initiatives {
          items {
            id
            createdAt
            updatedAt
            initiative
            initiativeDocumentation
            keyResultsAcheived
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveKeyResultId
            objectiveId
            approvalStatus
            objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            ObjectiveKeyResult {
              id
              createdAt
              updatedAt
              keyResult
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveId
              progressStatus
              progresssPercentage
              owner
              __typename
            }
            InitiativeCreatedBydeployedQrew {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            PercentageContributionOnkeyResult
            TimeRequiredToComplete
            owner
            __typename
          }
          nextToken
          __typename
        }
        durationInmonths
        qrewLive
        createdAt
        updatedAt
        owner
        __typename
      }
      companyprofile {
        createdAt
        businessUserId
        name
        companyMailId
        id
        preferences
        about
        websiteUrl
        LogoImageKey
        IndustryType
        Shortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CompanyQrew {
          items {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        prefferedprofile {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        preferenceslist {
          items {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        companyusers {
          items {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      recommendedprofiles {
        items {
          createdAt
          businessProfileId
          businessUserId
          UserId
          candidateprofileId
          acceptanceStatus
          ReasonofRecommendation
          ReasonofShortlist
          Reasonofrejection
          acceptanceStatus_by_CompanyUser_ID
          acceptanceStatus_by_CompanyUser_name
          recommendedbyId
          prferenceType {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          preferencesId
          companyQrewId
          JobApplicationsId
          JobApplication {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          JobPostId
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          businessProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          new
          id
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      shortlistedProfiles {
        items {
          createdAt
          businessProfileId
          businessUserId
          UserId
          about
          candidateprofileId
          shortlistedbyId
          JobPostId
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          shortlistedByUser {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          recommededId
          preferencesId
          prferenceType {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          id
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          businessProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      rejectedProfiles {
        items {
          createdAt
          businessProfileId
          businessUserId
          shortlistedbyId
          shortlistedByUser {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          UserId
          about
          candidateprofileId
          Reasonofrejection
          recommededId
          preferencesId
          prferenceType {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          id
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          businessProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      JobApplications {
        items {
          id
          JobPostId
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          CompanyProfileId
          ProfileId
          CompanyPreferenceId
          companyQrewId
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ApplyingStatus
          Level
          CandidateProfileAnalysis
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      JobPosts {
        items {
          id
          CompanyPreferenceId
          companyQrewId
          CompanyPreference {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          companyName
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedCandidates {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          jobPostQuestions {
            items {
              id
              JobPostId
              preferencesID
              Question
              TypeOfQuesiton
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          jobPostLink
          logoUrl
          htmldescription
          CompanyDescription
          AvailablityStatus
          experienceRequired
          jobType
          Location
          skills
          domain
          description
          ShortDesc
          RolesandResponsibility
          title
          CapabilitiesRequired {
            items {
              id
              jobPostID
              GlobalCapabilityConfigID
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          FeedsConnected {
            items {
              id
              UserSegmentId
              HidiId
              CapabilityConfigId
              jobPostId
              tags
              domain
              Description
              Payload
              FeedType
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CapabilitiesRequiredJSON
          createdAt
          updatedAt
          SavedJobPosts {
            items {
              id
              JobPostId
              ProfileId
              UserId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          owner
          __typename
        }
        nextToken
        __typename
      }
      deployedQrew {
        items {
          createdAt
          updatedAt
          companyQrewId
          businessProfileId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          finalCTCQrew
          candidateprofileId
          qrewPosition
          deployedUserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          preferencesId
          JobPostPreferences {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          deployedQrewHistory {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              NewContractCTC
              ContractEndedBy
              ContractPausedBy
              JobApplicationsId
              deployedQrewId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          recommededId
          joiningDate
          endingDate
          NDAkey
          Contractkey
          KYCdocumentkey
          WorkingOnit
          EndContract
          ContractEndingDate
          ExtensionDurationInMonths
          ExtensionDate
          ExtendingContract
          CandidateAcceptanceStatus
          CandidateContractRejectionReason
          CandidateContractNegotiatingReason
          NewContractCTC
          ContractEndedBy
          ContractExtendedBy
          ContractPausedBy
          JobApplicationsId
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          editObjectiveHistory {
            items {
              id
              createdAt
              updatedAt
              objectiveId
              prevTitle
              editedbyCompanyUserProfileId
              editedbyDeployedQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          id
          owner
          __typename
        }
        nextToken
        __typename
      }
      JobTraits {
        items {
          id
          TraitName
          createdAt
          updatedAt
          preferencesID
          owner
          __typename
        }
        nextToken
        __typename
      }
      package
      certifications
      noticeperiod
      maxExperienceRequired
      minExperienceRequired
      modeofwork
      JobDomain
      other
      previousrole
      combinedrole
      currencyCountry
      JobType
      Location
      JobPublished
      numberOfCandidateRequired
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateJobPostQuestions = /* GraphQL */ `
  subscription OnCreateJobPostQuestions(
    $filter: ModelSubscriptionJobPostQuestionsFilterInput
  ) {
    onCreateJobPostQuestions(filter: $filter) {
      id
      JobPostId
      preferencesID
      Question
      TypeOfQuesiton
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateJobPostQuestions = /* GraphQL */ `
  subscription OnUpdateJobPostQuestions(
    $filter: ModelSubscriptionJobPostQuestionsFilterInput
  ) {
    onUpdateJobPostQuestions(filter: $filter) {
      id
      JobPostId
      preferencesID
      Question
      TypeOfQuesiton
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteJobPostQuestions = /* GraphQL */ `
  subscription OnDeleteJobPostQuestions(
    $filter: ModelSubscriptionJobPostQuestionsFilterInput
  ) {
    onDeleteJobPostQuestions(filter: $filter) {
      id
      JobPostId
      preferencesID
      Question
      TypeOfQuesiton
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateJobPostQuestionsAnswered = /* GraphQL */ `
  subscription OnCreateJobPostQuestionsAnswered(
    $filter: ModelSubscriptionJobPostQuestionsAnsweredFilterInput
  ) {
    onCreateJobPostQuestionsAnswered(filter: $filter) {
      id
      JobPostQuestionsId
      JobPostId
      preferencesID
      answeredbyUserId
      Question
      Answer
      TypeOfQuesiton
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateJobPostQuestionsAnswered = /* GraphQL */ `
  subscription OnUpdateJobPostQuestionsAnswered(
    $filter: ModelSubscriptionJobPostQuestionsAnsweredFilterInput
  ) {
    onUpdateJobPostQuestionsAnswered(filter: $filter) {
      id
      JobPostQuestionsId
      JobPostId
      preferencesID
      answeredbyUserId
      Question
      Answer
      TypeOfQuesiton
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteJobPostQuestionsAnswered = /* GraphQL */ `
  subscription OnDeleteJobPostQuestionsAnswered(
    $filter: ModelSubscriptionJobPostQuestionsAnsweredFilterInput
  ) {
    onDeleteJobPostQuestionsAnswered(filter: $filter) {
      id
      JobPostQuestionsId
      JobPostId
      preferencesID
      answeredbyUserId
      Question
      Answer
      TypeOfQuesiton
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateJobTraits = /* GraphQL */ `
  subscription OnCreateJobTraits(
    $filter: ModelSubscriptionJobTraitsFilterInput
  ) {
    onCreateJobTraits(filter: $filter) {
      id
      TraitName
      createdAt
      updatedAt
      preferencesID
      owner
      __typename
    }
  }
`;
export const onUpdateJobTraits = /* GraphQL */ `
  subscription OnUpdateJobTraits(
    $filter: ModelSubscriptionJobTraitsFilterInput
  ) {
    onUpdateJobTraits(filter: $filter) {
      id
      TraitName
      createdAt
      updatedAt
      preferencesID
      owner
      __typename
    }
  }
`;
export const onDeleteJobTraits = /* GraphQL */ `
  subscription OnDeleteJobTraits(
    $filter: ModelSubscriptionJobTraitsFilterInput
  ) {
    onDeleteJobTraits(filter: $filter) {
      id
      TraitName
      createdAt
      updatedAt
      preferencesID
      owner
      __typename
    }
  }
`;
export const onCreateShortlist = /* GraphQL */ `
  subscription OnCreateShortlist(
    $filter: ModelSubscriptionShortlistFilterInput
  ) {
    onCreateShortlist(filter: $filter) {
      createdAt
      businessProfileId
      businessUserId
      UserId
      about
      candidateprofileId
      shortlistedbyId
      JobPostId
      jobPost {
        id
        CompanyPreferenceId
        companyQrewId
        CompanyPreference {
          createdAt
          id
          rolename
          businessUserId
          businessProfileId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          recommendedprofiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejectedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobPosts {
            items {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobTraits {
            items {
              id
              TraitName
              createdAt
              updatedAt
              preferencesID
              owner
              __typename
            }
            nextToken
            __typename
          }
          package
          certifications
          noticeperiod
          maxExperienceRequired
          minExperienceRequired
          modeofwork
          JobDomain
          other
          previousrole
          combinedrole
          currencyCountry
          JobType
          Location
          JobPublished
          numberOfCandidateRequired
          updatedAt
          owner
          __typename
        }
        companyName
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        shortlistedCandidates {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        jobPostQuestions {
          items {
            id
            JobPostId
            preferencesID
            Question
            TypeOfQuesiton
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        jobPostLink
        logoUrl
        htmldescription
        CompanyDescription
        AvailablityStatus
        experienceRequired
        jobType
        Location
        skills
        domain
        description
        ShortDesc
        RolesandResponsibility
        title
        CapabilitiesRequired {
          items {
            id
            jobPostID
            GlobalCapabilityConfigID
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        FeedsConnected {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CapabilitiesRequiredJSON
        createdAt
        updatedAt
        SavedJobPosts {
          items {
            id
            JobPostId
            ProfileId
            UserId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        owner
        __typename
      }
      shortlistedByUser {
        createdAt
        username
        designation
        ProfilePictureKey
        businessProfileId
        id
        cognitoId
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        Shortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        UserRole
        editObjectiveHistory {
          items {
            id
            createdAt
            updatedAt
            objectiveId
            Objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            prevTitle
            editedbyCompanyUserProfileId
            editbyCompanyUserProfile {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            editedbyDeployedQrewId
            editedDeployedQrewProfile {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        email
        updatedAt
        owner
        __typename
      }
      recommededId
      preferencesId
      prferenceType {
        createdAt
        id
        rolename
        businessUserId
        businessProfileId
        companyQrewId
        companyQrew {
          id
          qrewname
          aboutqrew
          businessProfileId
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Invoices {
            items {
              id
              CtcInvoiceNumber
              PlatformInvoiceNumber
              CompanyQrewId
              businessProfileId
              companyName
              CtcInvoiceS3Key
              PlarformInvoiceS3Key
              CtcTxnNumber
              PlatformTxnNumber
              CtcTxnNumberApproved
              PlatformTxnNumberApproved
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          qrewroles {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          objectives {
            items {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          durationInmonths
          qrewLive
          createdAt
          updatedAt
          owner
          __typename
        }
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        recommendedprofiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        shortlistedProfiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejectedProfiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobPosts {
          items {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedQrew {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobTraits {
          items {
            id
            TraitName
            createdAt
            updatedAt
            preferencesID
            owner
            __typename
          }
          nextToken
          __typename
        }
        package
        certifications
        noticeperiod
        maxExperienceRequired
        minExperienceRequired
        modeofwork
        JobDomain
        other
        previousrole
        combinedrole
        currencyCountry
        JobType
        Location
        JobPublished
        numberOfCandidateRequired
        updatedAt
        owner
        __typename
      }
      profile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      id
      candidateprofile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      businessProfile {
        createdAt
        businessUserId
        name
        companyMailId
        id
        preferences
        about
        websiteUrl
        LogoImageKey
        IndustryType
        Shortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CompanyQrew {
          items {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        prefferedprofile {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        preferenceslist {
          items {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        companyusers {
          items {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateShortlist = /* GraphQL */ `
  subscription OnUpdateShortlist(
    $filter: ModelSubscriptionShortlistFilterInput
  ) {
    onUpdateShortlist(filter: $filter) {
      createdAt
      businessProfileId
      businessUserId
      UserId
      about
      candidateprofileId
      shortlistedbyId
      JobPostId
      jobPost {
        id
        CompanyPreferenceId
        companyQrewId
        CompanyPreference {
          createdAt
          id
          rolename
          businessUserId
          businessProfileId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          recommendedprofiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejectedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobPosts {
            items {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobTraits {
            items {
              id
              TraitName
              createdAt
              updatedAt
              preferencesID
              owner
              __typename
            }
            nextToken
            __typename
          }
          package
          certifications
          noticeperiod
          maxExperienceRequired
          minExperienceRequired
          modeofwork
          JobDomain
          other
          previousrole
          combinedrole
          currencyCountry
          JobType
          Location
          JobPublished
          numberOfCandidateRequired
          updatedAt
          owner
          __typename
        }
        companyName
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        shortlistedCandidates {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        jobPostQuestions {
          items {
            id
            JobPostId
            preferencesID
            Question
            TypeOfQuesiton
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        jobPostLink
        logoUrl
        htmldescription
        CompanyDescription
        AvailablityStatus
        experienceRequired
        jobType
        Location
        skills
        domain
        description
        ShortDesc
        RolesandResponsibility
        title
        CapabilitiesRequired {
          items {
            id
            jobPostID
            GlobalCapabilityConfigID
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        FeedsConnected {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CapabilitiesRequiredJSON
        createdAt
        updatedAt
        SavedJobPosts {
          items {
            id
            JobPostId
            ProfileId
            UserId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        owner
        __typename
      }
      shortlistedByUser {
        createdAt
        username
        designation
        ProfilePictureKey
        businessProfileId
        id
        cognitoId
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        Shortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        UserRole
        editObjectiveHistory {
          items {
            id
            createdAt
            updatedAt
            objectiveId
            Objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            prevTitle
            editedbyCompanyUserProfileId
            editbyCompanyUserProfile {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            editedbyDeployedQrewId
            editedDeployedQrewProfile {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        email
        updatedAt
        owner
        __typename
      }
      recommededId
      preferencesId
      prferenceType {
        createdAt
        id
        rolename
        businessUserId
        businessProfileId
        companyQrewId
        companyQrew {
          id
          qrewname
          aboutqrew
          businessProfileId
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Invoices {
            items {
              id
              CtcInvoiceNumber
              PlatformInvoiceNumber
              CompanyQrewId
              businessProfileId
              companyName
              CtcInvoiceS3Key
              PlarformInvoiceS3Key
              CtcTxnNumber
              PlatformTxnNumber
              CtcTxnNumberApproved
              PlatformTxnNumberApproved
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          qrewroles {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          objectives {
            items {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          durationInmonths
          qrewLive
          createdAt
          updatedAt
          owner
          __typename
        }
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        recommendedprofiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        shortlistedProfiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejectedProfiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobPosts {
          items {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedQrew {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobTraits {
          items {
            id
            TraitName
            createdAt
            updatedAt
            preferencesID
            owner
            __typename
          }
          nextToken
          __typename
        }
        package
        certifications
        noticeperiod
        maxExperienceRequired
        minExperienceRequired
        modeofwork
        JobDomain
        other
        previousrole
        combinedrole
        currencyCountry
        JobType
        Location
        JobPublished
        numberOfCandidateRequired
        updatedAt
        owner
        __typename
      }
      profile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      id
      candidateprofile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      businessProfile {
        createdAt
        businessUserId
        name
        companyMailId
        id
        preferences
        about
        websiteUrl
        LogoImageKey
        IndustryType
        Shortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CompanyQrew {
          items {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        prefferedprofile {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        preferenceslist {
          items {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        companyusers {
          items {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteShortlist = /* GraphQL */ `
  subscription OnDeleteShortlist(
    $filter: ModelSubscriptionShortlistFilterInput
  ) {
    onDeleteShortlist(filter: $filter) {
      createdAt
      businessProfileId
      businessUserId
      UserId
      about
      candidateprofileId
      shortlistedbyId
      JobPostId
      jobPost {
        id
        CompanyPreferenceId
        companyQrewId
        CompanyPreference {
          createdAt
          id
          rolename
          businessUserId
          businessProfileId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          recommendedprofiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejectedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobPosts {
            items {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobTraits {
            items {
              id
              TraitName
              createdAt
              updatedAt
              preferencesID
              owner
              __typename
            }
            nextToken
            __typename
          }
          package
          certifications
          noticeperiod
          maxExperienceRequired
          minExperienceRequired
          modeofwork
          JobDomain
          other
          previousrole
          combinedrole
          currencyCountry
          JobType
          Location
          JobPublished
          numberOfCandidateRequired
          updatedAt
          owner
          __typename
        }
        companyName
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        shortlistedCandidates {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        jobPostQuestions {
          items {
            id
            JobPostId
            preferencesID
            Question
            TypeOfQuesiton
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        jobPostLink
        logoUrl
        htmldescription
        CompanyDescription
        AvailablityStatus
        experienceRequired
        jobType
        Location
        skills
        domain
        description
        ShortDesc
        RolesandResponsibility
        title
        CapabilitiesRequired {
          items {
            id
            jobPostID
            GlobalCapabilityConfigID
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        FeedsConnected {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CapabilitiesRequiredJSON
        createdAt
        updatedAt
        SavedJobPosts {
          items {
            id
            JobPostId
            ProfileId
            UserId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        owner
        __typename
      }
      shortlistedByUser {
        createdAt
        username
        designation
        ProfilePictureKey
        businessProfileId
        id
        cognitoId
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        Shortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        UserRole
        editObjectiveHistory {
          items {
            id
            createdAt
            updatedAt
            objectiveId
            Objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            prevTitle
            editedbyCompanyUserProfileId
            editbyCompanyUserProfile {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            editedbyDeployedQrewId
            editedDeployedQrewProfile {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        email
        updatedAt
        owner
        __typename
      }
      recommededId
      preferencesId
      prferenceType {
        createdAt
        id
        rolename
        businessUserId
        businessProfileId
        companyQrewId
        companyQrew {
          id
          qrewname
          aboutqrew
          businessProfileId
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Invoices {
            items {
              id
              CtcInvoiceNumber
              PlatformInvoiceNumber
              CompanyQrewId
              businessProfileId
              companyName
              CtcInvoiceS3Key
              PlarformInvoiceS3Key
              CtcTxnNumber
              PlatformTxnNumber
              CtcTxnNumberApproved
              PlatformTxnNumberApproved
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          qrewroles {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          objectives {
            items {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          durationInmonths
          qrewLive
          createdAt
          updatedAt
          owner
          __typename
        }
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        recommendedprofiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        shortlistedProfiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejectedProfiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobPosts {
          items {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedQrew {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobTraits {
          items {
            id
            TraitName
            createdAt
            updatedAt
            preferencesID
            owner
            __typename
          }
          nextToken
          __typename
        }
        package
        certifications
        noticeperiod
        maxExperienceRequired
        minExperienceRequired
        modeofwork
        JobDomain
        other
        previousrole
        combinedrole
        currencyCountry
        JobType
        Location
        JobPublished
        numberOfCandidateRequired
        updatedAt
        owner
        __typename
      }
      profile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      id
      candidateprofile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      businessProfile {
        createdAt
        businessUserId
        name
        companyMailId
        id
        preferences
        about
        websiteUrl
        LogoImageKey
        IndustryType
        Shortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CompanyQrew {
          items {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        prefferedprofile {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        preferenceslist {
          items {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        companyusers {
          items {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateRejected = /* GraphQL */ `
  subscription OnCreateRejected($filter: ModelSubscriptionRejectedFilterInput) {
    onCreateRejected(filter: $filter) {
      createdAt
      businessProfileId
      businessUserId
      shortlistedbyId
      shortlistedByUser {
        createdAt
        username
        designation
        ProfilePictureKey
        businessProfileId
        id
        cognitoId
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        Shortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        UserRole
        editObjectiveHistory {
          items {
            id
            createdAt
            updatedAt
            objectiveId
            Objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            prevTitle
            editedbyCompanyUserProfileId
            editbyCompanyUserProfile {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            editedbyDeployedQrewId
            editedDeployedQrewProfile {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        email
        updatedAt
        owner
        __typename
      }
      UserId
      about
      candidateprofileId
      Reasonofrejection
      recommededId
      preferencesId
      prferenceType {
        createdAt
        id
        rolename
        businessUserId
        businessProfileId
        companyQrewId
        companyQrew {
          id
          qrewname
          aboutqrew
          businessProfileId
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Invoices {
            items {
              id
              CtcInvoiceNumber
              PlatformInvoiceNumber
              CompanyQrewId
              businessProfileId
              companyName
              CtcInvoiceS3Key
              PlarformInvoiceS3Key
              CtcTxnNumber
              PlatformTxnNumber
              CtcTxnNumberApproved
              PlatformTxnNumberApproved
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          qrewroles {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          objectives {
            items {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          durationInmonths
          qrewLive
          createdAt
          updatedAt
          owner
          __typename
        }
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        recommendedprofiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        shortlistedProfiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejectedProfiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobPosts {
          items {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedQrew {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobTraits {
          items {
            id
            TraitName
            createdAt
            updatedAt
            preferencesID
            owner
            __typename
          }
          nextToken
          __typename
        }
        package
        certifications
        noticeperiod
        maxExperienceRequired
        minExperienceRequired
        modeofwork
        JobDomain
        other
        previousrole
        combinedrole
        currencyCountry
        JobType
        Location
        JobPublished
        numberOfCandidateRequired
        updatedAt
        owner
        __typename
      }
      profile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      id
      candidateprofile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      businessProfile {
        createdAt
        businessUserId
        name
        companyMailId
        id
        preferences
        about
        websiteUrl
        LogoImageKey
        IndustryType
        Shortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CompanyQrew {
          items {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        prefferedprofile {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        preferenceslist {
          items {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        companyusers {
          items {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateRejected = /* GraphQL */ `
  subscription OnUpdateRejected($filter: ModelSubscriptionRejectedFilterInput) {
    onUpdateRejected(filter: $filter) {
      createdAt
      businessProfileId
      businessUserId
      shortlistedbyId
      shortlistedByUser {
        createdAt
        username
        designation
        ProfilePictureKey
        businessProfileId
        id
        cognitoId
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        Shortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        UserRole
        editObjectiveHistory {
          items {
            id
            createdAt
            updatedAt
            objectiveId
            Objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            prevTitle
            editedbyCompanyUserProfileId
            editbyCompanyUserProfile {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            editedbyDeployedQrewId
            editedDeployedQrewProfile {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        email
        updatedAt
        owner
        __typename
      }
      UserId
      about
      candidateprofileId
      Reasonofrejection
      recommededId
      preferencesId
      prferenceType {
        createdAt
        id
        rolename
        businessUserId
        businessProfileId
        companyQrewId
        companyQrew {
          id
          qrewname
          aboutqrew
          businessProfileId
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Invoices {
            items {
              id
              CtcInvoiceNumber
              PlatformInvoiceNumber
              CompanyQrewId
              businessProfileId
              companyName
              CtcInvoiceS3Key
              PlarformInvoiceS3Key
              CtcTxnNumber
              PlatformTxnNumber
              CtcTxnNumberApproved
              PlatformTxnNumberApproved
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          qrewroles {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          objectives {
            items {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          durationInmonths
          qrewLive
          createdAt
          updatedAt
          owner
          __typename
        }
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        recommendedprofiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        shortlistedProfiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejectedProfiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobPosts {
          items {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedQrew {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobTraits {
          items {
            id
            TraitName
            createdAt
            updatedAt
            preferencesID
            owner
            __typename
          }
          nextToken
          __typename
        }
        package
        certifications
        noticeperiod
        maxExperienceRequired
        minExperienceRequired
        modeofwork
        JobDomain
        other
        previousrole
        combinedrole
        currencyCountry
        JobType
        Location
        JobPublished
        numberOfCandidateRequired
        updatedAt
        owner
        __typename
      }
      profile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      id
      candidateprofile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      businessProfile {
        createdAt
        businessUserId
        name
        companyMailId
        id
        preferences
        about
        websiteUrl
        LogoImageKey
        IndustryType
        Shortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CompanyQrew {
          items {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        prefferedprofile {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        preferenceslist {
          items {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        companyusers {
          items {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteRejected = /* GraphQL */ `
  subscription OnDeleteRejected($filter: ModelSubscriptionRejectedFilterInput) {
    onDeleteRejected(filter: $filter) {
      createdAt
      businessProfileId
      businessUserId
      shortlistedbyId
      shortlistedByUser {
        createdAt
        username
        designation
        ProfilePictureKey
        businessProfileId
        id
        cognitoId
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        Shortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        UserRole
        editObjectiveHistory {
          items {
            id
            createdAt
            updatedAt
            objectiveId
            Objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            prevTitle
            editedbyCompanyUserProfileId
            editbyCompanyUserProfile {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            editedbyDeployedQrewId
            editedDeployedQrewProfile {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        email
        updatedAt
        owner
        __typename
      }
      UserId
      about
      candidateprofileId
      Reasonofrejection
      recommededId
      preferencesId
      prferenceType {
        createdAt
        id
        rolename
        businessUserId
        businessProfileId
        companyQrewId
        companyQrew {
          id
          qrewname
          aboutqrew
          businessProfileId
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Invoices {
            items {
              id
              CtcInvoiceNumber
              PlatformInvoiceNumber
              CompanyQrewId
              businessProfileId
              companyName
              CtcInvoiceS3Key
              PlarformInvoiceS3Key
              CtcTxnNumber
              PlatformTxnNumber
              CtcTxnNumberApproved
              PlatformTxnNumberApproved
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          qrewroles {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          objectives {
            items {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          durationInmonths
          qrewLive
          createdAt
          updatedAt
          owner
          __typename
        }
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        recommendedprofiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        shortlistedProfiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejectedProfiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobPosts {
          items {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedQrew {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobTraits {
          items {
            id
            TraitName
            createdAt
            updatedAt
            preferencesID
            owner
            __typename
          }
          nextToken
          __typename
        }
        package
        certifications
        noticeperiod
        maxExperienceRequired
        minExperienceRequired
        modeofwork
        JobDomain
        other
        previousrole
        combinedrole
        currencyCountry
        JobType
        Location
        JobPublished
        numberOfCandidateRequired
        updatedAt
        owner
        __typename
      }
      profile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      id
      candidateprofile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      businessProfile {
        createdAt
        businessUserId
        name
        companyMailId
        id
        preferences
        about
        websiteUrl
        LogoImageKey
        IndustryType
        Shortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CompanyQrew {
          items {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        prefferedprofile {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        preferenceslist {
          items {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        companyusers {
          items {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateRecommendedprofile = /* GraphQL */ `
  subscription OnCreateRecommendedprofile(
    $filter: ModelSubscriptionRecommendedprofileFilterInput
  ) {
    onCreateRecommendedprofile(filter: $filter) {
      createdAt
      businessProfileId
      businessUserId
      UserId
      candidateprofileId
      acceptanceStatus
      ReasonofRecommendation
      ReasonofShortlist
      Reasonofrejection
      acceptanceStatus_by_CompanyUser_ID
      acceptanceStatus_by_CompanyUser_name
      recommendedbyId
      prferenceType {
        createdAt
        id
        rolename
        businessUserId
        businessProfileId
        companyQrewId
        companyQrew {
          id
          qrewname
          aboutqrew
          businessProfileId
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Invoices {
            items {
              id
              CtcInvoiceNumber
              PlatformInvoiceNumber
              CompanyQrewId
              businessProfileId
              companyName
              CtcInvoiceS3Key
              PlarformInvoiceS3Key
              CtcTxnNumber
              PlatformTxnNumber
              CtcTxnNumberApproved
              PlatformTxnNumberApproved
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          qrewroles {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          objectives {
            items {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          durationInmonths
          qrewLive
          createdAt
          updatedAt
          owner
          __typename
        }
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        recommendedprofiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        shortlistedProfiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejectedProfiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobPosts {
          items {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedQrew {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobTraits {
          items {
            id
            TraitName
            createdAt
            updatedAt
            preferencesID
            owner
            __typename
          }
          nextToken
          __typename
        }
        package
        certifications
        noticeperiod
        maxExperienceRequired
        minExperienceRequired
        modeofwork
        JobDomain
        other
        previousrole
        combinedrole
        currencyCountry
        JobType
        Location
        JobPublished
        numberOfCandidateRequired
        updatedAt
        owner
        __typename
      }
      preferencesId
      companyQrewId
      JobApplicationsId
      JobApplication {
        id
        JobPostId
        jobPost {
          id
          CompanyPreferenceId
          companyQrewId
          CompanyPreference {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          companyName
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedCandidates {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          jobPostQuestions {
            items {
              id
              JobPostId
              preferencesID
              Question
              TypeOfQuesiton
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          jobPostLink
          logoUrl
          htmldescription
          CompanyDescription
          AvailablityStatus
          experienceRequired
          jobType
          Location
          skills
          domain
          description
          ShortDesc
          RolesandResponsibility
          title
          CapabilitiesRequired {
            items {
              id
              jobPostID
              GlobalCapabilityConfigID
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          FeedsConnected {
            items {
              id
              UserSegmentId
              HidiId
              CapabilityConfigId
              jobPostId
              tags
              domain
              Description
              Payload
              FeedType
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CapabilitiesRequiredJSON
          createdAt
          updatedAt
          SavedJobPosts {
            items {
              id
              JobPostId
              ProfileId
              UserId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          owner
          __typename
        }
        CompanyProfileId
        ProfileId
        CompanyPreferenceId
        companyQrewId
        candidateprofile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        ApplyingStatus
        Level
        CandidateProfileAnalysis
        createdAt
        updatedAt
        owner
        __typename
      }
      JobPostId
      candidateprofile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      businessProfile {
        createdAt
        businessUserId
        name
        companyMailId
        id
        preferences
        about
        websiteUrl
        LogoImageKey
        IndustryType
        Shortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CompanyQrew {
          items {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        prefferedprofile {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        preferenceslist {
          items {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        companyusers {
          items {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      new
      id
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateRecommendedprofile = /* GraphQL */ `
  subscription OnUpdateRecommendedprofile(
    $filter: ModelSubscriptionRecommendedprofileFilterInput
  ) {
    onUpdateRecommendedprofile(filter: $filter) {
      createdAt
      businessProfileId
      businessUserId
      UserId
      candidateprofileId
      acceptanceStatus
      ReasonofRecommendation
      ReasonofShortlist
      Reasonofrejection
      acceptanceStatus_by_CompanyUser_ID
      acceptanceStatus_by_CompanyUser_name
      recommendedbyId
      prferenceType {
        createdAt
        id
        rolename
        businessUserId
        businessProfileId
        companyQrewId
        companyQrew {
          id
          qrewname
          aboutqrew
          businessProfileId
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Invoices {
            items {
              id
              CtcInvoiceNumber
              PlatformInvoiceNumber
              CompanyQrewId
              businessProfileId
              companyName
              CtcInvoiceS3Key
              PlarformInvoiceS3Key
              CtcTxnNumber
              PlatformTxnNumber
              CtcTxnNumberApproved
              PlatformTxnNumberApproved
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          qrewroles {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          objectives {
            items {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          durationInmonths
          qrewLive
          createdAt
          updatedAt
          owner
          __typename
        }
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        recommendedprofiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        shortlistedProfiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejectedProfiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobPosts {
          items {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedQrew {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobTraits {
          items {
            id
            TraitName
            createdAt
            updatedAt
            preferencesID
            owner
            __typename
          }
          nextToken
          __typename
        }
        package
        certifications
        noticeperiod
        maxExperienceRequired
        minExperienceRequired
        modeofwork
        JobDomain
        other
        previousrole
        combinedrole
        currencyCountry
        JobType
        Location
        JobPublished
        numberOfCandidateRequired
        updatedAt
        owner
        __typename
      }
      preferencesId
      companyQrewId
      JobApplicationsId
      JobApplication {
        id
        JobPostId
        jobPost {
          id
          CompanyPreferenceId
          companyQrewId
          CompanyPreference {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          companyName
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedCandidates {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          jobPostQuestions {
            items {
              id
              JobPostId
              preferencesID
              Question
              TypeOfQuesiton
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          jobPostLink
          logoUrl
          htmldescription
          CompanyDescription
          AvailablityStatus
          experienceRequired
          jobType
          Location
          skills
          domain
          description
          ShortDesc
          RolesandResponsibility
          title
          CapabilitiesRequired {
            items {
              id
              jobPostID
              GlobalCapabilityConfigID
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          FeedsConnected {
            items {
              id
              UserSegmentId
              HidiId
              CapabilityConfigId
              jobPostId
              tags
              domain
              Description
              Payload
              FeedType
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CapabilitiesRequiredJSON
          createdAt
          updatedAt
          SavedJobPosts {
            items {
              id
              JobPostId
              ProfileId
              UserId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          owner
          __typename
        }
        CompanyProfileId
        ProfileId
        CompanyPreferenceId
        companyQrewId
        candidateprofile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        ApplyingStatus
        Level
        CandidateProfileAnalysis
        createdAt
        updatedAt
        owner
        __typename
      }
      JobPostId
      candidateprofile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      businessProfile {
        createdAt
        businessUserId
        name
        companyMailId
        id
        preferences
        about
        websiteUrl
        LogoImageKey
        IndustryType
        Shortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CompanyQrew {
          items {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        prefferedprofile {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        preferenceslist {
          items {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        companyusers {
          items {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      new
      id
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteRecommendedprofile = /* GraphQL */ `
  subscription OnDeleteRecommendedprofile(
    $filter: ModelSubscriptionRecommendedprofileFilterInput
  ) {
    onDeleteRecommendedprofile(filter: $filter) {
      createdAt
      businessProfileId
      businessUserId
      UserId
      candidateprofileId
      acceptanceStatus
      ReasonofRecommendation
      ReasonofShortlist
      Reasonofrejection
      acceptanceStatus_by_CompanyUser_ID
      acceptanceStatus_by_CompanyUser_name
      recommendedbyId
      prferenceType {
        createdAt
        id
        rolename
        businessUserId
        businessProfileId
        companyQrewId
        companyQrew {
          id
          qrewname
          aboutqrew
          businessProfileId
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Invoices {
            items {
              id
              CtcInvoiceNumber
              PlatformInvoiceNumber
              CompanyQrewId
              businessProfileId
              companyName
              CtcInvoiceS3Key
              PlarformInvoiceS3Key
              CtcTxnNumber
              PlatformTxnNumber
              CtcTxnNumberApproved
              PlatformTxnNumberApproved
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          qrewroles {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          objectives {
            items {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          durationInmonths
          qrewLive
          createdAt
          updatedAt
          owner
          __typename
        }
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        recommendedprofiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        shortlistedProfiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejectedProfiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobPosts {
          items {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedQrew {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobTraits {
          items {
            id
            TraitName
            createdAt
            updatedAt
            preferencesID
            owner
            __typename
          }
          nextToken
          __typename
        }
        package
        certifications
        noticeperiod
        maxExperienceRequired
        minExperienceRequired
        modeofwork
        JobDomain
        other
        previousrole
        combinedrole
        currencyCountry
        JobType
        Location
        JobPublished
        numberOfCandidateRequired
        updatedAt
        owner
        __typename
      }
      preferencesId
      companyQrewId
      JobApplicationsId
      JobApplication {
        id
        JobPostId
        jobPost {
          id
          CompanyPreferenceId
          companyQrewId
          CompanyPreference {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          companyName
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedCandidates {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          jobPostQuestions {
            items {
              id
              JobPostId
              preferencesID
              Question
              TypeOfQuesiton
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          jobPostLink
          logoUrl
          htmldescription
          CompanyDescription
          AvailablityStatus
          experienceRequired
          jobType
          Location
          skills
          domain
          description
          ShortDesc
          RolesandResponsibility
          title
          CapabilitiesRequired {
            items {
              id
              jobPostID
              GlobalCapabilityConfigID
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          FeedsConnected {
            items {
              id
              UserSegmentId
              HidiId
              CapabilityConfigId
              jobPostId
              tags
              domain
              Description
              Payload
              FeedType
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CapabilitiesRequiredJSON
          createdAt
          updatedAt
          SavedJobPosts {
            items {
              id
              JobPostId
              ProfileId
              UserId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          owner
          __typename
        }
        CompanyProfileId
        ProfileId
        CompanyPreferenceId
        companyQrewId
        candidateprofile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        ApplyingStatus
        Level
        CandidateProfileAnalysis
        createdAt
        updatedAt
        owner
        __typename
      }
      JobPostId
      candidateprofile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      businessProfile {
        createdAt
        businessUserId
        name
        companyMailId
        id
        preferences
        about
        websiteUrl
        LogoImageKey
        IndustryType
        Shortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CompanyQrew {
          items {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        prefferedprofile {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        preferenceslist {
          items {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        companyusers {
          items {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      new
      id
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateDeployedQrewHistory = /* GraphQL */ `
  subscription OnCreateDeployedQrewHistory(
    $filter: ModelSubscriptionDeployedQrewHistoryFilterInput
  ) {
    onCreateDeployedQrewHistory(filter: $filter) {
      createdAt
      updatedAt
      companyQrewId
      businessProfileId
      finalCTCQrew
      candidateprofileId
      qrewPosition
      preferencesId
      recommededId
      joiningDate
      endingDate
      NDAkey
      Contractkey
      KYCdocumentkey
      WorkingOnit
      EndContract
      ContractEndingDate
      CandidateAcceptanceStatus
      CandidateContractRejectionReason
      ExtensionDurationInMonths
      ExtensionDate
      ExtendingContract
      NewContractCTC
      ContractEndedBy
      ContractPausedBy
      JobApplicationsId
      deployedQrewId
      id
      owner
      __typename
    }
  }
`;
export const onUpdateDeployedQrewHistory = /* GraphQL */ `
  subscription OnUpdateDeployedQrewHistory(
    $filter: ModelSubscriptionDeployedQrewHistoryFilterInput
  ) {
    onUpdateDeployedQrewHistory(filter: $filter) {
      createdAt
      updatedAt
      companyQrewId
      businessProfileId
      finalCTCQrew
      candidateprofileId
      qrewPosition
      preferencesId
      recommededId
      joiningDate
      endingDate
      NDAkey
      Contractkey
      KYCdocumentkey
      WorkingOnit
      EndContract
      ContractEndingDate
      CandidateAcceptanceStatus
      CandidateContractRejectionReason
      ExtensionDurationInMonths
      ExtensionDate
      ExtendingContract
      NewContractCTC
      ContractEndedBy
      ContractPausedBy
      JobApplicationsId
      deployedQrewId
      id
      owner
      __typename
    }
  }
`;
export const onDeleteDeployedQrewHistory = /* GraphQL */ `
  subscription OnDeleteDeployedQrewHistory(
    $filter: ModelSubscriptionDeployedQrewHistoryFilterInput
  ) {
    onDeleteDeployedQrewHistory(filter: $filter) {
      createdAt
      updatedAt
      companyQrewId
      businessProfileId
      finalCTCQrew
      candidateprofileId
      qrewPosition
      preferencesId
      recommededId
      joiningDate
      endingDate
      NDAkey
      Contractkey
      KYCdocumentkey
      WorkingOnit
      EndContract
      ContractEndingDate
      CandidateAcceptanceStatus
      CandidateContractRejectionReason
      ExtensionDurationInMonths
      ExtensionDate
      ExtendingContract
      NewContractCTC
      ContractEndedBy
      ContractPausedBy
      JobApplicationsId
      deployedQrewId
      id
      owner
      __typename
    }
  }
`;
export const onCreateDeployedQrew = /* GraphQL */ `
  subscription OnCreateDeployedQrew(
    $filter: ModelSubscriptionDeployedQrewFilterInput
  ) {
    onCreateDeployedQrew(filter: $filter) {
      createdAt
      updatedAt
      companyQrewId
      businessProfileId
      companyQrew {
        id
        qrewname
        aboutqrew
        businessProfileId
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        Invoices {
          items {
            id
            CtcInvoiceNumber
            PlatformInvoiceNumber
            CompanyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            businessProfileId
            companyName
            CtcInvoiceS3Key
            PlarformInvoiceS3Key
            CtcTxnNumber
            PlatformTxnNumber
            CtcTxnNumberApproved
            PlatformTxnNumberApproved
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        qrewroles {
          items {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedQrew {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        objectives {
          items {
            id
            createdAt
            updatedAt
            title
            creator
            companyProfileId
            companyUserId
            ObjectivekeyResults {
              nextToken
              __typename
            }
            deployedQrewId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            editHistory {
              nextToken
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        initiatives {
          items {
            id
            createdAt
            updatedAt
            initiative
            initiativeDocumentation
            keyResultsAcheived
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveKeyResultId
            objectiveId
            approvalStatus
            objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            ObjectiveKeyResult {
              id
              createdAt
              updatedAt
              keyResult
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveId
              progressStatus
              progresssPercentage
              owner
              __typename
            }
            InitiativeCreatedBydeployedQrew {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            PercentageContributionOnkeyResult
            TimeRequiredToComplete
            owner
            __typename
          }
          nextToken
          __typename
        }
        durationInmonths
        qrewLive
        createdAt
        updatedAt
        owner
        __typename
      }
      finalCTCQrew
      candidateprofileId
      qrewPosition
      deployedUserProfile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      preferencesId
      JobPostPreferences {
        createdAt
        id
        rolename
        businessUserId
        businessProfileId
        companyQrewId
        companyQrew {
          id
          qrewname
          aboutqrew
          businessProfileId
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Invoices {
            items {
              id
              CtcInvoiceNumber
              PlatformInvoiceNumber
              CompanyQrewId
              businessProfileId
              companyName
              CtcInvoiceS3Key
              PlarformInvoiceS3Key
              CtcTxnNumber
              PlatformTxnNumber
              CtcTxnNumberApproved
              PlatformTxnNumberApproved
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          qrewroles {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          objectives {
            items {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          durationInmonths
          qrewLive
          createdAt
          updatedAt
          owner
          __typename
        }
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        recommendedprofiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        shortlistedProfiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejectedProfiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobPosts {
          items {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedQrew {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobTraits {
          items {
            id
            TraitName
            createdAt
            updatedAt
            preferencesID
            owner
            __typename
          }
          nextToken
          __typename
        }
        package
        certifications
        noticeperiod
        maxExperienceRequired
        minExperienceRequired
        modeofwork
        JobDomain
        other
        previousrole
        combinedrole
        currencyCountry
        JobType
        Location
        JobPublished
        numberOfCandidateRequired
        updatedAt
        owner
        __typename
      }
      deployedQrewHistory {
        items {
          createdAt
          updatedAt
          companyQrewId
          businessProfileId
          finalCTCQrew
          candidateprofileId
          qrewPosition
          preferencesId
          recommededId
          joiningDate
          endingDate
          NDAkey
          Contractkey
          KYCdocumentkey
          WorkingOnit
          EndContract
          ContractEndingDate
          CandidateAcceptanceStatus
          CandidateContractRejectionReason
          ExtensionDurationInMonths
          ExtensionDate
          ExtendingContract
          NewContractCTC
          ContractEndedBy
          ContractPausedBy
          JobApplicationsId
          deployedQrewId
          id
          owner
          __typename
        }
        nextToken
        __typename
      }
      recommededId
      joiningDate
      endingDate
      NDAkey
      Contractkey
      KYCdocumentkey
      WorkingOnit
      EndContract
      ContractEndingDate
      ExtensionDurationInMonths
      ExtensionDate
      ExtendingContract
      CandidateAcceptanceStatus
      CandidateContractRejectionReason
      CandidateContractNegotiatingReason
      NewContractCTC
      ContractEndedBy
      ContractExtendedBy
      ContractPausedBy
      JobApplicationsId
      initiatives {
        items {
          id
          createdAt
          updatedAt
          initiative
          initiativeDocumentation
          keyResultsAcheived
          deployedQrewId
          companyProfileId
          candidateProfileId
          companyQrewId
          objectiveKeyResultId
          objectiveId
          approvalStatus
          objective {
            id
            createdAt
            updatedAt
            title
            creator
            companyProfileId
            companyUserId
            ObjectivekeyResults {
              nextToken
              __typename
            }
            deployedQrewId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            editHistory {
              nextToken
              __typename
            }
            owner
            __typename
          }
          ObjectiveKeyResult {
            id
            createdAt
            updatedAt
            keyResult
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveId
            initiatives {
              nextToken
              __typename
            }
            progressStatus
            progresssPercentage
            owner
            __typename
          }
          InitiativeCreatedBydeployedQrew {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          PercentageContributionOnkeyResult
          TimeRequiredToComplete
          owner
          __typename
        }
        nextToken
        __typename
      }
      editObjectiveHistory {
        items {
          id
          createdAt
          updatedAt
          objectiveId
          Objective {
            id
            createdAt
            updatedAt
            title
            creator
            companyProfileId
            companyUserId
            ObjectivekeyResults {
              nextToken
              __typename
            }
            deployedQrewId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            editHistory {
              nextToken
              __typename
            }
            owner
            __typename
          }
          prevTitle
          editedbyCompanyUserProfileId
          editbyCompanyUserProfile {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          editedbyDeployedQrewId
          editedDeployedQrewProfile {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          owner
          __typename
        }
        nextToken
        __typename
      }
      id
      owner
      __typename
    }
  }
`;
export const onUpdateDeployedQrew = /* GraphQL */ `
  subscription OnUpdateDeployedQrew(
    $filter: ModelSubscriptionDeployedQrewFilterInput
  ) {
    onUpdateDeployedQrew(filter: $filter) {
      createdAt
      updatedAt
      companyQrewId
      businessProfileId
      companyQrew {
        id
        qrewname
        aboutqrew
        businessProfileId
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        Invoices {
          items {
            id
            CtcInvoiceNumber
            PlatformInvoiceNumber
            CompanyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            businessProfileId
            companyName
            CtcInvoiceS3Key
            PlarformInvoiceS3Key
            CtcTxnNumber
            PlatformTxnNumber
            CtcTxnNumberApproved
            PlatformTxnNumberApproved
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        qrewroles {
          items {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedQrew {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        objectives {
          items {
            id
            createdAt
            updatedAt
            title
            creator
            companyProfileId
            companyUserId
            ObjectivekeyResults {
              nextToken
              __typename
            }
            deployedQrewId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            editHistory {
              nextToken
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        initiatives {
          items {
            id
            createdAt
            updatedAt
            initiative
            initiativeDocumentation
            keyResultsAcheived
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveKeyResultId
            objectiveId
            approvalStatus
            objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            ObjectiveKeyResult {
              id
              createdAt
              updatedAt
              keyResult
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveId
              progressStatus
              progresssPercentage
              owner
              __typename
            }
            InitiativeCreatedBydeployedQrew {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            PercentageContributionOnkeyResult
            TimeRequiredToComplete
            owner
            __typename
          }
          nextToken
          __typename
        }
        durationInmonths
        qrewLive
        createdAt
        updatedAt
        owner
        __typename
      }
      finalCTCQrew
      candidateprofileId
      qrewPosition
      deployedUserProfile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      preferencesId
      JobPostPreferences {
        createdAt
        id
        rolename
        businessUserId
        businessProfileId
        companyQrewId
        companyQrew {
          id
          qrewname
          aboutqrew
          businessProfileId
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Invoices {
            items {
              id
              CtcInvoiceNumber
              PlatformInvoiceNumber
              CompanyQrewId
              businessProfileId
              companyName
              CtcInvoiceS3Key
              PlarformInvoiceS3Key
              CtcTxnNumber
              PlatformTxnNumber
              CtcTxnNumberApproved
              PlatformTxnNumberApproved
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          qrewroles {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          objectives {
            items {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          durationInmonths
          qrewLive
          createdAt
          updatedAt
          owner
          __typename
        }
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        recommendedprofiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        shortlistedProfiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejectedProfiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobPosts {
          items {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedQrew {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobTraits {
          items {
            id
            TraitName
            createdAt
            updatedAt
            preferencesID
            owner
            __typename
          }
          nextToken
          __typename
        }
        package
        certifications
        noticeperiod
        maxExperienceRequired
        minExperienceRequired
        modeofwork
        JobDomain
        other
        previousrole
        combinedrole
        currencyCountry
        JobType
        Location
        JobPublished
        numberOfCandidateRequired
        updatedAt
        owner
        __typename
      }
      deployedQrewHistory {
        items {
          createdAt
          updatedAt
          companyQrewId
          businessProfileId
          finalCTCQrew
          candidateprofileId
          qrewPosition
          preferencesId
          recommededId
          joiningDate
          endingDate
          NDAkey
          Contractkey
          KYCdocumentkey
          WorkingOnit
          EndContract
          ContractEndingDate
          CandidateAcceptanceStatus
          CandidateContractRejectionReason
          ExtensionDurationInMonths
          ExtensionDate
          ExtendingContract
          NewContractCTC
          ContractEndedBy
          ContractPausedBy
          JobApplicationsId
          deployedQrewId
          id
          owner
          __typename
        }
        nextToken
        __typename
      }
      recommededId
      joiningDate
      endingDate
      NDAkey
      Contractkey
      KYCdocumentkey
      WorkingOnit
      EndContract
      ContractEndingDate
      ExtensionDurationInMonths
      ExtensionDate
      ExtendingContract
      CandidateAcceptanceStatus
      CandidateContractRejectionReason
      CandidateContractNegotiatingReason
      NewContractCTC
      ContractEndedBy
      ContractExtendedBy
      ContractPausedBy
      JobApplicationsId
      initiatives {
        items {
          id
          createdAt
          updatedAt
          initiative
          initiativeDocumentation
          keyResultsAcheived
          deployedQrewId
          companyProfileId
          candidateProfileId
          companyQrewId
          objectiveKeyResultId
          objectiveId
          approvalStatus
          objective {
            id
            createdAt
            updatedAt
            title
            creator
            companyProfileId
            companyUserId
            ObjectivekeyResults {
              nextToken
              __typename
            }
            deployedQrewId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            editHistory {
              nextToken
              __typename
            }
            owner
            __typename
          }
          ObjectiveKeyResult {
            id
            createdAt
            updatedAt
            keyResult
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveId
            initiatives {
              nextToken
              __typename
            }
            progressStatus
            progresssPercentage
            owner
            __typename
          }
          InitiativeCreatedBydeployedQrew {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          PercentageContributionOnkeyResult
          TimeRequiredToComplete
          owner
          __typename
        }
        nextToken
        __typename
      }
      editObjectiveHistory {
        items {
          id
          createdAt
          updatedAt
          objectiveId
          Objective {
            id
            createdAt
            updatedAt
            title
            creator
            companyProfileId
            companyUserId
            ObjectivekeyResults {
              nextToken
              __typename
            }
            deployedQrewId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            editHistory {
              nextToken
              __typename
            }
            owner
            __typename
          }
          prevTitle
          editedbyCompanyUserProfileId
          editbyCompanyUserProfile {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          editedbyDeployedQrewId
          editedDeployedQrewProfile {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          owner
          __typename
        }
        nextToken
        __typename
      }
      id
      owner
      __typename
    }
  }
`;
export const onDeleteDeployedQrew = /* GraphQL */ `
  subscription OnDeleteDeployedQrew(
    $filter: ModelSubscriptionDeployedQrewFilterInput
  ) {
    onDeleteDeployedQrew(filter: $filter) {
      createdAt
      updatedAt
      companyQrewId
      businessProfileId
      companyQrew {
        id
        qrewname
        aboutqrew
        businessProfileId
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        Invoices {
          items {
            id
            CtcInvoiceNumber
            PlatformInvoiceNumber
            CompanyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            businessProfileId
            companyName
            CtcInvoiceS3Key
            PlarformInvoiceS3Key
            CtcTxnNumber
            PlatformTxnNumber
            CtcTxnNumberApproved
            PlatformTxnNumberApproved
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        qrewroles {
          items {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedQrew {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        objectives {
          items {
            id
            createdAt
            updatedAt
            title
            creator
            companyProfileId
            companyUserId
            ObjectivekeyResults {
              nextToken
              __typename
            }
            deployedQrewId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            editHistory {
              nextToken
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        initiatives {
          items {
            id
            createdAt
            updatedAt
            initiative
            initiativeDocumentation
            keyResultsAcheived
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveKeyResultId
            objectiveId
            approvalStatus
            objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            ObjectiveKeyResult {
              id
              createdAt
              updatedAt
              keyResult
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveId
              progressStatus
              progresssPercentage
              owner
              __typename
            }
            InitiativeCreatedBydeployedQrew {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            PercentageContributionOnkeyResult
            TimeRequiredToComplete
            owner
            __typename
          }
          nextToken
          __typename
        }
        durationInmonths
        qrewLive
        createdAt
        updatedAt
        owner
        __typename
      }
      finalCTCQrew
      candidateprofileId
      qrewPosition
      deployedUserProfile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      preferencesId
      JobPostPreferences {
        createdAt
        id
        rolename
        businessUserId
        businessProfileId
        companyQrewId
        companyQrew {
          id
          qrewname
          aboutqrew
          businessProfileId
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Invoices {
            items {
              id
              CtcInvoiceNumber
              PlatformInvoiceNumber
              CompanyQrewId
              businessProfileId
              companyName
              CtcInvoiceS3Key
              PlarformInvoiceS3Key
              CtcTxnNumber
              PlatformTxnNumber
              CtcTxnNumberApproved
              PlatformTxnNumberApproved
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          qrewroles {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          objectives {
            items {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          durationInmonths
          qrewLive
          createdAt
          updatedAt
          owner
          __typename
        }
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        recommendedprofiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        shortlistedProfiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejectedProfiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobPosts {
          items {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedQrew {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobTraits {
          items {
            id
            TraitName
            createdAt
            updatedAt
            preferencesID
            owner
            __typename
          }
          nextToken
          __typename
        }
        package
        certifications
        noticeperiod
        maxExperienceRequired
        minExperienceRequired
        modeofwork
        JobDomain
        other
        previousrole
        combinedrole
        currencyCountry
        JobType
        Location
        JobPublished
        numberOfCandidateRequired
        updatedAt
        owner
        __typename
      }
      deployedQrewHistory {
        items {
          createdAt
          updatedAt
          companyQrewId
          businessProfileId
          finalCTCQrew
          candidateprofileId
          qrewPosition
          preferencesId
          recommededId
          joiningDate
          endingDate
          NDAkey
          Contractkey
          KYCdocumentkey
          WorkingOnit
          EndContract
          ContractEndingDate
          CandidateAcceptanceStatus
          CandidateContractRejectionReason
          ExtensionDurationInMonths
          ExtensionDate
          ExtendingContract
          NewContractCTC
          ContractEndedBy
          ContractPausedBy
          JobApplicationsId
          deployedQrewId
          id
          owner
          __typename
        }
        nextToken
        __typename
      }
      recommededId
      joiningDate
      endingDate
      NDAkey
      Contractkey
      KYCdocumentkey
      WorkingOnit
      EndContract
      ContractEndingDate
      ExtensionDurationInMonths
      ExtensionDate
      ExtendingContract
      CandidateAcceptanceStatus
      CandidateContractRejectionReason
      CandidateContractNegotiatingReason
      NewContractCTC
      ContractEndedBy
      ContractExtendedBy
      ContractPausedBy
      JobApplicationsId
      initiatives {
        items {
          id
          createdAt
          updatedAt
          initiative
          initiativeDocumentation
          keyResultsAcheived
          deployedQrewId
          companyProfileId
          candidateProfileId
          companyQrewId
          objectiveKeyResultId
          objectiveId
          approvalStatus
          objective {
            id
            createdAt
            updatedAt
            title
            creator
            companyProfileId
            companyUserId
            ObjectivekeyResults {
              nextToken
              __typename
            }
            deployedQrewId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            editHistory {
              nextToken
              __typename
            }
            owner
            __typename
          }
          ObjectiveKeyResult {
            id
            createdAt
            updatedAt
            keyResult
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveId
            initiatives {
              nextToken
              __typename
            }
            progressStatus
            progresssPercentage
            owner
            __typename
          }
          InitiativeCreatedBydeployedQrew {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          PercentageContributionOnkeyResult
          TimeRequiredToComplete
          owner
          __typename
        }
        nextToken
        __typename
      }
      editObjectiveHistory {
        items {
          id
          createdAt
          updatedAt
          objectiveId
          Objective {
            id
            createdAt
            updatedAt
            title
            creator
            companyProfileId
            companyUserId
            ObjectivekeyResults {
              nextToken
              __typename
            }
            deployedQrewId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            editHistory {
              nextToken
              __typename
            }
            owner
            __typename
          }
          prevTitle
          editedbyCompanyUserProfileId
          editbyCompanyUserProfile {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          editedbyDeployedQrewId
          editedDeployedQrewProfile {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          owner
          __typename
        }
        nextToken
        __typename
      }
      id
      owner
      __typename
    }
  }
`;
export const onCreateObjective = /* GraphQL */ `
  subscription OnCreateObjective(
    $filter: ModelSubscriptionObjectiveFilterInput
  ) {
    onCreateObjective(filter: $filter) {
      id
      createdAt
      updatedAt
      title
      creator
      companyProfileId
      companyUserId
      ObjectivekeyResults {
        items {
          id
          createdAt
          updatedAt
          keyResult
          deployedQrewId
          companyProfileId
          candidateProfileId
          companyQrewId
          objectiveId
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          progressStatus
          progresssPercentage
          owner
          __typename
        }
        nextToken
        __typename
      }
      deployedQrewId
      companyQrewId
      companyQrew {
        id
        qrewname
        aboutqrew
        businessProfileId
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        Invoices {
          items {
            id
            CtcInvoiceNumber
            PlatformInvoiceNumber
            CompanyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            businessProfileId
            companyName
            CtcInvoiceS3Key
            PlarformInvoiceS3Key
            CtcTxnNumber
            PlatformTxnNumber
            CtcTxnNumberApproved
            PlatformTxnNumberApproved
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        qrewroles {
          items {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedQrew {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        objectives {
          items {
            id
            createdAt
            updatedAt
            title
            creator
            companyProfileId
            companyUserId
            ObjectivekeyResults {
              nextToken
              __typename
            }
            deployedQrewId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            editHistory {
              nextToken
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        initiatives {
          items {
            id
            createdAt
            updatedAt
            initiative
            initiativeDocumentation
            keyResultsAcheived
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveKeyResultId
            objectiveId
            approvalStatus
            objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            ObjectiveKeyResult {
              id
              createdAt
              updatedAt
              keyResult
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveId
              progressStatus
              progresssPercentage
              owner
              __typename
            }
            InitiativeCreatedBydeployedQrew {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            PercentageContributionOnkeyResult
            TimeRequiredToComplete
            owner
            __typename
          }
          nextToken
          __typename
        }
        durationInmonths
        qrewLive
        createdAt
        updatedAt
        owner
        __typename
      }
      initiatives {
        items {
          id
          createdAt
          updatedAt
          initiative
          initiativeDocumentation
          keyResultsAcheived
          deployedQrewId
          companyProfileId
          candidateProfileId
          companyQrewId
          objectiveKeyResultId
          objectiveId
          approvalStatus
          objective {
            id
            createdAt
            updatedAt
            title
            creator
            companyProfileId
            companyUserId
            ObjectivekeyResults {
              nextToken
              __typename
            }
            deployedQrewId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            editHistory {
              nextToken
              __typename
            }
            owner
            __typename
          }
          ObjectiveKeyResult {
            id
            createdAt
            updatedAt
            keyResult
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveId
            initiatives {
              nextToken
              __typename
            }
            progressStatus
            progresssPercentage
            owner
            __typename
          }
          InitiativeCreatedBydeployedQrew {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          PercentageContributionOnkeyResult
          TimeRequiredToComplete
          owner
          __typename
        }
        nextToken
        __typename
      }
      editHistory {
        items {
          id
          createdAt
          updatedAt
          objectiveId
          Objective {
            id
            createdAt
            updatedAt
            title
            creator
            companyProfileId
            companyUserId
            ObjectivekeyResults {
              nextToken
              __typename
            }
            deployedQrewId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            editHistory {
              nextToken
              __typename
            }
            owner
            __typename
          }
          prevTitle
          editedbyCompanyUserProfileId
          editbyCompanyUserProfile {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          editedbyDeployedQrewId
          editedDeployedQrewProfile {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          owner
          __typename
        }
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onUpdateObjective = /* GraphQL */ `
  subscription OnUpdateObjective(
    $filter: ModelSubscriptionObjectiveFilterInput
  ) {
    onUpdateObjective(filter: $filter) {
      id
      createdAt
      updatedAt
      title
      creator
      companyProfileId
      companyUserId
      ObjectivekeyResults {
        items {
          id
          createdAt
          updatedAt
          keyResult
          deployedQrewId
          companyProfileId
          candidateProfileId
          companyQrewId
          objectiveId
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          progressStatus
          progresssPercentage
          owner
          __typename
        }
        nextToken
        __typename
      }
      deployedQrewId
      companyQrewId
      companyQrew {
        id
        qrewname
        aboutqrew
        businessProfileId
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        Invoices {
          items {
            id
            CtcInvoiceNumber
            PlatformInvoiceNumber
            CompanyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            businessProfileId
            companyName
            CtcInvoiceS3Key
            PlarformInvoiceS3Key
            CtcTxnNumber
            PlatformTxnNumber
            CtcTxnNumberApproved
            PlatformTxnNumberApproved
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        qrewroles {
          items {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedQrew {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        objectives {
          items {
            id
            createdAt
            updatedAt
            title
            creator
            companyProfileId
            companyUserId
            ObjectivekeyResults {
              nextToken
              __typename
            }
            deployedQrewId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            editHistory {
              nextToken
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        initiatives {
          items {
            id
            createdAt
            updatedAt
            initiative
            initiativeDocumentation
            keyResultsAcheived
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveKeyResultId
            objectiveId
            approvalStatus
            objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            ObjectiveKeyResult {
              id
              createdAt
              updatedAt
              keyResult
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveId
              progressStatus
              progresssPercentage
              owner
              __typename
            }
            InitiativeCreatedBydeployedQrew {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            PercentageContributionOnkeyResult
            TimeRequiredToComplete
            owner
            __typename
          }
          nextToken
          __typename
        }
        durationInmonths
        qrewLive
        createdAt
        updatedAt
        owner
        __typename
      }
      initiatives {
        items {
          id
          createdAt
          updatedAt
          initiative
          initiativeDocumentation
          keyResultsAcheived
          deployedQrewId
          companyProfileId
          candidateProfileId
          companyQrewId
          objectiveKeyResultId
          objectiveId
          approvalStatus
          objective {
            id
            createdAt
            updatedAt
            title
            creator
            companyProfileId
            companyUserId
            ObjectivekeyResults {
              nextToken
              __typename
            }
            deployedQrewId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            editHistory {
              nextToken
              __typename
            }
            owner
            __typename
          }
          ObjectiveKeyResult {
            id
            createdAt
            updatedAt
            keyResult
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveId
            initiatives {
              nextToken
              __typename
            }
            progressStatus
            progresssPercentage
            owner
            __typename
          }
          InitiativeCreatedBydeployedQrew {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          PercentageContributionOnkeyResult
          TimeRequiredToComplete
          owner
          __typename
        }
        nextToken
        __typename
      }
      editHistory {
        items {
          id
          createdAt
          updatedAt
          objectiveId
          Objective {
            id
            createdAt
            updatedAt
            title
            creator
            companyProfileId
            companyUserId
            ObjectivekeyResults {
              nextToken
              __typename
            }
            deployedQrewId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            editHistory {
              nextToken
              __typename
            }
            owner
            __typename
          }
          prevTitle
          editedbyCompanyUserProfileId
          editbyCompanyUserProfile {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          editedbyDeployedQrewId
          editedDeployedQrewProfile {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          owner
          __typename
        }
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onDeleteObjective = /* GraphQL */ `
  subscription OnDeleteObjective(
    $filter: ModelSubscriptionObjectiveFilterInput
  ) {
    onDeleteObjective(filter: $filter) {
      id
      createdAt
      updatedAt
      title
      creator
      companyProfileId
      companyUserId
      ObjectivekeyResults {
        items {
          id
          createdAt
          updatedAt
          keyResult
          deployedQrewId
          companyProfileId
          candidateProfileId
          companyQrewId
          objectiveId
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          progressStatus
          progresssPercentage
          owner
          __typename
        }
        nextToken
        __typename
      }
      deployedQrewId
      companyQrewId
      companyQrew {
        id
        qrewname
        aboutqrew
        businessProfileId
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        Invoices {
          items {
            id
            CtcInvoiceNumber
            PlatformInvoiceNumber
            CompanyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            businessProfileId
            companyName
            CtcInvoiceS3Key
            PlarformInvoiceS3Key
            CtcTxnNumber
            PlatformTxnNumber
            CtcTxnNumberApproved
            PlatformTxnNumberApproved
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        qrewroles {
          items {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedQrew {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        objectives {
          items {
            id
            createdAt
            updatedAt
            title
            creator
            companyProfileId
            companyUserId
            ObjectivekeyResults {
              nextToken
              __typename
            }
            deployedQrewId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            editHistory {
              nextToken
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        initiatives {
          items {
            id
            createdAt
            updatedAt
            initiative
            initiativeDocumentation
            keyResultsAcheived
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveKeyResultId
            objectiveId
            approvalStatus
            objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            ObjectiveKeyResult {
              id
              createdAt
              updatedAt
              keyResult
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveId
              progressStatus
              progresssPercentage
              owner
              __typename
            }
            InitiativeCreatedBydeployedQrew {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            PercentageContributionOnkeyResult
            TimeRequiredToComplete
            owner
            __typename
          }
          nextToken
          __typename
        }
        durationInmonths
        qrewLive
        createdAt
        updatedAt
        owner
        __typename
      }
      initiatives {
        items {
          id
          createdAt
          updatedAt
          initiative
          initiativeDocumentation
          keyResultsAcheived
          deployedQrewId
          companyProfileId
          candidateProfileId
          companyQrewId
          objectiveKeyResultId
          objectiveId
          approvalStatus
          objective {
            id
            createdAt
            updatedAt
            title
            creator
            companyProfileId
            companyUserId
            ObjectivekeyResults {
              nextToken
              __typename
            }
            deployedQrewId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            editHistory {
              nextToken
              __typename
            }
            owner
            __typename
          }
          ObjectiveKeyResult {
            id
            createdAt
            updatedAt
            keyResult
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveId
            initiatives {
              nextToken
              __typename
            }
            progressStatus
            progresssPercentage
            owner
            __typename
          }
          InitiativeCreatedBydeployedQrew {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          PercentageContributionOnkeyResult
          TimeRequiredToComplete
          owner
          __typename
        }
        nextToken
        __typename
      }
      editHistory {
        items {
          id
          createdAt
          updatedAt
          objectiveId
          Objective {
            id
            createdAt
            updatedAt
            title
            creator
            companyProfileId
            companyUserId
            ObjectivekeyResults {
              nextToken
              __typename
            }
            deployedQrewId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            editHistory {
              nextToken
              __typename
            }
            owner
            __typename
          }
          prevTitle
          editedbyCompanyUserProfileId
          editbyCompanyUserProfile {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          editedbyDeployedQrewId
          editedDeployedQrewProfile {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          owner
          __typename
        }
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onCreateObjectiveKeyResult = /* GraphQL */ `
  subscription OnCreateObjectiveKeyResult(
    $filter: ModelSubscriptionObjectiveKeyResultFilterInput
  ) {
    onCreateObjectiveKeyResult(filter: $filter) {
      id
      createdAt
      updatedAt
      keyResult
      deployedQrewId
      companyProfileId
      candidateProfileId
      companyQrewId
      objectiveId
      initiatives {
        items {
          id
          createdAt
          updatedAt
          initiative
          initiativeDocumentation
          keyResultsAcheived
          deployedQrewId
          companyProfileId
          candidateProfileId
          companyQrewId
          objectiveKeyResultId
          objectiveId
          approvalStatus
          objective {
            id
            createdAt
            updatedAt
            title
            creator
            companyProfileId
            companyUserId
            ObjectivekeyResults {
              nextToken
              __typename
            }
            deployedQrewId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            editHistory {
              nextToken
              __typename
            }
            owner
            __typename
          }
          ObjectiveKeyResult {
            id
            createdAt
            updatedAt
            keyResult
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveId
            initiatives {
              nextToken
              __typename
            }
            progressStatus
            progresssPercentage
            owner
            __typename
          }
          InitiativeCreatedBydeployedQrew {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          PercentageContributionOnkeyResult
          TimeRequiredToComplete
          owner
          __typename
        }
        nextToken
        __typename
      }
      progressStatus
      progresssPercentage
      owner
      __typename
    }
  }
`;
export const onUpdateObjectiveKeyResult = /* GraphQL */ `
  subscription OnUpdateObjectiveKeyResult(
    $filter: ModelSubscriptionObjectiveKeyResultFilterInput
  ) {
    onUpdateObjectiveKeyResult(filter: $filter) {
      id
      createdAt
      updatedAt
      keyResult
      deployedQrewId
      companyProfileId
      candidateProfileId
      companyQrewId
      objectiveId
      initiatives {
        items {
          id
          createdAt
          updatedAt
          initiative
          initiativeDocumentation
          keyResultsAcheived
          deployedQrewId
          companyProfileId
          candidateProfileId
          companyQrewId
          objectiveKeyResultId
          objectiveId
          approvalStatus
          objective {
            id
            createdAt
            updatedAt
            title
            creator
            companyProfileId
            companyUserId
            ObjectivekeyResults {
              nextToken
              __typename
            }
            deployedQrewId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            editHistory {
              nextToken
              __typename
            }
            owner
            __typename
          }
          ObjectiveKeyResult {
            id
            createdAt
            updatedAt
            keyResult
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveId
            initiatives {
              nextToken
              __typename
            }
            progressStatus
            progresssPercentage
            owner
            __typename
          }
          InitiativeCreatedBydeployedQrew {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          PercentageContributionOnkeyResult
          TimeRequiredToComplete
          owner
          __typename
        }
        nextToken
        __typename
      }
      progressStatus
      progresssPercentage
      owner
      __typename
    }
  }
`;
export const onDeleteObjectiveKeyResult = /* GraphQL */ `
  subscription OnDeleteObjectiveKeyResult(
    $filter: ModelSubscriptionObjectiveKeyResultFilterInput
  ) {
    onDeleteObjectiveKeyResult(filter: $filter) {
      id
      createdAt
      updatedAt
      keyResult
      deployedQrewId
      companyProfileId
      candidateProfileId
      companyQrewId
      objectiveId
      initiatives {
        items {
          id
          createdAt
          updatedAt
          initiative
          initiativeDocumentation
          keyResultsAcheived
          deployedQrewId
          companyProfileId
          candidateProfileId
          companyQrewId
          objectiveKeyResultId
          objectiveId
          approvalStatus
          objective {
            id
            createdAt
            updatedAt
            title
            creator
            companyProfileId
            companyUserId
            ObjectivekeyResults {
              nextToken
              __typename
            }
            deployedQrewId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            editHistory {
              nextToken
              __typename
            }
            owner
            __typename
          }
          ObjectiveKeyResult {
            id
            createdAt
            updatedAt
            keyResult
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveId
            initiatives {
              nextToken
              __typename
            }
            progressStatus
            progresssPercentage
            owner
            __typename
          }
          InitiativeCreatedBydeployedQrew {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          PercentageContributionOnkeyResult
          TimeRequiredToComplete
          owner
          __typename
        }
        nextToken
        __typename
      }
      progressStatus
      progresssPercentage
      owner
      __typename
    }
  }
`;
export const onCreateObjectiveKeyResultProgressHistory = /* GraphQL */ `
  subscription OnCreateObjectiveKeyResultProgressHistory(
    $filter: ModelSubscriptionObjectiveKeyResultProgressHistoryFilterInput
  ) {
    onCreateObjectiveKeyResultProgressHistory(filter: $filter) {
      id
      createdAt
      updatedAt
      ObjectiveKeyResultId
      previousPercentageProgress
      ResponsibleInitiativeId
      prevkeyResult
      deployedQrewId
      companyProfileId
      candidateProfileId
      companyQrewId
      objectiveId
      owner
      __typename
    }
  }
`;
export const onUpdateObjectiveKeyResultProgressHistory = /* GraphQL */ `
  subscription OnUpdateObjectiveKeyResultProgressHistory(
    $filter: ModelSubscriptionObjectiveKeyResultProgressHistoryFilterInput
  ) {
    onUpdateObjectiveKeyResultProgressHistory(filter: $filter) {
      id
      createdAt
      updatedAt
      ObjectiveKeyResultId
      previousPercentageProgress
      ResponsibleInitiativeId
      prevkeyResult
      deployedQrewId
      companyProfileId
      candidateProfileId
      companyQrewId
      objectiveId
      owner
      __typename
    }
  }
`;
export const onDeleteObjectiveKeyResultProgressHistory = /* GraphQL */ `
  subscription OnDeleteObjectiveKeyResultProgressHistory(
    $filter: ModelSubscriptionObjectiveKeyResultProgressHistoryFilterInput
  ) {
    onDeleteObjectiveKeyResultProgressHistory(filter: $filter) {
      id
      createdAt
      updatedAt
      ObjectiveKeyResultId
      previousPercentageProgress
      ResponsibleInitiativeId
      prevkeyResult
      deployedQrewId
      companyProfileId
      candidateProfileId
      companyQrewId
      objectiveId
      owner
      __typename
    }
  }
`;
export const onCreateObjectiveEditHistory = /* GraphQL */ `
  subscription OnCreateObjectiveEditHistory(
    $filter: ModelSubscriptionObjectiveEditHistoryFilterInput
  ) {
    onCreateObjectiveEditHistory(filter: $filter) {
      id
      createdAt
      updatedAt
      objectiveId
      Objective {
        id
        createdAt
        updatedAt
        title
        creator
        companyProfileId
        companyUserId
        ObjectivekeyResults {
          items {
            id
            createdAt
            updatedAt
            keyResult
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveId
            initiatives {
              nextToken
              __typename
            }
            progressStatus
            progresssPercentage
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedQrewId
        companyQrewId
        companyQrew {
          id
          qrewname
          aboutqrew
          businessProfileId
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Invoices {
            items {
              id
              CtcInvoiceNumber
              PlatformInvoiceNumber
              CompanyQrewId
              businessProfileId
              companyName
              CtcInvoiceS3Key
              PlarformInvoiceS3Key
              CtcTxnNumber
              PlatformTxnNumber
              CtcTxnNumberApproved
              PlatformTxnNumberApproved
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          qrewroles {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          objectives {
            items {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          durationInmonths
          qrewLive
          createdAt
          updatedAt
          owner
          __typename
        }
        initiatives {
          items {
            id
            createdAt
            updatedAt
            initiative
            initiativeDocumentation
            keyResultsAcheived
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveKeyResultId
            objectiveId
            approvalStatus
            objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            ObjectiveKeyResult {
              id
              createdAt
              updatedAt
              keyResult
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveId
              progressStatus
              progresssPercentage
              owner
              __typename
            }
            InitiativeCreatedBydeployedQrew {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            PercentageContributionOnkeyResult
            TimeRequiredToComplete
            owner
            __typename
          }
          nextToken
          __typename
        }
        editHistory {
          items {
            id
            createdAt
            updatedAt
            objectiveId
            Objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            prevTitle
            editedbyCompanyUserProfileId
            editbyCompanyUserProfile {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            editedbyDeployedQrewId
            editedDeployedQrewProfile {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        owner
        __typename
      }
      prevTitle
      editedbyCompanyUserProfileId
      editbyCompanyUserProfile {
        createdAt
        username
        designation
        ProfilePictureKey
        businessProfileId
        id
        cognitoId
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        Shortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        UserRole
        editObjectiveHistory {
          items {
            id
            createdAt
            updatedAt
            objectiveId
            Objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            prevTitle
            editedbyCompanyUserProfileId
            editbyCompanyUserProfile {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            editedbyDeployedQrewId
            editedDeployedQrewProfile {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        email
        updatedAt
        owner
        __typename
      }
      editedbyDeployedQrewId
      editedDeployedQrewProfile {
        createdAt
        updatedAt
        companyQrewId
        businessProfileId
        companyQrew {
          id
          qrewname
          aboutqrew
          businessProfileId
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Invoices {
            items {
              id
              CtcInvoiceNumber
              PlatformInvoiceNumber
              CompanyQrewId
              businessProfileId
              companyName
              CtcInvoiceS3Key
              PlarformInvoiceS3Key
              CtcTxnNumber
              PlatformTxnNumber
              CtcTxnNumberApproved
              PlatformTxnNumberApproved
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          qrewroles {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          objectives {
            items {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          durationInmonths
          qrewLive
          createdAt
          updatedAt
          owner
          __typename
        }
        finalCTCQrew
        candidateprofileId
        qrewPosition
        deployedUserProfile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        preferencesId
        JobPostPreferences {
          createdAt
          id
          rolename
          businessUserId
          businessProfileId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          recommendedprofiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejectedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobPosts {
            items {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobTraits {
            items {
              id
              TraitName
              createdAt
              updatedAt
              preferencesID
              owner
              __typename
            }
            nextToken
            __typename
          }
          package
          certifications
          noticeperiod
          maxExperienceRequired
          minExperienceRequired
          modeofwork
          JobDomain
          other
          previousrole
          combinedrole
          currencyCountry
          JobType
          Location
          JobPublished
          numberOfCandidateRequired
          updatedAt
          owner
          __typename
        }
        deployedQrewHistory {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            finalCTCQrew
            candidateprofileId
            qrewPosition
            preferencesId
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            NewContractCTC
            ContractEndedBy
            ContractPausedBy
            JobApplicationsId
            deployedQrewId
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        recommededId
        joiningDate
        endingDate
        NDAkey
        Contractkey
        KYCdocumentkey
        WorkingOnit
        EndContract
        ContractEndingDate
        ExtensionDurationInMonths
        ExtensionDate
        ExtendingContract
        CandidateAcceptanceStatus
        CandidateContractRejectionReason
        CandidateContractNegotiatingReason
        NewContractCTC
        ContractEndedBy
        ContractExtendedBy
        ContractPausedBy
        JobApplicationsId
        initiatives {
          items {
            id
            createdAt
            updatedAt
            initiative
            initiativeDocumentation
            keyResultsAcheived
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveKeyResultId
            objectiveId
            approvalStatus
            objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            ObjectiveKeyResult {
              id
              createdAt
              updatedAt
              keyResult
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveId
              progressStatus
              progresssPercentage
              owner
              __typename
            }
            InitiativeCreatedBydeployedQrew {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            PercentageContributionOnkeyResult
            TimeRequiredToComplete
            owner
            __typename
          }
          nextToken
          __typename
        }
        editObjectiveHistory {
          items {
            id
            createdAt
            updatedAt
            objectiveId
            Objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            prevTitle
            editedbyCompanyUserProfileId
            editbyCompanyUserProfile {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            editedbyDeployedQrewId
            editedDeployedQrewProfile {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        id
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onUpdateObjectiveEditHistory = /* GraphQL */ `
  subscription OnUpdateObjectiveEditHistory(
    $filter: ModelSubscriptionObjectiveEditHistoryFilterInput
  ) {
    onUpdateObjectiveEditHistory(filter: $filter) {
      id
      createdAt
      updatedAt
      objectiveId
      Objective {
        id
        createdAt
        updatedAt
        title
        creator
        companyProfileId
        companyUserId
        ObjectivekeyResults {
          items {
            id
            createdAt
            updatedAt
            keyResult
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveId
            initiatives {
              nextToken
              __typename
            }
            progressStatus
            progresssPercentage
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedQrewId
        companyQrewId
        companyQrew {
          id
          qrewname
          aboutqrew
          businessProfileId
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Invoices {
            items {
              id
              CtcInvoiceNumber
              PlatformInvoiceNumber
              CompanyQrewId
              businessProfileId
              companyName
              CtcInvoiceS3Key
              PlarformInvoiceS3Key
              CtcTxnNumber
              PlatformTxnNumber
              CtcTxnNumberApproved
              PlatformTxnNumberApproved
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          qrewroles {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          objectives {
            items {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          durationInmonths
          qrewLive
          createdAt
          updatedAt
          owner
          __typename
        }
        initiatives {
          items {
            id
            createdAt
            updatedAt
            initiative
            initiativeDocumentation
            keyResultsAcheived
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveKeyResultId
            objectiveId
            approvalStatus
            objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            ObjectiveKeyResult {
              id
              createdAt
              updatedAt
              keyResult
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveId
              progressStatus
              progresssPercentage
              owner
              __typename
            }
            InitiativeCreatedBydeployedQrew {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            PercentageContributionOnkeyResult
            TimeRequiredToComplete
            owner
            __typename
          }
          nextToken
          __typename
        }
        editHistory {
          items {
            id
            createdAt
            updatedAt
            objectiveId
            Objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            prevTitle
            editedbyCompanyUserProfileId
            editbyCompanyUserProfile {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            editedbyDeployedQrewId
            editedDeployedQrewProfile {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        owner
        __typename
      }
      prevTitle
      editedbyCompanyUserProfileId
      editbyCompanyUserProfile {
        createdAt
        username
        designation
        ProfilePictureKey
        businessProfileId
        id
        cognitoId
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        Shortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        UserRole
        editObjectiveHistory {
          items {
            id
            createdAt
            updatedAt
            objectiveId
            Objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            prevTitle
            editedbyCompanyUserProfileId
            editbyCompanyUserProfile {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            editedbyDeployedQrewId
            editedDeployedQrewProfile {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        email
        updatedAt
        owner
        __typename
      }
      editedbyDeployedQrewId
      editedDeployedQrewProfile {
        createdAt
        updatedAt
        companyQrewId
        businessProfileId
        companyQrew {
          id
          qrewname
          aboutqrew
          businessProfileId
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Invoices {
            items {
              id
              CtcInvoiceNumber
              PlatformInvoiceNumber
              CompanyQrewId
              businessProfileId
              companyName
              CtcInvoiceS3Key
              PlarformInvoiceS3Key
              CtcTxnNumber
              PlatformTxnNumber
              CtcTxnNumberApproved
              PlatformTxnNumberApproved
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          qrewroles {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          objectives {
            items {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          durationInmonths
          qrewLive
          createdAt
          updatedAt
          owner
          __typename
        }
        finalCTCQrew
        candidateprofileId
        qrewPosition
        deployedUserProfile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        preferencesId
        JobPostPreferences {
          createdAt
          id
          rolename
          businessUserId
          businessProfileId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          recommendedprofiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejectedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobPosts {
            items {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobTraits {
            items {
              id
              TraitName
              createdAt
              updatedAt
              preferencesID
              owner
              __typename
            }
            nextToken
            __typename
          }
          package
          certifications
          noticeperiod
          maxExperienceRequired
          minExperienceRequired
          modeofwork
          JobDomain
          other
          previousrole
          combinedrole
          currencyCountry
          JobType
          Location
          JobPublished
          numberOfCandidateRequired
          updatedAt
          owner
          __typename
        }
        deployedQrewHistory {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            finalCTCQrew
            candidateprofileId
            qrewPosition
            preferencesId
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            NewContractCTC
            ContractEndedBy
            ContractPausedBy
            JobApplicationsId
            deployedQrewId
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        recommededId
        joiningDate
        endingDate
        NDAkey
        Contractkey
        KYCdocumentkey
        WorkingOnit
        EndContract
        ContractEndingDate
        ExtensionDurationInMonths
        ExtensionDate
        ExtendingContract
        CandidateAcceptanceStatus
        CandidateContractRejectionReason
        CandidateContractNegotiatingReason
        NewContractCTC
        ContractEndedBy
        ContractExtendedBy
        ContractPausedBy
        JobApplicationsId
        initiatives {
          items {
            id
            createdAt
            updatedAt
            initiative
            initiativeDocumentation
            keyResultsAcheived
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveKeyResultId
            objectiveId
            approvalStatus
            objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            ObjectiveKeyResult {
              id
              createdAt
              updatedAt
              keyResult
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveId
              progressStatus
              progresssPercentage
              owner
              __typename
            }
            InitiativeCreatedBydeployedQrew {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            PercentageContributionOnkeyResult
            TimeRequiredToComplete
            owner
            __typename
          }
          nextToken
          __typename
        }
        editObjectiveHistory {
          items {
            id
            createdAt
            updatedAt
            objectiveId
            Objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            prevTitle
            editedbyCompanyUserProfileId
            editbyCompanyUserProfile {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            editedbyDeployedQrewId
            editedDeployedQrewProfile {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        id
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onDeleteObjectiveEditHistory = /* GraphQL */ `
  subscription OnDeleteObjectiveEditHistory(
    $filter: ModelSubscriptionObjectiveEditHistoryFilterInput
  ) {
    onDeleteObjectiveEditHistory(filter: $filter) {
      id
      createdAt
      updatedAt
      objectiveId
      Objective {
        id
        createdAt
        updatedAt
        title
        creator
        companyProfileId
        companyUserId
        ObjectivekeyResults {
          items {
            id
            createdAt
            updatedAt
            keyResult
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveId
            initiatives {
              nextToken
              __typename
            }
            progressStatus
            progresssPercentage
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedQrewId
        companyQrewId
        companyQrew {
          id
          qrewname
          aboutqrew
          businessProfileId
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Invoices {
            items {
              id
              CtcInvoiceNumber
              PlatformInvoiceNumber
              CompanyQrewId
              businessProfileId
              companyName
              CtcInvoiceS3Key
              PlarformInvoiceS3Key
              CtcTxnNumber
              PlatformTxnNumber
              CtcTxnNumberApproved
              PlatformTxnNumberApproved
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          qrewroles {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          objectives {
            items {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          durationInmonths
          qrewLive
          createdAt
          updatedAt
          owner
          __typename
        }
        initiatives {
          items {
            id
            createdAt
            updatedAt
            initiative
            initiativeDocumentation
            keyResultsAcheived
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveKeyResultId
            objectiveId
            approvalStatus
            objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            ObjectiveKeyResult {
              id
              createdAt
              updatedAt
              keyResult
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveId
              progressStatus
              progresssPercentage
              owner
              __typename
            }
            InitiativeCreatedBydeployedQrew {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            PercentageContributionOnkeyResult
            TimeRequiredToComplete
            owner
            __typename
          }
          nextToken
          __typename
        }
        editHistory {
          items {
            id
            createdAt
            updatedAt
            objectiveId
            Objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            prevTitle
            editedbyCompanyUserProfileId
            editbyCompanyUserProfile {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            editedbyDeployedQrewId
            editedDeployedQrewProfile {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        owner
        __typename
      }
      prevTitle
      editedbyCompanyUserProfileId
      editbyCompanyUserProfile {
        createdAt
        username
        designation
        ProfilePictureKey
        businessProfileId
        id
        cognitoId
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        Shortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        UserRole
        editObjectiveHistory {
          items {
            id
            createdAt
            updatedAt
            objectiveId
            Objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            prevTitle
            editedbyCompanyUserProfileId
            editbyCompanyUserProfile {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            editedbyDeployedQrewId
            editedDeployedQrewProfile {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        email
        updatedAt
        owner
        __typename
      }
      editedbyDeployedQrewId
      editedDeployedQrewProfile {
        createdAt
        updatedAt
        companyQrewId
        businessProfileId
        companyQrew {
          id
          qrewname
          aboutqrew
          businessProfileId
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Invoices {
            items {
              id
              CtcInvoiceNumber
              PlatformInvoiceNumber
              CompanyQrewId
              businessProfileId
              companyName
              CtcInvoiceS3Key
              PlarformInvoiceS3Key
              CtcTxnNumber
              PlatformTxnNumber
              CtcTxnNumberApproved
              PlatformTxnNumberApproved
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          qrewroles {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          objectives {
            items {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          durationInmonths
          qrewLive
          createdAt
          updatedAt
          owner
          __typename
        }
        finalCTCQrew
        candidateprofileId
        qrewPosition
        deployedUserProfile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        preferencesId
        JobPostPreferences {
          createdAt
          id
          rolename
          businessUserId
          businessProfileId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          recommendedprofiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejectedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobPosts {
            items {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobTraits {
            items {
              id
              TraitName
              createdAt
              updatedAt
              preferencesID
              owner
              __typename
            }
            nextToken
            __typename
          }
          package
          certifications
          noticeperiod
          maxExperienceRequired
          minExperienceRequired
          modeofwork
          JobDomain
          other
          previousrole
          combinedrole
          currencyCountry
          JobType
          Location
          JobPublished
          numberOfCandidateRequired
          updatedAt
          owner
          __typename
        }
        deployedQrewHistory {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            finalCTCQrew
            candidateprofileId
            qrewPosition
            preferencesId
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            NewContractCTC
            ContractEndedBy
            ContractPausedBy
            JobApplicationsId
            deployedQrewId
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        recommededId
        joiningDate
        endingDate
        NDAkey
        Contractkey
        KYCdocumentkey
        WorkingOnit
        EndContract
        ContractEndingDate
        ExtensionDurationInMonths
        ExtensionDate
        ExtendingContract
        CandidateAcceptanceStatus
        CandidateContractRejectionReason
        CandidateContractNegotiatingReason
        NewContractCTC
        ContractEndedBy
        ContractExtendedBy
        ContractPausedBy
        JobApplicationsId
        initiatives {
          items {
            id
            createdAt
            updatedAt
            initiative
            initiativeDocumentation
            keyResultsAcheived
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveKeyResultId
            objectiveId
            approvalStatus
            objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            ObjectiveKeyResult {
              id
              createdAt
              updatedAt
              keyResult
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveId
              progressStatus
              progresssPercentage
              owner
              __typename
            }
            InitiativeCreatedBydeployedQrew {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            PercentageContributionOnkeyResult
            TimeRequiredToComplete
            owner
            __typename
          }
          nextToken
          __typename
        }
        editObjectiveHistory {
          items {
            id
            createdAt
            updatedAt
            objectiveId
            Objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            prevTitle
            editedbyCompanyUserProfileId
            editbyCompanyUserProfile {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            editedbyDeployedQrewId
            editedDeployedQrewProfile {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        id
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onCreateInitiative = /* GraphQL */ `
  subscription OnCreateInitiative(
    $filter: ModelSubscriptionInitiativeFilterInput
  ) {
    onCreateInitiative(filter: $filter) {
      id
      createdAt
      updatedAt
      initiative
      initiativeDocumentation
      keyResultsAcheived
      deployedQrewId
      companyProfileId
      candidateProfileId
      companyQrewId
      objectiveKeyResultId
      objectiveId
      approvalStatus
      objective {
        id
        createdAt
        updatedAt
        title
        creator
        companyProfileId
        companyUserId
        ObjectivekeyResults {
          items {
            id
            createdAt
            updatedAt
            keyResult
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveId
            initiatives {
              nextToken
              __typename
            }
            progressStatus
            progresssPercentage
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedQrewId
        companyQrewId
        companyQrew {
          id
          qrewname
          aboutqrew
          businessProfileId
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Invoices {
            items {
              id
              CtcInvoiceNumber
              PlatformInvoiceNumber
              CompanyQrewId
              businessProfileId
              companyName
              CtcInvoiceS3Key
              PlarformInvoiceS3Key
              CtcTxnNumber
              PlatformTxnNumber
              CtcTxnNumberApproved
              PlatformTxnNumberApproved
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          qrewroles {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          objectives {
            items {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          durationInmonths
          qrewLive
          createdAt
          updatedAt
          owner
          __typename
        }
        initiatives {
          items {
            id
            createdAt
            updatedAt
            initiative
            initiativeDocumentation
            keyResultsAcheived
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveKeyResultId
            objectiveId
            approvalStatus
            objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            ObjectiveKeyResult {
              id
              createdAt
              updatedAt
              keyResult
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveId
              progressStatus
              progresssPercentage
              owner
              __typename
            }
            InitiativeCreatedBydeployedQrew {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            PercentageContributionOnkeyResult
            TimeRequiredToComplete
            owner
            __typename
          }
          nextToken
          __typename
        }
        editHistory {
          items {
            id
            createdAt
            updatedAt
            objectiveId
            Objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            prevTitle
            editedbyCompanyUserProfileId
            editbyCompanyUserProfile {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            editedbyDeployedQrewId
            editedDeployedQrewProfile {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        owner
        __typename
      }
      ObjectiveKeyResult {
        id
        createdAt
        updatedAt
        keyResult
        deployedQrewId
        companyProfileId
        candidateProfileId
        companyQrewId
        objectiveId
        initiatives {
          items {
            id
            createdAt
            updatedAt
            initiative
            initiativeDocumentation
            keyResultsAcheived
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveKeyResultId
            objectiveId
            approvalStatus
            objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            ObjectiveKeyResult {
              id
              createdAt
              updatedAt
              keyResult
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveId
              progressStatus
              progresssPercentage
              owner
              __typename
            }
            InitiativeCreatedBydeployedQrew {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            PercentageContributionOnkeyResult
            TimeRequiredToComplete
            owner
            __typename
          }
          nextToken
          __typename
        }
        progressStatus
        progresssPercentage
        owner
        __typename
      }
      InitiativeCreatedBydeployedQrew {
        createdAt
        updatedAt
        companyQrewId
        businessProfileId
        companyQrew {
          id
          qrewname
          aboutqrew
          businessProfileId
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Invoices {
            items {
              id
              CtcInvoiceNumber
              PlatformInvoiceNumber
              CompanyQrewId
              businessProfileId
              companyName
              CtcInvoiceS3Key
              PlarformInvoiceS3Key
              CtcTxnNumber
              PlatformTxnNumber
              CtcTxnNumberApproved
              PlatformTxnNumberApproved
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          qrewroles {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          objectives {
            items {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          durationInmonths
          qrewLive
          createdAt
          updatedAt
          owner
          __typename
        }
        finalCTCQrew
        candidateprofileId
        qrewPosition
        deployedUserProfile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        preferencesId
        JobPostPreferences {
          createdAt
          id
          rolename
          businessUserId
          businessProfileId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          recommendedprofiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejectedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobPosts {
            items {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobTraits {
            items {
              id
              TraitName
              createdAt
              updatedAt
              preferencesID
              owner
              __typename
            }
            nextToken
            __typename
          }
          package
          certifications
          noticeperiod
          maxExperienceRequired
          minExperienceRequired
          modeofwork
          JobDomain
          other
          previousrole
          combinedrole
          currencyCountry
          JobType
          Location
          JobPublished
          numberOfCandidateRequired
          updatedAt
          owner
          __typename
        }
        deployedQrewHistory {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            finalCTCQrew
            candidateprofileId
            qrewPosition
            preferencesId
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            NewContractCTC
            ContractEndedBy
            ContractPausedBy
            JobApplicationsId
            deployedQrewId
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        recommededId
        joiningDate
        endingDate
        NDAkey
        Contractkey
        KYCdocumentkey
        WorkingOnit
        EndContract
        ContractEndingDate
        ExtensionDurationInMonths
        ExtensionDate
        ExtendingContract
        CandidateAcceptanceStatus
        CandidateContractRejectionReason
        CandidateContractNegotiatingReason
        NewContractCTC
        ContractEndedBy
        ContractExtendedBy
        ContractPausedBy
        JobApplicationsId
        initiatives {
          items {
            id
            createdAt
            updatedAt
            initiative
            initiativeDocumentation
            keyResultsAcheived
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveKeyResultId
            objectiveId
            approvalStatus
            objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            ObjectiveKeyResult {
              id
              createdAt
              updatedAt
              keyResult
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveId
              progressStatus
              progresssPercentage
              owner
              __typename
            }
            InitiativeCreatedBydeployedQrew {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            PercentageContributionOnkeyResult
            TimeRequiredToComplete
            owner
            __typename
          }
          nextToken
          __typename
        }
        editObjectiveHistory {
          items {
            id
            createdAt
            updatedAt
            objectiveId
            Objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            prevTitle
            editedbyCompanyUserProfileId
            editbyCompanyUserProfile {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            editedbyDeployedQrewId
            editedDeployedQrewProfile {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        id
        owner
        __typename
      }
      PercentageContributionOnkeyResult
      TimeRequiredToComplete
      owner
      __typename
    }
  }
`;
export const onUpdateInitiative = /* GraphQL */ `
  subscription OnUpdateInitiative(
    $filter: ModelSubscriptionInitiativeFilterInput
  ) {
    onUpdateInitiative(filter: $filter) {
      id
      createdAt
      updatedAt
      initiative
      initiativeDocumentation
      keyResultsAcheived
      deployedQrewId
      companyProfileId
      candidateProfileId
      companyQrewId
      objectiveKeyResultId
      objectiveId
      approvalStatus
      objective {
        id
        createdAt
        updatedAt
        title
        creator
        companyProfileId
        companyUserId
        ObjectivekeyResults {
          items {
            id
            createdAt
            updatedAt
            keyResult
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveId
            initiatives {
              nextToken
              __typename
            }
            progressStatus
            progresssPercentage
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedQrewId
        companyQrewId
        companyQrew {
          id
          qrewname
          aboutqrew
          businessProfileId
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Invoices {
            items {
              id
              CtcInvoiceNumber
              PlatformInvoiceNumber
              CompanyQrewId
              businessProfileId
              companyName
              CtcInvoiceS3Key
              PlarformInvoiceS3Key
              CtcTxnNumber
              PlatformTxnNumber
              CtcTxnNumberApproved
              PlatformTxnNumberApproved
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          qrewroles {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          objectives {
            items {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          durationInmonths
          qrewLive
          createdAt
          updatedAt
          owner
          __typename
        }
        initiatives {
          items {
            id
            createdAt
            updatedAt
            initiative
            initiativeDocumentation
            keyResultsAcheived
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveKeyResultId
            objectiveId
            approvalStatus
            objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            ObjectiveKeyResult {
              id
              createdAt
              updatedAt
              keyResult
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveId
              progressStatus
              progresssPercentage
              owner
              __typename
            }
            InitiativeCreatedBydeployedQrew {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            PercentageContributionOnkeyResult
            TimeRequiredToComplete
            owner
            __typename
          }
          nextToken
          __typename
        }
        editHistory {
          items {
            id
            createdAt
            updatedAt
            objectiveId
            Objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            prevTitle
            editedbyCompanyUserProfileId
            editbyCompanyUserProfile {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            editedbyDeployedQrewId
            editedDeployedQrewProfile {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        owner
        __typename
      }
      ObjectiveKeyResult {
        id
        createdAt
        updatedAt
        keyResult
        deployedQrewId
        companyProfileId
        candidateProfileId
        companyQrewId
        objectiveId
        initiatives {
          items {
            id
            createdAt
            updatedAt
            initiative
            initiativeDocumentation
            keyResultsAcheived
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveKeyResultId
            objectiveId
            approvalStatus
            objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            ObjectiveKeyResult {
              id
              createdAt
              updatedAt
              keyResult
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveId
              progressStatus
              progresssPercentage
              owner
              __typename
            }
            InitiativeCreatedBydeployedQrew {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            PercentageContributionOnkeyResult
            TimeRequiredToComplete
            owner
            __typename
          }
          nextToken
          __typename
        }
        progressStatus
        progresssPercentage
        owner
        __typename
      }
      InitiativeCreatedBydeployedQrew {
        createdAt
        updatedAt
        companyQrewId
        businessProfileId
        companyQrew {
          id
          qrewname
          aboutqrew
          businessProfileId
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Invoices {
            items {
              id
              CtcInvoiceNumber
              PlatformInvoiceNumber
              CompanyQrewId
              businessProfileId
              companyName
              CtcInvoiceS3Key
              PlarformInvoiceS3Key
              CtcTxnNumber
              PlatformTxnNumber
              CtcTxnNumberApproved
              PlatformTxnNumberApproved
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          qrewroles {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          objectives {
            items {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          durationInmonths
          qrewLive
          createdAt
          updatedAt
          owner
          __typename
        }
        finalCTCQrew
        candidateprofileId
        qrewPosition
        deployedUserProfile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        preferencesId
        JobPostPreferences {
          createdAt
          id
          rolename
          businessUserId
          businessProfileId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          recommendedprofiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejectedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobPosts {
            items {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobTraits {
            items {
              id
              TraitName
              createdAt
              updatedAt
              preferencesID
              owner
              __typename
            }
            nextToken
            __typename
          }
          package
          certifications
          noticeperiod
          maxExperienceRequired
          minExperienceRequired
          modeofwork
          JobDomain
          other
          previousrole
          combinedrole
          currencyCountry
          JobType
          Location
          JobPublished
          numberOfCandidateRequired
          updatedAt
          owner
          __typename
        }
        deployedQrewHistory {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            finalCTCQrew
            candidateprofileId
            qrewPosition
            preferencesId
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            NewContractCTC
            ContractEndedBy
            ContractPausedBy
            JobApplicationsId
            deployedQrewId
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        recommededId
        joiningDate
        endingDate
        NDAkey
        Contractkey
        KYCdocumentkey
        WorkingOnit
        EndContract
        ContractEndingDate
        ExtensionDurationInMonths
        ExtensionDate
        ExtendingContract
        CandidateAcceptanceStatus
        CandidateContractRejectionReason
        CandidateContractNegotiatingReason
        NewContractCTC
        ContractEndedBy
        ContractExtendedBy
        ContractPausedBy
        JobApplicationsId
        initiatives {
          items {
            id
            createdAt
            updatedAt
            initiative
            initiativeDocumentation
            keyResultsAcheived
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveKeyResultId
            objectiveId
            approvalStatus
            objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            ObjectiveKeyResult {
              id
              createdAt
              updatedAt
              keyResult
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveId
              progressStatus
              progresssPercentage
              owner
              __typename
            }
            InitiativeCreatedBydeployedQrew {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            PercentageContributionOnkeyResult
            TimeRequiredToComplete
            owner
            __typename
          }
          nextToken
          __typename
        }
        editObjectiveHistory {
          items {
            id
            createdAt
            updatedAt
            objectiveId
            Objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            prevTitle
            editedbyCompanyUserProfileId
            editbyCompanyUserProfile {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            editedbyDeployedQrewId
            editedDeployedQrewProfile {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        id
        owner
        __typename
      }
      PercentageContributionOnkeyResult
      TimeRequiredToComplete
      owner
      __typename
    }
  }
`;
export const onDeleteInitiative = /* GraphQL */ `
  subscription OnDeleteInitiative(
    $filter: ModelSubscriptionInitiativeFilterInput
  ) {
    onDeleteInitiative(filter: $filter) {
      id
      createdAt
      updatedAt
      initiative
      initiativeDocumentation
      keyResultsAcheived
      deployedQrewId
      companyProfileId
      candidateProfileId
      companyQrewId
      objectiveKeyResultId
      objectiveId
      approvalStatus
      objective {
        id
        createdAt
        updatedAt
        title
        creator
        companyProfileId
        companyUserId
        ObjectivekeyResults {
          items {
            id
            createdAt
            updatedAt
            keyResult
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveId
            initiatives {
              nextToken
              __typename
            }
            progressStatus
            progresssPercentage
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedQrewId
        companyQrewId
        companyQrew {
          id
          qrewname
          aboutqrew
          businessProfileId
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Invoices {
            items {
              id
              CtcInvoiceNumber
              PlatformInvoiceNumber
              CompanyQrewId
              businessProfileId
              companyName
              CtcInvoiceS3Key
              PlarformInvoiceS3Key
              CtcTxnNumber
              PlatformTxnNumber
              CtcTxnNumberApproved
              PlatformTxnNumberApproved
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          qrewroles {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          objectives {
            items {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          durationInmonths
          qrewLive
          createdAt
          updatedAt
          owner
          __typename
        }
        initiatives {
          items {
            id
            createdAt
            updatedAt
            initiative
            initiativeDocumentation
            keyResultsAcheived
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveKeyResultId
            objectiveId
            approvalStatus
            objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            ObjectiveKeyResult {
              id
              createdAt
              updatedAt
              keyResult
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveId
              progressStatus
              progresssPercentage
              owner
              __typename
            }
            InitiativeCreatedBydeployedQrew {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            PercentageContributionOnkeyResult
            TimeRequiredToComplete
            owner
            __typename
          }
          nextToken
          __typename
        }
        editHistory {
          items {
            id
            createdAt
            updatedAt
            objectiveId
            Objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            prevTitle
            editedbyCompanyUserProfileId
            editbyCompanyUserProfile {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            editedbyDeployedQrewId
            editedDeployedQrewProfile {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        owner
        __typename
      }
      ObjectiveKeyResult {
        id
        createdAt
        updatedAt
        keyResult
        deployedQrewId
        companyProfileId
        candidateProfileId
        companyQrewId
        objectiveId
        initiatives {
          items {
            id
            createdAt
            updatedAt
            initiative
            initiativeDocumentation
            keyResultsAcheived
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveKeyResultId
            objectiveId
            approvalStatus
            objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            ObjectiveKeyResult {
              id
              createdAt
              updatedAt
              keyResult
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveId
              progressStatus
              progresssPercentage
              owner
              __typename
            }
            InitiativeCreatedBydeployedQrew {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            PercentageContributionOnkeyResult
            TimeRequiredToComplete
            owner
            __typename
          }
          nextToken
          __typename
        }
        progressStatus
        progresssPercentage
        owner
        __typename
      }
      InitiativeCreatedBydeployedQrew {
        createdAt
        updatedAt
        companyQrewId
        businessProfileId
        companyQrew {
          id
          qrewname
          aboutqrew
          businessProfileId
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Invoices {
            items {
              id
              CtcInvoiceNumber
              PlatformInvoiceNumber
              CompanyQrewId
              businessProfileId
              companyName
              CtcInvoiceS3Key
              PlarformInvoiceS3Key
              CtcTxnNumber
              PlatformTxnNumber
              CtcTxnNumberApproved
              PlatformTxnNumberApproved
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          qrewroles {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          objectives {
            items {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          durationInmonths
          qrewLive
          createdAt
          updatedAt
          owner
          __typename
        }
        finalCTCQrew
        candidateprofileId
        qrewPosition
        deployedUserProfile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        preferencesId
        JobPostPreferences {
          createdAt
          id
          rolename
          businessUserId
          businessProfileId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          recommendedprofiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejectedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobPosts {
            items {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobTraits {
            items {
              id
              TraitName
              createdAt
              updatedAt
              preferencesID
              owner
              __typename
            }
            nextToken
            __typename
          }
          package
          certifications
          noticeperiod
          maxExperienceRequired
          minExperienceRequired
          modeofwork
          JobDomain
          other
          previousrole
          combinedrole
          currencyCountry
          JobType
          Location
          JobPublished
          numberOfCandidateRequired
          updatedAt
          owner
          __typename
        }
        deployedQrewHistory {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            finalCTCQrew
            candidateprofileId
            qrewPosition
            preferencesId
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            NewContractCTC
            ContractEndedBy
            ContractPausedBy
            JobApplicationsId
            deployedQrewId
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        recommededId
        joiningDate
        endingDate
        NDAkey
        Contractkey
        KYCdocumentkey
        WorkingOnit
        EndContract
        ContractEndingDate
        ExtensionDurationInMonths
        ExtensionDate
        ExtendingContract
        CandidateAcceptanceStatus
        CandidateContractRejectionReason
        CandidateContractNegotiatingReason
        NewContractCTC
        ContractEndedBy
        ContractExtendedBy
        ContractPausedBy
        JobApplicationsId
        initiatives {
          items {
            id
            createdAt
            updatedAt
            initiative
            initiativeDocumentation
            keyResultsAcheived
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveKeyResultId
            objectiveId
            approvalStatus
            objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            ObjectiveKeyResult {
              id
              createdAt
              updatedAt
              keyResult
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveId
              progressStatus
              progresssPercentage
              owner
              __typename
            }
            InitiativeCreatedBydeployedQrew {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            PercentageContributionOnkeyResult
            TimeRequiredToComplete
            owner
            __typename
          }
          nextToken
          __typename
        }
        editObjectiveHistory {
          items {
            id
            createdAt
            updatedAt
            objectiveId
            Objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            prevTitle
            editedbyCompanyUserProfileId
            editbyCompanyUserProfile {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            editedbyDeployedQrewId
            editedDeployedQrewProfile {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        id
        owner
        __typename
      }
      PercentageContributionOnkeyResult
      TimeRequiredToComplete
      owner
      __typename
    }
  }
`;
export const onCreateTalent = /* GraphQL */ `
  subscription OnCreateTalent($filter: ModelSubscriptionTalentFilterInput) {
    onCreateTalent(filter: $filter) {
      createdAt
      hidiId
      UserId
      Spotter
      description
      id
      Talent
      Level
      profile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            Talents {
              nextToken
              __typename
            }
            signals {
              nextToken
              __typename
            }
            MobileNumber
            name
            UserId
            PSStitle
            PSSdescription
            DMStitle
            DMSdescription
            CTtitle
            CTdescription
            Atitle
            Adescription
            Verified
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Talents {
          items {
            createdAt
            hidiId
            UserId
            Spotter
            description
            id
            Talent
            Level
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            hidi {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        signals {
          items {
            createdAt
            hidiId
            UserId
            Spotter
            description
            id
            signalname
            score
            signalcategory
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            hidi {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        resume
        education
        experience
        expertise
        graduation
        iframescorelink
        iframehidilink
        updatedAt
        owner
        __typename
      }
      hidi {
        createdAt
        Email
        id
        profile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Talents {
            items {
              createdAt
              hidiId
              UserId
              Spotter
              description
              id
              Talent
              Level
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          signals {
            items {
              createdAt
              hidiId
              UserId
              Spotter
              description
              id
              signalname
              score
              signalcategory
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          resume
          education
          experience
          expertise
          graduation
          iframescorelink
          iframehidilink
          updatedAt
          owner
          __typename
        }
        Talents {
          items {
            createdAt
            hidiId
            UserId
            Spotter
            description
            id
            Talent
            Level
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            hidi {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        signals {
          items {
            createdAt
            hidiId
            UserId
            Spotter
            description
            id
            signalname
            score
            signalcategory
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            hidi {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        MobileNumber
        name
        UserId
        PSStitle
        PSSdescription
        DMStitle
        DMSdescription
        CTtitle
        CTdescription
        Atitle
        Adescription
        Verified
        updatedAt
        owner
        __typename
      }
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateTalent = /* GraphQL */ `
  subscription OnUpdateTalent($filter: ModelSubscriptionTalentFilterInput) {
    onUpdateTalent(filter: $filter) {
      createdAt
      hidiId
      UserId
      Spotter
      description
      id
      Talent
      Level
      profile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            Talents {
              nextToken
              __typename
            }
            signals {
              nextToken
              __typename
            }
            MobileNumber
            name
            UserId
            PSStitle
            PSSdescription
            DMStitle
            DMSdescription
            CTtitle
            CTdescription
            Atitle
            Adescription
            Verified
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Talents {
          items {
            createdAt
            hidiId
            UserId
            Spotter
            description
            id
            Talent
            Level
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            hidi {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        signals {
          items {
            createdAt
            hidiId
            UserId
            Spotter
            description
            id
            signalname
            score
            signalcategory
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            hidi {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        resume
        education
        experience
        expertise
        graduation
        iframescorelink
        iframehidilink
        updatedAt
        owner
        __typename
      }
      hidi {
        createdAt
        Email
        id
        profile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Talents {
            items {
              createdAt
              hidiId
              UserId
              Spotter
              description
              id
              Talent
              Level
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          signals {
            items {
              createdAt
              hidiId
              UserId
              Spotter
              description
              id
              signalname
              score
              signalcategory
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          resume
          education
          experience
          expertise
          graduation
          iframescorelink
          iframehidilink
          updatedAt
          owner
          __typename
        }
        Talents {
          items {
            createdAt
            hidiId
            UserId
            Spotter
            description
            id
            Talent
            Level
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            hidi {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        signals {
          items {
            createdAt
            hidiId
            UserId
            Spotter
            description
            id
            signalname
            score
            signalcategory
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            hidi {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        MobileNumber
        name
        UserId
        PSStitle
        PSSdescription
        DMStitle
        DMSdescription
        CTtitle
        CTdescription
        Atitle
        Adescription
        Verified
        updatedAt
        owner
        __typename
      }
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteTalent = /* GraphQL */ `
  subscription OnDeleteTalent($filter: ModelSubscriptionTalentFilterInput) {
    onDeleteTalent(filter: $filter) {
      createdAt
      hidiId
      UserId
      Spotter
      description
      id
      Talent
      Level
      profile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            Talents {
              nextToken
              __typename
            }
            signals {
              nextToken
              __typename
            }
            MobileNumber
            name
            UserId
            PSStitle
            PSSdescription
            DMStitle
            DMSdescription
            CTtitle
            CTdescription
            Atitle
            Adescription
            Verified
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Talents {
          items {
            createdAt
            hidiId
            UserId
            Spotter
            description
            id
            Talent
            Level
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            hidi {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        signals {
          items {
            createdAt
            hidiId
            UserId
            Spotter
            description
            id
            signalname
            score
            signalcategory
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            hidi {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        resume
        education
        experience
        expertise
        graduation
        iframescorelink
        iframehidilink
        updatedAt
        owner
        __typename
      }
      hidi {
        createdAt
        Email
        id
        profile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Talents {
            items {
              createdAt
              hidiId
              UserId
              Spotter
              description
              id
              Talent
              Level
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          signals {
            items {
              createdAt
              hidiId
              UserId
              Spotter
              description
              id
              signalname
              score
              signalcategory
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          resume
          education
          experience
          expertise
          graduation
          iframescorelink
          iframehidilink
          updatedAt
          owner
          __typename
        }
        Talents {
          items {
            createdAt
            hidiId
            UserId
            Spotter
            description
            id
            Talent
            Level
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            hidi {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        signals {
          items {
            createdAt
            hidiId
            UserId
            Spotter
            description
            id
            signalname
            score
            signalcategory
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            hidi {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        MobileNumber
        name
        UserId
        PSStitle
        PSSdescription
        DMStitle
        DMSdescription
        CTtitle
        CTdescription
        Atitle
        Adescription
        Verified
        updatedAt
        owner
        __typename
      }
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateSkill = /* GraphQL */ `
  subscription OnCreateSkill($filter: ModelSubscriptionSkillFilterInput) {
    onCreateSkill(filter: $filter) {
      createdAt
      profileId
      UserId
      description
      YearsOfExperience
      id
      Skill
      profile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      JoinedCapabilities {
        items {
          id
          UserId
          SkillId
          CapabilityId
          Skill {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Capabilities {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          GlobalCapabilityConfigId
          GlobalCapabilityConfigName
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateSkill = /* GraphQL */ `
  subscription OnUpdateSkill($filter: ModelSubscriptionSkillFilterInput) {
    onUpdateSkill(filter: $filter) {
      createdAt
      profileId
      UserId
      description
      YearsOfExperience
      id
      Skill
      profile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      JoinedCapabilities {
        items {
          id
          UserId
          SkillId
          CapabilityId
          Skill {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Capabilities {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          GlobalCapabilityConfigId
          GlobalCapabilityConfigName
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteSkill = /* GraphQL */ `
  subscription OnDeleteSkill($filter: ModelSubscriptionSkillFilterInput) {
    onDeleteSkill(filter: $filter) {
      createdAt
      profileId
      UserId
      description
      YearsOfExperience
      id
      Skill
      profile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      JoinedCapabilities {
        items {
          id
          UserId
          SkillId
          CapabilityId
          Skill {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Capabilities {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          GlobalCapabilityConfigId
          GlobalCapabilityConfigName
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateJoinSkillandCapability = /* GraphQL */ `
  subscription OnCreateJoinSkillandCapability(
    $filter: ModelSubscriptionJoinSkillandCapabilityFilterInput
  ) {
    onCreateJoinSkillandCapability(filter: $filter) {
      id
      UserId
      SkillId
      CapabilityId
      Skill {
        createdAt
        profileId
        UserId
        description
        YearsOfExperience
        id
        Skill
        profile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        JoinedCapabilities {
          items {
            id
            UserId
            SkillId
            CapabilityId
            Skill {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            Capabilities {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            GlobalCapabilityConfigId
            GlobalCapabilityConfigName
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      Capabilities {
        createdAt
        ProfileId
        id
        PointsEarned {
          items {
            id
            Project
            Hack
            ProjectLink
            Video
            Photo
            Presentation
            experience
            Claim
            Outcomes
            PeersVouch
            GlobalCapabilityConfigId
            Helped
            Problem
            Challenges
            CapabilityPoints
            UserId
            capabilityPointsCapabilityClaimedId
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        userProfile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        ConnectedHidis {
          items {
            CapabilityId
            HidiId
            Capability {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            createdAt
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        capabilitiesCapabilityConfigId
        CapabilityConfig {
          id
          name
          tag
          domain
          createdAt
          weeklyActiveOpportunity
          capabilitiesClaimedCount
          CapabilityPoints
          capabilitiesClaimed {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          feeds {
            items {
              id
              UserSegmentId
              HidiId
              CapabilityConfigId
              jobPostId
              tags
              domain
              Description
              Payload
              FeedType
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          connectedJobPosts {
            items {
              id
              jobPostID
              GlobalCapabilityConfigID
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivityStatus
          updatedAt
          owner
          __typename
        }
        JoinedSkills {
          items {
            id
            UserId
            SkillId
            CapabilityId
            Skill {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            Capabilities {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            GlobalCapabilityConfigId
            GlobalCapabilityConfigName
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      GlobalCapabilityConfigId
      GlobalCapabilityConfigName
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateJoinSkillandCapability = /* GraphQL */ `
  subscription OnUpdateJoinSkillandCapability(
    $filter: ModelSubscriptionJoinSkillandCapabilityFilterInput
  ) {
    onUpdateJoinSkillandCapability(filter: $filter) {
      id
      UserId
      SkillId
      CapabilityId
      Skill {
        createdAt
        profileId
        UserId
        description
        YearsOfExperience
        id
        Skill
        profile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        JoinedCapabilities {
          items {
            id
            UserId
            SkillId
            CapabilityId
            Skill {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            Capabilities {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            GlobalCapabilityConfigId
            GlobalCapabilityConfigName
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      Capabilities {
        createdAt
        ProfileId
        id
        PointsEarned {
          items {
            id
            Project
            Hack
            ProjectLink
            Video
            Photo
            Presentation
            experience
            Claim
            Outcomes
            PeersVouch
            GlobalCapabilityConfigId
            Helped
            Problem
            Challenges
            CapabilityPoints
            UserId
            capabilityPointsCapabilityClaimedId
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        userProfile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        ConnectedHidis {
          items {
            CapabilityId
            HidiId
            Capability {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            createdAt
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        capabilitiesCapabilityConfigId
        CapabilityConfig {
          id
          name
          tag
          domain
          createdAt
          weeklyActiveOpportunity
          capabilitiesClaimedCount
          CapabilityPoints
          capabilitiesClaimed {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          feeds {
            items {
              id
              UserSegmentId
              HidiId
              CapabilityConfigId
              jobPostId
              tags
              domain
              Description
              Payload
              FeedType
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          connectedJobPosts {
            items {
              id
              jobPostID
              GlobalCapabilityConfigID
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivityStatus
          updatedAt
          owner
          __typename
        }
        JoinedSkills {
          items {
            id
            UserId
            SkillId
            CapabilityId
            Skill {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            Capabilities {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            GlobalCapabilityConfigId
            GlobalCapabilityConfigName
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      GlobalCapabilityConfigId
      GlobalCapabilityConfigName
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteJoinSkillandCapability = /* GraphQL */ `
  subscription OnDeleteJoinSkillandCapability(
    $filter: ModelSubscriptionJoinSkillandCapabilityFilterInput
  ) {
    onDeleteJoinSkillandCapability(filter: $filter) {
      id
      UserId
      SkillId
      CapabilityId
      Skill {
        createdAt
        profileId
        UserId
        description
        YearsOfExperience
        id
        Skill
        profile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        JoinedCapabilities {
          items {
            id
            UserId
            SkillId
            CapabilityId
            Skill {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            Capabilities {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            GlobalCapabilityConfigId
            GlobalCapabilityConfigName
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      Capabilities {
        createdAt
        ProfileId
        id
        PointsEarned {
          items {
            id
            Project
            Hack
            ProjectLink
            Video
            Photo
            Presentation
            experience
            Claim
            Outcomes
            PeersVouch
            GlobalCapabilityConfigId
            Helped
            Problem
            Challenges
            CapabilityPoints
            UserId
            capabilityPointsCapabilityClaimedId
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        userProfile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        ConnectedHidis {
          items {
            CapabilityId
            HidiId
            Capability {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            createdAt
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        capabilitiesCapabilityConfigId
        CapabilityConfig {
          id
          name
          tag
          domain
          createdAt
          weeklyActiveOpportunity
          capabilitiesClaimedCount
          CapabilityPoints
          capabilitiesClaimed {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          feeds {
            items {
              id
              UserSegmentId
              HidiId
              CapabilityConfigId
              jobPostId
              tags
              domain
              Description
              Payload
              FeedType
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          connectedJobPosts {
            items {
              id
              jobPostID
              GlobalCapabilityConfigID
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivityStatus
          updatedAt
          owner
          __typename
        }
        JoinedSkills {
          items {
            id
            UserId
            SkillId
            CapabilityId
            Skill {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            Capabilities {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            GlobalCapabilityConfigId
            GlobalCapabilityConfigName
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      GlobalCapabilityConfigId
      GlobalCapabilityConfigName
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateCapabilities = /* GraphQL */ `
  subscription OnCreateCapabilities(
    $filter: ModelSubscriptionCapabilitiesFilterInput
  ) {
    onCreateCapabilities(filter: $filter) {
      createdAt
      ProfileId
      id
      PointsEarned {
        items {
          id
          Project
          Hack
          ProjectLink
          Video
          Photo
          Presentation
          experience
          Claim
          Outcomes
          PeersVouch
          GlobalCapabilityConfigId
          Helped
          Problem
          Challenges
          CapabilityPoints
          UserId
          capabilityPointsCapabilityClaimedId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      userProfile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      ConnectedHidis {
        items {
          CapabilityId
          HidiId
          Capability {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Hidi {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          createdAt
          id
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      capabilitiesCapabilityConfigId
      CapabilityConfig {
        id
        name
        tag
        domain
        createdAt
        weeklyActiveOpportunity
        capabilitiesClaimedCount
        CapabilityPoints
        capabilitiesClaimed {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        feeds {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        connectedJobPosts {
          items {
            id
            jobPostID
            GlobalCapabilityConfigID
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivityStatus
        updatedAt
        owner
        __typename
      }
      JoinedSkills {
        items {
          id
          UserId
          SkillId
          CapabilityId
          Skill {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Capabilities {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          GlobalCapabilityConfigId
          GlobalCapabilityConfigName
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateCapabilities = /* GraphQL */ `
  subscription OnUpdateCapabilities(
    $filter: ModelSubscriptionCapabilitiesFilterInput
  ) {
    onUpdateCapabilities(filter: $filter) {
      createdAt
      ProfileId
      id
      PointsEarned {
        items {
          id
          Project
          Hack
          ProjectLink
          Video
          Photo
          Presentation
          experience
          Claim
          Outcomes
          PeersVouch
          GlobalCapabilityConfigId
          Helped
          Problem
          Challenges
          CapabilityPoints
          UserId
          capabilityPointsCapabilityClaimedId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      userProfile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      ConnectedHidis {
        items {
          CapabilityId
          HidiId
          Capability {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Hidi {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          createdAt
          id
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      capabilitiesCapabilityConfigId
      CapabilityConfig {
        id
        name
        tag
        domain
        createdAt
        weeklyActiveOpportunity
        capabilitiesClaimedCount
        CapabilityPoints
        capabilitiesClaimed {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        feeds {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        connectedJobPosts {
          items {
            id
            jobPostID
            GlobalCapabilityConfigID
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivityStatus
        updatedAt
        owner
        __typename
      }
      JoinedSkills {
        items {
          id
          UserId
          SkillId
          CapabilityId
          Skill {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Capabilities {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          GlobalCapabilityConfigId
          GlobalCapabilityConfigName
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteCapabilities = /* GraphQL */ `
  subscription OnDeleteCapabilities(
    $filter: ModelSubscriptionCapabilitiesFilterInput
  ) {
    onDeleteCapabilities(filter: $filter) {
      createdAt
      ProfileId
      id
      PointsEarned {
        items {
          id
          Project
          Hack
          ProjectLink
          Video
          Photo
          Presentation
          experience
          Claim
          Outcomes
          PeersVouch
          GlobalCapabilityConfigId
          Helped
          Problem
          Challenges
          CapabilityPoints
          UserId
          capabilityPointsCapabilityClaimedId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      userProfile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      ConnectedHidis {
        items {
          CapabilityId
          HidiId
          Capability {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Hidi {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          createdAt
          id
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      capabilitiesCapabilityConfigId
      CapabilityConfig {
        id
        name
        tag
        domain
        createdAt
        weeklyActiveOpportunity
        capabilitiesClaimedCount
        CapabilityPoints
        capabilitiesClaimed {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        feeds {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        connectedJobPosts {
          items {
            id
            jobPostID
            GlobalCapabilityConfigID
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivityStatus
        updatedAt
        owner
        __typename
      }
      JoinedSkills {
        items {
          id
          UserId
          SkillId
          CapabilityId
          Skill {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Capabilities {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          GlobalCapabilityConfigId
          GlobalCapabilityConfigName
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateJoinHidisAndCapabilities = /* GraphQL */ `
  subscription OnCreateJoinHidisAndCapabilities(
    $filter: ModelSubscriptionJoinHidisAndCapabilitiesFilterInput
  ) {
    onCreateJoinHidisAndCapabilities(filter: $filter) {
      CapabilityId
      HidiId
      Capability {
        createdAt
        ProfileId
        id
        PointsEarned {
          items {
            id
            Project
            Hack
            ProjectLink
            Video
            Photo
            Presentation
            experience
            Claim
            Outcomes
            PeersVouch
            GlobalCapabilityConfigId
            Helped
            Problem
            Challenges
            CapabilityPoints
            UserId
            capabilityPointsCapabilityClaimedId
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        userProfile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        ConnectedHidis {
          items {
            CapabilityId
            HidiId
            Capability {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            createdAt
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        capabilitiesCapabilityConfigId
        CapabilityConfig {
          id
          name
          tag
          domain
          createdAt
          weeklyActiveOpportunity
          capabilitiesClaimedCount
          CapabilityPoints
          capabilitiesClaimed {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          feeds {
            items {
              id
              UserSegmentId
              HidiId
              CapabilityConfigId
              jobPostId
              tags
              domain
              Description
              Payload
              FeedType
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          connectedJobPosts {
            items {
              id
              jobPostID
              GlobalCapabilityConfigID
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivityStatus
          updatedAt
          owner
          __typename
        }
        JoinedSkills {
          items {
            id
            UserId
            SkillId
            CapabilityId
            Skill {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            Capabilities {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            GlobalCapabilityConfigId
            GlobalCapabilityConfigName
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      Hidi {
        createdAt
        Email
        id
        profileId
        profile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        name
        UserId
        challenges {
          items {
            createdAt
            id
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            challenge
            HidiId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        outcomes {
          items {
            createdAt
            id
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            Outcome
            HidiId
            Verified
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConnectedCapability {
          items {
            CapabilityId
            HidiId
            Capability {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            createdAt
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        connectedFeeds {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        summary
        description
        title
        Verified
        likes
        views
        steps
        jobs
        outcomelink
        impact
        impactOverall
        imagelink
        outcome
        pinnedFlag
        tag1
        tag2
        htmlheading
        htmlsummary
        htmloutput
        peerEmails
        helped
        problem
        Hack
        experience
        projectLink
        whereitwasdone
        whereitwasdonecompany
        SkillUsed
        ExperinceInSkill
        outcomejson
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        AiGenerated
        ProbabilityFake
        ProbabilityReal
        hidiTopic
        updatedAt
        owner
        __typename
      }
      createdAt
      id
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateJoinHidisAndCapabilities = /* GraphQL */ `
  subscription OnUpdateJoinHidisAndCapabilities(
    $filter: ModelSubscriptionJoinHidisAndCapabilitiesFilterInput
  ) {
    onUpdateJoinHidisAndCapabilities(filter: $filter) {
      CapabilityId
      HidiId
      Capability {
        createdAt
        ProfileId
        id
        PointsEarned {
          items {
            id
            Project
            Hack
            ProjectLink
            Video
            Photo
            Presentation
            experience
            Claim
            Outcomes
            PeersVouch
            GlobalCapabilityConfigId
            Helped
            Problem
            Challenges
            CapabilityPoints
            UserId
            capabilityPointsCapabilityClaimedId
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        userProfile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        ConnectedHidis {
          items {
            CapabilityId
            HidiId
            Capability {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            createdAt
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        capabilitiesCapabilityConfigId
        CapabilityConfig {
          id
          name
          tag
          domain
          createdAt
          weeklyActiveOpportunity
          capabilitiesClaimedCount
          CapabilityPoints
          capabilitiesClaimed {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          feeds {
            items {
              id
              UserSegmentId
              HidiId
              CapabilityConfigId
              jobPostId
              tags
              domain
              Description
              Payload
              FeedType
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          connectedJobPosts {
            items {
              id
              jobPostID
              GlobalCapabilityConfigID
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivityStatus
          updatedAt
          owner
          __typename
        }
        JoinedSkills {
          items {
            id
            UserId
            SkillId
            CapabilityId
            Skill {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            Capabilities {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            GlobalCapabilityConfigId
            GlobalCapabilityConfigName
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      Hidi {
        createdAt
        Email
        id
        profileId
        profile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        name
        UserId
        challenges {
          items {
            createdAt
            id
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            challenge
            HidiId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        outcomes {
          items {
            createdAt
            id
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            Outcome
            HidiId
            Verified
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConnectedCapability {
          items {
            CapabilityId
            HidiId
            Capability {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            createdAt
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        connectedFeeds {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        summary
        description
        title
        Verified
        likes
        views
        steps
        jobs
        outcomelink
        impact
        impactOverall
        imagelink
        outcome
        pinnedFlag
        tag1
        tag2
        htmlheading
        htmlsummary
        htmloutput
        peerEmails
        helped
        problem
        Hack
        experience
        projectLink
        whereitwasdone
        whereitwasdonecompany
        SkillUsed
        ExperinceInSkill
        outcomejson
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        AiGenerated
        ProbabilityFake
        ProbabilityReal
        hidiTopic
        updatedAt
        owner
        __typename
      }
      createdAt
      id
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteJoinHidisAndCapabilities = /* GraphQL */ `
  subscription OnDeleteJoinHidisAndCapabilities(
    $filter: ModelSubscriptionJoinHidisAndCapabilitiesFilterInput
  ) {
    onDeleteJoinHidisAndCapabilities(filter: $filter) {
      CapabilityId
      HidiId
      Capability {
        createdAt
        ProfileId
        id
        PointsEarned {
          items {
            id
            Project
            Hack
            ProjectLink
            Video
            Photo
            Presentation
            experience
            Claim
            Outcomes
            PeersVouch
            GlobalCapabilityConfigId
            Helped
            Problem
            Challenges
            CapabilityPoints
            UserId
            capabilityPointsCapabilityClaimedId
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        userProfile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        ConnectedHidis {
          items {
            CapabilityId
            HidiId
            Capability {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            createdAt
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        capabilitiesCapabilityConfigId
        CapabilityConfig {
          id
          name
          tag
          domain
          createdAt
          weeklyActiveOpportunity
          capabilitiesClaimedCount
          CapabilityPoints
          capabilitiesClaimed {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          feeds {
            items {
              id
              UserSegmentId
              HidiId
              CapabilityConfigId
              jobPostId
              tags
              domain
              Description
              Payload
              FeedType
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          connectedJobPosts {
            items {
              id
              jobPostID
              GlobalCapabilityConfigID
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivityStatus
          updatedAt
          owner
          __typename
        }
        JoinedSkills {
          items {
            id
            UserId
            SkillId
            CapabilityId
            Skill {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            Capabilities {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            GlobalCapabilityConfigId
            GlobalCapabilityConfigName
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      Hidi {
        createdAt
        Email
        id
        profileId
        profile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        name
        UserId
        challenges {
          items {
            createdAt
            id
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            challenge
            HidiId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        outcomes {
          items {
            createdAt
            id
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            Outcome
            HidiId
            Verified
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConnectedCapability {
          items {
            CapabilityId
            HidiId
            Capability {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            createdAt
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        connectedFeeds {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        summary
        description
        title
        Verified
        likes
        views
        steps
        jobs
        outcomelink
        impact
        impactOverall
        imagelink
        outcome
        pinnedFlag
        tag1
        tag2
        htmlheading
        htmlsummary
        htmloutput
        peerEmails
        helped
        problem
        Hack
        experience
        projectLink
        whereitwasdone
        whereitwasdonecompany
        SkillUsed
        ExperinceInSkill
        outcomejson
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        AiGenerated
        ProbabilityFake
        ProbabilityReal
        hidiTopic
        updatedAt
        owner
        __typename
      }
      createdAt
      id
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateGlobalCapabilityConfig = /* GraphQL */ `
  subscription OnCreateGlobalCapabilityConfig(
    $filter: ModelSubscriptionGlobalCapabilityConfigFilterInput
  ) {
    onCreateGlobalCapabilityConfig(filter: $filter) {
      id
      name
      tag
      domain
      createdAt
      weeklyActiveOpportunity
      capabilitiesClaimedCount
      CapabilityPoints
      capabilitiesClaimed {
        items {
          createdAt
          ProfileId
          id
          PointsEarned {
            items {
              id
              Project
              Hack
              ProjectLink
              Video
              Photo
              Presentation
              experience
              Claim
              Outcomes
              PeersVouch
              GlobalCapabilityConfigId
              Helped
              Problem
              Challenges
              CapabilityPoints
              UserId
              capabilityPointsCapabilityClaimedId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          userProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ConnectedHidis {
            items {
              CapabilityId
              HidiId
              createdAt
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          capabilitiesCapabilityConfigId
          CapabilityConfig {
            id
            name
            tag
            domain
            createdAt
            weeklyActiveOpportunity
            capabilitiesClaimedCount
            CapabilityPoints
            capabilitiesClaimed {
              nextToken
              __typename
            }
            feeds {
              nextToken
              __typename
            }
            connectedJobPosts {
              nextToken
              __typename
            }
            ActivityStatus
            updatedAt
            owner
            __typename
          }
          JoinedSkills {
            items {
              id
              UserId
              SkillId
              CapabilityId
              GlobalCapabilityConfigId
              GlobalCapabilityConfigName
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      feeds {
        items {
          id
          UserSegmentId
          HidiId
          Hidi {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          CapabilityConfigId
          jobPostId
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          tags
          domain
          CapabilityConfig {
            id
            name
            tag
            domain
            createdAt
            weeklyActiveOpportunity
            capabilitiesClaimedCount
            CapabilityPoints
            capabilitiesClaimed {
              nextToken
              __typename
            }
            feeds {
              nextToken
              __typename
            }
            connectedJobPosts {
              nextToken
              __typename
            }
            ActivityStatus
            updatedAt
            owner
            __typename
          }
          Description
          Payload
          FeedType
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      connectedJobPosts {
        items {
          id
          jobPostID
          GlobalCapabilityConfigID
          CapabilityConfig {
            id
            name
            tag
            domain
            createdAt
            weeklyActiveOpportunity
            capabilitiesClaimedCount
            CapabilityPoints
            capabilitiesClaimed {
              nextToken
              __typename
            }
            feeds {
              nextToken
              __typename
            }
            connectedJobPosts {
              nextToken
              __typename
            }
            ActivityStatus
            updatedAt
            owner
            __typename
          }
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      ActivityStatus
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateGlobalCapabilityConfig = /* GraphQL */ `
  subscription OnUpdateGlobalCapabilityConfig(
    $filter: ModelSubscriptionGlobalCapabilityConfigFilterInput
  ) {
    onUpdateGlobalCapabilityConfig(filter: $filter) {
      id
      name
      tag
      domain
      createdAt
      weeklyActiveOpportunity
      capabilitiesClaimedCount
      CapabilityPoints
      capabilitiesClaimed {
        items {
          createdAt
          ProfileId
          id
          PointsEarned {
            items {
              id
              Project
              Hack
              ProjectLink
              Video
              Photo
              Presentation
              experience
              Claim
              Outcomes
              PeersVouch
              GlobalCapabilityConfigId
              Helped
              Problem
              Challenges
              CapabilityPoints
              UserId
              capabilityPointsCapabilityClaimedId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          userProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ConnectedHidis {
            items {
              CapabilityId
              HidiId
              createdAt
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          capabilitiesCapabilityConfigId
          CapabilityConfig {
            id
            name
            tag
            domain
            createdAt
            weeklyActiveOpportunity
            capabilitiesClaimedCount
            CapabilityPoints
            capabilitiesClaimed {
              nextToken
              __typename
            }
            feeds {
              nextToken
              __typename
            }
            connectedJobPosts {
              nextToken
              __typename
            }
            ActivityStatus
            updatedAt
            owner
            __typename
          }
          JoinedSkills {
            items {
              id
              UserId
              SkillId
              CapabilityId
              GlobalCapabilityConfigId
              GlobalCapabilityConfigName
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      feeds {
        items {
          id
          UserSegmentId
          HidiId
          Hidi {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          CapabilityConfigId
          jobPostId
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          tags
          domain
          CapabilityConfig {
            id
            name
            tag
            domain
            createdAt
            weeklyActiveOpportunity
            capabilitiesClaimedCount
            CapabilityPoints
            capabilitiesClaimed {
              nextToken
              __typename
            }
            feeds {
              nextToken
              __typename
            }
            connectedJobPosts {
              nextToken
              __typename
            }
            ActivityStatus
            updatedAt
            owner
            __typename
          }
          Description
          Payload
          FeedType
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      connectedJobPosts {
        items {
          id
          jobPostID
          GlobalCapabilityConfigID
          CapabilityConfig {
            id
            name
            tag
            domain
            createdAt
            weeklyActiveOpportunity
            capabilitiesClaimedCount
            CapabilityPoints
            capabilitiesClaimed {
              nextToken
              __typename
            }
            feeds {
              nextToken
              __typename
            }
            connectedJobPosts {
              nextToken
              __typename
            }
            ActivityStatus
            updatedAt
            owner
            __typename
          }
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      ActivityStatus
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteGlobalCapabilityConfig = /* GraphQL */ `
  subscription OnDeleteGlobalCapabilityConfig(
    $filter: ModelSubscriptionGlobalCapabilityConfigFilterInput
  ) {
    onDeleteGlobalCapabilityConfig(filter: $filter) {
      id
      name
      tag
      domain
      createdAt
      weeklyActiveOpportunity
      capabilitiesClaimedCount
      CapabilityPoints
      capabilitiesClaimed {
        items {
          createdAt
          ProfileId
          id
          PointsEarned {
            items {
              id
              Project
              Hack
              ProjectLink
              Video
              Photo
              Presentation
              experience
              Claim
              Outcomes
              PeersVouch
              GlobalCapabilityConfigId
              Helped
              Problem
              Challenges
              CapabilityPoints
              UserId
              capabilityPointsCapabilityClaimedId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          userProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ConnectedHidis {
            items {
              CapabilityId
              HidiId
              createdAt
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          capabilitiesCapabilityConfigId
          CapabilityConfig {
            id
            name
            tag
            domain
            createdAt
            weeklyActiveOpportunity
            capabilitiesClaimedCount
            CapabilityPoints
            capabilitiesClaimed {
              nextToken
              __typename
            }
            feeds {
              nextToken
              __typename
            }
            connectedJobPosts {
              nextToken
              __typename
            }
            ActivityStatus
            updatedAt
            owner
            __typename
          }
          JoinedSkills {
            items {
              id
              UserId
              SkillId
              CapabilityId
              GlobalCapabilityConfigId
              GlobalCapabilityConfigName
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      feeds {
        items {
          id
          UserSegmentId
          HidiId
          Hidi {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          CapabilityConfigId
          jobPostId
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          tags
          domain
          CapabilityConfig {
            id
            name
            tag
            domain
            createdAt
            weeklyActiveOpportunity
            capabilitiesClaimedCount
            CapabilityPoints
            capabilitiesClaimed {
              nextToken
              __typename
            }
            feeds {
              nextToken
              __typename
            }
            connectedJobPosts {
              nextToken
              __typename
            }
            ActivityStatus
            updatedAt
            owner
            __typename
          }
          Description
          Payload
          FeedType
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      connectedJobPosts {
        items {
          id
          jobPostID
          GlobalCapabilityConfigID
          CapabilityConfig {
            id
            name
            tag
            domain
            createdAt
            weeklyActiveOpportunity
            capabilitiesClaimedCount
            CapabilityPoints
            capabilitiesClaimed {
              nextToken
              __typename
            }
            feeds {
              nextToken
              __typename
            }
            connectedJobPosts {
              nextToken
              __typename
            }
            ActivityStatus
            updatedAt
            owner
            __typename
          }
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      ActivityStatus
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateCapabilityPoints = /* GraphQL */ `
  subscription OnCreateCapabilityPoints(
    $filter: ModelSubscriptionCapabilityPointsFilterInput
  ) {
    onCreateCapabilityPoints(filter: $filter) {
      id
      Project
      Hack
      ProjectLink
      Video
      Photo
      Presentation
      experience
      Claim
      Outcomes
      PeersVouch
      GlobalCapabilityConfigId
      Helped
      Problem
      Challenges
      CapabilityPoints
      UserId
      capabilityPointsCapabilityClaimedId
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateCapabilityPoints = /* GraphQL */ `
  subscription OnUpdateCapabilityPoints(
    $filter: ModelSubscriptionCapabilityPointsFilterInput
  ) {
    onUpdateCapabilityPoints(filter: $filter) {
      id
      Project
      Hack
      ProjectLink
      Video
      Photo
      Presentation
      experience
      Claim
      Outcomes
      PeersVouch
      GlobalCapabilityConfigId
      Helped
      Problem
      Challenges
      CapabilityPoints
      UserId
      capabilityPointsCapabilityClaimedId
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteCapabilityPoints = /* GraphQL */ `
  subscription OnDeleteCapabilityPoints(
    $filter: ModelSubscriptionCapabilityPointsFilterInput
  ) {
    onDeleteCapabilityPoints(filter: $filter) {
      id
      Project
      Hack
      ProjectLink
      Video
      Photo
      Presentation
      experience
      Claim
      Outcomes
      PeersVouch
      GlobalCapabilityConfigId
      Helped
      Problem
      Challenges
      CapabilityPoints
      UserId
      capabilityPointsCapabilityClaimedId
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser($filter: ModelSubscriptionUserFilterInput) {
    onCreateUser(filter: $filter) {
      cognitoId
      id
      username
      usertype
      fullname
      registered
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser($filter: ModelSubscriptionUserFilterInput) {
    onUpdateUser(filter: $filter) {
      cognitoId
      id
      username
      usertype
      fullname
      registered
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser($filter: ModelSubscriptionUserFilterInput) {
    onDeleteUser(filter: $filter) {
      cognitoId
      id
      username
      usertype
      fullname
      registered
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateSignal = /* GraphQL */ `
  subscription OnCreateSignal($filter: ModelSubscriptionSignalFilterInput) {
    onCreateSignal(filter: $filter) {
      createdAt
      hidiId
      UserId
      Spotter
      description
      id
      signalname
      score
      signalcategory
      profile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            Talents {
              nextToken
              __typename
            }
            signals {
              nextToken
              __typename
            }
            MobileNumber
            name
            UserId
            PSStitle
            PSSdescription
            DMStitle
            DMSdescription
            CTtitle
            CTdescription
            Atitle
            Adescription
            Verified
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Talents {
          items {
            createdAt
            hidiId
            UserId
            Spotter
            description
            id
            Talent
            Level
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            hidi {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        signals {
          items {
            createdAt
            hidiId
            UserId
            Spotter
            description
            id
            signalname
            score
            signalcategory
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            hidi {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        resume
        education
        experience
        expertise
        graduation
        iframescorelink
        iframehidilink
        updatedAt
        owner
        __typename
      }
      hidi {
        createdAt
        Email
        id
        profile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Talents {
            items {
              createdAt
              hidiId
              UserId
              Spotter
              description
              id
              Talent
              Level
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          signals {
            items {
              createdAt
              hidiId
              UserId
              Spotter
              description
              id
              signalname
              score
              signalcategory
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          resume
          education
          experience
          expertise
          graduation
          iframescorelink
          iframehidilink
          updatedAt
          owner
          __typename
        }
        Talents {
          items {
            createdAt
            hidiId
            UserId
            Spotter
            description
            id
            Talent
            Level
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            hidi {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        signals {
          items {
            createdAt
            hidiId
            UserId
            Spotter
            description
            id
            signalname
            score
            signalcategory
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            hidi {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        MobileNumber
        name
        UserId
        PSStitle
        PSSdescription
        DMStitle
        DMSdescription
        CTtitle
        CTdescription
        Atitle
        Adescription
        Verified
        updatedAt
        owner
        __typename
      }
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateSignal = /* GraphQL */ `
  subscription OnUpdateSignal($filter: ModelSubscriptionSignalFilterInput) {
    onUpdateSignal(filter: $filter) {
      createdAt
      hidiId
      UserId
      Spotter
      description
      id
      signalname
      score
      signalcategory
      profile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            Talents {
              nextToken
              __typename
            }
            signals {
              nextToken
              __typename
            }
            MobileNumber
            name
            UserId
            PSStitle
            PSSdescription
            DMStitle
            DMSdescription
            CTtitle
            CTdescription
            Atitle
            Adescription
            Verified
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Talents {
          items {
            createdAt
            hidiId
            UserId
            Spotter
            description
            id
            Talent
            Level
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            hidi {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        signals {
          items {
            createdAt
            hidiId
            UserId
            Spotter
            description
            id
            signalname
            score
            signalcategory
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            hidi {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        resume
        education
        experience
        expertise
        graduation
        iframescorelink
        iframehidilink
        updatedAt
        owner
        __typename
      }
      hidi {
        createdAt
        Email
        id
        profile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Talents {
            items {
              createdAt
              hidiId
              UserId
              Spotter
              description
              id
              Talent
              Level
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          signals {
            items {
              createdAt
              hidiId
              UserId
              Spotter
              description
              id
              signalname
              score
              signalcategory
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          resume
          education
          experience
          expertise
          graduation
          iframescorelink
          iframehidilink
          updatedAt
          owner
          __typename
        }
        Talents {
          items {
            createdAt
            hidiId
            UserId
            Spotter
            description
            id
            Talent
            Level
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            hidi {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        signals {
          items {
            createdAt
            hidiId
            UserId
            Spotter
            description
            id
            signalname
            score
            signalcategory
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            hidi {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        MobileNumber
        name
        UserId
        PSStitle
        PSSdescription
        DMStitle
        DMSdescription
        CTtitle
        CTdescription
        Atitle
        Adescription
        Verified
        updatedAt
        owner
        __typename
      }
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteSignal = /* GraphQL */ `
  subscription OnDeleteSignal($filter: ModelSubscriptionSignalFilterInput) {
    onDeleteSignal(filter: $filter) {
      createdAt
      hidiId
      UserId
      Spotter
      description
      id
      signalname
      score
      signalcategory
      profile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            Talents {
              nextToken
              __typename
            }
            signals {
              nextToken
              __typename
            }
            MobileNumber
            name
            UserId
            PSStitle
            PSSdescription
            DMStitle
            DMSdescription
            CTtitle
            CTdescription
            Atitle
            Adescription
            Verified
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Talents {
          items {
            createdAt
            hidiId
            UserId
            Spotter
            description
            id
            Talent
            Level
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            hidi {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        signals {
          items {
            createdAt
            hidiId
            UserId
            Spotter
            description
            id
            signalname
            score
            signalcategory
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            hidi {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        resume
        education
        experience
        expertise
        graduation
        iframescorelink
        iframehidilink
        updatedAt
        owner
        __typename
      }
      hidi {
        createdAt
        Email
        id
        profile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Talents {
            items {
              createdAt
              hidiId
              UserId
              Spotter
              description
              id
              Talent
              Level
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          signals {
            items {
              createdAt
              hidiId
              UserId
              Spotter
              description
              id
              signalname
              score
              signalcategory
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          resume
          education
          experience
          expertise
          graduation
          iframescorelink
          iframehidilink
          updatedAt
          owner
          __typename
        }
        Talents {
          items {
            createdAt
            hidiId
            UserId
            Spotter
            description
            id
            Talent
            Level
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            hidi {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        signals {
          items {
            createdAt
            hidiId
            UserId
            Spotter
            description
            id
            signalname
            score
            signalcategory
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            hidi {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        MobileNumber
        name
        UserId
        PSStitle
        PSSdescription
        DMStitle
        DMSdescription
        CTtitle
        CTdescription
        Atitle
        Adescription
        Verified
        updatedAt
        owner
        __typename
      }
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateUserParsedResume = /* GraphQL */ `
  subscription OnCreateUserParsedResume(
    $filter: ModelSubscriptionUserParsedResumeFilterInput
  ) {
    onCreateUserParsedResume(filter: $filter) {
      UserId
      ParsedString
      createdAt
      id
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateUserParsedResume = /* GraphQL */ `
  subscription OnUpdateUserParsedResume(
    $filter: ModelSubscriptionUserParsedResumeFilterInput
  ) {
    onUpdateUserParsedResume(filter: $filter) {
      UserId
      ParsedString
      createdAt
      id
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteUserParsedResume = /* GraphQL */ `
  subscription OnDeleteUserParsedResume(
    $filter: ModelSubscriptionUserParsedResumeFilterInput
  ) {
    onDeleteUserParsedResume(filter: $filter) {
      UserId
      ParsedString
      createdAt
      id
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateFeed = /* GraphQL */ `
  subscription OnCreateFeed($filter: ModelSubscriptionFeedFilterInput) {
    onCreateFeed(filter: $filter) {
      id
      UserSegmentId
      HidiId
      Hidi {
        createdAt
        Email
        id
        profileId
        profile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        name
        UserId
        challenges {
          items {
            createdAt
            id
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            challenge
            HidiId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        outcomes {
          items {
            createdAt
            id
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            Outcome
            HidiId
            Verified
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConnectedCapability {
          items {
            CapabilityId
            HidiId
            Capability {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            createdAt
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        connectedFeeds {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        summary
        description
        title
        Verified
        likes
        views
        steps
        jobs
        outcomelink
        impact
        impactOverall
        imagelink
        outcome
        pinnedFlag
        tag1
        tag2
        htmlheading
        htmlsummary
        htmloutput
        peerEmails
        helped
        problem
        Hack
        experience
        projectLink
        whereitwasdone
        whereitwasdonecompany
        SkillUsed
        ExperinceInSkill
        outcomejson
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        AiGenerated
        ProbabilityFake
        ProbabilityReal
        hidiTopic
        updatedAt
        owner
        __typename
      }
      CapabilityConfigId
      jobPostId
      jobPost {
        id
        CompanyPreferenceId
        companyQrewId
        CompanyPreference {
          createdAt
          id
          rolename
          businessUserId
          businessProfileId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          recommendedprofiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejectedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobPosts {
            items {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobTraits {
            items {
              id
              TraitName
              createdAt
              updatedAt
              preferencesID
              owner
              __typename
            }
            nextToken
            __typename
          }
          package
          certifications
          noticeperiod
          maxExperienceRequired
          minExperienceRequired
          modeofwork
          JobDomain
          other
          previousrole
          combinedrole
          currencyCountry
          JobType
          Location
          JobPublished
          numberOfCandidateRequired
          updatedAt
          owner
          __typename
        }
        companyName
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        shortlistedCandidates {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        jobPostQuestions {
          items {
            id
            JobPostId
            preferencesID
            Question
            TypeOfQuesiton
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        jobPostLink
        logoUrl
        htmldescription
        CompanyDescription
        AvailablityStatus
        experienceRequired
        jobType
        Location
        skills
        domain
        description
        ShortDesc
        RolesandResponsibility
        title
        CapabilitiesRequired {
          items {
            id
            jobPostID
            GlobalCapabilityConfigID
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        FeedsConnected {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CapabilitiesRequiredJSON
        createdAt
        updatedAt
        SavedJobPosts {
          items {
            id
            JobPostId
            ProfileId
            UserId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        owner
        __typename
      }
      tags
      domain
      CapabilityConfig {
        id
        name
        tag
        domain
        createdAt
        weeklyActiveOpportunity
        capabilitiesClaimedCount
        CapabilityPoints
        capabilitiesClaimed {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        feeds {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        connectedJobPosts {
          items {
            id
            jobPostID
            GlobalCapabilityConfigID
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivityStatus
        updatedAt
        owner
        __typename
      }
      Description
      Payload
      FeedType
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateFeed = /* GraphQL */ `
  subscription OnUpdateFeed($filter: ModelSubscriptionFeedFilterInput) {
    onUpdateFeed(filter: $filter) {
      id
      UserSegmentId
      HidiId
      Hidi {
        createdAt
        Email
        id
        profileId
        profile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        name
        UserId
        challenges {
          items {
            createdAt
            id
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            challenge
            HidiId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        outcomes {
          items {
            createdAt
            id
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            Outcome
            HidiId
            Verified
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConnectedCapability {
          items {
            CapabilityId
            HidiId
            Capability {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            createdAt
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        connectedFeeds {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        summary
        description
        title
        Verified
        likes
        views
        steps
        jobs
        outcomelink
        impact
        impactOverall
        imagelink
        outcome
        pinnedFlag
        tag1
        tag2
        htmlheading
        htmlsummary
        htmloutput
        peerEmails
        helped
        problem
        Hack
        experience
        projectLink
        whereitwasdone
        whereitwasdonecompany
        SkillUsed
        ExperinceInSkill
        outcomejson
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        AiGenerated
        ProbabilityFake
        ProbabilityReal
        hidiTopic
        updatedAt
        owner
        __typename
      }
      CapabilityConfigId
      jobPostId
      jobPost {
        id
        CompanyPreferenceId
        companyQrewId
        CompanyPreference {
          createdAt
          id
          rolename
          businessUserId
          businessProfileId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          recommendedprofiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejectedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobPosts {
            items {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobTraits {
            items {
              id
              TraitName
              createdAt
              updatedAt
              preferencesID
              owner
              __typename
            }
            nextToken
            __typename
          }
          package
          certifications
          noticeperiod
          maxExperienceRequired
          minExperienceRequired
          modeofwork
          JobDomain
          other
          previousrole
          combinedrole
          currencyCountry
          JobType
          Location
          JobPublished
          numberOfCandidateRequired
          updatedAt
          owner
          __typename
        }
        companyName
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        shortlistedCandidates {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        jobPostQuestions {
          items {
            id
            JobPostId
            preferencesID
            Question
            TypeOfQuesiton
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        jobPostLink
        logoUrl
        htmldescription
        CompanyDescription
        AvailablityStatus
        experienceRequired
        jobType
        Location
        skills
        domain
        description
        ShortDesc
        RolesandResponsibility
        title
        CapabilitiesRequired {
          items {
            id
            jobPostID
            GlobalCapabilityConfigID
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        FeedsConnected {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CapabilitiesRequiredJSON
        createdAt
        updatedAt
        SavedJobPosts {
          items {
            id
            JobPostId
            ProfileId
            UserId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        owner
        __typename
      }
      tags
      domain
      CapabilityConfig {
        id
        name
        tag
        domain
        createdAt
        weeklyActiveOpportunity
        capabilitiesClaimedCount
        CapabilityPoints
        capabilitiesClaimed {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        feeds {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        connectedJobPosts {
          items {
            id
            jobPostID
            GlobalCapabilityConfigID
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivityStatus
        updatedAt
        owner
        __typename
      }
      Description
      Payload
      FeedType
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteFeed = /* GraphQL */ `
  subscription OnDeleteFeed($filter: ModelSubscriptionFeedFilterInput) {
    onDeleteFeed(filter: $filter) {
      id
      UserSegmentId
      HidiId
      Hidi {
        createdAt
        Email
        id
        profileId
        profile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        name
        UserId
        challenges {
          items {
            createdAt
            id
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            challenge
            HidiId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        outcomes {
          items {
            createdAt
            id
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            Outcome
            HidiId
            Verified
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConnectedCapability {
          items {
            CapabilityId
            HidiId
            Capability {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            createdAt
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        connectedFeeds {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        summary
        description
        title
        Verified
        likes
        views
        steps
        jobs
        outcomelink
        impact
        impactOverall
        imagelink
        outcome
        pinnedFlag
        tag1
        tag2
        htmlheading
        htmlsummary
        htmloutput
        peerEmails
        helped
        problem
        Hack
        experience
        projectLink
        whereitwasdone
        whereitwasdonecompany
        SkillUsed
        ExperinceInSkill
        outcomejson
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        AiGenerated
        ProbabilityFake
        ProbabilityReal
        hidiTopic
        updatedAt
        owner
        __typename
      }
      CapabilityConfigId
      jobPostId
      jobPost {
        id
        CompanyPreferenceId
        companyQrewId
        CompanyPreference {
          createdAt
          id
          rolename
          businessUserId
          businessProfileId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          recommendedprofiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejectedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobPosts {
            items {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobTraits {
            items {
              id
              TraitName
              createdAt
              updatedAt
              preferencesID
              owner
              __typename
            }
            nextToken
            __typename
          }
          package
          certifications
          noticeperiod
          maxExperienceRequired
          minExperienceRequired
          modeofwork
          JobDomain
          other
          previousrole
          combinedrole
          currencyCountry
          JobType
          Location
          JobPublished
          numberOfCandidateRequired
          updatedAt
          owner
          __typename
        }
        companyName
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        shortlistedCandidates {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        jobPostQuestions {
          items {
            id
            JobPostId
            preferencesID
            Question
            TypeOfQuesiton
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        jobPostLink
        logoUrl
        htmldescription
        CompanyDescription
        AvailablityStatus
        experienceRequired
        jobType
        Location
        skills
        domain
        description
        ShortDesc
        RolesandResponsibility
        title
        CapabilitiesRequired {
          items {
            id
            jobPostID
            GlobalCapabilityConfigID
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        FeedsConnected {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CapabilitiesRequiredJSON
        createdAt
        updatedAt
        SavedJobPosts {
          items {
            id
            JobPostId
            ProfileId
            UserId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        owner
        __typename
      }
      tags
      domain
      CapabilityConfig {
        id
        name
        tag
        domain
        createdAt
        weeklyActiveOpportunity
        capabilitiesClaimedCount
        CapabilityPoints
        capabilitiesClaimed {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        feeds {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        connectedJobPosts {
          items {
            id
            jobPostID
            GlobalCapabilityConfigID
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivityStatus
        updatedAt
        owner
        __typename
      }
      Description
      Payload
      FeedType
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateUserSegments = /* GraphQL */ `
  subscription OnCreateUserSegments(
    $filter: ModelSubscriptionUserSegmentsFilterInput
  ) {
    onCreateUserSegments(filter: $filter) {
      id
      SegmentString
      ConnectedUsers {
        items {
          UserSegmentId
          UserId
          createdAt
          updatedAt
          id
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateUserSegments = /* GraphQL */ `
  subscription OnUpdateUserSegments(
    $filter: ModelSubscriptionUserSegmentsFilterInput
  ) {
    onUpdateUserSegments(filter: $filter) {
      id
      SegmentString
      ConnectedUsers {
        items {
          UserSegmentId
          UserId
          createdAt
          updatedAt
          id
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteUserSegments = /* GraphQL */ `
  subscription OnDeleteUserSegments(
    $filter: ModelSubscriptionUserSegmentsFilterInput
  ) {
    onDeleteUserSegments(filter: $filter) {
      id
      SegmentString
      ConnectedUsers {
        items {
          UserSegmentId
          UserId
          createdAt
          updatedAt
          id
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateJoinUserSegmentsandNewProfile = /* GraphQL */ `
  subscription OnCreateJoinUserSegmentsandNewProfile(
    $filter: ModelSubscriptionJoinUserSegmentsandNewProfileFilterInput
  ) {
    onCreateJoinUserSegmentsandNewProfile(filter: $filter) {
      UserSegmentId
      UserId
      createdAt
      updatedAt
      id
      owner
      __typename
    }
  }
`;
export const onUpdateJoinUserSegmentsandNewProfile = /* GraphQL */ `
  subscription OnUpdateJoinUserSegmentsandNewProfile(
    $filter: ModelSubscriptionJoinUserSegmentsandNewProfileFilterInput
  ) {
    onUpdateJoinUserSegmentsandNewProfile(filter: $filter) {
      UserSegmentId
      UserId
      createdAt
      updatedAt
      id
      owner
      __typename
    }
  }
`;
export const onDeleteJoinUserSegmentsandNewProfile = /* GraphQL */ `
  subscription OnDeleteJoinUserSegmentsandNewProfile(
    $filter: ModelSubscriptionJoinUserSegmentsandNewProfileFilterInput
  ) {
    onDeleteJoinUserSegmentsandNewProfile(filter: $filter) {
      UserSegmentId
      UserId
      createdAt
      updatedAt
      id
      owner
      __typename
    }
  }
`;
export const onCreateUserEvents = /* GraphQL */ `
  subscription OnCreateUserEvents(
    $filter: ModelSubscriptionUserEventsFilterInput
  ) {
    onCreateUserEvents(filter: $filter) {
      id
      Payload
      UserId
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateUserEvents = /* GraphQL */ `
  subscription OnUpdateUserEvents(
    $filter: ModelSubscriptionUserEventsFilterInput
  ) {
    onUpdateUserEvents(filter: $filter) {
      id
      Payload
      UserId
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteUserEvents = /* GraphQL */ `
  subscription OnDeleteUserEvents(
    $filter: ModelSubscriptionUserEventsFilterInput
  ) {
    onDeleteUserEvents(filter: $filter) {
      id
      Payload
      UserId
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateJobApplications = /* GraphQL */ `
  subscription OnCreateJobApplications(
    $filter: ModelSubscriptionJobApplicationsFilterInput
  ) {
    onCreateJobApplications(filter: $filter) {
      id
      JobPostId
      jobPost {
        id
        CompanyPreferenceId
        companyQrewId
        CompanyPreference {
          createdAt
          id
          rolename
          businessUserId
          businessProfileId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          recommendedprofiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejectedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobPosts {
            items {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobTraits {
            items {
              id
              TraitName
              createdAt
              updatedAt
              preferencesID
              owner
              __typename
            }
            nextToken
            __typename
          }
          package
          certifications
          noticeperiod
          maxExperienceRequired
          minExperienceRequired
          modeofwork
          JobDomain
          other
          previousrole
          combinedrole
          currencyCountry
          JobType
          Location
          JobPublished
          numberOfCandidateRequired
          updatedAt
          owner
          __typename
        }
        companyName
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        shortlistedCandidates {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        jobPostQuestions {
          items {
            id
            JobPostId
            preferencesID
            Question
            TypeOfQuesiton
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        jobPostLink
        logoUrl
        htmldescription
        CompanyDescription
        AvailablityStatus
        experienceRequired
        jobType
        Location
        skills
        domain
        description
        ShortDesc
        RolesandResponsibility
        title
        CapabilitiesRequired {
          items {
            id
            jobPostID
            GlobalCapabilityConfigID
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        FeedsConnected {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CapabilitiesRequiredJSON
        createdAt
        updatedAt
        SavedJobPosts {
          items {
            id
            JobPostId
            ProfileId
            UserId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        owner
        __typename
      }
      CompanyProfileId
      ProfileId
      CompanyPreferenceId
      companyQrewId
      candidateprofile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      ApplyingStatus
      Level
      CandidateProfileAnalysis
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateJobApplications = /* GraphQL */ `
  subscription OnUpdateJobApplications(
    $filter: ModelSubscriptionJobApplicationsFilterInput
  ) {
    onUpdateJobApplications(filter: $filter) {
      id
      JobPostId
      jobPost {
        id
        CompanyPreferenceId
        companyQrewId
        CompanyPreference {
          createdAt
          id
          rolename
          businessUserId
          businessProfileId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          recommendedprofiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejectedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobPosts {
            items {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobTraits {
            items {
              id
              TraitName
              createdAt
              updatedAt
              preferencesID
              owner
              __typename
            }
            nextToken
            __typename
          }
          package
          certifications
          noticeperiod
          maxExperienceRequired
          minExperienceRequired
          modeofwork
          JobDomain
          other
          previousrole
          combinedrole
          currencyCountry
          JobType
          Location
          JobPublished
          numberOfCandidateRequired
          updatedAt
          owner
          __typename
        }
        companyName
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        shortlistedCandidates {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        jobPostQuestions {
          items {
            id
            JobPostId
            preferencesID
            Question
            TypeOfQuesiton
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        jobPostLink
        logoUrl
        htmldescription
        CompanyDescription
        AvailablityStatus
        experienceRequired
        jobType
        Location
        skills
        domain
        description
        ShortDesc
        RolesandResponsibility
        title
        CapabilitiesRequired {
          items {
            id
            jobPostID
            GlobalCapabilityConfigID
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        FeedsConnected {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CapabilitiesRequiredJSON
        createdAt
        updatedAt
        SavedJobPosts {
          items {
            id
            JobPostId
            ProfileId
            UserId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        owner
        __typename
      }
      CompanyProfileId
      ProfileId
      CompanyPreferenceId
      companyQrewId
      candidateprofile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      ApplyingStatus
      Level
      CandidateProfileAnalysis
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteJobApplications = /* GraphQL */ `
  subscription OnDeleteJobApplications(
    $filter: ModelSubscriptionJobApplicationsFilterInput
  ) {
    onDeleteJobApplications(filter: $filter) {
      id
      JobPostId
      jobPost {
        id
        CompanyPreferenceId
        companyQrewId
        CompanyPreference {
          createdAt
          id
          rolename
          businessUserId
          businessProfileId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          recommendedprofiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejectedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobPosts {
            items {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobTraits {
            items {
              id
              TraitName
              createdAt
              updatedAt
              preferencesID
              owner
              __typename
            }
            nextToken
            __typename
          }
          package
          certifications
          noticeperiod
          maxExperienceRequired
          minExperienceRequired
          modeofwork
          JobDomain
          other
          previousrole
          combinedrole
          currencyCountry
          JobType
          Location
          JobPublished
          numberOfCandidateRequired
          updatedAt
          owner
          __typename
        }
        companyName
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        shortlistedCandidates {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        jobPostQuestions {
          items {
            id
            JobPostId
            preferencesID
            Question
            TypeOfQuesiton
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        jobPostLink
        logoUrl
        htmldescription
        CompanyDescription
        AvailablityStatus
        experienceRequired
        jobType
        Location
        skills
        domain
        description
        ShortDesc
        RolesandResponsibility
        title
        CapabilitiesRequired {
          items {
            id
            jobPostID
            GlobalCapabilityConfigID
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        FeedsConnected {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CapabilitiesRequiredJSON
        createdAt
        updatedAt
        SavedJobPosts {
          items {
            id
            JobPostId
            ProfileId
            UserId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        owner
        __typename
      }
      CompanyProfileId
      ProfileId
      CompanyPreferenceId
      companyQrewId
      candidateprofile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      ApplyingStatus
      Level
      CandidateProfileAnalysis
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateJobApplicantionRecommendationKPIs = /* GraphQL */ `
  subscription OnCreateJobApplicantionRecommendationKPIs(
    $filter: ModelSubscriptionJobApplicantionRecommendationKPIsFilterInput
  ) {
    onCreateJobApplicantionRecommendationKPIs(filter: $filter) {
      id
      JobPostId
      CompanyProfileId
      CompanyPreferenceId
      NewApplicants
      NewRecommendations
      TotalApplicants
      companyPersonName
      companyMailId
      companyName
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateJobApplicantionRecommendationKPIs = /* GraphQL */ `
  subscription OnUpdateJobApplicantionRecommendationKPIs(
    $filter: ModelSubscriptionJobApplicantionRecommendationKPIsFilterInput
  ) {
    onUpdateJobApplicantionRecommendationKPIs(filter: $filter) {
      id
      JobPostId
      CompanyProfileId
      CompanyPreferenceId
      NewApplicants
      NewRecommendations
      TotalApplicants
      companyPersonName
      companyMailId
      companyName
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteJobApplicantionRecommendationKPIs = /* GraphQL */ `
  subscription OnDeleteJobApplicantionRecommendationKPIs(
    $filter: ModelSubscriptionJobApplicantionRecommendationKPIsFilterInput
  ) {
    onDeleteJobApplicantionRecommendationKPIs(filter: $filter) {
      id
      JobPostId
      CompanyProfileId
      CompanyPreferenceId
      NewApplicants
      NewRecommendations
      TotalApplicants
      companyPersonName
      companyMailId
      companyName
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateJobApplicationRecommednationsMailSent = /* GraphQL */ `
  subscription OnCreateJobApplicationRecommednationsMailSent(
    $filter: ModelSubscriptionJobApplicationRecommednationsMailSentFilterInput
  ) {
    onCreateJobApplicationRecommednationsMailSent(filter: $filter) {
      id
      JobPostIds
      CompanyUser
      companyPersonName
      companyMailId
      companyName
      mailMessageId
      MailSent
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateJobApplicationRecommednationsMailSent = /* GraphQL */ `
  subscription OnUpdateJobApplicationRecommednationsMailSent(
    $filter: ModelSubscriptionJobApplicationRecommednationsMailSentFilterInput
  ) {
    onUpdateJobApplicationRecommednationsMailSent(filter: $filter) {
      id
      JobPostIds
      CompanyUser
      companyPersonName
      companyMailId
      companyName
      mailMessageId
      MailSent
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteJobApplicationRecommednationsMailSent = /* GraphQL */ `
  subscription OnDeleteJobApplicationRecommednationsMailSent(
    $filter: ModelSubscriptionJobApplicationRecommednationsMailSentFilterInput
  ) {
    onDeleteJobApplicationRecommednationsMailSent(filter: $filter) {
      id
      JobPostIds
      CompanyUser
      companyPersonName
      companyMailId
      companyName
      mailMessageId
      MailSent
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateEventTable = /* GraphQL */ `
  subscription OnCreateEventTable(
    $filter: ModelSubscriptionEventTableFilterInput
  ) {
    onCreateEventTable(filter: $filter) {
      id
      EventNameTableKeyId
      EventName
      EventAttributes
      EventLogs
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateEventTable = /* GraphQL */ `
  subscription OnUpdateEventTable(
    $filter: ModelSubscriptionEventTableFilterInput
  ) {
    onUpdateEventTable(filter: $filter) {
      id
      EventNameTableKeyId
      EventName
      EventAttributes
      EventLogs
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteEventTable = /* GraphQL */ `
  subscription OnDeleteEventTable(
    $filter: ModelSubscriptionEventTableFilterInput
  ) {
    onDeleteEventTable(filter: $filter) {
      id
      EventNameTableKeyId
      EventName
      EventAttributes
      EventLogs
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateContractEventTable = /* GraphQL */ `
  subscription OnCreateContractEventTable(
    $filter: ModelSubscriptionContractEventTableFilterInput
  ) {
    onCreateContractEventTable(filter: $filter) {
      id
      EventNameTableKeyId
      EventName
      EventAttributes
      EventCreatedbyUserCognitoId
      EventLogs
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateContractEventTable = /* GraphQL */ `
  subscription OnUpdateContractEventTable(
    $filter: ModelSubscriptionContractEventTableFilterInput
  ) {
    onUpdateContractEventTable(filter: $filter) {
      id
      EventNameTableKeyId
      EventName
      EventAttributes
      EventCreatedbyUserCognitoId
      EventLogs
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteContractEventTable = /* GraphQL */ `
  subscription OnDeleteContractEventTable(
    $filter: ModelSubscriptionContractEventTableFilterInput
  ) {
    onDeleteContractEventTable(filter: $filter) {
      id
      EventNameTableKeyId
      EventName
      EventAttributes
      EventCreatedbyUserCognitoId
      EventLogs
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateJobPost = /* GraphQL */ `
  subscription OnCreateJobPost($filter: ModelSubscriptionJobPostFilterInput) {
    onCreateJobPost(filter: $filter) {
      id
      CompanyPreferenceId
      companyQrewId
      CompanyPreference {
        createdAt
        id
        rolename
        businessUserId
        businessProfileId
        companyQrewId
        companyQrew {
          id
          qrewname
          aboutqrew
          businessProfileId
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Invoices {
            items {
              id
              CtcInvoiceNumber
              PlatformInvoiceNumber
              CompanyQrewId
              businessProfileId
              companyName
              CtcInvoiceS3Key
              PlarformInvoiceS3Key
              CtcTxnNumber
              PlatformTxnNumber
              CtcTxnNumberApproved
              PlatformTxnNumberApproved
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          qrewroles {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          objectives {
            items {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          durationInmonths
          qrewLive
          createdAt
          updatedAt
          owner
          __typename
        }
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        recommendedprofiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        shortlistedProfiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejectedProfiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobPosts {
          items {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedQrew {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobTraits {
          items {
            id
            TraitName
            createdAt
            updatedAt
            preferencesID
            owner
            __typename
          }
          nextToken
          __typename
        }
        package
        certifications
        noticeperiod
        maxExperienceRequired
        minExperienceRequired
        modeofwork
        JobDomain
        other
        previousrole
        combinedrole
        currencyCountry
        JobType
        Location
        JobPublished
        numberOfCandidateRequired
        updatedAt
        owner
        __typename
      }
      companyName
      JobApplications {
        items {
          id
          JobPostId
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          CompanyProfileId
          ProfileId
          CompanyPreferenceId
          companyQrewId
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ApplyingStatus
          Level
          CandidateProfileAnalysis
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      shortlistedCandidates {
        items {
          createdAt
          businessProfileId
          businessUserId
          UserId
          about
          candidateprofileId
          shortlistedbyId
          JobPostId
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          shortlistedByUser {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          recommededId
          preferencesId
          prferenceType {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          id
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          businessProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      jobPostQuestions {
        items {
          id
          JobPostId
          preferencesID
          Question
          TypeOfQuesiton
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      jobPostLink
      logoUrl
      htmldescription
      CompanyDescription
      AvailablityStatus
      experienceRequired
      jobType
      Location
      skills
      domain
      description
      ShortDesc
      RolesandResponsibility
      title
      CapabilitiesRequired {
        items {
          id
          jobPostID
          GlobalCapabilityConfigID
          CapabilityConfig {
            id
            name
            tag
            domain
            createdAt
            weeklyActiveOpportunity
            capabilitiesClaimedCount
            CapabilityPoints
            capabilitiesClaimed {
              nextToken
              __typename
            }
            feeds {
              nextToken
              __typename
            }
            connectedJobPosts {
              nextToken
              __typename
            }
            ActivityStatus
            updatedAt
            owner
            __typename
          }
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      FeedsConnected {
        items {
          id
          UserSegmentId
          HidiId
          Hidi {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          CapabilityConfigId
          jobPostId
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          tags
          domain
          CapabilityConfig {
            id
            name
            tag
            domain
            createdAt
            weeklyActiveOpportunity
            capabilitiesClaimedCount
            CapabilityPoints
            capabilitiesClaimed {
              nextToken
              __typename
            }
            feeds {
              nextToken
              __typename
            }
            connectedJobPosts {
              nextToken
              __typename
            }
            ActivityStatus
            updatedAt
            owner
            __typename
          }
          Description
          Payload
          FeedType
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      CapabilitiesRequiredJSON
      createdAt
      updatedAt
      SavedJobPosts {
        items {
          id
          JobPostId
          ProfileId
          UserId
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onUpdateJobPost = /* GraphQL */ `
  subscription OnUpdateJobPost($filter: ModelSubscriptionJobPostFilterInput) {
    onUpdateJobPost(filter: $filter) {
      id
      CompanyPreferenceId
      companyQrewId
      CompanyPreference {
        createdAt
        id
        rolename
        businessUserId
        businessProfileId
        companyQrewId
        companyQrew {
          id
          qrewname
          aboutqrew
          businessProfileId
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Invoices {
            items {
              id
              CtcInvoiceNumber
              PlatformInvoiceNumber
              CompanyQrewId
              businessProfileId
              companyName
              CtcInvoiceS3Key
              PlarformInvoiceS3Key
              CtcTxnNumber
              PlatformTxnNumber
              CtcTxnNumberApproved
              PlatformTxnNumberApproved
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          qrewroles {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          objectives {
            items {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          durationInmonths
          qrewLive
          createdAt
          updatedAt
          owner
          __typename
        }
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        recommendedprofiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        shortlistedProfiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejectedProfiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobPosts {
          items {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedQrew {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobTraits {
          items {
            id
            TraitName
            createdAt
            updatedAt
            preferencesID
            owner
            __typename
          }
          nextToken
          __typename
        }
        package
        certifications
        noticeperiod
        maxExperienceRequired
        minExperienceRequired
        modeofwork
        JobDomain
        other
        previousrole
        combinedrole
        currencyCountry
        JobType
        Location
        JobPublished
        numberOfCandidateRequired
        updatedAt
        owner
        __typename
      }
      companyName
      JobApplications {
        items {
          id
          JobPostId
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          CompanyProfileId
          ProfileId
          CompanyPreferenceId
          companyQrewId
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ApplyingStatus
          Level
          CandidateProfileAnalysis
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      shortlistedCandidates {
        items {
          createdAt
          businessProfileId
          businessUserId
          UserId
          about
          candidateprofileId
          shortlistedbyId
          JobPostId
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          shortlistedByUser {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          recommededId
          preferencesId
          prferenceType {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          id
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          businessProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      jobPostQuestions {
        items {
          id
          JobPostId
          preferencesID
          Question
          TypeOfQuesiton
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      jobPostLink
      logoUrl
      htmldescription
      CompanyDescription
      AvailablityStatus
      experienceRequired
      jobType
      Location
      skills
      domain
      description
      ShortDesc
      RolesandResponsibility
      title
      CapabilitiesRequired {
        items {
          id
          jobPostID
          GlobalCapabilityConfigID
          CapabilityConfig {
            id
            name
            tag
            domain
            createdAt
            weeklyActiveOpportunity
            capabilitiesClaimedCount
            CapabilityPoints
            capabilitiesClaimed {
              nextToken
              __typename
            }
            feeds {
              nextToken
              __typename
            }
            connectedJobPosts {
              nextToken
              __typename
            }
            ActivityStatus
            updatedAt
            owner
            __typename
          }
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      FeedsConnected {
        items {
          id
          UserSegmentId
          HidiId
          Hidi {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          CapabilityConfigId
          jobPostId
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          tags
          domain
          CapabilityConfig {
            id
            name
            tag
            domain
            createdAt
            weeklyActiveOpportunity
            capabilitiesClaimedCount
            CapabilityPoints
            capabilitiesClaimed {
              nextToken
              __typename
            }
            feeds {
              nextToken
              __typename
            }
            connectedJobPosts {
              nextToken
              __typename
            }
            ActivityStatus
            updatedAt
            owner
            __typename
          }
          Description
          Payload
          FeedType
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      CapabilitiesRequiredJSON
      createdAt
      updatedAt
      SavedJobPosts {
        items {
          id
          JobPostId
          ProfileId
          UserId
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onDeleteJobPost = /* GraphQL */ `
  subscription OnDeleteJobPost($filter: ModelSubscriptionJobPostFilterInput) {
    onDeleteJobPost(filter: $filter) {
      id
      CompanyPreferenceId
      companyQrewId
      CompanyPreference {
        createdAt
        id
        rolename
        businessUserId
        businessProfileId
        companyQrewId
        companyQrew {
          id
          qrewname
          aboutqrew
          businessProfileId
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Invoices {
            items {
              id
              CtcInvoiceNumber
              PlatformInvoiceNumber
              CompanyQrewId
              businessProfileId
              companyName
              CtcInvoiceS3Key
              PlarformInvoiceS3Key
              CtcTxnNumber
              PlatformTxnNumber
              CtcTxnNumberApproved
              PlatformTxnNumberApproved
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          qrewroles {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          objectives {
            items {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          durationInmonths
          qrewLive
          createdAt
          updatedAt
          owner
          __typename
        }
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        recommendedprofiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        shortlistedProfiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejectedProfiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobPosts {
          items {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedQrew {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobTraits {
          items {
            id
            TraitName
            createdAt
            updatedAt
            preferencesID
            owner
            __typename
          }
          nextToken
          __typename
        }
        package
        certifications
        noticeperiod
        maxExperienceRequired
        minExperienceRequired
        modeofwork
        JobDomain
        other
        previousrole
        combinedrole
        currencyCountry
        JobType
        Location
        JobPublished
        numberOfCandidateRequired
        updatedAt
        owner
        __typename
      }
      companyName
      JobApplications {
        items {
          id
          JobPostId
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          CompanyProfileId
          ProfileId
          CompanyPreferenceId
          companyQrewId
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ApplyingStatus
          Level
          CandidateProfileAnalysis
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      shortlistedCandidates {
        items {
          createdAt
          businessProfileId
          businessUserId
          UserId
          about
          candidateprofileId
          shortlistedbyId
          JobPostId
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          shortlistedByUser {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          recommededId
          preferencesId
          prferenceType {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          id
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          businessProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      jobPostQuestions {
        items {
          id
          JobPostId
          preferencesID
          Question
          TypeOfQuesiton
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      jobPostLink
      logoUrl
      htmldescription
      CompanyDescription
      AvailablityStatus
      experienceRequired
      jobType
      Location
      skills
      domain
      description
      ShortDesc
      RolesandResponsibility
      title
      CapabilitiesRequired {
        items {
          id
          jobPostID
          GlobalCapabilityConfigID
          CapabilityConfig {
            id
            name
            tag
            domain
            createdAt
            weeklyActiveOpportunity
            capabilitiesClaimedCount
            CapabilityPoints
            capabilitiesClaimed {
              nextToken
              __typename
            }
            feeds {
              nextToken
              __typename
            }
            connectedJobPosts {
              nextToken
              __typename
            }
            ActivityStatus
            updatedAt
            owner
            __typename
          }
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      FeedsConnected {
        items {
          id
          UserSegmentId
          HidiId
          Hidi {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          CapabilityConfigId
          jobPostId
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          tags
          domain
          CapabilityConfig {
            id
            name
            tag
            domain
            createdAt
            weeklyActiveOpportunity
            capabilitiesClaimedCount
            CapabilityPoints
            capabilitiesClaimed {
              nextToken
              __typename
            }
            feeds {
              nextToken
              __typename
            }
            connectedJobPosts {
              nextToken
              __typename
            }
            ActivityStatus
            updatedAt
            owner
            __typename
          }
          Description
          Payload
          FeedType
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      CapabilitiesRequiredJSON
      createdAt
      updatedAt
      SavedJobPosts {
        items {
          id
          JobPostId
          ProfileId
          UserId
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onCreateJoinGlobalCapabilityconfigAndJobPost = /* GraphQL */ `
  subscription OnCreateJoinGlobalCapabilityconfigAndJobPost(
    $filter: ModelSubscriptionJoinGlobalCapabilityconfigAndJobPostFilterInput
  ) {
    onCreateJoinGlobalCapabilityconfigAndJobPost(filter: $filter) {
      id
      jobPostID
      GlobalCapabilityConfigID
      CapabilityConfig {
        id
        name
        tag
        domain
        createdAt
        weeklyActiveOpportunity
        capabilitiesClaimedCount
        CapabilityPoints
        capabilitiesClaimed {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        feeds {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        connectedJobPosts {
          items {
            id
            jobPostID
            GlobalCapabilityConfigID
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivityStatus
        updatedAt
        owner
        __typename
      }
      jobPost {
        id
        CompanyPreferenceId
        companyQrewId
        CompanyPreference {
          createdAt
          id
          rolename
          businessUserId
          businessProfileId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          recommendedprofiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejectedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobPosts {
            items {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobTraits {
            items {
              id
              TraitName
              createdAt
              updatedAt
              preferencesID
              owner
              __typename
            }
            nextToken
            __typename
          }
          package
          certifications
          noticeperiod
          maxExperienceRequired
          minExperienceRequired
          modeofwork
          JobDomain
          other
          previousrole
          combinedrole
          currencyCountry
          JobType
          Location
          JobPublished
          numberOfCandidateRequired
          updatedAt
          owner
          __typename
        }
        companyName
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        shortlistedCandidates {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        jobPostQuestions {
          items {
            id
            JobPostId
            preferencesID
            Question
            TypeOfQuesiton
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        jobPostLink
        logoUrl
        htmldescription
        CompanyDescription
        AvailablityStatus
        experienceRequired
        jobType
        Location
        skills
        domain
        description
        ShortDesc
        RolesandResponsibility
        title
        CapabilitiesRequired {
          items {
            id
            jobPostID
            GlobalCapabilityConfigID
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        FeedsConnected {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CapabilitiesRequiredJSON
        createdAt
        updatedAt
        SavedJobPosts {
          items {
            id
            JobPostId
            ProfileId
            UserId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateJoinGlobalCapabilityconfigAndJobPost = /* GraphQL */ `
  subscription OnUpdateJoinGlobalCapabilityconfigAndJobPost(
    $filter: ModelSubscriptionJoinGlobalCapabilityconfigAndJobPostFilterInput
  ) {
    onUpdateJoinGlobalCapabilityconfigAndJobPost(filter: $filter) {
      id
      jobPostID
      GlobalCapabilityConfigID
      CapabilityConfig {
        id
        name
        tag
        domain
        createdAt
        weeklyActiveOpportunity
        capabilitiesClaimedCount
        CapabilityPoints
        capabilitiesClaimed {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        feeds {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        connectedJobPosts {
          items {
            id
            jobPostID
            GlobalCapabilityConfigID
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivityStatus
        updatedAt
        owner
        __typename
      }
      jobPost {
        id
        CompanyPreferenceId
        companyQrewId
        CompanyPreference {
          createdAt
          id
          rolename
          businessUserId
          businessProfileId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          recommendedprofiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejectedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobPosts {
            items {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobTraits {
            items {
              id
              TraitName
              createdAt
              updatedAt
              preferencesID
              owner
              __typename
            }
            nextToken
            __typename
          }
          package
          certifications
          noticeperiod
          maxExperienceRequired
          minExperienceRequired
          modeofwork
          JobDomain
          other
          previousrole
          combinedrole
          currencyCountry
          JobType
          Location
          JobPublished
          numberOfCandidateRequired
          updatedAt
          owner
          __typename
        }
        companyName
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        shortlistedCandidates {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        jobPostQuestions {
          items {
            id
            JobPostId
            preferencesID
            Question
            TypeOfQuesiton
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        jobPostLink
        logoUrl
        htmldescription
        CompanyDescription
        AvailablityStatus
        experienceRequired
        jobType
        Location
        skills
        domain
        description
        ShortDesc
        RolesandResponsibility
        title
        CapabilitiesRequired {
          items {
            id
            jobPostID
            GlobalCapabilityConfigID
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        FeedsConnected {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CapabilitiesRequiredJSON
        createdAt
        updatedAt
        SavedJobPosts {
          items {
            id
            JobPostId
            ProfileId
            UserId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteJoinGlobalCapabilityconfigAndJobPost = /* GraphQL */ `
  subscription OnDeleteJoinGlobalCapabilityconfigAndJobPost(
    $filter: ModelSubscriptionJoinGlobalCapabilityconfigAndJobPostFilterInput
  ) {
    onDeleteJoinGlobalCapabilityconfigAndJobPost(filter: $filter) {
      id
      jobPostID
      GlobalCapabilityConfigID
      CapabilityConfig {
        id
        name
        tag
        domain
        createdAt
        weeklyActiveOpportunity
        capabilitiesClaimedCount
        CapabilityPoints
        capabilitiesClaimed {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        feeds {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        connectedJobPosts {
          items {
            id
            jobPostID
            GlobalCapabilityConfigID
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivityStatus
        updatedAt
        owner
        __typename
      }
      jobPost {
        id
        CompanyPreferenceId
        companyQrewId
        CompanyPreference {
          createdAt
          id
          rolename
          businessUserId
          businessProfileId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          recommendedprofiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejectedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobPosts {
            items {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobTraits {
            items {
              id
              TraitName
              createdAt
              updatedAt
              preferencesID
              owner
              __typename
            }
            nextToken
            __typename
          }
          package
          certifications
          noticeperiod
          maxExperienceRequired
          minExperienceRequired
          modeofwork
          JobDomain
          other
          previousrole
          combinedrole
          currencyCountry
          JobType
          Location
          JobPublished
          numberOfCandidateRequired
          updatedAt
          owner
          __typename
        }
        companyName
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        shortlistedCandidates {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        jobPostQuestions {
          items {
            id
            JobPostId
            preferencesID
            Question
            TypeOfQuesiton
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        jobPostLink
        logoUrl
        htmldescription
        CompanyDescription
        AvailablityStatus
        experienceRequired
        jobType
        Location
        skills
        domain
        description
        ShortDesc
        RolesandResponsibility
        title
        CapabilitiesRequired {
          items {
            id
            jobPostID
            GlobalCapabilityConfigID
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        FeedsConnected {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CapabilitiesRequiredJSON
        createdAt
        updatedAt
        SavedJobPosts {
          items {
            id
            JobPostId
            ProfileId
            UserId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateTrending = /* GraphQL */ `
  subscription OnCreateTrending($filter: ModelSubscriptionTrendingFilterInput) {
    onCreateTrending(filter: $filter) {
      id
      Domain
      CapabilityConfig {
        id
        name
        tag
        domain
        createdAt
        weeklyActiveOpportunity
        capabilitiesClaimedCount
        CapabilityPoints
        capabilitiesClaimed {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        feeds {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        connectedJobPosts {
          items {
            id
            jobPostID
            GlobalCapabilityConfigID
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivityStatus
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      trendingCapabilityConfigId
      owner
      __typename
    }
  }
`;
export const onUpdateTrending = /* GraphQL */ `
  subscription OnUpdateTrending($filter: ModelSubscriptionTrendingFilterInput) {
    onUpdateTrending(filter: $filter) {
      id
      Domain
      CapabilityConfig {
        id
        name
        tag
        domain
        createdAt
        weeklyActiveOpportunity
        capabilitiesClaimedCount
        CapabilityPoints
        capabilitiesClaimed {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        feeds {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        connectedJobPosts {
          items {
            id
            jobPostID
            GlobalCapabilityConfigID
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivityStatus
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      trendingCapabilityConfigId
      owner
      __typename
    }
  }
`;
export const onDeleteTrending = /* GraphQL */ `
  subscription OnDeleteTrending($filter: ModelSubscriptionTrendingFilterInput) {
    onDeleteTrending(filter: $filter) {
      id
      Domain
      CapabilityConfig {
        id
        name
        tag
        domain
        createdAt
        weeklyActiveOpportunity
        capabilitiesClaimedCount
        CapabilityPoints
        capabilitiesClaimed {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        feeds {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        connectedJobPosts {
          items {
            id
            jobPostID
            GlobalCapabilityConfigID
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivityStatus
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      trendingCapabilityConfigId
      owner
      __typename
    }
  }
`;
