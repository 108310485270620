import React from 'react'
import { API } from 'aws-amplify'
import { useEffect } from 'react'
import useUserStore from '../Userstore/UserStore'
import { list_JobPost_by_Domain } from '../graphql/queries'

const GetOpportunities = () => {
	const domain = useUserStore((state) => state.domain)
	const setJobDetails = useUserStore((state) => state.setJobDetails)

	const getJobDetails = async () => {
		try {
			const response = await API.graphql({
				query: list_JobPost_by_Domain,
				variables: {
					domain: domain,
					AvailablityStatus: {
						eq: 'true',
					},
					sortDirection: 'DESC',
				},
			})
			setJobDetails(response?.data?.list_JobPost_by_Domain?.items)
		} catch (error) {
			// console.error('Error fetching job details:', error)
		}
	}

	useEffect(() => {
		getJobDetails()
	}, [domain])
}

export default GetOpportunities
