/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
	aws_project_region: 'us-east-1',
	aws_appsync_graphqlEndpoint:
		'https://vi6sxxg2ojf73ndjde7nounayi.appsync-api.us-east-1.amazonaws.com/graphql',
	aws_appsync_region: 'us-east-1',
	aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
	aws_appsync_apiKey: 'da2-t5tkmdcerjh2jciehdld5bbdhu',
	aws_cognito_identity_pool_id:
		'us-east-1:b10646bf-ae76-4204-a5ab-374c99590c40',
	aws_cognito_region: 'us-east-1',
	aws_user_pools_id: 'us-east-1_cqvEWOGut',
	aws_user_pools_web_client_id: '5mg4pfbq3gg5nnlf3mgtrdm6sk',
	oauth: {
		domain: 'qapp39e8df0df-9e8df0df-dev.auth.us-east-1.amazoncognito.com',
		scope: [
			'phone',
			'email',
			'openid',
			'profile',
			'aws.cognito.signin.user.admin',
		],
		redirectSignIn: 'https://app.qrusible.com/',
		redirectSignOut: 'https://app.qrusible.com/',
		responseType: 'code',
	},
	federationTarget: 'COGNITO_USER_POOLS',
	aws_cognito_username_attributes: ['EMAIL'],
	aws_cognito_social_providers: ['GOOGLE'],
	aws_cognito_signup_attributes: ['EMAIL'],
	aws_cognito_mfa_configuration: 'OFF',
	aws_cognito_mfa_types: ['SMS'],
	aws_cognito_password_protection_settings: {
		passwordPolicyMinLength: 8,
		passwordPolicyCharacters: [],
	},
	aws_cognito_verification_mechanisms: ['EMAIL'],
	aws_user_files_s3_bucket: 'qpublicimages112040-dev',
	aws_user_files_s3_bucket_region: 'us-east-1',
	Analytics: {
		AWSPinpoint: {
			appId: '99949725a86d4917bb5cdb167379bf8c',
			region: 'us-east-1',
		},
	},
}

export default awsmobile