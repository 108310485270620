import React, { useState, useCallback } from 'react'
import { Dimensions } from 'react-native'
import {
	Box,
	FlatList,
	HStack,
	Heading,
	Pressable,
	Text,
	Divider,
} from '@gluestack-ui/themed'

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height
const TrendingComponent = ({ TrendsData, modalShow, setModalShow }) => {
	const renderItem = ({ item, index }) => {
		return (
			<Pressable
				onPress={() => setModalShow(true)}
				flexWrap="wrap"
				w={'100%'}
				marginBottom={10}
			>
				<Box>
					<Text
						fontFamily="$fontHeading"
						fontWeight="500"
						color="$primary950"
						fontSize={
							windowWidth > 480 ? '$fontSize15' : '$fontSize12'
						}
						flexWrap="wrap"
					>
						{item?.CapabilityConfig?.name}
					</Text>
					<HStack flex={1}>
						<Text
							fontFamily="$fontContent"
							fontSize={windowWidth > 480 ? '$fontSize12' : 10}
							flex={1}
						>
							Current Active Opportunities for Capability:{' '}
							{item?.CapabilityConfig?.weeklyActiveOpportunity}
						</Text>
					</HStack>
				</Box>
			</Pressable>
		)
	}

	return (
		<Box
			bg="$primary0"
			height={'fit-content'}
			p={windowWidth > 480 ? 20 : 10}
			borderRadius={6}
			flexWrap="wrap"
			borderWidth={1}
			borderColor="$borderDark0"
		>
			<Box>
				<Text
					fontFamily="$fontContent"
					fontSize={windowWidth > 480 ? '$fontSize12' : 10}
					fontWeight="700"
					lineHeight={15}
				>
					Capabilities in demand
				</Text>
			</Box>
			<Divider h={0.5} marginVertical={5} />
			<Box marginVertical={10}>
				<FlatList
					data={TrendsData}
					renderItem={renderItem}
					keyExtractor={(item) => item.id.toString()}
				/>
			</Box>
		</Box>
	)
}

export default TrendingComponent
