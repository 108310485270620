import React from 'react'
import {
	Box,
	HStack,
	Image,
	Pressable,
	Text,
	FlatList,
} from '@gluestack-ui/themed'

import { Dimensions } from 'react-native'

const windowHeight = Dimensions.get('window').height
const windowWidth = Dimensions.get('window').width

const MyOppRenderComponent = ({
	onPress,
	companyName,
	jobTitle,
	modeofwork,
	applyingStatus,
	level,
	rowFD,
	colFD,
}) => {
	let logoUrl =
		'https://logo.clearbit.com/' +
		companyName?.split(' ')[0].trim() +
		'.com'

	return (
		<Pressable
			flexWrap="wrap"
			w={!colFD && '100%'}
			bg="$primary0"
			paddingHorizontal={windowWidth > 480 ? 20 : 10}
			paddingVertical={windowWidth > 480 ? 20 : 10}
			flexDirection="row"
			justifyContent="space-between"
			// borderBottomWidth={1}
			borderColor="$borderDark0"
			onPress={onPress}
			borderRadius={10}
			alignItems="center"
		>
			<Box
				flexDirection={colFD ? 'column' : 'row'}
				gap={10}
				w={!colFD ? '100%' : '$full'}
				// w={200}
				borderRadius={10}
				flex={windowWidth > 480 ? !colFD && 1 : null}
			>
				<Box alignItems={colFD && 'center'}>
					<Image
						h={40}
						w={40}
						source={{ uri: logoUrl }}
						alt="company logo"
						borderRadius={6}
						defaultSource={require('../assets/Images/experience.png')}
					/>
				</Box>
				<Box
					flex={windowWidth > 480 ? !colFD && 1 : null}
					w={!colFD ? '80%' : '100%'}
					alignItems={colFD && 'center'}
					gap={colFD && 5}
				>
					<Text
						fontFamily="$fontHeading"
						fontSize={'$fontSize15'}
						flexWrap="wrap"
						fontWeight="700"
						textAlign={colFD && 'center'}
					>
						{jobTitle}
					</Text>
					<Text
						fontFamily="$fontHeading"
						color="$fontGray100"
						fontSize={
							windowWidth > 480
								? '$fontContent'
								: '$fontContentMobile'
						}
						fontWeight="500"
						lineHeight={17}
						flexWrap="wrap"
					>
						{companyName}
					</Text>
					<Text
						fontFamily="$fontHeading"
						color="$fontGray"
						fontSize={
							windowWidth > 480
								? '$fontContent'
								: '$fontContentMobile'
						}
						fontWeight="500"
						lineHeight={17}
					>
						{modeofwork}
					</Text>
				</Box>
			</Box>
			{!colFD && (
				<Box marginTop={windowWidth < 480 ? 10 : 0}>
					{level < 4 && (
						<Text
							flexWrap="wrap"
							fontFamily="$fontHeading"
							color="$primary0"
							fontSize={
								windowWidth > 480
									? '$fontContent'
									: '$fontContentMobile'
							}
							fontWeight="$bold"
							paddingHorizontal={10}
							paddingVertical={3}
							backgroundColor="$primary500"
							borderRadius={6}
						>
							Improve Applications
						</Text>
					)}
					{level === 4 && (
						<Text
							fontFamily="$fontHeading"
							bg="#039A00"
							fontSize={'$fontSize13'}
							fontWeight="$bold"
							color="#D8EFD9"
							paddingHorizontal={10}
							paddingVertical={3}
							borderRadius={6}
						>
							Application Complete
						</Text>
					)}
				</Box>
			)}
		</Pressable>
	)
}

export default MyOppRenderComponent
