import React from 'react'
import {
	Box,
	Divider,
	HStack,
	ScrollView,
	Text,
	Pressable,
	FlatList,
	Image,
} from '@gluestack-ui/themed'
import { Dimensions } from 'react-native'
import { OpenHidiPublicView } from '../../../HeplerFunctions/OpenHidiView'
const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height
export default function ProfileAnalysisComponent({ props, preferencesId }) {
	const ProfileAnalysisObject = JSON.parse(props)

	const renderItem = ({ item }) => {
		return (
			<Box marginBottom={25} flexDirection="row">
				{(item.Found == true || item.Found == 'true') ? (
					<Box marginTop={3}>
						<Image
							height={25}
							width={25}
							source={require('../../../assets/Images/approved.png')}
						/>
					</Box>
				) : (
					<Box
						borderRadius={5}
						marginTop={3}
						// backgroundColor='#FFFCE2'
					>
						<Image
							height={25}
							width={25}
							source={require('../../../assets/Images/cancel.png')}
						/>
					</Box>
				)}

				<Box width={'90%'} marginLeft={10}>
					<HStack justifyContent="space-between">
						<Text
							fontFamily="$fontHeading"
							fontSize={
								windowWidth > 480
									? '$fontContent'
									: '$fontContentMobile'
							}
							fontWeight="700"
						>
							{item.traitname}
						</Text>
						{(item.Found == true || item.Found == 'true') && (
							<Pressable
								onPress={() =>
								
									OpenHidiPublicView(
										item?.ExperienceId,
										preferencesId
									)
								
							}
							>
								<Text
									fontFamily="$fontHeading"
									fontSize="17"
									fontWeight="700"
									color="red"
								>
									Check Evidence
								</Text>
							</Pressable>
						)}
					</HStack>

					<Text
						fontFamily="$fontContent"
						fontSize={
							windowWidth > 480
								? '$fontContent'
								: '$fontContentMobile'
						}
						fontWeight="700"
						color={'$grey100'}
					>
						{item.Found == true || item.Found == 'true'
							? item.ChatGptExplanations
							: 'No evidence found'}
					</Text>
				</Box>
			</Box>
		)
	}
	return (
		<Box>
			<Text
				marginBottom={15}
				fontFamily="$fontHeading"
				fontWeight="600"
				fontSize={windowWidth > 480 ? 20 : 15}
				color="$primary950"
			>
				Candidate Traits
			</Text>
			<FlatList
				data={ProfileAnalysisObject}
				renderItem={renderItem}
				keyExtractor={(index) => index.toString()}
			/>
		</Box>
	)
}
