import React, { useState } from 'react'
import {
	Modal,
	ModalBackdrop,
	ModalBody,
	ModalContent,
	ModalCloseButton,
	ModalFooter,
	ModalHeader,
	Heading,
	Text,
	Box,
	Pressable,
	VStack,
	HStack,
	Icon,
	Image,
	AddIcon,
	CheckIcon,
	CloseIcon,
} from '@gluestack-ui/themed'

import { Dimensions } from 'react-native'
import ButtonComponent from '../../../newComponents/ButtonComponent'

import { useEffect } from 'react'

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

function RemoveCompanyCapabilityModal({ isOpen, onClose, onDelete }) {
	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			position="fixed"
			minHeight={windowHeight}
		>
			<ModalBackdrop />
			<ModalContent>
				<ModalHeader>
					<Box flex={1}>
						<Heading
							fontFamily="$fontHeading"
							fontSize={'$fontSize18'}
							lineHeight={22}
						>
							Removing capability?
						</Heading>
						<Text
							fontFamily="$fontContent"
							fontSize={'$fontSize12'}
							fontWeight="bold"
						>
							Tip: More capabilities will give you better
							opportunities
						</Text>
					</Box>
					<ModalCloseButton>
						<Icon as={CloseIcon} />
					</ModalCloseButton>
				</ModalHeader>
				<ModalBody p={0}>
					<Box></Box>
					<HStack
						justifyContent="flex-end"
						paddingHorizontal={20}
						gap={10}
						marginVertical={20}
					>
						<ButtonComponent
							text={'Cancel'}
							customFontSize={12}
							color={'$primary0'}
							textColor={'$primary500'}
							onPress={() => onClose()}
						/>
						<ButtonComponent
							text={'Remove'}
							customFontSize={12}
							onPress={() => {
								onDelete()
								onClose()
							}}
						/>
					</HStack>
				</ModalBody>
			</ModalContent>
		</Modal>
	)
}

export default RemoveCompanyCapabilityModal
