import { API } from 'aws-amplify'
import { ResumeParser } from '../../../graphql/queries'

const parseResume = async (userId) => {
	try {
		const response = await API.graphql({
			query: ResumeParser,
			variables: {
				msg: userId,
			},
		})

		console.log('resumeparsing successfull', response)
		return response.data.resumeParser
	} catch (error) {
		console.log('Error parsing resume', error)
	}
}

export { parseResume }
