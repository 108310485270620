import React from 'react'
import { Dimensions } from 'react-native'
import {
	Box,
	EditIcon,
	HStack,
	Heading,
	Icon,
	Image,
	Link,
	Pressable,
	Text,
} from '@gluestack-ui/themed'
const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height
const NewHIdiSmallComponent = ({
	field,
	text,
	inModal,
	sharedLink,
	onPressIcon,
	problem,
	fillerText,
	experience,
	exampleView,
}) => {
	const expereinceWordCount =
		experience?.split(/\s+/)?.filter((word) => word !== '')?.length < 20
	return (
		<Pressable
			marginBottom={5}
			disabled={problem === '' || expereinceWordCount || exampleView}
			marginTop={5}
			onPress={onPressIcon}
		>
			<Text
				fontFamily="$fontHeading"
				fontSize={windowWidth > 480 ? 12 : 11}
				color={'$fontGray'}
				fontWeight="500"
			>
				{field}
			</Text>
			<HStack alignItems="center" gap={windowWidth > 480 ? 5 : 2}>
				<Box flex={1}>
					{sharedLink ? (
						<Pressable
							onPress={() => {
								const completeURL =
									text.startsWith('http') ||
									text.startsWith('https')
										? text
										: `https://${text}`

								text === '' || text === null
									? onPressIcon()
									: window.open(completeURL, '_blank')
							}}
						>
							<Text
								fontFamily={'$fontHeading'}
								fontSize={windowWidth > 480 ? 16 : 15}
								color={
									text === '' || text === null
										? '#E2E2E2'
										: '#246BFD'
								}
								lineHeight={20}
							>
								{inModal
									? text === '' || text === null
										? 'Information not shared; added details will appear here.'
										: text
									: text}
							</Text>
						</Pressable>
					) : (
						<Text
							fontFamily={'$fontHeading'}
							fontSize={windowWidth > 480 ? 16 : 15}
							color={
								text === '' || text === null
									? '#E2E2E2'
									: '#212121'
							}
							lineHeight={20}
						>
							{inModal
								? text === '' || text === null
									? fillerText
									: text
								: text}
						</Text>
					)}
				</Box>
				<Box>
					{inModal &&
						text !== '' &&
						text !== null &&
						!exampleView && (
							<Pressable onPress={onPressIcon}>
								<Image
									source={require('../assets/Images/edit.png')}
									h={18}
									w={18}
								/>
							</Pressable>
						)}
				</Box>
			</HStack>
		</Pressable>
	)
}

export default NewHIdiSmallComponent
