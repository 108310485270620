import {
	StyleSheet,
	Text,
	View,
	TextInput,
	TouchableHighlight,
	Image,
	Alert,
	ActivityIndicator,
	Dimensions,
	ScrollView,
} from 'react-native'
import React, { useEffect, useState } from 'react'
import { TouchableOpacity } from 'react-native-web'
import { HStack, Button } from 'native-base'
import { Auth, auth0SignInButton } from 'aws-amplify'
import AsyncStorage from '@react-native-async-storage/async-storage'
import UserAvatar from 'react-native-user-avatar'
import useUserStore from '../Userstore/UserStore'
import { getNewprofile } from '../graphql/queries'
import { updateNewprofile } from '../graphql/mutations'
import { API, Storage } from 'aws-amplify'
const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height
import EditProfile from '../newComponents/editProfile/EditProfile'
import CandidatePreferences from '../newComponents/CandidatePreferences'
export default function ProfileSettings() {
	const [screenstate, setScreenState] = useState('edit')
	const profile = useUserStore((state) => state.profile)
	return (
		<View style={{ marginTop: 65 }}>
			<HStack style={styles.secondheader}>
				<TouchableOpacity onPress={() => setScreenState('edit')}>
					<Text
						style={
							screenstate === 'edit'
								? styles.activeText
								: styles.inactiveText
						}
					>
						Edit Profile
					</Text>
				</TouchableOpacity>
				<View style={styles.separator} />
				<TouchableOpacity onPress={() => setScreenState('preferences')}>
					<Text
						style={
							screenstate === 'preferences'
								? styles.activeText
								: styles.inactiveText
						}
					>
						Preferences
					</Text>
				</TouchableOpacity>
			</HStack>
			{screenstate == 'edit' && (
				<View style={styles.container}>
					<EditProfile />
				</View>
			)}
			{screenstate == 'preferences' && (
				<View
					style={{
						width: windowWidth * 0.9,
						alignSelf: 'center',
						marginTop: 10,
					}}
				>
					<CandidatePreferences />
				</View>
			)}
		</View>
	)
}

const styles = StyleSheet.create({
	secondheader: {
		width: windowWidth <= 480 ? '90%' : '80%',
		height: 50,
		// borderWidth: 2,
		alignSelf: 'center',
		borderRadius: 10,
		alignContent: 'center',
		alignItems: 'center',
		marginTop: 20,
		justifyContent: 'space-evenly',
		borderColor: 'grey',
		backgroundColor: '#f9f9f9',
		shadowColor: '#000',
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowOpacity: 0.2,
		shadowRadius: 3,
		elevation: 2,
	},
	// secondheader: {
	//   width: "90%",
	//   height: 50,
	//   alignSelf: "center",
	//   marginTop: 20,
	//   flexDirection: "row",
	//   justifyContent: "space-evenly",

	//   borderRadius: 10,

	// },

	activeText: {
		fontSize: 16,
		fontFamily: 'Lexend',
		color: '#007BFF',
	},

	inactiveText: {
		fontSize: 16,
		fontFamily: 'Lexend',
		color: '#6C757D',
	},

	separator: {
		width: 2,
		height: '85%',
		backgroundColor: '#CED4DA',
	},
	container: {
		borderWidth: 1,
		width: windowWidth <= 480 ? '90%' : '80%',
		alignSelf: 'center',
		marginTop: 20,
		backgroundColor: '#FFFFFF',
		borderRadius: 10,
		shadowColor: '#000',
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowOpacity: 0.1,
		shadowRadius: 3,
		elevation: 2,
		borderColor: '#f9f9f9',
	},
})
