
import React from 'react'
import { useEffect, useRef, useState } from 'react'
import { Dimensions, Animated } from 'react-native'
import ProgressBar from '../newComponents/ProgressBar'

import { Text, Box, View } from '@gluestack-ui/themed'
export default function ProgressBarQrew({percentageValue}) {
    const progressValue = percentageValue
    const fadeAnim = useRef(new Animated.Value(1)).current
    const [showProgress, setShowProgress] = useState(true)
    useEffect(() => {
		if (progressValue === 100) {
			Animated.timing(fadeAnim, {
				toValue: 0,
				duration: 4500,
				useNativeDriver: true,
			}).start(() => setShowProgress(false))
		} else if (progressValue === 0) {
			setShowProgress(true)
			Animated.timing(fadeAnim, {
				toValue: 1,
				duration: 1000,
				useNativeDriver: true,
			}).start()
		} else {
			setShowProgress(true)
			Animated.timing(fadeAnim, {
				toValue: 1,
				duration: 1000,
				useNativeDriver: true,
			}).start()
		}
	}, [progressValue, fadeAnim])
  return (
    <View>
     <Animated.View style={{ opacity: fadeAnim }}>
							<Box gap={10}>
								<Text
									fontFamily="$fontHeading"
									fontWeight="600"
									fontSize={12}
								>
									{progressValue}% complete
								</Text>
								<ProgressBar
									animationType={'timing'}
									progress={progressValue}
									bgColor={'#72A2AB'}
                                    heightPBar={10}
									bgPlaceHolder={'#CFDEE8'}
								/>
							</Box>
						</Animated.View>
    </View>
  )
}