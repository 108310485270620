import { Box, Text, Pressable, Image } from '@gluestack-ui/themed'
import { useNavigation } from '@react-navigation/native'
import React, { useEffect, useState } from 'react'
import { Dimensions } from 'react-native'
import useUserStore from '../../Userstore/UserStore'

const windowWidth = Dimensions.get('window').width

const SideNavBar = () => {
	const screenSideNav = useUserStore((state) => state.screenSideNav)
	const setScreenSideNav = useUserStore((state) => state.setScreenSideNav)
	const setSavedUrl = useUserStore((state) => state.setSavedUrl)
	const [activeTab, setActiveTab] = useState(screenSideNav)
	const profile =useUserStore(state => (state.profile))
	useEffect(() => {
		setActiveTab(screenSideNav)
	}, [screenSideNav])
	// const tabs = ['Home', 'Journey', 'Opportunities', 'Profile']

	// const navScreens = ['Feed', 'Journey', 'Opportunities', 'Profile']

	// const imgGreen = ['homeGreen', 'compassGreen', 'rocketGreen', 'userGreen']
	// const imgWhite = ['homeWhite', 'compassWhite', 'rocketWhite', 'userWhite']

	const tabs = ['Home', 'Profile', 'Your Qrew']

	const navScreens = ['Feed', 'Profile', 'YourQrew']

	const imgGreen = ['homeGreen', 'userGreen', 'userGreen' ]
	const imgWhite = ['homeWhite', 'userWhite', 'userWhite']

	const navigation = useNavigation()

	const handleTabClick = (tab) => {
		setActiveTab(tab)
		setScreenSideNav(tab)
	}


	const ScreenContainer = ({ tabs }) => {
		return (
			<Box
				w={windowWidth > 750 ? 200 : null}
				justifyContent={windowWidth > 750 ? 'center' : 'space-around'}
				flexDirection={windowWidth > 750 ? 'column' : 'row'}
				marginVertical={windowWidth > 750 ? 20 : null}
				gap={windowWidth > 750 ? 20 : null}
			>
				{tabs.map((tab, index) => (
					
					<>
						{profile?.deployedforqrews?.items?.length > 0 && tab == 'Your Qrew' ?
						(<Pressable
							key={tab}
							onPress={() => {
								handleTabClick(tab)
							
								navigation.navigate(navScreens[index])
							}}
							paddingHorizontal={windowWidth > 750 ? 20 : null}
							paddingVertical={windowWidth > 750 ? 10 : null}
							alignItems="center"
							borderColor={
								tab === activeTab ? '$borderDark100' : '$primary0'
							}
							// borderRadius={6}
							// borderWidth={2}
							bg={
								windowWidth > 750
									? null
									: tab === activeTab && '$primary0'
							}
						>
							<Box
								flexDirection="row"
								justifyContent="flex-start"
								alignItems="center"
								gap={10}
								w={'$full'}
							>
								<Box
									padding={5}
									// borderWidth={2}
									// borderColor="$primary500"
									// borderRadius={6}
									// bg={
									// 	tab === activeTab
									// 		? '$primary0'
									// 		: '$primary0'
									// }
									bg="$primary0"
									w={windowWidth > 750 ? null : '100%'}
								>
									<Image
										h={
											tab === activeTab
												? windowWidth > 480
													? 30
													: 30
												: 30
										}
										w={
											tab === activeTab
												? windowWidth > 480
													? 30
													: 30
												: 30
										}
										source={
											tab === activeTab
												? require(`../../assets/Images/${imgGreen[index]}.png`)
												: require(`../../assets/Images/${imgWhite[index]}.png`)
										}
									/>
								</Box>
								{windowWidth > 750 ? (
									<Text
										fontFamily="$fontHeading"
										fontWeight="$bold"
										fontSize={
											tab === activeTab
												? '$fontSize16'
												: '$fontSize13'
										}
										color={
											tab === activeTab
												? '$primary500'
												: undefined
										}
									>
										{tab}
									</Text>
								) : null}
							</Box>
						</Pressable>) :
						(
							<>
							{tab  == 'Your Qrew' ? (null) :(
								<Pressable
								key={tab}
								onPress={() => {
									handleTabClick(tab)
								
									navigation.navigate(navScreens[index])
								}}
								paddingHorizontal={windowWidth > 750 ? 20 : null}
								paddingVertical={windowWidth > 750 ? 10 : null}
								alignItems="center"
								borderColor={
									tab === activeTab ? '$borderDark100' : '$primary0'
								}
								// borderRadius={6}
								// borderWidth={2}
								bg={
									windowWidth > 750
										? null
										: tab === activeTab && '$primary0'
								}
							>
								<Box
									flexDirection="row"
									justifyContent="flex-start"
									alignItems="center"
									gap={10}
									w={'$full'}
								>
									<Box
										padding={5}
										// borderWidth={2}
										// borderColor="$primary500"
										// borderRadius={6}
										// bg={
										// 	tab === activeTab
										// 		? '$primary0'
										// 		: '$primary0'
										// }
										bg="$primary0"
										w={windowWidth > 750 ? null : '100%'}
									>
										<Image
											h={
												tab === activeTab
													? windowWidth > 480
														? 30
														: 30
													: 30
											}
											w={
												tab === activeTab
													? windowWidth > 480
														? 30
														: 30
													: 30
											}
											source={
												tab === activeTab
													? require(`../../assets/Images/${imgGreen[index]}.png`)
													: require(`../../assets/Images/${imgWhite[index]}.png`)
											}
										/>
									</Box>
									{windowWidth > 750 ? (
										<Text
											fontFamily="$fontHeading"
											fontWeight="$bold"
											fontSize={
												tab === activeTab
													? '$fontSize16'
													: '$fontSize13'
											}
											color={
												tab === activeTab
													? '$primary500'
													: undefined
											}
										>
											{tab}
										</Text>
									) : null}
								</Box>
							</Pressable>
							)
								
							}
							</>
						
						)
					
				}
					</>
				
				
				))}
			</Box>
		)
	}

	return (
		<Box
			position="fixed"
			bg="$primary0"
			zIndex={1000}
			alignSelf={windowWidth > 750 ? 'center' : null}
			marginTop={windowWidth > 750 ? 61 : null}
			paddingVertical={windowWidth > 750 ? null : 10}
			bottom={windowWidth > 750 ? null : 0}
			w={windowWidth > 750 ? null : '100%'}
			borderColor={windowWidth > 750 ? null : '$borderDark0'}
			h={windowWidth > 750 ? null : 50}
			borderTopWidth={windowWidth > 750 ? null : 2}
		>
			<Box w={'100%'} alignSelf={windowWidth > 750 ? 'center' : null}>
				<ScreenContainer tabs={tabs} />
			</Box>
		</Box>
	)
}

export default SideNavBar
