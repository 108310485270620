import { ArrowLeftIcon, Box, TrashIcon, Text } from '@gluestack-ui/themed'
import React from 'react'
import ShareSkeleton from './ShareSkeleton'
import { points } from '../../../newComponents/pointsComponents/pointsconfig'

const ShareChallenge = ({
	challenge,
	setChallenge,
	totalCapabilityPoints,
	setTab,
	prevData,
	capEditView,
	textAreaHeight,
}) => {
	const wordCount = challenge
		?.split(/\s+/)
		?.filter((word) => word !== '').length

	return (
		<Box>
			<ShareSkeleton
				prevFieldData={prevData}
				titleNormal1={'Any '}
				titleColored1={'specific challenges '}
				titleNormal2={
					'you encountered while '
				}
				titleColored2={'solving '}
				titleNormal3={'the problem?'}
				subtitle={'Talk about the hiccups you faced'}
				screen={'Challenge'}
				valueInput={challenge}
				setInputValueChange={setChallenge}
				textArea={true}
				infoStripText={
					'Employers highly value candidates who share real-world problem-solving experiences, providing concrete examples that demonstrate practical skills, critical thinking, and a results-oriented approach while offering insights into behavior and cultural alignment'
				}
				buttonText={
					challenge === '' || challenge === null ? 'Skip' : 'Next'
				}
				buttonOnPress={() => {
					setTab('Hacks')
				}}
				backButtonOnPress={() => {
					setTab('Experience')
				}}
				backIcon={ArrowLeftIcon}
				backText={'Back'}
				totalCapabilityPoints={totalCapabilityPoints}
				pointsAssigned={points.challenge}
				capEditView={capEditView}
				textAreaHeight={textAreaHeight}
				disableCopyPaste={true}
				disableRightClick={true}
			/>
		</Box>
	)
}

export default ShareChallenge
