import React, { useState, useEffect, useCallback } from 'react'
import { Dimensions } from 'react-native'
import ClaimedCapability from '../../FeedComponents/CheckEligibility/ClaimedCapability'
import UnclaimedCapability from '../../FeedComponents/CheckEligibility/UnclaimedCapability'
import UnlockedCapability from '../../FeedComponents/CheckEligibility/UnlockedCapability'
import useUserStore from '../../Userstore/UserStore'
import {
	getJobPost,
	list_JobApplications_by_ProfileId_filter_byJobPostId,
} from '../../graphql/custom-queries'
import { API, Storage, graphqlOperation } from 'aws-amplify'
import { createCapabilities } from '../../graphql/custom-mutations'
import {
	Box,
	HStack,
	Image,
	Text,
	FlatList,
	VStack,
	Pressable,
} from '@gluestack-ui/themed'
import SpinnerComponent from '../../newComponents/SpinnerComponent'
import AboutCompany from './AboutCompany'
import { listCapabilitiesByProfileIdByDate } from '../../graphql/custom-queries'
import { DekstopHeaderCandidate } from '../../newComponents/header/Header'
import { GetshortlistByjobPostIdfilterByUserId } from '../../graphql/custom-queries'

import {
	createJobApplications,
	updateJobApplications,
} from '../../graphql/custom-mutations'
import {
	createCandidateEducation,
	createCandidateExperience,
	updateNewprofile,
} from '../../graphql/mutations'

import { onCreateUserParsedResumeByUserId } from '../../graphql/subscriptions'

import ModalComponent from '../../newComponents/ModalComponent'
import CreateProfileModalBody from './CreateProfileModalBody'
import UploadResume from '../onboardingFlow/UploadResume'
import { points } from '../../newComponents/pointsComponents/pointsconfig'
import { ResumeParser } from '../../graphql/queries'
import LevelConfig from '../../LevelsConfig'
import TopProgressBar from './components/TopProgressBar'
import ConsentModal from './components/ConsentModal'
import ToolTipComponent from '../../newComponents/ToolTipComponent'
import VideoRecordingScreen from '../../newComponents/videoComponent/VideoRecordingScreen'
import SelectSkillModalBody from './components/SelectSkillModalBody'
import { listSkills } from './components/HelperFunctions'
import CompanyPrefs from './components/CompanyPrefs'
import AccordionComponent from '../../newComponents/AccordionComponent'
import ButtonComponent from '../../newComponents/ButtonComponent'
import ShiningButton from '../../newComponents/ShiningButton'
import ProgressBarsOnboarding from '../onboardingFlow/ProgressBarsOnboarding'
import { showToast } from '../../components/ErrorToast'
import AppliedScreen from './components/AppliedScreen'
import useImageFileLink from '../../customhook/useImageLinkhook'
const windowHeight = Dimensions.get('window').height
const windowWidth = Dimensions.get('window').width

const JobDetails = ({
	navigation,
	route,
	setJobPostFromId,
	jobPostIdModal,
	onClose,
	closeModal,
}) => {
	const jobPostId = jobPostIdModal ? jobPostIdModal : route?.params?.jobPostId

	const [jobPostByID, setJobPostById] = useState(null)
	const [isApplied, setIsApplied] = useState(false)
	const [isApplying, setIsApplying] = useState(false)
	const [isShortlisted, setShortlisted] = useState(false)
	const [loading, setLoading] = useState(false)
	const checkScreen = useUserStore((state) => state.checkScreen)
	const fileLink = useImageFileLink(jobPostByID?.CompanyPreference?.companyprofile?.LogoImageKey)
	
	const [loadingJobPost, setLoadingJobPost] = useState(true)
	const profileId = useUserStore((state) => state.profileId)
	const userId = useUserStore((state) => state.userId)
	const isLoggedIn = useUserStore((state) => state.isLoggedIn)
	const profile = useUserStore((state) => state.profile)
	const [createProfileModal, setCreateProfileModal] = useState(false)
	const [congratsLevel1Modal, setCongratsLevel1Modal] = useState(false)
	const [congratsLevel2Modal, setCongratsLevel2Modal] = useState(false)
	const [congratsLevel3Modal, setCongratsLevel3Modal] = useState(false)
	const [congratsLevel4Modal, setCongratsLevel4Modal] = useState(false)
	const [consentToShare, setConsentToShare] = useState(false)
	const [consentLoading, setConsentLoading] = useState(false)
	const [consentModal, setConsentModal] = useState(false)
	const typeOfUser = useUserStore((state) => state.group)
	const [isApplyClicked, setIsApplyClicked] = useState(false)
	const [applyNowLoading, setApplyNowLoading] = useState(false)
	const [videoUploaded, setVideoUploaded] = useState(false)
	const [switchAppliedView, setSwitchAppliedView] = useState(false)
	const [claimModal, setClaimModal] = useState(false)

	// const candidateCapabilities = profile?.Capabilities?.items || []
	const [currentJobStatus, setCurrentJobStatus] = useState('')
	let sumCandidatePoints = 0
	let sumCapabilityPoints = 0

	const candidateCapabilities = useUserStore(
		(state) => state.claimedCapabilities
	)
	const setSavedUrl = useUserStore((state) => state.setSavedUrl)
	const savedUrl = useUserStore((state) => state.savedurl)

	const [craeteCapabilityLoadingState, setcreateCapabilityLoadingState] =
		useState('')
	const setClaimedCapabailities = useUserStore(
		(state) => state.setClaimedCapabailities
	)
	const setJobsAppliedDetails = useUserStore(
		(state) => state.setJobsAppliedDetails
	)

	const jobsAppliedDetails = useUserStore((state) => state.jobsAppliedDetails)

	const requiredCaps = jobPostByID?.CapabilitiesRequired?.items || []

	const candidatePreferences = profile?.CandidatePreferences?.items

	const existingSkills = useUserStore((state) => state.userSkills)
	const setExistingSkills = useUserStore((state) => state.setUserSkills)
	const [existingSkillsLoading, setExistingSkillsLoading] = useState(false)

	requiredCaps.forEach((item) => {
		if (
			item.CapabilityConfig &&
			typeof item.CapabilityConfig.CapabilityPoints === 'number'
		) {
			sumCapabilityPoints += item.CapabilityConfig.CapabilityPoints
		} else {
			sumCapabilityPoints += 100
		}
	})

	const unclaimedCapabilities = []
	const matchedCandidateCapabilities = []
	const matchedCapabilitiesWithConnectedHidis = []
	const matchedCapabilitiesWithoutConnectedHidis = []

	const checkStorage = async () => {
		await Storage.get(`${userId}_video`, { validateObjectExistence: true })
			.then((res) => {
				setVideoUploaded(true)
			})
			.catch((err) => {
				setVideoUploaded(false)
			})
	}

	useEffect(() => {
		checkStorage()
	}, [])

	// tooltip start here

	const [firstStep, setFirstStep] = useState(false) // 1 claim
	const [secondStep, setSecondStep] = useState(false) // aisa aisa 5 claim krna hai

	const [thirdStep, setThirdStep] = useState(false) // after 5 claims, 1 unlock krwao

	const [fourthStep, setFourthStep] = useState(false) // after 1 unlock, unlock 2 more to get recognized

	const sumCalculation = requiredCaps.forEach((item) => {
		const matchingCapability =
			isLoggedIn &&
			profileId &&
			candidateCapabilities &&
			candidateCapabilities?.find(
				(capability) =>
					item?.CapabilityConfig?.id ===
					capability?.CapabilityConfig?.id
			)

		if (matchingCapability) {
			matchedCandidateCapabilities.push(matchingCapability)

			if (matchingCapability?.ConnectedHidis?.items?.length > 0) {
				matchedCapabilitiesWithConnectedHidis.push(matchingCapability)

				const pointsEarned = matchingCapability?.PointsEarned?.items[0]
				const capabilityPoints = pointsEarned?.CapabilityPoints

				sumCandidatePoints +=
					pointsEarned?.Hack === 'Yes'
						? (points.hack / 100) * capabilityPoints
						: 0
				sumCandidatePoints +=
					pointsEarned?.ProjectLink === 'Yes'
						? (points.projectLink / 100) * capabilityPoints
						: 0
				sumCandidatePoints +=
					pointsEarned?.Challenges === 'Yes'
						? (points.challenge / 100) * capabilityPoints
						: 0
				sumCandidatePoints +=
					pointsEarned?.Helped === 'Yes'
						? (points.helped / 100) * capabilityPoints
						: 0
				sumCandidatePoints +=
					pointsEarned?.experience === 'Yes'
						? (points.experience / 100) * capabilityPoints
						: 0
				sumCandidatePoints +=
					pointsEarned?.Problem === 'Yes'
						? (points.problem / 100) * capabilityPoints
						: 0
				sumCandidatePoints +=
					pointsEarned?.PeersVouch === 'Yes'
						? (points.peers / 100) * capabilityPoints
						: 0
			} else {
				matchedCapabilitiesWithoutConnectedHidis.push(
					matchingCapability
				)
			}
		} else {
			unclaimedCapabilities.push(item)
		}
	})

	const levelOne =
		matchedCandidateCapabilities.length > 0 &&
		(matchedCandidateCapabilities.length <=
			LevelConfig.Level1.CapabilityClaimed ||
			matchedCandidateCapabilities.length >=
				LevelConfig.Level1.CapabilityClaimed) &&
		(matchedCandidateCapabilities.length >=
		LevelConfig.Level1.CapabilityClaimed
			? matchedCapabilitiesWithConnectedHidis.length <=
			  LevelConfig.Level1.UnlockedCapability
			: true)

	const levelTwo =
		matchedCandidateCapabilities.length >=
			LevelConfig.Level2.CapabilityClaimed &&
		matchedCapabilitiesWithConnectedHidis.length >=
			LevelConfig.Level2.minUnlockedCapability &&
		matchedCapabilitiesWithConnectedHidis.length <=
			LevelConfig.Level2.maxUnLockedCapability

	const levelThree =
		matchedCandidateCapabilities.length >=
			LevelConfig.Level2.CapabilityClaimed &&
		matchedCapabilitiesWithConnectedHidis.length >=
			LevelConfig.Level3.minUnlockedCapability &&
		matchedCapabilitiesWithConnectedHidis.length <=
			LevelConfig.Level3.maxUnLockedCapability

	const levelFour =
		matchedCandidateCapabilities.length >=
			LevelConfig.Level2.CapabilityClaimed &&
		matchedCapabilitiesWithConnectedHidis.length >=
			LevelConfig.Level4.minUnlockedCapability &&
		(matchedCapabilitiesWithConnectedHidis.length <=
			LevelConfig.Level4.maxUnLockedCapability ||
			matchedCapabilitiesWithConnectedHidis.length >=
				LevelConfig.Level4.maxUnLockedCapability)

	const progressLevelOne = Math.floor(
		((matchedCandidateCapabilities.length >=
		LevelConfig.Level1.CapabilityClaimed
			? LevelConfig.Level2.CapabilityClaimed
			: matchedCandidateCapabilities.length) /
			LevelConfig.Level1.CapabilityClaimed) *
			100
	)

	const progressLevelTwo = Math.floor(
		(matchedCapabilitiesWithConnectedHidis.length /
			LevelConfig.Level2.maxUnLockedCapability) *
			100 +
			((matchedCandidateCapabilities.length >=
			LevelConfig.Level2.CapabilityClaimed
				? LevelConfig.Level2.CapabilityClaimed
				: matchedCandidateCapabilities.length) /
				LevelConfig.Level2.CapabilityClaimed) *
				0
	)

	const progressLevelThree = Math.floor(
		(matchedCapabilitiesWithConnectedHidis.length /
			LevelConfig.Level3.maxUnLockedCapability) *
			100 +
			((matchedCandidateCapabilities.length >=
			LevelConfig.Level3.CapabilityClaimed
				? LevelConfig.Level3.CapabilityClaimed
				: matchedCandidateCapabilities.length) /
				LevelConfig.Level3.CapabilityClaimed) *
				0
	)
	const progressLevelFour = Math.floor(
		((matchedCapabilitiesWithConnectedHidis.length >
		LevelConfig.Level4.maxUnLockedCapability
			? LevelConfig.Level4.maxUnLockedCapability
			: matchedCapabilitiesWithConnectedHidis.length) /
			LevelConfig.Level4.maxUnLockedCapability) *
			100 +
			((matchedCandidateCapabilities.length >=
			LevelConfig.Level4.CapabilityClaimed
				? LevelConfig.Level4.CapabilityClaimed
				: matchedCandidateCapabilities.length) /
				LevelConfig.Level4.CapabilityClaimed) *
				0
	)

	const allSharedPercentage = Math.floor(
		(matchedCapabilitiesWithConnectedHidis.length / requiredCaps.length) *
			100
	)

	async function getCapability() {
		await API.graphql({
			query: listCapabilitiesByProfileIdByDate,
			authMode: 'API_KEY',
			variables: {
				ProfileId: profileId,
			},
		})
			.then((res) => {
				setConsentToShare(
					res.data.listCapabilitiesByProfileIdByDate.items[0]
						.userProfile.ConsentToShareInfo
				)
				setClaimedCapabailities(
					res.data.listCapabilitiesByProfileIdByDate.items
				)
				// console.log('listCapabilitiesByProfileIdByDate', res)
			})
			.catch((err) => {})
	}
	const applyButton = async (prop) => {
		prop === 'Completed' && setLoading(true)
		const jobInput = {
			id: jobPostByID.id + '_' + profileId,
			JobPostId: jobPostByID?.id,
			ProfileId: profileId,
			CompanyPreferenceId: jobPostByID?.CompanyPreferenceId,
			ApplyingStatus: prop,
			Level:
				progressLevelFour > 99
					? 4
					: progressLevelThree > 99
					? 3
					: progressLevelTwo > 99
					? 2
					: progressLevelOne > 99
					? 1
					: 0,
		}

		const res = await API.graphql({
			query: createJobApplications,
			variables: {
				input: jobInput,
			},
		})
			.then((res) => {
				setJobsAppliedDetails(
					res.data.createJobApplications.candidateprofile
						.JobApplications.items
				)
				// if(prop == 'ProcessBComplete'){
				// 	setIsApplying(true)
				// 	handleApplyButtonPress()
				// }
				if (prop == 'Completed') {
					setIsApplied(true)
					setIsApplying(false)
				} else if (prop == 'Applying') {
					setIsApplying(true)
				}
				return setLoading(false)
			})
			.catch((err) => {
				setLoading(false)
			})
		if (prop === 'Completed' && jobPostByID?.CompanyPreference === null) {
			window.open(jobPostByID?.jobPostLink, '_blank')
		}
	}

	const CreateCapability = async (item) => {
		const CreateUserInput = {
			ProfileId: profileId,
			capabilitiesCapabilityConfigId: item?.GlobalCapabilityConfigID,
		}

		try {
			const res = await API.graphql({
				query: createCapabilities,
				variables: { input: CreateUserInput },
			})

			setClaimedCapabailities(
				res?.data?.createCapabilities?.userProfile?.Capabilities?.items
			)
			applyButton('Applying')
			return res?.data?.createCapabilities
		} catch (err) {
			// console.log(err)
		}
	}

	useEffect(() => {
		if (isLoggedIn && profileId) {
			getCapability()
		}
	}, [profileId])

	let logoUrl =
		'https://logo.clearbit.com/' +
		jobPostByID?.CompanyPreference?.companyprofile?.name
			?.split(' ')[0]
			.trim() +
		'.com'

	const listJobsApplied = async () => {
		setLoadingJobPost(true)
		try {
			await API.graphql({
				query: list_JobApplications_by_ProfileId_filter_byJobPostId,
				variables: {
					CompanyPreferenceId: jobPostByID?.CompanyPreferenceId,
					JobPostId: { eq: jobPostByID?.id },
					ProfileId: profileId,
				},
			}).then((res) => {
				setCurrentJobStatus(res)

				const applyingStatus =
					res?.data
						?.list_JobApplications_by_ProfileId_filter_byJobPostId
						?.items[0]?.ApplyingStatus
				// if(applyingStatus == 'ProcessBComplete'){
				// 	setIsApplied(false)
				// 	setIsApplying(true)
				// 	handleApplyButtonPress()
				// }
				if (applyingStatus === 'Applying') {
					setIsApplied(false)
					setIsApplying(true)
				} else if (applyingStatus === 'Completed') {
					setIsApplied(true)
					setIsApplying(false)
				}
			})
			setTimeout(() => {
				setLoadingJobPost(false)
			}, 1000)
		} catch (error) {
			console.error(error)
			setLoadingJobPost(false)
		}
	}

	const getJobPostByJobPostId = useCallback(async () => {
		
		setLoadingJobPost(true)
		try {
			const res = await API.graphql({
				query: getJobPost,
				authMode: 'API_KEY',
				variables: {
					id: jobPostId,
				},
			})
	
			setJobPostById(res?.data?.getJobPost)
			setJobPostFromId && setJobPostFromId(res?.data?.getJobPost)
			!isLoggedIn && setLoadingJobPost(false)
			isApplied && setLoadingJobPost(false)
			if (
				typeOfUser === 'companys' ||
				typeOfUser === 'QrusibleSuperAdmin'
			) {
				setLoadingJobPost(false)
			}
		} catch (error) {
			console.log(error)
			!isLoggedIn && setLoadingJobPost(false)
		}
	}, [])

	useEffect(() => {
		getJobPostByJobPostId()
	}, [jobPostId])

	const renderModalBody = () => {
		return (
			<CreateProfileModalBody
				modal={createProfileModal}
				setModal={setCreateProfileModal}
				jobType={jobPostByID?.jobType}
				navigation={navigation}
			/>
		)
	}

	useEffect(() => {
		if (jobPostByID && isLoggedIn && profileId) {
			listJobsApplied()
		}
	}, [jobPostByID, profileId, switchAppliedView])

	const getShortlistedData = async () => {
		await API.graphql({
			query: GetshortlistByjobPostIdfilterByUserId,
			variables: {
				JobPostId: jobPostId,
				UserId: userId,
			},
		})
			.then((res) => {
				if (res) {
					setShortlisted(true)
				}
			})
			.catch((err) => {
				// console.log('errorshortlisitng', err)
				setShortlisted(false)
			})
	}

	useEffect(() => {
		getShortlistedData()
	}, [jobPostId, userId])

	const [capabilityPressed, setCapabilityPressed] = useState(null)

	const firstCapId = unclaimedCapabilities[0]?.id
	const firstClaimedCapability =
		matchedCapabilitiesWithoutConnectedHidis[0]?.id

	const renderItem = ({ item }) => {
		const matchingCapability =
			isLoggedIn &&
			profileId &&
			candidateCapabilities &&
			candidateCapabilities?.find((capability) => {
				return (
					item?.GlobalCapabilityConfigID ===
					capability?.CapabilityConfig?.id
				)
			})

		if (matchingCapability) {
			if (matchingCapability.ConnectedHidis.items?.length > 0) {
				return (
					<Box m={5} w={windowWidth > 500 ? '32%' : '100%'}>
						<UnlockedCapability
							data={matchingCapability}
							getCapability={() => {
								getCapability()
							}}
							navigation={navigation}
							jobDomain={jobPostByID?.domain}
						/>
					</Box>
				)
			} else {
				return (
					<Box m={5} w={windowWidth > 500 ? '32%' : '100%'}>
						{(thirdStep || fourthStep) &&
						matchingCapability?.id === firstClaimedCapability ? (
							<ToolTipComponent
								isOpen={
									thirdStep
										? thirdStep
										: fourthStep
										? fourthStep
										: false
								}
								closeTooltip={() => {
									if (thirdStep) {
										setThirdStep(false)
									} else if (fourthStep) {
										setFourthStep(false)
									}
								}}
								backdrop={false}
								tooltipText={
									thirdStep
										? 'Unlock  atleast 2 capabilities to Apply'
										: fourthStep
										? 'Unlock more capabilities to highlight your profile for the employer'
										: null
								}
								renderToolTip={() => {
									return (
										<ClaimedCapability
											data={matchingCapability}
											navigation={navigation}
											getCapability={() => {
												getCapability()
											}}
											jobDomain={jobPostByID?.domain}
											closeTooltip={() => {
												thirdStep
													? setThirdStep(false)
													: fourthStep
													? setFourthStep(false)
													: null
											}}
										/>
									)
								}}
							/>
						) : (
							<ClaimedCapability
								data={matchingCapability}
								navigation={navigation}
								getCapability={() => {
									getCapability()
								}}
								jobDomain={jobPostByID?.domain}
								closeTooltip={() => {
									setSecondStep(false)
								}}
								thirdStep={thirdStep}
								fourthStep={fourthStep}
								firstClaimedCapability={firstClaimedCapability}
							/>
						)}
					</Box>
				)
			}
		} else {
			return (
				<Box m={5} w={windowWidth > 500 ? '32%' : '100%'} zIndex={1}>
					{firstStep &&
					firstCapId === item?.id &&
					profile &&
					(typeOfUser !== 'companys' ||
						typeOfUser !== 'QrusibleSuperAdmin') ? (
						<ToolTipComponent
							isOpen={firstStep}
							closeTooltip={() => {
								setFirstStep(false)
							}}
							backdrop={false}
							tooltipText={'Start applying by Claiming'}
							renderToolTip={() => {
								return (
									<UnclaimedCapability
										data={item}
										onPress={() => {
											if (
												typeOfUser === 'companys' ||
												typeOfUser ===
													'QrusibleSuperAdmin'
											) {
												return
											} else if (
												isLoggedIn &&
												profileId
											) {
												setSavedUrl('')
												setClaimModal(true)
												setCapabilityPressed(item)
											} else {
												setCreateProfileModal(true)
											}
										}}
										capId={firstCapId}
										firstStep={firstStep}
									/>
								)
							}}
						/>
					) : (
						<>
							<UnclaimedCapability
								data={item}
								onPress={() => {
									if (
										typeOfUser === 'companys' ||
										typeOfUser === 'QrusibleSuperAdmin'
									) {
										return
									} else if (isLoggedIn && profileId) {
										setSavedUrl('')
										setClaimModal(true)
										setCapabilityPressed(item)
									} else {
										setCreateProfileModal(true)
									}
								}}
								capId={firstCapId}
								firstStep={firstStep}
							/>
						</>
					)}
				</Box>
			)
		}
	}

	const updateConsent = () => {
		setConsentLoading(true)
		const CreateUserInput = {
			id: profileId,
			ConsentToShareInfo: true,
		}
		API.graphql({
			query: updateNewprofile,
			variables: { input: CreateUserInput },
		}).then((res) => {
			setConsentLoading(false)
			setConsentModal(false)
			setConsentToShare(true)
			if (progressLevelFour > 99) {
				setCongratsLevel4Modal(true)
			} else if (progressLevelThree > 99) {
				setCongratsLevel3Modal(true)
			} else if (progressLevelTwo > 99) {
				setCongratsLevel2Modal(true)
			}
		})
	}

	const UpdateApplyStatus = async (prop, level) => {
		try {
			const jobInput = {
				id: jobPostByID.id + '_' + profileId,
				ApplyingStatus: prop,
				Level: level,
			}

			const res = await API.graphql({
				query: updateJobApplications,
				variables: {
					input: jobInput,
				},
			})
			
			if (prop === 'Completed') {
				setIsApplied(true)
				setIsApplying(false)
			} else if (prop === 'Applying') {
				setIsApplied(false)
				setIsApplying(true)
			}
			// else if(prop === 'ProcessBComplete'){
			// 	setIsApplied(false)
			// 	setIsApplying(true)
			// }
			setLoading(false)
		} catch (error) {
			console.log(error)
			setLoading(false)
		}
	}

	useEffect(() => {
		if (progressLevelOne > 99 && levelOne) {
			UpdateApplyStatus('Applying', 1)
		} else if (progressLevelTwo > 99 && levelTwo && isApplying) {
			UpdateApplyStatus('Applying', 2)
		} else if (progressLevelThree > 99 && levelThree && isApplying) {
			console.log("shouldn't trigger 3")
			UpdateApplyStatus('Applying', 3)
		} else if (progressLevelFour > 99 && levelFour && isApplying) {
			console.log("shouldn't trigger 4")
			UpdateApplyStatus('Applying', 4)
		}
	}, [
		levelOne,
		levelTwo,
		levelThree,
		levelFour,
		progressLevelOne,
		progressLevelTwo,
		progressLevelThree,
		progressLevelFour,
	])

	// to be seen later
	// useEffect(() => {
	// 	if (
	// 		isLoggedIn &&
	// 		profileId &&
	// 		savedUrl.includes('JobDetails') &&
	// 		!createProfileModal &&
	// 		jobPostByID
	// 	) {
	// 		setLoadingJobPost(true)
	// 		const timeoutId = setTimeout(() => {
	// 			setLoadingJobPost(false)
	// 			setSavedUrl('')
	// 		}, 3000)
	// 		return () => clearTimeout(timeoutId)
	// 	}
	// }, [isLoggedIn, profileId, savedUrl, createProfileModal, jobPostByID])

	useEffect(() => {
		if (
			!congratsLevel1Modal &&
			!congratsLevel2Modal &&
			!congratsLevel3Modal &&
			!congratsLevel4Modal &&
			jobPostByID
		) {
			setLoadingJobPost(true)
			const timeoutId = setTimeout(() => {
				setLoadingJobPost(false)
			}, 1000)
			return () => clearTimeout(timeoutId)
		}
	}, [
		congratsLevel1Modal,
		congratsLevel2Modal,
		congratsLevel3Modal,
		congratsLevel4Modal,
	])

	const nextStepsText = isLoggedIn
		? progressLevelOne < 99
			? `Claim ${
				(requiredCaps?.length > LevelConfig.Level1.CapabilityClaimed ? 
					(LevelConfig.Level1.CapabilityClaimed - matchedCandidateCapabilities?.length) : (requiredCaps?.length -matchedCandidateCapabilities?.length ) )	 
					
			  } capabilities to Demonstrate your Expertise`
			: progressLevelTwo < 99
			? 'Share Experience for 1 Capability to Apply'
			: progressLevelThree < 99
			? `Share Experience for ${
					LevelConfig.Level3.maxUnLockedCapability -
					matchedCapabilitiesWithConnectedHidis?.length
			  } capabilities to complete Level 3`
			: progressLevelFour < 99
			? `Share Experience for ${
					LevelConfig.Level4.maxUnLockedCapability -
					matchedCapabilitiesWithConnectedHidis?.length
			  } capabilities to complete Level 4`
			: `You have cleared all the levels`
		: 'Claim a capability to start the process'

	useEffect(() => {
		let timeoutId
		const firstWalkthroughDone = localStorage.getItem('firstWalkthrough')
		if (firstWalkthroughDone || loadingJobPost) {
			setSecondStep(false)
			setFirstStep(false)
		} else {
			if (progressLevelOne === 0) {
				if (createProfileModal || claimModal) {
					setFirstStep(false)
				} else {
					if (!isLoggedIn) {
						setFirstStep(false)
						timeoutId = setTimeout(() => {
							setFirstStep(true)
						}, 20000)
					} else {
						setFirstStep(true)
					}
				}
			} else if (progressLevelOne > 0 && progressLevelOne < 30) {
				if (
					createProfileModal ||
					consentModal ||
					congratsLevel1Modal ||
					congratsLevel2Modal ||
					congratsLevel3Modal ||
					congratsLevel4Modal ||
					claimModal ||
					loadingJobPost
				) {
					setFirstStep(false)
					setSecondStep(false)
				} else {
					setFirstStep(false)
					setSecondStep(true)
					localStorage.setItem('firstWalkthrough', 'true')
				}
			} else {
				setSecondStep(false)
				setFirstStep(false)
			}
		}
		return () => {
			if (timeoutId) {
				clearTimeout(timeoutId)
			}
		}
	}, [
		progressLevelOne,
		createProfileModal,
		consentModal,
		congratsLevel1Modal,
		congratsLevel2Modal,
		congratsLevel3Modal,
		congratsLevel4Modal,
		claimModal,
		loadingJobPost,
	])

	useEffect(() => {
		const secondWalkthroughDone = localStorage.getItem('secondWalkthrough')
	
		if (secondWalkthroughDone || loadingJobPost || claimModal) {
			setThirdStep(false)
			setFourthStep(false)
		} else {
			if (progressLevelTwo === 0 && progressLevelOne > 99) {
				if (
					createProfileModal ||
					consentModal ||
					congratsLevel1Modal ||
					congratsLevel2Modal ||
					congratsLevel3Modal ||
					congratsLevel4Modal ||
					claimModal ||
					loadingJobPost
				) {
					setThirdStep(false)
				} else {
					setThirdStep(true)
				}
			} else if (progressLevelTwo > 45 && progressLevelThree < 60) {
				if (
					createProfileModal ||
					consentModal ||
					congratsLevel1Modal ||
					congratsLevel2Modal ||
					congratsLevel3Modal ||
					congratsLevel4Modal ||
					claimModal ||
					loadingJobPost
				) {
					setThirdStep(false)
					setFourthStep(false)
				} else {
					setThirdStep(false)
					setFourthStep(true)
					localStorage.setItem('secondWalkthrough', 'true')
				}
			} else {
				setThirdStep(false)
				setFourthStep(false)
			}
		}
	}, [
		progressLevelTwo,
		progressLevelThree,
		progressLevelOne,
		createProfileModal,
		consentModal,
		congratsLevel1Modal,
		congratsLevel2Modal,
		congratsLevel3Modal,
		congratsLevel4Modal,
		loadingJobPost,
		claimModal,
	])

	// useEffect(() => {
	// 	candidatePreferences?.forEach((item) => {
	// 		if (item?.jobType === 'FullTime') {
	// 			if (item?.currentSalary === null) {
	// 				setCreateProfileModal(true)
	// 			} else {
	// 				setCreateProfileModal(false)
	// 			}
	// 		} else if (item?.jobType === 'Contract') {
	// 			if (item?.minimumContractPeriod === null) {
	// 				setCreateProfileModal(true)
	// 			} else {
	// 				setCreateProfileModal(false)
	// 			}
	// 		}
	// 	})
	// }, [candidatePreferences, userId, profileId, profile])

	const listSkillsByUserId = async () => {
		setExistingSkillsLoading(true)
		const skills = await listSkills(userId)
		setExistingSkills(skills)
		setExistingSkillsLoading(false)
	}

	useEffect(() => {
		listSkillsByUserId()
	}, [userId])

	const selectSkillsModal = () => {
		return (
			<SelectSkillModalBody
				capability={capabilityPressed}
				createCapability={CreateCapability}
				setModal={setClaimModal}
				getCapability={getCapability}
				inModal={true}
				userId={userId}
				existingSkills={existingSkills}
				setExistingSkills={setExistingSkills}
				existingSkillsLoading={existingSkillsLoading}
				setExistingSkillsLoading={setExistingSkillsLoading}
			/>
		)
	}

	const AboutSection = () => {
		return (
			<Box>
				<Box>
					<CompanyPrefs
						companyPreferences={jobPostByID?.CompanyPreference}
					/>
				</Box>
				{jobPostByID?.CompanyDescription ||
				jobPostByID?.description ||
				jobPostByID?.title ||
				jobPostByID?.CompanyPreference?.modeofwork ? (
					<Box>
						<AboutCompany
							externalLocation={
								jobPostByID?.CompanyPreferences?.Location
									? jobPostByID?.CompanyPreferences?.Location
									: jobPostByID?.Location
							}
							externalAbout={jobPostByID?.htmldescription}
							shortDesc={jobPostByID?.ShortDesc}
							skills={jobPostByID?.skills}
							experienceRequired={jobPostByID?.experienceRequired}
							companyName={
								jobPostByID?.CompanyPreference?.companyprofile
									?.name
							}
							companyDescription={
								jobPostByID?.description
									? jobPostByID?.description
									: jobPostByID?.CompanyPreference
											?.companyprofile?.about
							}
							opportunity={jobPostByID?.RolesandResponsibility}
							jobType={jobPostByID?.CompanyPreference?.JobType}
							location={jobPostByID?.CompanyPreference?.Location}
							modeOfWork={
								jobPostByID?.CompanyPreference?.modeofwork
							}
							postedOn={jobPostByID?.createdAt?.split('T')[0]}
						/>
					</Box>
				) : null}
			</Box>
		)
	}

	const handleApplyButtonPress = () => {
		if (isLoggedIn) {
			console.log("In HandleApply")
			localStorage.setItem(`${jobPostId}_applyButtonClicked`, true)
			setIsApplyClicked(true)
		} else {
			setCreateProfileModal(true)
			localStorage.setItem(`${jobPostId}_applyButtonClicked`, true)
		}
	}

	useEffect(() => {
		if (isLoggedIn) {
			const applyButtonClicked = localStorage.getItem(
				`${jobPostId}_applyButtonClicked`
			)
			
			setIsApplyClicked(applyButtonClicked)
		}
	}, [jobPostId, isLoggedIn])

	const onPressApplyNow = async () => {
		setApplyNowLoading(true)
		if (progressLevelTwo > 99 && levelTwo) {
			await UpdateApplyStatus('Completed', 2)
			setSwitchAppliedView(false)
		} else if (progressLevelThree > 99 && levelThree) {
			await UpdateApplyStatus('Completed', 3)
			setSwitchAppliedView(false)
		} else if (progressLevelFour > 99 && levelFour) {
			await UpdateApplyStatus('Completed', 4)
			setSwitchAppliedView(false)
		}
		setApplyNowLoading(false)
	}

	const handleDisabledApplyButton = () => {
		if (matchedCandidateCapabilities?.length >= 5) {
			showToast({
				message: `${`Share atleast ${
					2 - matchedCapabilitiesWithConnectedHidis.length
				} more experiences to apply for this job`}`,
			})
		} else {
			showToast({
				message: `Claim ${
					5 - matchedCandidateCapabilities.length
				} more capabilities and share ${
					2 - matchedCapabilitiesWithConnectedHidis.length
				} more experiences to apply for this job`,
			})
		}
	}

	return (
		<>
			{!isLoggedIn && !profileId && (
				<Box>
					<DekstopHeaderCandidate navigationRef={navigation} />
				</Box>
			)}
			<Box
				bg={!onClose && '$backgroundGray'}
				minHeight={!onClose && windowHeight - 62}
				marginTop={!onClose && 62}
				marginBottom={!onClose && windowWidth > 480 ? null : 50}
				paddingHorizontal={!onClose && windowWidth > 480 ? 100 : 5}
				paddingVertical={!onClose && windowWidth > 480 ? 20 : 5}
				paddingBottom={!onClose && windowWidth > 480 ? null : 50}
			>
				{loadingJobPost ? (
					<Box alignItems="center" justifyContent="center" h={'100%'}>
						<SpinnerComponent
							smallText={
								jobPostByID?.companyName
									? `Loading ${jobPostByID?.companyName}'s opportunity for you!`
									: 'Loading an opportunity for you'
							}
						/>
					</Box>
				) : isApplied && !switchAppliedView ? (
					<Box
						w={
							isLoggedIn
								? typeOfUser === 'candidates'
									? '100%'
									: '70%'
								: '70%'
						}
						alignSelf="center"
						bg="$primary0"
						p={!onClose && windowWidth > 480 ? 20 : 10}
						h="fit-content"
						borderWidth={!onClose && 1}
						borderColor="$borderDark0"
						borderRadius={6}
					>
						<AppliedScreen
							jobPost={jobPostByID}
							aboutCurrentJob={currentJobStatus}
							applicationProgress={allSharedPercentage}
							setSwitchAppliedView={setSwitchAppliedView}
							navigation={navigation}
							macthedHidis={matchedCapabilitiesWithConnectedHidis}
							companyName={
								jobPostByID?.CompanyPreference?.companyprofile
									?.name
							}
						/>
					</Box>
				) : (
					<>
						{/* progress bar here - start */}

						{((isLoggedIn &&
							(matchedCandidateCapabilities.length > 0 ||
								matchedCapabilitiesWithConnectedHidis.length >
									0)) ||
							(isApplyClicked && isLoggedIn)) &&
						typeOfUser !== 'companys' &&
						typeOfUser !== 'QrusibleSuperAdmin' ? (
							<Box w={windowWidth > 480 ? '100%' : '95%'}>
								<ProgressBarsOnboarding
									progressBarFirst={100}
									progressBarSecond={100}
									progressBarThird={100}
									progressBarFourth={allSharedPercentage}
									mtop={10}
									mbottom={10}
								/>
							</Box>
						) : null}

						{/* progress bar here - end */}
						<Box
							w={
								isLoggedIn
									? typeOfUser === 'candidates'
										? '100%'
										: '70%'
									: windowWidth > 480
									? ' 70%'
									: '100%'
							}
							alignSelf="center"
							bg="$primary0"
							p={!onClose && windowWidth > 480 ? 20 : 10}
							h="fit-content"
							borderWidth={!onClose && 1}
							borderColor="$borderDark0"
							borderRadius={6}
						>
							{/* modals - should be unaffected */}

							<ModalComponent
								isOpen={createProfileModal}
								onClose={() => {
									setCreateProfileModal(false)
								}}
								renderBody={renderModalBody}
								minWidth={
									windowWidth > 480
										? windowWidth * 0.7
										: windowWidth * 0.95
								}
								height={
									windowWidth > 480
										? windowHeight * 0.9
										: windowHeight * 0.7
								}
							/>

							<ModalComponent
								isOpen={claimModal}
								onClose={() => {
									setClaimModal(false)
								}}
								heading={
									'Select the skills used in this capability'
								}
								subHeading={
									'You must select skills to continue and claim a capability'
								}
								// height={windowHeight * 0.5}
								minWidth={windowWidth * 0.5}
								renderBody={selectSkillsModal}
							/>

							<ConsentModal
								isOpen={consentModal}
								onClose={() => {
									setConsentModal(false)
								}}
								navigation={navigation}
								updateConsent={updateConsent}
								loadingBtn={consentLoading}
							/>
							{/* modals - should be unaffected */}

							{/* top box - should be unaffected - start - 1 */}
							<HStack
								justifyContent="space-between"
								alignItems="center"
								bg="$primary0"
								flexWrap="wrap"
								position={
									firstStep ||
									secondStep ||
									thirdStep ||
									fourthStep
										? 'relative'
										: 'sticky'
								}
								top={
									firstStep ||
									secondStep ||
									thirdStep ||
									fourthStep ||
									typeOfUser !== 'candidates'
										? 0
										: 62
								}
								zIndex={
									firstStep ||
									secondStep ||
									thirdStep ||
									fourthStep
										? 0
										: 1
								}
								borderBottomWidth={1.2}
								paddingVertical={10}
								mb={10}
								borderColor="$borderDark0"
							>
								<Box
									flexDirection={'row'}
									gap={15}
									marginVertical={windowWidth > 480 ? 10 : 5}
								>
									<Box>
										<Image
											h={windowWidth > 480 ? 80 : 40}
											w={windowWidth > 480 ? 80 : 40}
											source={{ uri: fileLink ? fileLink : logoUrl }}
											alt="company logo"
											borderRadius={6}
											resizeMode='contain'
											defaultSource={require('../../assets/Images/experience.png')}
										/>
									</Box>

									<Box
										flexWrap="wrap"
										w={
											windowWidth < 480 &&
											windowWidth * 0.6
										}
										flex={1}
										gap={5}
									>
										<Pressable
											onPress={() => {
												if (jobPostByID?.jobPostLink) {
													window.open(
														jobPostByID?.jobPostLink
													)
												}
											}}
										>
											<Text
												fontFamily="$fontHeading"
												fontWeight="900"
												fontSize={
													windowWidth > 480 ? 25 : 18
												}
												color={'$primary950'}
												lineHeight={
													windowWidth > 480 ? 30 : 20
												}
											>
												{
													jobPostByID
														?.CompanyPreference
														?.rolename
												}
											</Text>
										</Pressable>

										<Pressable
											onPress={() => {
												if (jobPostByID?.jobPostLink) {
													window.open(
														jobPostByID?.jobPostLink
													)
												}
											}}
										>
											<Text
												fontFamily="$fontHeading"
												fontWeight="500"
												fontSize={
													windowWidth > 480
														? '$fontContent'
														: '$fontContentMobile'
												}
												color={'#4A789C'}
												lineHeight={
													windowWidth > 480 ? 20 : 15
												}
											>
												{
													jobPostByID
														?.CompanyPreference
														?.companyprofile?.name
												}
											</Text>
										</Pressable>

										<HStack>
											<Text
												fontFamily="$fontHeading"
												fontWeight="500"
												fontSize={
													windowWidth > 480
														? '$fontContent'
														: '$fontContentMobile'
												}
												color={'#4A789C'}
												lineHeight={
													windowWidth > 480 ? 20 : 15
												}
											>
												{
													jobPostByID
														?.CompanyPreference
														?.JobType
												}
											</Text>
											<Text
												fontFamily="$fontHeading"
												fontWeight="500"
												fontSize={
													windowWidth > 480
														? '$fontContent'
														: '$fontContentMobile'
												}
												color={'#4A789C'}
												lineHeight={
													windowWidth > 480 ? 20 : 15
												}
											>
												{' · '}
												{
													jobPostByID
														?.CompanyPreference
														?.modeofwork
												}
											</Text>
										</HStack>
									</Box>
								</Box>

								{((isLoggedIn &&
									(matchedCandidateCapabilities.length > 0 ||
										matchedCapabilitiesWithConnectedHidis.length >
											0)) ||
									(isApplyClicked && isLoggedIn)) &&
								typeOfUser !== 'companys' &&
								typeOfUser !== 'QrusibleSuperAdmin' ? (
									<Box>
										<ShiningButton
											title={
												isApplied
													? 'Update Application'
													: 'Apply Now'
											}
											onPress={() => {
												onPressApplyNow()
											}}
											buttonDisabled={
												progressLevelOne < 99 ||
												progressLevelTwo < 99 ||
												applyNowLoading
											}
											onPressDisabled={() => {
												applyNowLoading
													? null
													: handleDisabledApplyButton()
											}}
											customWidth={200}
											customHeight={35}
											customFs={
												windowWidth > 480 ? 14 : 12
											}
											loading={applyNowLoading}
										/>
									</Box>
								) : null}
							</HStack>
							{/* top box - should be unaffected - end - 1*/}

							{/* should be inside accordion for applying users  - start - 2 */}

							{(isLoggedIn &&
								(matchedCandidateCapabilities.length > 0 ||
									matchedCapabilitiesWithConnectedHidis.length >
										0)) ||
							(isApplyClicked && isLoggedIn) ? (
								<Box flex={1}>
									<AccordionComponent
										title={'About'}
										renderItem={() => {
											return <AboutSection />
										}}
									/>
								</Box>
							) : (
								<AboutSection />
							)}

							{/* should be inside accordion for applying users - end - 2 */}

							{/* should be outside accordion for applying users - start - 3 */}
							{(isLoggedIn &&
								(matchedCandidateCapabilities.length > 0 ||
									matchedCapabilitiesWithConnectedHidis.length >
										0)) ||
							(isApplyClicked && isLoggedIn) ? (
								<VStack
									zIndex={
										firstStep || secondStep || thirdStep
											? 1
											: 0
									}
								>
									<Box
										paddingHorizontal={
											windowWidth > 480 ? 20 : 5
										}
										paddingVertical={10}
										bg={
											congratsLevel1Modal
												? '#2FBEC8'
												: congratsLevel2Modal
												? '#53B54E'
												: congratsLevel3Modal
												? '#EDCF11'
												: congratsLevel4Modal
												? '#EB5353'
												: progressLevelOne < 99
												? '#2FBEC8'
												: progressLevelTwo < 99
												? '#53B54E'
												: progressLevelThree < 99
												? '#EDCF11'
												: '#EB5353'
										}
										marginTop={20}
									>
										<Text
											fontFamily="$fontHeading"
											color="#fff"
											fontSize={
												windowWidth > 480 ? 14 : 12
											}
											fontWeight="600"
										>
											Next Steps:{' '}
											<Text
												color="#fff"
												fontFamily="$fontHeading"
												fontWeight="400"
											>
												{nextStepsText}
											</Text>
										</Text>
									</Box>

									<Box
										// bg="$backgroundGray"
										paddingVertical={10}
										marginBottom={20}
										paddingHorizontal={
											windowWidth > 480 ? 20 : 10
										}
										gap={10}
										borderColor="$borderDark0"
										borderWidth={1}
										zIndex={1}
									>
										<Text
											fontFamily="$fontHeading"
											color="#A0AEC0"
											fontSize={
												windowWidth > 480 ? 13 : 11
											}
											fontWeight="600"
											zIndex={-1}
										>
											Capabilities Required by the
											Employer
										</Text>

										{(firstStep ||
											thirdStep ||
											fourthStep) &&
											typeOfUser !== 'companys' &&
											typeOfUser !==
												'QrusibleSuperAdmin' && (
												<Pressable
													onPress={() => {
														setFirstStep(false)
														setSecondStep(false)
														setThirdStep(false)
														setFourthStep(false)
													}}
													position={'fixed'}
													top={0}
													left={0}
													right={0}
													width={'100%'}
													height={'100%'}
													backgroundColor="rgba(0, 0, 0, 0.3)"
													zIndex={1}
												/>
											)}
										<Box zIndex={1}>
											{secondStep ? (
												<ToolTipComponent
													isOpen={secondStep}
													tooltipText={
														'Claim atleast 4 more capabilities to match with the opportunity'
													}
													closeTooltip={() => {
														setSecondStep(false)
													}}
													backdrop={true}
													renderToolTip={() => {
														return (
															<Box zIndex={1}>
																<FlatList
																	data={
																		requiredCaps
																	}
																	renderItem={
																		renderItem
																	}
																	keyExtractor={(
																		item
																	) =>
																		item.id.toString()
																	}
																	numColumns={
																		windowWidth >
																		500
																			? 3
																			: 1
																	}
																/>
															</Box>
														)
													}}
												/>
											) : (
												<Box zIndex={0}>
													<FlatList
														data={requiredCaps}
														renderItem={renderItem}
														keyExtractor={(item) =>
															item.id.toString()
														}
														numColumns={
															windowWidth > 500
																? 3
																: 1
														}
													/>
												</Box>
											)}
										</Box>
									</Box>
								</VStack>
							) : (
								<Box
									mt={25}
									mb={15}
									w={windowWidth > 480 ? '50%' : '100%'}
									justifyContent="center"
									alignItems={
										windowWidth > 480
											? 'flex-start '
											: 'center'
									}
								>
									<ShiningButton
										title={'Apply'}
										onPress={() => {
											handleApplyButtonPress()
										}}
									/>
								</Box>
							)}
							{/* should be outside accordion for applying users - end - 3 */}
						</Box>
					</>
				)}
			</Box>
		</>
	)
}

export default JobDetails
