import { StyleSheet, Text, View, Dimensions,
    FlatList, Pressable, Alert, TouchableOpacity,
     Linking, ScrollView } from 'react-native'
import React, { useEffect, useState } from 'react'
import Moment from 'react-moment';
import { API } from 'aws-amplify';
import { Button, Image, HStack, VStack } from 'native-base';
// import { getProfileByUserId } from '../graphql/queries';
import { getProfileByUserId } from '../graphql/custom-queries';
import { ActivityIndicator } from 'react-native';
import { createProfile } from '../graphql/mutations';
import { Link } from '@react-navigation/native';
//import { TouchableOpacity } from 'react-native-gesture-handler';
import Iframe from 'react-iframe'
const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;
export default function Profile(props) {
   
   console.log("width", windowWidth)
   console.log("height", windowHeight)
   const route = props.route
   const navigation = props.navigation
   const [loading, setloading] = useState(true)
   const [profile, setProfile] = useState('')
   const [verifiedclaim, setverifiedclaim] = useState(0)
   const [pendingclaim, setPendingclaim] = useState(0)
   
   function count(props){
       let claims = 0
       let pending = 0
       props.hidis.items.map((item)=>{
           if(item.Verified == "denied"){
            
               pending = pending + 1
           }
           if(item.Verified == "inProgress"){
               pending = pending + 1
           }
           if(item.Verified == "verified"){
               claims = claims + 1
           }
       })
       setPendingclaim(pending)
       setverifiedclaim(claims)
   }
   async function getprofile(){
       await API.graphql({
           query: getProfileByUserId,
           authMode: 'API_KEY',
           variables: {
             UserId: route.params.userId
           },
         }).then((res) => {
           setProfile(res.data.getProfileByUserId.items[0])
           console.log(res.data.getProfileByUserId.items[0])
           setloading(false)
           count(res.data.getProfileByUserId.items[0])
         })
        }
useEffect(()=>{
getprofile()
},[])
   const renderTalentItem = (item) => {
       console.log(item)
       return(
       <View style={styles.tagTalent}>
           <View style ={{padding: 8, flexDirection: "row"}}>
           <Text style={styles.Talnettitle}>
           {item.item.Talent}
           </Text>
           <View style ={{height: 10, width:1, backgroundColor: "grey", alignSelf: "center", marginHorizontal: 20}}/>
           <Text style ={{color:'#959595'}}>
           {item.item.description}
           </Text>
           </View>
       </View>
)
   }
   const renderSkillItem = (item) => {
       console.log(item)
       return(
       <View style={styles.tagSkill}>
           <View style ={{padding: 8, flexDirection: "row"}}>
           <Text style={styles.Skilltitle}>
           {item.item.Skill}
           </Text>
           <View style ={{height: 10, width:1, backgroundColor: "grey", alignSelf: "center", marginHorizontal: 20}}/>
           <Text style ={{color:'#959595'}}>
           {item.item.description}
           </Text>
           </View>
       </View>
)
   }
   const _headerComponent = () =>{
       return(
           <ScrollView 
       contentContainerStyle = {styles.scrollcontainer}
       style = {styles.containerMobile}>

           <View style ={{flexDirection: "row", alignItems:'center'}}>
           {/* <Image  source={require('../assets/Images/profile2.png')} 
           style = {styles.logoimage2}
           /> */}<VStack>
               <Text style ={styles.nameheader}>
                   {profile.name}
               </Text>
               <Text style={{paddingLeft:5, fontWeight:'600'}}>
                ServiceNow Developer - India - Available Full Time
               </Text>
               </VStack>
               </View>
               <Text style ={styles.textAbout}>
                   About
               </Text>
               <Text style = {styles.textAboutDetails}>
               {profile.about}
               </Text>
               <HStack style={{marginLeft:5, marginTop:5}}>
               <Text style={{fontWeight:'bold', paddingRight:5}}>Education</Text>
               <Text>{profile.education}</Text>
               </HStack>
               <HStack style={{marginLeft:5, marginTop:5}}>
               <Text style={{fontWeight:'bold', paddingRight:5}}>Graduation</Text>
               <Text>{profile.graduation}</Text>
               </HStack>
               <HStack style={{marginLeft:5, marginTop:5}}>
               <Text style={{fontWeight:'bold', paddingRight:5}}>Expertise</Text>
               <Text>{profile.expertise}</Text>
               </HStack>
               <HStack style={{marginLeft:5, marginTop:5}}>
               <Text style={{fontWeight:'bold', paddingRight:5}}>Experience</Text>
               <Text>{profile.experience}</Text>
               </HStack>
               <View style ={{flexDirection: "row", justifyContent: "space-between", paddingVertical: 10, paddingHorizontal:5}}>
                   {profile.resume ? (<Text style ={{color: "blue"}}
                   onPress={() => Linking.openURL(profile.resume)}>
                       Resume</Text>): null}
                   
                  
                 </View>
                 <View style={{marginVertical:15, height: 1, backgroundColor: '#DADADA'}} />
                 <View>
                   <HStack  style={{alignItems:"center"}}>
                       <Text style ={styles.textclaim}>
                       Verification Status
                       </Text>
                       {/* <TouchableOpacity style={styles.button} onPress={()=>{ alert('This dabba needs to talk about validation. Choose between this dabba or the one below');}}>
                           <Text style={styles.info}>i</Text>
                       </TouchableOpacity> */}
                   </HStack>
                   <View style ={styles.about}>
                   <Text style ={styles.hidiskillstext}>
                   We are using a weighted methodology to give higher value to evaluators based on the level of domain expertise. So, level of expertise equals weight of feedback in the overall evaluation score.        
                   </Text>
                   </View>
                   
               <View style ={{flexDirection: "row", justifyContent: "space-between", paddingVertical: 10, paddingHorizontal:5}}>
                   <View style ={{flexDirection: "column", justifyContent: "center", alignItems:'center'}}>
                       <Text style ={styles.numbertext}>
                       {profile.hidis.items.length}
                       </Text>
                       <Text style ={styles.subclaimtext}>
                           Total
                       </Text>
                   </View>
                   <View style ={{flexDirection: "column", justifyContent: "center", alignItems:'center'}}>
                       <Text style ={styles.numbertext}>
                       {verifiedclaim}
                       </Text>
                       <Text style ={styles.verifiedclaimtext}>
                           Network Verified
                       </Text>
                   </View>
                   <View style ={{flexDirection: "column", justifyContent: "center", alignItems:'center'}}>
                       <Text style ={styles.numbertext}>
                       {pendingclaim}
                       </Text>
                       <Text style ={styles.pendingtext}>
                           Pending
                       </Text>
                   </View>
                   </View>
                 </View>
                 <View style={{marginVertical:15, height: 1, backgroundColor: '#DADADA'}} />          
       <View>
           <View style ={{flexDirection: "row", justifyContent: "space-between"}}>
           <Text style ={styles.talenttext}>
               Talent Feedback
           </Text>
           
           </View>
           <View style={{paddingHorizontal:5, paddingTop:10}}>
           <View>
           <FlatList
                 data={profile.Talents.items}
               //   columnWrapperStyle={{
               //     justifyContent: 'space-between',
               //     marginBottom: 15,
               //   }}
               ListEmptyComponent = {() => (<Image  source={require('../assets/Images/progress.png')} 
               style = {styles.picimage2}
               />)}
                 keyExtractor={item => item.id}
                 renderItem={renderTalentItem} 
                 />
               </View>
           
               
           </View>
       </View>
       <View style={{marginVertical:15, height: 1, backgroundColor: '#DADADA'}} />
       <View>
       <View style ={{flexDirection: "row", justifyContent: "space-between"}}>
           <Text style ={styles.SkillsText}>
               Skill Feedback
           </Text>
           
           </View>
           <View>
               <View>
               <FlatList
                 data={profile.Skills.items}
               //   columnWrapperStyle={{
               //     justifyContent: 'space-between',
               //     marginBottom: 15,
               //   }}
               ListEmptyComponent = {() => (<Image  source={require('../assets/Images/progress.png')} 
               style = {styles.picimage2}
               />)}
                 keyExtractor={item => item.id}
                 renderItem={renderSkillItem} 
                 />
               </View>
           
           </View>
       </View>
       <View >
       <View style={{marginVertical:15, height: 1, backgroundColor: '#DADADA'}} />
       <Text style ={styles.SkillsText}>
               How I Did It(HIDI)
           </Text>
       </View>
       
       </ScrollView>
       )
   }
     const renderItem = (item) => {
       console.log("inrender",item)
       return(
           <Link to={{ screen: 'HidiScreen', params: { hidiId: item.item.id } }}>
         

   <TouchableOpacity 
   style = {styles.hidibox1}>
       <View style ={styles.hidibox2}>
       <Image  source={require('../assets/Images/copy.png')} 
           style = {styles.picimage}
           />
       </View>
   <View style ={styles.hidibox3}>
       <View style ={{flexDirection: "column"}}>
       <View style ={{flexDirection: "row", justifyContent:'space-between'}}>
       <Text style ={styles.hidiskillstext}>
       {item.item.Adescription}
       </Text>
       {item.item.Verified == "verified" ? (
           <Image  source={require('../assets/Images/check.png')} 
           style = {styles.logoimage}
           />
       ) : (
           <Image  source={require('../assets/Images/uncheck.png')} 
           style = {styles.logoimage}
           />
       )}
           
       </View>   
       <Text numberOfLines={2} 
       style ={styles.hidititletext}>
           {item.item.Atitle}
       </Text>
       <Text style ={styles.DateNewtext}>
          
           {' '}
         <Moment fromNow ago interval={30000} element={Text}>
        {item.item.createdAt}
        </Moment>{' '}
        ago
       </Text>
       </View>   
   </View>
</TouchableOpacity>
</Link>   
     )}
     const renderItemMobile = (item) => {
       console.log("inrender",item)
       return(
           <Link to={{ screen: 'HidiScreen', params: { hidiId: item.item.id } }}>      

   <TouchableOpacity 
   style = {styles.hidiboxMobile1}>
       <View style ={styles.hidiboxMobile2}>
       <Image  source={require('../assets/Images/copy.png')} 
           style = {styles.picimage}
           />
       </View>
   <View style ={styles.hidiboxMobile3}>
       <View style ={{flexDirection: "column"}}>
       <View style ={{flexDirection: "row", justifyContent:'space-between'}}>
       <Text style ={styles.hidiskillstext}>
       {item.item.Adescription}
       </Text>
       {item.item.Verified == "verified" ? (
           <Image  source={require('../assets/Images/check.png')} 
           style = {styles.logoimage}
           />
       ) : (
           <Image  source={require('../assets/Images/uncheck.png')} 
           style = {styles.logoimage}
           />
       )}
           
       </View>   
       <Text numberOfLines={2} 
       style ={styles.hidititletext}>
           {item.item.Atitle}
       </Text>
       <Text style ={styles.DateNewtext}>
          
           {' '}
         <Moment fromNow ago interval={30000} element={Text}>
        {item.item.createdAt}
        </Moment>{' '}
        ago
       </Text>
       </View>   
   </View>
</TouchableOpacity>
</Link>
     )}
 return (
   <>
   {loading ? (<ActivityIndicator/>) : (
       <>
       {windowWidth <= 480 && 
       <View>
           <FlatList 
           data={profile.hidis.items}
           ListHeaderComponent={_headerComponent()}
           ListFooterComponent ={() => (
               <View style={{ height: 1, 
                   backgroundColor: '#DADADA', marginBottom : 80}} />
           )}
           keyExtractor={item => item.id}
                 renderItem={renderItemMobile} 
           />
       </View>
       }
       {
           windowWidth >480 &&
           <View style ={styles.container}>
             
       <View style = {styles.container1}>
       <Image  source={require('../assets/Images/qwhite.png')} 
           style = {styles.logoimage}
           />
       <Image  source={require('../assets/Images/profile.png')} 
           style = {styles.logoimage2}
           />
       </View>
       <ScrollView 
       contentContainerStyle = {styles.scrollcontainer}
       style = {styles.container2}>
           
           <View style ={{flexDirection: "row", alignItems:'center', marginBottom:15}}>
           <Image  source={require('../assets/Images/profile2.png')} 
           style = {styles.logoimage2}
           />
               <VStack><Text style ={styles.nameheader}>
                   {profile.name}
               </Text>
               <Text style={{paddingLeft:5, fontWeight:'bold'}}>
                   ServiceNow Developer     -     India     -     Available Full Time
               </Text>
               </VStack> 
               </View>
                 <HStack style={{alignContent:'center', justifyContent:'flex-start', paddingVertical:5}}>
                   <VStack>
               <Text style ={styles.textAbout}>
                   Talent Feedback
               </Text>
               </VStack> 
               <View style={{marginHorizontal:15, height: '100%', width:1,  backgroundColor: '#DADADA'}} />

           <View style={{paddingHorizontal:5}}>
           <View>
           <FlatList
                 data={profile.Talents.items}
               //   columnWrapperStyle={{
               //     justifyContent: 'space-between',
               //     marginBottom: 15,
               //   }}
               numColumns={2}
               ListEmptyComponent = {() => (<Image  source={require('../assets/Images/progress.png')} 
               style = {styles.picimage2}
               />)}
                 keyExtractor={item => item.id}
                 renderItem={renderTalentItem} 
                 />
               </View>
           
               
           </View>
       </HStack>
       <View style={{marginVertical:15, height: 1}} />
       <HStack style={{alignContent:'center', justifyContent:'flex-start', paddingVertical:5}}>
                   <VStack>
               <Text style ={styles.textAbout}>
                   Skill Feedback
               </Text>
               </VStack> 
               <View style={{marginHorizontal:15, height: '100%', width:1,  backgroundColor: '#DADADA'}} />
           <View>
               <View>
               <FlatList
                 data={profile.Skills.items}
               //   columnWrapperStyle={{
               //     justifyContent: 'space-between',
               //     marginBottom: 15,
               //   }}
               numColumns={2}
               ListEmptyComponent = {() => (<Image  source={require('../assets/Images/progress.png')} 
               style = {styles.picimage2}
               />)}
                 keyExtractor={item => item.id}
                 renderItem={renderSkillItem} 
                 />
               </View>
           
           </View>
       </HStack>
        <View style={{marginVertical:15, height: 1}} />
       <HStack style={{alignContent:'center', justifyContent:'flex-start', paddingVertical:5}}>
                   <VStack>
               <Text style ={styles.textAbout}>
                   About
               </Text>
               <View style ={{flexDirection: "row", justifyContent: "space-between", paddingVertical: 10, paddingHorizontal:5}}>
                   {profile.resume ? (<HStack style={{alignItems:'center'}}><Image  source={require('../assets/Images/attach.png')} 
           style = {{height:20, width:20, paddingRight:15}}
           /><Text style ={{color: "blue"}}
                   onPress={() => Linking.openURL(profile.resume)}>
                       Resume</Text></HStack>): null}
                   
                  
                 </View>
                 </VStack> 
               <View style={{marginHorizontal:15, height: '100%', width:1,  backgroundColor: '#DADADA'}} /> 
               <View style={{width:'80%'}}> 
               <Text style = {styles.textAboutDetails}>
               {profile.about}
               </Text>
               <HStack style={{marginLeft:5, marginTop:5}}>
               <Text style={{fontWeight:'bold', paddingRight:5}}>Education</Text>
               <Text>{profile.education}</Text>
               </HStack>
               <HStack style={{marginLeft:5, marginTop:5}}>
               <Text style={{fontWeight:'bold', paddingRight:5}}>Graduation</Text>
               <Text>{profile.graduation}</Text>
               </HStack>
               <HStack style={{marginLeft:5, marginTop:5}}>
               <Text style={{fontWeight:'bold', paddingRight:5}}>Expertise</Text>
               <Text>{profile.expertise}</Text>
               </HStack>
               <HStack style={{marginLeft:5, marginTop:5}}>
               <Text style={{fontWeight:'bold', paddingRight:5}}>Experience</Text>
               <Text>{profile.experience}</Text>
               </HStack>
               </View> 
               </HStack>
       </ScrollView>
       <ScrollView style = {styles.container3}>
       <Text style = {styles.textMainHeadingclaim}>
               How I Did It (HIDI)
               </Text>
           <Text style ={styles.textnumberofclaimmain}>
           {profile.hidis.items.length} HIDI's
           </Text>
           <View style={{paddingHorizontal:15}}>
                   <HStack  style={{alignItems:"center"}}>
                       <Text style ={styles.textclaim}>
                       Verification Status
                       </Text>
                       {/* <TouchableOpacity style={styles.button} onPress={()=>{ alert('This dabba needs to talk about validation. Choose between this dabba or the one below');}}>
                           <Text style={styles.info}>i</Text>
                       </TouchableOpacity> */}
                   </HStack>
                   <View style ={styles.about}>
                   <Text style ={styles.hidiskillstext}>
                   We are using a weighted methodology to give higher value to evaluators based on the level of domain expertise. So, level of expertise equals weight of feedback in the overall evaluation score.        
                   </Text>
                   </View>
               <View style ={{flexDirection: "row", justifyContent: "space-between", paddingVertical: 10, paddingHorizontal:5}}>
                   <View style ={{flexDirection: "column", justifyContent: "center", alignItems:'center'}}>
                       <Text style ={styles.numbertext}>
                       {profile.hidis.items.length}
                       </Text>
                       <Text style ={styles.subclaimtext}>
                           Total
                       </Text>
                   </View>
                   <View style ={{flexDirection: "column", justifyContent: "center", alignItems:'center'}}>
                       <Text style ={styles.numbertext}>
                       {verifiedclaim}
                       </Text>
                       <Text style ={styles.verifiedclaimtext}>
                           Network verified
                       </Text>
                   </View>
                   <View style ={{flexDirection: "column", justifyContent: "center", alignItems:'center'}}>
                       <Text style ={styles.numbertext}>
                       {pendingclaim}
                       </Text>
                       <Text style ={styles.pendingtext}>
                           Pending
                       </Text>
                   </View>
                   </View>
                   <View style={{marginVertical:15, height: 1, backgroundColor: '#DADADA'}} />
                 </View>
                 
           <FlatList
                 data={profile.hidis.items}
                 numColumns={1}
               //   columnWrapperStyle={{
               //     //justifyContent: 'flex-start',
               //     //alignContent:'space-between',
               //     //marginBottom: 15,
               //   }}
                 keyExtractor={item => item.id}
                 renderItem={renderItem} 
                 />
       </ScrollView>
   </View>
       }
       </>
   )}
   </>
   
 )
}

const styles = StyleSheet.create({
   container: {
       // #F7F7F7
       // #F2F2F2
       
       flexDirection: "row",
       flex: 1
   },
   container1 : {
       flexDirection: "column",
       justifyContent: "space-between",
       alignItems: "center",
       width: windowWidth*0.04,
       height: windowHeight,
       paddingVertical:10,
       // borderWidth: 1,

   },
   container2: {
       width: windowWidth*0.69,
       height: windowHeight,
       // borderWidth: 1,
       backgroundColor: "#F7F7F7",
       paddingTop:20,
       paddingLeft:30, 
       // paddingRight:30,
   },
   container3 : {
       width : windowWidth*0.21,
       height: windowHeight,
       // borderWidth: 1,
       paddingTop:20,
       paddingHorizontal:30,

   },
   containerMobile : {
       width : windowWidth*0.99,
       height: windowHeight,
       // borderWidth: 1,
       paddingTop:20,
       paddingHorizontal:15,
       // marginBottom: 20

   },
   scrollcontainer: {
       justifyContent: "center",
       alignContent: "center"
   },
   hidibox1 :{
       width : windowWidth*0.19,
       height: windowHeight*0.35,
       //alignItems: 'center',
       justifyContent:'center',
       marginLeft: 20,
       // padding: 10
       // borderWidth: 1,
   },
   hidibox2: {
       width : windowWidth*0.17,
       height: windowHeight*0.22,
       backgroundColor: "#F2F2F2",
       borderRadius: 25,
       //marginLeft: 15,
       padding : 5,
       justifyContent:'center',
       alignItems:'center',
   },
   hidibox3: {
       width : windowWidth*0.17,
       height: windowHeight*0.13,
       // borderWidth: 1,
       //flexDirection: "row",
       //paddingVertical: 15,
       padding : 5
   },
   hidiboxMobile1 :{
       width : windowWidth*0.99,
       height: windowHeight*0.40,
       //alignItems: 'center',
       justifyContent:'center',
       marginLeft: 20,
       // padding: 10
       // borderWidth: 1,
   },
   hidiboxMobile2: {
       width : windowWidth*0.90,
       height: windowHeight*0.26,
       backgroundColor: "#F2F2F2",
       borderRadius: 25,
       //marginLeft: 15,
       padding : 5,
       justifyContent:'center',
       alignItems:'center',
   },
   hidiboxMobile3: {
       width : windowWidth*0.90,
       height: windowHeight*0.14,
       // borderWidth: 1,
       //flexDirection: "row",
       //paddingVertical: 15,
       padding : 5
   },
   about: {
       //width : '100%',
       //height: '50px',
       backgroundColor: "#F2F2F2",
       //borderRadius: 8,
       //marginLeft: 15,
       padding : 5,
       paddingHorizontal:5
   },
   hidiskillstext: {
       color: "#A6A6A6",
       paddingVertical: 5,
       fontSize: 14,
       fontWeight: "300"
   },
   hidititletext: {
       fontWeight: "700",
       paddingVertical : 5,
       color: "#454545",
       fontSize: 14,
       //textAlign: "center"
       
   },
   DateNewtext:{
       color: "#A6A6A6",
       fontSize: 12,
       fontWeight: "500" 
   },
   logoimage: {
       height: 25,
       width: 25,
       
   },
   picimage: {
       height: 65,
       width: 65,
   },
   logoimage2: {
       height: 25,
       width: 25,
       marginTop : 20,
       // borderRadius: 50
   },
   nameheader : {
       fontWeight: "bold",
       padding : 5,
       fontSize: 23
   },
   textAbout: {
       fontWeight: "bold",
       padding: 5,
       fontSize: 16,
       width:130,
   },
   SkillsText: {
       fontWeight: "bold",
       padding : 5,
       fontSize: 16
   },
   talenttext: {
       fontWeight: "bold",
       padding : 5,
       fontSize: 16
   },
   viewAlltext: {
       fontWeight: "bold",
       padding : 5,
       color: "red",

   },
   textAboutDetails: {
       fontSize: 16,
       // alignSelf: "center",
       padding: 5,
       fontfamily: 'Lexend',
       fontWeight: "300"
   },
   numbertext: {
       fontSize: 29,
       fontWeight: "bold"
   },
   textclaim: {
       fontWeight: "bold",
       padding: 5,
       fontSize: 16
   },
   subclaimtext: {
     fontSize: 16,
     color: "#969696",
     fontWeight: "400" 
   },
   verifiedclaimtext:{
       fontSize: 16,
       color: "#39C962",
       fontWeight: "400"
   },
   pendingtext: {
       fontWeight: 16,
       color: " #DDCE80",
       fontWeight: "400"
   },
   TalentSkillText: {
       padding: 10,
       color: "#969696",
       fontSize: 16,
       fontWeight: 400
   },
   button:{
       width:20,
       height:20,
       borderRadius: 25,
       backgroundColor:"#FE393C",
       alignItems:'center',
       justifyContent:'center',
       paddingLeft: '15',
   },
   textMainHeadingclaim: {
       color: "#1C1C1C",
       fontSize: 24,
       fontWeight: "Bold",
       paddingVertical: 10,
       marginLeft: 20,
   },
   textnumberofclaimmain: {
       color : "#969696",
       fontSize: 16,
       fontWeight: "400",
       paddingBottom: 20,
       marginLeft: 20,
   },
   info:{
       color: "#ffffff",
       fontWeight: 'bold',
   },
   Talnettitle: {
       fontWeight: 'bold',
       fontSize: 14,
       color: "#959595",
       textAlign: "center"
   },
   Skilltitle: {
       fontWeight: 'bold',
       fontSize: 14,
       color: "#959595",
       textAlign: "center"
   },
   tagTalent: {
       backgroundColor: "#F0F0F0",
       // width: 140,
       borderRadius: 8,
       overflow: "hidden",
       margin:3,
       marginBottom: 2,
       alignSelf:'baseline',
       borderColor:'#CEE2FF',
       borderWidth:1
   },
   tagSkill: {
       backgroundColor: "#F0F0F0",
       // width: 140,
       borderRadius: 8,
       overflow: "hidden",
       margin:3,
       marginBottom: 2,
       alignSelf:'baseline',
       borderColor:'#FFD8D8',
       borderWidth:1
   },
   picimage2: {
       width: 80,
       height: 80,
       alignSelf:'center'
   },
   tabbar: {
       width: windowWidth*0.73,
       height: windowHeight*0.04,
       // borderWidth: 1 ,
       flexDirection: "row",
       // justifyContent: "center",
       alignContent: "center",
       marginLeft: -30,
       borderBottomWidth: 1,
       borderTopWidth: 1,
       backgroundColor: "#fff"
   }
   
   
})