import { Box, Divider, FlatList, Text } from '@gluestack-ui/themed'
import { Dimensions } from 'react-native'
import React from 'react'

const windowWidth = Dimensions.get('window').width

const CompanyPrefs = ({ companyPreferences }) => {
	const DATA = [
		{
			id: 0,
			preference: companyPreferences?.JobDomain || null,
			description: 'Job Domain',
		},
		{
			id: 1,
			preference: companyPreferences?.JobType || null,
			description: 'Job Type',
		},
		{
			id: 2,
			preference: companyPreferences?.Location || null,
			description: 'Location',
		},
		{
			id: 3,
			preference: companyPreferences?.package || null,
			description: 'Package Offered',
		},
		{
			id: 4,
			preference: (() => {
				const minExp = companyPreferences?.minExperienceRequired
				const maxExp = companyPreferences?.maxExperienceRequired

				if ((minExp === 0 || !minExp) && (maxExp === 0 || !maxExp)) {
					return 'Fresher'
				} else if (minExp === 'Fresher' && maxExp === 'Fresher') {
					return 'Fresher'
				} else if (minExp === 'Fresher' && maxExp) {
					return `Fresher - ${maxExp} Years`
				} else if (maxExp === 'Fresher') {
					return 'Fresher'
				} else {
					const minExperience = minExp || 0
					return `${minExperience}${
						maxExp ? ` - ${maxExp} Years` : ''
					}`
				}
			})(),
			description: 'Experience',
		},
	].filter((item) => item.preference !== null)

	const renderPreferences = ({ item, index }) => {
		const isLastItem = index === DATA.length - 1
		return (
			<Box flexBasis={'50%'}>
				<Box my={windowWidth > 480 ? 10 : 5}>
					<Text
						color={'#4F6696'}
						fontFamily="$fontHeading"
						fontSize={windowWidth > 500 ? 14 : 12}
					>
						{item.description}
					</Text>
					<Text
						fontFamily="$fontHeading"
						fontSize={windowWidth > 500 ? 12 : 11}
					>
						{item.preference}
					</Text>
				</Box>
				{!isLastItem && <Divider w={'100%'} h={0.5} />}
			</Box>
		)
	}

	return (
		<Box>
			<FlatList
				data={DATA}
				renderItem={renderPreferences}
				keyExtractor={(item) => item.id.toString()}
				numColumns={2}
			/>
			<Divider h={0.5} />
		</Box>
	)
}

export default CompanyPrefs
