import React, { useState, useEffect, useRef, useCallback } from 'react'
import { TouchableOpacity, Alert, Dimensions } from 'react-native'

import {
	Box,
	Image,
	KeyboardAvoidingView,
	Heading,
	Pressable,
	Center,
	Button,
	Text,
	ButtonIcon,
	ButtonText,
	ArrowLeftIcon,
	Input,
	InputSlot,
	Checkbox,
	CheckboxIndicator,
	CheckboxIcon,
	CheckboxLabel,
	CheckIcon,
	HStack,
} from '@gluestack-ui/themed'
import { Amplify, Auth } from 'aws-amplify'

import useUserStore from '../../../Userstore/UserStore'
import { faAlignCenter, faL } from '@fortawesome/free-solid-svg-icons'
import FederatedLogin from '../FederatedLogin'
import AlreadyUser from '../Options'
import LoginInputComponent from '../LoginInputComponent'
import { showToast } from '../../../components/ErrorToast'
import ForgotPassword from './Forgotpassword'
import TermsAndConditions from './TermsAndConditions'
import AboutQrusible from '../AboutQrusible'
import WavyBg from '../../../newComponents/WavyBg'

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

const Login = ({ navigation, routes, setTab, inModal, setModal }) => {
	const setNewPass = useUserStore((state) => state.setNewPassParams)
	const firstTimePass = useUserStore((state) => state.setFirstTimePass)
	const error = useUserStore((state) => state.error)
	const [loading, setLoading] = useState(false)
	const [userprop, SetUserprop] = useState('')
	const [showModal, setShowModal] = useState(false)
	const [modalForgotPassword, setModalForgotPassword] = useState(false)
	const [ischecked, setIsChecked] = useState(false)
	const [forgotPassword, setForgotPassword] = useState(false)
	const isLoggedIn = useUserStore((state) => state.isLoggedIn)

	const updateUser = useUserStore((state) => state.updateUser)
	const signIn = useUserStore((state) => state.signIn)
	const firsttime = useUserStore((state) => state.firstTimeLogin)
	const ref = useRef(null)

	const forgotPasswordModalClose = () => {
		setModalForgotPassword(false)
	}

	const composeOnLogin = (data, methods) => async () => {
		const { email, password } = data || {}
		const { updateUser, signIn, setLoading } = methods || {}
		if (!password || !email) {
			showToast({ message: 'Please fill in your Email and Password' })
			return
		}
		setLoading(true)
		try {
			updateUser({ email, password })
			await signIn()
			if (inModal) {
				window.location.href = '/Feed'
				setModal(false)
			}
			setLoading(false)
		} catch (err) {
			setLoading(false)
			console.log(err)
		}
	}

	const composeNewPassword = (data, methods) => async () => {
		const { Newpassword } = data || {}
		const { setNewPass, firstTimePass, setLoading } = methods || {}
		setLoading(true)

		try {
			setNewPass({ Newpassword })
			await firstTimePass()
			setLoading(false)
		} catch (err) {
			setLoading(false)
			console.log(err)
		}
	}
	const [newuserData, setNewUserData] = useState({
		fullName: '',
		companyname: '',
		Newpassword: '',
	})
	const [userData, setUserData] = useState({
		email: '',
		password: '',
	})

	const onSubmit = useCallback(
		composeOnLogin({ ...userData }, { updateUser, signIn, setLoading }),
		[userData, updateUser, signIn, setLoading]
	)

	const onSubmitFirstTime = React.useCallback(
		composeNewPassword(
			{ ...newuserData },
			{ firstTimePass, setNewPass, setLoading }
		),
		[newuserData, firstTimePass, setNewUserData]
	)

	const toggleCheck = () => {
		setIsChecked(!ischecked)
	}

	const SelectButton = () => {
		return (
			<Checkbox
				size="sm"
				isInvalid={false}
				isDisabled={false}
				gap={10}
				onChange={toggleCheck}
				defaultIsChecked={ischecked}
				aria-label={false}
			>
				<CheckboxIndicator>
					<CheckboxIcon as={CheckIcon} />
				</CheckboxIndicator>
				<CheckboxLabel>
					<Text
						fontWeight="600"
						fontFamily="$fontContent"
						fontSize={'$fontSize12'}
					>
						I accept the{' '}
					</Text>
					<Pressable onPress={() => setShowModal(true)}>
						<Text
							fontWeight="600"
							color="$primary500"
							fontFamily="$fontContent"
							fontSize={'$fontSize12'}
						>
							terms and conditions
						</Text>
					</Pressable>
				</CheckboxLabel>
			</Checkbox>
		)
	}

	return (
		<HStack>
			<Box
				alignItems="center"
				justifyContent="center"
				flexDirection="column"
				minHeight={
					inModal
						? windowWidth > 480
							? windowHeight * 0.9
							: null
						: '100vh'
				}
				paddingVertical={inModal ? (windowWidth > 480 ? 0 : 50) : 100}
				flex={1}
				alignSelf={'center'}
			>
				{!inModal && <WavyBg />}
				<Box
					justifyContent="center"
					alignItems={windowWidth > 500 && 'center'}
					w={'auto'}
					borderWidth={1}
					p={30}
					borderRadius={20}
					shadowRadius={30}
					shadowColor="black"
					// shadowOffset={{ height: 5, width: 5 }}
					shadowOpacity={0.2}
					borderColor="#d1d1e8"
					bg="white"
				>
					<Image
						h={'8rem'}
						w={'8rem'}
						alt="Qrusible logo"
						alignSelf="center"
						source={require('../../../assets/Images/QrusibleNewBlack.png')}
					/>
					<TermsAndConditions
						showModal={showModal}
						setShowModal={setShowModal}
						ref={ref}
					/>
					<KeyboardAvoidingView flex={1}>
						{firsttime ? (
							<Center>
								<Heading
									fontSize={
										windowWidth > 1500
											? '$fontSize30'
											: '$fontSize25'
									}
									fontWeight="$bold"
									fontFamily="$fontContent"
									color="$secondary950"
									marginVertical={40}
									textAlign="center"
								>
									Set a new password
								</Heading>
								<Box w={'100%'}>
									<LoginInputComponent
										buttonText={'Change Password'}
										screen={'firsttime'}
										btnMv={20}
										btnDisable={!ischecked}
										userData={newuserData}
										setUserData={setNewUserData}
										onPress={onSubmitFirstTime}
									/>
								</Box>
								<SelectButton />
							</Center>
						) : (
							<Box justifyContent="center" alignItems="center">
								<Heading
									fontSize={
										windowWidth > 1500
											? '$fontSize30'
											: '$fontSize25'
									}
									fontWeight="$bold"
									fontFamily="$fontContent"
									color="$secondary950"
									marginVertical={20}
									textAlign="center"
								>
									Log In
								</Heading>
								<FederatedLogin
									expanded={true}
									loginScreen={true}
								/>
								<Box w={'100%'}>
									<LoginInputComponent
										buttonText={'Sign In'}
										onPress={async () => {
											await onSubmit()
										}}
										userData={userData}
										setUserData={setUserData}
										loading={loading}
									/>
								</Box>
								{firsttime ? null : (
									<Button
										variant="link"
										size="sm"
										onPress={() => {
											setModalForgotPassword(true)
											setForgotPassword(false)
										}}
									>
										<ButtonText
											textAlign="center"
											fontFamily="$fontContent"
											fontSize={12}
										>
											Forgot Password?
										</ButtonText>
									</Button>
								)}
								<ForgotPassword
									modalClose={forgotPasswordModalClose}
									modalVisibilty={modalForgotPassword}
									ref={ref}
									forgotPassword={forgotPassword}
									setForgotPassword={setForgotPassword}
								/>
							</Box>
						)}
					</KeyboardAvoidingView>
				</Box>
				<AlreadyUser
					loginScreen={true}
					navigation={navigation}
					inModal={inModal}
					setTab={setTab}
				/>
			</Box>
		</HStack>
	)
}

export default Login
