import React, { useEffect, useState } from 'react'
import { Dimensions } from 'react-native'
import {
	AddIcon,
	Box,
	CloseIcon,
	FlatList,
	HStack,
	Icon,
	Pressable,
	Text,
	View,
} from '@gluestack-ui/themed'
import {
	deleteSkillandCapability,
	listSkills,
	listSkillsAndCapabilitiesByUserId,
} from './HelperFunctions'
import useUserStore from '../../../Userstore/UserStore'
import ButtonComponent from '../../../newComponents/ButtonComponent'
import LabelWithInputComponent from '../../../newComponents/LabelWithInputComponent'
import { createSkills } from './HelperFunctions'
import { joinSkillsAndCapability } from './HelperFunctions'
import { deleteSkills } from './HelperFunctions'
import SpinnerComponent from '../../../newComponents/SpinnerComponent'
import ModalComponent from '../../../newComponents/ModalComponent'
import SkillInfoModalBody from './SkillInfoModalBody'
import { API } from 'aws-amplify'

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

const SelectSkillModalBody = ({
	capability,
	createCapability,
	setModal,
	getCapability,
	inModal,
	screen,
	userId,
	existingSkills,
	setExistingSkills,
	existingSkillsLoading,
	showDelete,
	navigation,
	setExistingSkillsLoading,
	setSelectedSkillsItem,
	selectedSkillsItem,
}) => {
	const [addMore, setAddMore] = useState(false)
	const [selectedSkills, setSelectedSkills] = useState(
		selectedSkillsItem || []
	)
	const [skillsInput, setSkillsInput] = useState('')
	const [loadingProceed, setLoadingProceed] = useState(false)
	const [addSkillLoading, setAddSkillLoading] = useState(false)
	const [linkedSkills, setLinkedSkills] = useState([])

	const handleSkillPress = (skill) => {
		const index = selectedSkills.findIndex(
			(selectedSkill) => selectedSkill.id === skill.id
		)
		if (index === -1) {
			setSelectedSkills([...selectedSkills, skill])
		} else {
			const updatedSkills = [...selectedSkills]
			updatedSkills.splice(index, 1)
			setSelectedSkills(updatedSkills)
		}
	}


	useEffect(() => {
		setSelectedSkillsItem && setSelectedSkillsItem(selectedSkills)
	}, [selectedSkills])

	const deleteSkill = async (itemId) => {
		const input = {
			id: itemId,
		}
		await deleteSkills(input)
	}

	const getSkillsByUserId = async () => {
		const skillsResponse = await listSkillsAndCapabilitiesByUserId(userId)
		if (skillsResponse) {
			setLinkedSkills(skillsResponse)
		}
	}

	useEffect(() => {
		getSkillsByUserId()
	}, [])

	const deleteJoinSkillsBySkillId = async (skillId) => {
		try {
			const itemsToDelete = linkedSkills.filter(
				(item) => item.SkillId === skillId
			)

			for (const item of itemsToDelete) {
				const deleteInput = {
					id: item.id,
				}
				const itemToDelete = await deleteSkillandCapability(deleteInput)
			}
			getSkillsByUserId()
		} catch (e) {
			console.log('error', e)
		}
	}

	const handleRemoveSkill = (skillId) => {
		const updatedSkills = existingSkills.filter(
			(skill) => skill.id !== skillId
		)
		setExistingSkills(updatedSkills)
		deleteSkill(skillId)
		deleteJoinSkillsBySkillId(skillId)
		const updateSelectedSkills = selectedSkills.filter(
			(skill) => skill.id !== skillId
		)
		setSelectedSkills(updateSelectedSkills)
	}

	const renderSkillInfo = () => {
		return (
			<SkillInfoModalBody
				setSelectedSkills={setSelectedSkills}
				skillSelected={selectedSkills[0]?.id}
				navigation={navigation}
				screen={screen}
			/>
		)
	}

	const renderSkillsList = ({ item }) => {
		const isSelected = selectedSkills.some(
			(selectedSkill) => selectedSkill?.id === item?.id
		)

		const hasConnectedHidis = item?.JoinedCapabilities?.items.some(
			(skill) => skill?.Capabilities?.ConnectedHidis?.items?.length > 0
		)

		return (
			<Pressable
				borderWidth={2}
				mx={5}
				borderRadius={50}
				my={screen !== 'CapCreateHidi' ? 10 : 5}
				p={3}
				onPress={() => handleSkillPress(item)}
				flexDirection="row"
				justifyContent="space-between"
				alignItems="center"
				borderColor={
					inModal
						? null
						: hasConnectedHidis
						? '$green600'
						: '$coolGray500'
				}
				bg={
					inModal
						? isSelected
							? '$green500'
							: null
						: hasConnectedHidis
						? '$green200'
						: '#D3D3D3'
				}
			>
				{!inModal && (
					<ModalComponent
						isOpen={isSelected}
						renderBody={renderSkillInfo}
						onClose={() => {
							setSelectedSkills([])
						}}
						heading={`Skill: ${item.Skill}`}
						subHeading={'Skill and their related evidences'}
						minWidth={
							windowWidth > 480
								? windowWidth * 0.7
								: windowWidth * 0.9
						}
						height={windowHeight * 0.9}
						maxHeight={windowHeight * 0.9}
						showScroll={false}
						background={'white'}
					/>
				)}
				<Text
					fontSize={windowWidth > 480 ? 14 : 12}
					textAlign="center"
					fontFamily="$fontHeading"
					px={5}
					py={0}
					color={
						inModal
							? isSelected
								? 'white'
								: 'black'
							: hasConnectedHidis
							? '$green800'
							: 'black'
					}
				>
					{item.Skill}
				</Text>
				{showDelete && (
					<Pressable
						onPress={() => {
							handleRemoveSkill(item.id)
						}}
					>
						<Icon
							as={CloseIcon}
							size={'sm'}
							color={
								inModal
									? isSelected
										? 'white'
										: 'black'
									: hasConnectedHidis
									? '$green800'
									: 'black'
							}
						/>
					</Pressable>
				)}
			</Pressable>
		)
	}

	const addSkillsFromString = async () => {
		setAddSkillLoading(true)

		const newSkills = skillsInput?.split(',')?.map((skill, index) => ({
			id: index + existingSkills.length + 1,
			Skill: skill?.trim(),
		}))

		const skillsPromises = newSkills.map(async (skill) => {
			const input = {
				Skill: skill.Skill,
				UserId: userId,
			}
			return createSkills(input)
		})

		const skillsCreatedResponses = await Promise.all(skillsPromises)

		const updatedExistingSkills = [
			...existingSkills,
			...skillsCreatedResponses,
		]
		setExistingSkills(updatedExistingSkills)

		const updatedSelectedSkills = [
			...selectedSkills,
			...skillsCreatedResponses,
		]
		inModal && setSelectedSkills(updatedSelectedSkills)
		setAddSkillLoading(false)
		setAddMore(false)
		setSkillsInput('')
	}

	const joinCapabilityAndSkills = async () => {
		setLoadingProceed(true)

		const capabilityItem = await createCapability(capability)

		const joiningSkillsCapability = selectedSkills.map(async (skill) => {
			const input = {
				UserId: userId,
				SkillId: skill.id,
				CapabilityId: capabilityItem?.id,
				GlobalCapabilityConfigId: capability?.GlobalCapabilityConfigID,
				GlobalCapabilityConfigName: capability?.CapabilityConfig?.name,
			}
			return joinSkillsAndCapability(input)
		})

		const joinSkillsCapabilityResponse = await Promise.all(
			joiningSkillsCapability
		)

		await getCapability()
		setModal(false)
		setLoadingProceed(false)
	}

	return (
		<Box
			px={
				screen === 'CapCreateHidi'
					? 0
					: inModal && windowWidth > 480
					? 20
					: 10
			}
			justifyContent="space-between"
			my={screen !== 'CapCreateHidi' && 10}
		>
			{addMore ? (
				<Box mb={inModal ? 30 : 10}>
					<LabelWithInputComponent
						label={'Enter Skills (comma separated values)'}
						value={skillsInput}
						setValueChange={(text) => setSkillsInput(text)}
					/>
					<HStack gap={10}>
						<ButtonComponent
							text={'Cancel'}
							onPress={() => {
								setAddMore(false)
							}}
							color={'$primary0'}
							borderColor={'$primary950'}
							textColor={'$primary950'}
							width={100}
							height={33}
						/>
						<ButtonComponent
							text={'Add'}
							onPress={() => {
								addSkillsFromString()
							}}
							disabled={
								skillsInput === null || skillsInput === ''
							}
							loading={addSkillLoading}
							width={100}
							height={33}
						/>
					</HStack>
				</Box>
			) : (
				<Box mb={0}>
					{existingSkillsLoading ? (
						<Box marginVertical={10}>
							<SpinnerComponent button={true} />
						</Box>
					) : (
						<>
							{existingSkills.length > 0 ? (
								<FlatList
									data={existingSkills}
									keyExtractor={(item) => item.id.toString()}
									renderItem={renderSkillsList}
									contentContainerStyle={{
										flex: 1,
										flexWrap: 'wrap',
									}}
									horizontal={true}
								/>
							) : (
								<Text
									fontFamily="$fontHeading"
									fontWeight="600"
									letterSpacing={0.2}
								>
									No skills found
								</Text>
							)}
						</>
					)}
					{screen !== 'public' && (
						<HStack
							justifyContent="space-between"
							my={20}
							bottom={0}
						>
							<Pressable
								bg={'$primary500'}
								w={'fit-content'}
								px={10}
								py={5}
								flexDirection="row"
								gap={5}
								borderRadius={6}
								onPress={() => {
									setAddMore(true)
								}}
								alignItems="center"
							>
								<Icon as={AddIcon} color="white" />
								<Text
									color="$primary0"
									fontFamily="$fontHeading"
									fontWeight="600"
									letterSpacing={0.2}
								>
									{existingSkills.length > 0
										? 'Add More'
										: 'Add Skills'}
								</Text>
							</Pressable>
							{inModal && screen !== 'CapCreateHidi' ? (
								<ButtonComponent
									text={'Proceed'}
									onPress={() => {
										joinCapabilityAndSkills()
									}}
									disabled={
										selectedSkills.length === 0 ||
										existingSkillsLoading
									}
									loading={loadingProceed}
									width={100}
									height={33}
								/>
							) : (
								<Box />
							)}
						</HStack>
					)}
				</Box>
			)}
		</Box>
	)
}

export default SelectSkillModalBody
