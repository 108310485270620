const conversionRates = [
	{ currencyCode: 'AED', countryName: 'United Arab Emirates', rate: 1 },
	{ currencyCode: 'AFN', countryName: 'Afghanistan', rate: 20.0563 },
	{ currencyCode: 'ALL', countryName: 'Albania', rate: 26.2448 },
	{ currencyCode: 'AMD', countryName: 'Armenia', rate: 110.3801 },
	{ currencyCode: 'ANG', countryName: 'Netherlands Antilles', rate: 0.4874 },
	{ currencyCode: 'AOA', countryName: 'Angola', rate: 227.2276 },
	{ currencyCode: 'ARS', countryName: 'Argentina', rate: 227.3792 },
	{ currencyCode: 'AUD', countryName: 'Australia', rate: 0.4169 },
	{ currencyCode: 'AWG', countryName: 'Aruba', rate: 0.4874 },
	{ currencyCode: 'AZN', countryName: 'Azerbaijan', rate: 0.4629 },
	{
		currencyCode: 'BAM',
		countryName: 'Bosnia and Herzegovina',
		rate: 0.4942,
	},
	{ currencyCode: 'BBD', countryName: 'Barbados', rate: 0.5446 },
	{ currencyCode: 'BDT', countryName: 'Bangladesh', rate: 29.8702 },
	{ currencyCode: 'BGN', countryName: 'Bulgaria', rate: 0.4942 },
	{ currencyCode: 'BHD', countryName: 'Bahrain', rate: 0.1024 },
	{ currencyCode: 'BIF', countryName: 'Burundi', rate: 775.6894 },
	{ currencyCode: 'BMD', countryName: 'Bermuda', rate: 0.2723 },
	{ currencyCode: 'BND', countryName: 'Brunei Darussalam', rate: 0.3668 },
	{ currencyCode: 'BOB', countryName: 'Bolivia', rate: 1.8827 },
	{ currencyCode: 'BRL', countryName: 'Brazil', rate: 1.3533 },
	{ currencyCode: 'BSD', countryName: 'Bahamas', rate: 0.2723 },
	{ currencyCode: 'BTN', countryName: 'Bhutan', rate: 22.6116 },
	{ currencyCode: 'BWP', countryName: 'Botswana', rate: 3.7317 },
	{ currencyCode: 'BYN', countryName: 'Belarus', rate: 0.8886 },
	{ currencyCode: 'BZD', countryName: 'Belize', rate: 0.5446 },
	{ currencyCode: 'CAD', countryName: 'Canada', rate: 0.3672 },
	{
		currencyCode: 'CDF',
		countryName: 'Democratic Republic of the Congo',
		rate: 744.6576,
	},
	{ currencyCode: 'CHF', countryName: 'Switzerland', rate: 0.2399 },
	{ currencyCode: 'CLP', countryName: 'Chile', rate: 264.1278 },
	{ currencyCode: 'CNY', countryName: 'China', rate: 1.9645 },
	{ currencyCode: 'COP', countryName: 'Colombia', rate: 1062.6 },
	{ currencyCode: 'CRC', countryName: 'Costa Rica', rate: 140.2482 },
	{ currencyCode: 'CUP', countryName: 'Cuba', rate: 6.5351 },
	{ currencyCode: 'CVE', countryName: 'Cabo Verde', rate: 27.8643 },
	{ currencyCode: 'CZK', countryName: 'Czech Republic', rate: 6.4294 },
	{ currencyCode: 'DJF', countryName: 'Djibouti', rate: 48.3924 },
	{ currencyCode: 'DKK', countryName: 'Denmark', rate: 1.8853 },
	{ currencyCode: 'DOP', countryName: 'Dominican Republic', rate: 15.9201 },
	{ currencyCode: 'DZD', countryName: 'Algeria', rate: 36.6372 },
	{ currencyCode: 'EGP', countryName: 'Egypt', rate: 8.4134 },
	{ currencyCode: 'ERN', countryName: 'Eritrea', rate: 4.0844 },
	{ currencyCode: 'ETB', countryName: 'Ethiopia', rate: 15.4387 },
	{ currencyCode: 'EUR', countryName: 'Eurozone', rate: 0.2527 },
	{ currencyCode: 'FJD', countryName: 'Fiji', rate: 0.6115 },
	{ currencyCode: 'FKP', countryName: 'Falkland Islands', rate: 0.216 },
	{ currencyCode: 'FOK', countryName: 'Faroe Islands', rate: 1.8847 },
	{ currencyCode: 'GBP', countryName: 'United Kingdom', rate: 0.216 },
	{ currencyCode: 'GEL', countryName: 'Georgia', rate: 0.719 },
	{ currencyCode: 'GGP', countryName: 'Guernsey', rate: 0.216 },
	{ currencyCode: 'GHS', countryName: 'Ghana', rate: 3.4023 },
	{ currencyCode: 'GIP', countryName: 'Gibraltar', rate: 0.216 },
	{ currencyCode: 'GMD', countryName: 'Gambia', rate: 18.3243 },
	{ currencyCode: 'GNF', countryName: 'Guinea', rate: 2330.5799 },
	{ currencyCode: 'GTQ', countryName: 'Guatemala', rate: 2.1234 },
	{ currencyCode: 'GYD', countryName: 'Guyana', rate: 57.0072 },
	{ currencyCode: 'HKD', countryName: 'Hong Kong', rate: 2.1297 },
	{ currencyCode: 'HNL', countryName: 'Honduras', rate: 6.7099 },
	{ currencyCode: 'HRK', countryName: 'Croatia', rate: 1.904 },
	{ currencyCode: 'HTG', countryName: 'Haiti', rate: 35.9688 },
	{ currencyCode: 'HUF', countryName: 'Hungary', rate: 98.3938 },
	{ currencyCode: 'IDR', countryName: 'Indonesia', rate: 4261.0704 },
	{ currencyCode: 'ILS', countryName: 'Israel', rate: 0.9825 },
	{ currencyCode: 'IMP', countryName: 'Isle of Man', rate: 0.216 },
	{ currencyCode: 'INR', countryName: 'India', rate: 22.6116 },
	{ currencyCode: 'IQD', countryName: 'Iraq', rate: 356.6036 },
	{ currencyCode: 'IRR', countryName: 'Iran', rate: 11438.7359 },
	{ currencyCode: 'ISK', countryName: 'Iceland', rate: 37.7098 },
	{ currencyCode: 'JEP', countryName: 'Jersey', rate: 0.216 },
	{ currencyCode: 'JMD', countryName: 'Jamaica', rate: 42.658 },
	{ currencyCode: 'JOD', countryName: 'Jordan', rate: 0.1931 },
	{ currencyCode: 'JPY', countryName: 'Japan', rate: 40.8993 },
	{ currencyCode: 'KES', countryName: 'Kenya', rate: 40.0005 },
	{ currencyCode: 'KGS', countryName: 'Kyrgyzstan', rate: 24.3573 },
	{ currencyCode: 'KHR', countryName: 'Cambodia', rate: 1116.7312 },
	{ currencyCode: 'KID', countryName: 'Kiribati', rate: 0.4169 },
	{ currencyCode: 'KMF', countryName: 'Comoros', rate: 124.3216 },
	{ currencyCode: 'KRW', countryName: 'South Korea', rate: 362.9959 },
	{ currencyCode: 'KWD', countryName: 'Kuwait', rate: 0.08384 },
	{ currencyCode: 'KYD', countryName: 'Cayman Islands', rate: 0.2269 },
	{ currencyCode: 'KZT', countryName: 'Kazakhstan', rate: 122.5765 },
	{ currencyCode: 'LAK', countryName: 'Laos', rate: 5621.4099 },
	{ currencyCode: 'LBP', countryName: 'Lebanon', rate: 4084.4112 },
	{ currencyCode: 'LKR', countryName: 'Sri Lanka', rate: 85.0358 },
	{ currencyCode: 'LRD', countryName: 'Liberia', rate: 52.2712 },
	{ currencyCode: 'LSL', countryName: 'Lesotho', rate: 5.141 },
	{ currencyCode: 'LYD', countryName: 'Libya', rate: 1.3201 },
	{ currencyCode: 'MAD', countryName: 'Morocco', rate: 2.7406 },
	{ currencyCode: 'MDL', countryName: 'Moldova', rate: 4.8523 },
	{ currencyCode: 'MGA', countryName: 'Madagascar', rate: 1235.4257 },
	{ currencyCode: 'MKD', countryName: 'North Macedonia', rate: 15.6083 },
	{ currencyCode: 'MMK', countryName: 'Myanmar', rate: 570.327 },
	{ currencyCode: 'MNT', countryName: 'Mongolia', rate: 930.785 },
	{ currencyCode: 'MOP', countryName: 'Macao', rate: 2.1937 },
	{ currencyCode: 'MRU', countryName: 'Mauritania', rate: 10.7699 },
	{ currencyCode: 'MUR', countryName: 'Mauritius', rate: 12.7468 },
	{ currencyCode: 'MVR', countryName: 'Maldives', rate: 4.2003 },
	{ currencyCode: 'MWK', countryName: 'Malawi', rate: 458.6709 },
	{ currencyCode: 'MXN', countryName: 'Mexico', rate: 4.6447 },
	{ currencyCode: 'MYR', countryName: 'Malaysia', rate: 1.3017 },
	{ currencyCode: 'MZN', countryName: 'Mozambique', rate: 17.3846 },
	{ currencyCode: 'NAD', countryName: 'Namibia', rate: 5.141 },
	{ currencyCode: 'NGN', countryName: 'Nigeria', rate: 409.8867 },
	{ currencyCode: 'NIO', countryName: 'Nicaragua', rate: 10.0075 },
	{ currencyCode: 'NOK', countryName: 'Norway', rate: 2.8642 },
	{ currencyCode: 'NPR', countryName: 'Nepal', rate: 36.1786 },
	{ currencyCode: 'NZD', countryName: 'New Zealand', rate: 0.4444 },
	{ currencyCode: 'OMR', countryName: 'Oman', rate: 0.1047 },
	{ currencyCode: 'PAB', countryName: 'Panama', rate: 0.2723 },
	{ currencyCode: 'PEN', countryName: 'Peru', rate: 1.0464 },
	{ currencyCode: 'PGK', countryName: 'Papua New Guinea', rate: 1.0313 },
	{ currencyCode: 'PHP', countryName: 'Philippines', rate: 15.2354 },
	{ currencyCode: 'PKR', countryName: 'Pakistan', rate: 76.0482 },
	{ currencyCode: 'PLN', countryName: 'Poland', rate: 1.0959 },
	{ currencyCode: 'PYG', countryName: 'Paraguay', rate: 1987.3 },
	{ currencyCode: 'QAR', countryName: 'Qatar', rate: 0.9912 },
	{ currencyCode: 'RON', countryName: 'Romania', rate: 1.2583 },
	{ currencyCode: 'RSD', countryName: 'Serbia', rate: 29.6174 },
	{ currencyCode: 'RUB', countryName: 'Russia', rate: 25.0799 },
	{ currencyCode: 'RWF', countryName: 'Rwanda', rate: 346.4156 },
	{ currencyCode: 'SAR', countryName: 'Saudi Arabia', rate: 1.0211 },
	{ currencyCode: 'SBD', countryName: 'Solomon Islands', rate: 2.3211 },
	{ currencyCode: 'SCR', countryName: 'Seychelles', rate: 3.7291 },
	{ currencyCode: 'SDG', countryName: 'Sudan', rate: 124.8391 },
	{ currencyCode: 'SEK', countryName: 'Sweden', rate: 2.8413 },
	{ currencyCode: 'SGD', countryName: 'Singapore', rate: 0.3668 },
	{ currencyCode: 'SHP', countryName: 'Saint Helena', rate: 0.216 },
	{ currencyCode: 'SLE', countryName: 'Sierra Leone', rate: 6.2043 },
	{ currencyCode: 'SLL', countryName: 'Sierra Leone', rate: 6204.3463 },
	{ currencyCode: 'SOS', countryName: 'Somalia', rate: 155.4843 },
	{ currencyCode: 'SRD', countryName: 'Suriname', rate: 9.9046 },
	{ currencyCode: 'SSP', countryName: 'South Sudan', rate: 325.68 },
	{ currencyCode: 'STN', countryName: 'Sao Tome and Principe', rate: 6.1912 },
	{ currencyCode: 'SYP', countryName: 'Syria', rate: 3515.5964 },
	{ currencyCode: 'SZL', countryName: 'Eswatini', rate: 5.141 },
	{ currencyCode: 'THB', countryName: 'Thailand', rate: 9.8045 },
	{ currencyCode: 'TJS', countryName: 'Tajikistan', rate: 2.9799 },
	{ currencyCode: 'TMT', countryName: 'Turkmenistan', rate: 0.9526 },
	{ currencyCode: 'TND', countryName: 'Tunisia', rate: 0.8542 },
	{ currencyCode: 'TOP', countryName: 'Tonga', rate: 0.6362 },
	{ currencyCode: 'TRY', countryName: 'Turkey', rate: 8.4023 },
	{ currencyCode: 'TTD', countryName: 'Trinidad and Tobago', rate: 1.8424 },
	{ currencyCode: 'TVD', countryName: 'Tuvalu', rate: 0.4169 },
	{ currencyCode: 'TWD', countryName: 'Taiwan', rate: 8.5307 },
	{ currencyCode: 'TZS', countryName: 'Tanzania', rate: 691.9389 },
	{ currencyCode: 'UAH', countryName: 'Ukraine', rate: 10.3365 },
	{ currencyCode: 'UGX', countryName: 'Uganda', rate: 1059.676 },
	{ currencyCode: 'USD', countryName: 'United States', rate: 0.2723 },
	{ currencyCode: 'UYU', countryName: 'Uruguay', rate: 10.6442 },
	{ currencyCode: 'UZS', countryName: 'Uzbekistan', rate: 3408.5334 },
	{ currencyCode: 'VES', countryName: 'Venezuela', rate: 9.894 },
	{ currencyCode: 'VND', countryName: 'Vietnam', rate: 6679.8669 },
	{ currencyCode: 'VUV', countryName: 'Vanuatu', rate: 32.8365 },
	{ currencyCode: 'WST', countryName: 'Samoa', rate: 0.7426 },
	{
		currencyCode: 'XAF',
		countryName: 'Central African CFA franc',
		rate: 165.7621,
	},
	{ currencyCode: 'XCD', countryName: 'East Caribbean Dollar', rate: 0.7352 },
	{
		currencyCode: 'XDR',
		countryName: 'Special Drawing Rights',
		rate: 0.2056,
	},
	{
		currencyCode: 'XOF',
		countryName: 'West African CFA franc',
		rate: 165.7621,
	},
	{ currencyCode: 'XPF', countryName: 'CFP Franc', rate: 30.1555 },
	{ currencyCode: 'YER', countryName: 'Yemen', rate: 68.1355 },
	{ currencyCode: 'ZAR', countryName: 'South Africa', rate: 5.141 },
	{ currencyCode: 'ZMW', countryName: 'Zambia', rate: 6.823 },
	{ currencyCode: 'ZWL', countryName: 'Zimbabwe', rate: 3373.8398 },
]

function convertCurrency(baseCountry, targetCountry, amount) {
	const baseRateObj = conversionRates.find(
		(entry) => entry.countryName === baseCountry
	)

	const targetRateObj = conversionRates.find(
		(entry) => entry.countryName === targetCountry
	)

	if (!baseRateObj || !targetRateObj) {
		return null
	}

	const convertedAmount = (amount / targetRateObj.rate) * baseRateObj.rate

	return Math.ceil(convertedAmount)
}

export { conversionRates, convertCurrency }
