
import React from 'react'
import { Dimensions } from 'react-native'
import { Box, Text, Pressable, Image } from '@gluestack-ui/themed'
import useUserStore from '../../Userstore/UserStore'
import CandidateQrewObjectivesList from '../../newComponents/CandidateQrewComponents/CandidateQrewObjectiveList'
const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height
export default function CandidateQrewObjectiveScreen({route}) {
 
    const {qrewId} = route.params
   

   
  return (
    <Box
   
				minheight={windowWidth > 480 ? windowHeight : windowHeight - 50}
				// bg=
				// h={'100%'}
                height={windowWidth}
                backgroundColor="#F4FBFC"
				paddingHorizontal={windowWidth > 480 ? 70 : 10}
				paddingVertical={15}
				mb={windowWidth < 480 && 50}
				pt={windowWidth > 480 ? 50 : 30}
               
			>
      <Text
      marginTop={50}
							fontFamily="$fontHeading"
									fontSize={25}
									lineHeight={25}
									fontWeight="800"
									color={'black'}
									marginVertical={10}
								>
							Qrew Objectives
						</Text>
					
							<CandidateQrewObjectivesList qrewId={qrewId} />
						
    </Box>
  )
}