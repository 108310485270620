import React, { useState } from 'react'
import { Box } from '@gluestack-ui/themed'
import { Dimensions } from 'react-native'
import CapabilityTemplate from './CapabilityTemplate'
import ShareExperienceModal from './ShareExperience/ShareExperienceModal'
import { points } from '../../newComponents/pointsComponents/pointsconfig'
import { checkAiText } from '../../customhook/CheckAiText'
import { useEffect } from 'react'
import { updateNewhidi } from '../../graphql/custom-mutations'
import { API } from 'aws-amplify'

const windowHeight = Dimensions.get('window').height
const windowWidth = Dimensions.get('window').width

const UnlockedCapability = ({ data, getCapability, navigation, jobDomain }) => {
	const [modalOpen, setModalOpen] = useState(false)
	const [tab, setTab] = useState('Outcomes')
	const [gptGenerated, setGptGenerated] = useState(null)

	const hack = data.PointsEarned?.items[0]?.Hack === 'Yes'
	const experience = data.PointsEarned?.items[0]?.experience === 'Yes'
	const projectLink = data.PointsEarned?.items[0]?.ProjectLink === 'Yes'
	const problem = data.PointsEarned?.items[0]?.Problem === 'Yes'
	const helped = data.PointsEarned?.items[0]?.Helped === 'Yes'
	const challenge = data.PointsEarned?.items[0]?.Challenges === 'Yes'
	const peers = data.PointsEarned?.items[0]?.PeersVouch === 'Yes'

	const capabilityPoints = data?.PointsEarned?.items[0]?.CapabilityPoints
		? data?.PointsEarned?.items[0]?.CapabilityPoints
		: 100

	const pointsEarned =
		(hack ? (points.hack / 100) * capabilityPoints : 0) +
		(projectLink ? (points.projectLink / 100) * capabilityPoints : 0) +
		(challenge ? (points.challenge / 100) * capabilityPoints : 0) +
		(helped ? (points.helped / 100) * capabilityPoints : 0) +
		(experience ? (points.experience / 100) * capabilityPoints : 0) +
		(problem ? (points.problem / 100) * capabilityPoints : 0) +
		(peers ? (points.peers / 100) * capabilityPoints : 0)

	const hidi = data?.ConnectedHidis?.items[0]?.Hidi

	const experienceText = hidi?.experience

	const isAiGenerated = hidi?.AiGenerated
	const hidiId = hidi?.id

	const combinedText = `${experienceText}`

	async function updateHidi(aiGenerated, fakeProbability, realProbability) {
		const CreateUserInput = {
			id: hidiId,
			AiGenerated: aiGenerated,
			ProbabilityFake: fakeProbability,
			ProbabilityReal: realProbability,
		}
		await API.graphql({
			query: updateNewhidi,
			variables: { input: CreateUserInput },
		})
	}

	useEffect(() => {
		if (hidiId && combinedText && isAiGenerated === null) {
			checkAiText(combinedText)
				.then((response) => {
					updateHidi(
						response.isGPTGenerated,
						response.fakeProbability,
						response.realProbability
					)
					setGptGenerated(response)
				})
				.catch((err) => {
					console.error(err)
				})
		} else if (combinedText) {
			setGptGenerated(isAiGenerated)
		}
	}, [combinedText, isAiGenerated, hidi, hidiId])

	return (
		<Box h={'100%'}>
			<ShareExperienceModal
				isOpen={modalOpen}
				onClose={() => {
					setModalOpen(false)
				}}
				capabilityName={data?.CapabilityConfig?.name}
				tab={tab}
				setTab={setTab}
				capability={data}
				getCapability={() => {
					getCapability()
				}}
				navigation={navigation}
				jobDomain={jobDomain}
			/>
			<CapabilityTemplate
				capabilityText={data?.CapabilityConfig?.name}
				onPress={() => {
					setModalOpen(true)
					setTab('Problem')
				}}
				text={`Edit Experience `}
				borderColor={'$primary500'}
				bgColor={'#F2FDF1'}
				peersCount={
					data?.CapabilityConfig?.capabilitiesClaimed?.items?.length
				}
				claimed={true}
				unlocked={true}
				showTag={false}
				skillsList={data?.JoinedSkills?.items}
				isGptGenerated={gptGenerated}
			/>
		</Box>
	)
}

export default UnlockedCapability
