import { Dimensions } from 'react-native'
import React from 'react'
import { Box } from '@gluestack-ui/themed'

const WavyBg = () => {
	const windowWidth = Dimensions.get('window').width
	const windowHeight = Dimensions.get('window').height

	let controlPoint1X = windowWidth * 0.25
	let controlPoint2X = windowWidth * 0.75

	if (windowWidth < 500) {
		const stretchFactor = (480 - windowWidth) / 200
		controlPoint1X -= windowWidth * stretchFactor
		controlPoint2X += windowWidth * stretchFactor
	}

	const controlPoint1 = `${controlPoint1X},${windowHeight * 0.25}`
	const controlPoint2 = `${controlPoint2X},${windowHeight * 0.75}`

	return (
		<Box position="absolute" width="100%" height="100%" overflow="hidden">
			<svg
				viewBox={`0 0 ${windowWidth} ${windowHeight}`}
				xmlns="http://www.w3.org/2000/svg"
				style={{
					position: 'absolute',
					width: '100%',
					height: '100%',
				}}
			>
				<path
					fill="#fe8c58"
					d={`M0 ${windowHeight / 2} Q${controlPoint1}, ${
						windowWidth / 2
					} ${windowHeight / 2} Q${controlPoint2}, ${windowWidth} ${
						windowHeight / 2
					} V${windowHeight} H0 Z`}
				/>
			</svg>
		</Box>
	)
}

export default WavyBg
