export const points = {
	hack: 15,
	projectLink: 15,
	challenge: 15,
	helped: 15,
	experience: 15,
	problem: 15,
	peers: 10,
}

export const eligibilityPercent = {
	claim: 20,
	sharedHidi: 60,
	shortlisted: 10,
}
