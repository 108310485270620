import React, { useEffect, useState } from 'react'
import useUserStore from '../../../Userstore/UserStore'
import { Box } from '@gluestack-ui/themed'
import ShareSelectSkeleton from './ShareSelectSkeleton'
import { createWorkExperience, updateSkills } from './helperFunctions'

const ShareWorklPlace = ({
	setTab,
	workExperienceData,
	setWorkExperienceData,
	setSelectedWorkExperience,
	selectedWorkExperience,
	newWorkExperience,
	skillsClaimed,
}) => {
	const userId = useUserStore((state) => state.userId)
	const [inputOne, setInputOne] = useState(null)
	const [inputTwo, setInputTwo] = useState(null)
	const [startDate, setStartDate] = useState(null)
	const [endDate, setEndDate] = useState(null)
	const [presentlyWorking, setPresentlyWorking] = useState(false)

	const [experienceYears, setExperienceYears] = useState(0)

	const createWorkExperiences = () => {
		newWorkExperience.length > 0
			? newWorkExperience.map((item) => {
					const userInput = {
						UserId: userId,
						designation: item.designation,
						company: item.company,
						startDate: item.startDate,
						EndDate: item.EndDate,
						Present: item.Present,
					}
					const result = createWorkExperience(userInput)
			  })
			: null
	}

	const onPressAddButtonHandler = () => {
		const newItem = {
			id: `${inputOne}_${inputTwo}`,
			designation: inputOne,
			company: inputTwo,
			startDate: startDate,
			EndDate: endDate,
			Present: presentlyWorking,
		}
		setWorkExperienceData((prevData) => [...prevData, newItem])
		setSelectedWorkExperience(() => ({
			[`${inputOne}_${inputTwo}`]: true,
		}))
	}

	const selectedItemsWorkExp = workExperienceData?.filter(
		(skill) => selectedWorkExperience[skill.id]
	)

	const calculateExperience = () => {
		const startDate = new Date(selectedItemsWorkExp[0]?.startDate)
		let endDate

		if (selectedItemsWorkExp[0]?.Present) {
			endDate = new Date()
		} else {
			endDate = new Date(selectedItemsWorkExp[0]?.EndDate)
		}

		const diffTime = Math.abs(endDate - startDate)
		const diffYears = Math.ceil(diffTime / (1000 * 60 * 60 * 24 * 365))
		setExperienceYears(diffYears)
	}

	useEffect(() => {
		selectedItemsWorkExp && calculateExperience()
	}, [selectedItemsWorkExp])

	const updateClaimedSkills = () => {
		skillsClaimed.map((skill) => {
			const userInput = {
				UserId: userId,
				id: skill?.SkillId,
				YearsOfExperience: experienceYears,
			}
			if (skill?.Skill?.YearsOfExperience < experienceYears) {
				const result = updateSkills(userInput)
			}
		})
	}

	return (
		<Box>
			<ShareSelectSkeleton
				titleNormal1={'Select the '}
				titleColored1={'workplaces '}
				titleNormal2={'where you '}
				titleColored2={'solved '}
				titleNormal3={'similar problems.'}
				subtitle={'You can select multiple workplaces.'}
				type={'workplace'}
				labelInputOne={'Enter Job Role'}
				labelInputTwo={'Enter Company Name'}
				inputOne={inputOne}
				setInputOne={setInputOne}
				inputTwo={inputTwo}
				setInputTwo={setInputTwo}
				onBackPress={() => setTab('ProjectLink')}
				onNextPress={() => {
					setTab('Peers')
					createWorkExperiences()
					selectedWorkExperience && updateClaimedSkills()
				}}
				data={workExperienceData}
				setData={setWorkExperienceData}
				selectedItem={selectedWorkExperience}
				setSelectedItem={setSelectedWorkExperience}
				onPressAddButton={onPressAddButtonHandler}
				startDate={startDate}
				setStartDate={setStartDate}
				endDate={endDate}
				setEndDate={setEndDate}
				present={presentlyWorking}
				setPresent={setPresentlyWorking}
			/>
		</Box>
	)
}

export default ShareWorklPlace
