import React, { useState } from 'react'
import {
	AddIcon,
	Box,
	ChevronDownIcon,
	ChevronUpIcon,
	Divider, // Import the ChevronUpIcon
	Pressable,
	Text,
} from '@gluestack-ui/themed'
import { Motion, AnimatePresence } from '@legendapp/motion'

const AccordionComponent = ({ renderItem, title }) => {
	const [isOpen, setIsOpen] = useState(false)

	const toggleAccordion = () => {
		setIsOpen(!isOpen)
	}

	return (
		<Box
			flex={1}
			borderWidth={1}
			p={15}
			borderRadius={6}
			borderColor="#CFDEE8"
		>
			<Pressable
				onPress={toggleAccordion}
				flexDirection="row"
				justifyContent="space-between"
				alignItems="center"
			>
				<Text
					fontFamily="$fontHeading"
					fontSize={15}
					fontWeight="800"
					color={'$blueGray700'}
				>
					{title}
				</Text>
				<Motion.View
					animate={{ rotateZ: isOpen ? -180 : 0 }}
					transition={{ duration: 200 }}
				>
					{isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
				</Motion.View>
			</Pressable>

			<AnimatePresence>
				{isOpen && (
					<Motion.View
						initial={{ opacity: 0, y: 0 }}
						animate={{ opacity: 1, y: 7 }}
						exit={{ opacity: 0, y: 0 }}
						style={{ overflow: 'hidden', padding: 3 }}
					>
						<Divider h={0.5} mb={10} mt={17} />
						{renderItem()}
					</Motion.View>
				)}
			</AnimatePresence>
		</Box>
	)
}

export default AccordionComponent
