import React, { useState, useEffect } from 'react'
import { Dimensions, TouchableOpacity } from 'react-native'
import {
	Box,
	Text,
	ScrollView,
	FlatList,
	Pressable,
	Image,
	HStack,
	VStack,
	Divider,
	Icon,
	AddIcon,
	CheckIcon,
} from '@gluestack-ui/themed'
import LabelWithInputComponent from '../../../newComponents/LabelWithInputComponent'
import { API } from 'aws-amplify'
// import RemoveCapabilityModal from '../../../newComponents/selectCapabilities/RemoveCapabilityModal'
import RemoveCompanyCapabilityModal from './RemoveCompanyCapabilityModal'
import { deleteJoinGlobalCapabilityconfigAndJobPost } from '../../../graphql/custom-mutations'

const windowHeight = Dimensions.get('window').height
const windowWidth = Dimensions.get('window').width
const itemsPerPage = 10 // Number of items to display per page

const CompanyMultipleSelectCapability = ({
	capabilitySelected,
	setcapabilitySelected,
	data,
	selectedCapabilities,
	searchQueryText,
	setselectedCapabilities,
	handleRemoveAddedCapability
}) => {
	
	const [searchQuery, setSearchQuery] = useState(searchQueryText || '')
	const [filteredData, setFilteredData] = useState(data)
	const [currentPage, setCurrentPage] = useState(1)
	const [isEndReached, setIsEndReached] = useState(false)
	const [selectedItems, setSelectedItems] = useState(capabilitySelected || [])
	const startIndex = (currentPage - 1) * itemsPerPage
	const endIndex = startIndex + itemsPerPage
	const [randomNumbers, setRandomNumbers] = useState({})
	const [randomCapCount, setRandomCapCount] = useState({})

	const [capabilitySkills, setCapabilitySkills] = useState({})

	const [skillModal, setSkillModal] = useState(
		new Array(filteredData.length).fill(false)
	)
	const [removeCapability, setRemoveCapability] = useState(
		new Array(filteredData.length).fill(false)
	)

	useEffect(() => {
		const timer = setTimeout(() => {
			const lowerCaseQuery = searchQuery.toLowerCase()
			const filtered = data.filter((item) =>
				item.name.toLowerCase().includes(lowerCaseQuery)
			)
			setFilteredData(filtered)
		}, 300)

		return () => clearTimeout(timer)
	}, [searchQuery, data])

	const isAllIdsMatched =
		selectedCapabilities &&
		filteredData.every((item) => {
			return selectedCapabilities?.some(
				(capability) => capability.CapabilityConfig.id === item.id
			)
		})
		// const handleRemoveAddedCapability = (item) => {
		// 	console.log(item, "item")
		// 	const CreateUserInput = {
							
						
		// 		id: item
		// 	}
		// 	API.graphql({
		// 		query: deleteJoinGlobalCapabilityconfigAndJobPost,
		// 		// authMode: 'API_KEY',
		// 		variables: { input: CreateUserInput },
		// 	}).then((res) => {setselectedCapabilities(l => l.filter(data => 
		// 			{console.log(data, "data parsing")
					
						
		// 				data.id != item}))
		// 		console.log(selectedCapabilities, "selectedCapbilities")
		// 	})
			
		// 	// remove from the array by index value
			
			
		// }
	const unmatchedItems = filteredData.filter(
		(item) =>
			selectedCapabilities &&
			!selectedCapabilities?.some(
				(capability) =>
					capability.capabilitiesCapabilityConfigId === item.id
			)
	)

	const handleEndReached = () => {
		if (!isEndReached && endIndex < filteredData.length) {
			setIsEndReached(true)
			setCurrentPage(currentPage + 1)
			setIsEndReached(false)
		}
	}

	const handleItemClick = (itemId, incomingSkills = []) => {
		const updatedItems = [...selectedItems, itemId]
		setSelectedItems(updatedItems)
		setcapabilitySelected(updatedItems)

		setCapabilitySkills((prevSkills) => ({
			...prevSkills,
			[itemId]: [...(prevSkills[itemId] || []), ...incomingSkills],
		}))
	}
	const handleRemoveModalOpen = (index) => {
		const updatedModals = [...removeCapability]
		updatedModals[index] = true
		setRemoveCapability(updatedModals)
	}

	const handleRemoveModalClose = (index) => {
		const updatedModals = [...removeCapability]
		updatedModals[index] = false
		setRemoveCapability(updatedModals)
	}
	const handleItemRemove = (itemId) => {
		const updatedItems = selectedItems.filter((item) => item !== itemId)
		setSelectedItems(updatedItems)
		setcapabilitySelected(updatedItems)
	}

	


	const getOrGenerateRandomNumber = (itemId) => {
		if (!randomNumbers[itemId]) {
			const newRandomNumber = Math.floor(Math.random() * 21) + 20
			setRandomNumbers((prevNumbers) => ({
				...prevNumbers,
				[itemId]: newRandomNumber,
			}))
			return newRandomNumber
		}
		return randomNumbers[itemId]
	}
	const gnerateRandomCapCount = (itemId) => {
		if (!randomCapCount[itemId]) {
			const newRandomNumber = Math.floor(Math.random() * 10) + 10
			setRandomCapCount((prevNumbers) => ({
				...prevNumbers,
				[itemId]: newRandomNumber,
			}))
			return newRandomNumber
		}
		return randomCapCount[itemId]
	}

	const renderItem = ({ item, index }) => {
	
		const isSelected = selectedItems.includes(item.id)
		const isAlreadyaCapability = selectedCapabilities.filter(data => data.GlobalCapabilityConfigID == item.id)
	
		// const isAlreadyaCapability = selectedCapabilities.includes(item.id)
		
		return (
			<>
				<RemoveCompanyCapabilityModal
					isOpen={removeCapability[index]}
					onClose={() => handleRemoveModalClose(index)}
					onDelete={() =>  handleRemoveAddedCapability (isAlreadyaCapability[0].id)}
				/>

				<Pressable
				  
					flex={1}
					margin={10}
					borderWidth={0.5}
					borderColor ={ isAlreadyaCapability.length > 0 ? '$borderOrange100' : isSelected ? '$primary100' : '$borderOrange100'}
				
					shadowColor="#FDF0EB"
					shadowOffset={{ width: 0, height: 2 }}
					shadowOpacity={0.1}
					shadowRadius={12}
					elevation={25}
					borderRadius={15}
					justifyContent="space-between"
					onPress={() =>
						isAlreadyaCapability.length > 0 ? handleRemoveModalOpen(index) :
						isSelected
							? handleItemRemove(item.id)
							: handleItemClick(item.id)
					}
				>
					<HStack
						p={10}
						// alignItems="center"
						borderTopLeftRadius={15}
						borderTopRightRadius={15}
						gap={10}
						position="relative"
						bg="$borderBlue100"
					>
						{isSelected && (
							<Box
								p={1}
								borderWidth={1.5}
								borderRadius={50}
								borderColor="$primary300"
								bg="$primary300"
								position="absolute"
								top={-7}
								left={-7}
							>
								<Icon
									as={CheckIcon}
									size={'2xs'}
									color={'$primary0'}
								/>
							</Box>
						)}
						{isAlreadyaCapability.length > 0 &&
						<Box
						p={1}
						borderWidth={1.5}
						borderRadius={50}
						borderColor='$green400'
						bg="$green400"
						position="absolute"
						top={-7}
						left={-7}
					>
						<Icon
							as={CheckIcon}
							size={'2xs'}
							color={'$primary0'}
						/>
					</Box>
						}
						<Text
							marginVertical={5}
							fontSize={'$fontContent'}
							fontFamily="$fontHeading"
							fontWeight="600"
							color={isSelected ? '$primary950' : '$fontGray100'}
						>
							{item.name}
						</Text>
					</HStack>
				</Pressable>
			</>
		)
	}

	return (
		<Box>
			<Box>
				<LabelWithInputComponent
					placeholder={'Search capabilities...'}
					value={searchQuery}
					setValueChange={(text) => setSearchQuery(text)}
					marginVertical={0}
					type={'search'}
				/>
			</Box>
			<Box alignItems="center" justifyContent="space-evenly">
				<FlatList
					data={unmatchedItems}
					// numColumns={windowWidth > 480 ? 3 : 1}
					keyExtractor={(item) => item.id.toString()}
					renderItem={renderItem}
					maxHeight={
						windowWidth > 480
							? windowHeight * 0.5
							: windowHeight * 0.7
					}
					gap={10}
					borderWidth={1}
					showsHorizontalScrollIndicator={false}
					borderColor="$borderDark0"
					onEndReached={handleEndReached}
					onEndReachedThreshold={0.1}
				/>
			</Box>

			<Box>
				{selectedCapabilities && isAllIdsMatched && filteredData && (
					<Text
						fontFamily="$fontContent"
						fontSize={15}
						fontWeight="bold"
						textAlign="center"
					>
						No capabilities to show
					</Text>
				)}
			</Box>
		</Box>
	)
}

export default CompanyMultipleSelectCapability
