import React from 'react'
import { Box, FlatList, HStack, Text, VStack } from '@gluestack-ui/themed'
import { Dimensions } from 'react-native'
import ListEmptyComponent from '../../qrewComponents/ListEmptyComponent'
import { useState, useEffect } from 'react'
import { API, graphqlOperation } from 'aws-amplify'
import ModalComponent from '../ModalComponent'
import { updateQrewInitiative } from '../../HeplerFunctions/CandidateQrewHelperQuery'
import { updateInitiativeFunction, updateObjectiveKeyFunction, listInitiativesByObjectiveKeyResult, 
	createObjectiveKeyResulProgressHistoryFunction, } from '../../HeplerFunctions/CandidateQrewInitiativeQuery'
	import { DeleteInitiatives } from '../../HeplerFunctions/CandidateQrewHelperQuery'
 import { showToast } from '../../components/ErrorToast'
import SpinnerComponent from '../SpinnerComponent'
import ProgressBarQrew from '../../qrewComponents/ProgressBarQrew'
import LabelWithInputComponent from '../LabelWithInputComponent'
import ButtonComponent from '../ButtonComponent'
import useUserStore from '../../Userstore/UserStore'
import { onCreateInitiative } from '../../graphql/custom-subscriptions'
import { ClockIcon } from '@gluestack-ui/themed'
const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height
const CandidateQrewInitiativesList = ({ objectiveKeyResultId }) => {
	const [confirmationModal, setConfirmationModal] = useState('')
	const [loadingInitiatives, setLoadingInitiatives] = useState(false)
	const [initiativeDATA, setInitiativeDATA] = useState([])
	const [approveModal, setApproveModal] = useState('')
	const [approveBtnLoading, setApproveBtnLoading] = useState(false)
	const [rejectBtnLoading, setRejectBtnLoading] = useState(false)
    const [loadingConfirmEdit, setLoadingConfirmEdit] = useState(false)
	const [loadingDelete, setLoadingDelete] = useState(false)
	const [NewInitiativeInputDescription, setNewInitiativeinputDescription] = useState('')
    const [keyResultIdforInitiative, setkeyResultIdforInitiative] = useState('')
    const [NewInitiativeInput, setNewInitiativeinput] = useState('')
    const [InitativeTime, setInitiativeTime] = useState('')
    const [Initiativekeyresults, setInitiativeKeyResult] = useState('')
	const [createInitativeModal, setCreateInitativeModal] = useState(false)
	const [ObjectiveKeyResult, setObjecjectiveKeyResult] = useState(false)
	const [ContributionpercentageNumber, setContributionpercentageNumber] = useState('')
	const [KeyprogresssPercentage, setKeyprogresssPercentage] = useState('')
    const UserProfileId = useUserStore(state => state.profileId)
	const userProfile =useUserStore(state => state.profile)
	const initSubscriptions = async () => {
		let _subscriptions = [];
		  const subscription = API.graphql(
			graphqlOperation(
			  onCreateInitiative
			  
			),
		  ).subscribe({
			next: ({provider, value}) => {
			  
				listInitiativesByObjectiveKeyResultId()
			  
		  
			  // setIsParticipant(true);
			},
			error: error => {
				console.log(error)
			  // setConnectionStatus(false);
			  console.warn(error.error);
			},
		  });
		
		return;
	  };

	  useEffect(() =>{
		initSubscriptions()
	   }, [])
    const UpdateInitiativeForQrew = async (item) => {
	if(NewInitiativeInput) {
		try {
			setLoadingConfirmEdit(true)
		
			 
		
			const InitativeInput = {
                id : approveModal.id,
				initiative : NewInitiativeInput,
				keyResultsAcheived : Initiativekeyresults,
                TimeRequiredToComplete : InitativeTime ? InitativeTime : 0,
				initiativeDocumentation : NewInitiativeInputDescription
				
				
			
			
			}

			const resEdit = await updateQrewInitiative(InitativeInput)
			if(resEdit) {
				showToast({
					message :'Your Initiative is Updated Successfully',
					type : 'success'
				})
			}
			// const res = await updateQrewObjectives(objectiveInput)
		} catch (err) {
			console.log('Error updating objective', err)
		} finally {
			setCreateInitativeModal(false)
			setLoadingConfirmEdit(false)
			setNewInitiativeinput('')
			setInitiativeKeyResult('')
			listInitiativesByObjectiveKeyResultId()
            setApproveModal('')
            
		}
	}
	else{
		showToast({
			message : 'Pls fill mandatory fields',
			type : 'error'
		})
	}
		
	
	}
	const DeleteInitiativeForUser = async (item) => {
		if(item.approvalStatus != 'APPROVED') {
			try {
				setLoadingDelete(true)
			
				 
			
				const InitativeInput = {
					id : approveModal.id,
					
					
				
				
				}
	
				const resEdit = await DeleteInitiatives(InitativeInput)
				if(resEdit) {
					showToast({
						message :'Your Initiative is Deleted Successfully',
						type : 'success'
					})
				}
				// const res = await updateQrewObjectives(objectiveInput)
			} catch (err) {
				console.log('Error updating objective', err)
			} finally {
				setCreateInitativeModal(false)
				setLoadingDelete(false)
				setNewInitiativeinput('')
			setInitiativeKeyResult('')
			listInitiativesByObjectiveKeyResultId()
            setApproveModal('')
				
			}
		}
		else{
			showToast({
				message : 'Your Initiative is already approved cannot delete it',
				type : 'error'
			})
			setConfirmationModal('')
		}
			
		
		}
	useEffect(() => {
		if(objectiveKeyResultId) {
			listInitiativesByObjectiveKeyResultId()
		}else{
			setLoadingInitiatives(false)
		}
		
	}, [objectiveKeyResultId])
	const listInitiativesByObjectiveKeyResultId = async (props) => {
		if(props == 'NoLoading'){
			setLoadingInitiatives(false)
		}
		else{
			setLoadingInitiatives(true)
		}
		
		const response = await listInitiativesByObjectiveKeyResult(objectiveKeyResultId)
		
		setInitiativeDATA(response)
		setObjecjectiveKeyResult(response[0]?.ObjectiveKeyResult)
	
	
			setLoadingInitiatives(false)
		
		
	}
	const approveInitiative = async (PreviousContributionPercentage ,ObjectiveKeyResultId) => {
		const Status = 'APPROVED'
		setApproveBtnLoading(true)
		const input = {
			id: approveModal,
			PercentageContributionOnkeyResult : ContributionpercentageNumber,
			approvalStatus: 'APPROVED',
		}

		await updateInitiativeFunction(input)
		await UpdateKeyResultPercentage(PreviousContributionPercentage ,ObjectiveKeyResultId, Status)
		
	}
	const rejectInitiative = async (PreviousContributionPercentage ,ObjectiveKeyResultId) => {
		const Status = 'REJECTED'
		setRejectBtnLoading(true)
		const input = {
			id: approveModal,
			PercentageContributionOnkeyResult : 0,
			approvalStatus: 'REJECTED',
		}
		await updateInitiativeFunction(input)
		await UpdateKeyResultPercentage(PreviousContributionPercentage ,ObjectiveKeyResultId, Status )
	
		
	}
	const UpdateKeyResultPercentage= async (PreviousContributionPercentage ,ObjectiveKeyResultId, Status ) => {
		let ProgressPercentage = 0
		const percenatageDifference = ContributionpercentageNumber - PreviousContributionPercentage
		if(Status == 'APPROVED') {
			if(percenatageDifference < 0) {
				ProgressPercentage = KeyprogresssPercentage + percenatageDifference
			}
			else{
				ProgressPercentage = KeyprogresssPercentage + percenatageDifference
			}
		}
		else{
			ProgressPercentage = KeyprogresssPercentage - PreviousContributionPercentage
		}
		
	
		
		setApproveBtnLoading(true)
		const input = {
			id: ObjectiveKeyResultId,
			progresssPercentage : ProgressPercentage,
			
		}
		await updateObjectiveKeyFunction(input)
		
		listInitiativesByObjectiveKeyResultId("NoLoading")
		CreteKeyResultProgressHistory(ObjectiveKeyResultId )
		setRejectBtnLoading(false)
		setApproveBtnLoading(false)
		setApproveModal('')
	}
	const CreteKeyResultProgressHistory= async (ObjectiveKeyResultId) => {
		
		const input = {
			ObjectiveKeyResultId : ObjectiveKeyResultId,
			previousPercentageProgress: KeyprogresssPercentage,
		
			prevkeyResult: ObjectiveKeyResult?.keyResult,
			companyProfileId: ObjectiveKeyResult?.companyProfileId,
			companyQrewId: ObjectiveKeyResult?.companyQrewId,
			objectiveId: ObjectiveKeyResult?.objectiveId,
			ResponsibleInitiativeId : approveModal
		}
		await createObjectiveKeyResulProgressHistoryFunction(input)
		
	}
	const renderInitiatives = ({ item, index }) => {
		const submissionDate = new Date(item.updatedAt)
		const keyResultsAcheived = item.keyResultsAcheived
		const formattedSubmissionDate = submissionDate?.toLocaleDateString(
			'en-US',
			{
				
				month: 'short',
				day: 'numeric',
			}
		)
		const renderApprovalBody = () => {
			
            return (
				<Box p={windowWidth > 480 ? 30 : 15}  >
					<Text
						fontSize={18}
						fontWeight="600"
						fontFamily="$fontHeading"
					>
						Update Initiative for the following Key Result
					</Text>
                    <Text
						fontSize={14}
						fontWeight="600"
						fontFamily="$fontHeading"
					>
						Key Result : {item.ObjectiveKeyResult.keyResult}
					</Text>
                    <LabelWithInputComponent
					label={'Initiative'}
					textArea={true}
					heightTextArea={40}
					placeholder={
						'Explain your initiative.'
					}
					setValueChange={setNewInitiativeinput}
					important={true}
					value={NewInitiativeInput}
				/>
				
					<LabelWithInputComponent
					label={'Achieved key reuslts'}
					textArea={true}
					heightTextArea={70}
					placeholder={
						'Write your Achieved Key Results Here.'
					}
					setValueChange={setInitiativeKeyResult}
					important={false}
					value={Initiativekeyresults}
				/>
				<LabelWithInputComponent
					label={'How did you do it'}
					textArea={true}
					heightTextArea={100}
					placeholder={
						'Explain in Detail.'
					}
					setValueChange={setNewInitiativeinputDescription}
					important={false}
					value={NewInitiativeInputDescription}
				/>
                <Text
						fontSize={14}
						fontWeight="600"
						fontFamily="$fontHeading"
					>
					Hours Required to complete the Initative
					</Text>
					<LabelWithInputComponent
					label={'Enter Hours Required '}
					textArea={true}
					heightTextArea={40}
					placeholder={
						'Enter Hours Required.'
					}
                    setValueChange={(text) => {
						setInitiativeTime(text.replace(/[^0-9]/g, ''))
					}}
					
					important={false}
					value={InitativeTime}
				/>
                <Box>
        {/* const ModalBodyRender = () => {
	return( */}
	
	{/* ) */}
{/* } */}
                </Box>
					<HStack justifyContent="flex-end" gap={10}>
					<ButtonComponent
							text={'Delete'}
							color={'$secondary500'}
							borderColor={'$secondary500'}
							disabled={loadingDelete || loadingConfirmEdit }
							loading={loadingDelete}
							onPress={() => {
							setConfirmationModal(item)
							}}
							width={120}
						/>
						<ButtonComponent
							text={'Cancel'}
						
							onPress={() => {
								setApproveModal('')
								setCreateInitativeModal(false)
								setkeyResultIdforInitiative('')
							}}
							width={120}
						/>
						<ButtonComponent
							text={'Confirm'}
							onPress={() => {
								UpdateInitiativeForQrew()
							}}
							width={120}
							borderColor={'green'}
							color={'green'}
							disabled={loadingConfirmEdit || loadingDelete}
							loading={loadingConfirmEdit}
						/>
					</HStack>
				</Box>
			)
		}
		const renderApprovalBodyConfirmation = () => {
			
            return (
				<Box p={windowWidth > 480 ? 30 : 15}  >
					
                  
					
				
                <Text
				
						fontSize={14}
						fontWeight="600"
						fontFamily="$fontHeading"
					>
					Are You Sure You want to delete this initiative.
					</Text>
					
                <Box>
        {/* const ModalBodyRender = () => {
	return( */}
	
	{/* ) */}
{/* } */}
                </Box>
					<HStack justifyContent="flex-end" gap={10} marginTop={20}>
					<ButtonComponent
							text={'Yes'}
							color={'$secondary500'}
							borderColor={'$secondary500'}
							disabled={loadingDelete || loadingConfirmEdit }
							loading={loadingDelete}
							onPress={() => {
								DeleteInitiativeForUser(item)
							}}
							width={120}
						/>
						
						<ButtonComponent
							text={'No'}
							onPress={() => {
								setConfirmationModal('')
							}}
							width={120}
							borderColor={'green'}
							color={'green'}
							disabled={loadingConfirmEdit || loadingDelete}
							loading={loadingConfirmEdit}
						/>
					</HStack>
				</Box>
			)
		}
		return (
			<Box p={25}  borderColor="#E5E8EB" bgColor='#FFFFFF'>
				<ModalComponent
					isOpen={approveModal.id === item.id}
					onClose={() => {
						setApproveModal('')
					}}
					heading={`Update your Initiative`}
					
					renderBody={renderApprovalBody}
				/>
					<ModalComponent
					isOpen={confirmationModal.id === item.id}
					onClose={() => {
						setConfirmationModal(false)
					}}
				
					
					renderBody={renderApprovalBodyConfirmation}
				/>
				<HStack>
					
					<Text marginTop={10} marginRight={10} color='grey'>
						{index+1}.
					</Text>
					<VStack flex={3}>
					<HStack>
			<Text
			fontFamily="$fontHeading"
			fontSize={17}
			onPress={() => {
						if(item.candidateProfileId == UserProfileId){
                                        setNewInitiativeinput(item.initiative)
										setNewInitiativeinputDescription(item.initiativeDocumentation)
                                        setInitiativeKeyResult(keyResultsAcheived)
                                        setInitiativeTime(item.TimeRequiredToComplete)
                                        setApproveModal(item)
                        }
				
				
			}}
			color={item.candidateProfileId == UserProfileId ? '#4F7396' : 'lightgrey'}
			fontWeight="600"
			
			> {item.candidateProfileId == UserProfileId ? "Your Initiative" : 'Initiative'}: </Text>
			<Text
						flex={1}
				
						fontFamily="$fontHeading"
						fontSize={14}
						fontWeight="500"
					>
						{item.initiative}
					</Text>
			</HStack>
			
					<HStack>
					<Text
			fontFamily="$fontHeading"
			fontSize={17}
			
			fontWeight="600"
			
			> Key Results : </Text>
					<VStack flex={1.25} >
						{keyResultsAcheived ? (

<Text
						
fontFamily="$fontHeading"
fontSize={14}
fontWeight="500"
>
{keyResultsAcheived}
</Text>
						) : (<Text
						
						fontFamily="$fontHeading"
						fontSize={14}
						fontWeight="500"
					>
						Not Submitted
					</Text>)}
					
					</VStack>
					</HStack>
					</VStack>
				
				
					
					<Text
					flex={1}
						fontFamily="$fontHeading"
						fontSize={14}
						fontWeight="500"
						color='#4F7396'
						onPress={() => {
							window.open(
								`https://app.qrusible.com/Q-profile/${item?.InitiativeCreatedBydeployedQrew?.deployedUserProfile.UserId}`
							)
						}}
					>
						{
							item?.InitiativeCreatedBydeployedQrew
								?.deployedUserProfile.name
						}
					</Text>
					
					<Text
						flex={1}
						fontFamily="$fontHeading"
						fontSize={14}
						fontWeight="500"
					>
						{formattedSubmissionDate}
					</Text>
					{/* <Text
						flex={1}
						fontFamily="$fontHeading"
						fontSize={14}
						fontWeight="500"
					>
						{item.approvalStatus}
					</Text> */}
                    {item?.TimeRequiredToComplete ? (
						<><Text
						flex={1}
						
						fontFamily="$fontHeading"
						fontSize={12}
						fontWeight="500"
					>
						{`${item?.TimeRequiredToComplete} Hours`}
					</Text>
					
						</>
					) :(<>
					<Text
					
						flex={1}
						fontFamily="$fontHeading"
						fontSize={12}
						fontWeight="500"
					

					>
						Time Not Submitted
						
					</Text>
					
						</>)}
				<Box flex={1} alignItems='center'>
				<Text
						
						fontFamily="$fontHeading"
						fontSize={14}
						fontWeight="500"
						bgColor='#EFF9FB' borderRadius={25} 
						width={50}
						padding={'4%'}
						alignSelf='center'
						
						
			
						color='#72A2AB'
					>
						{item?.PercentageContributionOnkeyResult ? item?.PercentageContributionOnkeyResult : 0} %
					</Text>
				</Box>
					
					
					
				</HStack>
			
				
			</Box>
		)
	}
	
	return (
		<Box marginLeft={60}>
			{loadingInitiatives ? (
								<Box my={40}>
									<SpinnerComponent />
								</Box>
							) : (
			
			<FlatList
				data={initiativeDATA}
				renderItem={renderInitiatives}
				keyExtractor={(item) => item.id.toString()}
				ListEmptyComponent={() => {
					return (
						<Box mt={20} mb={40}>
							<ListEmptyComponent
								text={'No Initiatives Yet'}
								subText={
									'If there are any Initiatives, they will appear here.'
								}
							/>
						</Box>
					)
				}}
			/>
							)}
		</Box>
	)
}

export default CandidateQrewInitiativesList
