import { Dimensions } from 'react-native'
import React, { useState } from 'react'
import { useEffect } from 'react'
import {
	Box,
	HStack,
	Heading,
	Image,
	Pressable,
	Text,
} from '@gluestack-ui/themed'
import SearchAndSelectInput from './SearchAndSelectInput'
import fetchCurrenciesInfo from '../customhook/GetCurrencies'

const windowWidth = Dimensions.get('window').width

const CurrencyInput = ({
	selectedCurrencyCountry,
	setSelectedCurrencyCountry,
}) => {
	const [selectedCurrency, setSelectedCurrency] = useState(null)
	const [showSelected, setShowSelected] = useState(true)
	const [autoFocus, setAutoFocus] = useState(false)
	const [currencies, setCurrencies] = useState(null)

	const apiUrl = 'https://restcountries.com/v3.1/all'

	useEffect(() => {
		const fetchData = async () => {
			try {
				const countriesInfo = await fetchCurrenciesInfo(apiUrl)
				setCurrencies(countriesInfo)
			} catch (error) {
				console.error('Error in component:', error)
			}
		}

		fetchData()
	}, [apiUrl])

	useEffect(() => {
		if (selectedCurrencyCountry) {
			const currency = currencies?.find(
				(currency) => currency.countryName === selectedCurrencyCountry
			)
			setSelectedCurrency(currency)
		}
	}, [selectedCurrencyCountry, currencies])

	useEffect(() => {
		if (selectedCurrency) {
			setSelectedCurrencyCountry(selectedCurrency?.countryName)
		}
	}, [selectedCurrency])

	const renderSearchSelectItem = (item) => {
		return (
			<HStack p={10} gap={10} alignItems="center">
				<Image source={{ uri: item?.flag[0] }} h={20} w={20} />
				<Text fontFamily="$fontHeading">
					{item?.name}({item?.currencySymbol}) - {item?.countryName}
				</Text>
			</HStack>
		)
	}

	return (
		<Box
			flexDirection={windowWidth < 480 ? 'column' : 'row'}
			alignItems="center"
			gap={windowWidth > 480 ? 20 : 5}
			w={windowWidth < 480 && '$full'}
		>
			<Box w={windowWidth < 480 && '$full'}>
				<Text
					fontFamily="$fontContent"
					fontSize={
						windowWidth > 480
							? '$fontContent'
							: '$fontContentMobile'
					}
					fontWeight="800"
					color="$primary950"
				>
					Select your currency
				</Text>
			</Box>
			{showSelected && selectedCurrency ? (
				<Pressable
					onPress={() => {
						setShowSelected(false)
						setSelectedCurrency(null)
						setAutoFocus(true)
					}}
					marginVertical={windowWidth > 480 ? 9.7 : 5}
					w={windowWidth < 480 && '$full'}
					borderWidth={0.3}
					borderColor="$borderDark0"
					borderRadius={6}
				>
					<HStack p={10} gap={10} alignItems="center">
						<Image
							source={{ uri: selectedCurrency?.flag[0] }}
							h={20}
							w={20}
						/>
						<Text
							fontFamily="$fontHeading"
							fontWeight="500"
							fontSize={windowWidth > 480 ? 14 : 12}
						>
							{selectedCurrency?.name}(
							{selectedCurrency?.currencySymbol}) -{' '}
							{selectedCurrency?.countryName}
						</Text>
					</HStack>
				</Pressable>
			) : (
				<Box flex={1} w={windowWidth < 480 && '$full'}>
					<SearchAndSelectInput
						data={currencies}
						selectedItem={selectedCurrency}
						placeholder={
							'Search and select your preferred currency'
						}
						important={true}
						setSelectedItem={setSelectedCurrency}
						renderSearchSelectItem={renderSearchSelectItem}
						actionOnSelect={() => {
							setShowSelected(true)
							setAutoFocus(false)
						}}
						autoFocus={autoFocus}
						top={53}
					/>
				</Box>
			)}
		</Box>
	)
}

export default CurrencyInput
