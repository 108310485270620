import React, { useState } from 'react'
import {
	ArrowLeftIcon,
	Box,
	CloseIcon,
	HStack,
	Icon,
	Image,
	Pressable,
	Text,
	Heading,
	VStack,
} from '@gluestack-ui/themed'
import ButtonComponent from '../../../newComponents/ButtonComponent'
import { Dimensions } from 'react-native'
import LabelWithInputComponent from '../../../newComponents/LabelWithInputComponent'
import InfoStrip from './InfoStrip'
import EachStepPoint from '../../../newComponents/pointsComponents/EachStepPoint'
import { Motion } from '@legendapp/motion'

const windowWidth = Dimensions.get('window').width

const ShareSkeleton = ({
	prevFieldData,
	titleNormal1,
	titleNormal2,
	titleNormal3,
	titleColored1,
	titleColored2,
	subtitle,
	screen,
	wordCount,
	maxWordCount,
	placeholderInput,
	valueInput,
	setInputValueChange,
	wordBubbles,
	removeWordBubble,
	infoStripText,
	buttonText,
	buttonOnPress,
	backButtonOnPress,
	textArea,
	hideBackButton,
	disabled,
	minWordCount,
	backIcon,
	backText,
	totalCapabilityPoints,
	pointsAssigned,
	loadingBtn,
	capEditView,
	textAreaHeight,
	titleNormal4,
	infoText,
	disableCopyPaste,
	disableRightClick
}) => {
	return (
		// <Box w={'$full'} marginVertical={windowWidth < 480 && 20}>
		<Motion.View
			style={{ width: '100%', marginVertical: windowWidth < 480 && 20 }}
			initial={{
				opacity: 0,
				scale: 0.9,
			}}
			animate={{
				opacity: 1,
				scale: 1,
			}}
			transition={{
				default: {
					type: 'timing',
					duration: 350,
					damping: 30,
					stiffness: 1000,
				},
			}}
		>
			<HStack
				paddingHorizontal={
					capEditView ? 0 : windowWidth > 480 ? 25 : 15
				}
				marginVertical={windowWidth > 480 ? 5 : 15}
				alignItems="center"
			>
				<Box gap={3} flex={1}>
					<Text
						lineHeight={windowWidth > 480 ? 22 : 17}
						fontSize={windowWidth > 480 ? 18 : 14}
					>
						<Text
							fontFamily="$fontHeading"
							fontWeight={700}
							color="$primary950"
						>
							{titleNormal1}
						</Text>
						<Text
							fontFamily="$fontHeading"
							fontWeight={700}
							color="$secondary500"
						>
							{titleColored1}
						</Text>
						<Text
							fontFamily="$fontHeading"
							color="$primary950"
							fontWeight={700}
						>
							{titleNormal2}
						</Text>
						<Text
							fontFamily="$fontHeading"
							fontWeight={700}
							color="$secondary500"
						>
							{titleColored2}
						</Text>
						<Text
							fontFamily="$fontHeading"
							color="$primary950"
							fontWeight={700}
						>
							{titleNormal3}
						</Text>
					</Text>
					{titleNormal4 && (
						<Text
							fontFamily="$fontHeading"
							color="$fontGray100"
							fontWeight={700}
							fontSize={windowWidth > 480 ? 18 : 14}
						>
							{titleNormal4}
						</Text>
					)}
					{subtitle && (
						<Text
							fontFamily="$fontHeading"
							fontSize={windowWidth > 480 ? 12 : 11}
							lineHeight={14}
							color={'#999999'}
						>
							{subtitle}
						</Text>
					)}
				</Box>
				{/* {totalCapabilityPoints && (
					<EachStepPoint
						points={(pointsAssigned / 100) * totalCapabilityPoints}
					/>
				)} */}
			</HStack>
			<HStack
				alignItems="center"
				gap={10}
				flexWrap="nowrap"
				paddingHorizontal={
					capEditView ? 0 : windowWidth > 480 ? 25 : 15
				}
				marginVertical={capEditView ? 0 : 20}
			>
				{screen === 'peers' && (
					<Image
						source={require('../../../assets/Images/userBlue.png')}
						h={37}
						w={37}
					/>
				)}
				<Box flex={1}>
					<LabelWithInputComponent
					disableCopyPaste={disableCopyPaste}
					disableRightClick={disableRightClick}
						placeholder={placeholderInput}
						value={valueInput}
						setValueChange={setInputValueChange}
						wordCount={wordCount ? wordCount : null}
						maxWordCount={maxWordCount ? maxWordCount : null}
						minWordCount={minWordCount ? minWordCount : null}
						textArea={textArea ? textArea : null}
						heightTextArea={
							windowWidth > 480
								? textAreaHeight
									? textAreaHeight
									: 150
								: null
						}
					/>
					{infoText && (
						<Text
							fontFamily="$fontHeading"
							fontStyle="italic"
							fontSize={10}
							color="$secondary300"
							fontWeight="600"
							ml={5}
							mt={-10}
						>
							{infoText}
						</Text>
					)}
				</Box>
			</HStack>
			{screen === 'peers' && (
				<Box
					flexDirection="row"
					flexWrap="wrap"
					paddingHorizontal={windowWidth > 480 ? 25 : 15}
					marginTop={10}
					minHeight={50}
				>
					{wordBubbles.map((word, index) => (
						<Pressable
							key={index}
							flexDirection="row"
							alignItems="center"
							gap={7}
							onPress={() => removeWordBubble(index)}
							paddingHorizontal={10}
							borderRadius={20}
							borderWidth={2}
							borderColor="$fontGray100"
							marginRight={10}
							marginBottom={10}
							backgroundColor="$borderGrey100"
						>
							<Text
								// color="white"
								fontWeight="600"
								fontFamily="$fontHeading"
								fontSize={12}
								lineHeight={15}
							>
								{word}
							</Text>
							<Icon size="xs" as={CloseIcon} />
						</Pressable>
					))}
				</Box>
			)}

			{!capEditView && (
				<Box
					flexDirection={windowWidth > 480 ? 'row' : 'column-reverse'}
					alignItems={'center'}
					paddingHorizontal={windowWidth > 480 ? 25 : 15}
					gap={windowWidth > 480 ? 20 : 10}
					marginVertical={25}
				>
					{hideBackButton ? null : (
						<Pressable
							flexDirection="row"
							alignItems="center"
							gap={5}
							onPress={backButtonOnPress}
						>
							<Icon
								as={backIcon ? backIcon : ArrowLeftIcon}
								size={'xs'}
								color={'$primary500'}
							/>
							<Text
								fontFamily="$fontHeading"
								fontSize={12}
								color={'$primary500'}
								fontWeight="bold"
							>
								{backText ? backText : 'Back'}
							</Text>
						</Pressable>
					)}
					<ButtonComponent
						text={buttonText}
						onPress={buttonOnPress}
						width={windowWidth > 480 ? 200 : '80%'}
						disabled={disabled}
						loading={loadingBtn}
					/>
				</Box>
			)}
		</Motion.View>
	)
}

export default ShareSkeleton
