import * as React from "react";
import {
  StyleProp,
  ViewStyle,
  Image,
  StyleSheet,
  Text,
  View,
  TouchableOpacity
} from "react-native";
import { Button } from "@rneui/themed";
// import { TouchableOpacity } from "react-native-web";

const CandidateComponent = ({ style, item, navigation }) => {
    const CheckShortlisted = ({item}) => {
        let shortlisted = false
        const Response = item.jobPost.shortlistedCandidates.items.filter(
            (res) => {
                if(res.UserId == item.CandidateProfile.UserId){
                  
                    return   shortlisted = true
                      
                }
            }
          )
       
       return(
        <>
        {shortlisted ? (
            <TouchableOpacity onPress={() => navigation.push("NewProfile",{UserId : item.CandidateProfile.UserId,
                JobPostId : item.jobPost.id,
                PreferenceId : item.CompanyPreferenceId})}>
             <Text style ={{color : '#246BFD', fontFamily : 'Lexend'}}>
            Shortlisted
                </Text>
                </TouchableOpacity>
        )
        :(
        // <Button
        //     type="outline"
        //     color="#fff"
            
           
        //     containerStyle={styles.iconlyLightOutlineArrowDBtn}
        //     buttonStyle={styles.iconlyLightOutlineArrowDBtn1}
        //   />
          <TouchableOpacity
          
          onPress={() => navigation.push("NewProfile",{UserId : item.CandidateProfile.UserId,
            JobPostId : item.jobPost.id,
            PreferenceId : item.CompanyPreferenceId })}
          >
           
            <Image 
            style ={{width: 30, height : 30}}
            source={require('../assets/Images/go.png')}
            />
          </TouchableOpacity>
          )
        }
        </>
       )
    }
  return (
    <View
      style={[styles.candidatecomponent, style, styles.image123ParentFlexBox]}
    >
      <View style={styles.image123ParentFlexBox}>
        <Image
          style={styles.image123Icon}
          resizeMode="cover"
          source={require("../assets/Images/blueuserprofile.png")}
        />
        <View style={styles.ajaySharmaParent}>
          <Text style={styles.ajaySharma}>{item.CandidateProfile.name}</Text>
          <Text style={[styles.reactNativeDeveloper, styles.notEligibleTypo]}>
            {item.CandidateProfile.expertise}
          </Text>
        </View>
      </View>
      <Text style={[styles.notEligible, styles.notEligibleTypo]}>
        {
		  item?.CandidateProfile?.Capabilities?.items.filter( 
        (item) =>
        
        item?.ConnectedHidis?.items.length > 0
      ).length ?? 0}

      </Text>
      
      <CheckShortlisted 
      item ={item}
      />
      
    </View>
  );
};

const styles = StyleSheet.create({
  iconlyLightOutlineArrowDBtn: {
    position: "relative",
  },
  iconlyLightOutlineArrowDBtn1: {
    width: 24,
    height: 24,
    overflow: "hidden",
  },
  image123ParentFlexBox: {
    alignItems: "center",
    flexDirection: "row",
  },
  notEligibleTypo: {
    fontSize: 12,
    textAlign: "left",
  },
  image123Icon: {
    width: 34,
    height: 36,
  },
  ajaySharma: {
    fontSize: 14,
    color: "#000",
    textAlign: "left",
    fontFamily: "Urbanist",
    fontWeight: 'bold',
  },
  reactNativeDeveloper: {
    color: "#656565",
    marginTop: 3,
    fontFamily: "Urbanist",
    fontWeight: "500",
    fontSize: 12,
  },
  ajaySharmaParent: {
    justifyContent: "center",
    marginLeft: 5,
  },
  notEligible: {
    lineHeight: 16,
    fontWeight: "700",
    fontFamily: "Urbanist",
    color: "#464646",
  },
  candidatecomponent: {
    alignSelf: "stretch",
    justifyContent: "space-between",
    marginVertical: 20
  },
});

export default CandidateComponent;