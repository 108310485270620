import { Dimensions } from 'react-native'

import {
	ModalContent,
	Modal,
	ModalBackdrop,
	ModalHeader,
	VStack,
	HStack,
	Heading,
	Text,
	ModalBody,
	Input,
	InputField,
	Box,
	ModalCloseButton,
	Icon,
	ModalFooter,
	Button,
	ButtonText,
	CloseIcon,
	InputSlot,
	InputIcon,
	EyeIcon,
	EyeOffIcon,
} from '@gluestack-ui/themed'
import React, { useState } from 'react'
import SpinnerComponent from '../../../newComponents/SpinnerComponent'
import useUserStore from '../../../Userstore/UserStore'
import { Auth } from 'aws-amplify'

import LoadingScreen from '../../../screens/LoadingScreen'
import { showToast } from '../../../components/ErrorToast'
import { ToastContainer } from 'react-toastify'

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

const ForgotPassword = ({
	navigation,
	modalVisibilty,
	modalClose,
	ref,
	forgotPassword,
	setForgotPassword,
}) => {
	const [error, setError] = useState('')
	const [email, setEmail] = useState('')
	const [loading, setLoading] = useState(false)
	const [otp, setOtp] = useState('')
	const [isFocusedEmail, setIsFocusedEmail] = useState(false)
	const [password, setPassword] = useState('')
	const [isFocusedPassword, setIsFocusedPassword] = useState(false)
	const [isFocusedOTP, setIsFocusedOTP] = useState(false)
	const [showPassword, setShowPassword] = useState(false)

	const togglePasswordShow = () => {
		setShowPassword(!showPassword)
	}

	const handleFocusPassword = () => {
		setIsFocusedPassword(true)
	}

	const handleBlurPassword = () => {
		setIsFocusedPassword(false)
	}

	const handleFocus = () => {
		setIsFocusedEmail(true)
	}
	const handleBlur = () => {
		setIsFocusedEmail(false)
	}
	const handleFocusOTP = () => {
		setIsFocusedOTP(true)
	}
	const handleBlurOTP = () => {
		setIsFocusedOTP(false)
	}

	const sendVerification = async () => {
		if (!email) {
			showToast({ message: 'Please fill your Email to get OTP' })
		}
		setLoading(true)
		try {
			await Auth.forgotPassword(email).then((response) => {
				setLoading(false)
				setForgotPassword(true)
				console.log(response)
				setError('')
			})
		} catch (error) {
			setLoading(false)
			console.error('error verification in', error)
			setError(error.message)
			showToast({ message: `${error.message}` })
		}
	}
	const resetPass = async () => {
		setLoading(true)
		try {
			await Auth.forgotPasswordSubmit(email, otp, password).then(
				(res) => {
					setForgotPassword(false)
					modalClose()
					showToast({
						message:
							'Password reset successfully!. You can login with the new password',
							type: 'success',
					})
				}
			)
		} catch (error) {
			setLoading(false)
			console.error('error verification in', error)
			setError(error.message)
		}
	}
	return (
		<Modal
			isOpen={modalVisibilty}
			onClose={modalClose}
			minHeight={windowHeight}
			finalFocusRef={ref}
			position="fixed"
		>
			<ModalBackdrop />
			<ToastContainer />
			<ModalContent
				minWidth={windowWidth > 480 ? null : windowWidth * 0.9}
				overflow={false}
			>
				<ModalHeader>
					<Heading
						fontFamily="$fontHeading"
						fontSize={
							windowWidth > 480
								? '$fontHeading'
								: '$fontHeadingMobile'
						}
					>
						Recover Account!
					</Heading>
					<ModalCloseButton>
						<Icon as={CloseIcon} />
					</ModalCloseButton>
				</ModalHeader>
				<ModalBody>
					<VStack gap={15}>
						<Text size="sm" fontFamily="$fontContent" fontSize={12}>
							Enter the email to reset your password
						</Text>
						<Input>
							<InputField
								placeholder="Email"
								type="text"
								onChangeText={(text) => {
									setEmail(text)
								}}
							/>
						</Input>

						{forgotPassword && (
							<VStack gap={15}>
								<Input>
									<InputField
										placeholder="OTP"
										type="text"
										onChangeText={(text) => {
											setOtp(text)
										}}
									/>
								</Input>
								<Input paddingRight={10}>
									<InputField
										placeholder="New Password"
										type={
											showPassword ? 'text' : 'password'
										}
										onChangeText={(text) => {
											setPassword(text)
										}}
									/>
									<InputSlot onPress={togglePasswordShow}>
										<InputIcon
											as={
												showPassword
													? EyeIcon
													: EyeOffIcon
											}
											color={'$primary500'}
										/>
									</InputSlot>
								</Input>
							</VStack>
						)}
					</VStack>
				</ModalBody>
				<ModalFooter justifyContent="flex-end">
					<Button
						size="sm"
						action="primary"
						borderWidth={1}
						w={'fit-content'}
						onPress={forgotPassword ? resetPass : sendVerification}
						sx={{
							bg: '$primary500',
							':hover': {
								bg: '$primary400',
							},
							':active': {
								bg: '$primary500',
							},
						}}
						justifyContent="center"
						alignItems="center"
					>
						{loading ? (
							<SpinnerComponent
								color={'$primary0'}
								button={true}
							/>
						) : (
							<ButtonText
								fontSize={windowWidth > 480 ? 12 : 11}
								fontFamily="$fontContent"
							>
								{forgotPassword ? 'Reset Password' : 'Get OTP'}
							</ButtonText>
						)}
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}

export default ForgotPassword
