import { Box, FlatList, Text } from '@gluestack-ui/themed'
import React from 'react'
import { Dimensions } from 'react-native'
import ButtonComponent from '../../../newComponents/ButtonComponent'

const windowWidth = Dimensions.get('window').width

const QuestionsAsked = ({ macthedHidis, companyName }) => {
	const extractItems = (relatedHidis) => {
		const extractedItems = []

		relatedHidis.forEach((item) => {
			if (
				item &&
				item.ConnectedHidis &&
				item.ConnectedHidis.items &&
				item.ConnectedHidis.items.length > 0 &&
				item.CapabilityConfig &&
				item.CapabilityConfig.name
			) {
				const capabilityName = item.CapabilityConfig.name
				const convoItems =
					item.ConnectedHidis.items[0]?.Hidi?.ConvoOnHidi?.items

				if (Array.isArray(convoItems) && convoItems.length > 0) {
					// Attach the capability name to each conversation item
					convoItems.forEach((convoItem) => {
						extractedItems.push({
							...convoItem,
							capabilityName: capabilityName, // Add capability name here
						})
					})
				}
			}
		})

		return extractedItems
	}

	const extractedItems = extractItems(macthedHidis)

	console.log('extractedItems', extractedItems)

	const renderQuestions = ({ item }) => {
		return (
			<Box
				my={10}
				borderWidth={1}
				p={10}
				borderColor="$borderDark0"
				borderRadius={18}
				flexDirection={windowWidth > 480 ? 'row' : 'column'}
			>
				<Box gap={5} flex={1}>
					{item.Answer !== '' && (
						<Box
							bg={'$green400'}
							w={'fit-content'}
							px={5}
							py={1.5}
							borderRadius={18}
						>
							<Text color={'$light100'} fontFamily="$fontHeading">
								Answered
							</Text>
						</Box>
					)}
					<Box>
						<Text
							fontFamily="$fontHeading"
							color="#4A789C"
							fontSize={12}
							fontWeight="600"
						>
							For the capability
						</Text>
						<Text
							color={'$primary500'}
							fontFamily="$fontHeading"
							fontSize={16}
							fontWeight="600"
						>
							{item?.capabilityName}
						</Text>
					</Box>
					<Box>
						<Text
							fontFamily="$fontHeading"
							color="#4A789C"
							fontSize={12}
							fontWeight="600"
						>
							Question by {companyName}
						</Text>
						<Text
							fontFamily="$fontHeading"
							fontSize={16}
							fontWeight="600"
						>
							{item?.Question}
						</Text>
					</Box>
					{item.Answer !== '' && (
						<Box>
							<Text
								fontFamily="$fontHeading"
								color="#4A789C"
								fontSize={12}
								fontWeight="600"
							>
								Your Answer
							</Text>
							<Text
								fontFamily="$fontHeading"
								fontSize={16}
								fontWeight="600"
							>
								{item?.Answer}
							</Text>
						</Box>
					)}
				</Box>
				<Box>
					<ButtonComponent
						height={30}
						text={item.Answer !== '' ? 'Update' : 'Answer'}
						onPress={() => {
							window.location.href = `/hidi/${item.HidiId}`
						}}
						customFontSize={12}
					/>
				</Box>
			</Box>
		)
	}

	return (
		<Box display={extractedItems.length === 0 && 'none'}>
			<Text
				fontFamily="$fontHeading"
				color="$primary950"
				fontSize={16}
				fontWeight="600"
			>
				Questions Asked
			</Text>

			{extractedItems.length > 0 && (
				<FlatList
					data={extractedItems}
					renderItem={renderQuestions}
					keyExtractor={(item) => item.id.toString()}
				/>
			)}
		</Box>
	)
}

export default QuestionsAsked
