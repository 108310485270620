import React, { useEffect } from 'react'
import {
	View,
	Dimensions,
	StyleSheet,
	Text,
	TouchableOpacity,
	TextInput,
	ScrollView,
	Image,
} from 'react-native'
const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

// import { createNewhidi } from "../graphql/mutations";
import { API } from 'aws-amplify'
import { useRef, useState } from 'react'
import {
	Input,
	Button,
	Pressable,
	FlatList,
	HStack,
	VStack,
	Divider,
} from 'native-base'
// load theme styles with webpack
import 'medium-editor/dist/css/medium-editor.css'
import 'medium-editor/dist/css/themes/default.css'
import useUserStore from '../Userstore/UserStore'
import { getNewhidi } from '../graphql/queries'
// ES module
import Editor from 'react-medium-editor'
import { createNewhidi } from '../graphql/mutations'
import { createOutcome } from '../graphql/mutations'
import { deleteOutcome } from '../graphql/mutations'
import { updateNewhidi } from '../graphql/mutations'
import { ActivityIndicator } from 'react-native-web'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { title } from 'process'
import { HidiValidator } from '../components/HidiValidator'
import { formatValidationMessages } from '../HeplerFunctions/Validationhelpers'
import HidiErrorPopup from '../components/HidiErrorPopup'
import ExampleHidi from '../components/ExampleHidi'
import Hidicontainer from '../components/HidiContainer'
// import { TouchableOpacity } from "react-native-web";

export default function EditHidiScreen({ route, navigation }) {
	const { profile } = route.params
	const notify = (errorMessages) =>
		toast.warn(<HidiErrorPopup text={errorMessages} />, {
			position: 'top-right',
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: 'light',
		})
	const [heading, setHeading] = useState('')
	const [summary, setSummary] = useState('')
	const [output, setOutput] = useState('')
	const [loadingoutcome, setloadingoutcome] = useState(false)
	const [loading, setloading] = useState(false)
	const [loadingOutcomeId, setloadingOutcomeId] = useState('')
	const [background, setBackground] = useState('')
	const [screenstate, setScreenState] = useState('edit')

	const [textValue, setTextValue] = useState('')
	const [Outcomes, setOutcomes] = useState('')
	const [numInputs, setNumInputs] = useState(1)

	const refInputs = useRef([textValue])
	// const Hidicontainer = () => {
	// 	return (
	// 		<View style={styles.hidiBox}>
	// 			<Text style={styles.title}>{heading}</Text>

	// 			{Outcomes?.items?.length != 0 && (
	// 				<View style={styles.innerBox}>
	// 					<OutcomeComponent props={Outcomes} />
	// 				</View>
	// 			)}

	// 			<Text style={styles.abouttext}>{background}</Text>
	// 		</View>
	// 	)
	// }

	const OutcomeRender = ({ item, index }) => {
		const outcomeNumber = index + 1

		return (
			<View
				style={{
					width: '100%',
					justifyContent: 'space-between',
					flexDirection: 'row',
					alignItems: 'center',
				}}
			>
				<View
					style={{
						flexDirection: 'row',
						flex: 1,
						marginVertical: 12,

						borderRadius: 24,

						marginTop: 12,
						flex: 1,
						alignSelf: 'center',
					}}
				>
					<View style={styles.outcomeNumber}>
						<Text style={styles.numberText}>{outcomeNumber}</Text>
					</View>
					<Text
						style={{
							fontFamily: 'Lexend',
							fontSize: 14,
							color: '#272727',

							flexWrap: 'wrap',

							fontWeight: 400,
						}}
					>
						{item.Outcome}
					</Text>
				</View>

				{loadingOutcomeId == item.id ? (
					<ActivityIndicator />
				) : (
					<Pressable
						onPress={() => removeoutcome(item.id)}
						style={{
							marginLeft: 5,
							backgroundColor: '#FFF2F2',
							borderRadius: 200,
							padding: '1%',
							alignItems: 'center',
						}}
					>
						<Image
							style={{ height: 16, width: 16 }}
							source={require('../assets/Images/trash.png')}
						/>
					</Pressable>
				)}
			</View>
		)
	}

	async function removeoutcome(props) {
		setloadingOutcomeId(props)
		const CreateUserInput = {
			// Timelinestring: Data1,
			id: props,
		}
		await API.graphql({
			query: deleteOutcome,
			// authMode: 'API_KEY',
			variables: { input: CreateUserInput },
		}).then((res) => {
			setOutcomes(res.data.deleteOutcome.Hidi.outcomes)
			setloadingOutcomeId('')
			// setNumInputs(res.data.deleteOutcome.Hidi.outcomes.items.length)
		})
	}

	async function getnewhididata() {
		await API.graphql({
			query: getNewhidi,
			// authMode: 'API_KEY',
			variables: {
				id: route.params.hidiId,
			},
		})
			.then((res) => {
				setloading(true)
				setHeading(res.data.getNewhidi.htmlheading)
				setSummary(res.data.getNewhidi.htmlsummary)
				setBackground(res.data.getNewhidi.summary)
				//    setTextValue(res.data.getNewhidi.outcomes.items)
				setOutcomes(res.data.getNewhidi.outcomes)
				//    setNumInputs(res.data.getNewhidi.outcomes.items.length)
				setloading(false)
				// getUserData()
			})
			.catch((err) => {
				console.log(err)
				
			})
	}
	useEffect(() => {
		getnewhididata()
	}, [])

	async function updateHidi() {
		const updateProp = 'Updatehidi'
		setloading(true)
		const validationErrors = HidiValidator(
			heading,
			summary,
			numInputs,
			updateProp
		)

		if (validationErrors?.length > 0) {
			// Display validation errors using alert
			const errorMessages = formatValidationMessages(validationErrors)

			notify(errorMessages)
			setloading(false)
			return null
		} else {
			const CreateUserInput = {
				id: route.params.hidiId,
				htmlheading: heading,
				htmlsummary: summary,
				summary: background,
				// htmloutput: output,
			}
			API.graphql({
				query: updateNewhidi,
				// authMode: 'API_KEY',
				variables: { input: CreateUserInput },
			}).then((res) => {
				createOutcomes()
				// navigation.navigate("NewHIDI", {hidiId: res.data.createNewhidi.id})
			})
		}
	}
	async function createOutcomes() {
		{
			refInputs.current.map((value, i) => {
				if (value) {
					const CreateUserInput = {
						HidiId: route.params.hidiId,
						Outcome: value,
						Verified: 'notverified',

						// htmloutput: output,
					}
					API.graphql({
						query: createOutcome,
						// authMode: 'API_KEY',
						variables: { input: CreateUserInput },
					}).then((res) => {
						setloading(false)
					})
				}
			})
			setloading(false)
			navigation.push('NewHIDI', { hidiId: route.params.hidiId })
		}
	}

	const setInputValue = (index, value) => {
		// first, we are storing input value to refInputs array to track them
		const inputs = refInputs.current
		inputs[index] = value
		// we are also setting the text value to the input field onChangeText
		setTextValue(value)
	}
	const addInput = () => {
		if (textValue.length < 45) {
			const message =
				'Outcome Length (Less than 40 characters):"Hold on! What you did needs to be at least 40 characters long for a captivating Outcome." '

			return notify(message)
		}
		// add a new element in our refInputs array
		refInputs.current.push('')
		// increase the number of inputs
		setNumInputs((value) => value + 1)
	}
	const removeInput = (i) => {
		// remove from the array by index value
		refInputs.current.splice(i, 1)[0]
		// decrease the number of inputs
		setNumInputs((value) => value - 1)
	}
	const inputs = []
	for (let i = 0; i < numInputs; i++) {
		inputs.push(
			<View
				key={i}
				style={{ flexDirection: 'row', alignItems: 'center' }}
			>
				<TextInput
					style={{ ...styles.inputs, marginRight: '1.5%' }}
					onChangeText={(value) => setInputValue(i, value)}
					value={refInputs.current[i]}
					placeholder="Share the results and impact of the solution implemented."
					placeholderTextColor={'#AAA6B9'}
				/>
				{/* To remove the input */}
				{i >= 1 && (
					<Pressable
						onPress={() => removeInput(i)}
						style={{
							marginLeft: 5,
							backgroundColor: '#FFF2F2',
							borderRadius: 200,
							padding: '1%',
							alignItems: 'center',
						}}
					>
						<Image
							style={{ height: 16, width: 16 }}
							source={require('../assets/Images/trash.png')}
						/>
					</Pressable>
				)}

				{i == numInputs - 1 && (
					<Pressable
						onPress={() => addInput()}
						style={{
							marginLeft: 5,
							backgroundColor: '#DDFFDC',
							borderRadius: 200,
							padding: '1%',
							alignItems: 'center',
						}}
					>
						<Image
							style={{ height: 16, width: 16 }}
							source={require('../assets/Images/addbuttonimage.png')}
						/>
					</Pressable>
				)}
			</View>
		)
	}

	return (
		<>
			{!loading && (
				<View style={styles.container}>
					<View
						style={{
							flexDirection: windowWidth < 480 ? 'column' : 'row',
							width: windowWidth < 480 ? '100%' : '77%',
							alignSelf: 'center',
							justifyContent: 'center',
						}}
					>
						<View style={{}}>
							{screenstate == 'edit' && (
								<View
									style={{
										flex: 1,
										width: '100%',
										flexDirection: 'column',
										paddingVertical: '3%',
									}}
								>
									<View style={styles.containerheading}>
										<Text style={styles.title}>
											What did you do?
										</Text>
										<TextInput
											onChangeText={(text) =>
												setHeading(text)
											}
											placeholder="Please provide a title"
											style={{
												...styles.inputs,
												height:
													windowWidth < 480
														? windowHeight * 0.07
														: 'auto ',
											}}
											multiline={true}
											value={heading}
											outlineWidth={0}
											placeholderTextColor={'#AAA6B9'}
										/>
									</View>
									<View style={styles.containeSummary}>
										<Text style={styles.title}>
											Give a background for it
										</Text>
										<TextInput
											onChangeText={(text) =>
												setBackground(text)
											}
											placeholder="Please provide a detailed description of the issue, problem, or situation you encountered."
											style={{
												...styles.inputs,
												height: windowHeight * 0.15,
											}}
											multiline={true}
											value={background}
											placeholderTextColor={'#AAA6B9'}
										/>
									</View>
									<View style={styles.containeSummary}>
										<Text style={styles.title}>
											How did you do it?
										</Text>
										<View
											style={{
												width:
													windowWidth < 480
														? windowWidth * 0.92
														: windowWidth * 0.5,
												fontSize: 14,
												marginTop: 4,
												marginBottom: 8,
												backgroundColor: '#FAFAFA',
												paddingHorizontal: 22,
												paddingVertical: 12,
												borderRadius: 10,
											}}
										>
											<Editor
												text={summary}
												style={{
													lineheight: '1.2rem',
													color: '#424242',
													fontWeight: '400',
													placeholderTextColor:
														'#AAA6B9',
													borderRadius: 10,
													fontSize: 14,
													fontStyle: 'normal',
													outlineStyle: 'none',
													alignItems: 'center',
													justifyContent: 'left',
													fontFamily: 'Open Sans',
												}}
												onChange={(text) =>
													setSummary(text)
												}
												options={{
													toolbar: {
														buttons: [
															'bold',
															'italic',
															'underline',
															'orderedlist',
															'unorderedlist',
															'qoute',
															'ancor',
															'h3',
															'h4',
															'h5',
															'h6',
														],
														placeholder: false,
													},
													placeholder: {
														text: '',
													},

													// toolbar: { buttons: ['bold', 'italic', 'underline'] },
												}}
											/>
										</View>
									</View>
									<View style={styles.containeroutput}>
										<Text style={styles.title}>
											Outcomes
										</Text>
										<FlatList
											renderItem={OutcomeRender}
											data={Outcomes.items}
											style={{ marginBottom: 10 }}
										/>
										<Text style={styles.title}>
											What were the outcomes?
										</Text>

										<View
											contentContainerStyle={{
												flexDirection: 'row',
												flex: 1,
												marginVertical: 12,

												borderRadius: 24,
												padding: 20,
												marginLeft: '1%',
												marginRight: '1%',
												borderWidth: 1,
												borderColor: '#EEEEEE',
												marginTop: 12,
												flex: 1,
												alignSelf: 'center',
											}}
										>
											{inputs}
										</View>
									</View>
								</View>
							)}
							{screenstate == 'view' && (
								<View
									style={{
										flex: 1,
										width: '100%',
										alignItems: 'center',
										paddingVertical: '3%',
									}}
								>
									{/* <Hidicontainer  /> */}

									{/* look here */}
									<Hidicontainer
										summary={summary}
										background={background}
										refInputs={refInputs}
										heading={heading}
										profile={profile}
										outcomes={Outcomes}
										screen="edit"
									/>

									{/* <Editor
                    style={{ padding: 10, lineheight: "0%", width: (windowWidth) < 480 ? windowWidth * 0.86 : windowWidth * 0.75, alignSelf: "center" }}
                    text={summary}

                    // onChange={(text) => setOutput(text)}
                    options={{
                      toolbar: false,
                      // toolbar: { buttons: ['bold', 'italic', 'underline'] },
                      disableEditing: true,


                    }} 
                  />*/}
								</View>
							)}
						</View>

						{windowWidth <= 480 ? (
							<Divider
								thickness="1"
								bg="#eeeeee"
								orientation="horizontal"
								style={{ width: '100%', marginVertical: '3%' }}
							/>
						) : (
							<Divider
								thickness="1"
								bg="#eeeeee"
								orientation="vertical"
								style={{
									minHeight: windowHeight,
									marginHorizontal: '3%',
								}}
							/>
						)}
						<View
							style={{
								paddingVertical: '3%',
								paddingHorizontal:
									windowWidth <= 480 ? '3%' : '0%',
								alignSelf: 'flex-start',
								width: windowWidth <= 480 ? '100%' : '20%',
								marginBottom: windowWidth <= 480 ? '3%' : '0%',
							}}
						>
							<HStack
								style={{
									gap: windowWidth <= 480 ? '3%' : '4%',
									flex: 1,
									alignItems: 'center',
									width: '100%',
								}}
							>
								<TouchableOpacity
									style={styles.otherTab}
									onPress={() =>
										screenstate == 'view'
											? setScreenState('edit')
											: setScreenState('view')
									}
								>
									<Text style={styles.otherTabText}>
										{screenstate == 'view'
											? 'Edit Draft'
											: 'View Draft'}
									</Text>
								</TouchableOpacity>
								<TouchableOpacity
									style={styles.activeTab}
									onPress={() => updateHidi()}
								>
									<Text style={styles.activeTabText}>
										Publish
									</Text>
								</TouchableOpacity>
							</HStack>
							<View>
								<ExampleHidi />
							</View>
						</View>
					</View>
				</View>
			)}
			{loading && <ActivityIndicator />}
		</>
	)
}

// export default EditHidiScreen;
const styles = StyleSheet.create({
	container: {
		width: windowWidth,
		height: windowHeight,
		marginTop: 80,
	},
	containerheading: {
		width: windowWidth < 480 ? windowWidth * 0.92 : windowWidth * 0.5,

		// borderWidth: 1,
		alignSelf: 'center',
		marginVertical: 10,
	},
	containeSummary: {
		width: windowWidth < 480 ? windowWidth * 0.92 : windowWidth * 0.5,
		// height: windowHeight*0.65,
		// borderWidth: 1,
		alignSelf: 'center',
		marginVertical: 10,
	},
	containeroutput: {
		width: windowWidth < 480 ? windowWidth * 0.92 : windowWidth * 0.5,
		// height: windowHeight*0.15,
		// Height: windowHeight,
		// borderWidth: 1,
		marginVertical: 20,

		alignSelf: 'center',
	},
	containerview: {
		flexDirection: 'row',
		borderWidth: 1,
		flexWrap: 'wrap',
		width: 130,
		justifyContent: 'space-between',
		zIndex: 1,
		alignSelf: 'flex-end',
		// position: "absolute",
		top: 10,
		right: 30,
		padding: 10,
		borderRadius: 10,
	},

	title1: {
		fontSize: 17,
		paddingBottom: 20,
		fontWeight: 'bold',
	},
	title2: {
		fontSize: 17,
		paddingBottom: 20,
		fontWeight: 'bold',
	},
	input: {
		width: '100%',
		height: 40,
		borderWidth: 1,
		borderColor: '#cccccc',
		borderRadius: 8,
		marginBottom: 10,
	},
	addButton: {
		backgroundColor: 'green',
		width: 180,
		height: 40,
		padding: 10,
		alignSelf: 'center',

		justifyContent: 'center',
		borderRadius: 10,
		marginVertical: 10,
	},
	Hidititle: {
		padding: 10,
		fontSize: 18,
		fontWeight: 'bold',
		fontFamily: 'Lexend',
		paddingHorizontal: '3%',
	},

	abouttext: {
		paddingVertical: 10,
		// lineHeight: 10,
		fontSize: 14,
		color: 'rgba(91, 91, 91, 0.7)',
	},
	introtext: {
		padding: 10,
		fontWeight: '800',
		fontSize: 16,
		color: '#5B5B5B',
		fontFamily: 'Lexend',
	},
	detailscontainer: {
		width: windowWidth <= 480 ? '95%' : '60%',
		backgroundColor: '#fff',
		alignSelf: 'center',
		marginTop: 20,
		borderRadius: 15,
		borderWidth: 0.5,
		borderColor: 'lightgrey',
		// shadowColor: '#171717',
		// shadowOffset: {width: -2, height: 4},
		// shadowOpacity: 0.2,
		// shadowRadius: 3,
		marginBottom: 40,
	},
	imagedetails: {
		width: windowWidth <= 480 ? '95%' : '70%',
		height: windowWidth <= 480 ? windowWidth * 0.6 : windowWidth * 0.35,
		// padding: 10,
		alignSelf: 'center',
	},
	backgroundcontainer: {
		borderWidth: 1,
		width: '95%',
		alignSelf: 'center',
		marginVertical: 10,
		borderRadius: 15,
		borderColor: '#F1F1F1',
	},
	outcomecontainer: {
		borderWidth: 1,
		width: '95%',
		alignSelf: 'center',
		marginVertical: 10,
		borderRadius: 15,
		borderColor: '#F1F1F1',
	},
	hidiBox: {
		width: windowWidth < 480 ? windowWidth * 0.92 : windowWidth * 0.5,
		marginVertical: 12,
		flexDirection: 'column',
		borderRadius: 24,
		padding: 20,

		borderWidth: 1,
		borderColor: '#EEEEEE',
	},
	innerBox: {
		marginVertical: 12,
		flexDirection: 'column',
		borderRadius: 24,
		padding: 20,
		marginHorizontal: '3%',
		borderWidth: 1,
		borderColor: '#EEEEEE',
		marginTop: 12,
		width: '100%',
		alignSelf: 'center',
	},
	hidicontainer1: {
		// width: (windowWidth <= 480) ? "95%" : "60%",
		// height: windowHeight*0.22,

		// alignItems: "center",
		marginLeft: -1,
		paddingVertical: 20,
	},
	hidicontainer2: {
		width: '60%',
		// height: "80%",
		borderWidth: 2,
		borderRadius: 15,
		borderColor: '#F1F1F1',
	},
	title: {
		fontWeight: '900',
		fontSize: 14,
		marginTop: 4,
		marginBottom: 8,
		fontFamily: 'Urbanist',
		color: '#272727',
		// borderWidth: 1
		// marginLeft: 20,
		// padding: 10
	},
	inputs: {
		fontWeight: '400',
		fontSize: 14,
		marginTop: 4,
		marginBottom: 8,
		fontFamily: 'Open Sans',
		color: '#424242',
		backgroundColor: '#FAFAFA',
		outlineStyle: 'none',
		paddingHorizontal: 22,
		paddingVertical: 12,
		borderRadius: 10,
		width: '100%',
		lineHeight: '1.2rem',
	},
	hashtagtext: {
		fontWeight: '300',
		fontSize: 14,
		fontFamily: 'Open Sans',
		color: 'rgba(91, 91, 91, 0.7)',
		// paddingVertical: 5
		// padding: 10
	},
	impacttext: {
		fontWeight: '900',
		fontFamily: 'Open Sans Bold 700',
		fontSize: 16,
		lineHeight: 18,
		// marginVerti/cal: 2
	},
	impactcontainer: {
		marginTop: 10,
	},
	impactgreytext: {
		fontFamily: 'Open Sans',
		color: 'rgba(91, 91, 91, 0.7)',
		marginVertical: 10,
	},
	secondheader: {
		width: windowWidth <= 480 ? '90%' : '65%',
		height: 50,
		borderWidth: 1,
		alignSelf: 'center',
		borderRadius: 5,
		alignContent: 'center',
		alignItems: 'center',
		marginTop: 20,
		marginBottom: 20,
		justifyContent: 'space-evenly',
	},
	impacttext: {
		fontWeight: '900',
		fontFamily: 'Open Sans Bold 700',
		fontSize: 16,
		lineHeight: 18,
		// marginVerti/cal: 2
	},
	activeTab: {
		width: '47%',
		paddingVertical: '3%',
		paddingHorizontal: '4%',
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: 100,
		backgroundColor: '#246bfe',
		borderWidth: 2,
		borderColor: '#246bfe',
	},
	activeTabText: {
		color: '#fff',
		fontSize: 11,
		fontWeight: 400,
		fontFamily: 'Lexend',
		alignContent: 'center',
	},
	otherTab: {
		width: '47%',
		paddingVertical: '3%',
		paddingHorizontal: '4%',
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: 100,
		borderWidth: 2,
		borderColor: '#246bfe',
	},
	otherTabText: {
		color: '#246bfe',
		fontFamily: 'Lexend',
		fontSize: 11,
		fontWeight: 400,
		alignContent: 'center',
	},
	outcomeNumber: {
		borderWidth: 1,
		borderColor: '#868686',
		backgroundColor: '#868686',
		borderRadius: 50,
		height: 'fit-content',
		paddingVertical: 7,
		paddingHorizontal: 10,
		textAlign: 'center',
		marginRight: 7,
	},
	numberText: {
		textAlign: 'center',
		color: '#FFFFFF',
		fontSize: 10,
		fontWeight: 'bold',
	},
})
