export const createOutcome = /* GraphQL */ `
	mutation CreateOutcome(
		$input: CreateOutcomeInput!
		$condition: ModelOutcomeConditionInput
	) {
		createOutcome(input: $input, condition: $condition) {
			createdAt
			id
			Outcome
			HidiId
			Verified
			updatedAt
			owner
			__typename
		}
	}
`
export const createNewhidi = /* GraphQL */ `
	mutation CreateNewhidi(
		$input: CreateNewhidiInput!
		$condition: ModelNewhidiConditionInput
	) {
		createNewhidi(input: $input, condition: $condition) {
			createdAt
			Email
			id
			profileId
			name
			UserId
			summary
			description
			title
			Verified
			likes
			views
			steps
			jobs
			outcomelink
			impact
			impactOverall
			imagelink
			outcome
			pinnedFlag
			tag1
			tag2
			htmlheading
			htmlsummary
			peerEmails
			projectLink
			htmloutput
			outcomejson
			hidiTopic

			whereitwasdone
			Hack
			experience
			helped
			ProbabilityFake
			ProbabilityReal
			AiGenerated
			problem
			updatedAt
			owner
			__typename
		}
	}
`
export const createConvoOnHidi = /* GraphQL */ `
	mutation CreateConvoOnHidi(
		$input: CreateConvoOnHidiInput!
		$condition: ModelConvoOnHidiConditionInput
	) {
		createConvoOnHidi(input: $input, condition: $condition) {
			id
			Question
			Answer
			profileId
			UserId

			PreferenceId
			companyProfileId
			companyUserProfileId
			HidiId
			NewHidi {
				createdAt
				Email
				id
				profileId
				name
				UserId
				ConvoOnHidi {
					items {
						id
						Question
						Answer
						profileId
						UserId

						PreferenceId
						companyProfileId
						CompanyProfile {
							createdAt
							name
							businessUserId
							id
							preferences
							about
							updatedAt
							owner
							__typename
						}
						companyUser {
							createdAt
							username
							businessProfileId
							id
							cognitoId
							email
							updatedAt
							owner
							__typename
						}
						companyUserProfileId
						HidiId

						createdAt
						updatedAt
						owner
						__typename
					}
					nextToken
					__typename
				}
				hidiTopic
				updatedAt
				owner
				__typename
			}
			createdAt
			updatedAt
			owner
			__typename
		}
	}
`
export const updateConvoOnHidi = /* GraphQL */ `
	mutation UpdateConvoOnHidi(
		$input: UpdateConvoOnHidiInput!
		$condition: ModelConvoOnHidiConditionInput
	) {
		updateConvoOnHidi(input: $input, condition: $condition) {
			id
			Question
			Answer
			profileId
			UserId
			PreferenceId
			companyProfileId
			companyUserProfileId
			HidiId
			NewHidi {
				ConvoOnHidi {
					items {
						id
						Question
						Answer
						profileId
						UserId
						PreferenceId
						companyProfileId
						CompanyProfile {
							createdAt
							name
							businessUserId
							id
							preferences
							about
							updatedAt
							owner
							__typename
						}
						companyUser {
							createdAt
							username
							businessProfileId
							id
							cognitoId
							email
							updatedAt
							owner
							__typename
						}
						companyUserProfileId
						HidiId
						createdAt
						updatedAt
						owner
						__typename
					}
					nextToken
					__typename
				}
				hidiTopic
				updatedAt
				owner
				__typename
			}
			createdAt
			updatedAt
			owner
			__typename
		}
	}
`

export const createCapabilities = /* GraphQL */ `
	mutation CreateCapabilities(
		$input: CreateCapabilitiesInput!
		$condition: ModelCapabilitiesConditionInput
	) {
		createCapabilities(input: $input, condition: $condition) {
			createdAt
			ProfileId
			id

			userProfile {
				createdAt
				name
				UserId
				Email
				id
				phone
				about
				Capabilities {
					items {
						createdAt
						ProfileId
						id
						PointsEarned {
							items {
								id
								Project
								Hack
								ProjectLink
								Video
								Photo
								Presentation
								experience
								Claim
								Outcomes
								PeersVouch
								GlobalCapabilityConfigId
								CapabilityPoints
								UserId
								Helped
								Problem
								Challenges
								capabilityPointsCapabilityClaimedId
								createdAt
								updatedAt
								owner
								__typename
							}
							nextToken
							__typename
						}
						ConnectedHidis {
							items {
								id
								Hidi {
									createdAt
									summary
									description
									title
									imagelink
									outcome
									pinnedFlag
									tag1
									tag2
									htmlheading
									htmlsummary
									htmloutput
									ProbabilityFake
									ProbabilityReal
									AiGenerated
									peerEmails
									helped
									problem
									Hack
									experience
									projectLink

									whereitwasdone
									whereitwasdonecompany
									owner
									__typename
								}
							}
						}
						JoinedSkills {
							items {
								id
								UserId
								SkillId
								CapabilityId
								Skill {
									createdAt
									profileId
									UserId
									id
									Skill
									updatedAt
									owner
									__typename
								}
								GlobalCapabilityConfigId
								GlobalCapabilityConfigName
								createdAt
								updatedAt
								owner
								__typename
							}
							nextToken
							__typename
						}
						capabilitiesCapabilityConfigId
						CapabilityConfig {
							id
							name
							tag
							domain
							createdAt
							CapabilityPoints
							weeklyActiveOpportunity
							updatedAt
							owner
							__typename
						}
						updatedAt
						owner
						__typename
					}
					nextToken
					__typename
				}

				ConnectedUserSegments {
					items {
						UserSegmentId
						UserId
						createdAt
						updatedAt
						id
						owner
						__typename
					}
					nextToken
					__typename
				}

				updatedAt
				owner
				__typename
			}

			capabilitiesCapabilityConfigId
			CapabilityConfig {
				id
				name
				tag
				domain
				createdAt
				weeklyActiveOpportunity
				updatedAt
				owner
				__typename
			}
			ConnectedHidis {
				items {
					id
					Hidi {
						createdAt
						summary
						description
						title
						imagelink
						outcome
						pinnedFlag
						tag1
						tag2
						htmlheading
						htmlsummary
						htmloutput
						ProbabilityFake
						ProbabilityReal
						AiGenerated
						peerEmails
						helped
						problem
						Hack
						experience
						projectLink

						whereitwasdone
						whereitwasdonecompany
						owner
						__typename
					}
				}
			}
			JoinedSkills {
				items {
					id
					UserId
					SkillId
					CapabilityId
					Skill {
						createdAt
						profileId
						UserId
						id
						Skill
						updatedAt
						owner
						__typename
					}
					GlobalCapabilityConfigId
					GlobalCapabilityConfigName
					createdAt
					updatedAt
					owner
					__typename
				}
				nextToken
				__typename
			}
			updatedAt
			owner
			__typename
		}
	}
`

export const createJoinHidisAndCapabilities = /* GraphQL */ `
	mutation CreateJoinHidisAndCapabilities(
		$input: CreateJoinHidisAndCapabilitiesInput!
		$condition: ModelJoinHidisAndCapabilitiesConditionInput
	) {
		createJoinHidisAndCapabilities(input: $input, condition: $condition) {
			CapabilityId
			HidiId
			Capability {
				createdAt
				ProfileId
				id
				userProfile {
					createdAt
					name
					UserId
					Email
					id
					phone
					about
					Capabilities {
						items {
							createdAt
							ProfileId
							id
							ConnectedHidis {
								items {
									id
								}
							}
							JoinedSkills {
								items {
									id
									UserId
									SkillId
									CapabilityId
									Skill {
										createdAt
										profileId
										UserId
										id
										Skill
										updatedAt
										owner
										__typename
									}
									GlobalCapabilityConfigId
									GlobalCapabilityConfigName
									createdAt
									updatedAt
									owner
									__typename
								}
								nextToken
								__typename
							}
							capabilitiesCapabilityConfigId
							CapabilityConfig {
								id
								name
								tag
								domain
								createdAt
								weeklyActiveOpportunity
								updatedAt
								owner
								__typename
							}
							updatedAt
							owner
							__typename
						}
						nextToken
						__typename
					}
					owner
					__typename
				}
				updatedAt
				owner
				__typename
			}

			createdAt
			id
			updatedAt
			owner
			__typename
		}
	}
`

export const updateNewhidi = /* GraphQL */ `
	mutation UpdateNewhidi(
		$input: UpdateNewhidiInput!
		$condition: ModelNewhidiConditionInput
	) {
		updateNewhidi(input: $input, condition: $condition) {
			createdAt
			Email
			id
			profileId
			summary
			description
			title
			Verified
			likes
			views
			steps
			jobs
			outcomelink
			impact
			impactOverall
			imagelink
			outcome
			pinnedFlag
			tag1
			tag2
			htmlheading
			htmlsummary
			htmloutput
			peerEmails
			projectLink
			outcomejson
			hidiTopic

			whereitwasdone
			Hack

			experience
			helped
			ProbabilityFake
			ProbabilityReal
			AiGenerated
			problem
			updatedAt
			owner
			__typename
		}
	}
`

export const updateOutcome = /* GraphQL */ `
	mutation UpdateOutcome(
		$input: UpdateOutcomeInput!
		$condition: ModelOutcomeConditionInput
	) {
		updateOutcome(input: $input, condition: $condition) {
			createdAt
			id
			Outcome
			HidiId
			Verified
			updatedAt
			owner
			__typename
		}
	}
`

export const createCapabilityPoints = /* GraphQL */ `
	mutation CreateCapabilityPoints(
		$input: CreateCapabilityPointsInput!
		$condition: ModelCapabilityPointsConditionInput
	) {
		createCapabilityPoints(input: $input, condition: $condition) {
			id
			Project
			Hack
			ProjectLink
			Video
			Photo
			Presentation
			experience
			Claim
			Outcomes
			PeersVouch
			GlobalCapabilityConfigId
			CapabilityPoints
			Helped
			Problem
			Challenges
			UserId
			capabilityPointsCapabilityClaimedId
			createdAt
			updatedAt
			owner
			__typename
		}
	}
`
export const updateCapabilityPoints = /* GraphQL */ `
	mutation UpdateCapabilityPoints(
		$input: UpdateCapabilityPointsInput!
		$condition: ModelCapabilityPointsConditionInput
	) {
		updateCapabilityPoints(input: $input, condition: $condition) {
			id
			UserId
			GlobalCapabilityConfigId
			capabilityPointsCapabilityClaimedId
			Hack
			ProjectLink
			experience
			Outcomes
			PeersVouch
			Video
			Photo
			Presentation
			Claim
			Helped
			Problem
			Challenges
			Project
			CapabilityPoints
			createdAt
			updatedAt
			owner
			__typename
		}
	}
`

export const createChallenges = /* GraphQL */ `
	mutation CreateChallenges(
		$input: CreateChallengesInput!
		$condition: ModelChallengesConditionInput
	) {
		createChallenges(input: $input, condition: $condition) {
			createdAt
			id
			challenge
			HidiId
			updatedAt
			owner
			__typename
		}
	}
`
export const updateChallenges = /* GraphQL */ `
	mutation UpdateChallenges(
		$input: UpdateChallengesInput!
		$condition: ModelChallengesConditionInput
	) {
		updateChallenges(input: $input, condition: $condition) {
			createdAt
			id
			challenge
			HidiId
			updatedAt
			owner
			__typename
		}
	}
`
export const createRecommendedprofile = /* GraphQL */ `
	mutation CreateRecommendedprofile(
		$input: CreateRecommendedprofileInput!
		$condition: ModelRecommendedprofileConditionInput
	) {
		createRecommendedprofile(input: $input, condition: $condition) {
			createdAt
			businessProfileId
			businessUserId
			UserId
			candidateprofileId
			acceptanceStatus
			Reasonofrejection
			recommendedbyId
			id
			updatedAt
			owner
			JobApplicationsId
			JobPostId
			__typename
		}
	}
`

export const createShortlist = /* GraphQL */ `
	mutation CreateShortlist(
		$input: CreateShortlistInput!
		$condition: ModelShortlistConditionInput
	) {
		createShortlist(input: $input, condition: $condition) {
			createdAt
			businessProfileId
			businessUserId
			UserId
			about
			candidateprofileId
			shortlistedbyId
			JobPostId
			updatedAt
			owner
			__typename
		}
	}
`
export const createRejected = /* GraphQL */ `
	mutation CreateRejected(
		$input: CreateRejectedInput!
		$condition: ModelRejectedConditionInput
	) {
		createRejected(input: $input, condition: $condition) {
			createdAt
			businessProfileId
			businessUserId
			shortlistedbyId
			UserId
			about
			candidateprofileId
			Reasonofrejection
			recommededId
			preferencesId
			updatedAt
			owner
			__typename
		}
	}
`

export const createJobApplications = /* GraphQL */ `
	mutation CreateJobApplications(
		$input: CreateJobApplicationsInput!
		$condition: ModelJobApplicationsConditionInput
	) {
		createJobApplications(input: $input, condition: $condition) {
			id
			JobPostId
			CompanyProfileId
			ProfileId
			CompanyPreferenceId
			candidateprofile {
				createdAt
				name
				UserId
				Email
				id
				phone
				about
				JobApplications {
					items {
						id
						JobPostId
						jobPost {
							id
							CompanyPreferenceId
							companyName
							jobPostLink
							logoUrl
							htmldescription
							CompanyDescription
							AvailablityStatus
							experienceRequired
							jobType
							Location
							skills
							domain
							description
							ShortDesc
							title
							CapabilitiesRequiredJSON
							createdAt
							updatedAt
							owner
							__typename
						}
						CompanyProfileId
						ProfileId
						CompanyPreferenceId
						ApplyingStatus
						Level
						createdAt
						updatedAt
						owner
						__typename
					}
					nextToken
					__typename
				}
			}
			ApplyingStatus
			Level
			createdAt
			updatedAt
			owner
			__typename
		}
	}
`
export const updateJobApplications = /* GraphQL */ `
	mutation UpdateJobApplications(
		$input: UpdateJobApplicationsInput!
		$condition: ModelJobApplicationsConditionInput
	) {
		updateJobApplications(input: $input, condition: $condition) {
			id
			JobPostId
			ApplyingStatus
			Level
			createdAt
			updatedAt
			owner
			__typename
		}
	}
`
export const updateRecommendedprofile = /* GraphQL */ `
	mutation UpdateRecommendedprofile(
		$input: UpdateRecommendedprofileInput!
		$condition: ModelRecommendedprofileConditionInput
	) {
		updateRecommendedprofile(input: $input, condition: $condition) {
			createdAt
			businessProfileId
			businessUserId
			UserId
			candidateprofileId
			acceptanceStatus
			ReasonofShortlist
			Reasonofrejection
			acceptanceStatus_by_CompanyUser_ID
			acceptanceStatus_by_CompanyUser_name
			recommendedbyId
			preferencesId
			JobApplicationsId
			JobPostId
			new
			id
			updatedAt
			owner
			__typename
		}
	}
`

export const createCandidatePreferences = /* GraphQL */ `
	mutation CreateCandidatePreferences(
		$input: CreateCandidatePreferencesInput!
		$condition: ModelCandidatePreferencesConditionInput
	) {
		createCandidatePreferences(input: $input, condition: $condition) {
			createdAt
			id
			rolename
			jobType
			workType
			rolelevel
			benefits
			priority
			expectation
			Salaryexpectation
			ContractCompensation
			currentSalary
			companyType
			workStyle
			minimumContractPeriod
			servingNotice
			noticePeriod
			workSchedule
			Activelylooking
			contractType
			CurrencyCountry
			compensationStructure
			AvailableDate
			userDomain
			roletype
			location1
			Country
			location2
			location3
			ActivatePreference
			prevContractCount
			profileId
			updatedAt
			owner
			__typename
		}
	}
`

export const updateCandidatePreferences = /* GraphQL */ `
	mutation UpdateCandidatePreferences(
		$input: UpdateCandidatePreferencesInput!
		$condition: ModelCandidatePreferencesConditionInput
	) {
		updateCandidatePreferences(input: $input, condition: $condition) {
			createdAt
			id
			rolename
			jobType
			workType
			rolelevel
			benefits
			priority
			expectation
			Salaryexpectation
			ContractCompensation
			currentSalary
			companyType
			workStyle
			CurrencyCountry
			minimumContractPeriod
			servingNotice
			noticePeriod
			workSchedule
			Activelylooking
			Country
			contractType
			compensationStructure
			AvailableDate
			userDomain
			roletype
			location1
			location2
			location3
			ActivatePreference
			prevContractCount
			profileId
			updatedAt
			owner
			__typename
		}
	}
`

export const deleteCandidatePreferences = /* GraphQL */ `
	mutation DeleteCandidatePreferences(
		$input: DeleteCandidatePreferencesInput!
		$condition: ModelCandidatePreferencesConditionInput
	) {
		deleteCandidatePreferences(input: $input, condition: $condition) {
			createdAt
			id
			rolename
			jobType
			workType
			rolelevel
			benefits
			priority
			expectation
			Salaryexpectation
			ContractCompensation
			currentSalary
			companyType
			workStyle
			minimumContractPeriod
			servingNotice
			noticePeriod
			workSchedule
			Activelylooking
			contractType
			compensationStructure
			AvailableDate
			userDomain
			roletype
			location1
			location2
			location3
			Country
			CurrencyCountry
			ActivatePreference
			prevContractCount
			profileId

			updatedAt
			owner
			__typename
		}
	}
`

export const createSkill = /* GraphQL */ `
	mutation CreateSkill(
		$input: CreateSkillInput!
		$condition: ModelSkillConditionInput
	) {
		createSkill(input: $input, condition: $condition) {
			createdAt
			profileId
			UserId
			description
			id
			Skill
			YearsOfExperience
			updatedAt
			owner
			__typename
		}
	}
`

export const updateSkill = /* GraphQL */ `
	mutation UpdateSkill(
		$input: UpdateSkillInput!
		$condition: ModelSkillConditionInput
	) {
		updateSkill(input: $input, condition: $condition) {
			createdAt
			profileId
			UserId
			description
			YearsOfExperience
			id
			Skill
			updatedAt
			owner
			__typename
		}
	}
`

export const deleteSkill = /* GraphQL */ `
	mutation DeleteSkill(
		$input: DeleteSkillInput!
		$condition: ModelSkillConditionInput
	) {
		deleteSkill(input: $input, condition: $condition) {
			createdAt
			profileId
			UserId
			id
			updatedAt
			owner
			__typename
		}
	}
`

export const createJoinSkillandCapability = /* GraphQL */ `
	mutation CreateJoinSkillandCapability(
		$input: CreateJoinSkillandCapabilityInput!
		$condition: ModelJoinSkillandCapabilityConditionInput
	) {
		createJoinSkillandCapability(input: $input, condition: $condition) {
			id
			UserId
			SkillId
			CapabilityId
			GlobalCapabilityConfigId
			GlobalCapabilityConfigName
			createdAt
			updatedAt
			owner
			__typename
		}
	}
`

export const updateJoinSkillandCapability = /* GraphQL */ `
	mutation UpdateJoinSkillandCapability(
		$input: UpdateJoinSkillandCapabilityInput!
		$condition: ModelJoinSkillandCapabilityConditionInput
	) {
		updateJoinSkillandCapability(input: $input, condition: $condition) {
			id
			UserId
			SkillId
			CapabilityId
			GlobalCapabilityConfigId
			GlobalCapabilityConfigName
			createdAt
			updatedAt
			owner
			__typename
		}
	}
`

export const deleteJoinSkillandCapability = /* GraphQL */ `
	mutation DeleteJoinSkillandCapability(
		$input: DeleteJoinSkillandCapabilityInput!
		$condition: ModelJoinSkillandCapabilityConditionInput
	) {
		deleteJoinSkillandCapability(input: $input, condition: $condition) {
			id
			UserId
			SkillId
			CapabilityId
			GlobalCapabilityConfigId
			GlobalCapabilityConfigName
			createdAt
			updatedAt
			owner
			__typename
		}
	}
`
export const createPreferences = /* GraphQL */ `
	mutation CreatePreferences(
		$input: CreatePreferencesInput!
		$condition: ModelPreferencesConditionInput
	) {
		createPreferences(input: $input, condition: $condition) {
			createdAt
			id
			rolename
			businessUserId
			businessProfileId
			JobType
			package
			modeofwork
			maxExperienceRequired
			minExperienceRequired
			JobDomain
			owner
			__typename
		}
	}
`
export const updatePreferences = /* GraphQL */ `
	mutation UpdatePreferences(
		$input: UpdatePreferencesInput!
		$condition: ModelPreferencesConditionInput
	) {
		updatePreferences(input: $input, condition: $condition) {
			createdAt
			id
			rolename
			businessUserId
			businessProfileId
			package
			certifications
			noticeperiod
			maxExperienceRequired
			minExperienceRequired
			modeofwork
			JobDomain
			other
			previousrole
			combinedrole
			currencyCountry
			JobType
			Location
			JobPublished
			updatedAt
			owner
			__typename
		}
	}
`
export const createJobPost = /* GraphQL */ `
	mutation CreateJobPost(
		$input: CreateJobPostInput!
		$condition: ModelJobPostConditionInput
	) {
		createJobPost(input: $input, condition: $condition) {
			id
			CompanyPreferenceId

			companyName

			jobPostLink
			logoUrl
			htmldescription
			CompanyDescription
			AvailablityStatus
			experienceRequired
			jobType
			Location
			skills
			domain
			description
			ShortDesc
			title

			CapabilitiesRequiredJSON
			createdAt
			updatedAt

			owner
			__typename
		}
	}
`
export const updateJobPost = /* GraphQL */ `
	mutation UpdateJobPost(
		$input: UpdateJobPostInput!
		$condition: ModelJobPostConditionInput
	) {
		updateJobPost(input: $input, condition: $condition) {
			id
			CompanyPreferenceId

			companyName

			jobPostLink
			logoUrl
			htmldescription
			CompanyDescription
			AvailablityStatus
			experienceRequired
			jobType
			Location
			skills
			domain
			description
			ShortDesc
			title

			CapabilitiesRequiredJSON
			createdAt
			updatedAt

			owner
			__typename
		}
	}
`
export const createGlobalCapabilityConfig = /* GraphQL */ `
	mutation CreateGlobalCapabilityConfig(
		$input: CreateGlobalCapabilityConfigInput!
		$condition: ModelGlobalCapabilityConfigConditionInput
	) {
		createGlobalCapabilityConfig(input: $input, condition: $condition) {
			id
			name
			tag
			domain
			owner
			__typename
		}
	}
`
export const createJoinGlobalCapabilityconfigAndJobPost = /* GraphQL */ `
	mutation CreateJoinGlobalCapabilityconfigAndJobPost(
		$input: CreateJoinGlobalCapabilityconfigAndJobPostInput!
		$condition: ModelJoinGlobalCapabilityconfigAndJobPostConditionInput
	) {
		createJoinGlobalCapabilityconfigAndJobPost(
			input: $input
			condition: $condition
		) {
			id
			jobPostID
			GlobalCapabilityConfigID
			createdAt
			updatedAt
			owner
			__typename
		}
	}
`
export const deleteJoinGlobalCapabilityconfigAndJobPost = /* GraphQL */ `
	mutation DeleteJoinGlobalCapabilityconfigAndJobPost(
		$input: DeleteJoinGlobalCapabilityconfigAndJobPostInput!
		$condition: ModelJoinGlobalCapabilityconfigAndJobPostConditionInput
	) {
		deleteJoinGlobalCapabilityconfigAndJobPost(
			input: $input
			condition: $condition
		) {
			id
			jobPostID
			GlobalCapabilityConfigID
			createdAt
			updatedAt
			owner
			__typename
		}
	}
`

export const updatePofilePicture = /* GraphQL */ `
	mutation UpdateNewprofile(
		$input: UpdateNewprofileInput!
		$condition: ModelNewprofileConditionInput
	) {
		updateNewprofile(input: $input, condition: $condition) {
			createdAt
			imagekey
			updatedAt
			owner
			__typename
		}
	}
`
export const createDeployedQrew = /* GraphQL */ `
	mutation CreateDeployedQrew(
		$input: CreateDeployedQrewInput!
		$condition: ModelDeployedQrewConditionInput
	) {
		createDeployedQrew(input: $input, condition: $condition) {
			createdAt
			updatedAt
			companyQrewId
			businessProfileId
			candidateprofileId
			preferencesId
			recommededId
			joiningDate
			NDA
			ContractTerm
			KYCdocument
			WorkingOnit
			JobApplicationsId
			id
			owner
			__typename
		}
	}
`
export const createCompanyQrewInvoices = /* GraphQL */ `
	mutation CreateCompanyQrewInvoices(
		$input: CreateCompanyQrewInvoicesInput!
		$condition: ModelCompanyQrewInvoicesConditionInput
	) {
		createCompanyQrewInvoices(input: $input, condition: $condition) {
			id
			CtcInvoiceNumber
			PlatformInvoiceNumber
			CompanyQrewId
			businessProfileId
			CtcInvoiceS3Key
			PlarformInvoiceS3Key
			CtcTxnNumber
			PlatformTxnNumber
			CtcTxnNumberApproved
			PlatformTxnNumberApproved
			createdAt
			updatedAt
			owner
			__typename
		}
	}
`

export const updateCompanyQrewInvoices = /* GraphQL */ `
	mutation UpdateCompanyQrewInvoices(
		$input: UpdateCompanyQrewInvoicesInput!
		$condition: ModelCompanyQrewInvoicesConditionInput
	) {
		updateCompanyQrewInvoices(input: $input, condition: $condition) {
			id
			CtcInvoiceNumber
			PlatformInvoiceNumber
			CompanyQrewId
			businessProfileId
			companyName
			CtcInvoiceS3Key
			PlarformInvoiceS3Key
			CtcTxnNumber
			PlatformTxnNumber
			CtcTxnNumberApproved
			PlatformTxnNumberApproved
			createdAt
			updatedAt
			owner
			__typename
		}
	}
`

export const createObjective = /* GraphQL */ `
	mutation CreateObjective(
		$input: CreateObjectiveInput!
		$condition: ModelObjectiveConditionInput
	) {
		createObjective(input: $input, condition: $condition) {
			id
			createdAt
			updatedAt

			title
			creator

			companyProfileId
			companyQrewId

			companyUserId
			deployedQrewId
			owner
			__typename
		}
	}
`

export const updateObjective = /* GraphQL */ `
	mutation UpdateObjective(
		$input: UpdateObjectiveInput!
		$condition: ModelObjectiveConditionInput
	) {
		updateObjective(input: $input, condition: $condition) {
			id
			createdAt
			updatedAt

			title
			creator

			companyProfileId
			companyQrewId

			companyUserId
			deployedQrewId
			owner
			__typename
		}
	}
`

export const deleteObjective = /* GraphQL */ `
	mutation DeleteObjective(
		$input: DeleteObjectiveInput!
		$condition: ModelObjectiveConditionInput
	) {
		deleteObjective(input: $input, condition: $condition) {
			id
			createdAt
			updatedAt

			owner
			__typename
		}
	}
`

export const updateInitiative = /* GraphQL */ `
	mutation UpdateInitiative(
		$input: UpdateInitiativeInput!
		$condition: ModelInitiativeConditionInput
	) {
		updateInitiative(input: $input, condition: $condition) {
			id
			createdAt
			updatedAt
			approvalStatus
			initiative
			keyResultsAcheived
			deployedQrewId
			companyProfileId
			candidateProfileId
			companyQrewId
			objectiveId
			owner
			__typename
		}
	}
`

export const createInitiative = /* GraphQL */ `
	mutation CreateInitiative(
		$input: CreateInitiativeInput!
		$condition: ModelInitiativeConditionInput
	) {
		createInitiative(input: $input, condition: $condition) {
			id
			createdAt
			updatedAt
			initiative
			keyResultsAcheived
			deployedQrewId
			companyProfileId
			candidateProfileId
			companyQrewId
			objectiveId
			approvalStatus
			__typename
		}
	}
`
export const createObjectiveEditHistory = /* GraphQL */ `
	mutation CreateObjectiveEditHistory(
		$input: CreateObjectiveEditHistoryInput!
		$condition: ModelObjectiveEditHistoryConditionInput
	) {
		createObjectiveEditHistory(input: $input, condition: $condition) {
			objectiveId
			createdAt
			prevTitle
			editedbyCompanyUserProfileId
			editedbyDeployedQrewId
			owner
			__typename
		}
	}
`
export const createObjectiveKeyResult = /* GraphQL */ `
  mutation CreateObjectiveKeyResult(
    $input: CreateObjectiveKeyResultInput!
    $condition: ModelObjectiveKeyResultConditionInput
  ) {
    createObjectiveKeyResult(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      keyResult
      deployedQrewId
      companyProfileId
      candidateProfileId
      companyQrewId
      owner
      __typename
    }
  }
`;
export const updateObjectiveKeyResult = /* GraphQL */ `
  mutation UpdateObjectiveKeyResult(
    $input: UpdateObjectiveKeyResultInput!
    $condition: ModelObjectiveKeyResultConditionInput
  ) {
    updateObjectiveKeyResult(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      keyResult
      deployedQrewId
      companyProfileId
      candidateProfileId
      companyQrewId
      owner
      __typename
    }
  }
`;
export const deleteObjectiveKeyResult = /* GraphQL */ `
  mutation DeleteObjectiveKeyResult(
    $input: DeleteObjectiveKeyResultInput!
    $condition: ModelObjectiveKeyResultConditionInput
  ) {
    deleteObjectiveKeyResult(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      keyResult
      deployedQrewId
      companyProfileId
      candidateProfileId
      companyQrewId
      owner
      __typename
    }
  }
`;
export const createObjectiveKeyResultProgressHistory = /* GraphQL */ `
  mutation CreateObjectiveKeyResultProgressHistory(
    $input: CreateObjectiveKeyResultProgressHistoryInput!
    $condition: ModelObjectiveKeyResultProgressHistoryConditionInput
  ) {
    createObjectiveKeyResultProgressHistory(
      input: $input
      condition: $condition
    ) {
      id
      
      __typename
    }
  }
`;


export const deleteInitiative = /* GraphQL */ `
  mutation DeleteInitiative(
    $input: DeleteInitiativeInput!
    $condition: ModelInitiativeConditionInput
  ) {
    deleteInitiative(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      initiative
      initiativeDocumentation
      keyResultsAcheived
      deployedQrewId
      companyProfileId
      candidateProfileId
      companyQrewId
      objectiveKeyResultId
      objectiveId
      approvalStatus
      PercentageContributionOnkeyResult
      TimeRequiredToComplete
      owner
      __typename
    }
  }`

export const updateDeployedQrew = /* GraphQL */ `
mutation UpdateDeployedQrew(
  $input: UpdateDeployedQrewInput!
  $condition: ModelDeployedQrewConditionInput
) {
  updateDeployedQrew(input: $input, condition: $condition) {
	createdAt
	updatedAt
	companyQrewId
	businessProfileId
	finalCTCQrew
	candidateprofileId
	qrewPosition
	preferencesId
	recommededId
	joiningDate
	endingDate
	NDAkey
	Contractkey
	KYCdocumentkey
	WorkingOnit
	EndContract
	ContractEndedBy
	ContractPausedBy
	JobApplicationsId
   
	id
	owner
	__typename
  }
}`

export const createContractEventTable = /* GraphQL */ `
  mutation CreateContractEventTable(
    $input: CreateContractEventTableInput!
    $condition: ModelContractEventTableConditionInput
  ) {
    createContractEventTable(input: $input, condition: $condition) {
      id
      EventNameTableKeyId
      EventName
      EventAttributes
      EventCreatedbyUserCognitoId
      EventLogs
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;

export const createDeployedQrewHistory = /* GraphQL */ `
  mutation CreateDeployedQrewHistory(
    $input: CreateDeployedQrewHistoryInput!
    $condition: ModelDeployedQrewHistoryConditionInput
  ) {
    createDeployedQrewHistory(input: $input, condition: $condition) {
      createdAt
      updatedAt
      companyQrewId
      businessProfileId
      finalCTCQrew
      candidateprofileId
      qrewPosition
      preferencesId
      recommededId
      joiningDate
      endingDate
      NDAkey
      Contractkey
      KYCdocumentkey
      WorkingOnit
      EndContract
      ContractEndingDate
      CandidateAcceptanceStatus
      ExtensionDurationInMonths
      ExtensionDate
      ExtendingContract
      NewContractCTC
      ContractEndedBy
      ContractPausedBy
      JobApplicationsId
      deployedQrewId
      id
      owner
      __typename
    }
  }
`;
export const deleteCompanyprofile = /* GraphQL */ `
  mutation DeleteCompanyprofile(
    $input: DeleteCompanyprofileInput!
    $condition: ModelCompanyprofileConditionInput
  ) {
    deleteCompanyprofile(input: $input, condition: $condition) {
      createdAt
      businessUserId
      name
      companyMailId
      id
      preferences
      about
      websiteUrl
      LogoImageKey
      IndustryType
      updatedAt
      owner
      __typename
    }
  }`
