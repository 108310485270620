import { Box, HStack, Pressable, Text } from '@gluestack-ui/themed'
import { Dimensions } from 'react-native'
import React from 'react'
import useUserStore from '../../../Userstore/UserStore'
import { useEffect } from 'react'

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

const PrefBoxes = ({ counts, text, setTab, tab, screen, showCandidates }) => {
	const setSelectedOption = useUserStore((state) => state.setSelectedOption)

	const selectedOption = useUserStore((state) => state.selectedOption)

	useEffect(() => {
		setSelectedOption(selectedOption)
	})

	return (
		<HStack>
			{counts.map((item, index) => {
				return (
					<Pressable
						key={index}
						onPress={() => {
							if (screen === 'Candidates') {
								setTab(index)
							} else {
								setTab(index)
								setSelectedOption(index)
								showCandidates()
							}
						}}
						alignItems="center"
						borderWidth={2}
						borderColor={
							tab === index && screen === 'Candidates'
								? '$primary500'
								: '$borderDark0'
						}
						borderRadius={6}
						p={windowWidth > 480 ? 15 : 7}
						gap={windowWidth > 480 ? 10 : 5}
						marginHorizontal={windowWidth > 480 ? 10 : 5}
					>
						<Text
							color={
								tab === index && screen === 'Candidates'
									? '$primary500'
									: '$primary950'
							}
							fontFamily="$fontHeading"
							fontSize={
								windowWidth > 480
									? '$fontHeading'
									: '$fontHeadingMobile'
							}
							fontWeight="600"
							lineHeight={15}
						>
							{item}
						</Text>
						<Text
							color={
								tab === index && screen === 'Candidates'
									? '$primary500'
									: '$fontGray100'
							}
							fontFamily="$fontHeading"
							fontSize={windowWidth > 480 ? 12 : 10}
							fontWeight="600"
							lineHeight={15}
						>
							{text[index]}
						</Text>
					</Pressable>
				)
			})}
		</HStack>
	)
}

export default PrefBoxes
