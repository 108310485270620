

const HidiValidator = (heading, summary, numInputs, updateProp) => {
    
  const errors = [];

  if( !heading  && !summary  && numInputs ==1 ) {
    errors.push({
        field: 'all fields',
        error: 'Oops! Provide all necessary details to unlock the capabilities and let your experience shine.\n'
      });
    }
  else{
    if (heading == undefined || heading?.length < 40) {
        errors.push({
          field: 'heading',
          error: 'Hold on! "What did you do?" needs to be at least 40 characters long for a captivating introduction.\n'
        });
      }
    
      if ( summary == undefined || summary?.length < 180) {
        errors.push({
          field: 'summary',
          error: 'Almost there! Share your journey on how you did it in at least 180 characters to give readers a comprehensive insight.\n'
        });
      }
    if (updateProp == 'Updatehidi') {
        null
    } else if (numInputs == 1) {
        errors.push({
            field: 'Outcomes',
            error: 'Wait! Do not forget to include at least one outcome to showcase the impact of your story.\n'
        });
    }
  }
  




  // Add additional validation rules for taskData properties if needed

  return errors;
};
export { HidiValidator };