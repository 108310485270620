import { Dimensions } from 'react-native'
import { Box, HStack, Heading, Image, Text } from '@gluestack-ui/themed'

import React, { useEffect, useState } from 'react'

import useUserStore from '../../../Userstore/UserStore'
// import { getNewprofile } from '../../../graphql/queries'
// import {
// 	createCandidatePreferences,
// 	createProfile,
// 	updateCandidatePreferences,
// 	updateNewprofile,
// } from '../../../graphql/custom-mutations'

import { API, Storage, Auth } from 'aws-amplify'
import { showToast } from '../../../components/ErrorToast'
import AvatarComponent from '../../../newComponents/AvatarComponent'
import LabelWithInputComponent from '../../../newComponents/LabelWithInputComponent'
import DropdownComponent from '../../../newComponents/DropdownComponent'
import ButtonComponent from '../../../newComponents/ButtonComponent'
import SpinnerComponent from '../../../newComponents/SpinnerComponent'
import { createJobTraits } from '../../../graphql/mutations'
import { Get_JobPost_by_companyPreferenceId } from '../../../graphql/custom-queries'
import { createJobPost, updateJobPost } from '../../../graphql/custom-mutations'

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

export default function CreateUpdateComponentJobPost({
	handleProceed,
	handleGoBack,
	responseLambda,
	mapData,
	screen,
	hideModal,
	hiddenHeading,
	navigation,
	jobType,
	preferenceId,
	companyProfileId
}) {
	// const [user, setUser] = useState('');

	const [loading, setLoading] = useState(false)
	const [loadingPage, setLoadingPage] = useState(false)
	const [AbouttheJob, setAbouttheJob] = useState('')
	const [roleandresponsiblity, setRolesandResponsibility] = useState('')
	const[JobPostId, setJobPostId] = useState('null')

	const checkScreen = useUserStore((state) => state.checkScreen)
	const signout = useUserStore((state) => state.signOut)
	// const isJobLink = savedUrl.includes('JobDetails')


	
	const CreateJobPost = async() => {
		if(JobPostId != 'null'){
			UpdateJobPost()
		}
		else{
			console.log("increate")
			setLoading(true)
			const CreateUserInput = {
				CompanyPreferenceId: preferenceId,
				description : AbouttheJob,
				RolesandResponsibility: roleandresponsiblity

				
				
			}
			await API.graphql({
				query: createJobPost,
				variables: { input: CreateUserInput }
				
			}).then((res)=> {
				
				
				handleProceed()
				setLoading(false)
			
			})
			.catch((err) => {
				console.log('CreateJobPOst', err)
			})
		}
		
	}
	const UpdateJobPost = async() => {
		console.log("inupdate")
		setLoading(true)
			const CreateUserInput = {
				id: JobPostId,
				description : AbouttheJob,
				RolesandResponsibility: roleandresponsiblity

				
				
			}
			await API.graphql({
				query: updateJobPost,
				variables: { input: CreateUserInput }
				
			}).then((res)=> {
				
				handleProceed()
				setLoading(false)
				
			})
			.catch((err) => {
				console.log('UpdateJobPost', err)
			})
		
		
	}

	async function getJobPost() {
		setLoadingPage(true)
		
		await API.graphql({
			query: Get_JobPost_by_companyPreferenceId,
			// authMode: 'API_KEY',
			variables: {
				CompanyPreferenceId: preferenceId,
			},
		})
			.then((res) => {
			
				setJobPostId(res.data.Get_JobPost_by_companyPreferenceId.items[0].id)
				setAbouttheJob(res.data.Get_JobPost_by_companyPreferenceId.items[0].description)
				setRolesandResponsibility(res.data.Get_JobPost_by_companyPreferenceId.items[0].RolesandResponsibility )

				// setLocation(res.data.getNewprofile?.location)
				
				setLoading(false)
				setLoadingPage(false)
			})
			.catch((err) => {
				
					console.log(err)
					
			})
	}


	





	const getSession = async () => {
		try {
			await Auth.currentSession()
		}
		catch(error){
			console.log(error)
			signout()
		}
		
		
	  }
	useEffect(() => {
		getJobPost()
		// getProfile()
		// if (mapData?.length !== 0) {
		// 	setFullName(mapData?.name)
		// }
		// if (mapData?.length !== 0) {
		// 	setBio(mapData?.about)
		// }
		// if (mapData?.length !== 0) {
		// 	setExpertise(mapData?.experiences[0]?.designation)
		// }
		// if (mapData?.length !== 0) {
		// 	setPhone(mapData?.phoneNo)
		// }
		// getSession()
	}, [])



	// useEffect(() => {
	// 	const timer = setInterval(() => {
	// 		const newPercentage = filledPercentage + 20
	// 		const newTextIndex =
	// 			(textOptions.indexOf(displayText) + 1) % textOptions.length
	// 		setDisplayText(textOptions[newTextIndex])

	// 		if (newPercentage >= 95) {
	// 			setFilledPercentage(95)
	// 		} else {
	// 			setFilledPercentage(newPercentage)
	// 		}
	// 	}, 8000)

	// 	return () => clearInterval(timer)
	// }, [filledPercentage, displayText])


	return (
		<>
			<Box
				borderWidth={hiddenHeading ? null : 1}
				borderColor="$borderDark0"
				borderRadius={6}
				alignSelf="center"
				justifyContent="center"
				alignItems="center"
				w={'100%'}
				paddingVertical={windowWidth > 480 ? 25 : 10}
				paddingHorizontal={windowWidth > 480 ? 10 : 5}
				marginBottom={30}
				bg="$primary0"
			>
				
					<Box
						w={'100%'}
						alignSelf="center"
						justifyContent="center"
						alignItems="center"
					>
						
						
						
                       
						
						<Box w={windowWidth > 480 ? '80%' : '95%'}>
						<Heading
									
									fontFamily="$fontHeading"
									fontSize={
										windowWidth > 480
											? '$fontHeading'
											: '$fontHeadingMobile'
									}
									fontWeight="700"
								>
									Job Description
									
								</Heading>
                                <Text
								fontFamily="$fontContent"
								fontSize={
									windowWidth > 480
										? '$fontContent'
										: '$fontContentMobile'
								}
								fontWeight="700"
								color="$primary950"
								>
								Describe the key aspects of the role to give candidates a clear understanding.
                        </Text>
							<LabelWithInputComponent
								label={'About the Job'}
								placeholder={' Provide an overview of the position and its significance within the company.'}
								setValueChange={setAbouttheJob}
								height={100}
								important={true}
								value={AbouttheJob}
							/>
								<LabelWithInputComponent
								label={'Roles and Responsibilities'}
								placeholder={'Outline the specific duties and tasks expected from the candidate.'}
								setValueChange={setRolesandResponsibility}
								height={150}
								important={true}
								value={roleandresponsiblity}
							/>
                          
                          
                           
							
							

							
						</Box>
						<Box
							flexDirection={
								windowWidth > 650 ? 'row' : 'column-reverse'
							}
							justifyContent={'space-between'}
							w={windowWidth > 480 ? '80%' : '95%'}
						>
							
					<ButtonComponent
					marginVertical={windowWidth > 650 ? 25 : 5}
						onPress={() => handleGoBack()}
						text={'Go Back'}
						width={windowWidth > 650 ? 210 : '100%'}
						color={'$white'}
						textColor={'$primary500'}
					/>
							<ButtonComponent
								loading={loading}
								disabled={
									false
								}
								onPress={() => {
									CreateJobPost()
								}}
								text={
									
										'Save and Proceed'
										
								}
								marginVertical={windowWidth > 650 ? 25 : 5}
								width={
									windowWidth > 650
										
											? 210
											: 100
										
								}
							/>
						</Box>
					</Box>
				
			</Box>
		</>
	)
}
