import { useEffect, useState } from 'react'
import { Storage } from 'aws-amplify'
import awsmobile from '../aws-exports'
import { Amplify } from 'aws-amplify'
// Config
Amplify.configure({
	awsmobile,
})
// Create an instance of the AWS S3 service

const useImageFileLink = (fileKey) => {
	const [fileLink, setFileLink] = useState(null)
	useEffect(() => {
		const getFileLink = async () => {
			if (fileKey) {
				try {
					// Set the parameters for the getObject request
					const fileObject = await Storage.get(fileKey)

					// Set the file link
					setFileLink(fileObject)
				} catch (error) {
					console.error('Error retrieving file from AWS S3:', error)
				}
			} else {
				setFileLink(null)
			}
		}

		getFileLink()
	}, [fileKey])

	return fileLink
}

export default useImageFileLink
