import React from 'react'
import { Dimensions } from 'react-native'
import { Box, HStack, Divider, Text, VStack } from '@gluestack-ui/themed'

const windowHeight = Dimensions.get('window').height
const windowWidth = Dimensions.get('window').width

const StatCounts = ({ counts, labels }) => {
	return (
		<Box
			flexDirection="row"
			bg={'$backgroundGray'}
			borderRadius={6}
			p={windowWidth > 480 ? 20 : 10}
			flexWrap="wrap"
			// justifyContent="space-evenly"
			gap={windowWidth > 480 ? 10 : 5}
		>
			{counts.map((item, index) => {
				return (
					<Box
						key={index}
						p={windowWidth > 480 ? 15 : 7}
						borderWidth={2}
						borderColor="$borderDark100"
						gap={windowWidth > 480 ? 5 : 0}
						borderRadius={6}
					>
						<Text
							fontFamily="$fontHeading"
							color="$primary500"
							fontSize={windowWidth > 480 ? 24 : 18}
							textAlign="center"
							fontWeight="700"
						>
							{item}
						</Text>
						<Text
							color="$fontGray"
							fontSize={windowWidth > 480 ? '$fontContent' : 11}
							fontFamily="$fontContent"
							fontWeight="bold"
							// w={windowWidth > 480 ? 'auto' : 100}
						>
							{labels[index]}
						</Text>
					</Box>
				)
			})}
		</Box>
	)
}

export default StatCounts
