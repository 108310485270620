import {
	VStack,
	Box,
	Text,
	View,
	HStack,
	Divider,
	Pressable,
	Image,
} from '@gluestack-ui/themed'
import { Dimensions } from 'react-native'
import React from 'react'
import { useEffect } from 'react'
import useUserStore from '../../Userstore/UserStore'
import ProgressBarsOnboarding from './ProgressBarsOnboarding'

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

const ProgressBubbles = ({
	activeSection,
	setTotalScreen,
	setActiveSection,
}) => {
	const savedUrl = useUserStore((state) => state.savedurl)
	const sections = ['Profile', 'Preferences']

	const images = [
		require('../../assets/Images/onboardingCheckGreen.png'),
		require('../../assets/Images/onboardingCheckGray.png'),
	]

	// const sections = savedUrl.includes('opportunities')
	// 	? ['Profile', 'Preferences']
	// 	: ['Profile', 'Preferences', 'Capabilities']
	// const images = savedUrl.includes('opportunities')
	// 	? [
	// 			require('../../assets/Images/userOnboarding.png'),
	// 			require('../../assets/Images/preferencesOnboarding.png'),
	// 	  ]
	// 	: [
	// 			require('../../assets/Images/userOnboarding.png'),
	// 			require('../../assets/Images/preferencesOnboarding.png'),
	// 			require('../../assets/Images/capabilitiesOnboarding.png'),
	// 	  ]

	// 	  [
	// 		require('../../assets/Images/resumeOnboarding.png'),
	// 		require('../../assets/Images/userOnboarding.png'),
	// 		require('../../assets/Images/capabilitiesOnboarding.png'),
	//   ]

	const timeTaken = [1, 2, 1]
	const numSections = sections.length

	useEffect(() => {
		setTotalScreen(numSections)
	})

	const renderBubbles = () => {
		return sections.map((section, index) => (
			<VStack
				key={index}
				w={`${80 / numSections - 1}%`}
				marginVertical={30}
				justifyContent="center"
				alignItems="center"
			>
				<HStack alignItems="center" justifyContent="center" w={'49%'}>
					<Divider h={index === 0 ? 0 : 0.5} />

					{activeSection === index ? (
						<Image source={images[1]} h={30} w={30} />
					) : activeSection > index ? (
						<Image source={images[0]} h={30} w={30} />
					) : (
						<Image source={images[1]} h={30} w={30} />
					)}

					<Divider h={index === numSections - 1 ? 0 : 0.5} />
				</HStack>

				<Text fontFamily="$fontHeading" fontWeight="600" marginTop={10}>
					{section}
				</Text>
				<Text
					color="$primary500"
					fontFamily="$fontHeading"
					fontSize={13}
					fontWeight="600"
				>
					{'<'} {timeTaken[index]} min
				</Text>
			</VStack>
		))
	}

	return (
		<>
			{savedUrl.includes('JobDetails') ? (
				<Box
					w={windowWidth > 480 ? '70%' : '95%'}
					mr={windowWidth > 480 ? null : 10}
					alignSelf="center"
				>
					<ProgressBarsOnboarding
						progressBarFirst={activeSection > 0 ? 100 : 0}
						progressBarSecond={activeSection > 1 ? 100 : 0}
						progressBarThird={activeSection > 2 ? 100 : 0}
						progressBarFourth={0}
					/>
				</Box>
			) : windowWidth >= 480 ? (
				<HStack justifyContent="center" alignItems="center" w={'100%'}>
					{renderBubbles()}
				</HStack>
			) : (
				<Box
					justifyContent="center"
					alignItems="center"
					margin={10}
					padding={10}
				>
					<HStack
						justifyContent="center"
						alignItems="center"
						w={'49%'}
					>
						{activeSection !== 0 ? (
							<Divider h={0.5} />
						) : (
							<Box w={'100%'}></Box>
						)}

						<Image source={images[1]} h={30} w={30} />
						{activeSection !== numSections - 1 ? (
							<Divider h={0.5} />
						) : (
							<Box w={'100%'}></Box>
						)}
					</HStack>
					<Text
						fontFamily="$fontHeading"
						fontWeight="600"
						// marginTop={10}
					>
						{sections[activeSection]}
					</Text>
					<Text
						color="$primary500"
						fontFamily="$fontHeading"
						fontSize={13}
						fontWeight="600"
					>
						{'<'} {timeTaken[activeSection]} min
					</Text>
				</Box>
			)}
		</>
	)
}

export default ProgressBubbles
