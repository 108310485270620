import React from 'react'
import Preferencescontainer from './PreferencesComponent'
import { Dimensions } from 'react-native'
import { Box, HStack, Pressable, Text } from '@gluestack-ui/themed'
import { MobileHeader } from '../../newComponents/header/Header'
import useUserStore from '../../Userstore/UserStore'
const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

function CandidateList({ navigation, route }) {
	const { item, companyname, daysPassed } = route.params
	const typeofuser = useUserStore((state) => state.group)

	const BreadCrumbs = () => {
		return (
			<HStack marginHorizontal={5}>
				<Pressable
					onPress={() => {
						if(typeofuser == "QrusibleSuperAdmin") {
							navigation.push('AllCompany')

						}else{
							navigation.push('PreferencesScreen')
						}
						
					}}
				>
					<Text
						fontFamily={'$fontContent'}
						fontSize={
							windowWidth > 480
								? '$fontContent'
								: '$fontContentMobile'
						}
						fontWeight="600"
					>
						Home
					</Text>
				</Pressable>
				<Text
					fontSize={
						windowWidth > 480
							? '$fontContent'
							: '$fontContentMobile'
					}
					fontFamily="$fontContent"
					fontWeight="600"
				>
					{' / '}
				</Text>
				<Text
					fontSize={
						windowWidth > 480
							? '$fontContent'
							: '$fontContentMobile'
					}
					fontFamily="$fontContent"
					fontWeight="600"
					underline
				>
					Candidates
				</Text>
			</HStack>
		)
	}

	return (
		<Box>
			<Box zIndex={1}>
				<MobileHeader
					props={'Candidates List'}
					navigation={navigation}
				/>
			</Box>

			<Box
				bg="$backgroundGray"
				minHeight={windowHeight - 62}
				marginTop={62}
				// marginBottom={windowWidth > 480 ? null : 50}
				paddingHorizontal={windowWidth > 480 ? 100 : 5}
				paddingVertical={windowWidth > 480 ? 20 : 5}
				paddingBottom={windowWidth > 480 ? null : 50}
			>
				<BreadCrumbs />
				<Preferencescontainer
					screen={'Candidates'}
					companyname={companyname}
					daysPassed={daysPassed}
					props={item}
				/>
			</Box>
		</Box>
	)
}

export default CandidateList
