import { Dimensions } from 'react-native'
import { Box, HStack, Heading, Image, Text } from '@gluestack-ui/themed'

import React, { useEffect, useState } from 'react'

import useUserStore from '../../../Userstore/UserStore'
// import { getNewprofile } from '../../../graphql/queries'
// import {
// 	createCandidatePreferences,
// 	createProfile,
// 	updateCandidatePreferences,
// 	updateNewprofile,
// } from '../../../graphql/custom-mutations'

import { API, Storage, Auth } from 'aws-amplify'
import { createNewprofile } from '../../../graphql/mutations'
import { useDropzone } from 'react-dropzone'
import { showToast } from '../../../components/ErrorToast'

import UploadComponent from '../../../newComponents/UploadComponent'
import AvatarComponent from '../../../newComponents/AvatarComponent'
import LabelWithInputComponent from '../../../newComponents/LabelWithInputComponent'
import DropdownComponent from '../../../newComponents/DropdownComponent'
import ButtonComponent from '../../../newComponents/ButtonComponent'
import SpinnerComponent from '../../../newComponents/SpinnerComponent'
import { getPreferences } from '../../../graphql/custom-queries'
import UploadResume from '../../onboardingFlow/UploadResume'
import { createPreferences } from '../../../graphql/custom-mutations'
import { updatePreferences } from '../../../graphql/custom-mutations'

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

export default function CreateUpdatePreferences({
	handleProceed,
	handleGoBack,
	responseLambda,
	mapData,
	screen,
	hideModal,
	hiddenHeading,
	navigation,
	jobType,
	preferenceId,
	setpreferenceId,
	companyProfileId
	
}) {
	
	// const [user, setUser] = useState('');

	const [loading, setLoading] = useState(false)
	const [loadingPage, setLoadingPage] = useState(false)
	const setUserProfile = useUserStore((state) => state.setUserProfile)
	const setDomain = useUserStore((state) => state.setDomain)

	const savedUrl = useUserStore((state) => state.savedurl)
	const [fullName, setFullName] = useState('')
	
	
	const [expertise, setExpertise] = useState('')
	// const [location, setLocation] = useState('')
	
	const companyUserId = useUserStore((state) => state.companyuserprofileId)
	// const companyProfileId = useUserStore((state) => state.companyProfileId)

	const [JobTitle, setJobTitle] = useState('')
	const [Location, setLocation] = useState('')
	const [Compensation, setCompensation] = useState(0)
	

	const [userpreferences, setUserpreferences] = useState({
		experienceMax: '',
		experienceMin: '',
		domain: '',
		userpreferenceId: '',
		jobType: '',
		workType: ''
	})

	

	const setProfileId = useUserStore((state) => state.setProfileId)
	const user = useUserStore((state) => state.user)
	const userEmail = useUserStore((state) => state.userEmail)

	const DropdownJobType = [
		
		{ label: 'FullTime', value: 'FullTime' },
		{ label: 'Contract', value: 'Contract' },
		
	]
	const Dropdowndomain = [
		{ label: 'Full Stack', value: 'fullstack' },
		{ label: 'Cyber Security', value: 'cybersecurity' },
		{ label: 'HR', value: 'hr' },
		{ label: 'Content', value: 'content' },
		{ label: 'Ai and Blockchain', value: 'ai' },
	]
	const DropdownExperienceList = [
		{ label: 'Fresher', value: 'Fresher' },
		{ label: '1 Year', value: '1 Year' },
		{ label: '2 Years', value: '2 Years' },
		{ label: '3 Years', value: '3 Years' },
		{ label: '4 Years', value: '4 Years' },
		{ label: '5 Years', value: '5 Years' },
		{ label: '6 Years', value: '6 Years' },
		{ label: '7 Years', value: '7 Years' },
		{ label: '8 Years', value: '8 Years' },
		{ label: '9 Years', value: '9 Years' },
		{ label: '10 Years', value: '10 Years' },
		{ label: '10+ Years', value: '10+ Years' },
	]
	const DropdownworkTypeListCommon = [
		{ label: 'Remote', value: 'Remote' },
		{ label: 'Onsite', value: 'Onsite' },
		{ label: 'Any', value: 'Any' },
	]
	const isOpportunities = savedUrl.includes('opportunities')
	const isJobLink = savedUrl.includes('JobDetails')

	const navToFeed = () => {
		navigation.push('Feed')
	}

	const CreateJobpreferences = async() => {
		if(preferenceId != undefined){

			UpdateJobpreferences()
		}
		else{
			setLoading(true)
			const CreateUserInput = {
			
				rolename: JobTitle,
				businessUserId: companyUserId,
				businessProfileId: companyProfileId,
				JobDomain: userpreferences.domain,
				JobType : userpreferences.jobType,
				package : Compensation,
				modeofwork: userpreferences.workType,
				maxExperienceRequired : parseInt(userpreferences.experienceMax),
				minExperienceRequired : parseInt(userpreferences.experienceMin),
				Location: Location,
				JobPublished: false
				
			}
			await API.graphql({
				query: createPreferences,
				variables: { input: CreateUserInput }
				
			}).then((res)=> {
				setpreferenceId(res.data.createPreferences.id)
				setLoading(false)
		handleProceed() }
		)
			.catch((err) => {
				console.log('Createpreferences', err)
			})
		}
		
	}

	const UpdateJobpreferences = async() => {
		setLoading(true)
		const CreateUserInput = {
			id: preferenceId,
			rolename: JobTitle,
			// businessUserId: companyUserId,
			// businessProfileId: companyProfileId,
			JobDomain: userpreferences.domain,
			JobType : userpreferences.jobType,
			package : Compensation,
			modeofwork: userpreferences.workType,
			maxExperienceRequired : parseInt(userpreferences.experienceMax),
    		minExperienceRequired : parseInt(userpreferences.experienceMin),
			Location: Location

			
		}
		await API.graphql({
			query: updatePreferences,
			variables: { input: CreateUserInput }
			
		}).then((res)=>  {
		setpreferenceId(res.data.updatePreferences.id)
		handleProceed()
		setLoading(false)
	})
		.catch((err) => {
			console.log('updatepreferences', err)
		})
	}

	

	async function getJobPreferences() {
		setLoadingPage(true)
		await API.graphql({
			query: getPreferences,
			// authMode: 'API_KEY',
			variables: {
				id: preferenceId,
			},
		})
			.then((res) => {
			
				setJobTitle(res.data.getPreferences.rolename)

				// setLocation(res.data.getNewprofile?.location)
				setUserpreferences({
					// workTypeList: res.data.getNewprofile?.typeofwork,
					experienceMax: `${res.data.getPreferences.maxExperienceRequired} Years`,
						experienceMin: `${res.data.getPreferences.minExperienceRequired} Years`,
						domain: res.data.getPreferences.JobDomain,

					jobType: res.data.getPreferences.JobType,
					workType: res.data.getPreferences.modeofwork
				})
				
				setCompensation(res.data.getPreferences.package)
				setLocation(res.data.getPreferences.Location)

				setLoading(false)
				setLoadingPage(false)
			})
			.catch((err) => {
				
					console.log(err)
					
			})
	}

	async function CreateProfile() {
		setLoading(true)

		const CreateUserInput = {
			name: fullName,
			about: bio,
			expertise: expertise,
			UserId: userId,
			Email: userEmail,
			// location: location,
			// typeofwork: userpreferences.workTypeList,
			phone: phone,
			experience: userpreferences.experienceList,
		}
		API.graphql({
			query: createNewprofile,
			variables: { input: CreateUserInput },
		})
			.then((res) => {
				setProfileId(res.data.createNewprofile.id)
				!userpreferences.userpreferenceId
					? userpreferences.domain &&
					  Createpreferences({ id: res.data.createNewprofile.id })
					: UpdateCandidatePreferences({
							id: userpreferences.userpreferenceId,
					  })
				handleProceed && handleProceed()
				setLoading(false)
			})
			.catch((err) => {
				console.log('CreateProfile', err)
			})
	}

	async function updateProfile() {
		if (ProfileId) {
			setLoading(true)
			//const _updatedAttributes = JSON.parse(JSON.stringify(updatedAttributes));
			// console.log(_updatedAttributes);
			const CreateUserInput = {
				id: ProfileId,
				name: fullName,
				about: bio,
				expertise: expertise,
				// location: location,
				// typeofwork: userpreferences.workTypeList,
				phone: phone,
				experience: userpreferences.experienceList,

				// htmloutput: output,
			}
			API.graphql({
				query: updateNewprofile,
				variables: { input: CreateUserInput },
			})
				.then((res) => {
					setProfileId(res.data.updateNewprofile.id)
					!userpreferences.userpreferenceId
						? userpreferences.domain &&
						  Createpreferences({
								id: res.data.updateNewprofile.id,
						  })
						: UpdateCandidatePreferences({
								id: userpreferences.userpreferenceId,
						  })
					setUserProfile(res.data.updateNewprofile)
					setLoading(false)
					{
						screen !== 'onboarding' && hideModal()
					}
					{
						screen === 'onboarding' && handleProceed()
					}
				})
				.catch((err) => {
					console.log('updateProfile', err)
				})
		} else {
			CreateProfile()
		}
	}


	useEffect(() => {
		getJobPreferences()
		// getProfile()
		// if (mapData?.length !== 0) {
		// 	setFullName(mapData?.name)
		// }
		// if (mapData?.length !== 0) {
		// 	setBio(mapData?.about)
		// }
		// if (mapData?.length !== 0) {
		// 	setExpertise(mapData?.experiences[0]?.designation)
		// }
		// if (mapData?.length !== 0) {
		// 	setPhone(mapData?.phoneNo)
		// }
		// getSession()
	}, [])

	

	// useEffect(() => {
	// 	const timer = setInterval(() => {
	// 		const newPercentage = filledPercentage + 20
	// 		const newTextIndex =
	// 			(textOptions.indexOf(displayText) + 1) % textOptions.length
	// 		setDisplayText(textOptions[newTextIndex])

	// 		if (newPercentage >= 95) {
	// 			setFilledPercentage(95)
	// 		} else {
	// 			setFilledPercentage(newPercentage)
	// 		}
	// 	}, 8000)

	// 	return () => clearInterval(timer)
	// }, [filledPercentage, displayText])

	const countWords = (text) => {
		const words = text.trim().split(/\s+/)
		return words.filter((word) => word !== '').length
	}

	return (
		<>
			<Box
				borderWidth={hiddenHeading ? null : 1}
				borderColor="$borderDark0"
				borderRadius={6}
				alignSelf="center"
				justifyContent="center"
				alignItems="center"
				w={'100%'}
				paddingVertical={windowWidth > 480 ? 25 : 10}
				paddingHorizontal={windowWidth > 480 ? 10 : 5}
				marginBottom={30}
				bg="$primary0"
			>
				
					<Box
						w={'100%'}
						alignSelf="center"
						justifyContent="center"
						alignItems="center"
					>
				
						
                       
						
						<Box w={windowWidth > 480 ? '80%' : '95%'}>

						<Heading
									
									fontFamily="$fontHeading"
									fontSize={
										windowWidth > 480
											? '$fontHeading'
											: '$fontHeadingMobile'
									}
									fontWeight="700"
								>
									Basic Info
									
								</Heading>
                               
								<Text
						fontFamily="$fontContent"
						fontSize={
							windowWidth > 480
								? '$fontContent'
								: '$fontContentMobile'
						}
						fontWeight="700"
						color="$primary950"
					>
                        Provide essential information about the job to attract suitable candidates.
                        </Text>
							
							<LabelWithInputComponent
								label={'Job Title'}
								placeholder={' Enter Job Title'}
								setValueChange={setJobTitle}
								important={true}
								value={JobTitle}
							/>
                            <HStack >
							<DropdownComponent
                            
                            width={'45%'}
								label={'Work arrangement:'}
								placeholder={'Select your preferred thing'}
								userpreferences={userpreferences}
								setUserPreferences={setUserpreferences}
								property={'workType'}
								data={DropdownworkTypeListCommon}
								important={true}
							/>
                            <Text>
                                '       '
                            </Text>
                            <LabelWithInputComponent
                            width={'45%'}
								label={'Location'}
								placeholder={'Location'}
								important={true}
								setValueChange={setLocation}
								value={Location}
							/>
                            </HStack>
                            <DropdownComponent
                            
                            width={'45%'}
								label={'Job Type:'}
								placeholder={'Select your preferred domain'}
								userpreferences={userpreferences}
								setUserPreferences={setUserpreferences}
								property={'jobType'}
								data={DropdownJobType}
								important={true}
							/>
                              <HStack  width={'100%'}>
							  <DropdownComponent
							   width={'45%'}
								label={'Max Experience'}
								placeholder={'Select Experience'}
								data={DropdownExperienceList}
								property={'experienceMax'}
								userpreferences={userpreferences}
								setUserPreferences={setUserpreferences}
								important={true}
							/>
                            <Text>
                                '       '
                            </Text>
							<DropdownComponent
							 width={'45%'}
								label={'Min Experience'}
								placeholder={'Select Experience'}
								data={DropdownExperienceList}
								property={'experienceMin'}
								userpreferences={userpreferences}
								setUserPreferences={setUserpreferences}
								important={true}
							/>
                            </HStack>
							<DropdownComponent
								label={'Domain:'}
								placeholder={'Select your preferred domain'}
								userpreferences={userpreferences}
								setUserPreferences={setUserpreferences}
								property={'domain'}
								data={Dropdowndomain}
								important={true}
							/>
							<LabelWithInputComponent
								label={'Compensation'}
								placeholder={' Enter Compensation in numbers'}
								setValueChange={setCompensation}
								important={true}
								value={Compensation}
							/>
							{/* <LabelWithInputComponent
								label={'Location'}
								placeholder={'Current Location'}
								setValueChange={setLocation}
								value={location}
							/> */}
							{/* <DropdownComponent
								label={'Mode of Work'}
								placeholder={'Current Mode of Work'}
								property={'workTypeList'}
								data={DropdownworkTypeListCommon}
								userpreferences={userpreferences}
								setUserPreferences={setUserpreferences}
							/> */}
							
							
							

							
						</Box>
						<Box
							flexDirection={
								windowWidth > 650 ? 'row' : 'column-reverse'
							}
							justifyContent={'space-between'}
							w={windowWidth > 480 ? '80%' : '95%'}
						>
							{screen === 'onboarding' ? (
								<Box> </Box>
							) : (
								<Box> </Box>
							)}

							<ButtonComponent
								loading={loading}
								disabled={
									false
								}
								onPress={() => {
									CreateJobpreferences()
								}}
								text={
								
										'Save and Proceed'
									
								}
								marginVertical={windowWidth > 650 ? 25 : 5}
								width={
									windowWidth > 650
										
											? 210
											: 100
										
								}
							/>
						</Box>
					</Box>
				
			</Box>
		</>
	)
}
