import React, { useEffect, useState } from 'react'
import { Dimensions } from 'react-native'
import { Box, Text } from '@gluestack-ui/themed'
import { Country } from 'country-state-city'
import CandidateCareerViewComponent from '../../../newComponents/editCareer/CandidateCareerViewComponent'
import fetchCurrenciesInfo from '../../../customhook/GetCurrencies'

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

const CandidatePreferenceView = ({ preferences, navigation, screen }) => {
	
	
	
	

	function formatDate(inputDate) {
		const date = new Date(inputDate)

		const day = String(date.getDate()).padStart(2, '0')
		const month = String(date.getMonth() + 1).padStart(2, '0')
		const year = date.getFullYear()

		return `${day}/${month}/${year}`
	}

	const [currencies, setCurrencies] = useState(null)
	const [selectedCountryInfo, setSelectedCountryInfo] = useState(null)

	const apiUrl = 'https://restcountries.com/v3.1/all'

	useEffect(() => {
		const fetchData = async () => {
			try {
				const countriesInfo = await fetchCurrenciesInfo(apiUrl)
				setCurrencies(countriesInfo)
			} catch (error) {
				console.error('Error in component:', error)
			}
		}

		fetchData()
	}, [apiUrl])

	useEffect(() => {
		if (currencies && preferences && preferences?.CurrencyCountry) {
			const selectedCountry = currencies.find(
				(country) =>
					country.countryName === preferences?.CurrencyCountry
			)
			if (selectedCountry) {
				const { name, currencySymbol } = selectedCountry

				setSelectedCountryInfo({ name, currencySymbol })
			} else {
				console.warn(
					'Country not found in currencies:',
					preferences?.CurrencyCountry
				)
			}
		}
	}, [currencies, preferences])

	const data = [
		{
			id: 1,
			title: 'Mode of Work',
			preference: preferences?.workType
				? preferences?.workType
				: null,
		},
		{
			id: 2,
			title: 'Current Salary',
			preference:
			preferences?.currentSalary
					? `${
							selectedCountryInfo
								? selectedCountryInfo?.currencySymbol
								: ''
					  } ${preferences?.currentSalary}`
					: null,
		},
		{
			id: 3,
			title: 'Expected Salary',
			preference: preferences?.ActivatePreference
				? `${
						selectedCountryInfo
							? selectedCountryInfo?.currencySymbol
							: ''
				  } ${preferences?.Salaryexpectation}`
				: null,
		},
		{
			id: 4,
			title: 'Notice Period',
			preference: preferences?.ActivatePreference
				? preferences?.noticePeriod
				: null,
		},
		{
			id: 5,
			title: 'Serving Notice Period',
			preference: preferences?.ActivatePreference
				? preferences?.servingNotice
				: null,
		},
		{
			id: 13,
			title: 'Country',
			preference: preferences?.Country
				? preferences?.Country
				: preferences?.Country
				? preferences?.Country
				: null,
		},
		{
			id: 6,
			title: 'Location Preference',
			preference: preferences
				? preferences?.location1
					? `${
						preferences?.location1
								? preferences?.location1
								: ''
					  }, ${
						preferences?.location2
								? preferences?.location2
								: ''
					  }, ${
						preferences?.location3
								? preferences?.location3
								: ''
					  }`
					: null
				: null,
		},
		{
			id: 7,
			title: 'Minimum Contract Period',
			preference: preferences
				? preferences?.minimumContractPeriod
				: null,
		},
		{
			id: 8,
			title: 'Type of your contract',
			preference: preferences?.contractType
				? preferences?.contractType
				: null,
		},
		{
			id: 9,
			title: 'Compensation Structure',
			preference: preferences?.compensationStructure
				? preferences?.compensationStructure
				: null,
		},
		{
			id: 10,
			title: 'Expected contract compensation ',
			preference: preferences
				? `${
						selectedCountryInfo
							? selectedCountryInfo?.currencySymbol
							: ''
				  } ${preferences?.ContractCompensation}`
				: null,
		},
		{
			id: 11,
			title: 'Contracts Completed',
			preference: preferences?.prevContractCount
				? preferences?.prevContractCount
				: null,
		},
		{
			id: 12,
			title: 'Available From',
			preference: preferences?.AvailableDate
				? formatDate(preferences?.AvailableDate)
				
				: null,
		},
		{
			id: 13,
			title: 'Hourly Contract Compensation ',
			preference: preferences
				? `${
						selectedCountryInfo
							? selectedCountryInfo?.currencySymbol
							: ''
				  } ${preferences?.HourlyRateExpectedOnContract}`
				: null,
		},
	]

	const filteredData = data.filter(
		(item) => 
			item.preference !== null &&
			item.preference !== '' &&
			item.preference !== undefined
	)

	const renderItemMethod = ({ item }) => {
		return (
			<Box w={windowWidth > 480 ? '$1/3' : '$full'} marginVertical={5}>
				<Text
					fontFamily="$fontHeading"
					fontWeight="600"
					fontSize={windowWidth > 480 ? 14 : 12}
				>
					{item.title}
				</Text>
				<Text
					fontFamily="$fontHeading"
					fontSize={windowWidth > 480 ? 13 : 11}
					textAlign="left"
				>
					{item.preference}
				</Text>
			</Box>
		)
	}

	const editPreference = () => {
		navigation.push('CandidatePreferences')
	}

	return (
		<Box
			bg="$primary0"
			marginBottom={12}
			p={windowWidth > 480 ? 20 : 10}
			borderWidth={1}
			borderColor="$borderDark0"
			borderRadius={6}
		>
			<CandidateCareerViewComponent
				title={'Preferences'}
				editIcon={true}
				data={filteredData}
				loading={!preferences}
				renderItem={renderItemMethod}
				onPress={editPreference}
				screen={screen}
				numColumnsFlatlist={windowWidth > 408 ? 3 : 1}
			/>
		</Box>
	)
}

export default CandidatePreferenceView
