import {
	Box,
	Modal,
	ModalBackdrop,
	ModalContent,
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalCloseButton,
	Icon,
	Button,
	ButtonText,
	Heading,
	CloseIcon,
} from '@gluestack-ui/themed'
import { Dimensions } from 'react-native'
import React, { useState } from 'react'
import EditProfile from '../editProfile/EditProfile'
import ButtonComponent from '../ButtonComponent'
import SpinnerComponent from '../SpinnerComponent'
import { ToastContainer } from 'react-toastify'

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

const EditProfileModal = ({ isOpen, onClose }) => {
	const [loading, setLoading] = useState(false)

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			minHeight={windowHeight}
			position="fixed"
		>
			<ToastContainer />
			<ModalBackdrop />
			<ModalContent
				maxHeight={windowHeight * 0.9}
				minWidth={
					windowWidth > 480 ? windowWidth * 0.7 : windowWidth * 0.9
				}
				overflow={false}
			>
				<ModalHeader>
					<Heading
						fontFamily="$fontHeading"
						fontSize={
							windowWidth > 480
								? '$fontHeading'
								: '$fontHeadingMobile'
						}
					>
						Edit Profile
					</Heading>
					<ModalCloseButton>
						<Icon
							as={CloseIcon}
							size={windowWidth > 480 ? 'md' : 'xs'}
						/>
					</ModalCloseButton>
				</ModalHeader>
				<ModalBody
					paddingHorizontal={windowWidth > 480 ? 50 : 5}
					paddingVertical={5}
					bg="white"
				>
					<EditProfile hideModal={onClose} hiddenHeading={true} />
				</ModalBody>
			</ModalContent>
		</Modal>
	)
}

export default EditProfileModal
