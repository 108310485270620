import {
	View,
	Text,
	Image,
	Dimensions,
	StyleSheet,
	ActivityIndicator,
	TouchableOpacity,
	FlatList,
} from 'react-native'
import React from 'react'
import { useEffect, useState, Fla } from 'react'
import { Button } from 'native-base'
const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height
import { listNewprofiles } from '../../graphql/queries'
import { Drawer, MobileHeader } from '../../newComponents/header/Header'
import { DekstopHeader } from '../../newComponents/header/Header'
import { API, Auth, graphqlOperation } from 'aws-amplify'
import SpinnerComponent from '../../newComponents/SpinnerComponent'
import { list_JobApplicants_by_companyPreferenceId } from '../../graphql/custom-queries'

import CandidateComponent from '../../components/ApplicantCandidateComponent'
import Divider from '../../FeedComponents/Divider'
import { Box } from '@gluestack-ui/themed'
import CandidateViewComponent from './components/CandidateViewComponent'
export default function JobApplicantsList({
	navigation,
	preferenceId,
	rolename,
	companyName,
	JobPostData,
	SelectedTab,
	prefferedSalary,
	minExperience,
	maxExpeirence,
	// JobApplicantsList 
	
}) {
	const [JobApplicantslist, setJobApplicantslist] = useState('')
	const [loading, setloading] = useState(true)
	const [nextToken, setNextToken] = useState(null)
	async function getJobApplications() {
		try {
			const response = await API.graphql({
				query: list_JobApplicants_by_companyPreferenceId,
				variables: {
					CompanyPreferenceId: preferenceId,
					limit: 25,
					nextToken: nextToken,
				},
			})

			const newData =
				response.data.list_JobApplicants_by_companyPreferenceId?.items
			setNextToken(
				response.data.list_JobApplicants_by_companyPreferenceId
					.nextToken
			)

			setJobApplicantslist([...JobApplicantslist, ...newData])
			
			setloading(false)
		} catch (error) {
			console.log(error)
			setloading(false)
		}
	}

	useEffect(() => {
		getJobApplications()
	}, [])

	const fetchMoreData = () => {
		
		if (nextToken) {
			getJobApplications(nextToken)
		}
		else{
			return null
		}
	}

	return (
		<Box>
			{!loading ? (
				<CandidateViewComponent
				loading={loading}
				headingText={'Applicants'}
				companyname={companyName}
				onPressBtn={null}
				navigation={navigation}
				data={JobApplicantslist}
				rolename={rolename}
				fetchMoreData={fetchMoreData}
				PreferenceId = {preferenceId}
				listEmptyText={'No applicants yet!'}
				JobPostData ={JobPostData}
				prefferedSalary = {prefferedSalary}
				minExperience = {minExperience}
				maxExpeirence  ={maxExpeirence}
			/>

			) :(<SpinnerComponent />)}
			
		</Box>
	)
}
