import React, { useRef } from 'react'
import { Dimensions, StyleSheet } from 'react-native'
import { Box, View, VStack, HStack, Image, Text } from '@gluestack-ui/themed'
import UserAvatar from 'react-native-user-avatar'
import useUserStore from '../../Userstore/UserStore'
import { useHover } from 'react-native-web-hooks'
import AvatarComponent from '../AvatarComponent'

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

const CircularProgress = ({ percent, fileLink }) => {
	const name = useUserStore((state) => state.name)

	const ref = useRef(null)
	const isHovered = useHover(ref)

	const propStyle = (percent, base_degrees) => {
		const rotateBy = base_degrees + percent * 3.6
		return {
			transform: [{ rotateZ: `${rotateBy}deg` }],
		}
	}

	const renderThirdLayer = (percent) => {
		if (percent > 50) {
			return (
				<View
					style={[
						styles.secondProgressLayer,
						propStyle(percent - 50, 45),
					]}
					borderRightColor="$borderDark100"
					borderTopColor="$borderDark100"
				></View>
			)
		} else {
			return <View style={styles.offsetLayer}></View>
		}
	}

	let firstProgressLayerStyle
	if (percent > 50) {
		firstProgressLayerStyle = propStyle(50, -135)
	} else {
		firstProgressLayerStyle = propStyle(percent, -135)
	}

	return (
		<View style={styles.container}>
			<View
				style={[styles.firstProgressLayer, firstProgressLayerStyle]}
				borderRightColor="$borderDark100"
				borderTopColor="$borderDark100"
			></View>
			{renderThirdLayer(percent)}

			<View ref={ref}>
				{isHovered ? (
					<View style={styles.percentageText}>
						<Text
							style={{
								textAlign: 'center',
								fontWeight: 'bold',
							}}
							fontFamily="$fontContent"
							fontSize={windowWidth > 480 ? 14 : 10}
						>
							{percent}%
						</Text>
					</View>
				) : (
					<AvatarComponent
						name={name}
						height={windowWidth > 480 ? 50 : 45}
						width={windowWidth > 480 ? 50 : 45}
						imgSource={fileLink}
					/>
				)}
			</View>
		</View>
	)
}

const styles = StyleSheet.create({
	container: {
		width: windowWidth > 480 ? 65 : 55,
		height: windowWidth > 480 ? 65 : 55,
		borderWidth: 10,
		borderRadius: 100,
		borderColor: '#EEEEEE',
		justifyContent: 'center',
		alignItems: 'center',
	},
	firstProgressLayer: {
		width: windowWidth > 480 ? 65 : 55,
		height: windowWidth > 480 ? 65 : 55,
		borderWidth: 10,
		borderRadius: 100,
		position: 'absolute',
		borderLeftColor: 'transparent',
		borderBottomColor: 'transparent',
		transform: [{ rotateZ: '-135deg' }],
	},
	secondProgressLayer: {
		width: windowWidth > 480 ? 65 : 55,
		height: windowWidth > 480 ? 65 : 55,
		position: 'absolute',
		borderWidth: 10,
		borderRadius: 100,
		borderLeftColor: 'transparent',
		borderBottomColor: 'transparent',
		transform: [{ rotateZ: '45deg' }],
	},
	offsetLayer: {
		width: windowWidth > 480 ? 65 : 55,
		height: windowWidth > 480 ? 65 : 55,
		position: 'absolute',
		borderWidth: 10,
		borderRadius: 100,
		borderLeftColor: 'transparent',
		borderBottomColor: 'transparent',
		borderRightColor: '#EEEEEE',
		borderTopColor: '#EEEEEE',
		transform: [{ rotateZ: '-135deg' }],
	},
	headerIcons: {
		height: windowWidth > 480 ? 65 : 55,
		width: windowWidth > 480 ? 65 : 55,
		borderRadius: 50,
	},
	profileimage: {
		height: windowWidth > 480 ? 65 : 55,
		width: windowWidth > 480 ? 65 : 55,
		borderRadius: 50,
	},
	percentageText: {
		height: windowWidth > 480 ? 65 : 55,
		width: windowWidth > 480 ? 65 : 55,
		borderRadius: 50,
		justifyContent: 'center',
		alignContent: 'center',
	},
})

export default CircularProgress
