import React from 'react'
import { Box, Heading, VStack, Text } from '@gluestack-ui/themed'
import { Dimensions } from 'react-native'

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

const ConsentTermsAndConditions = () => {
	return (
		<Box
			bg="$backgroundGray"
			minHeight={windowHeight - 62}
			marginTop={62}
			marginBottom={windowWidth > 480 ? null : 50}
			paddingHorizontal={windowWidth > 480 ? 100 : 5}
			paddingVertical={windowWidth > 480 ? 20 : 5}
			paddingBottom={windowWidth > 480 ? null : 50}
		>
			<VStack
				gap={10}
				bg="$primary0"
				paddingVertical={20}
				paddingHorizontal={40}
			>
				<Heading size="2xl" fontFamily="$fontHeading">
					Terms and Conditions: User-Generated Content Distribution
				</Heading>
				<Box>
					<Text size="sm" fontFamily="$fontContent">
						Welcome to Qrusible! By accessing and using our
						website/application, you agree to comply with the
						following terms and conditions related to the
						publication and distribution of user-generated content.
						Please read them carefully before proceeding:
					</Text>
				</Box>
				{/* 1 */}
				<Heading size="md" fontFamily="$fontHeading">
					1. Acceptance of Terms{' '}
				</Heading>
				<Box>
					<Text size="sm" fontFamily="$fontContent">
						By using Qrusible, you agree to comply with and be bound
						by these terms and conditions, our privacy policy, and
						any additional terms and conditions that may apply to
						specific services or features.
					</Text>
				</Box>
				{/* 2 */}
				<Heading size="md" fontFamily="$fontHeading">
					2. User Registration
				</Heading>
				<Box>
					<Text size="sm" fontFamily="$fontContent">
						To fully access the features of Qrusible, you may be
						required to register for an account. You agree to
						provide accurate, current, and complete information
						during the registration process and to update such
						information to keep it accurate, current, and complete.
					</Text>
				</Box>
				{/* 3 */}
				<Heading size="md" fontFamily="$fontHeading">
					3. User Conduct
				</Heading>
				<Box>
					<Text size="sm" fontFamily="$fontContent">
						You agree to use Qrusible in accordance with all
						applicable laws and regulations. You are solely
						responsible for all content you post, share, or
						otherwise make available on Qrusible. You shall not
						engage in any activity that disrupts the normal
						functioning of the platform or infringes upon the rights
						of others.
					</Text>
				</Box>
				{/* 4 */}
				<Heading size="md" fontFamily="$fontHeading">
					4. Knowledge Sharing
				</Heading>
				<Box>
					<Text size="sm" fontFamily="$fontContent">
						Qrusible is a platform for knowledge sharing. Users are
						encouraged to share their knowledge and expertise within
						the bounds of legality and ethical behavior. Qrusible
						does not endorse or verify the accuracy of
						user-generated content.
					</Text>
				</Box>
				{/* 5 */}
				<Heading size="md" fontFamily="$fontHeading">
					5. Employment Opportunities
				</Heading>
				<Box>
					<Text size="sm" fontFamily="$fontContent">
						Qrusible may provide a platform for employment
						opportunities. Users looking for employment or offering
						job opportunities do so at their own discretion.
						Qrusible does not guarantee the accuracy or legitimacy
						of job postings.
					</Text>
				</Box>
				{/* 6 */}
				<Heading size="md" fontFamily="$fontHeading">
					6. Confidential Information
				</Heading>
				<Box>
					<Text size="sm" fontFamily="$fontContent">
						By using Qrusible, you acknowledge and agree that you
						may have access to confidential information belonging to
						Qrusible or other users. You agree to treat such
						information as confidential and not disclose, reproduce,
						or use such information for any purpose other than the
						intended use of the platform.
					</Text>
				</Box>
				{/* 7 */}
				<Heading size="md" fontFamily="$fontHeading">
					7. Usage of Confidential Information
				</Heading>
				<Box>
					<Text size="sm" fontFamily="$fontContent">
						You grant Qrusible a non-exclusive, royalty-free,
						worldwide license to use, reproduce, modify, adapt,
						publish, translate, distribute, and display any content
						you post on Qrusible for the purpose of operating and
						improving the platform.
					</Text>
				</Box>
				{/* 8 */}
				<Heading size="md" fontFamily="$fontHeading">
					8. Termination of Account
				</Heading>
				<Box>
					<Text size="sm" fontFamily="$fontContent">
						Qrusible reserves the right to terminate or suspend your
						account and access to the platform at its sole
						discretion, without prior notice, for any reason,
						including but not limited to a breach of these terms and
						conditions.
					</Text>
				</Box>
				<Heading size="md" fontFamily="$fontHeading">
					9. Changes to Terms and Conditions
				</Heading>
				<Box>
					<Text size="sm" fontFamily="$fontContent">
						Qrusible may modify these terms and conditions at any
						time. The updated terms will be effective upon posting
						on the platform. It is your responsibility to review the
						terms regularly.
					</Text>
				</Box>
				<Heading size="md" fontFamily="$fontHeading">
					10. Governing Law
				</Heading>
				<Box>
					<Text size="sm" fontFamily="$fontContent">
						These terms and conditions are governed by the laws of
						India. Any dispute arising out of or in connection with
						these terms and conditions shall be subject to the
						exclusive jurisdiction of the courts in Hyderabad,
						Telangana.
					</Text>
				</Box>
				<Box>
					<Text size="sm" fontFamily="$fontContent">
						By using Qrusible, you acknowledge that you have read,
						understood, and agreed to these terms and conditions. If
						you have any questions or concerns, please contact us at
						support@qrusible.com.
					</Text>
				</Box>
			</VStack>
		</Box>
	)
}

export default ConsentTermsAndConditions
