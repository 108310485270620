import { Dimensions } from 'react-native'
import React, { useEffect } from 'react'
import { Box, Divider, Heading, Text } from '@gluestack-ui/themed'
import { useState } from 'react'
import { API } from 'aws-amplify'

import {
	createCandidatePreferences,
	updateCandidatePreferences,
} from '../graphql/custom-mutations'

import { Country, City } from 'country-state-city'

import {getNewprofile} from "../graphql/custom-queries"
import useUserStore from '../Userstore/UserStore'
import { deleteCandidatePreferences } from '../graphql/custom-mutations'
import { updateNewprofile } from '../graphql/mutations'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-datepicker/dist/react-datepicker-cssmodules.css'
import DropdownComponent from './DropdownComponent'
import LabelWithInputComponent from './LabelWithInputComponent'
import CustomDatePicker from './DatePickerComponent'
import ButtonComponent from './ButtonComponent'
import SpinnerComponent from './SpinnerComponent'
import MultipleSelect from './MultipleSelect'
import SearchAndSelectInput from './SearchAndSelectInput'
import CurrencyInput from './CurrencyInput'
import { CreateEvents } from '../HeplerFunctions/EventTableMutations'
import { isBrowser, isMobile, browserName} from 'react-device-detect';
import { showToast } from '../components/ErrorToast'
const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

export default function CandidatePreferences({
	screen,
	handleProceed,
	handleGoBack,
	navigation,
	inModal,
	setJobOnboardModal,
}) {
	const ProfileId = useUserStore((state) => state.profileId)
	const setProfile = useUserStore((state) => state.setUserProfile)
	const profile = useUserStore((state) => state.profile)
	const savedUrl = useUserStore((state) => state.savedurl)
	const setSavedUrl = useUserStore((state) => state.setSavedUrl)
	const setDomain = useUserStore((state) => state.setDomain)
	const [groupValues, setGroupValues] = React.useState([])
	const [dateContract, setdateContract] = useState('')
	const [loading, setLoading] = useState('')
	const [loadingPage, setLoadingPage] = useState(false)
	const [loadingActivated, setLoadingActivated] = useState(false)
	const [activeFullTime, setActiveFullTime] = useState(false)
	const [activeContractRole, setActiveContractRole] = useState(false)
	const setCheckScreen = useUserStore((state) => state.setCheckScreen)
	const [zIndexDatePicker, setZIndexDatePicker] = useState(5)
	const [
		ActivatedCandisatePreferencesID,
		setActivatedCandisatePreferencesID,
	] = useState('')
	const [tab, setTab] = useState('fullTime')
	const userId = useUserStore(state => (state.userId))
	const [selectedCurrencyCountry, setSelectedCurrencyCountry] = useState(null)
	const [selectedCountry, setSelectedCountry] = useState(null)
	const [selectedCountryName, setSelectedCountryName] = useState(null)
	const [citiesToShow, setCitiesToShow] = useState(null)
	const [selectedCity1, setSelectedCity1] = useState(null)
	const [selectedCity2, setSelectedCity2] = useState(null)
	const [selectedCity3, setSelectedCity3] = useState(null)
	const isOpportunities = savedUrl?.includes('opportunities')

	const navToFeed = () => {
		navigation.push('Feed')
	}

	const [userpreferences, setUserpreferences] = useState({
		userpreferencesId: '',
		ActivatePreference: null,
		domain: '',
		workTypeList: '',
		Salaryexpectation: '',
		currentSalary: '',
		servingNotice: '',
		noticePeriod: '',
	})

	const [commonPreferences, setCommonPreferences] = useState({
		jobTypeList: [],
		AvailableDate: '',
	})

	const [userpreferencesContract, setUserpreferencesContract] = useState({
		userpreferencesContractId: '',
		ActivatePreference: null,

		minimumContractPeriod: '',
		ContractCompensation: '',
		HourlyCompensation : 0,
		prevContractCount: null,
		ContractType: [],
		CompensationStructure: [],
	})
	const domain = [
		{ label: 'Full Stack', value: 'fullstack' },
		{ label: 'Cyber Security', value: 'cybersecurity' },
		{ label: 'HR', value: 'hr' },
		{ label: 'Content', value: 'content' },
		{ label: 'Ai and Blockchain', value: 'ai' },
		{ label: 'Digital Marketing', value: 'digitalmarketing' },

	]
	const CreateEventProfilePreferenceCreation = async (item) => {
		
	
		const EventAttributesJSOn = {
			"UserId" :  userId,
			"UserProfileId" : ProfileId,
			"IsMobile" : isMobile
		  };
		try {
			
			

			const Input = {
				EventNameTableKeyId: item,
				EventName : "CreatePreferencesStep3",
				EventAttributes : JSON.stringify(EventAttributesJSOn)

	
			}

			const res = await CreateEvents(Input)

		
		} catch (err) {
			console.log('Error updating objective', err)
		} 
		
	}
	useEffect(() => {
		if (!userpreferences.ActivatePreference) {
			setCommonPreferences((prevPreferences) => ({
				...prevPreferences,
				jobTypeList: prevPreferences.jobTypeList.filter(
					(jobType) => jobType !== 'FullTime'
				),
			}))
		}
		if (!userpreferencesContract.ActivatePreference) {
			setCommonPreferences((prevPreferences) => ({
				...prevPreferences,
				jobTypeList: prevPreferences.jobTypeList.filter(
					(jobType) => jobType !== 'Contract'
				),
			}))
		}
	}, [
		userpreferences.ActivatePreference,
		userpreferencesContract.ActivatePreference,
	])

	const DropdownMinimumContractPeriod = [
		{
			label: 'Short Contract(3-8 Months)',
			value: 'Short Contract(3-8 Months)',
		},
		{
			label: 'Long Contract(8 Months - 2 years)',
			value: 'Long Contract(8 Months - 2 years)',
		},
		{ label: 'Any', value: 'Any' },
	]

	const CompensationStructure = [
		{ label: 'By-Job', value: 'By-Job' },
		{ label: 'Monthly', value: 'Monthly' },
		{ label: 'Weekly', value: 'Weekly' },
		{ label: 'Bi - Monthly', value: 'Bi - Monthly' },
	]

	const TypeContract = [
		{ label: 'Full-Time Contracting', value: 'Full-Time' },
		{ label: 'Part-Time Consulting', value: 'Part-Time' },
		// { label: 'Delivery Based', value: 'Delivery Based' },
	]

	const servingNotice = [
		{ label: 'Yes', value: 'Yes' },
		{ label: 'No', value: 'No' },
	]

	const DropdownworkTypeListCommon = [
		{ label: 'Remote', value: 'Remote' },
		{ label: 'Onsite', value: 'Onsite' },
		{ label: 'Any', value: 'Any' },
	]
	const SelectJobType = [
		{ label: 'Full-Time Employment', value: 'FullTime' },
		{ label: 'Contract/Freelance', value: 'Contract' },
	]

	const remoteSelected = userpreferences?.workTypeList?.includes('Remote')

	const notServingNoticePeriod = userpreferences?.servingNotice === 'No'
	const allCountries = Country.getAllCountries()

	useEffect(() => {
		if (selectedCountry) {
			setSelectedCountryName(selectedCountry.name)
		}
	}, [selectedCountry])

	useEffect(() => {
		if (selectedCountry) {
			const citiesOfCountry = City.getCitiesOfCountry(
				selectedCountry?.isoCode
			)
			setCitiesToShow(citiesOfCountry)
		}
	}, [selectedCountry])

	const handlSave = async () => {
		if (userpreferences.userpreferencesId) {
			await UpdateCandidatePreferences(
				userpreferences,
				userpreferences.userpreferencesId,
				'FullTime',
				commonPreferences,
				true
			)

			savedUrl?.includes('opportunities') && setSavedUrl('')
		} else {
			await Createpreferences(
				userpreferences,
				commonPreferences,
				true
			)

			savedUrl?.includes('opportunities') && setSavedUrl('')
		}

		// if (userpreferencesContract.userpreferencesContractId) {
		// 	if (commonPreferences.jobTypeList?.includes('Contract')) {
		// 		await UpdateCandidatePreferences(
		// 			userpreferencesContract,
		// 			userpreferencesContract.userpreferencesContractId,
		// 			'Contract',
		// 			commonPreferences,
		// 			true
		// 		)
		// 	} else {
		// 		await UpdateCandidatePreferences(
		// 			userpreferencesContract,
		// 			userpreferencesContract.userpreferencesContractId,
		// 			'Contract',
		// 			commonPreferences,
		// 			false
		// 		)
		// 	}
		// 	savedUrl?.includes('opportunities') && setSavedUrl('')
		// } else {
		// 	if (commonPreferences.jobTypeList?.includes('Contract')) {
		// 		await Createpreferences(
		// 			userpreferencesContract,
		// 			commonPreferences,
		// 			true
		// 		)
		// 	}
		// 	savedUrl?.includes('opportunities') && setSavedUrl('')
		// }
	}

	async function setActivatedPreferences(id) {
		setLoadingActivated(true)
		if (id) {
			const CreateUserInput = {
				id: ProfileId,
				ActivatedCandisatePreferencesID: id,
				// htmloutput: output,
			}
			API.graphql({
				query: updateNewprofile,
				variables: { input: CreateUserInput },
			}).then((res) => {
				setLoadingActivated(false)
				setActivatedCandisatePreferencesID(
					res.data.updateNewprofile.ActivatedCandisatePreferencesID
				)
			})
		} else {
			const CreateUserInput = {
				id: ProfileId,
				ActivatedCandisatePreferencesID: 'null',
				// htmloutput: output,
			}
			API.graphql({
				query: updateNewprofile,
				variables: { input: CreateUserInput },
			}).then((res) => {
				setLoadingActivated(false)
				setActivatedCandisatePreferencesID('')
			})
		}
	}

	const onboardingModalClick = () => {
		setJobOnboardModal(false)
		setCheckScreen('JobDetails')
	}

	async function Createpreferences(props, commonProps, activated) {
		console.log("in Create")
			setLoading('FullTime')
			const CreateUserInput = {
				profileId: ProfileId,
				
				location1: selectedCity1,
				location2: selectedCity2,
				location3: selectedCity3,
				CurrencyCountry: selectedCurrencyCountry,
				AvailableDate: commonProps.AvailableDate,
				Salaryexpectation: userpreferences.Salaryexpectation,
				servingNotice: userpreferences.servingNotice,
				noticePeriod: userpreferences.noticePeriod,
				currentSalary: userpreferences.currentSalary,
				userDomain : userpreferences.domain,
				ContractCompensation: userpreferencesContract.ContractCompensation,
				HourlyRateExpectedOnContract : userpreferencesContract.HourlyCompensation
			}
			API.graphql({
				query: createCandidatePreferences,
				variables: { input: CreateUserInput },
			}).then((res) => {
				screen === 'onboarding'
					? inModal
						? onboardingModalClick()
						: navToFeed()
					: navigation.push('Profile')
					setTimeout(() => {
						CreateEventProfilePreferenceCreation(res.data.createCandidatePreferences.id)
								}, 3000)
				setLoading('')
			})
		
		
	}

	


	async function UpdateCandidatePreferences(
		props,
		id,
		jobType,
		commonProps,
		activated
	) {
		setLoading(jobType)
		console.log("in Update")
		const updatePreferences = async (input) => {
			try {
				const res = await API.graphql({
					query: updateCandidatePreferences,
					variables: { input },
				})
				setLoading('')

				screen === 'onboarding'
					? inModal
						? onboardingModalClick()
						: navToFeed()
					: navigation.push('Profile')
			} catch (error) {
				setLoading('')
				console.error('Error updating preferences:', error)
			}
		}
	
		
			const updateUserInput = {
				id: id,
						
				location1: selectedCity1,
				location2: selectedCity2,
				location3: selectedCity3,
				CurrencyCountry: selectedCurrencyCountry,
				AvailableDate: commonProps.AvailableDate,
				Salaryexpectation: userpreferences.Salaryexpectation,
				servingNotice: userpreferences.servingNotice,
				noticePeriod: userpreferences.noticePeriod,
				currentSalary: userpreferences.currentSalary,
				userDomain : userpreferences.domain,
				ContractCompensation: userpreferencesContract.ContractCompensation,
				HourlyRateExpectedOnContract : userpreferencesContract.HourlyCompensation
			
			}
			await updatePreferences(updateUserInput)
		

		
	}

	const deletePreferences = async (id) => {
		try {
			const deleteUserInput = {
				id: id,
			}
			await API.graphql({
				query: deleteCandidatePreferences,
				variables: { input: deleteUserInput },
			})
		} catch (error) {
			console.error('Error deleting preferences:', error)
		}
	}

	async function getProfile() {
		setLoadingPage(true)
		let firstFullTimeItem = null
		let firstContractItem = null
		let itemsToDelete = []
		await API.graphql({
			query: getNewprofile,
			variables: {
				id: ProfileId,
			},
		})
			.then((res) => {
				const data =
					res?.data?.getNewprofile?.CandidatePreferences?.items[0]

					console.log(data?.HourlyRateExpectedOnContract)
				
					setUserpreferences({
						userpreferencesId: data.id,
						
						
						Salaryexpectation: data?.Salaryexpectation,
						currentSalary: data?.currentSalary,
						servingNotice: data?.servingNotice,
						noticePeriod: data?.noticePeriod,
						domain : data?.userDomain
					})
					setSelectedCity1(firstFullTimeItem?.location1)
					setSelectedCity2(firstFullTimeItem?.location2)
					setSelectedCity3(firstFullTimeItem?.location3)

					setCommonPreferences((prevCommonPreferences) => ({
						...prevCommonPreferences,
						
						AvailableDate: data?.AvailableDate
							? new Date(firstFullTimeItem?.AvailableDate)
							: '',
					}))
					setSelectedCountryName(data?.Country)
					setSelectedCurrencyCountry(
						data?.CurrencyCountry
					)
				

					setUserpreferencesContract({
						userpreferencesContractId: data?.id,
						ActivatePreference:
							data?.ActivatePreference,
						minimumContractPeriod:
							data?.minimumContractPeriod,
						ContractType:
							data?.contractType?.split(','),
						CompensationStructure:
							data?.compensationStructure?.split(
								','
							),

							
						ContractCompensation:
							data?.ContractCompensation,
						prevContractCount: data?.prevContractCount,
						domain : data?.userDomain ,
						HourlyCompensation : data?.HourlyRateExpectedOnContract
					})

					setCommonPreferences((prevCommonPreferences) => ({
						...prevCommonPreferences,
						
						AvailableDate: data?.AvailableDate
							? new Date(data.AvailableDate)
							: '',
					}))
					setSelectedCountryName(data?.Country)
					setSelectedCurrencyCountry(
						data?.CurrencyCountry
					)
				

				setProfile(res.data?.getNewprofile)
				setLoadingPage(false)
			})
			.catch((err) => {
				console.log(err)
			})

		if (itemsToDelete.length > 0) {
			try {
				await Promise.all(
					itemsToDelete?.map(async (id) => {
						await deletePreferences(id)
						console.log('Item deleted successfully:', id)
					})
				)
			} catch (error) {
				console.error('Error deleting items:', error)
			}
		}
	}

	useEffect(() => {
		if (ProfileId) getProfile()
	}, [])

	const renderSearchSelectItem = (item) => {
		return (
			<Box p={10}>
				<Text
					fontFamily="$fontHeading"
					fontSize={windowWidth > 480 ? 14 : 12}
				>
					{item?.name}
				</Text>
			</Box>
		)
	}

	function isCommonPreferencesInvalid() {
		return (
			commonPreferences?.AvailableDate === ''
			// selectedCurrencyCountry === null ||
			// (!notServingNoticePeriod && commonPreferences?.AvailableDate === '')
		)
	}

	function isFullTimePreferencesInvalid() {
		return (
			expectedSalaryDigitCount < 4 ||
			currentSalaryDigitCount < 4 
			// userpreferences.noticePeriod === '' 
			// userpreferences.servingNotice === '' ||
			// userpreferences.workTypeList === '' ||
			// (!remoteSelected && selectedCountryName === null) ||
			// (!remoteSelected && selectedCity1 === null)
		)
	}
	function isContractPreferencesValid() {
		return (
			// userpreferencesContract?.minimumContractPeriod === '' ||
			userpreferencesContract.ContractCompensation === '' 
			// userpreferencesContract.ContractType.length === 0 ||
			// userpreferencesContract.CompensationStructure.length === 0
		)
	}

	function isSaveButtonDisabled() {
		
		const isFullTime = commonPreferences.jobTypeList?.includes('FullTime')
		const isContract = commonPreferences.jobTypeList?.includes('Contract')
	
		
		if (isFullTime && isContract) {
			const x =  isCommonPreferencesInvalid()
			console.log(x)
			return (
				isCommonPreferencesInvalid() ||
				isFullTimePreferencesInvalid() ||
				isContractPreferencesValid()
				
				
			)
		} else if (isFullTime) {
			return (
				isCommonPreferencesInvalid() || isFullTimePreferencesInvalid()
			)
		} else if (isContract) {
			return isCommonPreferencesInvalid() || isContractPreferencesValid()
		}

		return true // Disable the button by default
	}

	const currentSalaryDigitCount = userpreferences?.currentSalary?.length
	const expectedSalaryDigitCount = userpreferences?.Salaryexpectation?.length

	return (
		<Box
			bg="$backgroundGray"
			minHeight={screen !== 'onboarding' && windowHeight - 62}
			marginTop={screen !== 'onboarding' && 62}
			marginBottom={windowWidth > 480 ? null : 50}
			paddingHorizontal={
				screen !== 'onboarding' && windowWidth > 480 ? 100 : 5
			}
			paddingVertical={
				screen !== 'onboarding' && windowWidth > 480 ? 20 : 5
			}
			paddingBottom={
				screen !== 'onboarding' && windowWidth > 480 ? null : 50
			}
			borderRadius={6}
			alignSelf="center"
			w={'100%'}
		>
			{loadingPage ? (
				<Box
					marginVertical={windowWidth > 480 ? 20 : 10}
					p={windowWidth > 480 ? 20 : 10}
					bg="$primary0"
					height={windowHeight * 0.2}
				>
					<SpinnerComponent smallText={'Loading...'} />
				</Box>
			) : (
				<Box
					bg="$primary0"
					marginBottom={windowWidth > 480 ? 30 : 6}
					p={windowWidth > 480 ? 20 : 5}
					borderWidth={1}
					borderColor="$borderDark0"
					borderRadius={6}
				>
					<Heading
						fontSize={
							windowWidth > 480
								? '$fontHeading'
								: '$fontHeadingMobile'
						}
						fontFamily="$fontHeading"
					>
						Set Your{' '}
						<Text
							fontFamily="$fontHeading"
							fontSize={
								windowWidth > 480
									? '$fontHeading'
									: '$fontHeadingMobile'
							}
							fontWeight="700"
							color={'$primary500'}
						>
							Preferences
						</Text>
					</Heading>
					<Divider
						h={0.5}
						marginVertical={windowWidth > 480 ? 10 : 5}
					/>
					<DropdownComponent
								label={'Domain:'}
								placeholder={'Select your preferred domain'}
								userpreferences={userpreferences}
								setUserPreferences={setUserpreferences}
								property={'domain'}
								data={domain}
								important={true}
							/>
					<Box>
						<MultipleSelect
							data={SelectJobType}
							userpreferences={commonPreferences}
							setUserpreferences={setCommonPreferences}
							property={'jobTypeList'}
							label={'What opportunities you are looking for?'}
							important={true}
						/>
					</Box>

					{commonPreferences.jobTypeList?.includes('Contract') && (
						<Heading
							fontFamily="$fontHeading"
							fontSize={windowWidth > 480 ? 20 : 15}
							color="$fontGray"
							fontWeight="900"
						>
							Contract/Freelance
						</Heading>
					)}

					{/* {commonPreferences.jobTypeList?.includes('Contract') && (
						<Box marginVertical={windowWidth > 480 ? 5 : 2}>
							<DropdownComponent
								label={'Minimum Contract Period:'}
								placeholder={
									'Select your minimum contract period'
								}
								userpreferences={userpreferencesContract}
								setUserPreferences={setUserpreferencesContract}
								property={'minimumContractPeriod'}
								data={DropdownMinimumContractPeriod}
								important={true}
							/>
						</Box>
					)} */}

					<Box
						flexDirection={windowWidth < 480 ? 'column' : 'row'}
						gap={windowWidth > 480 ? 10 : 0}
						alignItems="center"
					>
						{commonPreferences.jobTypeList?.includes(
							'Contract'
						) && (
							<Box
								marginVertical={windowWidth > 480 ? 5 : 2}
								flex={windowWidth > 480 && 1}
								w={windowWidth < 480 && '100%'}
							>
								{/* <MultipleSelect
									data={TypeContract}
									userpreferences={userpreferencesContract}
									setUserpreferences={
										setUserpreferencesContract
									}
									property={'ContractType'}
									label={'Type of your contract'}
									important={true}
								/> */}
							</Box>
						)}

						{/* {commonPreferences.jobTypeList?.includes(
							'Contract'
						) && (
							<Box
								marginVertical={windowWidth > 480 ? 5 : 2}
								flex={windowWidth > 480 && 1}
								w={windowWidth < 480 && '100%'}
							>
								<LabelWithInputComponent
									label={
										'Number of contracts you have worked on:'
									}
									placeholder={
										'Enter the number of contracts you have completed/woked on'
									}
									value={
										userpreferencesContract.prevContractCount
									}
									setValueChange={(text) =>
										setUserpreferencesContract({
											...userpreferencesContract,
											prevContractCount: text?.replace(
												/[- #*;,.abcdefghijklmnopqrstuvwxyz<>\{\}\[\]\\\/]/gi,
												''
											),
										})
									}
									important={true}
								/>
							</Box>
						)} */}
					</Box>

					{commonPreferences.jobTypeList?.includes('Contract') && (
						<Box
							marginBottom={windowWidth > 480 ? 12 : 6}
							zIndex={10}
						>
							<CurrencyInput
								selectedCurrencyCountry={
									selectedCurrencyCountry
								}
								setSelectedCurrencyCountry={
									setSelectedCurrencyCountry
								}
							/>
						</Box>
					)}
					<Box
						flexDirection={windowWidth < 480 ? 'column' : 'row'}
						gap={10}
					>
						{/* {commonPreferences.jobTypeList?.includes(
							'Contract'
						) && (
							<Box
								marginVertical={windowWidth > 480 ? 5 : 2}
								flex={1}
							>
								<MultipleSelect
									data={CompensationStructure}
									userpreferences={userpreferencesContract}
									setUserpreferences={
										setUserpreferencesContract
									}
									property={'CompensationStructure'}
									label={'Compensation Structure:'}
									important={true}
								/>
							</Box>
						)} */}
						{commonPreferences.jobTypeList?.includes(
							'Contract'
						) && (
							<Box
								marginVertical={windowWidth > 480 ? 5 : 2}
								flex={1}
							>
								<LabelWithInputComponent
									label={'Hourly expected Compensation for Contract'}
									placeholder={
										'Enter your expected compensation'
									}
									value={
										userpreferencesContract.HourlyCompensation
									}
									setValueChange={(text) =>
										setUserpreferencesContract({
											...userpreferencesContract,
											HourlyCompensation: text?.replace(
												/[^0-9]/g,
												''
											),
										})
									}
									important={true}
									numberInput={true}
								/>
								<LabelWithInputComponent
									label={'Yearly expected Compensation for Contract: (in Lakh)'}
									placeholder={
										'Enter your expected compensation in Lakh'
									}
									value={
										userpreferencesContract.ContractCompensation
									}
									setValueChange={(text) =>
										setUserpreferencesContract({
											...userpreferencesContract,
											ContractCompensation: text?.replace(
												/[^0-9]/g,
												''
											),
										})
									}
									important={true}
									numberInput={true}
								/>
							</Box>
						)}
					</Box>

					{commonPreferences.jobTypeList?.includes('FullTime') && (
						<Heading
							fontFamily="$fontHeading"
							fontSize={windowWidth > 480 ? 20 : 15}
							color="$fontGray"
							fontWeight="900"
						>
							Full-Time
						</Heading>
					)}

					<Box
						flexDirection={windowWidth < 480 ? 'column' : 'row'}
						gap={10}
						alignItems="flex-start"
					>
						{/* {commonPreferences.jobTypeList?.includes(
							'FullTime'
						) && (
							<Box
								marginVertical={windowWidth > 480 ? 5 : 2}
								flex={windowWidth > 480 && 1}
								w={windowWidth < 480 && '100%'}
							>
								<DropdownComponent
									label={'Mode of Work:'}
									placeholder={
										'Select your preferred work type'
									}
									userpreferences={userpreferences}
									setUserPreferences={setUserpreferences}
									property={'workTypeList'}
									data={DropdownworkTypeListCommon}
									important={true}
								/>
							</Box>
						)} */}
						{commonPreferences.jobTypeList?.includes(
							'FullTime'
						) && (
							<Box
								marginVertical={windowWidth > 480 ? 5 : 2}
								w={windowWidth < 480 && '100%'}
							>
								<LabelWithInputComponent
									label={'Current Salary: (per Year)'}
									placeholder={
										'Enter your current salary in LPA'
									}
									value={userpreferences.currentSalary}
									setValueChange={(text) =>
										setUserpreferences({
											...userpreferences,
											currentSalary: text?.replace(
												/[^0-9]/g,
												''
											),
										})
									}
									important={true}
									numberInput={true}
									minWordCount={4 - currentSalaryDigitCount}
								/>
							</Box>
						)}
						{commonPreferences.jobTypeList?.includes(
							'FullTime'
						) && (
							<Box
								marginVertical={windowWidth > 480 ? 5 : 2}
								w={windowWidth < 480 && '100%'}
							>
								<LabelWithInputComponent
									label={'Salary Expectations: (per Year)'}
									placeholder={
										'Enter the Expected Salary'
									}
									value={userpreferences.Salaryexpectation}
									setValueChange={(text) =>
										setUserpreferences({
											...userpreferences,
											Salaryexpectation: text?.replace(
												/[^0-9]/g,
												''
											),
										})
									}
									important={true}
									numberInput={true}
									minWordCount={4 - expectedSalaryDigitCount}
								/>
							</Box>
						)}
					</Box>
					{/* {commonPreferences.jobTypeList?.includes('FullTime') && (
						<Box
							marginBottom={windowWidth > 480 ? 12 : 6}
							zIndex={10}
						>
							<CurrencyInput
								selectedCurrencyCountry={
									selectedCurrencyCountry
								}
								setSelectedCurrencyCountry={
									setSelectedCurrencyCountry
								}
							/>
						</Box>
					)} */}
					<Box
						flexDirection={windowWidth < 480 ? 'column' : 'row'}
						gap={10}
						alignItems="center"
					>
						{commonPreferences.jobTypeList?.includes(
							'FullTime'
						) && (
							<Box
								marginVertical={windowWidth > 480 ? 5 : 2}
								flex={windowWidth > 480 && 1}
								w={windowWidth < 480 && '100%'}
							>
								<LabelWithInputComponent
									label={'Notice Period: (in Days)'}
									placeholder={
										'Enter the notice period to be served in your company'
									}
									value={userpreferences.noticePeriod}
									setValueChange={(text) =>
										setUserpreferences({
											...userpreferences,
											noticePeriod: text?.replace(
												/[- #*;,.abcdefghijklmnopqrstuvwxyz<>\{\}\[\]\\\/]/gi,
												''
											),
										})
									}
									important={true}
								/>
							</Box>
						)}
						{commonPreferences.jobTypeList?.includes(
							'FullTime'
						) && (
							<Box
								marginVertical={windowWidth > 480 ? 5 : 2}
								flex={windowWidth > 480 && 1}
								w={windowWidth < 480 && '100%'}
							>
								<DropdownComponent
									label={'Serving Notice Period:'}
									placeholder={
										'Are you serving your notice period?'
									}
									userpreferences={userpreferences}
									setUserPreferences={setUserpreferences}
									property={'servingNotice'}
									data={servingNotice}
									important={true}
								/>
							</Box>
						)}
					</Box>
					{/* <Box
						flexDirection={windowWidth < 480 ? 'column' : 'row'}
						gap={10}
						alignItems="center"
						zIndex={10}
						flex={windowWidth > 480 && 1}
					>
						{commonPreferences.jobTypeList?.includes('FullTime') &&
							!remoteSelected && (
								<Box
									zIndex={10}
									flex={windowWidth > 480 && 1}
									w={windowWidth < 480 && '100%'}
								>
									<SearchAndSelectInput
										data={allCountries}
										selectedItem={selectedCountryName}
										setSelectedItem={setSelectedCountry}
										itemsPerPage={50}
										label={'Country:'}
										placeholder={'Select your country'}
										important={true}
										renderSearchSelectItem={
											renderSearchSelectItem
										}
									/>
								</Box>
							)}

						{commonPreferences.jobTypeList?.includes('FullTime') &&
							!remoteSelected && (
								<Box
									marginVertical={windowWidth > 480 ? 5 : 2}
									zIndex={9}
									flex={windowWidth > 480 && 1}
									w={windowWidth < 480 && '100%'}
								>
									<SearchAndSelectInput
										data={citiesToShow}
										selectedItem={selectedCity1}
										needName={true}
										setSelectedItem={setSelectedCity1}
										itemsPerPage={50}
										label={'Location 1:'}
										placeholder={
											'Select your preferred location'
										}
										important={true}
										renderSearchSelectItem={
											renderSearchSelectItem
										}
									/>
								</Box>
							)}
						{commonPreferences.jobTypeList?.includes('FullTime') &&
							!remoteSelected && (
								<Box
									marginVertical={windowWidth > 480 ? 5 : 2}
									zIndex={8}
									flex={windowWidth > 480 && 1}
									w={windowWidth < 480 && '100%'}
								>
									<SearchAndSelectInput
										data={citiesToShow}
										selectedItem={selectedCity2}
										setSelectedItem={setSelectedCity2}
										needName={true}
										itemsPerPage={50}
										label={'Location 2:'}
										placeholder={
											'Select your preferred location'
										}
										renderSearchSelectItem={
											renderSearchSelectItem
										}
									/>
								</Box>
							)}
						{commonPreferences.jobTypeList?.includes('FullTime') &&
							!remoteSelected && (
								<Box
									marginVertical={windowWidth > 480 ? 5 : 2}
									zIndex={7}
									flex={windowWidth > 480 && 1}
									w={windowWidth < 480 && '100%'}
								>
									<SearchAndSelectInput
										data={citiesToShow}
										selectedItem={selectedCity3}
										setSelectedItem={setSelectedCity3}
										needName={true}
										itemsPerPage={50}
										label={'Location 3:'}
										placeholder={
											'Select your preferred location'
										}
										renderSearchSelectItem={
											renderSearchSelectItem
										}
									/>
								</Box>
							)}
					</Box> */}

				
						<Box
							marginVertical={windowWidth > 480 ? 5 : 2}
							zIndex={zIndexDatePicker}
						>
							<CustomDatePicker
								label={'Availability To Join From:'}
								selectedDate={commonPreferences.AvailableDate}
								onDateChange={(date) => {
									setCommonPreferences({
										...commonPreferences,
										AvailableDate: date,
									})
								}}
								onFocus={() => {
									setZIndexDatePicker(11)
								}}
								onBlur={() => {
									setZIndexDatePicker(5)
								}}
							/>
						</Box>
					
					<Box
						marginVertical={20}
						alignSelf="center"
						alignItems="center"
						zIndex={-100}
						flexDirection={
							windowWidth > 650 ? 'row' : 'column-reverse'
						}
						justifyContent={'space-between'}
						w={
							screen === 'onboarding'
								? windowWidth > 480
									? '90%'
									: '95%'
								: null
						}
						// borderWidth={1}
					>
						{screen === 'onboarding' ? (
							<ButtonComponent
								onPress={() => handleGoBack()}
								text={'Go Back'}
								marginVertical={windowWidth > 650 ? 25 : 5}
								width={windowWidth > 650 ? 210 : '100%'}
								color={'$white'}
								textColor={'$primary500'}
							/>
						) : null}
						<ButtonComponent
							text={
								screen === 'onboarding'
									? 'Save and Proceed'
									: 'Save'
							}
							width={
								windowWidth > 650
									? screen === 'onboarding'
										? 210
										: 100
									: '100%'
							}
							onPress={async () => {
								if(userpreferences.domain){
									await handlSave()
								}else{
									showToast({
										message : 'Pls Choose your domain'
									})
								}
								
							}}
							loading={
								loading === 'FullTime' || loading === 'Contract'
							}
							disabled={isSaveButtonDisabled()}
						/>
					</Box>
				</Box>
			)}
		</Box>
	)
}
