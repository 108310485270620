import { StyleSheet, Text, View, Modal, Dimensions } from 'react-native'
import React from 'react'
import { useEffect, useState } from 'react'
import SelectCapabilities from '../newComponents/selectCapabilities/SelectCapabilities'
const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height
import { API } from 'aws-amplify'
import { listCapabilitiesByProfileIdByDate } from '../graphql/queries'
import useUserStore from '../Userstore/UserStore'
import { ActivityIndicator } from 'react-native'
export default function ModalSelectCapabilities({
	modalShow,
	setModalShow,
	searchQuery,
}) {
	return (
		<>
			<Modal
				visible={modalShow}
				onRequestClose={() => setModalShow(false)}
				ariaHideApp={false}
				transparent={true}
			>
				<View style={styles.modalContainer}>
					<View style={styles.modalContent}>
						<SelectCapabilities
							screen="profile"
							showModal={setModalShow}
							feedState={true}
							searchQuery={searchQuery}
						/>
					</View>
				</View>
			</Modal>
		</>
	)
}

const styles = StyleSheet.create({
	modalContainer: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: 'rgba(84, 84, 84, 0.6)',
	},
	modalContent: {
		width: windowWidth > 480 ? '83%' : '95%',
		justifyContent: 'center',
		maxHeight: '95%',
	},
})
