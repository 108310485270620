import {
	createCapabilities,
	createJoinSkillandCapability,
	createSkill,
	deleteJoinSkillandCapability,
	deleteSkill,
	updateJoinSkillandCapability,
} from '../../../graphql/custom-mutations'
import {
	getSkill,
	listSkillsByUserIdByDate,
} from '../../../graphql/custom-queries'
import { API } from 'aws-amplify'
import { listCandidateExperienceByUserIdByDate } from '../../../graphql/queries'
import { listJoinSkillAndCapabilitiesByUserId } from '../../../graphql/custom-queries'
import { createCandidateExperience } from '../../../graphql/mutations'

const listSkills = async (userId) => {
	try {
		const response = await API.graphql({
			query: listSkillsByUserIdByDate,
			authMode: 'API_KEY',
			variables: {
				UserId: userId,
			},
		})

		return response.data.listSkillsByUserIdByDate.items
	} catch (error) {
		console.log('Error fetching skills', error)
	}
}

const createSkills = async (input) => {
	try {
		const response = await API.graphql({
			query: createSkill,
			authMode: 'API_KEY',
			variables: {
				input: input,
			},
		})
		return response.data.createSkill
	} catch (error) {
		console.log('Error creating skill', error)
	}
}
const deleteSkills = async (input) => {
	try {
		const response = await API.graphql({
			query: deleteSkill,
			authMode: 'API_KEY',
			variables: {
				input: input,
			},
		})
	} catch (error) {
		console.log('Error deleting skill', error)
	}
}

const listWorkExperiences = async (userId) => {
	try {
		const response = await API.graphql({
			query: listCandidateExperienceByUserIdByDate,
			authMode: 'API_KEY',
			variables: { UserId: userId },
		})
		return response.data.listCandidateExperienceByUserIdByDate.items
	} catch (error) {
		console.log('Error fetching work experience', error)
	}
}

const createWorkExperience = async (input) => {
	try {
		const response = await API.graphql({
			query: createCandidateExperience,
			authMode: 'AMAZON_COGNITO_USER_POOLS',
			variables: {
				input: input,
			},
		})
	} catch (error) {
		console.log('Error creating experience', error)
	}
}

const claimCapability = async (input) => {
	try {
		const res = await API.graphql({
			query: createCapabilities,
			variables: { input: input },
		})

		return res?.data?.createCapabilities
	} catch (error) {
		console.log('Error claiming capability', error)
	}
}

const joinSkillsAndCapability = async (input) => {
	try {
		const response = await API.graphql({
			query: createJoinSkillandCapability,
			authMode: 'API_KEY',
			variables: {
				input: input,
			},
		})
		return response.data.createJoinSkillandCapability
	} catch (error) {
		console.log('Error creating skill', error)
	}
}

const updateSkillsAndCapability = async (input) => {
	try {
		const response = await API.graphql({
			query: updateJoinSkillandCapability,
			authMode: 'API_KEY',
			variables: {
				input: input,
			},
		})
		return response.data.updateJoinSkillandCapability
	} catch (error) {
		console.log('Error updating skills', error)
	}
}

const listSkillsAndCapabilitiesByUserId = async (userId) => {
	try {
		const response = await API.graphql({
			query: listJoinSkillAndCapabilitiesByUserId,
			authMode: 'API_KEY',
			variables: {
				UserId: userId,
			},
		})
		return response.data.listJoinSkillAndCapabilitiesByUserId.items
	} catch (error) {
		console.log('Error fetching skills', error)
	}
}

const deleteSkillandCapability = async (input) => {
	try {
		const response = await API.graphql({
			query: deleteJoinSkillandCapability,
			authMode: 'API_KEY',
			variables: {
				input: input,
			},
		})
		return response.data.deleteJoinSkillandCapability
	} catch (error) {
		console.log('Error deleting skill', error)
	}
}

const getSkillWithId = async (id) => {
	try {
		const response = await API.graphql({
			query: getSkill,
			authMode: 'API_KEY',
			variables: {
				id: id,
			},
		})
		return response.data.getSkill
	} catch (error) {
		console.log('Error fetching skill', error)
	}
}

export {
	listSkills,
	createSkills,
	deleteSkills,
	listWorkExperiences,
	createWorkExperience,
	joinSkillsAndCapability,
	deleteSkillandCapability,
	getSkillWithId,
	claimCapability,
	updateSkillsAndCapability,
	listSkillsAndCapabilitiesByUserId,
}
