import {
	Box,
	Divider,
	Image,
	Pressable,
	Text,
	Tooltip,
	TooltipContent,
	TooltipText,
} from '@gluestack-ui/themed'
import React, { useEffect, useState } from 'react'
import ModalComponent from '../ModalComponent'
import { Dimensions } from 'react-native'
import { Storage } from 'aws-amplify'
import { HStack } from '@gluestack-ui/themed'

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

const VideoViewerComponent = ({ keyParam, introVideo, navigation, screen }) => {
	const [videoSource, setVideoSource] = useState(null)
	const [videoModal, setVideoModal] = useState(false)
	const [loadingVideo, setLoadingVideo] = useState(true)

	const getVideo = async () => {
		try {
			const videoFromS3 = await Storage.get(keyParam)
			setVideoSource(videoFromS3)
		} catch (error) {
			console.log(error)
		}
	}

	useEffect(() => {
		if (videoSource) {
			const timer = setTimeout(() => {
				setLoadingVideo(false)
			}, 4000)

			return () => clearTimeout(timer)
		}
	}, [keyParam, loadingVideo, videoSource, introVideo])

	useEffect(() => {
		if (introVideo) {
			getVideo()
		}
	}, [introVideo])

	const VideoPlayer = () => {
		return (
			<Box>
				<video
					src={videoSource}
					// height={'100%'}
					height={windowWidth > 480 ? windowHeight * 0.9 : 250}
					width={
						windowWidth > 480
							? windowWidth * 0.7
							: windowWidth * 0.9
					}
					controls
					controlsList="nodownload"
					style={{
						objectFit: 'cover',
					}}
				/>
			</Box>
		)
	}

	return (
		<Box
			borderWidth={1}
			borderRadius={6}
			borderColor="$borderDark0"
			p={20}
			bg="white"
			marginVertical={10}
		>
			<HStack justifyContent="space-between" alignItems="center">
				<Box>
					<Text
						fontFamily="$fontHeading"
						fontWeight="600"
						fontSize={windowWidth > 480 ? 20 : 15}
						color="$primary950"
					>
						Video
					</Text>
				</Box>

				{screen === 'private' && (
					<Tooltip
						placement="top"
						trigger={(triggerProps) => {
							return (
								<Pressable
									{...triggerProps}
									onPress={() => {
										navigation.push('IntroVideo')
									}}
								>
									<Image
										source={
											introVideo
												? require('../../assets/Images/edit.png')
												: require('../../assets/Images/add.png')
										}
										h={18}
										w={18}
									/>
								</Pressable>
							)
						}}
					>
						<TooltipContent bg={'$backgroundGray'}>
							<TooltipText
								fontFamily="$fontHeading"
								fontSize={12}
								color={'$fontGray100'}
								fontWeight="600"
							>
								{introVideo ? 'Edit' : 'Add'}
							</TooltipText>
						</TooltipContent>
					</Tooltip>
				)}
			</HStack>
			<Divider
				marginVertical={windowWidth > 480 ? 20 : 10}
				bg={'$borderDark0'}
			/>
			<ModalComponent
				renderBody={VideoPlayer}
				isOpen={videoModal}
				onClose={() => {
					setVideoModal(false)
				}}
				hideCloseButton={true}
				showScroll={false}
				padding={0}
				minWidth={
					windowWidth > 480 ? windowWidth * 0.7 : windowWidth * 0.9
				}
				height={windowWidth > 480 ? windowHeight * 0.9 : 250}
			/>

			{introVideo ? (
				<Pressable
					borderWidth={1}
					w={'fit-content'}
					borderRadius={20}
					borderColor="$borderDark0"
					position="relative"
					onPress={() => {
						setVideoModal(true)
					}}
				>
					<Box position="absolute" top={10} right={10}>
						<Image
							source={require('../../assets/Images/play.png')}
							w={25}
							h={25}
						/>
					</Box>
					<Box
						h={'$full'}
						w={'$full'}
						bg="$backgroundGray"
						position="absolute"
						opacity={0.35}
						borderRadius={20}
					/>
					{loadingVideo ? (
						<Box
							h={250}
							w={150}
							alignItems="center"
							justifyContent="center"
							blurRadius={20}
						>
							<Image
								source={require('../../assets/Images/videoIcon.png')}
								h={40}
								w={40}
							/>
						</Box>
					) : (
						<video
							src={videoSource}
							height={250}
							width={150}
							autoPlay
							muted
							loop
							style={{
								objectFit: 'cover',
								borderRadius: 20,
							}}
						/>
					)}
				</Pressable>
			) : (
				<Box alignItems="center" gap={10} marginVertical={10}>
					<Text
						fontFamily="$fontHeading"
						fontSize={windowWidth > 480 ? 12 : 10}
						color="$fontGray100"
						marginTop={10}
					>
						No video available
					</Text>
					<Pressable
						onPress={() => {
							navigation.push('IntroVideo')
						}}
						bg="$primary500"
						paddingVertical={windowWidth > 480 ? 7 : 4}
						paddingHorizontal={windowWidth > 480 ? 10 : 5}
						borderRadius={6}
					>
						<Text
							fontFamily="$fontHeading"
							fontSize={windowWidth > 480 ? 13 : 11}
							color="$primary0"
							fontWeight="600"
						>
							Add a video
						</Text>
					</Pressable>
				</Box>
			)}
		</Box>
	)
}

export default VideoViewerComponent
