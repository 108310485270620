import { useEffect, useState } from 'react'
import { list_globalcapbilities_by_ActivityStatus_filer_by_domain } from '../graphql/custom-queries'
import useUserStore from '../Userstore/UserStore'
import { API } from 'aws-amplify'

const useGlobalCapabilityConfig = (setLoadingCallback) => {
	const [loading, setLoading] = useState(true)
	const GlobalCapabilities = useUserStore((state) => state.GlobalCapabilities)
	const domain = useUserStore((state) => state.domain)
	const setGlobalCapabilities = useUserStore(
		(state) => state.setGlobalCapabilities
	)

	async function getCapabilitylist() {
		setLoading(true)
		await API.graphql({
			query: list_globalcapbilities_by_ActivityStatus_filer_by_domain,
			variables: {
				ActivityStatus: 'active',
				domain: {
					eq: domain,
				},
			},
		})
			.then((res) => {
				setGlobalCapabilities(
					res.data
						.list_globalcapbilities_by_ActivityStatus_filer_by_domain
						.items,
					...GlobalCapabilities
				)
				setLoading(false)
				if (setLoadingCallback) setLoadingCallback(false)
			})
			.catch((err) => console.log(err))
	}

	async function getCapabilitylistGeneral() {
		setLoading(true)
		await API.graphql({
			query: list_globalcapbilities_by_ActivityStatus_filer_by_domain,
			variables: {
				ActivityStatus: 'active',
				domain: {
					eq: 'general',
				},
			},
		})
			.then((res) => {
				setGlobalCapabilities(
					res.data
						.list_globalcapbilities_by_ActivityStatus_filer_by_domain
						.items,
					...GlobalCapabilities
				)
				setLoading(false)
				if (setLoadingCallback) setLoadingCallback(false)
			})
			.catch((err) => console.log(err))
	}

	useEffect(() => {
		if (domain) {
			getCapabilitylist()
			getCapabilitylistGeneral()
		}
	}, [domain])
}

export default useGlobalCapabilityConfig
