import {
	Box,
	Divider,
	FlatList,
	HStack,
	Text,
	VStack,
} from '@gluestack-ui/themed'
import { Dimensions, Pressable, Alert, View } from 'react-native'
import React, { useEffect, useState, useCallback } from 'react'
import { API } from 'aws-amplify'
import SpinnerComponent from '../../newComponents/SpinnerComponent'
import CompanyDetailComponent from './components/CompanyDetailsComponent'// Import the new component
import {MobileHeader }from '../../newComponents/header/Header'

import { listCompanyprofiles } from '../../graphql/custom-queries'
import useUserStore from '../../Userstore/UserStore'
import {deleteCompanyprofile }from '../../graphql/custom-mutations'
import ModalComponent from '../../newComponents/ModalComponent'
import ButtonComponent from '../../newComponents/ButtonComponent'
const windowWidth = Dimensions.get('window').width
const contentWidth = windowWidth * 0.7 // 70% of screen width


export default function AllCompanyScreen({ route, navigation }) {
	const userId = useUserStore((state) => state.userId)
	const [loading, setLoading] = useState(true)
	const [companydata, setCompanyData] = useState([])
	const [showApprovalModal, setShowApprovalModal] = useState({ isOpen: false, id: null })
    const [deleting, setDeleting] = useState(false) // New state for delete loading


	const getCompanyProfiles = useCallback(async () => {
		try {
			const res = await API.graphql({
				query: listCompanyprofiles,
				
			})
			
			// Sort the company data by createdAt in descending order
			const sortedData = res.data.listCompanyprofiles.items.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
			setCompanyData(sortedData)
			setLoading(false)
		} catch (error) {
			setLoading(false)
			console.log(error)
		}
	}, [userId])

	useEffect(() => {
		getCompanyProfiles()
	}, [])

	const handleEdit = (id) => {
		navigation.push('EditCompanyScreen', { id }) // Navigate to the edit screen
	}

	const handleDelete = async (id) => {
		setShowApprovalModal({ isOpen: true, id }) // Open modal for confirmation
	}

	const confirmDelete = async () => {
		const id = showApprovalModal.id
        setDeleting(true)
		try {
			const res = await API.graphql({
				query: deleteCompanyprofile,
				variables: { input: { id } },

                
			})

			// Update the company data after successful deletion
			setCompanyData(companydata.filter(company => company.id !== id))
			setShowApprovalModal({ isOpen: false, id: null }) // Close modal after deletion
		} catch (error) {
			console.log('Error deleting company:', error)
		}finally {
			setDeleting(false) // Reset loading state
		}
	}

	const renderApprovalBodyConfirmation = () => (
		<VStack space={4} alignItems="center" padding={20}>
			<Text
            color="$fontGray100"
            fontWeight="600"
            fontFamily="$fontHeading"
            fontSize={
                windowWidth > 480
                    ? '$fontContent'
                    : '$fontContentMobile'
            }
            >Are you sure you want to delete this company?</Text>
			<HStack space={4} marginTop={20} justifyContent='space-around' width={300}>
				<ButtonComponent
                width={windowWidth > 480 ? 100 : 50}
                height={windowWidth > 480 ? 40 : 30}
                text={'Cancel'}
                color={'green'}
                borderColor={'green'}
                disabled={deleting}
                loading={deleting}
                
                onPress={() => setShowApprovalModal({ isOpen: false, id: null })} />
				
				
				<ButtonComponent onPress={confirmDelete} 
                
                  width={windowWidth > 480 ? 100 : 50}
                  height={windowWidth > 480 ? 40 : 30}
                  color={'red'}
                  borderColor={'red'}
                  text={'Delete'}
                  disabled={deleting}
                  loading={deleting}
               />
					
				
			</HStack>
		</VStack>
	)

	const renderItem = ({ item }) => {
		return (
            <>
            <ModalComponent
					isOpen={showApprovalModal.isOpen && showApprovalModal.id === item.id}
					onClose={() => setShowApprovalModal({ isOpen: false, id: null })}
					heading={`Delete Company`}
					renderBody={renderApprovalBodyConfirmation}
				/>
            <Pressable onPress={() => navigation.push("PreferencesScreen", {id : item.id})}>
				<CompanyDetailComponent 
					company={item} 
					onEdit={handleEdit} 
					onDelete={handleDelete} 
				/>
			</Pressable>
            </>
		)
	}

	return (
        <View style={{ flex: 1, alignItems: 'center' }}>
            <Box width="100%" zIndex={1}>
                <MobileHeader props={'All Company Roles'} navigation={navigation} />
            </Box>
            <Box width={contentWidth} maxWidth={contentWidth} style={{ marginTop: 50 }}>
                {loading ? (
                    <SpinnerComponent />
                ) : (
                    <FlatList
                        data={companydata}
                        renderItem={renderItem}
                        keyExtractor={(item) => item.id}
                        ItemSeparatorComponent={() => <View style={{ height: 16 }} />}
                        contentContainerStyle={{ paddingTop: 20, paddingBottom: 20 }}
                    />
                )}
            </Box>
        </View>
    )
}
