import { Dimensions } from 'react-native'
import { Box, Text, HStack, VStack } from '@gluestack-ui/themed'
import React from 'react'
import WorkExperience from './workExperience/WorkExperienceComponent'
import EducationDetails from './educationDetails/EducationDetails'
import { TouchableOpacity } from 'react-native-web'
import CertificateComponent from './certifications/CertificateComponent'
import ButtonComponent from '../ButtonComponent'
import { useRef } from 'react'
import { useState } from 'react'

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

export default function CareerEditComponent({
	setScreenstate,
	screenState,
	setMapData,
}) {
	const ref = useRef(null)

	return (
		<VStack alignSelf="center" w={'100%'}>
			<Box>
				<WorkExperience ref={ref} />
				<EducationDetails ref={ref} />
				<CertificateComponent ref={ref} />
			</Box>

			<Box
				flexDirection={windowWidth > 650 ? 'row' : 'column-reverse'}
				justifyContent="space-between"
				w={windowWidth > 480 ? '80%' : '95%'}
				alignSelf="center"
			>
				<ButtonComponent
					onPress={() => {
						setScreenstate(screenState - 1)
						setMapData([])
					}}
					text={'Go Back'}
					marginVertical={windowWidth > 650 ? 25 : 5}
					width={windowWidth > 650 ? 210 : '100%'}
					color={'$white'}
					textColor={'$primary500'}
				/>
				<ButtonComponent
					onPress={() => {
						setScreenstate(screenState + 1)
					}}
					text={'Save and Proceed'}
					marginVertical={windowWidth > 650 ? 25 : 5}
					width={windowWidth > 650 ? 210 : '100%'}
				/>
			</Box>
			<Box marginVertical={5}>
				<Text
					color="$borderDark0"
					fontFamily="$fontContent"
					fontSize={12}
					textAlign="center"
				>
					Logos provided by Clearbit
				</Text>
			</Box>
		</VStack>
	)
}
