import React from 'react'
import { Dimensions } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { Center, Pressable, Text } from '@gluestack-ui/themed'
import ButtonComponent from '../../newComponents/ButtonComponent'

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

const AlreadyUser = ({
	navigation,
	loginScreen,
	signInOption,
	routes,
	setTab,
	inModal,
}) => {
	const loginAccount = () => {
		if (inModal) {
			setTab('Login')
		} else {
			navigation.push('Login')
		}
	}
	const createNewAccount = () => {
		if (inModal) {
			setTab('CreateAccount')
		} else {
			navigation.push('CreateAccount')
		}
	}

	return (
		<>
			<Center w={'100%'} mt={!signInOption && 20}>
				{signInOption ? (
					<ButtonComponent
						text={'Sign in with password'}
						width={'100%'}
						onPress={loginAccount}
						marginVertical={windowWidth > 480 ? 20 : 10}
					/>
				) : null}

				{loginScreen ? (
					<Text
						fontSize={windowWidth > 480 ? 13 : 11}
						fontWeight="600"
						fontFamily={'$fontContent'}
						letterSpacing={0.6}
					>
						Don't have an account?{'  '}
						<Pressable onPress={createNewAccount}>
							<Text
								fontSize={windowWidth > 480 ? 13 : 11}
								color={
									signInOption || inModal
										? '$primary500'
										: '$textLight0'
								}
								fontFamily={'$fontContent'}
								fontWeight="700"
								letterSpacing={0.6}
							>
								Sign Up
							</Text>
						</Pressable>
					</Text>
				) : (
					<Text
						fontSize={windowWidth > 480 ? 13 : 11}
						fontWeight="600"
						fontFamily={'$fontContent'}
						letterSpacing={0.6}
					>
						Already have an account?{'  '}
						<Pressable onPress={loginAccount}>
							<Text
								fontSize={windowWidth > 480 ? 13 : 11}
								fontFamily={'$fontContent'}
								color={
									signInOption || inModal
										? '$primary500'
										: '$textLight0'
								}
								fontWeight="700"
								letterSpacing={0.6}
							>
								Sign In
							</Text>
						</Pressable>
					</Text>
				)}
			</Center>
		</>
	)
}

export default AlreadyUser
