import { StyleSheet, Text, View, Image, Dimensions } from 'react-native'
import React from 'react'
import { HStack } from 'native-base'
const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;
const ProfileFeedback = ({props}) => {
 
  return (
    <View style ={{width: (windowWidth < 480) ? "90%" : "90%", 
    alignContent: "center", alignSelf: (windowWidth < 480) ? "center" : "auto"}}>
       
      
        <View style ={{borderWidth: 1, padding: 15, borderRadius: 20, marginTop: 10, borderColor: "#F1F1F1"}}>
           
            <HStack style ={{marginBottom: 10}}>
            <Image style ={{height: 40, width: 40}}
            source={require("../assets/Images/profile.png")}  />
             <View style ={{margin: 5,}}>
              <HStack>
              <Text style ={styles.name}>
                {props.name}
            </Text>
            <Image style ={{height: 16, width: 16, marginTop: 2}}
             source={require("../assets/Images/qrusiblesmall.png")}/>
            <Image style ={{height: 16, width: 16, marginTop: 2}}
             source={require("../assets/Images/shield.png")}/>
             
              </HStack>
           
            <Text style ={styles.designation}>
                {props.designation}
            </Text>
            </View>
            </HStack>
           
            
            <Text style = {styles.description}>
            {props.description}
            </Text>
            {/* <View style ={{width: "100%", height: 1, backgroundColor: "grey", marginTop: 10}}/> */}
        </View>
      </View>
    
  )
}

export default ProfileFeedback

const styles = StyleSheet.create({
  name: {
    fontFamily: "Lexend",
    fontSize: 16,
    fontWeight: "600",
    color: "#686868"
  },
  designation: {
    color: "#AAAAAA",
    fontSize: 14,
    fontFamily: "Open Sans"
  },
  description: {
    fontFamily: "Open Sans",
    color: "#5B5B5B",
    fontSize: 14

  }
})