import { Dimensions } from 'react-native'
import { Box, HStack, Heading, Image, Text, Pressable, 
    FlatList, Icon, CloseIcon, } from '@gluestack-ui/themed'

import React, { useEffect, useState, useRef } from 'react'
import { getPreferenceCapability, getPreferenceSkillandtraits } from '../../../graphql/custom-queries'
import { createGlobalCapabilityConfig, createJoinGlobalCapabilityconfigAndJobPost, deleteJoinGlobalCapabilityconfigAndJobPost } from '../../../graphql/custom-mutations'
import useUserStore from '../../../Userstore/UserStore'

import { API, Storage, Auth } from 'aws-amplify'
// import { getNewprofile } from '../../../graphql/queries'
// import {
// 	createCandidatePreferences,
// 	createProfile,
// 	updateCandidatePreferences,
// 	updateNewprofile,
// } from '../../../graphql/custom-mutations'


import LabelWithInputComponent from '../../../newComponents/LabelWithInputComponent'
import SelectCapabilities from '../../../newComponents/selectCapabilities/SelectCapabilities'
import ButtonComponent from '../../../newComponents/ButtonComponent'
import SpinnerComponent from '../../../newComponents/SpinnerComponent'

import CompanyMultipleSelectCapability from './CompanyMultipleSelectCapability'
import CompanySelectCapabilities from './CompanySelectCapabilities'
const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

export default function CreateCapabilityforJobPost({
	handleProceed,
	handleGoBack,
	responseLambda,
	mapData,
	screen,
	hideModal,
	hiddenHeading,
	navigation,
	jobType,
	preferenceId,
	companyProfileId
}) {
	// const [user, setUser] = useState('');

	const [loading, setLoading] = useState(false)
	const [loadingPage, setLoadingPage] = useState(false)
	const savedUrl = useUserStore((state) => state.savedurl)
	
	const [filledPercentage, setFilledPercentage] = useState(40)
	const [displayText, setDisplayText] = useState(
		'Your resume is being processed'
	)
	const [resumeUploaded, setResumeUploaded] = useState(false)

	const checkScreen = useUserStore((state) => state.checkScreen)
	const signout = useUserStore((state) => state.signOut)

	

	const [wordCount, setWordCount] = useState(0)

	const [userpreferences, setUserpreferences] = useState({
		experienceList: '',
		domain: '',
		userpreferenceId: '',
	})

    const [Capabilities, setCapability] = useState([])
	const [AddedCapability, setAddedCapability] = useState('')
    const [CapabilityText, setCapabilityText] = useState('')
	const [AlreadyMadeCapability, setAlreadyMadeCapability] = useState([])
	const [JobPostId, setJobPostId] =useState('')
	const [JobDomain,setJobDomain] = useState('')

 
	
   
	const addInputCapability = (value) => {
        setCapability(oldArray => [...oldArray, CapabilityText ])
        setCapabilityText('')
		
		
	}
	const handleRemoveAddedCapability = async (item) => {
		
		const CreateUserInput = {
						
					
			id: item
		}
		await API.graphql({
			query: deleteJoinGlobalCapabilityconfigAndJobPost,
			// authMode: 'API_KEY',
			variables: { input: CreateUserInput },
		}).then((res) => { RemoveAddedCapabilityfromState(item)})
		
		// remove from the array by index value
		
		
	}

	const RemoveAddedCapabilityfromState = (item) => {
		
	
		const updatedItems = AddedCapability.filter((data) => data.id !== item)
		setAddedCapability(updatedItems)
		

	}

	async function RequiredCapability() {
		{
			setLoading(true)
			
			Capabilities?.map((value, i) => {
				if (value) {
					const CreateUserInput = {
						
					
						name : value ,
						domain :JobDomain,
						tag :'Unique',
						ActivityStatus:'active'
					}
					API.graphql({
						query: createGlobalCapabilityConfig,
						// authMode: 'API_KEY',
						variables: { input: CreateUserInput },
					}).then(async (res) => {
						
							await CreateJoinCapJobPost(res.data.createGlobalCapabilityConfig.id)
						
						 
						
					})
				}
			})

			JoinAlreadyMadeCapabilities()
		}
	}
	const CreateJoinCapJobPost = async (id) => {
	
		const CreateUserInput = {
			GlobalCapabilityConfigID : id,
			jobPostID :JobPostId,
      
		}
		API.graphql({
			query: createJoinGlobalCapabilityconfigAndJobPost,
			// authMode: 'API_KEY',
			variables: { input: CreateUserInput },
		}).then(async (res) => {
			
			return res
			
		})
	}
	
	const handleRemoveCapability = (value) => {
       
		// remove from the array by index value
		
        setCapability(l => l.filter(item => item !== value));
	}
	async function JoinAlreadyMadeCapabilities() {
		{
			
			AlreadyMadeCapability?.map((value, i) => {
				if (value) {
					const CreateUserInput = {
						
					
						GlobalCapabilityConfigID : value,
						jobPostID :JobPostId,
					}
					API.graphql({
						query: createJoinGlobalCapabilityconfigAndJobPost,
						// authMode: 'API_KEY',
						variables: { input: CreateUserInput },
					}).then(async (res) => {
						
							
						
						 
						
					}).catch(err => console.log(err, 'inJoin'))
				}
			})
			setLoading(false)
			handleProceed()
		}
	}
	// const handleRemoveAddedCapability = (id) => {
	// 	console.log(id)
	// 	const CreateUserInput = {
						
					
	// 		id: id
	// 	}
	// 	API.graphql({
	// 		query: deleteJoinGlobalCapabilityconfigAndJobPost,
	// 		// authMode: 'API_KEY',
	// 		variables: { input: CreateUserInput },
	// 	}).then((res) => {
	// 		setAddedCapability(l => l.filter(item => 
	// 			{console.log(item.id)
					
	// 				item.id !== id}))
	// 		console.log(res)
	// 	})
        
	// 	// remove from the array by index value
		
        
	// }



	const renderCapabilityList = ({ item }) => {
		
      
		return (
			// <Box
				
				
			// 	width={'100%'}
			// 	flexDirection="row"
			// 	justifyContent="space-between"
				
			// >
			// 	<Box borderWidth={1} borderRadius = {5} width= {'100%'} my={10}
			// 	p={3}>
            //     <Text
			// 		fontSize={windowWidth > 480 ? 14 : 12}
			// 		textAlign="center"
			// 		fontFamily="$fontHeading"
			// 		px={5}
			// 		py={0}
			// 		color={
            //             '$green500'
			// 		}
			// 	>
			// 		{item}
			// 	</Text>
            //     </Box>
				
            //     <Pressable
			// 			onPress={() => {
			// 				handleRemoveCapability(item)
			// 			}}
			// 		>
			// 			<Icon
			// 				as={CloseIcon}
			// 				size={'sm'}
			// 				color={
							
			// 							 'black'
									
			// 				}
			// 			/>
			// 		</Pressable>
				
				
			// </Box>
			<Pressable
			borderWidth={2}
			mx={5}
			borderRadius={50}
			my={10}
			p={3}
			
			flexDirection="row"
			justifyContent="space-between"
			alignItems="center"
			borderColor={
				
					'$primary300'
			}
			
		>
			
			<Text
				fontSize={windowWidth > 480 ? 14 : 12}
				textAlign="center"
				fontFamily="$fontHeading"
				px={5}
				py={0}
				color={
					'$primary300'
				}
			>
				{item}
			</Text>
			<Pressable
					onPress={() => {
						handleRemoveCapability(item)
					}}
				>
					<Icon
						as={CloseIcon}
						size={'sm'}
						color={
						
									 'black'
								
						}
					/>
				</Pressable>
			
			
		</Pressable>
		)
	}
    // const renderAddedCapabilityList = ({ item }) => {
		
      
	// 	return (
	// 		<Pressable
	// 			borderWidth={2}
	// 			mx={5}
	// 			borderRadius={50}
	// 			my={10}
	// 			p={3}
				
	// 			flexDirection="row"
	// 			justifyContent="space-between"
	// 			alignItems="center"
	// 			borderColor={
					
	// 					'$coolGray500'
	// 			}
	// 			bg={
					
	// 					 '#D3D3D3'
	// 			}
	// 		>
				
	// 			<Text
	// 				fontSize={windowWidth > 480 ? 14 : 12}
	// 				textAlign="center"
	// 				fontFamily="$fontHeading"
	// 				px={5}
	// 				py={0}
	// 				color={
    //                     '$green500'
	// 				}
	// 			>
	// 				{item.CapabilityConfig.name}
	// 			</Text>
    //             <Pressable
	// 					onPress={() => {
	// 						handleRemoveAddedCapability(item.id)
	// 					}}
	// 				>
	// 					<Icon
	// 						as={CloseIcon}
	// 						size={'sm'}
	// 						color={
							
	// 									 'black'
									
	// 						}
	// 					/>
	// 				</Pressable>
				
				
	// 		</Pressable>
	// 	)
	// }

	async function getCapabilities() {
		setLoadingPage(true)
		await API.graphql({
			query: getPreferenceCapability,
			// authMode: 'API_KEY',
			variables: {
				id: preferenceId,
			},
		})
			.then((res) => {
				
				
				
				setJobDomain(res.data.getPreferences.JobDomain)
				setJobPostId(res.data.getPreferences.JobPosts.items[0].id)
				setAddedCapability(res.data.getPreferences.JobPosts.items[0].CapabilitiesRequired.items)
				// setSkill()
				

				// setLocation(res.data.getNewprofile?.location)
				
				setLoading(false)
				setLoadingPage(false)
			})
			.catch((err) => {
				
					console.log(err)
					
			})
	}
	
	
	useEffect(() => {
		getCapabilities()
		
	}, [])

	

	

	return (
		<>
			<Box
				borderWidth={hiddenHeading ? null : 1}
				borderColor="$borderDark0"
				borderRadius={6}
				alignSelf="center"
				justifyContent="center"
				alignItems="center"
				w={'100%'}
				paddingVertical={windowWidth > 480 ? 25 : 10}
				paddingHorizontal={windowWidth > 480 ? 10 : 5}
				marginBottom={30}
				bg="$primary0"
			>
		
					<Box
						w={'100%'}
						alignSelf="center"
						justifyContent="center"
						alignItems="center"
					>
					
						
                      
						
						<Box w={windowWidth > 480 ? '80%' : '95%'}>
						<Heading
									
									fontFamily="$fontHeading"
									fontSize={
										windowWidth > 480
											? '$fontHeading'
											: '$fontHeadingMobile'
									}
									fontWeight="700"
								>
									About the Candidate
									
								</Heading>
                                <Text
								fontFamily="$fontContent"
								fontSize={
									windowWidth > 480
										? '$fontContent'
										: '$fontContentMobile'
								}
								fontWeight="700"
								color="$primary950"
								>
								Highlight the required Capabilities for the candidates
                        </Text>
						{JobDomain && 
						<CompanySelectCapabilities
						handleGoBack={handleGoBack}
						navigation={navigation}
						JobDomain = {JobDomain}
						selectedCapabilities = {AddedCapability}
						setAlreadyMadeCapability ={setAlreadyMadeCapability}
						AlreadyMadeCapability = {AlreadyMadeCapability}
						setselectedCapabilities = {setAddedCapability}
						handleRemoveAddedCapability ={handleRemoveAddedCapability}
					
						
					/>
						}
						
                        <HStack >
						
                        <LabelWithInputComponent
								label={'Add Capability'}
								placeholder={'Adding Custom Capability'}
								setValueChange={setCapabilityText}
							
								important={true}
								value={CapabilityText}
							/>
                          
                          <Pressable onPress={() => addInputCapability()}>

                            <Image alignSelf='baseline'  marginTop={45} borderRadius={10}
							
							marginLeft={20}
                            style={{ height: 30, width: 30 }} 
                            source={require('../../../assets/Images/addbuttonimage.png')}
                                    />
                    </Pressable>


                        </HStack>
						<Box borderWidth={2} borderColor='lightgrey' padding={5} marginVertical = {15} borderRadius={10}>
							 <FlatList
                            data={Capabilities}
                            // keyExtractor={(item) => item.id.toString()}
                            renderItem={renderCapabilityList}
                            contentContainerStyle={{
                                flex: 1,
                                flexWrap: 'wrap',
                            }}
							ListEmptyComponent={() => {return(
								<Text
								fontFamily="$fontContent"
								fontSize={
									windowWidth > 480
										? '$fontContent'
										: '$fontContentMobile'
								}
								fontWeight="700"
								color="$primary950"
								>
									Not added Any custom capabilities
								</Text>
							)}}
                            horizontal={true}
                        />
						  
						  {/* <FlatList
                            data={AddedCapability}
                            // keyExtractor={(item) => item.id.toString()}
                            renderItem={renderAddedCapabilityList}
                            contentContainerStyle={{
                                flex: 1,
                                flexWrap: 'wrap',
                            }}
                            horizontal={true}
                        /> */}
								</Box> 
                          
                            
                           

						</Box>
                       
                       
						<Box
							flexDirection={
								windowWidth > 650 ? 'row' : 'column-reverse'
							}
							justifyContent={'space-between'}
							w={windowWidth > 480 ? '80%' : '95%'}
						>
							
					<ButtonComponent
					marginVertical={windowWidth > 650 ? 25 : 5}
						onPress={() => handleGoBack()}
						text={'Go Back'}
						width={windowWidth > 650 ? 210 : '100%'}
						color={'$white'}
						textColor={'$primary500'}
					/>
							<ButtonComponent
								loading={loading}
								disabled={
									false
								}
								onPress={() => {
									RequiredCapability()
								}}
								text={
							
										 'Save and Proceed'
										
								}
								marginVertical={windowWidth > 650 ? 25 : 5}
								width={
									windowWidth > 650
										
											? 210
											: 100
									
								}
							/>
						</Box>
					</Box>
				
			</Box>
		</>
	)
}
