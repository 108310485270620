import {
	Box,
	Avatar,
	AvatarFallbackText,
	AvatarImage,
} from '@gluestack-ui/themed'
import { Dimensions } from 'react-native'
import React from 'react'

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

const AvatarComponent = ({
	name,
	height,
	width,
	imgSource,
	imgPath,
	borderRadius,
}) => {
	return (
		<Avatar
			bgColor="$primary500"
			h={height ? height : windowWidth > 480 ? 100 : 70}
			w={width ? width : windowWidth > 480 ? 100 : 70}
		>
			<AvatarFallbackText
				fontSize={15}
				fontFamily="$fontContent"
				color="$primary0"
				fontWeight="700"
			>
				{name ? name : 'Qrusible Pvt. Limited'}
			</AvatarFallbackText>
			<AvatarImage
				borderRadius={borderRadius ? borderRadius : 50}
				source={imgSource ? { uri: imgSource } : { imgPath }}
				alt="profile image"
			/>
		</Avatar>
	)
}

export default AvatarComponent
