import { Dimensions } from 'react-native'
import { Box, HStack, Heading, Image, Text, Pressable, 
    FlatList, Icon, CloseIcon, } from '@gluestack-ui/themed'

import React, { useEffect, useState, useRef } from 'react'

import useUserStore from '../../../Userstore/UserStore'

import { getPreferenceSkillandtraits } from '../../../graphql/custom-queries'

import { API, Storage, Auth } from 'aws-amplify'

import LabelWithInputComponent from '../../../newComponents/LabelWithInputComponent'
import ButtonComponent from '../../../newComponents/ButtonComponent'
import SpinnerComponent from '../../../newComponents/SpinnerComponent'
import { createJobTraits } from '../../../graphql/mutations'
import {updateJobPost } from '../../../graphql/custom-mutations'
import { deleteJobTraits } from '../../../graphql/mutations'


const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

export default function CreateUpdateSkillTrait({
	handleProceed,
	handleGoBack,
	responseLambda,
	mapData,
	screen,
	hideModal,
	hiddenHeading,
	navigation,
	jobType,
	preferenceId,
	companyProfileId
}) {
	// const [user, setUser] = useState('');

	const [loading, setLoading] = useState(false)
	const [loadingPage, setLoadingPage] = useState(false)
    const [JobPostId, setJobPostId] = useState('')
	const [filledPercentage, setFilledPercentage] = useState(40)
	const [displayText, setDisplayText] = useState(
		'Your resume is being processed'
	)
	const [resumeUploaded, setResumeUploaded] = useState(false)

	const checkScreen = useUserStore((state) => state.checkScreen)
	const signout = useUserStore((state) => state.signOut)

	

	const [wordCount, setWordCount] = useState(0)

	const [userpreferences, setUserpreferences] = useState({
		experienceList: '',
		domain: '',
		userpreferenceId: '',
	})

	const [fileName, setFileName] = useState('')

	const setProfileId = useUserStore((state) => state.setProfileId)
	const user = useUserStore((state) => state.user)
	const userEmail = useUserStore((state) => state.userEmail)
    const [numInputs, setNumInputs] = useState(1)
	const numInputTraitsRef = useRef(0)
    const numInputSkillsRef = useRef(1)
    const [traits, setTraits] = useState([])
	const [AddedTraits, setAddedTraits] = useState([])
    const [skill, setSkill] = useState([])
    const [skillText, setSkillText] = useState('')
    const [traitsText, settraitsText] = useState('')
   
	console.log("jobpostid",JobPostId)

	
   
	const addInputSkill = () => {
        setSkill(oldArray => [...oldArray,skillText ])
        setSkillText('')
		
		
	}
    const addInputTrait = (value) => {
        setTraits(oldArray => [...oldArray,traitsText ])
        settraitsText('')
		
		
	}
	const handleRemoveSkill = (value) => {
        console.log(value)
		// remove from the array by index value
		
        setSkill(l => l.filter(item => item !== value));
	}
    const handleRemoveTraits = (value) => {
        console.log(value)
		// remove from the array by index value
		
        setTraits(l => l.filter(item => item !== value));
	}
	const handleRemoveAddedTraits = (id) => {
		const CreateUserInput = {
						
					
			id: id
		}
		API.graphql({
			query: deleteJobTraits,
			// authMode: 'API_KEY',
			variables: { input: CreateUserInput },
		}).then((res) => {
			setAddedTraits(l => l.filter(item => item.id !== id))
			console.log(res)
		})
        
		// remove from the array by index value
		
        
	}



	const renderSkillsList = ({ item }) => {
		
      
		return (
			<Pressable
				borderWidth={2}
				mx={5}
				borderRadius={50}
				my={10}
				p={3}
				
				flexDirection="row"
				justifyContent="space-between"
				alignItems="center"
				borderColor={
					
					'$primary300'
				}
				
			>
				
				<Text
					fontSize={windowWidth > 480 ? 14 : 12}
					textAlign="center"
					fontFamily="$fontHeading"
					px={5}
					py={0}
					color={
						'$primary300'
					}
				>
					{item}
				</Text>
                <Pressable
						onPress={() => {
							handleRemoveSkill(item)
						}}
					>
						<Icon
							as={CloseIcon}
							size={'sm'}
							color={
							
										 'black'
									
							}
						/>
					</Pressable>
				
				
			</Pressable>
		)
	}
    const renderTraitsList = ({ item }) => {
		
      
		return (
			<Pressable
				borderWidth={2}
				mx={5}
				borderRadius={50}
				my={10}
				p={3}
				
				flexDirection="row"
				justifyContent="space-between"
				alignItems="center"
				borderColor={
					
					'$primary300'
				}
				
			>
				
				<Text
					fontSize={windowWidth > 480 ? 14 : 12}
					textAlign="center"
					fontFamily="$fontHeading"
					px={5}
					py={0}
					color={
						'$primary300'
					}
				>
					{item}
				</Text>
                <Pressable
						onPress={() => {
							handleRemoveTraits(item)
						}}
					>
						<Icon
							as={CloseIcon}
							size={'sm'}
							color={
							
										 'black'
									
							}
						/>
					</Pressable>
				
				
			</Pressable>
		)
	}
	const renderAddedTraitsList = ({ item }) => {
		
      
		return (
			<Pressable
				borderWidth={2}
				mx={5}
				borderRadius={50}
				my={10}
				p={3}
				
				flexDirection="row"
				justifyContent="space-between"
				alignItems="center"
				borderColor={
					
					'$primary300'
				}
				
			>
				
				<Text
					fontSize={windowWidth > 480 ? 14 : 12}
					textAlign="center"
					fontFamily="$fontHeading"
					px={5}
					py={0}
					color={
						'$primary300'
					}
				>
					{item.TraitName}
				</Text>
                <Pressable
						onPress={() => {
							handleRemoveAddedTraits(item.id)
						}}
					>
						<Icon
							as={CloseIcon}
							size={'sm'}
							color={
							
										 'black'
									
							}
						/>
					</Pressable>
				
				
			</Pressable>
		)
	}
	async function createTraits() {
		setLoading(true)
		{
			traits?.map((value, i) => {
				if (value) {
					const CreateUserInput = {
						
					
						preferencesID : preferenceId,
						TraitName: value
					}
					API.graphql({
						query: createJobTraits,
						// authMode: 'API_KEY',
						variables: { input: CreateUserInput },
					}).then((res) => {
						console.log(res)
					})
				}
			})

			handleProceed()
			setLoading(false)
		}
	}
	const UpdateJobPost = async() => {
		setLoading(true)
		const CreateUserInput = {
			id: JobPostId,
			skills :skill

			
			
		}
		await API.graphql({
			query: updateJobPost,
			variables: { input: CreateUserInput }
			
		}).then((res)=> {
			setLoading(true)
			createTraits()
			console.log('UpdateJobPOst',res)})
		.catch((err) => {
			console.log('UpdateJobPost', err)
		})
	
	
}


	useEffect(() => {
		getSkillandTraits()
		// getProfile()
		// if (mapData?.length !== 0) {
		// 	setFullName(mapData?.name)
		// }
		// if (mapData?.length !== 0) {
		// 	setBio(mapData?.about)
		// }
		// if (mapData?.length !== 0) {
		// 	setExpertise(mapData?.experiences[0]?.designation)
		// }
		// if (mapData?.length !== 0) {
		// 	setPhone(mapData?.phoneNo)
		// }
		// getSession()
	}, [])

	async function getSkillandTraits() {
		setLoadingPage(true)
		await API.graphql({
			query: getPreferenceSkillandtraits,
			// authMode: 'API_KEY',
			variables: {
				id: preferenceId,
			},
		})
			.then((res) => {
				setJobPostId(res.data.getPreferences.JobPosts.items[0]?.id)
				console.log(res)
				let skill = []
				console.log(res)
				skill = res.data.getPreferences.JobPosts.items[0].skills 
				skill.map((value) => {
					setSkill(oldArray => [...oldArray,value ])
				})
				
				
				// setSkill()
				setAddedTraits(res.data.getPreferences.JobTraits.items)

				// setLocation(res.data.getNewprofile?.location)
				
				setLoading(false)
				setLoadingPage(false)
			})
			.catch((err) => {
				
					console.log(err)
					
			})
	}

	// useEffect(() => {
	// 	const timer = setInterval(() => {
	// 		const newPercentage = filledPercentage + 20
		
	// 		if (newPercentage >= 95) {
	// 			setFilledPercentage(95)
	// 		} else {
	// 			setFilledPercentage(newPercentage)
	// 		}
	// 	}, 8000)

	// 	return () => clearInterval(timer)
	// }, [filledPercentage, displayText])

	

	return (
		<>
			<Box
				borderWidth={hiddenHeading ? null : 1}
				borderColor="$borderDark0"
				borderRadius={6}
				alignSelf="center"
				justifyContent="center"
				alignItems="center"
				w={'100%'}
				paddingVertical={windowWidth > 480 ? 25 : 10}
				paddingHorizontal={windowWidth > 480 ? 10 : 5}
				marginBottom={30}
				bg="$primary0"
			>
				
					<Box
						w={'100%'}
						alignSelf="center"
						justifyContent="center"
						alignItems="center"
					>
						
                       
						
						<Box w={windowWidth > 480 ? '80%' : '95%'}>
						<Heading
									
									fontFamily="$fontHeading"
									fontSize={
										windowWidth > 480
											? '$fontHeading'
											: '$fontHeadingMobile'
									}
									fontWeight="700"
								>
									About the Candidate
									
								</Heading>
                                <Text
								fontFamily="$fontContent"
								fontSize={
									windowWidth > 480
										? '$fontContent'
										: '$fontContentMobile'
								}
								fontWeight="700"
								color="$primary950"
								>
								Highlight the required skills and attributes for success in the role.
                        </Text>
                        <HStack>
                        <LabelWithInputComponent
								label={'Skills'}
								placeholder={'List technical skills or expertise necessary for the job'}
								setValueChange={setSkillText}
							
								important={true}
								value={skillText}
							/>
                          
                          <Pressable onPress={() => addInputSkill()}>

                            <Image
                        marginLeft={20}
						style={{ height: 30, width: 30 }} 
							 alignSelf='baseline'  marginTop={45} borderRadius={10}
                            source={require('../../../assets/Images/addbuttonimage.png')}
                                    />
                    </Pressable>


                        </HStack>
						{skill.length > 0 && 
						 <Box borderWidth={2} borderColor='lightgrey' padding={5} marginVertical = {15} borderRadius={10}>
						 <FlatList
						data={skill}
						// keyExtractor={(item) => item.id.toString()}
						renderItem={renderSkillsList}
						contentContainerStyle={{
							flex: 1,
							flexWrap: 'wrap',
						}}
						horizontal={true}
					/>
							</Box> 
						}
							
                           
                            
    
                                <HStack>
                                <LabelWithInputComponent
								label={'Traits'}
								placeholder={'Specify personal or professional qualities needed for effective performance.'}
								setValueChange={settraitsText}
							
								important={true}
								value={traitsText}
							/>
                              <Pressable onPress={() => addInputTrait()}>

                                <Image
                                marginLeft={20}
								style={{ height: 30, width: 30 }} 
								alignSelf='baseline'  marginTop={45}  borderRadius={10}
                                source={require('../../../assets/Images/addbuttonimage.png')}
                                        />
                                </Pressable>

                                </HStack>
								<Box borderWidth={2} borderColor='lightgrey' padding={5} marginVertical = {15} borderRadius={10}>
								<FlatList
                            data={traits}
                            // keyExtractor={(item) => item.id.toString()}
                            renderItem={renderTraitsList}
							contentContainerStyle={{
                                flex: 1,
                                flexWrap: 'wrap',
                            }}
                           
                            
                        />
						  <FlatList
                            data={AddedTraits}
                            // keyExtractor={(item) => item.id.toString()}
                            renderItem={renderAddedTraitsList}
							contentContainerStyle={{
                                flex: 1,
                                flexWrap: 'wrap',
                            }}
                           
                            
                        />

								</Box>
                            

						</Box>
                       
                       
						<Box
							flexDirection={
								windowWidth > 650 ? 'row' : 'column-reverse'
							}
							justifyContent={'space-between'}
							w={windowWidth > 480 ? '80%' : '95%'}
						>
							
					<ButtonComponent
					marginVertical={windowWidth > 650 ? 25 : 5}
						onPress={() => handleGoBack()}
						text={'Go Back'}
						width={windowWidth > 650 ? 210 : '100%'}
						color={'$white'}
						textColor={'$primary500'}
					/>
							<ButtonComponent
								loading={loading}
								disabled={
									false
								}
								onPress={() => {
						UpdateJobPost()
								}}
								text={
									'Save and Proceed'
									
								}
								marginVertical={windowWidth > 650 ? 25 : 5}
								width={
									windowWidth > 650
										
											? 210
											: 100
									
								}
							/>
						</Box>
					</Box>
				
			</Box>
		</>
	)
}
