import * as React from 'react'
import { Box } from '@gluestack-ui/themed'

import CapabilityTemplate from './CapabilityTemplate'

const UnclaimedCapability = ({ data, onPress }) => {
	return (
		<Box h={'100%'} zIndex={1}>
			<CapabilityTemplate
				capabilityText={data?.CapabilityConfig?.name}
				text={'Claim'}
				peersCount={
					data?.CapabilityConfig?.capabilitiesClaimed?.items?.length
				}
				textColor={'#E96025'}
				borderColor={'$secondary500'}
				bgColor={'$secondary100'}
				onPress={onPress}
			/>
		</Box>
	)
}

export default UnclaimedCapability
