import React from 'react'
import { Dimensions } from 'react-native'
import { useState, useEffect, useCallback } from 'react'
import { Box } from '@gluestack-ui/themed'
import { useNavigation } from '@react-navigation/native'
import { listCapabilitiesByProfileIdByDate } from '../../graphql/custom-queries'
import useUserStore from '../../Userstore/UserStore'
import { API } from 'aws-amplify'
import CapabilitiesRenderComponent from './CapabilitiesRenderComponent'
import CandidateCareerViewComponent from '../editCareer/CandidateCareerViewComponent'
import SelectCapabilitiesModal from './SelectCapabilitiesModal'

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

const NewKeyCapabilities = ({
	tabState,
	capCount,
	setShowModal,
	profile,
	setCapablities,
	screen,
}) => {
	const navigation = useNavigation()
	const [totalActive, setTotalActive] = useState(0)
	const loggedinProfile = useUserStore((state) => state.profile)
	const claimedCapabilities = useUserStore(
		(state) => state.claimedCapabilities
	)

	const setClaimedCapabilities = useUserStore(
		(state) => state.setClaimedCapabailities
	)

	const profileId = profile?.id
	const [loading, setLoading] = useState(true)
	const [Capablity, setCapablity] = useState([])

	const sortedCapabilities =
		claimedCapabilities &&
		claimedCapabilities?.sort((a, b) => {
			const aLength = a?.ConnectedHidis?.items?.length
			const bLength = b?.ConnectedHidis?.items?.length

			if (aLength < bLength) {
				return -1
			} else if (aLength > bLength) {
				return 1
			} else {
				if (aLength === 0) {
					return a?.CapabilityConfig?.createdAt.localeCompare(
						b?.CapabilityConfig?.createdAt
					)
				} else {
					return 0
				}
			}
		})

	const reverseCapabilities = [...sortedCapabilities].reverse()

	const KeyCapabilities = reverseCapabilities.slice(0, 5)
	const [modalShow, setModalShow] = useState(false)

	const showSelectCapabilities = () => {
		setModalShow(true)
	}
	const hideSelectCapabilities = () => {
		setModalShow(false)
	}

	const hideModal = () => {
		setShowModal(false)
	}

	const updateTotalActive = (props) => {
		const count = props.reduce(
			(acc, item) =>
				item.ConnectedHidis?.items?.length !== 0 ? acc + 1 : acc,
			0
		)
		setTotalActive(count)
		capCount(count)
	}

	const getCapability = useCallback(async () => {
		try {
		  const response = await API.graphql({
			query: listCapabilitiesByProfileIdByDate,
			authMode: 'API_KEY',
			variables: {
			  ProfileId: profile ? profileId : loggedinProfile?.id,
			},
		  });
	
		  const capabilities = response.data.listCapabilitiesByProfileIdByDate.items;
	
		  setCapablity(capabilities);
		  setClaimedCapabilities(capabilities);
		  updateTotalActive(capabilities);
		  setCapablities(capabilities);
		  setLoading(false);
		} catch (error) {
		  setLoading(false);
		  console.error(error);
		}
	  }, [profile, profileId, loggedinProfile]);

	useEffect(() => {
		getCapability()
	}, [profile])

	const CapabilitiesRender = ({ item, index }) => {
		return (
			<Box marginVertical={windowWidth > 480 ? 2 : 0.5}>
				<CapabilitiesRenderComponent
					item={item}
					setShowModal={setShowModal}
					navigation={navigation}
					profile={profile ? profile : loggedinProfile?.id}
					key={index}
					screen={screen}
				/>
			</Box>
		)
	}

	return (
		<>
			{claimedCapabilities ? (
				<Box
					bg="$primary0"
					marginTop={10}
					marginBottom={12}
					p={windowWidth > 480 ? 20 : 10}
					borderWidth={1}
					borderColor="$borderDark0"
					borderRadius={6}
					w={'100%'}
				>
					<CandidateCareerViewComponent
						title={
							tabState === 'cap'
								? screen === 'Journey'
									? ''
									: 'All Capabilities'
								: 'Key Capabilities'
						}
						data={
							tabState == 'cap'
								? reverseCapabilities
								: KeyCapabilities
						}
						renderItem={CapabilitiesRender}
						loading={loading}
						claimButton={'+ Claim More'}
						onPress={showSelectCapabilities}
						tabState={tabState}
						totalActive={totalActive}
						CapablityLength={claimedCapabilities?.length}
						screen={screen}
					/>
				</Box>
			) : null}

			<Box>
				<SelectCapabilitiesModal
					isOpen={modalShow}
					onClose={hideSelectCapabilities}
					setModalShow={setModalShow}
				/>
			</Box>
		</>
	)
}

export default NewKeyCapabilities
