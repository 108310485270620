import React from 'react'
import { Dimensions } from 'react-native'
import { Button, ButtonText } from '@gluestack-ui/themed'
import SpinnerComponent from './SpinnerComponent'

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

function LightButtonComponent({
	text,
	width,
	onPress,
	loading,
	marginVertical,
	disabled,
	color,
	height,
	textColor,
	paddingHorizontal,
	borderColor,
	spinnerColor,
	customFontSize,
}) {
	return (
		<Button
			size="xl"
			borderRadius={100}
			disabled={disabled}
			borderWidth={2}
			borderColor={
				disabled ? '$gray' : borderColor ? borderColor : '#F8F8F8'
			}
			gap={10}
			h={height ? height : windowWidth > 480 ? 40 : 35}
			justifyContent="center"
			alignItems="center"
			paddingHorizontal={paddingHorizontal ? paddingHorizontal : null}
			w={width}
			backgroundColor={disabled ? '$gray' : color ? color : '#F8F8F8'}
			marginVertical={marginVertical}
			// hardShadow="5"
			sx={{
				':hover': {
					bg: disabled ? null : color ? null : '#F8F8F8',
					borderColor: disabled ? null : color ? null : '#F8F8F8',
				},
				':active': {
					bg: disabled ? null : color ? null : '#F8F8F8',
					borderColor: disabled ? null : color ? null : '#F8F8F8',
				},
			}}
			onPress={onPress}
		>
			{loading ? (
				<SpinnerComponent
					color={
						color
							? spinnerColor
								? spinnerColor
								: '$primary500'
							: '#000000'
					}
					button={true}
				/>
			) : (
				<>
					<ButtonText
						fontSize={
							customFontSize
								? customFontSize
								: windowWidth > 480
								? '$fontContent'
								: '$fontContentMobile'
						}
						fontFamily={'$fontContent'}
						color={textColor ? textColor : '#000000'}
						letterSpacing={0.2}
						textAlign="center"
					>
						{text}
					</ButtonText>
				</>
			)}
		</Button>
	)
}

export default LightButtonComponent
