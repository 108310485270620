import React from 'react'
import { StyleSheet, Dimensions } from 'react-native'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import 'react-datepicker/dist/react-datepicker-cssmodules.css'
import { Box, HStack, Text, VStack } from '@gluestack-ui/themed'

const windowWidth = Dimensions.get('window').width
const CustomDatePicker = ({
	label,
	selectedDate,
	onDateChange,
	disabled,
	onFocus,
	onBlur,
}) => {
	return (
		<VStack marginTop={windowWidth > 480 ? 10 : 5} zIndex={10}>
			<HStack zIndex={-10}>
				<Text
					fontFamily="$fontContent"
					fontSize={
						windowWidth > 480
							? '$fontContent'
							: '$fontContentMobile'
					}
					fontWeight="800"
					color="$primary950"
					zIndex={-10}
				>
					{label}
				</Text>
				<Text color="$secondary500">*</Text>
			</HStack>
			<Box marginTop={windowWidth > 480 ? 5 : null}>
				<DatePicker
					showIcon
					style={styles.datepicker}
					onChange={(pickedDate) => onDateChange(pickedDate)}
					selected={selectedDate}
					disabled={disabled}
					onFocus={onFocus}
					onBlur={onBlur}
					showYearDropdown
					dateFormatCalendar="MMMM"
					yearDropdownItemNumber={30}
					showMonthDropdown
					scrollableYearDropdown
					dropdownMode="select"
					placeholderText="Select a date"
					required
				/>
			</Box>
		</VStack>
	)
}

const styles = StyleSheet.create({
	datepickerContainer: {},
	textstyle: {
		marginBottom: 4,
		fontSize: 12,
		fontWeight: 'bold',
		fontFamily: 'Lexend',
	},
	datepicker: {
		zIndex: 10,
	},
})

export default CustomDatePicker
