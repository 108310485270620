import {
	Modal,
	ModalBackdrop,
	ModalContent,
	ModalCloseButton,
	ModalBody,
	Heading,
	ModalFooter,
	Box,
	ModalHeader,
	Icon,
	Button,
	ButtonText,
	CloseIcon,
} from '@gluestack-ui/themed'
import React from 'react'
import { Dimensions } from 'react-native'
import MultipleSelectCapability from '../selectCapabilities/MultipleSelectCapability'
import SelectCapabilities from '../selectCapabilities/SelectCapabilities'
import SpinnerComponent from '../SpinnerComponent'
import { ToastContainer } from 'react-toastify'

const windowHeight = Dimensions.get('window').height
const windowWidth = Dimensions.get('window').width

const SelectCapabilitiesModal = ({ isOpen, onClose, setModalShow }) => {
	const loading = false

	return (
		<Box>
			<Modal
				isOpen={isOpen}
				onClose={onClose}
				position="fixed"
				maxHeight={windowHeight}
			>
				<ModalBackdrop />
				<ToastContainer />
				<ModalContent
					maxHeight={windowHeight * 0.9}
					minWidth={
						windowWidth > 480
							? windowWidth * 0.7
							: windowWidth * 0.9
					}
				>
					<ModalHeader>
						<Heading
							fontFamily="$fontHeading"
							fontSize={
								windowWidth > 480
									? '$fontHeading'
									: '$fontHeadingMobile'
							}
							lineHeight={15}
						>
							Claim your capabilities
						</Heading>
						<ModalCloseButton>
							<Icon
								as={CloseIcon}
								size={windowWidth > 480 ? 'md' : 'xs'}
							/>
						</ModalCloseButton>
					</ModalHeader>
					<ModalBody paddingVertical={0} bg="white">
						<SelectCapabilities
							screen={'profile'}
							showModal={setModalShow}
							feedState={true}
						/>
					</ModalBody>
				</ModalContent>
			</Modal>
		</Box>
	)
}

export default SelectCapabilitiesModal
