import { StyleSheet, Text, View, Dimensions, Image } from 'react-native'
import React from 'react'
import { HStack, ScrollView } from 'native-base'
const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height
export default function UserInsightScreen({ route, navigation }) {
	return (
		<View style={{ backgroundColor: '#fff', width: windowWidth }}>
			{windowWidth < 480 && (
				<View style={{}}>
					<Image
						style={styles.dummyimage4}
						resizeMode="contain"
						source={require('../assets/Images/summary.png')}
					/>
					<Image
						style={styles.dummyimage2}
						resizeMode="contain"
						source={require('../assets/Images/talentgraph.png')}
					/>
					<Image
						style={styles.dummyimage}
						resizeMode="contain"
						source={require('../assets/Images/mix.png')}
					/>
					{/* <Image style ={styles.dummyimage}
        resizeMode='contain'
        source={require("../assets/Images/Talents.png")}
        />
        <Image style ={styles.dummyimage}
        resizeMode='contain'
        source={require("../assets/Images/hidistat.png")}
        /> */}

					<Image
						style={styles.dummyimage3}
						resizeMode="contain"
						source={require('../assets/Images/analysis.png')}
					/>
				</View>
			)}
			{windowWidth >= 480 && (
				<View style={{ width: windowWidth * 0.8, alignSelf: 'center' }}>
					<View
						style={{
							flexDirection: 'row',
							alignSelf: 'center',
							marginTop: 15,
						}}
					>
						<Image
							style={styles.dummyimage6}
							resizeMode="contain"
							source={require('../assets/Images/summary.png')}
						/>
						<Image
							style={styles.dummyimage6}
							resizeMode="contain"
							source={require('../assets/Images/mix.png')}
						/>
					</View>
					<View
						style={{
							flexDirection: 'row',
							alignSelf: 'center',
							marginBottom: 10,
							marginTop: 10,
						}}
					>
						<Image
							style={styles.dummyimage7}
							resizeMode="contain"
							source={require('../assets/Images/talentgraph.png')}
						/>
						<Image
							style={styles.dummyimage7}
							resizeMode="contain"
							source={require('../assets/Images/analysis.png')}
						/>
					</View>
				</View>
			)}
		</View>
	)
}

const styles = StyleSheet.create({
	container: {
		marginTop: 50,
		backgroundColor: '#F5F8FC',
		width: windowWidth <= 480 ? windowWidth * 0.95 : windowWidth * 0.31,

		alignSelf: 'center',
		borderRadius: 15,
		padding: 20,
	},
	dummyimage: {
		width: windowWidth <= 480 ? windowWidth * 0.9 : windowWidth * 0.31,
		height: windowWidth <= 480 ? windowWidth * 0.64 : windowWidth * 0.1286,
		alignSelf: 'center',
		// marginTop: 10
	},
	dummyimage2: {
		width: windowWidth <= 480 ? windowWidth * 0.97 : windowWidth * 0.31,
		height: windowWidth <= 480 ? windowWidth * 0.95 : windowWidth * 0.33,
		alignSelf: 'center',
		// marginTop: 10
	},
	dummyimage3: {
		width: windowWidth <= 480 ? windowWidth * 0.95 : windowWidth * 0.31,
		height: windowWidth <= 480 ? windowWidth * 0.98 : windowWidth * 0.33,
		alignSelf: 'center',
		// padding: 10, marginLeft: 20
	},
	dummyimage4: {
		width: windowWidth <= 480 ? windowWidth * 0.88 : windowWidth * 0.31,
		height: windowWidth <= 480 ? windowWidth * 0.64 : windowWidth * 0.22,
		//   borderWidth: 1,
		alignSelf: 'center',
		//   marginTop: 10
	},
	dummyimage5: {
		width: windowWidth <= 480 ? windowWidth * 0.9 : windowWidth * 0.31,
		height: windowWidth <= 480 ? windowWidth * 0.64 : windowWidth * 0.22,
		//   borderWidth: 1,
		alignSelf: 'center',
		marginTop: -10,
	},
	dummyimage6: {
		width: windowWidth >= 480 ? windowWidth * 0.3 : windowWidth * 0.31,
		height: windowWidth >= 480 ? windowWidth * 0.16 : windowWidth * 0.22,
		//   borderWidth: 1,
		alignSelf: 'center',
		//   marginTop: 10
	},
	dummyimage7: {
		width: windowWidth >= 480 ? windowWidth * 0.3 : windowWidth * 0.31,
		height: windowWidth >= 480 ? windowWidth * 0.26 : windowWidth * 0.22,
		//   borderWidth: 1,
		alignSelf: 'center',
		//   marginTop: 10
	},
})
