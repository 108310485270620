import React from 'react'
import {
	Box,
	Pressable,
	Text,
	Tooltip,
	TooltipContent,
	TooltipText,
} from '@gluestack-ui/themed'
import { Dimensions } from 'react-native'

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

const ToolTipComponent = ({
	renderToolTip,
	isOpen,
	closeTooltip,
	tooltipText,
	backdrop,
}) => {
	return (
		<Box h={'100%'} position="relative">
			{backdrop && (
				<Pressable
					onPress={() => {
						closeTooltip()
					}}
					position="fixed"
					top={0}
					left={0}
					right={0}
					width={'100%'}
					height={'100%'}
					backgroundColor="rgba(0, 0, 0, 0.3)"
					overflow={'hidden'}
				/>
			)}
			<Tooltip
				placement={'top'}
				isOpen={isOpen}
				trigger={(triggerProps) => (
					<Box {...triggerProps} w={'100%'} h={'100%'} p={0}>
						{renderToolTip()}
					</Box>
				)}
			>
				<TooltipContent
					bg={'$blue500'}
					flex={1}
					py={10}
					px={20}
					zIndex={2}
					position={'relative'}
				>
					<TooltipText
						fontFamily="$fontHeading"
						color="white"
						fontWeight="600"
						fontSize={windowWidth > 480 ? 16 : 13}
					>
						{tooltipText}
					</TooltipText>
					<Box
						position={'absolute'}
						bg={'$blue500'}
						w={15}
						h={15}
						bottom={-3}
						left={10}
						zIndex={-1}
						transform={'rotate(45deg)'}
					/>
				</TooltipContent>
			</Tooltip>
		</Box>
	)
}

export default ToolTipComponent
