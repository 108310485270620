import { StyleSheet, Text, View } from 'react-native'
import React from 'react'

const Tag = ({ props }) => {
  return (
    // <View style ={styles.tagcontainer}>
    //   <Text style ={styles.textstyle}>{props}</Text>
    // </View>
    <View style={styles.tag}>
      <Text style={styles.tagText}>{props}</Text>
    </View>
  )
}

export default Tag

const styles = StyleSheet.create({
  tagcontainer: {
    borderWidth: 0.5,
    padding: 5,
    paddingHorizontal: 10,
    marginEnd: 5,
    borderRadius: 12,
    borderColor: "#11A75C",
    backgroundColor: "#F2FFF9",
    flexWrap: "wrap",
    maxWidth: "100%"
    // marginLeft :-3

  },
  textstyle: {
    fontFamily: "Open Sans",
    fontWeight: "300",
    color: "#11A75C",
    // flexWrap: "wrap",
    wrap: true
  },
  tag: {
    paddingVertical: 5,
    paddingHorizontal: 10,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 100,
    borderWidth: 1,
    borderColor: '#2BC48A',
    marginRight: 5,
    backgroundColor: '#F2FEF6',
  },
  tagText: {
    color: '#2BC48A',
    fontSize: 10,
  },
})