import {
	Box,
	Heading,
	Modal,
	ModalBackdrop,
	ModalCloseButton,
	ModalHeader,
	Icon,
	CloseIcon,
	ModalBody,
	ModalFooter,
	Button,
	ButtonText,
	Switch,
	Text,
	VStack,
	Center,
	ModalContent,
	Pressable,
	Divider,
} from '@gluestack-ui/themed'
import React from 'react'
import { Dimensions } from 'react-native'
import ButtonComponent from './ButtonComponent'
import { ToastContainer } from 'react-toastify'
const windowHeight = Dimensions.get('window').height
const windowWidth = Dimensions.get('window').width

const ModalComponent = ({
	heading,
	isOpen,
	onClose,
	renderBody,
	minWidth,
	showScroll,
	hideCloseButton,
	renderFooter,
	closeOnOverlayClick,
	subHeading,
	padding,
	maxHeight,
	height,
	background,
}) => {
	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			minHeight={windowHeight}
			position="fixed"
			closeOnOverlayClick={closeOnOverlayClick}
		>
			<ToastContainer />
			<ModalBackdrop />
			<ModalContent
				minWidth={minWidth ? minWidth : null}
				maxHeight={maxHeight ? maxHeight : windowHeight * 0.9}
				height={height ? height : null}
				bg={background ? background : null}
			>
				{heading && (
					<ModalHeader>
						<VStack
							flexWrap="wrap"
							w={windowWidth < 480 && windowWidth * 0.85}
						>
							<Text
								flex={1}
								fontWeight="600"
								fontFamily="$fontHeading"
								fontSize={windowWidth > 480 ? 16 : 12}
								lineHeight={windowWidth > 480 ? 20 : 15}
							>
								{heading}
							</Text>
							{subHeading && (
								<Text
									fontFamily="$fontHeading"
									fontSize={windowWidth > 480 ? 12 : 10}
									lineHeight={windowWidth > 480 ? 20 : 15}
								>
									{subHeading}
								</Text>
							)}
						</VStack>

						{!hideCloseButton && (
							<ModalCloseButton>
								<Icon as={CloseIcon} />
							</ModalCloseButton>
						)}
					</ModalHeader>
				)}
				<ModalBody
					showsHorizontalScrollIndicator={
						showScroll ? showScroll : true
					}
					p={padding}
				>
					{renderBody()}
				</ModalBody>

				{renderFooter && (
					<ModalFooter
						justifyContent={'flex-start'}
						w={windowWidth < 480 && windowWidth * 0.85}
					>
						{renderFooter()}
					</ModalFooter>
				)}
			</ModalContent>
		</Modal>
	)
}

export default ModalComponent
