import {
	View,
	Text,
	Image,
	Dimensions,
	StyleSheet,
	ActivityIndicator,
	TouchableOpacity,
	FlatList,
} from 'react-native'
import React from 'react'
import { useEffect, useState, Fla } from 'react'
import { Button } from 'native-base'
const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height
import { listNewprofiles } from '../../graphql/queries'
import { Drawer, MobileHeader } from '../../newComponents/header/Header'
import { DekstopHeader } from '../../newComponents/header/Header'
import { API, Auth, graphqlOperation } from 'aws-amplify'
import SpinnerComponent from '../../newComponents/SpinnerComponent'
import { list_JobApplicants_by_Level_sortbyCompanyprefernceId } from '../../graphql/custom-queries'
import CandidateComponent from '../../components/ApplicantCandidateComponent'
import Divider from '../../FeedComponents/Divider'
import { Box } from '@gluestack-ui/themed'
import CandidateViewComponent from './components/CandidateViewComponent'
export default function Level4({
	navigation,
	preferenceId,
	rolename,
	companyName,
	JobPostData,
}) {
	const [JobApplicantslist, setJobApplicantslist] = useState('')
	const [loading, setloading] = useState(true)
	const [nextToken, setNextToken] = useState(null)

	async function getJobApplications(token = null) {
		try {
			const response = await API.graphql({
				query: list_JobApplicants_by_Level_sortbyCompanyprefernceId,
				variables: {
					Level: 4,
					CompanyPreferenceId: { eq: preferenceId },
					limit: 25,
					nextToken: token,
				},
			})

			const newData =
				response.data
					.list_JobApplicants_by_Level_sortbyCompanyprefernceId.items
			setNextToken(
				response.data
					.list_JobApplicants_by_Level_sortbyCompanyprefernceId
					.nextToken
			)

			setJobApplicantslist([...JobApplicantslist, ...newData])
			setloading(false)
		} catch (error) {
			console.log(error)
			setloading(false)
		}
	}

	useEffect(() => {
		getJobApplications()
	}, [])

	const fetchMoreData = () => {
		if (nextToken) {
			getJobApplications(nextToken)
		}
	}

	return (
		<Box>
			{!loading ? (
				<CandidateViewComponent
					loading={loading}
					headingText={'Applicants'}
					companyname={companyName}
					onPressBtn={null}
					navigation={navigation}
					data={JobApplicantslist}
					rolename={rolename}
					fetchMoreData={fetchMoreData}
					PreferenceId={preferenceId}
					listEmptyText={'No applicants yet!'}
					JobPostData={JobPostData}
					prefferedSalary={JobPostData?.CompanyPreference?.package}
					minExperience={
						JobPostData?.CompanyPreference?.minExperienceRequired
					}
					maxExperience={
						JobPostData?.CompanyPreference?.maxExperienceRequired
					}
				/>
			) : (
				<SpinnerComponent />
			)}
		</Box>
	)
}
