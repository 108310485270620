import React from 'react'
import {
	View,
	Text,
	Image,
	TouchableOpacity,
	StyleSheet,
	FlatList,
	ActivityIndicator,
	Dimensions,
	Modal,
} from 'react-native'
import { Divider, HStack } from 'native-base'
import EditEducation from './EditEducation'
import { useState, useEffect, useRef } from 'react'
import useUserStore from '../../../Userstore/UserStore'
import {
	createCandidateEducation,
	deleteCandidateEducation,
	updateCandidateEducation,
} from '../../../graphql/mutations'
import { API } from 'aws-amplify'
import { listCandidateEducationByUserIdByDate } from '../../../graphql/queries'
import { ResumeParser } from '../../../graphql/queries'
import { showToast } from '../../../components/ErrorToast'
import CandidateCareerRenderComponent from '../CandidateCareerRenderComponent'
import { Box, Center } from '@gluestack-ui/themed'
import CandidateCareerViewComponent from '../CandidateCareerViewComponent'
const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height
const EducationDetails = ({ ref, userId, screen }) => {
	const userIdPrivate = useUserStore((state) => state.userId)
	const [showModal, setShowModal] = useState(false)
	const [DATA, setDATA] = useState([])
	const [loading, setLoading] = useState(true)
	const [arrangedData, setArrangedData] = useState([])
	const typeofuser = useUserStore((state) => state.group)
	const [viewHeight, setViewHeight] = useState('')
	const [fields, setFields] = useState({
		id: '',
		degree: '',
		// fieldOfStudy: '',
		institute: '',
		percentage: '',
		StartDate: '',
		EndDate: '',
		isPresent: false,
	})

	const addEducation = async (newData) => {
		if (!newData.id) {
			const updatedData = {
				degree: newData.degree,
				institute: newData.institute,
				percentage: newData.percentage,
				EndDate: newData.EndDate,
				startDate: newData.StartDate,
				Present: newData.isPresent,
				UserId: userId ? userId : userIdPrivate,
			}

			try {
				const result = await API.graphql({
					query: createCandidateEducation,
					authMode: 'AMAZON_COGNITO_USER_POOLS',
					variables: {
						input: updatedData,
					},
				})
				const createdEducation = result.data.createCandidateEducation
				setDATA([...DATA, createdEducation])
			} catch (error) {
				console.error('Error creating education details:', error)
			}
		} else {
			const updatedData = {
				id: newData.id,
				degree: newData.degree,
				institute: newData.institute,
				percentage: newData.percentage,
				EndDate: newData.EndDate,
				startDate: newData.StartDate,
				Present: newData.isPresent,
				UserId: userId ? userId : userIdPrivate,
			}
			try {
				const result = await API.graphql({
					query: updateCandidateEducation,
					authMode: 'AMAZON_COGNITO_USER_POOLS',
					variables: {
						input: updatedData,
					},
				})
				const updatedEducation = result.data.updateCandidateEducation
				UpdateDataObject(updatedEducation)
			} catch (error) {
				console.error('Error creating education details:', error)
			}
		}
	}

	const UpdateDataObject = (updatedObject) => {
		const RemainingData = DATA.filter((obj) => obj.id !== updatedObject.id)
		setDATA([...RemainingData, updatedObject])
	}

	const handleDelete = async (itemId) => {
		try {
			const updatedData = DATA.filter((item) => item.id !== itemId)
			setDATA(updatedData)

			await API.graphql({
				query: deleteCandidateEducation,
				authMode: 'AMAZON_COGNITO_USER_POOLS',
				variables: {
					input: { id: itemId },
				},
			})
		} catch (error) {
			console.error('Error deleting experience details:', error)
		}
	}

	useEffect(() => {
		getEducationData()
	}, [userId, userIdPrivate])

	// useEffect(() => {
	// 	setArrangedData([...DATA].reverse())
	// }, [DATA])

	useEffect(() => {
		const sortedData = [...DATA].sort((a, b) => {
			const startDateA = a.startDate ? new Date(a.startDate) : new Date()
			const startDateB = b.startDate ? new Date(b.startDate) : new Date()
			const endDateA = a.EndDate ? new Date(a.EndDate) : new Date()
			const endDateB = b.EndDate ? new Date(b.EndDate) : new Date()

			if (a.Present && !b.Present) {
				return -1
			}
			if (!a.Present && b.Present) {
				return 1
			}

			if (!a.EndDate && b.EndDate) {
				return -1
			}
			if (a.EndDate && !b.EndDate) {
				return 1
			}

			return (
				endDateB.getTime() - endDateA.getTime() ||
				startDateB.getTime() - startDateA.getTime()
			)
		})

		setArrangedData(sortedData)
	}, [DATA])
	const onMainViewLayout = (event) => {
		const { height } = event.nativeEvent.layout
		setViewHeight(height)
	}

	async function getEducationData() {
		try {
			const response = await API.graphql({
				query: listCandidateEducationByUserIdByDate,
				authMode: 'API_KEY',
				variables: {
					UserId: userId ? userId : userIdPrivate,
				},
			})
			const educationData =
				response.data.listCandidateEducationByUserIdByDate.items
				if(educationData.length == 0 && typeofuser == 'QrusibleSuperAdmin'){
					ResumeParserFunction()
				}
			setDATA(educationData)
			setLoading(false)
		} catch (error) {} 
	}

	const toastShow = () => {
		showToast({ message: 'Please fill the all the necessary fields.' })
	}
	const ResumeParserFunction = async () => {
		try {
			await API.graphql({
				query: ResumeParser,
				variables: {
					msg: userId ? userId : userIdPrivate,
				},
			}).then((response) => {})
			return
		} catch (error) {
			return
		}
	}
	const renderItemMethod = ({ item }) => {
		const startDate = item.startDate ? new Date(item.startDate) : ''
		const endDate = item?.EndDate ? new Date(item.EndDate) : ''

		const formattedStartDate = startDate.toLocaleString('en-US', {
			month: 'short',
			year: 'numeric',
		})

		const formattedEndDate = endDate.toLocaleString('en-US', {
			month: 'short',
			year: 'numeric',
		})

		let logoUrl =
			'https://logo.clearbit.com/' +
			item?.institute?.split(' ')[0].trim() +
			'.ac.in'

		return (
			<Box w={'100%'} marginVertical={10}>
				<CandidateCareerRenderComponent
					defaultImgSource={require('../../../assets/Images/education.png')}
					imgSource={logoUrl}
					title={item?.degree}
					institution={item?.institute}
					startDate={item?.startDate ? formattedStartDate : null}
					endDate={item?.EndDate ? formattedEndDate : null}
					present={item?.Present}
					duration={item?.duration}
					grades={item?.percentage}
					onEdit={() => {
						setFields({
							id: item.id,
							degree: item.degree,
							institute: item.institute,
							percentage: item.percentage,
							StartDate: item.startDate
								? new Date(item.startDate)
								: '',
							EndDate: item?.EndDate
								? new Date(item.EndDate)
								: '',
							isPresent: item?.Present,
						})
						setShowModal(true)
					}}
					onDelete={() => {
						handleDelete(item.id)
					}}
					screen={screen}
				/>
			</Box>
		)
	}

	return (
		<>
			{screen == 'public' && DATA.length == 0 ? (
				<></>
			) : (
				<Box
					id="main-view"
					onLayout={onMainViewLayout}
					bg="$primary0"
					marginBottom={12}
					p={windowWidth > 480 ? 20 : 10}
					borderWidth={1}
					borderColor="$borderDark0"
					borderRadius={6}
				>
					<CandidateCareerViewComponent
						data={arrangedData}
						renderItem={renderItemMethod}
						loading={loading}
						title={'Education'}
						onPress={() => {
							setShowModal(true)
						}}
						ref={ref}
						description={'No education details added'}
						buttonText={'Add Education Details'}
						addMoreImage={require('../../../assets/Images/addedu.png')}
						screen={screen}
					/>
					<Center>
						<EditEducation
							onSave={addEducation}
							fields={fields}
							setShowModal={setShowModal}
							setFields={setFields}
							toast={toastShow}
							ref={ref}
							modalVisibility={showModal}
						/>
					</Center>
				</Box>
			)}
		</>
	)
}

export default EducationDetails
