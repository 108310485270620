import React, { useState } from 'react';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';

const CompanyDetailComponent = ({ company, onEdit, onDelete }) => {
    const [expanded, setExpanded] = useState(false);
    const aboutText = company.about || '';

    return (
        <View style={styles.container}>
            <View style={styles.content}>
                <Text style={styles.name}>{company.name}</Text>
                <View style={styles.rightContent}>
                    <Text style={styles.about} numberOfLines={expanded ? undefined : 1}>
                        {aboutText}
                    </Text>
                    {aboutText.length > 50 && (
                        <TouchableOpacity onPress={() => setExpanded(!expanded)} style={styles.readMoreButton}>
                            <Text style={styles.readMoreText}>
                                {expanded ? 'Less' : 'More'}
                            </Text>
                        </TouchableOpacity>
                    )}
                    <Text style={styles.createdAt}>
                        📅 {new Date(company.createdAt).toLocaleDateString()}
                    </Text>
                </View>
            </View>
            <TouchableOpacity onPress={() => onDelete(company.id)} style={styles.deleteButton}>
                <Text style={styles.deleteIcon}>🗑️</Text>
            </TouchableOpacity>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 16,
        backgroundColor: '#FFFFFF',
        borderBottomWidth: 1,
        borderBottomColor: '#E0E0E0',
    },
    content: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    name: {
        fontSize: 18,
        fontWeight: '600',
        color: '#1C1C1E',
        flex: 1,
    },
    rightContent: {
        flex: 2,
        alignItems: 'flex-end',
    },
    about: {
        fontSize: 14,
        color: '#3A3A3C',
        textAlign: 'right',
    },
    readMoreButton: {
        marginTop: 4,
    },
    readMoreText: {
        color: '#007AFF',
        fontSize: 12,
        fontWeight: '500',
    },
    createdAt: {
        fontSize: 12,
        color: '#8E8E93',
        marginTop: 4,
    },
    deleteButton: {
        padding: 8,
        marginLeft: 8,
    },
    deleteIcon: {
        fontSize: 18,
    },
});

export default CompanyDetailComponent;