import { Box, FlatList, HStack, Image, Text } from '@gluestack-ui/themed'
import React from 'react'
import TopBoxJD from './TopBoxJD'
import ProgressBar from '../../../newComponents/ProgressBar'
import ButtonComponent from '../../../newComponents/ButtonComponent'
import ImproveApplication from './ImproveApplication'
import { Dimensions } from 'react-native'
import AddProfilePic from './AddProfilePic'
import AddVideo from './AddVideo'
import useUserStore from '../../../Userstore/UserStore'
import QuestionsAsked from './QuestionsAsked'
import AccordionComponent from '../../../newComponents/AccordionComponent'
import CompanyPrefs from './CompanyPrefs'
import AboutCompany from '../AboutCompany'
const windowWidth = Dimensions.get('window').width

const AppliedScreenProcessB = ({
	jobPost,
	aboutCurrentJob,
	applicationProgress,
	setSwitchAppliedView,
	navigation,
	macthedHidis,
	companyName,
}) => {
    const jobPostByID = jobPost
	const profile = useUserStore((state) => state.profile)
	const currentJob =
		aboutCurrentJob?.data
			?.list_JobApplications_by_ProfileId_filter_byJobPostId?.items[0]

	let logoUrl =
		'https://logo.clearbit.com/' +
		jobPost?.CompanyPreference?.companyprofile?.name?.split(' ')[0].trim() +
		'.com'

	const formatDate = (dateString) => {
		if (!dateString) return 'No date provided'

		const date = new Date(dateString)
		return new Intl.DateTimeFormat('en-US', {
			month: 'short',
			day: 'numeric',
			year: 'numeric',
		}).format(date)
	}
    const AboutSection = () => {
		return (
			<Box>
				<Box>
					<CompanyPrefs
						companyPreferences={jobPostByID?.CompanyPreference}
					/>
				</Box>
				{jobPostByID?.CompanyDescription ||
				jobPostByID?.description ||
				jobPostByID?.title ||
				jobPostByID?.CompanyPreference?.modeofwork ? (
					<Box>
						<AboutCompany
							externalLocation={
								jobPostByID?.CompanyPreferences?.Location
									? jobPostByID?.CompanyPreferences?.Location
									: jobPostByID?.Location
							}
							externalAbout={jobPostByID?.htmldescription}
							shortDesc={jobPostByID?.ShortDesc}
							skills={jobPostByID?.skills}
							experienceRequired={jobPostByID?.experienceRequired}
							companyName={
								jobPostByID?.CompanyPreference?.companyprofile
									?.name
							}
							companyDescription={
								jobPostByID?.description
									? jobPostByID?.description
									: jobPostByID?.CompanyPreference
											?.companyprofile?.about
							}
							opportunity={jobPostByID?.RolesandResponsibility}
							jobType={jobPostByID?.CompanyPreference?.JobType}
							location={jobPostByID?.CompanyPreference?.Location}
							modeOfWork={
								jobPostByID?.CompanyPreference?.modeofwork
							}
							postedOn={jobPostByID?.createdAt?.split('T')[0]}
						/>
					</Box>
				) : null}
			</Box>
		)
	}
	const ApplicationStatusData = [
		{
			id: 1,
			title: 'Recieved',
			time: formatDate(currentJob?.createdAt),
			imageSource: require('../../../assets/Images/applicationRecieved.png'),
		},
		{
			id: 2,
			title: 'Reviewed',
			time: null,
			imageSource: null,
		},
	].filter((item) => item.time !== null)

	const renderStatus = ({ item }) => {
		return (
			<HStack px={6} py={10} gap={10} my={10}>
				<Box
					bg="white"
					h={25}
					alignItems="center"
					justifyContent="center"
				>
					<Image source={item.imageSource} h={20} w={20} />
				</Box>
				<Box>
					<Text
						fontFamily="$fontHeading"
						fontSize={14}
						color="$primary950"
						fontWeight="600"
					>
						{item?.title}
					</Text>
					<Text
						fontFamily="$fontHeading"
						fontSize={14}
						color="#4A789C"
					>
						{item?.time}
					</Text>
				</Box>
			</HStack>
		)
	}

	return (
		<Box w={'100%'}>
			<TopBoxJD
				jobTitle={jobPost?.CompanyPreference?.rolename}
				onPressTtitle={() => {
					if (jobPost?.jobPostLink) {
						window.open(jobPost?.jobPostLink)
					}
				}}
				companyName={jobPost?.CompanyPreference?.companyprofile?.name}
				jobType={jobPost?.CompanyPreference?.JobType}
				logoUrl={logoUrl}
				modeOfWork={jobPost?.CompanyPreference?.modeofwork}
			/>
			<Box gap={10} my={15}>
				{/* <HStack justifyContent="space-between">
					<Text
						fontFamily="$fontHeading"
						fontSize={14}
						color="$primary950"
						fontWeight="600"
					>
						Application Completion
					</Text>
					<Text fontFamily="$fontHeading" fontSize={14}>
						{applicationProgress}%
					</Text>
				</HStack> */}
				<Box flex={1}>
									<AccordionComponent
										title={'About'}
										renderItem={() => {
											return <AboutSection />
										}}
									/>
								</Box>
                                        
                                        <Box
 >
                                            <Image 
                                        alignSelf='center'
                                        w={windowWidth*0.25}
                                        h = {windowWidth*0.320}
                                        source={require('../../../assets/Images/JobApplicationCompleteImage.jpg')}
                                        />
                                        <Text 
                                fontFamily="$fontHeading"
                                fontWeight="900"
                                fontSize={windowWidth > 480 ? 22 : 16}
                                color={'$primary400'}
                            
                               textAlign='center'
                                lineHeight={windowWidth > 480 ? 30 : 20}
                                >
                                Congratulations! 
			 
                                </Text>
                                <Text 
                                fontFamily="$fontHeading"
                                fontWeight="900"
                                fontSize={windowWidth > 480 ? 22 : 16}
                                color={'$primary950'}
                                textAlign='center'
                              
                           
                                lineHeight={windowWidth > 480 ? 30 : 20}
                                >
                                Your job application has been successfully received.
			 
                                </Text>
                                <Text 
                                fontFamily="$fontHeading"
                                fontWeight="900"
                                fontSize={windowWidth > 480 ? 22 : 16}
                                color={'$primary950'}
                                textAlign='center'
                               
                             
                                lineHeight={windowWidth > 480 ? 30 : 20}
                                >
                                 Our team will be in touch with you shortly.
			 
                                </Text>
                                        </Box>
                                
				
			</Box>
		
			
			 
		</Box>
	)
}

export default AppliedScreenProcessB
