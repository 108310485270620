import {
	Box,
	Text,
	Pressable,
	VStack,
	HStack,
	Image,
} from '@gluestack-ui/themed'
import React from 'react'
import { Dimensions } from 'react-native'

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

const CapabilitiesRenderComponent = ({
	setShowModal,
	item,
	profile,
	navigation,
	disable,
	screen,
}) => {
	const renderOnPress = () => {
		try {
			setShowModal(false)

			if (!disable) {
				if (item?.ConnectedHidis?.items?.length > 0) {
					navigation.push('CapabilityDetails', {
						capabilityItem: item,
						screen: screen,
					})
				} else if (
					screen !== 'public' &&
					item?.ConnectedHidis?.items?.length === 0
				) {
					navigation.push('CapCreateHidi', {
						capabilityName: item?.CapabilityConfig?.name,
						capabilityId: item?.id,
						profile: profile,
					})
				}
			}
		} catch (error) {
			console.error('Error in renderOnPress:', error)
		}
	}

	return (
		<Box marginVertical={8}>
			<Pressable
				onPress={
					disable ||
					(screen === 'public' &&
						item?.ConnectedHidis?.items?.length === 0)
						? null
						: renderOnPress
				}
			>
				<HStack alignItems="center" justifyContent="space-between">
					<VStack flex={1}>
						{item?.CapabilityConfig?.tag !== 'null' &&
						item?.CapabilityConfig?.tag !== 'none' ? (
							<Text
								paddingHorizontal={10}
								// paddingVertical={2}
								fontFamily="$fontContent"
								bg={
									item?.CapabilityConfig?.tag === 'popular'
										? '$secondary200'
										: item?.CapabilityConfig?.tag ===
										  'indemand'
										? '$primary200'
										: '$yellow200'
								}
								w={'fit-content'}
								color={
									item?.CapabilityConfig?.tag === 'popular'
										? '$secondary600'
										: item?.CapabilityConfig?.tag ===
										  'indemand'
										? '$primary600'
										: '$yellow600'
								}
								fontSize={
									windowWidth > 480
										? '$fontContentMobile'
										: 10
								}
								borderRadius={3}
							>
								{item?.CapabilityConfig?.tag}
							</Text>
						) : (
							<Box marginVertical={2}></Box>
						)}

						<Text
							fontFamily="$fontContent"
							fontSize={
								windowWidth > 480
									? '$fontContent'
									: '$fontContentMobile'
							}
							w={'fit-content'}
							fontWeight="500"
							color={
								item?.ConnectedHidis?.items?.length !== 0
									? '$primary950'
									: '#868686'
							}
						>
							{item?.CapabilityConfig?.name?.replace(
								/^"|"$/g,
								''
							)}
						</Text>
					</VStack>
					<Box>
						{item?.ConnectedHidis?.items?.length === 0 ? (
							<></>
						) : (
							<Box alignSelf="center">
								{screen === 'feed' ? null : item?.ConnectedHidis
										?.items[0]?.Hidi?.Verified ===
								  'verified' ? (
									<Box>
										<Image
											h={windowWidth > 480 ? 20 : 14}
											w={windowWidth > 480 ? 16 : 10}
											source={require('../../assets/Images/Vector.png')}
										/>
									</Box>
								) : item?.ConnectedHidis?.items[0]?.Hidi
										?.Verified === 'flag' ? (
									<Box>
										<Image
											h={windowWidth > 480 ? 20 : 14}
											w={windowWidth > 480 ? 16 : 10}
											source={require('../../assets/Images/underReview.png')}
										/>
									</Box>
								) : (
									<Box>
										<Image
											h={windowWidth > 480 ? 20 : 14}
											w={windowWidth > 480 ? 16 : 10}
											source={require('../../assets/Images/processingCap.png')}
										/>
									</Box>
								)}
							</Box>
						)}
					</Box>
				</HStack>
			</Pressable>
		</Box>
	)
}

export default CapabilitiesRenderComponent
