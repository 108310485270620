import { StyleSheet, Text, View, FlatList, Dimensions } from 'react-native'
import React from 'react'
import { useState } from 'react'
import { Input, VStack, CheckIcon, Checkbox } from 'native-base'
import Select from './Select'
const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height
const Dropdown = ({ userpreferences, setUserpreferences, property, data }) => {
	return (
		<VStack
			my={2}
			mx={2}
			ml={3}
			width={windowWidth > 480 ? 300 : '95%'}
			space={4}
		>
			<Select
				shadow={1}
				selectedValue={userpreferences[property]}
				minWidth="200"
				accessibilityLabel="Select"
				placeholder="Select"
				_selectedItem={{
					bg: 'teal.600',
					endIcon: <CheckIcon size="5" />,
				}}
				_light={{
					bg: 'coolGray.100',
					_hover: {
						bg: 'coolGray.200',
					},
					_focus: {
						bg: 'coolGray.200:alpha.70',
					},
				}}
				_dark={{
					bg: 'coolGray.800',
					_hover: {
						bg: 'coolGray.900',
					},
					_focus: {
						bg: 'coolGray.900:alpha.70',
					},
				}}
				onValueChange={(itemValue) =>
					setUserpreferences({
						...userpreferences,
						[property]: itemValue,
					})
				}
			>
				{data.map((option) => (
					<Select.Item
						key={option.value}
						label={option.label}
						value={option.value}
					/>
				))}
			</Select>
		</VStack>
	)
}

export default Dropdown
