import React, { useState } from 'react'
import { Dimensions } from 'react-native'
import EditCandidateCareerModal from '../EditCandidateCareerModal'
import { Box } from '@gluestack-ui/themed'

const windowWidth = Dimensions.get('window').width

const EditEducation = ({
	onSave,
	setShowModal,
	setFields,
	fields,
	toast,
	ref,
	modalVisibility,
}) => {
	const toggleSwitch = () =>
		setFields({ ...fields, isPresent: !fields.isPresent })
	const [loading, setLoading] = useState(false)

	const saveFields = async () => {
		setLoading(true)
		if (validateFields(fields)) {
			await onSave(fields)
			setLoading(false)
			setShowModal(false)
			setFields({
				id: '',
				degree: '',
				// fieldOfStudy: '',
				institute: '',
				percentage: '',
				StartDate: '',
				EndDate: '',
				isPresent: false,
			})
		} else {
			setLoading(false)
			toast()
		}
	}

	const closeModal = () => {
		setFields([
			{
				id: '',
				degree: '',
				// fieldOfStudy: '',
				institute: '',
				percentage: '',
				StartDate: '',
				EndDate: '',
				isPresent: false,
			},
		])
		setShowModal(false)
	}

	const validateFields = () => {
		if (
			fields.degree === '' ||
			fields.institute === '' ||
			fields.percentage === '' ||
			fields.StartDate === '' ||
			(!fields.isPresent && fields.EndDate === '')
		) {
			return false
		}
		return true
	}

	const setDegreeChange = (value) => setFields({ ...fields, degree: value })
	const setPercentageChange = (value) =>
		setFields({ ...fields, percentage: value })
	const setInstituteChange = (value) =>
		setFields({ ...fields, institute: value })

	const setStartDateChange = (pickedDate) =>
		setFields({ ...fields, StartDate: pickedDate })
	const setEndDateChange = (pickedDate) =>
		setFields({ ...fields, EndDate: pickedDate })

	return (
		<Box>
			<EditCandidateCareerModal
				heading={'Education'}
				isOpen={modalVisibility}
				onClose={closeModal}
				ref={ref}
				labels={['Level of Education', 'Obtained Score', 'Institution']}
				importants={[true, true, true]}
				placeholders={['B.Tech', '85%', 'JNU']}
				values={[fields?.degree, fields?.percentage, fields?.institute]}
				setValueChanges={[
					setDegreeChange,
					setPercentageChange,
					setInstituteChange,
				]}
				handleSave={saveFields}
				date={true}
				startDate={fields?.StartDate}
				onStartDateChange={setStartDateChange}
				endDate={fields?.EndDate}
				onEndDateChange={setEndDateChange}
				isPresent={fields?.isPresent}
				toggleSwitch={toggleSwitch}
				loading={loading}
			/>
		</Box>
	)
}

export default EditEducation
