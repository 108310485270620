import { API } from 'aws-amplify'
import { createEventTable } from '../graphql/mutations'

export const CreateEvents = async (input) => {
	try {
		const response = await API.graphql({
			query: createEventTable,
			authMode: 'API_KEY',
			variables: {
				input: input,
			},
		})
		return response.data.createEventTable
	} catch (error) {
		console.log('Error fetching initiatives', error)
	}
}

