import React from 'react'
import { Dimensions } from 'react-native'
import { Box, Divider, Text } from '@gluestack-ui/themed'

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

import { showPublicView } from '../HeplerFunctions/ShowPublicProfile'
import Hidiheader from '../components/Hidiheader'
import CapabilitiesRenderComponent from '../newComponents/keyCapabilities/CapabilitiesRenderComponent'

const CapabilityUnlock = ({ item, modalShow, setModalShow, navigation }) => {
	return (
		<Box
			w={'100%'}
			borderWidth={1}
			borderColor="$borderDark0"
			p={windowWidth > 480 ? 20 : 10}
			bg="$primary0"
			marginVertical={10}
		>
			<Box>
				<Text
					fontFamily="$fontContent"
					fontSize={windowWidth > 480 ? '$fontSize12' : 10}
					fontWeight="700"
				>
					Someone you might be interested in has claimed a new
					capability!
				</Text>
			</Box>
			<Divider marginVertical={10} h={0.5} />
			<Box>
				<Hidiheader
					props={
						item?.CapabilityConfig?.capabilitiesClaimed?.items[0]
							?.userProfile
					}
					screen={'feed'}
					onPress={() =>
						showPublicView(
							item.CapabilityConfig.capabilitiesClaimed.items[0]
								.userProfile?.UserId
						)
					}
					navigation={navigation}
				/>
			</Box>
			<Divider marginVertical={10} h={0.5} />
			<Box>
				<CapabilitiesRenderComponent
					item={item}
					setShowModal={setModalShow}
					navigation={navigation}
					screen={'feed'}
					disable={true}
				/>
			</Box>
		</Box>
	)
}

export default CapabilityUnlock
