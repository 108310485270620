import { StyleSheet, Text, View } from 'react-native'
import React from 'react'

export default function PeersProfileComponent() {
  return (
    <View>
      <Text>PeersProfileComponent</Text>
    </View>
  )
}

const styles = StyleSheet.create({})