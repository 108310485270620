import React from 'react'
import {
	Box,
	Text,
	SelectBackdrop,
	Select,
	SelectContent,
	SelectTrigger,
	SelectInput,
	SelectIcon,
	ChevronDownIcon,
	Icon,
	SelectPortal,
	SelectDragIndicatorWrapper,
	SelectDragIndicator,
	SelectItem,
	HStack,
} from '@gluestack-ui/themed'
import { Dimensions } from 'react-native'

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

const DropdownComponent = ({
	label,
	placeholder,
	property,
	userpreferences,
	setUserPreferences,
	data,
	important,
	disabled,
	type,
	newProfile,
	colorAccent,
	width,
}) => {
	return (
		<Box
			w={width}
			marginVertical={windowWidth > 480 ? (!newProfile ? 12 : 0) : 6}
		>
			<HStack>
				<Text
					fontFamily="$fontContent"
					fontSize={
						windowWidth > 480
							? '$fontContent'
							: '$fontContentMobile'
					}
					fontWeight="800"
					color="$primary950"
				>
					{label}
				</Text>
				{important && <Text color="$secondary500">*</Text>}
			</HStack>
			<Box
				marginTop={windowWidth > 480 ? (!newProfile ? 10 : 5) : 5}
				borderRadius={12}
			>
				<Select
					bg="#f7f7fc"
					selectedValue={
						userpreferences[property]?.length > 0
							? userpreferences[property]
							: userpreferences[null]
					}
					onValueChange={(itemValue) =>
						setUserPreferences({
							...userpreferences,
							[property]: itemValue,
						})
					}
					isDisabled={disabled}
					borderColor={colorAccent ? colorAccent : '$borderDark0'}
					borderWidth={colorAccent ? 1 : 1}
					borderRadius={12}
					h={43}
				>
					<SelectTrigger
						variant="outline"
						borderColor="#d1d1e8"
						borderRadius={12}
						h={43}
						size={windowWidth > 480 ? 'md' : 'sm'}
					>
						<SelectInput
							placeholder={placeholder}
							borderRadius={12}
							fontFamily="$fontContent"
							color={colorAccent ? colorAccent : '$primary950'}
							fontWeight={newProfile ? '600' : '500'}
							fontSize={
								windowWidth > 480 ? 12 : '$fontContentMobile'
							}
						/>
						<SelectIcon mr={windowWidth > 480 ? '$3' : '$2'}>
							<Icon as={ChevronDownIcon} />
						</SelectIcon>
					</SelectTrigger>
					<SelectPortal>
						<SelectBackdrop />
						<SelectContent>
							<SelectDragIndicatorWrapper>
								<SelectDragIndicator />
							</SelectDragIndicatorWrapper>
							{data.map((option) => (
								<SelectItem
									key={
										type === 'Location'
											? option.ascii_name
											: option.value
									}
									label={
										type === 'Location'
											? option.ascii_name
											: option.label
									}
									value={
										type === 'Location'
											? option.ascii_name
											: option.value
									}
								/>
							))}
						</SelectContent>
					</SelectPortal>
				</Select>
			</Box>
		</Box>
	)
}

export default DropdownComponent
