import React from 'react'
import { Dimensions } from 'react-native'
import { Box, HStack, Image, Pressable, DownloadIcon, Icon, FlatList, Text, Divider } from '@gluestack-ui/themed'
import ExperienceLetterPdfComponent from './ExperienceLetterPdfComponent'
import { PDFDownloadLink } from '@react-pdf/renderer';
import { useState, useEffect } from 'react'
import useUserStore from '../../../Userstore/UserStore'
import Profile from '../../profileScreen/ProfileScreen';



const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

const QrusibleWorkExperience = ({ ref, userId, screen, data }) => {
	const Data = {
		name: "John Doe",
		address: "123 Main St, Anytown, USA",
		clientName: "XYZ Corporation",
		designation: "Software Developer",
		startDate: "January 1, 2023",
		endDate: "December 31, 2023",
		userId: "12345"
	  };
	  
	const renderItemMethod = ({ item }) => {

		
		const startDate = new Date(item.joiningDate).toLocaleDateString('en-US', {
			day: '2-digit',
			month: 'short',
			year: 'numeric'
		  });
		
		const endDate = new Date(item?.ContractEndingDate).toLocaleDateString('en-US', {
			day: '2-digit',
			month: 'short',
			year: 'numeric'
		  });
		  
		const formattedStartDate = startDate.toLocaleString('en-US', {
			month: 'short',
			year: 'numeric',
		})

		const formattedEndDate = endDate.toLocaleString('en-US', {
			month: 'short',
			year: 'numeric',
		})

		let logoUrl =
			'https://logo.clearbit.com/' +
			item.JobPostPreferences?.companyprofile?.name?.split(' ')[0].trim() +
			'.ac.in'

		return (
<Box
			bg="white"
			my={20}
			borderWidth={1}
			borderColor="$borderDark0"
			p={20}
			
		>
			
				<Text
					fontFamily="$fontHeading"
					fontWeight="600"
					fontSize={windowWidth > 480 ? 20 : 15}
					color="$primary950"
				>
					Qrusible Work Experience
				</Text>
				<Divider marginVertical={10} width={'100%'} h={0.5}/>
			<HStack w={'100%'} flex={1}>
			<Box
				borderWidth={1}
				padding={10}
				alignItems="center"
				justifyContent="center"
				w={windowWidth > 480 ? 50 : 40}
				h={windowWidth > 480 ? 50 : 40}
				borderRadius={6}
				borderColor="$borderDark0"
			>
				<Image
					h={windowWidth > 480 ? 30 : 20}
					w={windowWidth > 480 ? 30 : 20}
					source={{ uri: logoUrl }}
					defaultSource={ require('../../../assets/Images/experience.png')}
				/>
			</Box>
				
				<Box flex={1} paddingHorizontal={windowWidth > 480 ? 10 : 5}>
					{item.JobPostPreferences?.rolename ? (
						<Text
							fontFamily="$fontContent"
							fontSize={
								windowWidth > 480
									? '$fontContent'
									: '$fontContentMobile'
							}
							fontWeight="700"
							color="$primary950"
						>
							{item.JobPostPreferences?.rolename}
						</Text>
					) : null}
					{item.JobPostPreferences?.companyprofile?.name ? (
						<Text
							fontFamily="$fontContent"
							fontSize={windowWidth > 480 ? '$fontSize12' : 10}
							fontWeight="500"
							color="$primary950"
						>
							{item.JobPostPreferences?.companyprofile?.name}
						</Text>
					) : null}
				<HStack>
				<Text
							fontFamily="$fontContent"
							fontSize={windowWidth > 480 ? '$fontSize12' : 10}
							fontWeight="500"
							color="$primary950"
						>
							{startDate} -{' '}
						</Text>
						{item?.ContractEndingDate ? (<Text
							fontFamily="$fontContent"
							fontSize={windowWidth > 480 ? '$fontSize12' : 10}
							fontWeight="500"
							color="$primary950"
						>
							{endDate}
						</Text> ) : ( <Text
							fontFamily="$fontContent"
							fontSize={windowWidth > 480 ? '$fontSize12' : 10}
							fontWeight="500"
							color="$primary950"
						>
							Present
						</Text>)}
						
				</HStack>
						
					

				</Box>
				{screen !== 'public' && (
					<Box
						flexDirection={windowWidth > 480 ? 'row' : 'column'}
						paddingVertical={2}
						gap={20}
					>
						{item?.ContractEndingDate && screen == 'private' &&
							<PDFDownloadLink document={<ExperienceLetterPdfComponent profiledata={data} qrewdata={item}/>} fileName='ProofofWork.pdf'>
						<Pressable h={'fit-content'}>
							<Icon
								size={windowWidth > 480 ? 'sm' : 'xs'}
								as={DownloadIcon}
								color={'$primary500'}
							/>
						</Pressable>
     </PDFDownloadLink>

						}
						

					</Box>
				)}
			</HStack>
			</Box>
		)
	}
	return (
		<Box>
			<FlatList
				renderItem={renderItemMethod}
				data={data.deployedforqrews?.items}
			/>
		</Box>
	)


}

export default QrusibleWorkExperience
