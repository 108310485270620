

	import React, { useState } from 'react'
import {
	AddIcon,
	Box,
	ChevronDownIcon,
	ChevronUpIcon,
	CloseIcon,
	Divider, // Import the ChevronUpIcon
	Pressable,
	Text,
	MenuIcon,
} from '@gluestack-ui/themed'
import { Motion, AnimatePresence } from '@legendapp/motion'
import { HStack } from 'native-base'

const QrewAccordionComponent = ({
	renderItem,
	renderTop,
	renderTitle,
	toggleActive,
	InitiativeData,
	renderHoverComponent
}) => {
	
	const [isOpen, setIsOpen] = useState(false)

	const toggleAccordion = () => {
		if (toggleActive) {
			setIsOpen(!isOpen)
		}
	}

	return (
		<Box flex={1} p={15} borderRadius={6} borderColor="#CFDEE8">
			{renderTitle()}
			<HStack>
			<HStack  mar width={120} bgColor={'#E1E1E1'} alignItems={'center'} alignContent = 'center' borderRadius={15} padding={1} justifyContent={'space-evenly'} marginLeft={120}>
			{InitiativeData?.length > 0 ?
			(<Text
				fontFamily="$fontHeading"
				fontSize={11}
				// lineHeight={60}
				alignSelf='center'
				fontWeight="800"
			color='black'>
				{InitiativeData.length} Initiative
			</Text>) :(<Text 
			fontFamily="$fontHeading"
			fontSize={11}
			// lineHeight={60}
			alignSelf='center'
			fontWeight="800"color='black'>
				{0} Initiative
				</Text>)
					
					}
			{/* {renderTop()} */}
			<Pressable
				
				onPress={toggleAccordion}
				flexDirection="row"
				justifyContent="space-between"
				alignItems="flex-start"
			>

				<Box
				
					borderRadius={200}
					// bg={isOpen ? '#F3F5F6' : ''}
				>
					
					
					{isOpen ? (
						<CloseIcon color={'black'} />
					) : (
						<MenuIcon color={'black'} />
					)}
				</Box>
				
				
			</Pressable>
			</HStack>
				{renderHoverComponent()}
			</HStack>
			
			

			

			<AnimatePresence >
				{isOpen && (
					<Motion.View
						initial={{ opacity: 0, y: 0 }}
						animate={{ opacity: 1, y: 7 }}
						exit={{ opacity: 0, y: 0 }}
						style={{ overflow: 'hidden', padding: 3 }}
					>
						{/* <Divider h={0} mb={10} mt={17} /> */}
						{renderItem()}
					</Motion.View>
				)}
			</AnimatePresence>
		</Box>
	)
}

export default QrewAccordionComponent
