import React from 'react';
import { Text, View, Page, Document, StyleSheet , Image} from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    padding: 50,
    backgroundColor: '#FFFFFF',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 20,
  },
  divider: {
    width: '100%',
    borderBottom: 1,
    borderColor: '#000000',
    marginBottom: 20,
  },
  content: {
    fontSize: 12,
    lineHeight: 1.5,
    textAlign: 'center',
    marginBottom: 10,
  },
  name: {
    fontSize: 16,
    fontWeight: 'bold',
    marginVertical: 10,
  },
  footer: {
    fontSize: 10,
    marginTop: 30,
  },
  signature: {
    width: 100,
    height: 60,
    alignSelf: 'center',
    marginTop: 20,
  },
  logoContainer: {
    backgroundColor: '#E16449',
    padding: 10,
    marginBottom: 20,
    width: '100%',
  },
  logo: {
    width: 100,
    height: 30,
    alignSelf: 'center',
  },
  footer: {
    fontSize: 10,
    marginTop: 30,
  }
});

export default function ExperienceLetterPdfComponent({ profiledata, qrewdata }) {
  const Startdate = new Date(qrewdata?.joiningDate).toLocaleDateString('en-US', {
    day: '2-digit',
    month: 'short',
    year: 'numeric'
  });
  const Enddate = new Date(qrewdata?.ContractEndingDate).toLocaleDateString('en-US', {
    day: '2-digit',
    month: 'short',
    year: 'numeric'
  });
  return (
    <Document>
      <Page size="A4" style={styles.page}>
      <View style={styles.logoContainer}>
          <Image style={styles.logo} src={require('../../../assets/Images/qrusibleOrange.png')} />
        </View>
      
        <Text style={styles.title}>Proof Of Work</Text>
        
        <View style={styles.divider} />
        
        <Text style={styles.content}>This is to certify that</Text>
        
        <Text style={styles.name}>{profiledata?.name}</Text>
        
        <Text style={styles.content}>
          has successfully completed their contracted engagement with {qrewdata?.JobPostPreferences.companyprofile.name} as a {qrewdata?.JobPostPreferences.rolename}
         {' '} from {Startdate} to {Enddate} through the Qrusible Talent Network.
        </Text>
        
        {/* <View style={styles?.divider} /> */}
        
        {/* <Text style={styles?.content}>
          We acknowledge and appreciate the expertise and commitment displayed by {profiledata?.name}{' '}
          during their tenure and wish them continued success in their future endeavours.
        </Text> */}
        
        <Text style={styles?.footer}>
            Profile Link: app.qrusible.com/Q-profile/{profiledata?.UserId}
        </Text>
        <Image style={styles?.signature} src={require('../../../assets/Images/signatureharish.png')} />
        <Text style={styles?.footer}>
          Harish Krishnan
        </Text>
      </Page>
    </Document>
  );
}