import React from 'react'
import { Dimensions } from 'react-native'
import {
	AlertCircleIcon,
	Badge,
	BadgeIcon,
	BadgeText,
	Box,
	CheckIcon,
	Tooltip,
	TooltipContent,
	TooltipText,
} from '@gluestack-ui/themed'
const windowWidth = Dimensions.get('window').width

const AiBadge = ({ isGptGenerated, paddingHorizontal }) => {
	return (
		<Box
			mb={2}
			mt={5}
			paddingHorizontal={paddingHorizontal ? paddingHorizontal : 0}
			alignSelf="flex-start"
		>
			<Badge
				size="md"
				variant="outline"
				borderRadius="$3xl"
				action={isGptGenerated ? 'warning' : 'success'}
				alignItems="center"
			>
				<BadgeIcon
					as={isGptGenerated ? AlertCircleIcon : CheckIcon}
					mr="$2"
				/>
				<BadgeText
					fontSize={windowWidth > 480 ? 12 : 10}
					fontWeight="500"
					fontFamily="$fontHeading"
				>
					{isGptGenerated ? 'AI Detected' : 'Handcrafted'}
				</BadgeText>
			</Badge>
		</Box>
	)
}

export default AiBadge
