import {
	Box,
	Avatar,
	AvatarFallbackText,
	AvatarImage,
} from '@gluestack-ui/themed'
import { Dimensions } from 'react-native'
import React from 'react'

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

const AvatarComponent = ({ name, height, width, imgSource, imgPath }) => {
	return (
		name ? <Avatar
			bgColor="$primary950"
			h={height ? height : windowWidth > 480 ? 100 : 70}
			w={width ? width : windowWidth > 480 ? 100 : 70}
		>
			<AvatarFallbackText
				fontSize={15}
				fontFamily="$fontContent"
				color="$primary950"
				fontWeight="700"
			>
				{name}
			</AvatarFallbackText>
			<AvatarImage
				source={imgSource ? { uri: imgSource } : { imgPath }}
				alt="profile image"
			/>
		</Avatar>  : <Avatar
			h={height ? height : windowWidth > 480 ? 100 : 70}
			w={width ? width : windowWidth > 480 ? 100 : 70}
		>
			<AvatarImage
				source={{uri:"https://ionicframework.com/docs/img/demos/avatar.svg"}}
				alt="profile image"
			/>
		</Avatar>
)
}

export default AvatarComponent
