import { Dimensions } from 'react-native'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { list_globalcapbilities_by_ActivityStatus_filer_by_domain } from '../../graphql/custom-queries'
// import { createCapabilities } from '../../graphql/mutations'
import { createCapabilities } from '../../graphql/custom-mutations'
import { API } from 'aws-amplify'
import useUserStore from '../../Userstore/UserStore'
import MultipleSelectCapability from './MultipleSelectCapability'
import { showToast } from '../../components/ErrorToast'
import { Box, Divider, Heading, Text } from '@gluestack-ui/themed'
import SpinnerComponent from '../SpinnerComponent'
import ButtonComponent from '../ButtonComponent'
import CongratsOnboarding from '../pointsComponents/CongratsOnboarding'
import useGlobalCapabilityConfig from '../../customhook/GetGlobalCapability'

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height
export default function SelectCapabilities({
	handleGoBack,
	screen,
	showModal,
	feedState,
	navigation,
	searchQuery,
	setJobOnboardModal,
	inModal,
}) {
	const profileId = useUserStore((state) => state.profileId)
	const [status, setStatus] = useState('uncheck')
	const [capabilitySelected, setcapabilitySelected] = useState([])
	const [congratsModal, setCongratsModal] = useState(false)
	const [loading, setloading] = useState(true)

	const [loadingApI, setloadingApI] = useState(false)
	const setGlobalCapabilities = useUserStore(
		(state) => state.setGlobalCapabilities
	)
	const domain = useUserStore((state) => state.domain)

	const globalCapabilities = useUserStore((state) => state.GlobalCapabilities)
	const setClaimedCapabailities = useUserStore(
		(state) => state.setClaimedCapabailities
	)
	const selectedCapabilities = useUserStore(
		(state) => state.claimedCapabilities
	)

	const setLoadingCallback = (value) => {
		setloading(value)
	}

	useGlobalCapabilityConfig(setLoadingCallback)

	async function CreateCapability() {
		setloadingApI(true)
		capabilitySelected.map((item) => {
			const CreateUserInput = {
				ProfileId: profileId,
				capabilitiesCapabilityConfigId: item,
			}
			API.graphql({
				query: createCapabilities,
				variables: { input: CreateUserInput },
			})
				.then((res) => {
					setClaimedCapabailities(
						res?.data?.createCapabilities?.userProfile?.Capabilities
							?.items
					)
				})
				.catch((err) => {
					console.log(err)
				})
		})

		setloadingApI(false)
	}

	const onPressContinue = () => {
		if (feedState == true) {
			return
		} else if (screen === 'profile') {
			return
		} else {
			if (inModal) {
				setJobOnboardModal(false)
			} else {
				return navigation.push('Feed')
			}
		}
	}

	const OnpressClaimandProceed = () => {
		if (feedState == true) {
			CreateCapability()
			showToast({
				message: 'Capabilities claimed. Unlock them to get recognised.',
				type: 'success',
			})
			// setCongratsModal(true)
			onPressContinue()
		} else {
			CreateCapability()
			screen === 'profile' &&
				showToast({
					message:
						'Capabilities claimed. Unlock them to get recognised.',
					type: 'success',
				})
			// setCongratsModal(true)
			screen === 'profile' && showModal && showModal(false)
			onPressContinue()
		}
	}
	return (
		<Box
			bg="$primary0"
			w={'100%'}
			paddingVertical={
				screen !== 'profile' && windowWidth > 480 ? 20 : 10
			}
			paddingHorizontal={windowWidth > 480 ? 50 : 0}
			marginBottom={30}
			borderRadius={screen !== 'profile' && 6}
			borderWidth={screen !== 'profile' && 1}
			borderColor="$borderDark0"
		>
			{screen === 'profile' ? null : (
				<>
					<Box marginBottom={5}>
						<Heading
							fontFamily="$fontHeading"
							fontSize={
								windowWidth > 480
									? '$fontHeading'
									: '$fontHeadingMobile'
							}
						>
							Unlock{' '}
							<Text
								fontFamily="$fontHeading"
								fontSize={
									windowWidth > 480
										? '$fontHeading'
										: '$fontHeadingMobile'
								}
								fontWeight="700"
								color={'$primary500'}
							>
								opportunities
							</Text>{' '}
							by claiming{' '}
							<Text
								fontFamily="$fontHeading"
								fontSize={
									windowWidth > 480
										? '$fontHeading'
										: '$fontHeadingMobile'
								}
								fontWeight="700"
								color={'$primary500'}
							>
								capabilities
							</Text>
						</Heading>
						<Text
							fontFamily="$fontContent"
							fontSize={
								windowWidth > 480
									? '$fontContent'
									: '$fontContentMobile'
							}
							fontWeight="600"
							lineHeight={12}
							color="$secondary500"
						>
							*Select a min of 5 capabilities
						</Text>
					</Box>
					<Divider marginTop={10} bgColor="$borderDark0" h={0.5} />
				</>
			)}
			<Box>
				{loading ? (
					<Box marginTop={10}>
						<SpinnerComponent
							size={'large'}
							smallText={'Loading...'}
						/>
					</Box>
				) : (
					<Box>
						<MultipleSelectCapability
							capabilitySelected={capabilitySelected}
							setcapabilitySelected={setcapabilitySelected}
							data={globalCapabilities}
							selectedCapabilities={selectedCapabilities}
							searchQueryText={searchQuery}
						/>
					</Box>
				)}
			</Box>
			<Box
				flexDirection={windowWidth > 650 ? 'row' : 'column-reverse'}
				justifyContent={
					screen === 'profile' ? 'center' : 'space-between'
				}
				w={windowWidth > 480 ? '80%' : '95%'}
				alignSelf="center"
			>
				{screen === 'profile' ? null : (
					<ButtonComponent
						onPress={() => handleGoBack()}
						text={'Go Back'}
						marginVertical={windowWidth > 650 ? 25 : 5}
						width={windowWidth > 650 ? 210 : '100%'}
						color={'$white'}
						textColor={'$primary500'}
					/>
				)}

				{/* <CongratsOnboarding
					isOpen={congratsModal}
					onClose={() => {
						setCongratsModal(false)
					}}
					points={capabilitySelected.length}
					onPressContinue={() => {
						setCongratsModal(false)
						onPressContinue()
					}}
				/> */}
				<ButtonComponent
					loading={loadingApI}
					text={'Save and Proceed'}
					marginVertical={windowWidth > 650 ? 25 : 5}
					width={windowWidth > 650 ? 210 : '100%'}
					disabled={
						screen === 'profile'
							? capabilitySelected.length < 1
							: capabilitySelected.length < 5
					}
					onPress={async () => {
						OnpressClaimandProceed()
					}}
				/>
			</Box>
		</Box>
	)
}
