/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 *
 * @format
 * @flow strict-local
 */
/**
 * TBD: Handle the login process with HUB. Make it more event driven.
 * TBD: Need to clear the notifications from local storage and replace it with an API call for
 * older notifications.
 * TBD: Notifications don't remain in the localDB
 */
import { Dimensions, Platform, Linking } from 'react-native'
import { Box, View } from '@gluestack-ui/themed'
import WebFont from 'webfontloader'
// import { Tab } from '@rneui/sthemed';
import React, { useEffect, useState } from 'react'
import { SSRProvider } from '@react-aria/ssr'
import LoadingScreen from './screens/LoadingScreen'
import { Amplify, API, graphqlOperation, Analytics, Hub } from 'aws-amplify'
import awsconfig from './aws-exports'
import NewProfile from './newScreens/CompanyScreen/NewProfile'
import NewHIdi from './newScreens/NewHIdi'
import RecomendedScreen from './newScreens/CompanyScreen/RecomendedScreen'
import ShortlistScreen from './newScreens/CompanyScreen/ShortlistScreen'
import InsightScreen from './screens/InsightScreen'
import UserInsightScreen from './screens/UserInsightScreen'
import PreferencesScreen from './newScreens/CompanyScreen/Preferncesscreen'
import Profile from './newScreens/profileScreen/ProfileScreen'
import CapabilityDetails from './newScreens/profileScreen/capabilitiesScreen/CapabilityDetails'
import DashboardInsightsScreen from './screens/DashboardInsightsScreen'
import HidiListScreen from './newScreens/profileScreen/WhatIDid/HidiListScreen'
import { LogBox } from 'react-native'
import useUserStore from './Userstore/UserStore'
import CreateHidiScreen from './screens/CreateHidiScreen'
import EditHidiScreen from './screens/EditHidiScreen'
import ProfileDetails from './screens/ProfileDetails'
import EditPreferences from './screens/EditPreferences'
import ServiceNowHidiExplorer from './screens/ServiceNowHidiExplorer'
import OnboardingScreen from './newScreens/onboardingFlow/OnboardingScreen'
import { ToastContainer } from 'react-toastify'
import CreateJobPost from './newScreens/CompanyScreen/CreateJobPost'
import 'react-toastify/dist/ReactToastify.css'
import Capabilities from './screens/Capabilities'
import Feed from './newScreens/FeedScreen/FeedScreen'
import CandidateQrewHomeScreen from './newScreens/CandidateSideQrew/CandidateQrewHomeScreen'
import CandidateQrewObjectiveScreen from './newScreens/CandidateSideQrew/CandidateQrewObjectiveScreen'
// import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
Amplify.configure({
	...awsconfig,
})

// import ConversationList from './src/screens/Conversations';
import { NativeBaseProvider } from 'native-base'
import { NavigationContainer } from '@react-navigation/native'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import AllCompanyScreen from './newScreens/CompanyScreen/AllCompanyScreen'
// import TabBar from './components/navbar';
import { SafeAreaProvider } from 'react-native-safe-area-context'

import Login from './newScreens/loginFlow/LogIn/Login'
import UserProfile from './newScreens/publicView/UserProfile'
import ProfileSettings from './screens/ProfileSettings'
import EditProfile from './newComponents/editProfile/EditProfile'
import CreateAccount from './newScreens/loginFlow/CreateAccount/CreateAccount'
import SignUpOptions from './newScreens/loginFlow/SignUpOptions/SignUpOptions'
import CapCreateHidi from './newScreens/CapCreateHidi'
import JobApplicantsList from './newScreens/CompanyScreen/JobApplicantsList'
import JobApplyingCandidateList from './newScreens/CompanyScreen/JobApplyingCandidateList'
import { GluestackUIProvider } from '@gluestack-ui/themed'
import config from './gluestack-ui.config'
import { DekstopHeaderCandidate } from './newComponents/header/Header'
import SideNavBar from './newComponents/sideNavigationBar/SideNavBar'
import Journey from './newScreens/journey/Journey'
import Opportunities from './newScreens/opportunities/Opportunities'
import CandidatePreferences from './newComponents/CandidatePreferences'
import CandidateList from './newScreens/CompanyScreen/CandidateList'
import JobDetails from './newScreens/opportunities/JobDetails'
import ConsentTermsAndConditions from './newScreens/opportunities/components/ConsentTermsAndConditions'
import VideoRecordingScreen from './newComponents/videoComponent/VideoRecordingScreen'
import JobApplyProcessB from './newScreens/opportunities/JobApplyPageProcessB'
// const Tab = createBottomTabNavigator();

// import Onboarding from './src/screens/Onboarding';
const Stack = createNativeStackNavigator()
const navigationRef = React.createRef()

const windowWidth = Dimensions.get('window').width

const App = () => {
	const [isOnboardingScreen, setIsOnboardingScreen] = useState(false)
	const loaded = useUserStore((state) => state.loaded)
	const checkScreen = useUserStore((state) => state.checkScreen)
	const profileId = useUserStore((state) => state.profileId)
	const checkLogin = useUserStore((state) => state.checkUserLoggedIn)
	const isLoggedIn = useUserStore((state) => state.isLoggedIn)
	const typeofuser = useUserStore((state) => state.group)
	const setSavedurl = useUserStore((state) => state.setSavedUrl)
	const firsttimmeuser = useUserStore((state) => state.firstTimeUser)
	const signout = useUserStore((state) => state.signOut)

	useEffect(() => {
		WebFont.load({
			google: {
				families: [
					'Lexend',
					'Open Sans',
					'Open Sans Bold 700',
					'Urbanist',
					'Urbanist SemiBold 600',
					'Urbanist Bold 700',
					'Urbanist ExtraBold 800',
				],
			},
		})
	}, [])
	useEffect(() => {
		Linking.getInitialURL().then((url) => {
			if (url && url.includes('opportunities')) {
				setSavedurl(url)
			} else if (url && url.includes('JobDetails')) {
				setSavedurl(url)
			} else if (url && url.includes("JobApply")){
				setSavedurl(url)
			}
		})
	}, [])
	const linking = {
		config: {
			// initialRouteName: 'FormScreen',
			screens: {
				Profile: {
					path: 'profile/',
				},
				Journey: {
					path: 'journey/',
				},
				Opportunities: {
					path: 'opportunities/:JobPostId/',
					parse: {
						JobPostId: (JobPostId) => `${JobPostId}`,
					},
				},
				JobDetails: {
					path: 'JobDetails/',
				},
				JobApply: {
					path: 'JobApply/',
				},
				PreferencesScreen: {
					path: 'preferences/',
				},
				UserProfile: {
					path: 'Q-profile/:UserId',
					parse: {
						UserId: (UserId) => `${UserId}`,
					},
				},
				UserInsightScreen: {
					path: 'insights',
				},
				NewHIDI: {
					path: 'hidi/:hidiId/:PreferenceId?/:jobPostId?',
					parse: {
						hidiId: (hidiId) => `${hidiId}`,
						PreferenceId: (PreferenceId) => `${PreferenceId}`,
						jobPostId: (jobPostId) => `${jobPostId}`,
					},
				},
				ServiceNowHidiExplorer: {
					path: 'hidiexplorer/:HidiTopic',
					parse: {
						HidiTOpic: (HidiTopic) => `${HidiTopic}`,
					},
				},
				Hidilist: {
					path: 'hidis/:UserId',
					parse: {
						UserId: (UserId) => `${UserId}`,
					},
				},
				//for testing
				CapabilityDetails: {
					path: 'capabilitydetails/',
				},
				CapCreateHidi: {
					path: 'CreateCapabilityHidi/',
				},
				FormScreen: {
					path: 'form',
				},

				ProfileSettings: {
					path: 'ProfileSettings',
				},
				CreateAccount: {
					path: 'CreateAccount',
				},
				TermsAndConditions: {
					path: 'TermsAndConditions',
				},
				IntroVideo: {
					path: 'IntroVideo',
				},

				Home: 'home',
			},
		},
	}

	useEffect(() => {
		checkLogin()
	}, [])

	return (
		<GluestackUIProvider config={config}>
			<View>
				<SSRProvider>
					{!loaded ? (
						<LoadingScreen />
					) : (
						<NativeBaseProvider
							behavior={Platform.OS === 'ios' ? 'padding' : null}
							enabled={Platform.OS === 'android' ? true : true}
							style={{
								height: '100%',
							}}
						>
							<SafeAreaProvider>
								<ToastContainer />
								<NavigationContainer
									linking={linking}
									fallback={<LoadingScreen />}
									ref={navigationRef}
								>
									{isLoggedIn &&
									typeofuser !== 'companys' &&
									typeofuser !== 'QrusibleSuperAdmin' ? (
										<DekstopHeaderCandidate
											navigationRef={navigationRef}
										/>
									) : null}
									<View w={'100%'} flexDirection="row">
										{isLoggedIn &&
										profileId &&
										typeofuser !== 'companys' &&
										checkScreen !== 'Onboarding' ? (
											windowWidth > 750 ? (
												<View
													minWidth={250}
													w={windowWidth * 0.18}
												>
													<SideNavBar />
												</View>
											) : (
												<SideNavBar />
											)
										) : null}
										<Stack.Navigator
											screenOptions={{
												headerShown: false,
											}}
										>
											{isLoggedIn ? (
												<>
													{typeofuser == 'companys' ||
													typeofuser ==
														'QrusibleSuperAdmin' ? (
														<>
														{typeofuser == 'QrusibleSuperAdmin' && 
															<Stack.Screen
															name="AllCompany"
															component={
																AllCompanyScreen
															}
															options={{
																headerShown: false,
															}}
														/>
														}
														<Stack.Screen
																name="PreferencesScreen"
																component={
																	PreferencesScreen
																}
																options={{
																	headerShown: false,
																}}
															/>
															<Stack.Screen
																name="JobDetails"
																component={
																	JobDetails
																}
																options={{
																	headerShown: false,
																	title: 'Opportunities',
																}}
															/>
															<Stack.Screen
																name="JobApply"
																component={
																	JobApplyProcessB
																}
																options={{
																	headerShown: false,
																	title: 'Opportunities',
																}}
															/>
																<Stack.Screen
																name="CreateJobPost"
																component={
																	CreateJobPost
																}
																options={{
																	headerShown: false,
																	title: 'Create Job Post',
																}}
															/>
															<Stack.Screen
																name="Candidates"
																component={
																	CandidateList
																}
																options={{
																	headerShown: false,
																}}
															/>
															<Stack.Screen
																name="JobApplicantsList"
																component={
																	JobApplicantsList
																}
																options={{
																	headerShown: false,
																}}
															/>
															<Stack.Screen
																name="JobApplicantsApplying"
																component={
																	JobApplyingCandidateList
																}
																options={{
																	headerShown: false,
																}}
															/>
															<Stack.Screen
																name="Hidilist"
																component={
																	HidiListScreen
																}
																options={{
																	headerShown: false,
																}}
															/>
															<Stack.Screen
																name="NewHIDI"
																component={
																	NewHIdi
																}
																options={{
																	headerShown: false,
																}}
															/>
															<Stack.Screen
																name="NewProfile"
																component={
																	NewProfile
																}
																options={{
																	headerShown: false,
																	title: 'Candidate Profile',
																}}
															/>
															<Stack.Screen
																name="InsightScreen"
																component={
																	InsightScreen
																}
																options={{
																	headerShown: false,
																}}
															/>

															<Stack.Screen
																name="RecomendedScreen"
																component={
																	RecomendedScreen
																}
																options={{
																	headerShown: false,
																}}
															/>
															<Stack.Screen
																name="Shortlist"
																component={
																	ShortlistScreen
																}
																options={{
																	headerShown: false,
																}}
															/>
															<Stack.Screen
																name="UserProfile"
																component={
																	UserProfile
																}
																options={{
																	headerShown: false,
																}}
															/>
															<Stack.Screen
																name="CapabilityDetails"
																component={
																	CapabilityDetails
																}
																options={{
																	headerShown: false,
																}}
															/>
															<Stack.Screen
																name="UserInsightScreen"
																component={
																	UserInsightScreen
																}
																options={{
																	headerShown: false,
																}}
															/>
															<Stack.Screen
																name="ServiceNowHidiExplorer"
																component={
																	ServiceNowHidiExplorer
																}
																options={{
																	headerShown: false,
																}}
															/>
														</>
													) : (
														<>
													
															<Stack.Screen
																name="Feed"
																component={Feed}
																options={{
																	headerShown: false,
																	title: 'Feed',
																}}
															/>
															<Stack.Screen
																name="QrewObjectiveScreen"
																component={CandidateQrewObjectiveScreen}
																options={{
																	headerShown: false,
																	title: 'Feed',
																}}
															/>
															<Stack.Screen
																name="YourQrew"
																component={CandidateQrewHomeScreen}
																options={{
																	headerShown: false,
																	title: 'Feed',
																}}
															/>

															<Stack.Screen
																name="Profile"
																component={
																	Profile
																}
																options={{
																	headerShown: false,
																}}
															/>
															<Stack.Screen
																name="Journey"
																component={
																	Journey
																}
																options={{
																	headerShown: false,
																}}
															/>

															<Stack.Screen
																name="Opportunities"
																component={
																	Opportunities
																}
																options={{
																	headerShown: false,
																
																}}
															/>
															<Stack.Screen
																name="JobApply"
																component={
																	JobApplyProcessB
																}
																options={{
																	headerShown: false,
																	title: 'Opportunities',
																}}
															/>

															<Stack.Screen
																name="JobDetails"
																component={
																	JobDetails
																}
																options={{
																	headerShown: false,
																	title: 'Opportunities',
																}}
															/>

															<Stack.Screen
																name="Onboarding"
																component={
																	OnboardingScreen
																}
																options={{
																	headerShown: false,
																}}
															/>

															<Stack.Screen
																name="EditProfile"
																component={
																	EditProfile
																}
																options={{
																	headerShown: false,
																	title: 'Profile Edit',
																}}
															/>
															<Stack.Screen
																name="EditPreferences"
																component={
																	EditPreferences
																}
																options={{
																	headerShown: false,
																}}
															/>
															<Stack.Screen
																name="ProfileDetails"
																component={
																	ProfileDetails
																}
																options={{
																	headerShown: false,
																}}
															/>
															<Stack.Screen
																name="HidiEdit"
																component={
																	EditHidiScreen
																}
																options={{
																	headerShown: false,
																}}
															/>
															<Stack.Screen
																name="CreateHidiScreen"
																component={
																	CreateHidiScreen
																}
																options={{
																	headerShown: false,
																}}
															/>
															<Stack.Screen
																name="IntroVideo"
																component={
																	VideoRecordingScreen
																}
																options={{
																	headerShown: false,
																}}
															/>
															<Stack.Screen
																name="TermsAndConditions"
																component={
																	ConsentTermsAndConditions
																}
																options={{
																	headerShown: false,
																}}
															/>
															<Stack.Screen
																name="NewHIDI"
																component={
																	NewHIdi
																}
																options={{
																	headerShown: false,
																}}
															/>
															<Stack.Screen
																name="UserProfile"
																component={
																	UserProfile
																}
																options={{
																	headerShown: false,
																}}
															/>
															<Stack.Screen
																name="CandidatePreferences"
																component={
																	CandidatePreferences
																}
																options={{
																	headerShown: false,
																}}
															/>

															<Stack.Screen
																name="UserInsightScreen"
																component={
																	UserInsightScreen
																}
																options={{
																	headerShown: false,
																}}
															/>
															<Stack.Screen
																name="CapabilityDetails"
																component={
																	CapabilityDetails
																}
																options={{
																	headerShown: false,
																}}
															/>
															<Stack.Screen
																name="CapCreateHidi"
																component={
																	CapCreateHidi
																}
																options={{
																	headerShown: false,
																}}
															/>

															<Stack.Screen
																name="Hidilist"
																component={
																	HidiListScreen
																}
																options={{
																	headerShown: false,
																}}
															/>

															<Stack.Screen
																name="DashboardInsights"
																component={
																	DashboardInsightsScreen
																}
																options={{
																	headerShown: false,
																}}
															/>
															<Stack.Screen
																name="ServiceNowHidiExplorer"
																component={
																	ServiceNowHidiExplorer
																}
																options={{
																	headerShown: false,
																}}
															/>
														</>
													)}
												</>
											) : (
												<>
													<Stack.Screen
														name="SignUpOptions"
														component={
															SignUpOptions
														}
														options={{
															headerShown: false,
														}}
													/>
													<Stack.Screen
														name="Login"
														component={Login}
														options={{
															headerShown: false,
														}}
													/>
													<Stack.Screen
														name="CreateAccount"
														component={
															CreateAccount
														}
														options={{
															headerShown: false,
														}}
													/>
														<Stack.Screen
																name="JobApply"
																component={
																	JobApplyProcessB
																}
																options={{
																	headerShown: false,
																	title: 'Opportunities',
																}}
															/>
													<Stack.Screen
														name="JobDetails"
														component={JobDetails}
														options={{
															headerShown: false,
															title: 'Opportunities',
														}}
													/>

													<Stack.Screen
														name="Hidilist"
														component={
															HidiListScreen
														}
														options={{
															headerShown: false,
														}}
													/>
													<Stack.Screen
														name="NewHIDI"
														component={NewHIdi}
														options={{
															headerShown: false,
														}}
													/>

													<Stack.Screen
														name="UserProfile"
														component={UserProfile}
														options={{
															headerShown: false,
														}}
													/>

													<Stack.Screen
														name="UserInsightScreen"
														component={
															UserInsightScreen
														}
														options={{
															headerShown: false,
														}}
													/>
													<Stack.Screen
														name="ServiceNowHidiExplorer"
														component={
															ServiceNowHidiExplorer
														}
														options={{
															headerShown: false,
														}}
													/>

													<Stack.Screen
														name="CapabilityDetails"
														component={
															CapabilityDetails
														}
														options={{
															headerShown: false,
														}}
													/>
												</>
											)}
										</Stack.Navigator>
									</View>
								</NavigationContainer>
							</SafeAreaProvider>
						</NativeBaseProvider>
					)}
				</SSRProvider>
			</View>
		</GluestackUIProvider>
	)
}

export default App
