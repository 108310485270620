import {
	ModalContent,
	Modal,
	ModalBackdrop,
	ModalHeader,
	VStack,
	Heading,
	Text,
	ModalBody,
	Box,
	ModalCloseButton,
	Icon,
	ModalFooter,
	Button,
	ButtonText,
	CloseIcon,
	Divider,
} from '@gluestack-ui/themed'
import { Dimensions } from 'react-native'
import React from 'react'
import { Center } from 'native-base'
import { ToastContainer } from 'react-toastify'

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

function TermsAndConditions({ showModal, setShowModal, ref }) {
	const handleCloseModal = () => {
		setShowModal(false)
	}

	return (
		<Center>
			<Modal
				isOpen={showModal}
				onClose={handleCloseModal}
				minHeight={windowHeight}
				finalFocusRef={ref}
				position="fixed"
			>
				<ModalBackdrop />
				<ToastContainer />
				<ModalContent
					maxHeight={windowHeight * 0.9}
					minWidth={
						windowWidth > 480
							? windowWidth * 0.7
							: windowWidth * 0.9
					}
				>
					<ModalHeader>
						<Heading size="lg" fontFamily="$fontHeading">
							Contact Us
						</Heading>
						<ModalCloseButton>
							<Icon as={CloseIcon} />
						</ModalCloseButton>
					</ModalHeader>
					<Center>
						<Divider w={'95%'} />
					</Center>
					<ModalBody>
						<VStack gap={10}>
							<Heading size="2xl" fontFamily="$fontHeading">
								Terms and Conditions: User-Generated Content
								Distribution
							</Heading>
							<Box>
								<Text size="sm" fontFamily="$fontContent">
									Welcome to Qrusible! By accessing and using
									our website/application, you agree to comply
									with the following terms and conditions
									related to the publication and distribution
									of user-generated content. Please read them
									carefully before proceeding:
								</Text>
							</Box>
							{/* 1 */}
							<Heading size="md" fontFamily="$fontHeading">
								1. User-Generated Content (UGC)
							</Heading>
							<Box>
								<Text size="sm" fontFamily="$fontContent">
									a. Qrusible allows users to create, upload,
									post, publish, and share various types of
									content, including but not limited to text,
									images, videos, audio, and other materials.
								</Text>
							</Box>
							<Box>
								<Text size="sm" fontFamily="$fontContent">
									b. You understand and acknowledge that any
									content you submit or make available on
									Qrusible is solely your responsibility.
								</Text>
							</Box>
							{/* 2 */}
							<Heading size="md" fontFamily="$fontHeading">
								2. Grant of License
							</Heading>
							<Box>
								<Text size="sm" fontFamily="$fontContent">
									a. By submitting or uploading UGC on
									Qrusible, you grant us a worldwide,
									royalty-free, non-exclusive, transferable,
									and sublicensable license to reproduce,
									distribute, publicly display, and perform
									the UGC in connection with the operation and
									promotion of Qrusible.
								</Text>
							</Box>
							<Box>
								<Text size="sm" fontFamily="$fontContent">
									b. This license allows us to use, modify,
									adapt, translate, create derivative works
									from, and incorporate the UGC into any form,
									media, or technology now known or later
									developed.
								</Text>
							</Box>
							{/* 3 */}
							<Heading size="md" fontFamily="$fontHeading">
								3. User Content Representation and Warranty
							</Heading>
							<Box>
								<Text size="sm" fontFamily="$fontContent">
									a. You represent and warrant that you own or
									have obtained all necessary rights,
									licenses, consents, and permissions to grant
									us the license in Section 2 above.
								</Text>
							</Box>
							<Box>
								<Text size="sm" fontFamily="$fontContent">
									b. You further represent and warrant that
									the UGC you submit does not infringe or
									violate any third-party rights, including
									copyright, trademark, privacy, publicity, or
									any other intellectual property or
									proprietary rights.
								</Text>
							</Box>
							{/* 4 */}
							<Heading size="md" fontFamily="$fontHeading">
								4. Content Moderation
							</Heading>
							<Box>
								<Text size="sm" fontFamily="$fontContent">
									a. We reserve the right, but not the
									obligation, to monitor, review, edit, or
									remove any UGC submitted by users without
									prior notice.
								</Text>
							</Box>
							<Box>
								<Text size="sm" fontFamily="$fontContent">
									b. We may, at our discretion, take
									appropriate action against any user who
									violates our content guidelines or terms of
									service.
								</Text>
							</Box>
							{/* 5 */}
							<Heading size="md" fontFamily="$fontHeading">
								5. Indemnification
							</Heading>
							<Box>
								<Text size="sm" fontFamily="$fontContent">
									a. You agree to indemnify and hold Qrusible
									and its affiliates, directors, officers,
									employees, and agents harmless from any
									claims, damages, liabilities, losses, or
									expenses (including attorney's fees) arising
									out of or in connection with your UGC,
									including but not limited to any violation
									of third-party rights or any breach of the
									representations and warranties.
								</Text>
							</Box>
							{/* 6 */}
							<Heading size="md" fontFamily="$fontHeading">
								6. User Privacy
							</Heading>
							<Box>
								<Text size="sm" fontFamily="$fontContent">
									a. Our use of your UGC is subject to our
									Privacy Policy. By using Qrusible, you
									consent to the collection, storage, and
									processing of your personal information as
									outlined in our Privacy Policy.
								</Text>
							</Box>
							{/* 7 */}
							<Heading size="md" fontFamily="$fontHeading">
								7. Modification of Terms
							</Heading>
							<Box>
								<Text size="sm" fontFamily="$fontContent">
									a. We reserve the right to modify or update
									these terms and conditions relating to
									user-generated content without prior notice.
									Any changes will be effective immediately
									upon posting on Qrusible. It is your
									responsibility to review the terms
									periodically.
								</Text>
							</Box>
							{/* 8 */}
							<Heading size="md" fontFamily="$fontHeading">
								8. Governing Law and Jurisdiction
							</Heading>
							<Box>
								<Text size="sm" fontFamily="$fontContent">
									a. These terms and conditions shall be
									governed by and construed in accordance with
									the laws of [Jurisdiction]. Any disputes
									arising out of or relating to these terms
									shall be subject to the exclusive
									jurisdiction of the courts located in
									[Jurisdiction].
								</Text>
							</Box>
							<Box>
								<Text size="sm" fontFamily="$fontContent">
									By using Qrusible, you acknowledge that you
									have read, understood, and agree to abide by
									these terms and conditions regarding the
									publication and distribution of
									user-generated content. If you do not agree
									with any part of these terms, please refrain
									from using our website/application.
								</Text>
							</Box>
						</VStack>
					</ModalBody>
					<ModalFooter justifyContent="flex-end">
						<Button
							size="sm"
							action="primary"
							borderWidth="$0"
							w={135}
							onPress={handleCloseModal}
							sx={{
								bg: '$primary500',
								':hover': {
									bg: '$primary400',
								},
								':active': {
									bg: '$primary500',
								},
							}}
						>
							<ButtonText fontSize={12} fontFamily="$fontContent">
								Okay
							</ButtonText>
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</Center>
	)
}

export default TermsAndConditions
