// gluestack-ui.config.js

import { createConfig, config as defaultConfig } from '@gluestack-ui/themed'

const config = createConfig({
	...defaultConfig.theme,
	tokens: {
		...defaultConfig.theme.tokens,
		colors: {
			...defaultConfig.theme.tokens.colors,
			primary0: '#ffffff',
			primary50: '#fef1ea',
			primary100: '#fecfb9',
			primary200: '#fead89',
			primary300: '#fe8c58',
			primary400: '#fe6a27',
			primary500: '#E16449',
			primary600: '#E16449',
			primary700: '#cb4000',
			primary800: '#9a3000',
			primary900: '#692100',
			primary950: '#000000',

			grey100: '#7a7777',
			tertiary100: '#DDE7F1',

			borderDark0: '#EEEEEE',
			borderDark50: '#A0AEC0',
			borderDark100: '#718096',

			borderOrange100: '#FFE9E4',
			borderGrey100: '#E9E9E9',
			borderBlue100: '#F4FEFF',

			backgroundGray: '#FCFCFC',
			backgroundGray100: '#F7F7F7',

			fontGray: '#A0AEC0',
			fontGray100: '#718096',
			fontGray200: '#989898',

			secondary50: '#fff1f2',
			secondary100: '#FFF8F5',
			secondary200: '#fecdd3',
			secondary300: '#fda4af',
			secondary400: '#fb7185',
			secondary500: '#f43f5e',
			secondary600: '#e11d48',
			secondary700: '#be123c',
			secondary800: '#9f1239',
			secondary900: '#881337',

			green500: '#219C90',
			progressGray: '#C7D3EB',

			blue500: '#1791FF',

			faintPink: '#FFF7F7',
			faintPink300: '#FFE9E9',
			darkPink: '#FA4F00',
		},
		fonts: {
			fontContent: 'Open Sans',
			fontHeading: 'Helvetica',
			fontHeading2: 'Helvetica Neue',
		},
		fontSizes: {
			fontSize11: 11,
			fontSize12: 12,
			fontSize13: 13,
			fontSize14: 14,
			fontSize15: 15,
			fontSize16: 16,
			fontSize18: 18,
			fontSize20: 20,
			fontSize22: 22,
			fontSize25: 25,
			fontSize30: 30,
			fontSize35: 35,
			fontSize40: 40,

			fontHeading: 18,
			fontHeadingMobile: 15,
			fontContent: 14,
			fontContentMobile: 11,
		},
	},
})

export default config
