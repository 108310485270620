import React from 'react'
import { Box, Heading, Text } from '@gluestack-ui/themed'
import useUserStore from '../../Userstore/UserStore'
import VideoRecorderComponent from './VideoRecorderComponent'
import { Dimensions } from 'react-native'
import { useState } from 'react'
import { useEffect } from 'react'
import { Auth } from 'aws-amplify'
import { CreateEvents } from '../../HeplerFunctions/EventTableMutations'
import { isBrowser, isMobile, browserName} from 'react-device-detect';
import ButtonComponent from '../ButtonComponent'
const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

const VideoRecordingScreen = ({
	navigation,
	screen,
	handleGoBack,
	handleProceed,
	inModal,
	setModal,
	setCongratsModal,
}) => {
	const [keyParam, setKeyParam] = useState('')

	const userId = useUserStore((state) => state.userId)
	const signout = useUserStore((state) => state.signOut)

	const savedUrl = useUserStore((state) => state.savedurl)

	const getSession = async () => {
		try {
			await Auth.currentSession()
		} catch (error) {
			console.log(error)
			signout()
		}
	}
	useEffect(() => {
		setKeyParam(`${userId}_video`)
		getSession()
	}, [userId])

	const inModalActions = () => {
		setModal(false)
		setCongratsModal(true)
	}
	const CreateEventForUploadVideoCreation = async (item) => {
		
	
		const EventAttributesJSOn = {
			"UserId" :  userId,
			"IsMobile" : isMobile,
			"Skipped" : (item == 'Skipped' ? true : false)
		
		  };
		try {
			
			

			const Input = {
				EventNameTableKeyId: userId,
				EventName : "UploadVideoStep2",
				EventAttributes : JSON.stringify(EventAttributesJSOn)

	
			}

			const res = await CreateEvents(Input)
			console.log(res)
		
		} catch (err) {
			console.log('Error updating objective', err)
		} 
		
	}
const handleOnboardingProceed = () => {
handleProceed()
CreateEventForUploadVideoCreation()
}
	return (
		<Box
			bg="$backgroundGray"
			minHeight={inModal ? windowHeight * 0.5 : windowHeight - 62}
			marginTop={screen !== 'Onboarding' && !inModal && 62}
			marginBottom={windowWidth > 480 ? null : 50}
			paddingHorizontal={
				windowWidth > 480 && !inModal && screen !== 'Onboarding'
					? 100
					: 5
			}
			paddingVertical={windowWidth > 480 && !inModal ? 20 : 5}
			paddingBottom={windowWidth > 480 ? null : 50}
		>
			<Box
				borderWidth={1}
				p={windowWidth > 480 && !inModal ? 20 : 10}
				bg="white"
				borderColor="$borderDark0"
				borderRadius={6}
			>
				{!inModal && (
					<Box>
						<Heading fontFamily="$fontHeading" fontWeight="600">
							Record a video for employers to see
						</Heading>
						<Text
							fontSize={windowWidth > 480 ? 12 : 10}
							fontFamily="$fontHeading"
							lineHeight={15}
						>
							A video will let the employers know more about you
							and your skills and experinces
						</Text>
					</Box>
				)}
				<Box>
					<VideoRecorderComponent
						keyParam={keyParam}
						screen={screen}
						handleGoBack={handleGoBack}
						actionOnUpload={() => {
							inModal
								? inModalActions()
								: screen === 'Onboarding'
								? handleOnboardingProceed()
								: savedUrl.includes('JobDetails')
								? (window.location.href = savedUrl)
								: navigation.push('Profile')
						}}
						handleSkip={ () => 
							{
								CreateEventForUploadVideoCreation("Skipped")
								handleProceed()
							}
							}
					/>
				</Box>
			</Box>
		</Box>
	)
}

export default VideoRecordingScreen
