import React, { useState } from 'react'
import { Dimensions } from 'react-native'
import FederatedLogin from '../FederatedLogin'
import AlreadyUser from '../Options'
import ValidateOtpNew from './ValidateOtpNew'
import { Amplify } from 'aws-amplify'
import awsmobile from '../../../aws-exports'
import useUserStore from '../../../Userstore/UserStore'
import SpinnerComponent from '../../../newComponents/SpinnerComponent'
import {
	Box,
	Image,
	KeyboardAvoidingView,
	Heading,
	Center,
	HStack,
} from '@gluestack-ui/themed'
import LoginInputComponent from '../LoginInputComponent'
import { showToast } from '../../../components/ErrorToast'
import { useRef } from 'react'
import AboutQrusible from '../AboutQrusible'
import WavyBg from '../../../newComponents/WavyBg'

Amplify.configure(awsmobile)

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

const CreateAccount = ({ routes, navigation, setTab, inModal }) => {
	const ref = useRef(null)
	const [isModalVisible, setModalVisible] = useState(false)
	const error = useUserStore((state) => state.error)
	const [userData, setUserData] = useState({
		email: '',
		password: '',
	})
	const [loading, setLoading] = useState(false)
	const updateUser = useUserStore((state) => state.updateUser)
	const signUp = useUserStore((state) => state.signUp)

	const handleSignUpPress = async () => {
		setLoading(true)
		const { email, password } = userData
		if (email && password) {
			updateUser({ email, password })
			const response = await signUp()

			if (response) {
				setModalVisible(true)
			} else {
				showToast({
					message: 'You already have an account with ' + email,
				})
			}
			setLoading(false)
		} else {
			setLoading(false)
			showToast({ message: 'Please fill in your Email and Password' })
		}
	}

	const handleModalClose = () => {
		setModalVisible(false)
	}

	return (
		<HStack  
		height={
			inModal &&
			(windowWidth > 480
				? windowHeight * 0.9
				: windowHeight * 0.7)
		}
		justifyContent='center'
		>
			{!inModal && <WavyBg />}
			{!loading ? (
				<Box
				alignItems="center"
				justifyContent="center"
				flexDirection="column"
				
				minHeight={inModal ? 'auto' : '100vh'}
				maxHeight={
					inModal &&
					(windowWidth > 480
						? windowHeight * 0.9
						: windowHeight * 0.7)
				}
				paddingVertical={100}
				flex={1}
				alignSelf={'center'}
				
			>
				<Box
					justifyContent="center"
					alignItems={windowWidth > 500 && 'center'}
					w={'auto'}
					
					p={windowWidth > 480
						? 30 : 20}
					borderRadius={20}
					shadowRadius={30}
					shadowColor="black"
					// shadowOffset={{ height: 5, width: 5 }}
					shadowOpacity={0.2}
					borderColor="#d1d1e8"
					bg="white"
				>
					<Image
						h={windowWidth > 480
							? '8rem'
							: '4rem'}
						w={windowWidth > 480
							? '8rem'
							: '4rem'}
						alt="Qrusible logo"
						alignSelf="center"
						source={require('../../../assets/Images/QrusibleNewBlack.png')}
					/>
					<KeyboardAvoidingView flex={1}>
						<Box justifyContent="center" alignItems="center">
							<Heading
								fontSize={
									windowWidth > 1500
										? '$fontSize30'
										: '$fontSize20'
								}
								fontWeight="$bold"
								fontFamily="$fontContent"
								color="$secondary950"
								marginVertical={windowWidth > 480
									? 20 : 10}
								textAlign="center"
							>
								Sign Up
							</Heading>
							<FederatedLogin
								setTab={setTab}
								expanded={true}
								loginScreen={true}
							/>
							<Box w={'100%'}>
								<LoginInputComponent
									buttonText={'Sign Up'}
									onPress={handleSignUpPress}
									userData={userData}
									setUserData={setUserData}
									loading={loading}
								/>
							</Box>
						</Box>

						{/* <Box></Box> */}

						<Center>
							<ValidateOtpNew
								modalClose={handleModalClose}
								modalVisibilty={isModalVisible}
								userData={userData}
								ref={ref}
								inModal={inModal}
								setTab={setTab}
								loading ={loading}
								setLoading ={setLoading}
								navigation={navigation}
								
							/>
						</Center>
					</KeyboardAvoidingView>
				</Box>
				<AlreadyUser
					navigation={navigation}
					inModal={inModal}
					setTab={setTab}
				/>
			</Box>) : (
				<>
				{!inModal && <WavyBg />}
				
				<Box
				alignItems="center"
				justifyContent="center"
				flexDirection="column"
				
				minHeight={inModal ? 'auto' : '100vh'}
				maxHeight={
					inModal &&
					(windowWidth > 480
						? windowHeight * 0.9
						: windowHeight * 0.7)
				}
				paddingVertical={100}
				flex={1}
				alignSelf={'center'}
				
			>
				<Box
				padding={100}
				justifyContent="center"
				alignSelf='center'
				alignItems={windowWidth > 500 && 'center'}
				
				borderWidth={1}
				p={30}
				borderRadius={20}
				shadowRadius={30}
				shadowColor="black"
				
				// shadowOffset={{ height: 5, width: 5 }}
				shadowOpacity={0.2}
				borderColor="#d1d1e8"
				bg="white"
			>
<SpinnerComponent 
					
					size={'large'} text={'Please Wait'} />
			</Box>
				
					
				</Box>
				</>
				
			)}
			
		</HStack>
	)
}

export default CreateAccount
