import {
	View,
	Text,
	Image,
	Dimensions,
	StyleSheet,
	ActivityIndicator,
	TouchableOpacity,
	FlatList,
} from 'react-native'
import React from 'react'
import { useEffect, useState, Fla } from 'react'
import { Button } from 'native-base'
const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height
import { listNewprofiles } from '../../graphql/queries'
import { Drawer, MobileHeader } from '../../newComponents/header/Header'
import { DekstopHeader } from '../../newComponents/header/Header'
import { API, Auth, graphqlOperation } from 'aws-amplify'
import SpinnerComponent from '../../newComponents/SpinnerComponent'
import { list_JobApplicants_by_Level_sortbyCompanyprefernceId } from '../../graphql/custom-queries'
import { listRejectedByPreferencesByDate } from '../../graphql/custom-queries'
import CandidateComponent from '../../components/ApplicantCandidateComponent'
import Divider from '../../FeedComponents/Divider'
import { Box } from '@gluestack-ui/themed'
import CandidateViewComponent from './components/CandidateViewComponent'
export default function RejectedScreen({
	navigation,
	preferenceId,
	rolename,
	companyName,
	JobPostData
}) {
	const [Rejectedlist, setRejectedlist] = useState('')
	const [loading, setloading] = useState(true)
	const [nextToken, setNextToken] = useState(null)
   
	async function getRejectedList(token = null) {
		try {
			const response = await API.graphql({
				query: listRejectedByPreferencesByDate,
				variables: {
                    preferencesId : preferenceId
                
                },
			})
			
			const newData =
				response.data.listRejectedByPreferencesByDate.items
			setNextToken(
				response.data.listRejectedByPreferencesByDate
					.nextToken
			)
                setRejectedlist([...Rejectedlist, ...newData])
			
			setloading(false)
		} catch (error) {
			console.log(error)
			setloading(false)
		}
	}

	useEffect(() => {
		getRejectedList()
	}, [])

	const fetchMoreData = () => {
		if (nextToken) {
			getRejectedList(nextToken)
		}
	}

	return (
		<Box>
			{!loading ? (
				<CandidateViewComponent
				loading={loading}
				headingText={'Applicants'}
				companyname={companyName}
				onPressBtn={null}
				navigation={navigation}
				data={Rejectedlist}
				rolename={rolename}
				fetchMoreData={fetchMoreData}
				PreferenceId = {preferenceId}
				listEmptyText={'No applicants yet!'}
				JobPostData ={JobPostData}
			/>

			) :(<SpinnerComponent />)}
			
		</Box>
	)
}
