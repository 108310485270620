import {
	Box,
	Image,
	Modal,
	ModalBackdrop,
	ModalBody,
	ModalContent,
	Pressable,
	Text,
} from '@gluestack-ui/themed'
import { Dimensions } from 'react-native'
import React from 'react'
import TotalPointsOneCapability from './TotalPointsOneCapability'
import SpinnerComponent from '../SpinnerComponent'

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

const CongratsCapabilityUnlock = ({
	points,
	isOpen,
	onClose,
	loading,
	onPressContinue,
	text1,
	text2,
}) => {
	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			position="fixed"
			maxHeight={windowHeight}
		>
			<ModalBackdrop />
			<ModalContent
				p={0}
				w={'fit-content'}
				h={'fit-content'}
				borderRadius={30}
			>
				<ModalBody
					p={0}
					w={'fit-content'}
					h={'fit-content'}
					borderRadius={30}
				>
					<Box
						alignItems="center"
						bg="rgba(166, 89, 254, 1)"
						paddingVertical={40}
						paddingHorizontal={50}
						gap={10}
						w={'fit-content'}
						borderRadius={30}
					>
						<Box>
							<Image
								source={require('../../assets/Images/congratsCap.png')}
								h={120}
								w={120}
								alt="congratsCap"
							/>
						</Box>
						<Box marginVertical={10}>
							<Text
								textAlign="center"
								color="$primary0"
								fontFamily="$fontHeading"
							>
								{text1 ? text1 : 'You have improved your'}
							</Text>
							<Text
								textAlign="center"
								color="$primary0"
								fontFamily="$fontHeading"
							>
								{text2 ? text2 : 'chances to get shortlisted'}
							</Text>
						</Box>
						<Box marginVertical={10}>
							<TotalPointsOneCapability
								points={points}
								text={'Added to your profile'}
							/>
						</Box>

						{loading ? (
							<>
								<Box marginVertical={10}>
									<SpinnerComponent
										button={true}
										size={'small'}
										color={'$primary0'}
									/>
								</Box>
							</>
						) : (
							<Pressable
								marginVertical={10}
								onPress={onPressContinue}
							>
								<Text
									fontFamily="$fontHeading"
									fontWeight="bold"
									color="rgba(255, 255, 255, 0.67)"
									sx={{
										':hover': {
											color: 'white',
										},
									}}
								>
									Continue
								</Text>
							</Pressable>
						)}
					</Box>
				</ModalBody>
			</ModalContent>
		</Modal>
	)
}

export default CongratsCapabilityUnlock
