import {
	Box,
	CheckIcon,
	Checkbox,
	CheckboxGroup,
	CheckboxIcon,
	CheckboxIndicator,
	CheckboxLabel,
	Link,
	LinkText,
	Text,
	VStack,
} from '@gluestack-ui/themed'
import React, { useState } from 'react'
import ModalComponent from '../../../newComponents/ModalComponent'
import { Dimensions } from 'react-native'
import ProfileScreen from '../../profileScreen/ProfileScreen'
import ButtonComponent from '../../../newComponents/ButtonComponent'
import UserProfile from '../../publicView/UserProfile'
import useUserStore from '../../../Userstore/UserStore'

const windowWidth = Dimensions.get('window').width

const ConsentModal = ({
	isOpen,
	onClose,
	navigation,
	updateConsent,
	loadingBtn,
}) => {
	const [checkBox, setCheckBox] = useState([])
	const userId = useUserStore((state) => state.userId)

	const renderProfile = () => {
		return (
			<UserProfile
				navigation={navigation}
				inModal={true}
				consentUserId={userId}
			/>
		)
	}

	const renderFooter = () => {
		return (
			<Box flexDirection="column" justifyContent="flex-end">
				<CheckboxGroup
					value={checkBox}
					onChange={(keys) => {
						setCheckBox(keys)
					}}
					marginVertical={windowWidth > 480 ? 10 : 5}
				>
					<VStack gap={2} w={windowWidth < 480 && windowWidth * 0.85}>
						<Box>
							<Checkbox value="TermsAndConditions">
								<CheckboxIndicator mr={5} h={13} w={13}>
									<CheckboxIcon as={CheckIcon} />
								</CheckboxIndicator>
								<CheckboxLabel
									fontFamily="$fontHeading"
									fontSize={windowWidth > 480 ? 12 : 10}
									lineHeight={15}
								>
									I agree to the{' '}
									<Link href="/TermsAndConditions" isExternal>
										<LinkText
											size="lg"
											fontFamily="$fontHeading"
										>
											Terms and Conditions
										</LinkText>
									</Link>
								</CheckboxLabel>
							</Checkbox>
						</Box>
						<Box>
							<Checkbox value="ConsentToShare">
								<CheckboxIndicator mr={5} h={13} w={13}>
									<CheckboxIcon as={CheckIcon} />
								</CheckboxIndicator>
								<CheckboxLabel
									fontFamily="$fontHeading"
									fontSize={windowWidth > 480 ? 12 : 10}
									lineHeight={15}
								>
									I hereby declare that the information given
									above and in the enclosed documents is true
									to the best of my knowledge
								</CheckboxLabel>
							</Checkbox>
						</Box>
					</VStack>
				</CheckboxGroup>
				<ButtonComponent
					text="Continue"
					width={100}
					disabled={
						checkBox.includes('TermsAndConditions') &&
						checkBox.includes('ConsentToShare')
							? false
							: true
					}
					onPress={() => {
						updateConsent()
					}}
					loading={loadingBtn}
				/>
			</Box>
		)
	}

	return (
		<Box>
			<ModalComponent
				isOpen={isOpen}
				onClose={onClose}
				heading={
					'Congratulations! You can now review your profile and Apply.'
				}
				hideCloseButton={true}
				renderBody={renderProfile}
				showScroll={false}
				renderFooter={renderFooter}
				minWidth={
					windowWidth > 480 ? windowWidth * 0.7 : windowWidth * 0.9
				}
				subHeading={
					'This is what the employer will see. Make sure it has all the information you want to relay.'
				}
			/>
		</Box>
	)
}

export default ConsentModal
