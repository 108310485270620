import {
	AddIcon,
	Box,
	Icon,
	Pressable,
	Text,
	Image,
	FlatList,
	Divider,
	Heading,
	VStack,
	HStack,
} from '@gluestack-ui/themed'
import React from 'react'
import { Dimensions } from 'react-native'
import SpinnerComponent from '../SpinnerComponent'

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

const CandidateCareerViewComponent = ({
	title,
	loading,
	description,
	onPress,
	buttonText,
	data,
	renderItem,
	addMoreImage,
	claimButton,
	ref,
	tabState,
	totalActive,
	CapablityLength,
	component,
	onPressViewAll,
	screen,
	hidiCount,
	editIcon,
	numColumnsFlatlist,
}) => {
	return (
		<Box>
			<Box
				flexDirection="row"
				justifyContent="space-between"
				alignItems="center"
				marginBottom={5}
			>
				<VStack flex={1}>
					<Heading
						fontFamily="$fontHeading"
						fontSize={
							windowWidth > 480
								? '$fontHeading'
								: '$fontHeadingMobile'
						}
						flex={1}
						lineHeight={20}
					>
						{screen === 'Journey'
							? component === 'whatIDid' &&
							  `${hidiCount} ${title}`
							: title}
					</Heading>
					{claimButton ? (
						<Text
							// lineHeight={12}
							color="$secondary500"
							fontFamily="$fontContent"
							fontSize={
								screen === 'Journey'
									? windowWidth > 480
										? '$fontHeading'
										: '$fontHeadingContent'
									: 11
							}
							fontWeight="700"
							lineHeight={15}
						>
							{tabState == 'cap'
								? totalActive
								: totalActive >= 5
								? 5
								: totalActive}
							{tabState == 'cap'
								? totalActive != CapablityLength
									? `/${CapablityLength}`
									: null
								: totalActive <= 5
								? '/5'
								: null}{' '}
							Unlocked
						</Text>
					) : null}
				</VStack>
				<HStack gap={windowWidth > 480 ? 10 : 5} flexWrap="wrap">
					{screen !== 'public' && (
						<Pressable onPress={onPress} ref={ref}>
							<Box
								alignItems="center"
								flexDirection="row"
								paddingHorizontal={
									windowWidth > 480 ? !editIcon && 8 : 4
								}
								paddingVertical={windowWidth > 480 ? 5 : 3}
								borderRadius={6}
								borderWidth={!editIcon && 2}
								borderColor="$primary500"
							>
								{editIcon ? (
									<Image
										source={require('../../assets/Images/edit.png')}
										h={18}
										w={18}
									/>
								) : (
									<Text
										fontFamily="$fontContent"
										fontSize={windowWidth > 480 ? 13 : 11}
										fontWeight="900"
										color="$primary500"
									>
										{claimButton
											? claimButton
											: data?.length <= 0
											? '+ Add'
											: '+ Add More'}
									</Text>
								)}
							</Box>
						</Pressable>
					)}

					{/* {component === 'whatIDid' && (
						<Pressable onPress={onPressViewAll} ref={ref}>
							<Box
								alignItems="center"
								flexDirection="row"
								paddingHorizontal={windowWidth > 480 ? 8 : 4}
								paddingVertical={windowWidth > 480 ? 5 : 3}
								borderRadius={6}
								borderWidth={2}
								borderColor="$primary500"
							>
								<Text
									fontFamily="$fontContent"
									fontSize={windowWidth > 480 ? 13 : 11}
									fontWeight="900"
									color="$primary500"
								>
									View All
								</Text>
							</Box>
						</Pressable>
					)} */}
				</HStack>
			</Box>
			<Divider marginVertical={10} bgColor="$borderDark0" h={0.5} />
			<Box>
				{loading ? (
					<Box marginVertical={windowWidth > 480 ? 10 : 20}>
						<SpinnerComponent smallText={'Loading...'} />
					</Box>
				) : (
					<Box>
						{data?.length === 0 && addMoreImage ? (
							<Box alignItems="center" w={'100%'}>
								{addMoreImage && (
									<Image
										height={
											windowWidth <= 480
												? '8rem'
												: '10rem'
										}
										width={
											windowWidth <= 480
												? '9rem'
												: '11rem'
										}
										marginVertical={10}
										source={addMoreImage}
										alt="add more image"
									/>
								)}

								<Text
									textAlign="center"
									fontFamily="$fontContent"
									fontSize={windowWidth > 480 ? 12 : 10}
									fontWeight="700"
								>
									{description ? description : null}
								</Text>
								<Divider
									marginVertical={10}
									bgColor="$borderDark0"
									h={0.5}
								/>
								{screen !== 'public' && (
									<Pressable
										onPress={onPress ? onPress : () => {}}
										marginVertical={10}
									>
										<Text
											textAlign="center"
											paddingVertical={
												windowWidth > 480 ? 8 : 5
											}
											paddingHorizontal={
												windowWidth > 480 ? 10 : 8
											}
											bg="$primary200"
											borderRadius={6}
											color="$primary950"
											fontFamily="$fontContent"
											fontSize={
												windowWidth > 480 ? 13 : 10
											}
											hardShadow={5}
											fontWeight="600"
										>
											{buttonText ? buttonText : null}
										</Text>
									</Pressable>
								)}
							</Box>
						) : (
							<Box w={'100%'}>
								<FlatList
									data={data}
									renderItem={renderItem}
									keyExtractor={(item) => item.id}
									numColumns={
										numColumnsFlatlist
											? numColumnsFlatlist
											: null
									}
								/>
							</Box>
						)}
					</Box>
				)}
			</Box>
		</Box>
	)
}

export default CandidateCareerViewComponent
