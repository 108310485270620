import * as React from 'react'
import { Box, VStack, HStack, Text, Divider } from '@gluestack-ui/themed'
import { Dimensions } from 'react-native'

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height
import { showPublicView } from '../HeplerFunctions/ShowPublicProfile'

import useImageFileLink from '../customhook/useImageLinkhook'
import CapabilitiesRenderComponent from '../newComponents/keyCapabilities/CapabilitiesRenderComponent'
import HidiViewProfile from '../newScreens/profileScreen/WhatIDid/HidiViewProfile'
import Hidiheader from '../components/Hidiheader'

const HidiFeed = React.memo(({ item, navigation }) => {
	return (
		<Box
			w={'100%'}
			borderWidth={1}
			borderColor="$borderDark0"
			p={windowWidth > 480 ? 20 : 10}
			bg="$primary0"
			marginVertical={10}
		>
			<Box>
				<Text
					fontFamily="$fontContent"
					fontSize={windowWidth > 480 ? '$fontSize12' : 10}
					lineHeight={15}
					fontWeight="700"
				>
					You might be interested in How They Did It
				</Text>
			</Box>
			<Divider marginVertical={10} h={0.5} />
			<Box>
				<Box marginVertical={10}>
					<Hidiheader
						props={item?.Hidi?.profile}
						screen={'feed'}
						onPress={() => showPublicView(item?.Hidi?.UserId)}
						navigation={navigation}
					/>
				</Box>
				<HidiViewProfile
					item={item?.Hidi}
					navigation={navigation}
					view={'public'}
					screen={'feed'}
				/>
			</Box>
			<Divider marginVertical={10} h={0.5} />
			<Box>
				<CapabilitiesRenderComponent
					item={item?.Hidi?.ConnectedCapability?.items[0]?.Capability}
					disable={true}
					screen={'feed'}
				/>
			</Box>
		</Box>
	)
}

)

export default HidiFeed
