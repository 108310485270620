import { Dimensions } from 'react-native'
import { Box, Divider, HStack, Heading, Image, Text, VStack } from '@gluestack-ui/themed'

import React, { useEffect, useState } from 'react'

import useUserStore from '../../../Userstore/UserStore'
// import { getNewprofile } from '../../../graphql/queries'
// import {
// 	createCandidatePreferences,
// 	createProfile,
// 	updateCandidatePreferences,
// 	updateNewprofile,
// } from '../../../graphql/custom-mutations'

import { API, Storage, Auth } from 'aws-amplify'
import { showToast } from '../../../components/ErrorToast'
import AvatarComponent from '../../../newComponents/AvatarComponent'
import LabelWithInputComponent from '../../../newComponents/LabelWithInputComponent'
import DropdownComponent from '../../../newComponents/DropdownComponent'
import ButtonComponent from '../../../newComponents/ButtonComponent'
import SpinnerComponent from '../../../newComponents/SpinnerComponent'
import { createJobTraits } from '../../../graphql/mutations'
import { Get_JobPost_by_companyPreferenceId } from '../../../graphql/custom-queries'
import { createJobPost, updateJobPost } from '../../../graphql/custom-mutations'
import { updatePreferences } from '../../../graphql/custom-mutations'
import { getFullPreferences } from '../../../graphql/custom-queries'

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

export default function CreateJobPostView({
	handleProceed,
	handleGoBack,
	responseLambda,
	mapData,
	screen,
	hideModal,
	hiddenHeading,
	navigation,
	jobType,
	preferenceId,
	companyProfileId
}) {
	
	// const [user, setUser] = useState('');

	const [loading, setLoading] = useState(false)
	const [loadingPage, setLoadingPage] = useState(false)

	const [JobTitle, setJobTitle] = useState('')
	const [Location, setLocation] = useState('')
	const [Compensation, setCompensation] = useState(0)
	

	const [userpreferences, setUserpreferences] = useState({
		experienceMax: '',
		experienceMin: '',
		domain: '',
		userpreferenceId: '',
		jobType: '',
		workType: ''
	})

	const [AbouttheJob, setAbouttheJob] = useState('')
	const [roleandresponsiblity, setRolesandResponsibility] = useState('')
	const[JobPostId, setJobPostId] = useState('')
	const [CompanyProfile, setCompanyprofile] = useState('')

	const checkScreen = useUserStore((state) => state.checkScreen)
	const signout = useUserStore((state) => state.signOut)
	
	// const isJobLink = savedUrl.includes('JobDetails')


	
	

	





	const getSession = async () => {
		try {
			await Auth.currentSession()
		}
		catch(error){
			console.log(error)
			signout()
		}
		
		
	  }
	useEffect(() => {
		getJobPreferences()
		// getProfile()
		// if (mapData?.length !== 0) {
		// 	setFullName(mapData?.name)
		// }
		// if (mapData?.length !== 0) {
		// 	setBio(mapData?.about)
		// }
		// if (mapData?.length !== 0) {
		// 	setExpertise(mapData?.experiences[0]?.designation)
		// }
		// if (mapData?.length !== 0) {
		// 	setPhone(mapData?.phoneNo)
		// }
		// getSession()
	}, [])

	const UpdateJobpreferences = async() => {
		setLoading(true)
		
		const CreateUserInput = {
			id: preferenceId,
			JobPublished: true
			
		}
		await API.graphql({
			query: updatePreferences,
			variables: { input: CreateUserInput }
			
		}).then(()=> {
		navigation.push("PreferencesScreen")
		setLoading(false)
	})
		.catch((err) => {
			console.log('updatepreferences', err)
		})
	}

	async function getJobPreferences() {
		setLoadingPage(true)
		await API.graphql({
			query: getFullPreferences,
			// authMode: 'API_KEY',
			variables: {
				id: preferenceId,
			},
		})
			.then((res) => {
				console.log(res)
				
				setJobTitle(res.data.getPreferences.rolename)
				setCompanyprofile(res.data.getPreferences.companyprofile)
				// setLocation(res.data.getNewprofile?.location)
				setUserpreferences({
					// workTypeList: res.data.getNewprofile?.typeofwork,
					experienceMax: `${res.data.getPreferences.maxExperienceRequired} Years`,
						experienceMin: `${res.data.getPreferences.minExperienceRequired} Years`,
						domain: res.data.getPreferences.JobDomain,

					jobType: res.data.getPreferences.JobType,
					workType: res.data.getPreferences.modeofwork
				})
				setCompensation(res.data.getPreferences.package)
				setAbouttheJob(res.data.getPreferences.JobPosts.items[0].description)
				setRolesandResponsibility(res.data.getPreferences.JobPosts.items[0].RolesandResponsibility )
				setLocation(res.data.getPreferences.Location)

				setLoading(false)
				setLoadingPage(false)
			})
			.catch((err) => {
				
					console.log(err)
					
			})
	}

	// useEffect(() => {
	// 	const timer = setInterval(() => {
	// 		const newPercentage = filledPercentage + 20
	// 		const newTextIndex =
	// 			(textOptions.indexOf(displayText) + 1) % textOptions.length
	// 		setDisplayText(textOptions[newTextIndex])

	// 		if (newPercentage >= 95) {
	// 			setFilledPercentage(95)
	// 		} else {
	// 			setFilledPercentage(newPercentage)
	// 		}
	// 	}, 8000)

	// 	return () => clearInterval(timer)
	// }, [filledPercentage, displayText])


	return (
		<>
			<Box
				borderWidth={hiddenHeading ? null : 1}
				borderColor="$borderDark0"
				borderRadius={6}
				alignSelf="center"
				justifyContent="center"
				alignItems="center"
				w={'100%'}
				paddingVertical={windowWidth > 480 ? 25 : 10}
				paddingHorizontal={windowWidth > 480 ? 10 : 5}
				marginBottom={30}
				bg="$primary0"
			>
				
					<Box
						w={'100%'}
						alignSelf="center"
						justifyContent="center"
						alignItems="center"
					>
						
						
						
                       
						
						<Box w={windowWidth > 480 ? '80%' : '95%'}>
						<Heading
									
									fontFamily="$fontHeading"
									fontSize={
										windowWidth > 480
											? '$fontHeading'
											: '$fontHeadingMobile'
									}
									fontWeight="700"
								>
									{JobTitle}
									
								</Heading>
                                <Text fontFamily="$fontContent"
						fontSize={
							windowWidth > 480
								? '$fontContent'
								: '$fontContentMobile'
						}
						fontWeight="700"
						color='grey'>
								{userpreferences.jobType} - {Location}
                        </Text>
							<Divider marginVertical={20} padding={1} />
							<HStack
							w={'100%'}
							alignSelf="center"
							justifyContent="space-between"
							
						>
							
							
							
							<VStack>
								<Text
								marginBottom={3}
								fontFamily="$fontContent"
								fontSize={
									windowWidth > 480
										? '$fontContent'
										: '$fontContentMobile'
								}
								fontWeight="700"
								color='grey'
								>
									Salary Range
								</Text>
								<Text
								fontFamily="$fontContent"
								fontSize={
									windowWidth > 480
										? '$fontContent'
										: '$fontContentMobile'
								}
								fontWeight="700"
								color="$primary950"
								>
									{Compensation}
								</Text>
							</VStack>
							<VStack>
								<Text
								marginBottom={3}
								fontFamily="$fontContent"
								fontSize={
									windowWidth > 480
										? '$fontContent'
										: '$fontContentMobile'
								}
								
								fontWeight="700"
								color='grey'>
									Job Type
									
								</Text>
								<Text
								fontFamily="$fontContent"
								fontSize={
									windowWidth > 480
										? '$fontContent'
										: '$fontContentMobile'
								}
								fontWeight="700"
								color="$primary950">
									{userpreferences.jobType}
								</Text>
							</VStack>
							</HStack>
							<Divider marginVertical={20} padding={1} />
							<HStack
							w={'100%'}
							alignSelf="center"
							justifyContent="space-between"
							
						>
							
							
							
							<VStack>
								<Text
								marginBottom={3}
								fontFamily="$fontContent"
								fontSize={
									windowWidth > 480
										? '$fontContent'
										: '$fontContentMobile'
								}
								fontWeight="700"
								color='grey'
								>
									Maximum Experience
								</Text>
								<Text
								fontFamily="$fontContent"
								fontSize={
									windowWidth > 480
										? '$fontContent'
										: '$fontContentMobile'
								}
								fontWeight="700"
								color="$primary950"
								>
									{userpreferences.experienceMax} years
								</Text>
							</VStack>
							<VStack>
								<Text
								marginBottom={3}
								fontFamily="$fontContent"
								fontSize={
									windowWidth > 480
										? '$fontContent'
										: '$fontContentMobile'
								}
								
								fontWeight="700"
								color='grey'>
									Minimum Experience
									
								</Text>
								<Text
								fontFamily="$fontContent"
								fontSize={
									windowWidth > 480
										? '$fontContent'
										: '$fontContentMobile'
								}
								fontWeight="700"
								color="$primary950">
									{userpreferences.experienceMin} years
								</Text>
							</VStack>
							</HStack>
							<Divider marginVertical={20} padding={1} />
							<Heading
									
									fontFamily="$fontHeading"
									fontSize={
										windowWidth > 480
											? '$fontHeading'
											: '$fontHeadingMobile'
									}
									fontWeight="700"
								>
									About {CompanyProfile.name}
									
								</Heading>
								<Box flexDirection='row' marginTop={10} >
							
					
								
								<Text 	fontFamily="$fontContent"
						fontSize={
							windowWidth > 480
								? '$fontContent'
								: '$fontContentMobile'
						}
						fontWeight="700"
						color="$primary950">
									{CompanyProfile.about}
								</Text>
							
						  </Box>
								<Divider marginVertical={20} padding={1} />
							
						<Heading
									
									fontFamily="$fontHeading"
									fontSize={
										windowWidth > 480
											? '$fontHeading'
											: '$fontHeadingMobile'
									}
									fontWeight="700"
								>
									Roles and Responsibility
									
								</Heading>
								<Box flexDirection='row'  >
							<Image
							h={40}
							w={40}
							marginTop={10}
							source={require('../../../assets/Images/responsibility.png')}
							/>
							<VStack width={'100%'} marginLeft={10} marginTop={10}>
								<Text 	fontFamily="$fontContent"
						fontSize={
							windowWidth > 480
								? '$fontContent'
								: '$fontContentMobile'
						}
						fontWeight="700"
						color='grey'>
								About the Job
								</Text>
								<Text 	fontFamily="$fontContent"
						fontSize={
							windowWidth > 480
								? '$fontContent'
								: '$fontContentMobile'
						}
						fontWeight="700"
						color="$primary950">
									{AbouttheJob}
								</Text>
							</VStack>
						  </Box>
                          <Box flexDirection='row' marginTop={10} >
							<Image
							h={40}
							w={40}
							
							source={require('../../../assets/Images/roles.png')}
							/>
							<VStack width={'100%'} marginLeft={10}>
								<Text
									fontFamily="$fontContent"
									fontSize={
										windowWidth > 480
											? '$fontContent'
											: '$fontContentMobile'
									}
									fontWeight="700"
									color='grey'>
									
									Role
								</Text>
								<Text 	fontFamily="$fontContent"
						fontSize={
							windowWidth > 480
								? '$fontContent'
								: '$fontContentMobile'
						}
						fontWeight="700"
						color="$primary950">
									{roleandresponsiblity}
								</Text>
							</VStack>
						  </Box>
						 
                           
							
							

							
						</Box>
						<Box
							flexDirection={
								windowWidth > 650 ? 'row' : 'column-reverse'
							}
							justifyContent={'space-between'}
							w={windowWidth > 480 ? '80%' : '95%'}
						>
							
					<ButtonComponent
					marginVertical={windowWidth > 650 ? 25 : 5}
						onPress={() => handleGoBack()}
						text={'Go Back'}
						width={windowWidth > 650 ? 210 : '100%'}
						color={'$white'}
						textColor={'$primary500'}
					/>
							<ButtonComponent
								loading={loading}
								disabled={
									false
								}
								onPress={() => {
									UpdateJobpreferences()
								}}
								text={
								
										'Save and Publish'
										
								}
								marginVertical={windowWidth > 650 ? 25 : 5}
								width={
									210
										
								}
							/>
						</Box>
					</Box>
				
			</Box>
		</>
	)
}
