import React from 'react'
import { Dimensions } from 'react-native'
import { Box, HStack, Text } from '@gluestack-ui/themed'
import ProgressBar from '../../newComponents/ProgressBar'

const windowWidth = Dimensions.get('window').width

const ProgressBarsOnboarding = ({
	progressBarFirst,
	progressBarSecond,
	progressBarThird,
	progressBarFourth,
	mtop,
	mbottom,
}) => {
	// const progressPercent =
	// 	progressBarFourth > 0
	// 		? 75 + Math.floor((progressBarFourth / 100) * 25)
	// 		: progressBarThird === 100
	// 		? 75
	// 		: progressBarSecond === 100
	// 		? 50
	// 		: progressBarFirst === 100
	// 		? 25
	// 		: 0
	const progressPercent =
		progressBarFourth > 0
			? 100
			: progressBarSecond === 100
			? 100
			: progressBarFirst === 100
			? 50
			: 0


	return (
		<Box mt={mtop ? mtop : 50} mb={mbottom ? mbottom : 30} w={'100%'}>
			<Box>
				<Text fontFamily="$fontHeading" fontWeight="600" fontSize={14}>
					{progressPercent}%
				</Text>
			</Box>
			<HStack
				flexWrap="nowrap"
				w={'100%'}
				gap={windowWidth > 480 ? 15 : 5}
			>
				<ProgressBar
					progress={progressBarFirst}
					bgColor={'#fe6a27'}
					animationType={'timing'}
					width={windowWidth > 480 ? '48%' : '50%'}
					borderColor={'#EEEEEE'}
					bgPlaceHolder={'#EEEEEE'}
				/>
				<ProgressBar
					progress={progressBarSecond}
					bgColor={'#fe6a27'}
					animationType={'timing'}
					width={windowWidth > 480 ? '48%' : '50%'}
					borderColor={'#EEEEEE'}
					bgPlaceHolder={'#EEEEEE'}
				/>
				{/* <ProgressBar
					progress={progressBarThird}
					bgColor={'#fe6a27'}
					animationType={'timing'}
					width={windowWidth > 480 ? '24%' : '25%'}
					borderColor={'#EEEEEE'}
					bgPlaceHolder={'#EEEEEE'}
				/>

				<ProgressBar
					progress={progressBarFourth}
					bgColor={'#fe6a27'}
					animationType={'timing'}
					width={windowWidth > 480 ? '24%' : '25%'}
					borderColor={'#EEEEEE'}
					bgPlaceHolder={'#EEEEEE'}
				/> */}
			</HStack>
		</Box>
	)
}

export default ProgressBarsOnboarding
