import { Box, Heading, Divider, FlatList, Text } from '@gluestack-ui/themed'
import { Dimensions } from 'react-native'
import React from 'react'
import HidiViewProfile from '../WhatIDid/HidiViewProfile'
import { MobileHeader } from '../../../newComponents/header/Header'
import useUserStore from '../../../Userstore/UserStore'

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

const CapabilityDetails = ({ route, navigation }) => {
	const { capabilityItem } = route.params
	const typeOfUser = useUserStore((state) => state.group)

	const renderItemMethod = ({ item }) => {
		return (
			<Box>
				<HidiViewProfile
					item={item.Hidi}
					navigation={navigation}
					view={route?.params?.screen}
				/>
			</Box>
		)
	}

	return (
		<>
			{typeOfUser === 'companys' && (
				<MobileHeader
					props={'Candidate Profile'}
					navigation={navigation}
				/>
			)}

			{capabilityItem?.ConnectedHidis?.items.length > 0 ? (
				<Box
					bg="$backgroundGray"
					minHeight={windowHeight - 62}
					marginTop={62}
					marginBottom={windowWidth > 480 ? null : 50}
					paddingHorizontal={windowWidth > 480 ? 100 : 5}
					paddingVertical={windowWidth > 480 ? 20 : 5}
					paddingBottom={windowWidth > 480 ? null : 50}
				>
					<Heading
						fontFamily="$fontHeading"
						fontSize={
							windowWidth > 480
								? '$fontHeading'
								: '$fontHeadingMobile'
						}
						bg="$primary100"
						paddingVertical={windowWidth > 480 ? 10 : 5}
						paddingHorizontal={5}
						borderRadius={6}
						lineHeight={windowWidth > 480 ? 30 : 20}
					>
						{capabilityItem?.CapabilityConfig?.name}
					</Heading>
					<Divider h={0.5} />
					<Heading
						fontFamily="$fontHeading"
						fontSize={
							windowWidth > 480 ? '$fontSize20' : '$fontSize13'
						}
						marginTop={windowWidth > 480 ? 10 : 5}
					>
						Things I Did
					</Heading>
					<FlatList
						data={capabilityItem?.ConnectedHidis?.items}
						renderItem={renderItemMethod}
						keyExtractor={(item) => item.id}
						ListEmptyComponent={() => {
							return <Text>Try Again</Text>
						}}
					/>
				</Box>
			) : null}
		</>
	)
}

export default CapabilityDetails
