import { ArrowLeftIcon, Box, TrashIcon } from '@gluestack-ui/themed'
import React from 'react'
import { points } from '../../../newComponents/pointsComponents/pointsconfig'
import ShareSkeleton from './ShareSkeleton'

const ShareHack = ({
	setTab,
	hack,
	setHack,
	totalCapabilityPoints,
	prevData,
	capEditView,
	textAreaHeight,
}) => {
	const wordCount = hack?.split(/\s+/)?.filter((word) => word !== '')?.length

	return (
		<Box>
			<ShareSkeleton
				prevFieldData={prevData}
				titleNormal1={'Was there '}
				titleColored1={'anything different '}
				titleNormal2={'you did that led to a '}
				titleColored2={'successful '}
				titleNormal3={'outcome?'}
				subtitle={'Any new ideas or new discoveries that helped you'}
				textArea={true}
				valueInput={hack}
				setInputValueChange={setHack}
				minWordCount={10 - wordCount}
				infoStripText={`Your inventive spirit adds a spark to the workplace. 🚀 Employers adore candidates with fresh perspectives and a knack for innovation!`}
				buttonText={wordCount < 10 ? 'Skip' : 'Next'}
				buttonOnPress={() => {
					setTab('Helped')
					wordCount < 10 && setHack('')
				}}
				backButtonOnPress={() => {
					setTab('Challenge')
					wordCount < 10 && setHack('')
				}}
				backIcon={ArrowLeftIcon}
				backText={'Back'}
				totalCapabilityPoints={totalCapabilityPoints}
				pointsAssigned={points.hack}
				capEditView={capEditView}
				textAreaHeight={textAreaHeight}
				disableCopyPaste={true}
				disableRightClick={true}
			/>
		</Box>
	)
}

export default ShareHack
