import {
	Box,
	HStack,
	Image,
	Modal,
	ModalBackdrop,
	ModalBody,
	ModalContent,
	Pressable,
	Text,
	VStack,
} from '@gluestack-ui/themed'
import { Dimensions } from 'react-native'
import React, { useState, useEffect } from 'react'
import { API } from 'aws-amplify'
import { list_JobApplicants_by_Level_sortbyCompanyprefernceId } from '../../graphql/custom-queries'
import { Motion } from '@legendapp/motion'
import ProgressBar from '../ProgressBar'

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

const CongratsLevelCrossed = ({
	isOpen,
	onClose,
	level,
	btnText,
	companyPrefId,
}) => {
	const [levelTwo, setLevelTwo] = useState(0)
	const [levelThree, setLevelThree] = useState(0)
	const [levelFour, setLevelFour] = useState(0)

	const [progress, setProgress] = useState(0)

	useEffect(() => {
		if (level === 1) {
			setProgress(0)
			const timeoutId = setTimeout(() => {
				setProgress(50)
			}, 2300)
			return () => clearTimeout(timeoutId)
		} else if (level === 2 || level === 3) {
			setProgress(20)
			const timeoutId = setTimeout(() => {
				setProgress(80)
			}, 2300)
			return () => clearTimeout(timeoutId)
		} else if (level === 4) {
			setProgress(0)
			const timeoutId = setTimeout(() => {
				setProgress(100)
			}, 2300)
			return () => clearTimeout(timeoutId)
		} else {
			setProgress(0)
		}
	}, [level])

	const levelsConfig = [
		{
			level: 'Level 1',
			congratsImg: require('../../assets/Images/congratsLevel1.png'),
			topTitle: 'Your capabilities are now a Match for the job!',
			title: 'Share your experiences and ',
			subtitleBold: 'Unlock',
			subtitle: 'capabilities to apply for the opportunity!',
			nextLevel:
				levelTwo > 0 || levelTwo === null || levelTwo === undefined
					? `${levelTwo} other peers are unlocking 2 more capabilities to Apply`
					: 'Unlock 2 capabilities to Apply!',
		},
		{
			level: 'Level 2',
			congratsImg: require('../../assets/Images/congratsLevel2.png'),
			topTitle: 'You have now applied for the job!!',
			title: 'You can now unlock more ',
			subtitleBold: 'capabilities',
			subtitle: 'to highlight  your profile!',
			nextLevel:
				levelThree > 0 ||
				levelThree === null ||
				levelThree === undefined
					? `${levelThree} other peers are unlocking 3 capabilities to move to Level 3`
					: 'Unlock 3 capabilities to move to get ahead!',
		},
		{
			level: 'Level 3',
			congratsImg: require('../../assets/Images/congratsLevel3.png'),
			topTitle: 'You have completed Level 3',
			title: 'Your profile will now ',
			subtitleBold: 'Standout',
			subtitle: 'for the Employer',
			nextLevel:
				levelFour > 0 || levelFour === null || levelFour === undefined
					? `${levelFour} other peers are unlocking the capabilities and sharing Ideas, Tips and Tricks to Standout!`
					: 'Unlock the capabilities and share Ideas, Tips and Tricks to Standout!',
		},
		{
			level: 'Level 4',
			congratsImg: require('../../assets/Images/congratsLevel4.png'),
			topTitle: 'You have completed Level 4',
			title: 'Your profile is now ',
			subtitleBold: 'Shortlisted',
			subtitle: '',
			nextLevel: '',
		},
	]

	async function getLevel2() {
		await API.graphql({
			query: list_JobApplicants_by_Level_sortbyCompanyprefernceId,
			variables: {
				Level: 2,
				CompanyPreferenceId: { eq: companyPrefId },
			},
		})
			.then((res) => {
				setLevelTwo(
					res?.data
						.list_JobApplicants_by_Level_sortbyCompanyprefernceId
						?.items?.length
				)
			})
			.catch((err) => {
				console.log(err)
			})
	}
	async function getLevel3() {
		await API.graphql({
			query: list_JobApplicants_by_Level_sortbyCompanyprefernceId,
			variables: {
				Level: 3,
				CompanyPreferenceId: { eq: companyPrefId },
			},
		})
			.then((res) => {
				setLevelThree(
					res?.data
						.list_JobApplicants_by_Level_sortbyCompanyprefernceId
						?.items?.length
				)
			})
			.catch((err) => {
				console.log(err)
			})
	}
	async function getLevel4() {
		await API.graphql({
			query: list_JobApplicants_by_Level_sortbyCompanyprefernceId,
			variables: {
				Level: 4,
				CompanyPreferenceId: { eq: companyPrefId },
			},
		})
			.then((res) => {
				setLevelFour(
					res?.data
						.list_JobApplicants_by_Level_sortbyCompanyprefernceId
						?.items?.length
				)
			})
			.catch((err) => {
				console.log(err)
			})
	}

	useEffect(() => {
		getLevel2()
		getLevel3()
		getLevel4()
	}, [companyPrefId])

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			position="fixed"
			maxHeight={windowHeight}
		>
			<ModalBackdrop />
			<ModalContent p={0} w={320} h={'fit-content'} borderRadius={30}>
				<ModalBody
					p={0}
					w={'fit-content'}
					h={'fit-content'}
					borderRadius={30}
					overflow="hidden"
				>
					<VStack
						alignContent="center"
						paddingVertical={20}
						paddingHorizontal={10}
					>
						<Box>
							<Motion.Text
								style={{
									textAlign: 'center',
									fontFamily: 'Helvetica',
									fontWeight: '900',
									fontSize: windowWidth > 480 ? 19 : 16,
								}}
								initial={{
									opacity: 0,
									y: 10,
								}}
								animate={{
									opacity: 1,
									y: 0,
								}}
								transition={{
									default: {
										type: 'spring',
										delay: 400,
										duration: 1200,
									},
								}}
							>
								CONGRATULATIONS!
							</Motion.Text>
						</Box>
						<Box marginTop={10}>
							<Motion.Text
								style={{
									fontFamily: 'Helvetica',
									textAlign: 'center',
									color: '#626268',
									fontSize: windowWidth > 480 ? 14 : 12,
								}}
								initial={{
									opacity: 0,
									y: 10,
								}}
								animate={{
									opacity: 1,
									y: 0,
								}}
								transition={{
									default: {
										type: 'spring',
										delay: 800,
										duration: 1600,
									},
								}}
							>
								{level === 1
									? levelsConfig[0].topTitle
									: level === 2
									? levelsConfig[1].topTitle
									: level === 3
									? levelsConfig[2].topTitle
									: level === 4
									? levelsConfig[3].topTitle
									: null}
							</Motion.Text>
						</Box>
						<Box marginTop={10} mb={5}>
							<Motion.Text
								style={{
									fontFamily: 'Helvetica',
									textAlign: 'center',
									color: '#626268',
									fontSize: windowWidth > 480 ? 14 : 12,
								}}
								initial={{
									opacity: 0,
									y: 10,
								}}
								animate={{
									opacity: 1,
									y: 0,
								}}
								transition={{
									default: {
										type: 'spring',
										delay: 1200,
										duration: 2000,
									},
								}}
							>
								{level === 1
									? levelsConfig[0].title
									: level === 2
									? levelsConfig[1].title
									: level === 3
									? levelsConfig[2].title
									: level === 4
									? levelsConfig[3].title
									: null}
								<Text
									fontFamily="$fontHeading"
									fontWeight="bold"
									color="$primary500"
								>
									{level === 1
										? levelsConfig[0].subtitleBold
										: level === 2
										? levelsConfig[1].subtitleBold
										: level === 3
										? levelsConfig[2].subtitleBold
										: level === 4
										? levelsConfig[3].subtitleBold
										: null}
								</Text>{' '}
							</Motion.Text>
							<Motion.Text
								style={{
									fontFamily: 'Helvetica',
									textAlign: 'center',
									color: '#626268',
								}}
								initial={{
									opacity: 0,
									y: 10,
								}}
								animate={{
									opacity: 1,
									y: 0,
								}}
								transition={{
									default: {
										type: 'spring',
										delay: 1600,
										duration: 2400,
									},
								}}
							>
								{level === 1
									? levelsConfig[0].subtitle
									: level === 2
									? levelsConfig[1].subtitle
									: level === 3
									? levelsConfig[2].subtitle
									: level === 4
									? levelsConfig[3].subtitle
									: null}
							</Motion.Text>
						</Box>
						<Box alignItems="center" marginVertical={10}>
							{(level === 1 || level === 4) && (
								<Box w={'$full'}>
									<Box w={'$full'} alignItems="center">
										<Motion.Image
											source={
												level === 1
													? levelsConfig[0]
															.congratsImg
													: level === 2
													? levelsConfig[1]
															.congratsImg
													: level === 3
													? levelsConfig[2]
															.congratsImg
													: level === 4
													? levelsConfig[3]
															.congratsImg
													: null
											}
											style={{
												height: 100,
												width: 100,
											}}
											initial={{
												opacity: 0,
											}}
											animate={{
												opacity: 1,
											}}
											transition={{
												type: 'timing',
												delay: 4200,
												duration: 3500,
											}}
										/>
									</Box>
									{level === 4 && (
										<Box w={'$full'} alignItems="flex-end">
											<Motion.Image
												source={require('../../assets/Images/flag.png')}
												style={{
													height: 30,
													width: 30,
													bottom: -14,
													right: -5,
												}}
												initial={{
													opacity: 0,
													y: 10,
												}}
												animate={{
													opacity: 1,
													y: 0,
												}}
												transition={{
													default: {
														type: 'timing',
														delay: 1200,
														easing: 'linear',
													},
												}}
											/>
										</Box>
									)}
								</Box>
							)}
							{(level === 2 || level === 3) && (
								<HStack
									justifyContent="space-between"
									paddingLeft={'16%'}
									paddingRight={'13%'}
									w={'$full'}
									alignItems="flex-end"
								>
									<Motion.Image
										source={
											level === 2
												? require('../../assets/Images/level1-blue.png')
												: require('../../assets/Images/level2-blue.png')
										}
										style={{
											height: 20,
											width: 20,
										}}
										initial={{
											opacity: 0,
											y: 10,
										}}
										animate={{
											opacity: 1,
											y: 0,
										}}
										transition={{
											default: {
												type: 'timing',
												delay: 1200,
												easing: 'linear',
											},
										}}
									/>
									<Motion.Image
										source={
											level === 2
												? levelsConfig[1].congratsImg
												: level === 3
												? levelsConfig[2].congratsImg
												: null
										}
										style={{
											height: 50,
											width: 50,
										}}
										initial={{
											opacity: 0,
										}}
										animate={{
											opacity: 1,
										}}
										transition={{
											type: 'timing',
											delay: 4000,
											duration: 3500,
										}}
									/>
								</HStack>
							)}
						</Box>
						<Motion.View
							initial={{
								opacity: 0,
								y: 10,
							}}
							animate={{
								opacity: 1,
								y: 0,
							}}
							transition={{
								default: {
									type: 'timing',
									delay: 1200,
									easing: 'linear',
								},
							}}
						>
							<ProgressBar
								progress={progress}
								bgColor={'red'}
								borderColor={'lightgrey'}
								animationType={'timing'}
							/>
						</Motion.View>
						<Box marginVertical={10}>
							<Motion.Text
								style={{
									textAlign: 'center',
									fontFamily: 'Helvetica',
								}}
								initial={{
									opacity: 0,
								}}
								animate={{
									opacity: 1,
								}}
								transition={{
									default: {
										type: 'timing',
										delay: 1200,
										easing: 'linear',
									},
								}}
							>
								{level === 1
									? levelsConfig[0].nextLevel
									: level === 2
									? levelsConfig[1].nextLevel
									: level === 3
									? levelsConfig[2].nextLevel
									: level === 4
									? ''
									: ''}
							</Motion.Text>
						</Box>
						<Pressable
							paddingVertical={10}
							paddingHorizontal={25}
							w={'$full'}
							onPress={() => {
								onClose()
								setProgress(0)
							}}
							bg="$primary500"
							borderRadius={30}
							marginTop={5}
							minWidth={300}
						>
							<Text
								textAlign="center"
								fontFamily="$fontHeading"
								color={'$primary0'}
							>
								{btnText}
							</Text>
						</Pressable>
					</VStack>
				</ModalBody>
			</ModalContent>
		</Modal>
	)
}

export default CongratsLevelCrossed
