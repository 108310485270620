import { Dimensions } from 'react-native'
import React from 'react'
import { Box, Tabs } from '@gluestack-ui/themed'
import { useEffect, useState, useCallback } from 'react'
import { API, Auth, graphqlOperation } from 'aws-amplify'
import { getNewprofileByUserId } from '../../graphql/custom-queries'
import { getNewprofileByUserIdForProfile } from '../../graphql/custom-queries'

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

import AboutBox from '../../newComponents/aboutBox/AboutBox'

import useUserStore from '../../Userstore/UserStore'
import JourneyTabs from '../journey/JourneyTabs'
import NewKeyCapabilities from '../../newComponents/keyCapabilities/NewKeyCapabilities'
import WorkExperience from '../../newComponents/editCareer/workExperience/WorkExperienceComponent'
import EducationDetails from '../../newComponents/editCareer/educationDetails/EducationDetails'
import CertificateComponent from '../../newComponents/editCareer/certifications/CertificateComponent'
import WhatIDidView from '../profileScreen/WhatIDid/WhatIDidView'
import {
	DekstopHeaderCandidate,
	MobileHeader,
} from '../../newComponents/header/Header'
import SpinnerComponent from '../../newComponents/SpinnerComponent'
import CapabilityHidiOnProfile from '../../newComponents/CapabilityHidiOnProfile'
import ResumeView from '../../newComponents/ResumeView'
import CandidatePreferenceView from '../profileScreen/preferencesView/CandidatePreferenceView'
import VideoViewerComponent from '../../newComponents/videoComponent/VideoViewerComponent'
import SkillsComponent from '../profileScreen/SkillsComponent'
import QrusibleWorkExperience from '../opportunities/components/QrusibleWorkExperience'

export default function UserProfile({
	navigation,
	route,
	inModal,
	consentUserId,
}) {
	const [loading, setloading] = useState(true)
	const [profile, setProfile] = useState('')
	const [hidis, setHidis] = useState([])
	//   const [user, setUser] = useState('')
	const setScreenSideNav = useUserStore((state) => state.setScreenSideNav)
	const screenSideNav = useUserStore((state) => state.screenSideNav)
	const [shortlisted, setshortlisted] = useState(false)
	const pinnedItems = hidis?.filter((item) => item?.pinnedFlag)
	const nonPinnedItems = hidis?.filter((item) => !item?.pinnedFlag)
	//  const topThreeEntries = nonPinnedItems.slice(0, 2);
	const typeOfUser = useUserStore((state) => state.group)
	const allData = [...pinnedItems, ...nonPinnedItems]
	// const newData = allData?.slice(0, 3)
	const [tabState, setTabState] = useState('about')
	const getprofile = useCallback(async () => {
		try {
			const res = await API.graphql({
				query: getNewprofileByUserIdForProfile,
				authMode: 'API_KEY',
				variables: {
					UserId: inModal ? consentUserId : route?.params?.UserId,
				},
			})

			setProfile(res.data.getNewprofileByUserId?.items[0])
			setHidis(res.data.getNewprofileByUserId.items[0]?.hidis.items)
			setloading(false)

			// getUserData()
		} catch (err) {
			console.log(err)
		}
	}, [route?.params?.UserId])

	useEffect(() => {
		getprofile()
		setScreenSideNav('')
	}, [screenSideNav])

	const CapabilityClaimed = profile?.Capabilities?.items

	const matchingCapabilities = allData?.filter((data) =>
		CapabilityClaimed?.some(
			(cap) =>
				cap.capabilitiesCapabilityConfigId ==
				data?.ConnectedCapability?.items[0]?.Capability.CapabilityConfig
					?.id
		)
	)

	return (
		<Box bg="$backgroundGray">
			{!inModal ? (
				typeOfUser === 'companys' ? (
					<MobileHeader
						props={'Candidate Profile'}
						navigation={navigation}
					/>
				) : (
					<DekstopHeaderCandidate />
				)
			) : null}

			<Box
				w={
					typeOfUser === 'candidates'
						? '100%'
						: windowWidth > 480
						? '70%'
						: '100%'
				}
				bg="$backgroundGray"
				alignSelf="center"
			>
				{loading ? (
					<Box
						bg="$backgroundGray"
						minHeight={windowHeight - 62}
						marginTop={62}
						marginBottom={windowWidth > 480 ? null : 50}
						paddingHorizontal={windowWidth > 480 ? 100 : 5}
						paddingVertical={windowWidth > 480 ? 20 : 5}
						paddingBottom={windowWidth > 480 ? null : 50}
					>
						<SpinnerComponent />
					</Box>
				) : (
					<Box
						bg="$backgroundGray"
						minHeight={windowHeight - 62}
						marginTop={!inModal && 62}
						marginBottom={windowWidth > 480 ? null : 50}
						paddingHorizontal={
							windowWidth > 480 ? (inModal ? 30 : 100) : 5
						}
						paddingVertical={windowWidth > 480 ? 20 : 5}
						paddingBottom={windowWidth > 480 ? null : 50}
					>
						<AboutBox props={profile} publicState={'public'} />

						{profile?.IntroVideo && (
							<VideoViewerComponent
								keyParam={`${route?.params?.UserId}_video`}
								introVideo={profile?.IntroVideo}
							/>
						)}

						<SkillsComponent
							screen={'public'}
							userId={
								route?.params?.UserId
									? route?.params?.UserId
									: profile?.UserId
							}
						/>

						<Box>
							{matchingCapabilities && (
								<Box
									bg="$primary0"
									marginBottom={12}
									p={windowWidth > 480 ? 20 : 10}
									borderWidth={1}
									borderColor="$borderDark0"
									borderRadius={6}
								>
									<CapabilityHidiOnProfile
										UnlockedCapability={
											matchingCapabilities
										}
										navigation={navigation}
										capabilitiesClaimedLength={
											CapabilityClaimed?.length +
											matchingCapabilities?.length
										}
										evidences={matchingCapabilities?.length}
										capabilities={CapabilityClaimed}
										capabilitiesClaimed={CapabilityClaimed}
										inModal={inModal}
									/>
								</Box>
							)}
							{profile?.deployedforqrews?.items?.length > 0 &&
			<QrusibleWorkExperience
			
			screen={'public'}
			data={profile}
			navigation={navigation}
		/>
			}
							<WorkExperience
								userId={profile?.UserId}
								screen={'public'}
							/>
							<EducationDetails
								userId={profile?.UserId}
								screen={'public'}
							/>
							<CertificateComponent
								userId={profile?.UserId}
								screen={'public'}
							/>
							<ResumeView
								userId={
									route?.params?.UserId
										? route?.params?.UserId
										: profile?.UserId
								}
							/>
						</Box>
					</Box>
				)}
			</Box>
		</Box>
	)
}
