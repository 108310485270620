import { Box, Text, Pressable, HStack, VStack } from '@gluestack-ui/themed'
import { Dimensions } from 'react-native'

import React from 'react'
import Profile from '../screens/Profile'
const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height
import { useNavigation } from '@react-navigation/native'
import { Auth } from 'aws-amplify'
import useImageFileLink from '../customhook/useImageLinkhook'
import UserAvatar from 'react-native-user-avatar'
import AvatarComponent from '../newComponents/AvatarComponent'
import useUserStore from '../Userstore/UserStore'
const Hidiheader = ({
	props,
	params,
	setProfile,
	screen,
	onPress,
	company,
	logoUrl,
	navigation,
	inModal,
	timeUpdatedAt,
}) => {
	const fileKey = props?.imagekey
	const fileLink = useImageFileLink(fileKey)

	const getCurrentDateTimeFormatted = () => {
		const options = {
			year: 'numeric',
			month: 'short',
			day: '2-digit',
			hour: '2-digit',
			minute: '2-digit',
			second: '2-digit',
			hour12: true,
		}

		return `Last updated at ${new Date().toLocaleString(
			undefined,
			options
		)}`
	}

	const formattedDateTime = getCurrentDateTimeFormatted()

	const formatDate = (dateString) => {
		const options = {
			year: 'numeric',
			month: 'short',
			day: '2-digit',
			hour: 'numeric',
			minute: 'numeric',
			second: 'numeric',
		}
		const date = new Date(dateString)
		return date.toLocaleDateString('en-US', options)
	}

	async function getUserData() {
		await Auth.currentAuthenticatedUser()
			.then((response) => {
				if (response?.attributes?.sub == props?.UserId) {
					navigation.push('Profile')
				} else {
					navigation.push('UserProfile', {
						UserId: props?.UserId,
					})
				}
			})
			.catch((error) => {
				navigation.push('UserProfile', { UserId: props?.UserId })
			})
	}
	return (
		<Box paddingTop={10}>
			<HStack alignItems={'center'} gap={5}>
				<Pressable
					onPress={() =>
						screen === 'feed' ? onPress() : getUserData()
					}
				>
					<AvatarComponent
						imgSource={company ? logoUrl : fileLink}
						height={company ? 45 : 35}
						width={company ? 45 : 35}
						name={props?.name}
					/>
				</Pressable>
				<VStack alignItems="flex-start">
					<HStack>
						<Pressable
							onPress={() =>
								screen === 'feed' ? onPress() : getUserData()
							}
							alignItems="center"
							justifyContent="center"
						>
							<Text
								lineHeight={15}
								fontSize={
									windowWidth > 480
										? '$fontContent'
										: '$fontContentMobile'
								}
								fontFamily="$fontHeading"
								color="$primary950"
								fontWeight="700"
							>
								{company ? props?.companyName : props?.name}
							</Text>
						</Pressable>
						<Box>
							<Text
								lineHeight={20}
								fontFamily="$fontHeading"
								fontWeight="500"
								color="$primary950"
								fontSize={
									windowWidth > 480 ? '$fontSize12' : 10
								}
							>
								{', '}
								{company ? props?.title : props?.expertise}
							</Text>
						</Box>
					</HStack>
					<Box>
						<Text
							fontFamily="$fontHeading"
							fontSize={windowWidth > 480 ? 11 : 10}
							textAlign="left"
							color="$fontGray"
						>
							{inModal
								? formattedDateTime
								: screen === 'edit'
								? formattedDateTime
								: `Last updated at ${formatDate(
										timeUpdatedAt
								  )}`}
						</Text>
					</Box>
				</VStack>
			</HStack>
		</Box>
	)
}

export default Hidiheader
