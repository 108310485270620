import { Box, HStack, Image, Text, Pressable } from '@gluestack-ui/themed'
import React, { useRef, useEffect } from 'react'
import { StyleSheet, Animated, Dimensions } from 'react-native'
import SpinnerComponent from './SpinnerComponent'

const windowWidth = Dimensions.get('window').width

const ShiningButton = ({
	title,
	onPress,
	customWidth,
	customHeight,
	customFs,
	buttonDisabled,
	onPressDisabled,
	loading,
}) => {
	const animation = useRef(new Animated.Value(0)).current

	useEffect(() => {
		const runAnimation = () => {
			Animated.loop(
				Animated.sequence([
					Animated.timing(animation, {
						toValue: 1,
						duration: 3000,
						useNativeDriver: true,
					}),
					Animated.delay(1500),
					Animated.timing(animation, {
						toValue: 0,
						duration: 3000,
						useNativeDriver: true,
					}),
					Animated.delay(2000),
				])
			).start()
		}

		runAnimation()
	}, [animation])

	const buttonWidth = customWidth
		? customWidth
		: windowWidth > 480
		? 650
		: 400

	const translateX = animation.interpolate({
		inputRange: [0, 1],
		outputRange: [-buttonWidth / 2, buttonWidth],
	})

	return (
		<Pressable
			h={customHeight ? customHeight : 45}
			bg={!buttonDisabled ? '$primary500' : '$grey100'}
			borderColor={!buttonDisabled ? '$primary500' : '$grey100'}
			borderRadius={6}
			overflow="hidden"
			justifyContent="center"
			width={buttonWidth}
			my={10}
			mx={0}
			onPress={buttonDisabled ? onPressDisabled : onPress}
		>
			{!buttonDisabled && (
				<Animated.View
					style={[
						styles.shine,
						{
							transform: [{ translateX }, { skewX: '15deg' }],
							opacity: animation.interpolate({
								inputRange: [0, 0.5, 1],
								outputRange: [0, 0.3, 0],
							}),
						},
					]}
				/>
			)}
			{loading ? (
				<>
					<SpinnerComponent button={true} color={'white'} />
				</>
			) : (
				<Text
					color="white"
					fontSize={customFs ? customFs : 16}
					fontWeight="600"
					textAlign="center"
					zIndex={1}
					fontFamily="$fontHeading"
				>
					{title}
				</Text>
			)}
		</Pressable>
	)
}

const styles = StyleSheet.create({
	shine: {
		position: 'absolute',
		left: 0,
		top: 0,
		bottom: 0,
		width: 100,
		backgroundColor: 'white',
	},
})

export default ShiningButton
