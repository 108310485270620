import { Dimensions } from 'react-native'
import React from 'react'
import { useEffect, useState } from 'react'
import { API, Auth } from 'aws-amplify'
import { getNewprofileByUserIdForProfile } from '../../graphql/custom-queries'
import { getNewprofileByUserId } from '../../graphql/custom-queries'
import useUserStore from '../../Userstore/UserStore'
import WorkExperience from '../../newComponents/editCareer/workExperience/WorkExperienceComponent'
import EducationDetails from '../../newComponents/editCareer/educationDetails/EducationDetails'
import AboutBox from '../../newComponents/aboutBox/AboutBox'
import NewKeyCapabilities from '../../newComponents/keyCapabilities/NewKeyCapabilities'
import CertificateComponent from '../../newComponents/editCareer/certifications/CertificateComponent'
import WhatIDidView from './WhatIDid/WhatIDidView'
import { Box } from '@gluestack-ui/themed'
import ProfileStats from '../../FeedComponents/ProfileStats'
import CapabilityHidiOnProfile from '../../newComponents/CapabilityHidiOnProfile'
import ResumeView from '../../newComponents/ResumeView'
import SpinnerComponent from '../../newComponents/SpinnerComponent'
import CandidatePreferenceView from './preferencesView/CandidatePreferenceView'
import VideoRecorderComponent from '../../newComponents/videoComponent/VideoRecorderComponent'
import VideoViewerComponent from '../../newComponents/videoComponent/VideoViewerComponent'
import SkillsComponent from './SkillsComponent'
import useGlobalCapabilityConfig from '../../customhook/GetGlobalCapability'
import QrusibleWorkExperience from '../opportunities/components/QrusibleWorkExperience'
const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

export default function Profile({ navigation, route, inModal, closeModal }) {
	const [loading, setloading] = useState(true)
	const userId = useUserStore((state) => state.userId)
	const [profile, setProfile] = useState('')
	const [hidis, setHidis] = useState([])
	//   const [user, setUser] = useState('')a
	const setProfileId = useUserStore((state) => state.setProfileId)
	const setDomain = useUserStore((state) => state.setDomain)
	const setHidisState = useUserStore((state) => state.setHidis)
	const setFullName = useUserStore((state) => state.setName)
	const setImageProfile = useUserStore((state) => state.setImage)
	const setImagekeyProfile = useUserStore((state) => state.setImagekey)
	const setUserProfile = useUserStore((state) => state.setUserProfile)
	const signout = useUserStore((state) => state.signOut)
	const setCountCapabailities = useUserStore(
		(state) => state.setCountCapabailities
	)
	const getGlobalCapabilities = useGlobalCapabilityConfig()

	const getSession = async () => {
		try {
			await Auth.currentSession()
		} catch (error) {
			console.log(error)
			signout()
		}
	}
	async function getprofile() {
		await API.graphql({
			query: getNewprofileByUserIdForProfile,
			variables: {
				UserId: userId,
			},
		})
			.then((res) => {
				if (!res.data.getNewprofileByUserId?.items[0]) {
					navigation.push('Onboarding', { stepState: 0 })
				} else {
					setProfile(res.data.getNewprofileByUserId?.items[0])
					setDomain(
						res.data.getNewprofileByUserId?.items[0]
							.CandidatePreferences.items[0]?.userDomain
					)
					setUserProfile(res.data.getNewprofileByUserId?.items[0])
					setProfileId(res.data.getNewprofileByUserId?.items[0]?.id)
					setFullName(res.data.getNewprofileByUserId?.items[0]?.name)

					setImageProfile(
						res.data.getNewprofileByUserId?.items[0]?.image
					)
					setImagekeyProfile(
						res.data.getNewprofileByUserId?.items[0]?.imagekey
					)
					setHidis(
						res.data.getNewprofileByUserId?.items[0]?.hidis.items
					)
					setHidisState(
						res.data.getNewprofileByUserId?.items[0]?.hidis.items
					)
					setCountCapabailities(
						res.data.getNewprofileByUserId?.items[0]?.ConnectedHidis
							?.items
					)

					setloading(false)
				}

				//   getUserData()
			})
			.catch((err) => {
				console.log(err)
			})
	}

	useEffect(() => {
		getSession()
		getprofile()
	}, [navigation])

	const pinnedItems = hidis?.filter((item) => item?.pinnedFlag)
	const nonPinnedItems = hidis?.filter((item) => !item?.pinnedFlag)
	//  const topThreeEntries = nonPinnedItems.slice(0, 2);
	const allData = [...pinnedItems, ...nonPinnedItems]
	// const newData = allData.slice(0, 3)
	const CapabilityClaimed = profile.Capabilities?.items

	const matchingCapabilities = allData?.filter((data) =>
		CapabilityClaimed?.some(
			(cap) =>
				cap.capabilitiesCapabilityConfigId ==
				data?.ConnectedCapability?.items[0]?.Capability.CapabilityConfig
					?.id
		)
	)

	return (
		<Box
			bg="$backgroundGray"
			minHeight={windowHeight - 62}
			marginTop={!inModal && 62}
			marginBottom={windowWidth > 480 ? null : 50}
			paddingHorizontal={windowWidth > 480 ? (inModal ? 30 : 100) : 5}
			paddingVertical={windowWidth > 480 ? 20 : 5}
			paddingBottom={windowWidth > 480 ? null : 50}
		>
			<AboutBox />

			<VideoViewerComponent
				keyParam={`${userId}_video`}
				introVideo={profile?.IntroVideo}
				navigation={navigation}
				screen={'private'}
			/>

			<SkillsComponent
				screen={'private'}
				userId={userId}
				navigation={navigation}
			/>

			<CandidatePreferenceView
			preferences={profile?.CandidatePreferences?.items[0]}
				navigation={navigation}
			/>
			{matchingCapabilities && (
				<Box
					bg="$primary0"
					marginBottom={12}
					p={windowWidth > 480 ? 20 : 10}
					borderWidth={1}
					borderColor="$borderDark0"
					borderRadius={6}
				>
					<CapabilityHidiOnProfile
						UnlockedCapability={matchingCapabilities}
						navigation={navigation}
						capabilitiesClaimedLength={
							CapabilityClaimed?.length +
							matchingCapabilities?.length
						}
						evidences={matchingCapabilities?.length}
						capabilities={CapabilityClaimed}
						capabilitiesClaimed={CapabilityClaimed}
						view={'profile'}
						closeModal={closeModal ? closeModal : () => {}}
					/>
				</Box>
			)}
			{profile.deployedforqrews?.items?.length > 0 &&
			<QrusibleWorkExperience
			userId={userId}
			screen={'private'}
			data={profile}
			navigation={navigation}
		/>
			}
			
			<WorkExperience userId={userId} />
			<EducationDetails userId={userId} />
			<CertificateComponent userId={userId} />
			<ResumeView
				userId={userId}
				screen={'private'}
				navigation={navigation}
			/>
		</Box>
	)
}
