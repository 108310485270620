import React from 'react'
import { Box, Image, Text } from '@gluestack-ui/themed'

const ListEmptyComponent = ({ text, subText }) => {
	return (
		<Box alignItems={'center'} justifyContent={'center'}>
			<Image
				source={require('../assets/Images/listEmpty.png')}
				h={90}
				w={150}
			/>
			<Text fontWeight="600" fontFamily="$fontHeading" textAlign="center">
				{text}
			</Text>
			<Text fontFamily="$fontHeading" textAlign="center">
				{subText}
			</Text>
		</Box>
	)
}

export default ListEmptyComponent
