import { Dimensions } from 'react-native'
import { Box } from '@gluestack-ui/themed'
import React from 'react'
import { useEffect, useState } from 'react'
import { API } from 'aws-amplify'
import { list_JobApplicants_by_companyPreferenceId } from '../../graphql/custom-queries'
import CandidateViewComponent from './components/CandidateViewComponent'

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

export default function JobApplyingCandidateList({
	preferenceId,
	navigation,
	companyName,
	rolename,
	JobPostData
}) {
	const [jobApplyingCandidateList, setJobApplyingCandidateList] = useState([])
	const [loading, setLoading] = useState(true)
	const [nextToken, setNextToken] = useState(null)

	async function getJobApplications(token = null) {
		try {
			const res = await API.graphql({
				query: list_JobApplicants_by_companyPreferenceId,
				variables: {
					CompanyPreferenceId: preferenceId,
					ApplyingStatus: { eq: 'Applying' },
					limit: 25,
					nextToken: token,
				},
			})

			const newData =
				res.data.list_JobApplicants_by_companyPreferenceId.items
			setNextToken(
				res.data.list_JobApplicants_by_companyPreferenceId.nextToken
			)

			setJobApplyingCandidateList([
				...jobApplyingCandidateList,
				...newData,
			])

			setLoading(false)
		} catch (err) {
			console.log(err)
			setLoading(false)
		}
	}

	useEffect(() => {
		getJobApplications()
	}, [])

	const fetchMoreData = () => {
		if (nextToken) {
			getJobApplications(nextToken)
		}
	}

	return (
		<Box>
			<CandidateViewComponent
				loading={loading}
				headingText={'Applying'}
				companyname={companyName}
				navigation={navigation}
				data={jobApplyingCandidateList}
				rolename={rolename}
				onPressBtn={null}
				fetchMoreData={fetchMoreData}
				PreferenceId = {preferenceId}
				listEmptyText={'No candidates are applying yet!'}
				JobPostData ={JobPostData}
			/>
		</Box>
	)
}
