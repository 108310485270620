import {
	StyleSheet,
	Text,
	View,
	FlatList,
	TouchableOpacity,
	Image,
	Dimensions,
	ActivityIndicator,
} from 'react-native'
import { HStack } from 'native-base'
import { API } from 'aws-amplify'
import React from 'react'
import Tag from '../components/tag'
import { listNewhidisByUserIdByDate } from '../graphql/queries'
import { useState, useEffect } from 'react'
// import {
//   BrowserRouter as Router,
//   Switch,
//   Route,
//   Link,
//   useParams
// } from "react-router-dom";

import { listNewhidisByhidiTopic } from '../graphql/queries'

import Editor from 'react-medium-editor'
import OutcomeComponent from '../newComponents/OutcomeComponent'
import useUserStore from '../Userstore/UserStore'
const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height
export default function ServiceNowHidiExplorer({ route, navigation }) {
	const [loading, setloading] = useState(true)
	const group = useUserStore((state) => state.group)
	const [hidis, setHidis] = useState([])
	const userId = useUserStore((state) => state.userId)
	const loggedIn = useUserStore((state) => state.isLoggedIn)
	const profile = useUserStore((state) => state.profile)

	async function gethididata(tag) {
		await API.graphql({
			query: listNewhidisByhidiTopic,
			authMode: 'API_KEY',
			variables: {
				hidiTopic: tag,
			},
		})
			.then((res) => {
				setHidis(res.data.listNewhidisByhidiTopic.items)
				setloading(false)
				// getUserData()
			})
			.catch((err) => {
				console.log(err)
				window.alert('Something went wrong ... we are looking into it')
			})
	}

	useEffect(() => {
		const tag = route.params.HidiTopic
		gethididata(tag.toLowerCase())
	}, [])
	const pinnedItems = hidis.filter((item) => item.pinnedFlag)
	const nonPinnedItems = hidis.filter((item) => !item.pinnedFlag)
	//  const topThreeEntries = nonPinnedItems.slice(0, 2);

	const newData = [...pinnedItems, ...nonPinnedItems]

	const Hidicontainer = ({ item }) => {
		var impactarr = item?.impact
		if (item.impact) {
			impactarr = impactarr?.split('///')
		}

		return (
			<View
				style={{
					...styles.hidicontainer1,
					width: windowWidth <= 480 ? '100%' : '80%',
				}}
			>
				{/* {windowWidth >= 480 && 
              <Image style ={{height: 100, width: 100, position: "absolute", top: 50, right: 20}}
              source={item.imagelink}
              />
              } */}

				<TouchableOpacity
					onPress={() =>
						navigation.push('NewHIDI', {
							hidiId: item.id,
							companyname: route.params.companyname,
							PreferenceId: route.params.PreferenceId,
							rolename: route.params.rolename,
							Shortlisted: route.params.Shortlisted,
							profilename: route.params.profilename,
							Hidilist: 'HidiList',
						})
					}
					style={{ ...styles.hidicontainer2, borderColor: '#F1F1F1' }}
				>
					{item.pinnedFlag && (
						<Image
							resizeMode="contain"
							style={{
								height: 30,
								width: 80,
								marginTop: 20,
								marginLeft: 20,
							}}
							source={require('../assets/Images/pinned.png')}
						/>
					)}

					<View style={{ flexDirection: 'row', width: '100%' }}>
						<View
							style={{
								padding: 15,
								width: windowWidth < 480 ? '100%' : '85%',
							}}
						>
							<View style={{ flexDirection: 'row' }}>
								<Text style={styles.title}>{item.title}</Text>
								{item.htmlheading && (
									<Text style={styles.title}>
										{item.htmlheading}
									</Text>
								)}

								{/* <Image style ={styles.shieldimage}
         source={require("../assets/Images/sheild.png")}/> */}
							</View>
							<HStack
								style={{ flexWrap: 'wrap', marginBottom: 5 }}
							>
								{item.tag1 && <Tag props={item.tag1} />}
								{item.tag2 && <Tag props={item.tag2} />}
							</HStack>
							<Text style={styles.hashtagtext}>
								{item.summary}
							</Text>
							<OutcomeComponent props={item.outcomes} />
							{item.impact && (
								<View style={styles.impactcontainer}>
									{impactarr.map((item) => {
										const arr = item.split('~//~')
										return (
											<>
												{arr[1] == '1' && (
													<View
														style={{
															flexDirection:
																'row',
															marginTop: 2,
														}}
													>
														<Text
															style={
																styles.impacttext
															}
														>
															{arr[0]}
														</Text>
														<Image
															style={{
																height: 16,
																width: 16,
															}}
															source={require('../assets/Images/greycheck.png')}
														/>
													</View>
												)}
												{arr[1] == '2' && (
													<View
														style={{
															flexDirection:
																'row',
															marginTop: 2,
														}}
													>
														<Text
															style={
																styles.impacttext
															}
														>
															{arr[0]}
														</Text>
														<Image
															style={{
																height: 16,
																width: 16,
															}}
															source={require('../assets/Images/yellowcheck.png')}
														/>
													</View>
												)}
												{arr[1] == '3' && (
													<View
														style={{
															flexDirection:
																'row',
															marginTop: 2,
														}}
													>
														<Text
															style={
																styles.impacttext
															}
														>
															{arr[0]}
														</Text>
														<Image
															style={{
																height: 16,
																width: 16,
															}}
															source={require('../assets/Images/greencheck.png')}
														/>
													</View>
												)}
											</>
										)
									})}
									{item.impactOverall && (
										<Text style={styles.impactgreytext}>
											{item.impactOverall}
										</Text>
									)}
								</View>
							)}
							<View
								style={{
									width:
										windowWidth < 480
											? windowWidth * 0.8
											: windowWidth * 0.6,
									height: 1,
									backgroundColor: '#DEE1E7',
									marginTop: 15,
								}}
							/>
						</View>
						{windowWidth >= 480 && (
							<Image
								style={{
									height: 90,
									width: 90,
									alignSelf: 'center',
								}}
								source={item.imagelink}
							/>
						)}
					</View>

					<View style={{ paddingHorizontal: 15 }}>
						{item.steps && (
							<HStack
								style={{
									justifyContent: 'space-between',
									paddingBottom: 5,
								}}
							>
								<Text
									style={{
										color: '#AAAAAA',
										fontFamily: 'Open sans',
									}}
								>
									{item.steps} steps to Outcome
								</Text>
								{/* <Text style ={{color: "#AAAAAA"}}>
               11 minutes Read
           </Text> */}
							</HStack>
						)}

						<HStack
							style={{
								paddingVertical: 5,
								justifyContent: 'space-between',
							}}
						>
							<HStack>
								{route.params.UserId == userId &&
									item.htmlsummary && (
										<>
											<TouchableOpacity
												onPress={() =>
													navigation.navigate(
														'HidiEdit',
														{ hidiId: item.id }
													)
												}
											>
												<Text
													style={{
														...styles.readmoretext,
														color: 'red',
													}}
												>
													{' '}
													Edit Hidi
												</Text>
											</TouchableOpacity>
										</>
									)}
							</HStack>
							<TouchableOpacity
								onPress={() =>
									navigation.push('NewHIDI', {
										hidiId: item.id,
										companyname: route.params.companyname,
										PreferenceId: route.params.PreferenceId,
										rolename: route.params.rolename,
										Shortlisted: route.params.Shortlisted,
										profilename: route.params.profilename,
										Hidilist: 'HidiList',
									})
								}
							>
								<Text style={styles.readmoretext}>
									Read More →
								</Text>
							</TouchableOpacity>
						</HStack>
					</View>
				</TouchableOpacity>
			</View>
		)
	}
	return (
		<View>
			{loading ? (
				<ActivityIndicator />
			) : (
				<>
					<View style={{ width: '90%', alignSelf: 'center' }}>
						<FlatList
							data={newData}
							renderItem={Hidicontainer}
							ListEmptyComponent={() => {
								return (
									<View
										style={{
											alignSelf: 'center',
											marginTop: 20,
										}}
									>
										<Text
											style={{
												fontFamily: 'Lexend',
												fontSize: 20,
											}}
										>
											No hidis are available on this topic
										</Text>
									</View>
								)
							}}
							// stickyHeaderIndices={stickyHeaderIndices}
							keyExtractor={(item) => item.id.toString()}
						/>
					</View>
				</>
			)}
		</View>
	)
}

const styles = StyleSheet.create({
	hidicontainer1: {
		// height: windowHeight*0.22,
		justifyContent: 'center',
		alignItems: 'center',
		marginLeft: -1,
		paddingVertical: 20,
		alignSelf: 'center',
	},
	hidicontainer2: {
		width: '99%',
		// height: "80%",
		borderWidth: 2,
		borderRadius: 15,
		borderColor: '#F1F1F1',
	},
	title: {
		fontWeight: 'bold',
		fontSize: 18,
		marginTop: 4,
		marginBottom: 8,
		fontFamily: 'Lexend',
		color: '#293D5B',
		// borderWidth: 1
		// marginLeft: 20,
		// padding: 10
	},

	hashtagtext: {
		fontWeight: '300',
		fontSize: 14,
		fontFamily: 'Open Sans',
		color: 'rgba(91, 91, 91, 0.7)',
		// paddingVertical: 5
		// padding: 10
	},
	Hidi: {
		// padding: 10,
		fontWeight: '800',
		// fontSize: 20,
		// lineHeight: 12,
		fontSize: 18,
		letterSpacing: 1,
		color: 'grey',

		// marginTop: 20
	},
	analysis: {
		fontSize: 18,
		fontWeight: '800',
		color: 'grey',
		// padding: 10,
	},
	outcome: {
		// fontSize: 14,
		color: 'grey',
		marginTop: 5,
		fontWeight: '400',
	},
	analysiscircle: {
		height: windowWidth * 0.13,
		width: windowWidth * 0.13,
		borderRadius: (windowWidth * 0.13) / 2,
		borderColor: '#2BC48A',
		borderWidth: 1,
	},

	profiletext: {
		fontWeight: '500',
		fontSize: 20,
		textAlign: 'center',
		marginLeft: '40%',
	},
	subcontainer: {
		width: '80%',
		alignSelf: 'center',
		marginTop: 20,
		flexDirection: 'row',
		justifyContent: 'space-between',
		// height: 40,
		// borderWidth: 1,
		// backgroundColor: "grey"
	},
	subcontainer2: {
		width: '90%',
		alignSelf: 'center',
		marginTop: 10,
		marginBottom: 15,
		// height: 40,
		// borderWidth: 1,
		// backgroundColor: "grey"
	},
	shieldimage: {
		height: 18,
		width: 18,
		marginTop: 5,
		marginLeft: 3,
	},
	readmoretext: {
		color: 'blue',
		alignSelf: 'flex-end',
		fontFamily: 'Open Sans Bold 700',
		fontWeight: '700',
		//   padding: 10
	},
	impacttext: {
		fontWeight: '900',
		fontFamily: 'Open Sans Bold 700',
		fontSize: 16,
		lineHeight: 18,
		// marginVerti/cal: 2
	},
	impactcontainer: {
		marginTop: 10,
	},
	impactgreytext: {
		fontFamily: 'Open Sans',
		color: 'rgba(91, 91, 91, 0.7)',
		marginTop: 10,
	},
	breadcrumps: {
		fontSize: 16,
		fontFamily: 'Lexend',
		fontWeight: '900',
	},
})
