import React, { useState, useRef } from 'react'
import { Dimensions } from 'react-native'
import {
	Box,
	Divider,
	FlatList,
	HStack,
	Text,
	Pressable,
	EditIcon,
	CloseIcon,
	CheckIcon,
	ClockIcon,
	AddIcon
} from '@gluestack-ui/themed'
// import AccordionComponent from '../AccordionComponent'
import QrewAccordionComponent from '../../qrewComponents/QrewAccordionComponent'
import CandidateQrewInitiativesList from './CandidateQrewInitiativeList'
import { createObjectiveEdit, listQrewObjectiveKeyResultsbyObjectiveIdAccordian, createQrewInitiative,
	updateQrewObjectives, } from '../../HeplerFunctions/CandidateQrewHelperQuery'
import { useEffect } from 'react'

import ListEmptyComponent from '../../qrewComponents/ListEmptyComponent'

import SpinnerComponent from '../SpinnerComponent'
import LabelWithInputComponent from '../LabelWithInputComponent'
import ModalComponent from '../ModalComponent'
import ButtonComponent from '../ButtonComponent'

// import SideViewOpenerAnimated from '../../Contractors/components/SideViewOpenerAnimated'
import useUserStore from '../../Userstore/UserStore'
import { onUpdateObjectiveKeyResult, onDeleteInitiative } from '../../graphql/custom-subscriptions'


import ProgressBarQrew from '../../qrewComponents/ProgressBarQrew'
import { API, graphqlOperation } from 'aws-amplify'
import { allowedNodeEnvironmentFlags } from 'process'
import { showToast } from '../../components/ErrorToast'
const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

const CandidateQrewObjectiveKeyResultsList = ({ objectiveId }) => {
	const [objectiveDATA, setObjectiveDATA] = useState([])
	// const companyProfileId = useUserStore((state) => state.companyProfileId)
	const [loadingObjectives, setLoadingObjectives] = useState(false)
	const [nextId, setNextId] = useState(2)
	const [showEditButton, setShowEditButton] = useState('')
	const [CaptureChanges, setCaptureChanges] = useState(false)
	const [loadingBtn, setLoadingBtn] = useState(false)
	const [keyResultIdforInitiative, setkeyResultIdforInitiative] = useState('')
    const [NewInitiativeInput, setNewInitiativeinput] = useState('')
	const [NewInitiativeInputDescription, setNewInitiativeinputDescription] = useState('')
	const [InitativeTime, setInitiativeTime] = useState('')
    const [Initiativekeyresults, setInitiativeKeyResult] = useState('')
	const [createInitativeModal, setCreateInitativeModal] = useState(false)
	const [editInput, setEditInput] = useState('')
	const [loadingConfirmEdit, setLoadingConfirmEdit] = useState(false)
	const [showSideView, setShowSideView] = useState(null)
	const [selectedObjective, setSelectedObjective] = useState(null)
	const [selectedObjectiveTitle, setSelectedObjectiveTitle] = useState('')
	const UserProfileId = useUserStore(state => state.profileId)
	const userProfile =useUserStore(state => state.profile)
	const listObjectivesKeyResults = async () => {
		setLoadingObjectives(true)
		const response = await listQrewObjectiveKeyResultsbyObjectiveIdAccordian(objectiveId)
       
		setObjectiveDATA(response)
		setLoadingObjectives(false)
	}
	const listObjectivesKeyResultsWithoutLoading = async () => {
		
		const response = await listQrewObjectiveKeyResultsbyObjectiveIdAccordian(objectiveId)
		setObjectiveDATA(response)
		
	}
	const initSubscriptions = async () => {
		let _subscriptions = [];
		  const subscription = API.graphql(
			graphqlOperation(
			  onDeleteInitiative
			  
			),
		  ).subscribe({
			next: ({provider, value}) => {
			  
				listObjectivesKeyResultsWithoutLoading()
			  
		  
			  // setIsParticipant(true);
			},
			error: error => {
				console.log(error)
			  // setConnectionStatus(false);
			  console.warn(error.error);
			},
		  });
		
		return;
	  };

	  useEffect(() =>{
		initSubscriptions()
	   }, [])
	
	const CreateInitiativeForQrew = async (item) => {
		
	if(NewInitiativeInput){
		try {
			setLoadingConfirmEdit(true)
		
			 
		
			const InitativeInput = {
				initiative : NewInitiativeInput,
				keyResultsAcheived : Initiativekeyresults,
				deployedQrewId : userProfile.deployedforqrews.items[0].id,
				
				companyProfileId : keyResultIdforInitiative.companyProfileId,
				candidateProfileId : UserProfileId,
				companyQrewId : keyResultIdforInitiative.companyQrewId,
				objectiveKeyResultId : keyResultIdforInitiative.id,
				
				objectiveId : keyResultIdforInitiative.objectiveId,
				approvalStatus : 'REQUESTED',
				TimeRequiredToComplete : InitativeTime ? InitativeTime : 0,
				initiativeDocumentation : NewInitiativeInputDescription
			
			
			}

			const resEdit = await createQrewInitiative(InitativeInput)
			if(resEdit) {
				showToast({
					message :'Your Initiative is Created Successfully',
					type : 'success'
				})
			}
			
		} catch (err) {
			console.log('Error updating objective', err)
		} finally {
			setCreateInitativeModal(false)
			setLoadingConfirmEdit(false)
			setNewInitiativeinput('')
			setNewInitiativeinputDescription('')
			setInitiativeKeyResult('')
			setkeyResultIdforInitiative('')
			listObjectivesKeyResultsWithoutLoading()
		}
	}else{
		showToast({
			message : 'Pls fill mandatory fields',
			type : 'error'
		})
	}
		
		
	}

	useEffect(() => {
		listObjectivesKeyResults()
	}, [])

	const renderObjectivesAccordion = ({ item }) => {
		const HoverButtonComponent = () => {
			return(
				<>
				{showEditButton === item.id ? (
					<HStack marginLeft={10}>
						<Pressable
						borderWidth={0.5}
						borderRadius={10}
						padding={4}
						borderColor='#1C91F2'
						
					   
							onPress={() => {
								
								// setEditInput(item.title)
								setCreateInitativeModal(true)
								setkeyResultIdforInitiative(item)
							}}
						>
							<Text
							fontFamily="$fontHeading"
							fontSize={10}
							// lineHeight={50}
							fontWeight="600"
							color={'#1C91F2'}
							>
								ADD INITIATIVE
							</Text>
						</Pressable>
						{/* <Pressable
							onPress={() => {
								setShowSideView(
									'ObjectiveHistory'
								)
								setSelectedObjective(item)
	
								setSelectedObjectiveTitle(
									item.title
								)
							}}
						>
							<ClockIcon />
						</Pressable> */}
					</HStack>
				) :(
					<HStack marginLeft={10}>
							<Pressable
						borderWidth={0.5}
						borderRadius={10}
						padding={4}
						borderColor='white'
						
					 
							
						>
					<Text
					fontFamily="$fontHeading"
					fontSize={10}
					// lineHeight={50}
					fontWeight="600"
					color={'white'}
					>
						#ADD INITIATIVE
					</Text>
	
						</Pressable>
				
					</HStack>
					)}
				</>
				
			)
		}
		const renderTopOfAccordion = () => {
			return (
                <>
                </>
				// <HStack mt={10} gap={15} alignItems="center" marginLeft={30}>
				// 	<Box gap={5}>
				// 		<Text
				// 			fontFamily="$fontHeading"
				// 			fontSize={15}
				// 			fontWeight="700"
				// 			color="grey"
				// 		>
				// 			Completed Initiatives
				// 		</Text>
				// 		<Text
				// 			fontFamily="$fontHeading"
				// 			fontSize={14}
				// 			fontWeight="500"
				// 			color="#4F7396"
				// 		>
				// 			{item?.initiatives?.items?.length}
				// 		</Text>
				// 	</Box>
					
				// </HStack>
			)
		}
		const renderAccordionItem = () => {
			return (
				<Box marginLeft={30}>
					<CandidateQrewInitiativesList
						objectiveKeyResultId={item.id}
					/>
				</Box>
			)
		}

		const renderInitativeCreationModal = () => {
			return (
				<Box p={windowWidth > 480 ? 30 : 15} >
					<Text
						fontSize={18}
						fontWeight="600"
						fontFamily="$fontHeading"
					>
						Create New Initiative for the following Key Result
					</Text>
                    <Text
						fontSize={14}
						fontWeight="600"
						fontFamily="$fontHeading"
					>
						Key Result : {item.keyResult}
					</Text>
                    <LabelWithInputComponent
					label={'Initiative'}
					textArea={true}
					heightTextArea={40}
					placeholder={
						'Explain your initiative.'
					}
					setValueChange={setNewInitiativeinput}
					important={true}
					value={NewInitiativeInput}
				/>
					
					<LabelWithInputComponent
					label={'Acheived key reuslts'}
					textArea={true}
					heightTextArea={70}
					placeholder={
						'Write your Achieved Key Results Here.'
					}
					setValueChange={setInitiativeKeyResult}
					important={false}
					value={Initiativekeyresults}
				/>
		
					<LabelWithInputComponent
					label={'How did you do it'}
					textArea={true}
					heightTextArea={100}
					placeholder={
						'Explain in Details.'
					}
					setValueChange={setNewInitiativeinputDescription}
					important={false}
					value={NewInitiativeInputDescription}
				/>
			
				<Text
						fontSize={14}
						fontWeight="600"
						fontFamily="$fontHeading"
					>
					Hours Required to complete the Initative
					</Text>
					<LabelWithInputComponent
					label={'Hours Required to Complete'}
					textArea={true}
					heightTextArea={40}
					placeholder={
						'Enter Hours Required.'
					}
                    setValueChange={(text) => {
						setInitiativeTime(text.replace(/[^0-9]/g, ''))
					}}
					important={false}
					value={InitativeTime}
				/>
                <Box>
        {/* const ModalBodyRender = () => {
	return( */}
	
	{/* ) */}
{/* } */}
                </Box>
					<HStack justifyContent="flex-end" gap={10}>
						<ButtonComponent
							text={'Cancel'}
							color={'$secondary500'}
							borderColor={'$secondary500'}
							onPress={() => {
								setCreateInitativeModal(false)
								setkeyResultIdforInitiative('')
							}}
							width={120}
						/>
						<ButtonComponent
							text={'Confirm'}
							onPress={() => {
								CreateInitiativeForQrew(item)
							}}
							width={120}
							disabled={loadingConfirmEdit}
							loading={loadingConfirmEdit}
						/>
					</HStack>
				</Box>
			)
		}

		const renderTitle = () => {
            
			return (
				<Box
					flex={1}
					mb={5}
					marginLeft={20}
                
					borderColor="#E5E8EB"
					

				>
					{keyResultIdforInitiative.id === item.id ? (
						<HStack alignItems="center" gap={10} mr={10} justifyContent= 'space-between'>
							<ModalComponent
								isOpen={createInitativeModal}
								onClose={() => {
									setCreateInitativeModal(false)
								setkeyResultIdforInitiative('')
								}}
								renderBody={renderInitativeCreationModal}
							/>
                            <Text
									fontFamily="$fontHeading"
									fontSize={15}
									lineHeight={50}
									fontWeight="600"
									color={'grey'}
								>
									{item.keyResult}
								</Text>
							{/* <Box flex={1}>
								<LabelWithInputComponent
									value={editInput}
									setValueChange={setEditInput}
								/>
							</Box>
							<Pressable
								p={5}
								bg="$secondary500"
								borderRadius={30}
								onPress={() => setkeyResultIdforInitiative('')}
							>
								<CloseIcon color={'white'} size={'lg'} />
							</Pressable>
							<Pressable
								p={5}
								bg="$green400"
								borderRadius={30}
								onPress={() => {
									setCreateInitativeModal(true)
								}}
							>
								<CheckIcon color={'white'} size={'lg'} />
							</Pressable> */}
                           
						</HStack>
					) : (
						<div
							// onMouseEnter={() => {
							// 	setShowEditButton(item.id)
							// }}
							// onMouseLeave={() => {
							// 	setShowEditButton('')
							// }}
						>
                            <HStack  gap={10} justifyContent='space-between'>
                            <HStack alignItems="center"   >
							<HStack
								gap={8}
								justifyContent='center' alignItems='center'>
								<Box  borderRadius={15}  
								bgColor='#F5C02B'
								justifyContent='center'
								alignContent='center'
								alignItems='center'
								marginHorizontal={10}
								padding={5}
							 >
								<Text fontFamily="$fontHeading"
									fontSize={10}
									// lineHeight={60}
									alignSelf='center'
									fontWeight="800"
									
									color={'white'} >
									KEY RESULT
								</Text>
								</Box>
								<Text
									fontFamily="$fontHeading"
									fontSize={15}
									// lineHeight={50}
									fontWeight="600"
									color={'grey'}
								>
									{item.keyResult}
								</Text>
								</HStack>
                                
                             
								
                             
                                
                             
                                
							</HStack>
                            <Box marginRight={10}>
                               <ProgressBarQrew percentageValue = {item.progresssPercentage} />
                               </Box>
                            </HStack>
							
						</div>
					)}
				</Box>
			)
		}

		return (
			<Box bg="white"  >
				<div
							onMouseEnter={() => {
								setShowEditButton(item.id)
							}}
							onMouseLeave={() => {
								setShowEditButton('')
							}}
						>
							
<QrewAccordionComponent
					renderHoverComponent = {HoverButtonComponent}
					renderItem={renderAccordionItem}
					renderTop={renderTopOfAccordion}
					renderTitle={renderTitle}
					toggleActive={!keyResultIdforInitiative}
					InitiativeData={item?.initiatives?.items}
				/>
				
						</div>
						
						
				
				
			</Box>
		)
	}

	return (
		<Box>
			{showSideView && (
				<Box
					position="fixed"
					bg="black"
					top={0}
					bottom={0}
					opacity={0.4}
					right={0}
					h={windowHeight}
					w={windowWidth}
					zIndex={10000}
				/>
			)}
			{/* <SideViewOpenerAnimated
				showSideView={showSideView}
				setShowSideView={setShowSideView}
				objectiveHistory={selectedObjective}
				setObjectiveHistory={setSelectedObjective}
				objectiveTitle={selectedObjectiveTitle}
			/> */}
			{loadingObjectives ? (
				<Box
					h={200}
					alignItems="center"
					justifyContent="center"
					bg="white"
				>
					<SpinnerComponent />
				</Box>
			) : (
				<FlatList
					data={objectiveDATA}
					renderItem={renderObjectivesAccordion}
					keyExtractor={(item) => item.id.toString()}
					ListEmptyComponent={() => {
						return (
							<Box
								bg="white"
								h={200}
								alignItems="center"
								justifyContent="center"
							>
								<ListEmptyComponent
									text={'No Key Results Yet'}
									subText={
										'If there are any Key Reuslts, they will appear here.'
									}
								/>
							</Box>
						)
					}}
				/>
			)}
		</Box>
	)
}

export default CandidateQrewObjectiveKeyResultsList
