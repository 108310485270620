import React from 'react'
import { Box, HStack, Image, Pressable, Text } from '@gluestack-ui/themed'
import { Dimensions } from 'react-native'

const windowWidth = Dimensions.get('window').width

const TopBoxJD = ({
	companyName,
	jobTitle,
	jobType,
	modeOfWork,
	logoUrl,
	onPressTtitle,
}) => {
	return (
		<HStack paddingVertical={10} gap={15} w={'100%'}>
			<Box>
				<Image
					source={{ uri: logoUrl }}
					h={windowWidth > 480 ? 80 : 40}
					w={windowWidth > 480 ? 80 : 40}
					alt="company logo"
					borderRadius={6}
					defaultSource={require('../../../assets/Images/experience.png')}
				/>
			</Box>
			<Box gap={5}>
				<Pressable onPress={onPressTtitle}>
					<Text
						fontFamily="$fontHeading"
						fontWeight="900"
						fontSize={windowWidth > 480 ? 25 : 18}
						color={'$primary950'}
						lineHeight={windowWidth > 480 ? 30 : 20}
					>
						{jobTitle}
					</Text>
					<Text
						fontFamily="$fontHeading"
						fontWeight="500"
						fontSize={
							windowWidth > 480
								? '$fontContent'
								: '$fontContentMobile'
						}
						color={'#4A789C'}
						lineHeight={windowWidth > 480 ? 20 : 15}
					>
						{companyName}
					</Text>
				</Pressable>
				<HStack>
					<Text
						fontFamily="$fontHeading"
						fontWeight="500"
						fontSize={
							windowWidth > 480
								? '$fontContent'
								: '$fontContentMobile'
						}
						color={'#4A789C'}
						lineHeight={windowWidth > 480 ? 20 : 15}
					>
						{jobType}
						{' · '}
					</Text>
					<Text
						fontFamily="$fontHeading"
						fontWeight="500"
						fontSize={
							windowWidth > 480
								? '$fontContent'
								: '$fontContentMobile'
						}
						color={'#4A789C'}
						lineHeight={windowWidth > 480 ? 20 : 15}
					>
						{modeOfWork}
					</Text>
				</HStack>
			</Box>
		</HStack>
	)
}

export default TopBoxJD
