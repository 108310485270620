import {
	HStack,
	VStack,
	Box,
	FlatList,
	Text,
	Icon,
	TrashIcon,
	Pressable,
} from '@gluestack-ui/themed'
import React, { useState } from 'react'
import { Dimensions } from 'react-native'
import SpinnerComponent from './SpinnerComponent'
import DeleteConfirmationModal from './DeleteConfirmationModal'

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

const OutcomeComponent = ({
	props,
	limit,
	screen,
	deleteOutcome,
	loading,
	setLoading,
}) => {
	const [deleteModal, setDeleteModal] = useState(false)
	const OutcomesRender = ({ item, index }) => {
		const outcomeNumber = index + 1

		return (
			<Box>
				<HStack
					gap={5}
					marginBottom={windowWidth > 480 ? 7 : 2}
					alignItems="flex-start"
				>
					<Box alignItems="center">
						<Text
							fontFamily={'$fontContent'}
							fontSize={
								windowWidth > 480
									? '$fontContent'
									: '$fontContentMobile'
							}
							fontWeight="bold"
							textAlign="center"
						>
							{outcomeNumber}.
						</Text>
					</Box>
					<HStack
						flexWrap="wrap"
						flex={1}
						// borderWidth={1}
						mt={2}
					>
						<Text
							fontFamily={'$fontContent'}
							fontSize={
								windowWidth > 480
									? '$fontContent'
									: '$fontContentMobile'
							}
							lineHeight={windowWidth > 480 ? '$md' : '$2xs'}
							fontWeight="bold"
							flexWrap="wrap"
							flex={1}
						>
							{item?.Outcome}
						</Text>
						{screen === 'edit' && (
							<>
								<DeleteConfirmationModal
									isOpen={deleteModal}
									onClose={() => {
										setDeleteModal(false)
										setLoading('')
									}}
									onDelete={async () => {
										setLoading(item.id)
										await deleteOutcome(item.id)
										setDeleteModal(false)
									}}
									loading={loading === item?.id}
								/>
								<Pressable
									onPress={() => {
										setDeleteModal(true)
									}}
									marginHorizontal={10}
									bg="$secondary200"
									h={25}
									p={2}
									borderRadius={6}
								>
									<Icon
										h={20}
										w={20}
										as={TrashIcon}
										color={'$secondary500'}
									/>
								</Pressable>
							</>
						)}
					</HStack>
				</HStack>
			</Box>
		)
	}

	const limitedData = props.items.slice(0, limit)

	return (
		<Box>
			<FlatList
				data={screen === 'about' ? limitedData : props?.items}
				renderItem={OutcomesRender}
				keyExtractor={(item) => item?.id}
			/>
			<Box>
				{screen === 'about' && (
					<Text
						color="$primary500"
						fontFamily="$fontContent"
						fontSize={windowWidth > 480 ? '$fontSize12' : 10}
						fontWeight="700"
					>
						Read more...
					</Text>
				)}
			</Box>
		</Box>
	)
}

export default OutcomeComponent
