import React, { useEffect, useState } from 'react'
import {
	Box,
	Heading,
	Icon,
	Text,
	Pressable,
	ArrowLeftIcon,
	ScrollView,
} from '@gluestack-ui/themed'
import { Dimensions } from 'react-native'
import HIdidetails from '../../../newComponents/HIdidetails'
import ButtonComponent from '../../../newComponents/ButtonComponent'
import { getNewhidi } from '../../../graphql/custom-queries'
import useUserStore from '../../../Userstore/UserStore'
import { API } from 'aws-amplify'
import SpinnerComponent from '../../../newComponents/SpinnerComponent'
const windowHeight = Dimensions.get('window').height
const windowWidth = Dimensions.get('window').width

const PreviewHidi = ({
	hack,
	challenge,
	experience,
	projectLink,
	peers,
	helped,
	problem,

	setTab,
	navigation,
	profile,
	capabilityName,
	loadingHidi,
	saveDetails,
	setHack,
	setExperience,
	jobDomain,
}) => {
	const [loading, setLoading] = useState(false)

	const [hackExample, setHackExample] = useState('')
	const [challengeExample, setChallengeExample] = useState('')
	const [experienceExample, setExperienceExample] = useState('')
	const [projectLinkExample, setProjectLinkExample] = useState('')
	const [peersExample, setPeersExample] = useState('')
	const [helpedExample, setHelpedLinkExample] = useState('')
	const [problemExample, setProblemExample] = useState('')

	const data = [
		{
			domain: 'fullstack',
			hidiId: '68672f08-5217-4457-83a6-f5c257efc47b',
		},
		{
			domain: 'cybersecurity',
			hidiId: '4acafafa-a625-4b78-bce9-f430b234c0b3',
		},
		{
			domain: 'hr',
			hidiId: '68672f08-5217-4457-83a6-f5c257efc47b',
		},
		{
			domain: 'content',
			hidiId: '4acafafa-a625-4b78-bce9-f430b234c0b3',
		},
		{
			domain: 'ai',
			hidiId: '68672f08-5217-4457-83a6-f5c257efc47b',
		},
	]

	const selectedData = data.find((item) => item.domain === jobDomain)

	const gethidi = async () => {
		setLoading(true)
		await API.graphql({
			query: getNewhidi,
			variables: {
				id: selectedData.hidiId,
			},
		})
			.then((res) => {
				setLoading(false)
				const responseHidi = res?.data?.getNewhidi

				setHackExample(responseHidi?.Hack)
				setChallengeExample(
					responseHidi?.challenges?.items.length > 1
						? responseHidi?.challenges?.items[0]?.challenge
						: null
				)
				setExperienceExample(responseHidi?.experience)
				setHelpedLinkExample(responseHidi?.helped)
				setProjectLinkExample(responseHidi?.projectLink)
				setPeersExample(responseHidi?.peerEmails)
				setProblemExample(responseHidi?.problem)
			})
			.catch((error) => {
				setLoading(false)
				console.log('Gethidi error', error)
			})
	}

	useEffect(() => {
		gethidi()
	}, [jobDomain])

	return (
		<>
			{false ? ( // actual condition: problem === ''
				false ? ( // actual condition: loading
					<Box
						justifyContent="center"
						borderRadius={6}
						borderWidth={1}
						shadowColor="black"
						shadowOpacity={0.15}
						shadowRadius={20}
						elevation={15}
						borderColor="$borderGrey100"
						flex={1}
						marginHorizontal={windowWidth > 480 ? 30 : 0}
					>
						<SpinnerComponent button={true} />
					</Box>
				) : (
					<Box
						justifyContent="center"
						borderRadius={6}
						borderWidth={1}
						shadowColor="black"
						shadowOpacity={0.15}
						shadowRadius={20}
						elevation={15}
						borderColor="$borderGrey100"
						marginHorizontal={windowWidth > 480 ? 30 : 0}
					>
						<Box
							paddingHorizontal={windowWidth > 480 ? 25 : 10}
							paddingVertical={windowWidth > 480 ? 10 : 10}
							borderTopLeftRadius={6}
							borderTopRightRadius={6}
							// marginBottom={10}
							justifyContent="center"
							h={'fit-content'}
							bg={'#DDE7F1'}
						>
							<Heading fontFamily="$fontHeading" lineHeight={25}>
								Example View
							</Heading>
							<Text fontFamily="$fontHeading" lineHeight={15}>
								This is an example to show how your colleagues
								are responding to these questions in order to
								get selected
							</Text>
						</Box>
						<ScrollView
							maxHeight={
								windowWidth > 480
									? windowHeight * 0.65
									: windowHeight * 0.5
							}
							minHeight={
								windowWidth > 480
									? windowHeight * 0.65
									: windowHeight * 0.5
							}
							h={'fit-content'}
							showsHorizontalScrollIndicator={true}
							marginHorizontal={windowWidth > 480 ? 5 : 5}
						>
							<HIdidetails
								props={[
									hackExample,
									challengeExample,
									experienceExample,
									projectLinkExample,
									peersExample,
									helpedExample,
								]}
								setTab={setTab}
								navigation={navigation}
								inModal={true}
								exampleView={true}
								profile={profile}
								outcomePreview={problemExample}
								capabilityName={capabilityName}
								setHack={setHack}
								setExperience={setExperience}
							/>
						</ScrollView>
						<Box
							borderBottomLeftRadius={6}
							borderBottomRightRadius={6}
							bg="$secondary100"
							paddingHorizontal={windowWidth > 480 ? 30 : 10}
						>
							<Text
								marginVertical={15}
								fontFamily="$fontHeading"
								textAlign="center"
								color="$fontGray"
								fontWeight="500"
							>
								Disclaimer: AI Checker activated. Profiles
								containing text generated using tools like
								ChatGPT, BARD, Grok, or any other AI
								applications will be flagged for review.
							</Text>
						</Box>

						{windowWidth > 480 ? null : (
							<Box
								alignItems="center"
								justifyContent="center"
								gap={windowWidth > 480 ? 20 : 10}
								marginVertical={25}
							>
								<ButtonComponent
									text={'Submit'}
									onPress={() => {
										saveDetails()
									}}
									loading={loadingHidi}
									width={windowWidth > 480 ? 200 : '80%'}
								/>
								<Pressable
									flexDirection="row"
									alignItems="center"
									gap={5}
									onPress={() => {
										setTab('Peers')
									}}
									alignSelf="center"
								>
									<Icon
										as={ArrowLeftIcon}
										size={'xs'}
										color={'$primary500'}
									/>
									<Text
										fontFamily="$fontHeading"
										fontSize={12}
										color={'$primary500'}
										fontWeight="bold"
									>
										Back
									</Text>
								</Pressable>
							</Box>
						)}
					</Box>
				)
			) : (
				<Box
					justifyContent="center"
					borderRadius={6}
					borderWidth={1}
					shadowColor="black"
					shadowOpacity={0.15}
					shadowRadius={20}
					elevation={15}
					borderColor="$borderGrey100"
					marginHorizontal={windowWidth > 480 ? 30 : 0}
				>
					<Box
						paddingHorizontal={windowWidth > 480 ? 25 : 10}
						paddingVertical={windowWidth > 480 ? 10 : 10}
						borderTopLeftRadius={6}
						borderTopRightRadius={6}
						// marginBottom={10}
						justifyContent="center"
						h={'fit-content'}
						bg={'#DDE7F1'}
					>
						<Heading fontFamily="$fontHeading" lineHeight={25}>
							Recruiter View
						</Heading>
						<Text fontFamily="$fontHeading" lineHeight={15}>
							This is what a recuiter will see on your profile
						</Text>
					</Box>
					<ScrollView
						// justifyContent="center"
						maxHeight={
							windowWidth > 480
								? windowHeight * 0.65
								: windowHeight * 0.5
						}
						minHeight={
							windowWidth > 480
								? windowHeight * 0.65
								: windowHeight * 0.5
						}
						h={'fit-content'}
						showsHorizontalScrollIndicator={true}
						marginHorizontal={windowWidth > 480 ? 5 : 5}
					>
						<HIdidetails
							props={[
								hack,
								challenge,
								experience,
								projectLink,
								peers,
								helped,
							]}
							setTab={setTab}
							navigation={navigation}
							inModal={true}
							profile={profile}
							outcomePreview={problem}
							capabilityName={capabilityName}
							setHack={setHack}
							setExperience={setExperience}
						/>
					</ScrollView>
					<Box
						borderBottomLeftRadius={6}
						borderBottomRightRadius={6}
						bg="$secondary100"
						paddingHorizontal={windowWidth > 480 ? 30 : 10}
					>
						<Text
							marginVertical={15}
							fontFamily="$fontHeading"
							textAlign="center"
							color="$fontGray"
							fontWeight="500"
						>
							Disclaimer: AI Checker activated. Profiles
							containing text generated using tools like ChatGPT,
							BARD, Grok, or any other AI applications will be
							flagged for review.
						</Text>
					</Box>

					{windowWidth > 480 ? null : (
						<Box
							alignItems="center"
							justifyContent="center"
							gap={windowWidth > 480 ? 20 : 10}
							marginVertical={25}
						>
							<ButtonComponent
								text={'Submit'}
								onPress={() => {
									saveDetails()
								}}
								loading={loadingHidi}
								width={windowWidth > 480 ? 200 : '80%'}
							/>
							<Pressable
								flexDirection="row"
								alignItems="center"
								gap={5}
								onPress={() => {
									setTab('Peers')
								}}
								alignSelf="center"
							>
								<Icon
									as={ArrowLeftIcon}
									size={'xs'}
									color={'$primary500'}
								/>
								<Text
									fontFamily="$fontHeading"
									fontSize={12}
									color={'$primary500'}
									fontWeight="bold"
								>
									Back
								</Text>
							</Pressable>
						</Box>
					)}
				</Box>
			)}
		</>
	)
}

export default PreviewHidi
