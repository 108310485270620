import React from 'react'
import {
	CheckboxGroup,
	Checkbox,
	CheckboxIndicator,
	CheckboxIcon,
	CheckIcon,
	CheckboxLabel,
	Text,
	Box,
	HStack,
} from '@gluestack-ui/themed'
import { Dimensions } from 'react-native'

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

const MultipleSelect = ({
	userpreferences,
	setUserpreferences,
	property,
	data,
	label,
	disabled,
	important,
}) => {
	const handlePropertyChange = (value) => {
		setUserpreferences((prevState) => ({
			...prevState,
			[property]: value,
		}))
	}

	const selectedValues = Array.isArray(userpreferences[property])
		? userpreferences[property]
		: []

	return (
		<Box w={'100%'} marginVertical={windowWidth > 480 ? 12 : 6}>
			{label && (
				<HStack>
					<Text
						fontFamily="$fontContent"
						fontSize={
							windowWidth > 480
								? '$fontContent'
								: '$fontContentMobile'
						}
						fontWeight="800"
						color="$primary950"
					>
						{label}
					</Text>
					{important && <Text color="$secondary500">*</Text>}
				</HStack>
			)}
			<CheckboxGroup
				value={selectedValues}
				accessibilityLabel="choose numbers"
				onChange={(value) => handlePropertyChange(value)}
				flexDirection="row"
				w={'fit-content'}
				flexWrap="wrap"
				gap={20}
				marginVertical={10}
				isDisabled={disabled}
				aria-label="checkbox group"
			>
				{data?.map((item) => (
					<Checkbox
						key={item?.value}
						value={item?.value}
						w={'fit-content'}
						gap={5}
						size={windowWidth > 480 ? 'md' : 'sm'}
						aria-label="checkbox"
					>
						<CheckboxIndicator>
							<CheckboxIcon
								as={CheckIcon}
								size={windowWidth > 480 ? 'md' : 'sm'}
								key={item?.value}
							/>
						</CheckboxIndicator>
						<CheckboxLabel
							key={item?.value}
							fontFamily="$fontContent"
							fontWeight="bold"
							fontSize={
								windowWidth > 480
									? '$fontContent'
									: '$fontContentMobile'
							}
						>
							{item?.label}
						</CheckboxLabel>
					</Checkbox>
				))}
			</CheckboxGroup>
		</Box>
	)
}

export default MultipleSelect
