import {
	Modal,
	ModalBackdrop,
	ModalBody,
	ModalContent,
	ModalCloseButton,
	ModalFooter,
	ModalHeader,
	Heading,
	Text,
	Box,
	VStack,
	HStack,
	Icon,
	CloseIcon,
} from '@gluestack-ui/themed'

import React from 'react'
import { Dimensions } from 'react-native'
import ForYou from '../../newScreens/opportunities/ForYou'
import StatCounts from './StatCounts'

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

const PotentialOppModal = ({
	isOpen,
	onClose,
	navigation,
	potentialOpportunities,
	capabilitiesClaimed,
}) => {
	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			position="fixed"
			minHeight={windowHeight}
		>
			<ModalBackdrop />
			<ModalContent
				minWidth={
					windowWidth > 480 ? windowWidth * 0.7 : windowWidth * 0.9
				}
				maxHeight={windowHeight * 0.9}
				overflow={false}
			>
				<ModalHeader bg={'$primary0'}>
					<VStack flex={1}>
						<Heading
							fontFamily="$fontHeading"
							fontSize={
								windowWidth > 480
									? '$fontHeading'
									: '$fontHeadingMobile'
							}
							lineHeight={windowWidth < 480 && 15}
						>
							Check your{' '}
							<Text
								fontFamily="$fontHeading"
								fontSize={
									windowWidth > 480
										? '$fontHeading'
										: '$fontHeadingMobile'
								}
								fontWeight="700"
								color={'$primary500'}
							>
								elgibility
							</Text>
						</Heading>
						<Text
							fontFamily="$fontContent"
							color="$fontGray"
							fontSize={windowWidth > 480 ? 11 : 9}
							lineHeight={15}
						>
							For your matched opportunities
						</Text>
					</VStack>
					<ModalCloseButton>
						<Icon as={CloseIcon} />
					</ModalCloseButton>
				</ModalHeader>
				<ModalBody
					bg={'$primary0'}
					showsHorizontalScrollIndicator={false}
				>
					<StatCounts
						counts={[potentialOpportunities, capabilitiesClaimed]}
						labels={['Opportunities', 'Capabilities Claimed']}
					/>
					<ForYou
						navigation={navigation}
						inModal={true}
						closeModal={onClose}
						potentialOpp={true}
					/>
				</ModalBody>
			</ModalContent>
		</Modal>
	)
}

export default PotentialOppModal
